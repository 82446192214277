///////////////////////////////
// Description
///////////////////////////////

import { AppBar, Box, Button, Card, Dialog, DialogContent, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_AllProjectBaseInvoicesForStatus_Query,
  DatabaseRef_ProjectBaseInvoice_Document,
} from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseStagedBatchUpdate, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ReconcileBaseInvoicePaymentsDialog {
  clientKey: string
  pendingPayments: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

const tableSettings_BillReconciliation: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: false,
  sort_direction: 'asc',
  sort_property_default: 'invoice_id_number',
}

const tableColumns_BillReconciliation: TsInterface_TableColumns = {
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Invoice ID Number'), 'invoice_id_number'),
  associated_project_job_code: TableCellBasic('associated_project_job_code', rLIB('Project Job Code'), 'associated_project_job_code'),
  associated_task_name: TableCellBasic('associated_task_name', rLIB('Task Name'), 'associated_task_name'),
  total_price: TableCellCurrency('total_price', rLIB('Total Price'), 'total_price'),
  timestamp_invoice_billed: TableCellTimestamp(
    'timestamp_invoice_billed',
    rLIB('Invoice Billed Date'),
    'timestamp_invoice_billed',
    'D MMM YYYY - h:mm a',
    false,
  ),
}

const tableColumns_BillReconciliationWithPayment: TsInterface_TableColumns = {
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Invoice ID Number'), 'invoice_id_number'),
  associated_project_job_code: TableCellBasic('associated_project_job_code', rLIB('Project Job Code'), 'associated_project_job_code'),
  associated_task_name: TableCellBasic('associated_task_name', rLIB('Task Name'), 'associated_task_name'),
  total_price: TableCellCurrency('total_price', rLIB('Total Price'), 'total_price'),
  pending_payment_amount: TableCellCurrency('pending_payment_amount', rLIB('Uploaded Payment Amount'), 'pending_payment_amount'),
  TEMP_difference_amount: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellClassName = ''
        let differenceAmount = getProp(rowData, 'total_price', 0) - getProp(rowData, 'pending_payment_amount', 0)
        if (differenceAmount !== 0) {
          cellClassName = 'tw-bg-warning_main'
        } else {
          cellClassName = 'tw-bg-success_main'
        }
        return cellClassName
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box></Box>
        let differenceAmount = getProp(rowData, 'total_price', 0) - getProp(rowData, 'pending_payment_amount', 0)
        cellJSX = <Box>{formatCurrency(differenceAmount)}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Difference') as JSX.Element
      },
      header_sort_by: 'input_type',
    },
  },
  timestamp_invoice_billed: TableCellTimestamp(
    'timestamp_invoice_billed',
    rLIB('Invoice Billed Date'),
    'timestamp_invoice_billed',
    'D MMM YYYY - h:mm a',
    false,
  ),
}

const tableSettings_PaymentReconciliation: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: false,
  sort_direction: 'asc',
  sort_property_default: 'unique_identifier',
}

const tableColumns_PaymentReconciliation: TsInterface_TableColumns = {
  unique_identifier: TableCellBasic('unique_identifier', rLIB('Unique ID'), 'unique_identifier'),
  amount: TableCellCurrency('amount', rLIB('Amount'), 'amount'),
  date: TableCellBasic('date', rLIB('Date'), 'date'),
}

///////////////////////////////
// Functions
///////////////////////////////

const returnReconciledBaseInvoicePayments = (billedInvoices: TsInterface_UnspecifiedObject, pendingPayments: TsInterface_UnspecifiedObject) => {
  let reconciliationResult: TsInterface_UnspecifiedObject = {
    BILLS_with_duplicate_invoice_id_numbers: {},
    BILLS_with_matching_payment_but_different_amounts: {},
    BILLS_with_matching_payments_and_same_amounts: {},
    BILLS_without_matching_payments: {},
    PAYMENTS_missing_required_params: {},
    PAYMENTS_with_duplicate_unique_ids: {},
    PAYMENTS_with_multiple_matching_billing_ids: {},
    PAYMENTS_without_matching_bills: {},
  }
  let pendingPaymentIDs: TsInterface_UnspecifiedObject = {}
  let billingIdAlreadyUsed: TsInterface_UnspecifiedObject = {}
  let paymentIdAlreadyUsed: TsInterface_UnspecifiedObject = {}
  for (let loopPaymentKey in pendingPayments) {
    let loopPayment = pendingPayments[loopPaymentKey]
    if (loopPayment != null && loopPayment.amount != null && loopPayment.unique_identifier != null) {
      if (billingIdAlreadyUsed[loopPayment.unique_identifier] === true) {
        reconciliationResult['PAYMENTS_with_duplicate_unique_ids'][loopPayment.unique_identifier] = { ...loopPayment }
      }
      pendingPaymentIDs[loopPayment.unique_identifier] = true
      let foundMatchingBilledInvoice = false
      for (let loopBilledInvoiceKey in billedInvoices) {
        let loopBilledInvoice = billedInvoices[loopBilledInvoiceKey]
        if (
          loopBilledInvoice != null &&
          loopBilledInvoice.invoice_id_number != null &&
          loopPayment.unique_identifier != null &&
          loopBilledInvoice.invoice_id_number === loopPayment.unique_identifier
        ) {
          if (foundMatchingBilledInvoice === false) {
            foundMatchingBilledInvoice = true
            //
            if (loopBilledInvoice.total_price === loopPayment.amount) {
              reconciliationResult['BILLS_with_matching_payments_and_same_amounts'][loopBilledInvoice.key] = { ...loopBilledInvoice }
              reconciliationResult['BILLS_with_matching_payments_and_same_amounts'][loopBilledInvoice.key]['pending_payment_amount'] = loopPayment.amount
            } else {
              reconciliationResult['BILLS_with_matching_payment_but_different_amounts'][loopBilledInvoice.key] = { ...loopBilledInvoice }
              reconciliationResult['BILLS_with_matching_payment_but_different_amounts'][loopBilledInvoice.key]['pending_payment_amount'] = loopPayment.amount
            }
          } else {
            reconciliationResult['PAYMENTS_with_multiple_matching_billing_ids'][loopPayment.unique_identifier] = { ...loopPayment }
          }
        }
      }
      if (foundMatchingBilledInvoice === false) {
        reconciliationResult['PAYMENTS_without_matching_bills'][loopPayment.unique_identifier] = { ...loopPayment }
      }
    } else {
      reconciliationResult['PAYMENTS_missing_required_params'][loopPayment.unique_identifier] = { ...loopPayment }
    }
  }
  for (let loopBilledInvoiceKey in billedInvoices) {
    let loopBilledInvoice = billedInvoices[loopBilledInvoiceKey]
    if (loopBilledInvoice != null && loopBilledInvoice.invoice_id_number != null && pendingPaymentIDs[loopBilledInvoice.invoice_id_number] === undefined) {
      reconciliationResult['BILLS_without_matching_payments'][loopBilledInvoice.key] = { ...loopBilledInvoice }
    }
    if (loopBilledInvoice != null && loopBilledInvoice.invoice_id_number != null) {
      if (paymentIdAlreadyUsed[loopBilledInvoice.invoice_id_number] === true) {
        reconciliationResult['BILLS_with_duplicate_invoice_id_numbers'][loopBilledInvoice.key] = { ...loopBilledInvoice }
      }
      paymentIdAlreadyUsed[loopBilledInvoice.invoice_id_number] = true
    }
  }
  return reconciliationResult
}

///////////////////////////////
// Container
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const ReconcileBaseInvoicePaymentsDialog: React.FC<TsInterface_ReconcileBaseInvoicePaymentsDialog> = ({ clientKey, pendingPayments }): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const [us_unpaidBaseInvoices, us_setUnpaidBaseInvoices] = useState<TsInterface_UnspecifiedObject>({})
  const [us_reconciledInvoices, us_setReconciledInvoices] = useState<TsInterface_UnspecifiedObject>({})
  const [us_updatingInvoices, us_setUpdatingInvoices] = useState<boolean>(false)
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnpaidBaseInvoices(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectBaseInvoicesForStatus_Query(clientKey, 'billed'), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, clientKey])

  useEffect(() => {
    us_setReconciledInvoices(returnReconciledBaseInvoicePayments(us_unpaidBaseInvoices, pendingPayments))
    ur_forceRerender()
    return () => {}
  }, [us_unpaidBaseInvoices, pendingPayments, ur_forceRerender])

  // Functions

  const updateInvoicesFromBilledToPaid = (billedInvoices: TsInterface_UnspecifiedObject) => {
    us_setUpdatingInvoices(true)
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
    // let projectFinanceUpdateObject: TsInterface_UnspecifiedObject = {}
    for (let loopInvoiceKey in getProp(us_reconciledInvoices, 'BILLS_with_matching_payments_and_same_amounts', {})) {
      let loopInvoice = getProp(us_reconciledInvoices, 'BILLS_with_matching_payments_and_same_amounts', {})[loopInvoiceKey]
      updateArray.push({
        type: 'setMerge',
        ref: DatabaseRef_ProjectBaseInvoice_Document(clientKey, loopInvoice.key),
        data: { status: 'paid', timestamp_invoice_paid: new Date() },
      })
    }
    DatabaseStagedBatchUpdate(updateArray)
      .then((res_DBU) => {
        us_setUpdatingInvoices(false)
        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
      })
      .catch((rej_DBU) => {
        console.error(rej_DBU)
        us_setUpdatingInvoices(false)
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: rej_DBU.error,
        })
      })
  }

  // JSX Generation
  const rJSX_BillsWithMatchingPaymentsAndSameAmounts = (): JSX.Element => {
    let sectionJSX = <></>
    if (
      us_reconciledInvoices['BILLS_with_matching_payments_and_same_amounts'] != null &&
      objectToArray(us_reconciledInvoices['BILLS_with_matching_payments_and_same_amounts']).length > 0
    ) {
      sectionJSX = (
        <Box className="tw-text-left tw-mb-4">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: themeVariables.success_main,
                  borderRadius: '12px',
                  padding: '1px 8px',
                  minWidth: '40px',
                  textAlign: 'center',
                }}
              >
                <Icon
                  icon="circle-check"
                  className="tw-mr-2"
                />
                {objectToArray(us_reconciledInvoices['BILLS_with_matching_payments_and_same_amounts']).length}
              </Box>
              {rLIB('Bills with matching payments')}
            </Typography>
            <Button
              variant="contained"
              color="success"
              startIcon={
                us_updatingInvoices ? (
                  <Icon
                    icon="arrows-rotate"
                    className="bp_spin"
                  />
                ) : (
                  <Icon icon="money-check-dollar-pen" />
                )
              }
              disabled={us_updatingInvoices}
              onClick={() => {
                updateInvoicesFromBilledToPaid(us_reconciledInvoices['BILLS_with_matching_payments_and_same_amounts'])
              }}
            >
              {rLIB('Update Invoices to Paid')}
            </Button>
          </Stack>
          <Box>
            <Card className="tw-mt-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={tableColumns_BillReconciliationWithPayment}
                tableData={objectToArray(us_reconciledInvoices['BILLS_with_matching_payments_and_same_amounts'])}
                tableSettings={tableSettings_BillReconciliation}
              />
            </Card>
          </Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_BillsWithMatchingPaymentButDifferentAmounts = (): JSX.Element => {
    let sectionJSX = <></>
    if (
      us_reconciledInvoices['BILLS_with_matching_payment_but_different_amounts'] != null &&
      objectToArray(us_reconciledInvoices['BILLS_with_matching_payment_but_different_amounts']).length > 0
    ) {
      sectionJSX = (
        <Box className="tw-text-left tw-mb-4">
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{
                fontWeight: 'bold',
                backgroundColor: themeVariables.error_main,
                borderRadius: '12px',
                padding: '1px 8px',
                minWidth: '40px',
                textAlign: 'center',
              }}
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {objectToArray(us_reconciledInvoices['BILLS_with_matching_payment_but_different_amounts']).length}
            </Box>
            {rLIB('Bills with mismatched amounts')}
          </Typography>
          <Box>
            <Card className="tw-mt-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={tableColumns_BillReconciliationWithPayment}
                tableData={objectToArray(us_reconciledInvoices['BILLS_with_matching_payment_but_different_amounts'])}
                tableSettings={tableSettings_BillReconciliation}
              />
            </Card>
          </Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_BillsWithoutMatchingPayments = (): JSX.Element => {
    let sectionJSX = <></>
    if (
      us_reconciledInvoices['BILLS_without_matching_payments'] != null &&
      objectToArray(us_reconciledInvoices['BILLS_without_matching_payments']).length > 0
    ) {
      sectionJSX = (
        <Box className="tw-text-left tw-mb-4">
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{
                fontWeight: 'bold',
                backgroundColor: themeVariables.warning_main,
                borderRadius: '12px',
                padding: '1px 8px',
                minWidth: '40px',
                textAlign: 'center',
              }}
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {objectToArray(us_reconciledInvoices['BILLS_without_matching_payments']).length}
            </Box>
            {rLIB('Bills without matching payments')}
          </Typography>
          <Box>
            <Card className="tw-mt-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={tableColumns_BillReconciliation}
                tableData={objectToArray(us_reconciledInvoices['BILLS_without_matching_payments'])}
                tableSettings={tableSettings_BillReconciliation}
              />
            </Card>
          </Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_PaymentsWithoutMatchingBills = (): JSX.Element => {
    let sectionJSX = <></>
    if (
      us_reconciledInvoices['PAYMENTS_without_matching_bills'] != null &&
      objectToArray(us_reconciledInvoices['PAYMENTS_without_matching_bills']).length > 0
    ) {
      sectionJSX = (
        <Box className="tw-text-left tw-mb-4">
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{
                fontWeight: 'bold',
                backgroundColor: themeVariables.warning_main,
                borderRadius: '12px',
                padding: '1px 8px',
                minWidth: '40px',
                textAlign: 'center',
              }}
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {objectToArray(us_reconciledInvoices['PAYMENTS_without_matching_bills']).length}
            </Box>
            {rLIB('Payments without matching bills')}
          </Typography>
          <Box>
            <Card className="tw-mt-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={tableColumns_PaymentReconciliation}
                tableData={objectToArray(us_reconciledInvoices['PAYMENTS_without_matching_bills'])}
                tableSettings={tableSettings_PaymentReconciliation}
              />
            </Card>
          </Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_PaymentsMissingRequiredParams = (): JSX.Element => {
    let sectionJSX = <></>
    if (
      us_reconciledInvoices['PAYMENTS_missing_required_params'] != null &&
      objectToArray(us_reconciledInvoices['PAYMENTS_missing_required_params']).length > 0
    ) {
      sectionJSX = (
        <Box className="tw-text-left tw-mb-4">
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{
                fontWeight: 'bold',
                backgroundColor: themeVariables.error_main,
                borderRadius: '12px',
                padding: '1px 8px',
                minWidth: '40px',
                textAlign: 'center',
              }}
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {objectToArray(us_reconciledInvoices['PAYMENTS_missing_required_params']).length}
            </Box>
            {rLIB('Payments missing required params')}
          </Typography>
          <Box>
            <Card className="tw-mt-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={tableColumns_PaymentReconciliation}
                tableData={objectToArray(us_reconciledInvoices['PAYMENTS_missing_required_params'])}
                tableSettings={tableSettings_PaymentReconciliation}
              />
            </Card>
          </Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_BillsWithDuplicateInvoiceIdNumbers = (): JSX.Element => {
    let sectionJSX = <></>
    if (
      us_reconciledInvoices['BILLS_with_duplicate_invoice_id_numbers'] != null &&
      objectToArray(us_reconciledInvoices['BILLS_with_duplicate_invoice_id_numbers']).length > 0
    ) {
      sectionJSX = (
        <Box className="tw-text-left tw-mb-4">
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{
                fontWeight: 'bold',
                backgroundColor: themeVariables.error_main,
                borderRadius: '12px',
                padding: '1px 8px',
                minWidth: '40px',
                textAlign: 'center',
              }}
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {objectToArray(us_reconciledInvoices['BILLS_with_duplicate_invoice_id_numbers']).length}
            </Box>
            {rLIB('Bills with duplicate invoice id numbers')}
          </Typography>
          <Box>
            <Card className="tw-mt-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={tableColumns_BillReconciliation}
                tableData={objectToArray(us_reconciledInvoices['BILLS_with_duplicate_invoice_id_numbers'])}
                tableSettings={tableSettings_BillReconciliation}
              />
            </Card>
          </Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_PaymentsWithDuplicateUniqueIds = (): JSX.Element => {
    let sectionJSX = <></>
    if (
      us_reconciledInvoices['PAYMENTS_with_duplicate_unique_ids'] != null &&
      objectToArray(us_reconciledInvoices['PAYMENTS_with_duplicate_unique_ids']).length > 0
    ) {
      sectionJSX = (
        <Box className="tw-text-left tw-mb-4">
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{
                fontWeight: 'bold',
                backgroundColor: themeVariables.error_main,
                borderRadius: '12px',
                padding: '1px 8px',
                minWidth: '40px',
                textAlign: 'center',
              }}
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {objectToArray(us_reconciledInvoices['PAYMENTS_with_duplicate_unique_ids']).length}
            </Box>
            {rLIB('Payments with duplicate unique ids')}
          </Typography>
          <Box>
            <Card className="tw-mt-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={tableColumns_PaymentReconciliation}
                tableData={objectToArray(us_reconciledInvoices['PAYMENTS_with_duplicate_unique_ids'])}
                tableSettings={tableSettings_PaymentReconciliation}
              />
            </Card>
          </Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_PaymentsWithMultipleMatchingBillingIds = (): JSX.Element => {
    let sectionJSX = <></>
    if (
      us_reconciledInvoices['PAYMENTS_with_multiple_matching_billing_ids'] != null &&
      objectToArray(us_reconciledInvoices['PAYMENTS_with_multiple_matching_billing_ids']).length > 0
    ) {
      sectionJSX = (
        <Box className="tw-text-left tw-mb-4">
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{
                fontWeight: 'bold',
                backgroundColor: themeVariables.error_main,
                borderRadius: '12px',
                padding: '1px 8px',
                minWidth: '40px',
                textAlign: 'center',
              }}
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {objectToArray(us_reconciledInvoices['PAYMENTS_with_multiple_matching_billing_ids']).length}
            </Box>
            {rLIB('Payments with multiple matching billing ids')}
          </Typography>
          <Box>
            <Card className="tw-mt-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={tableColumns_PaymentReconciliation}
                tableData={objectToArray(us_reconciledInvoices['PAYMENTS_with_multiple_matching_billing_ids'])}
                tableSettings={tableSettings_PaymentReconciliation}
              />
            </Card>
          </Box>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_ReconciliationResults = (): JSX.Element => {
    let resultsJSX = <></>
    resultsJSX = (
      <Box>
        {rJSX_BillsWithMatchingPaymentsAndSameAmounts()}
        {rJSX_BillsWithMatchingPaymentButDifferentAmounts()}
        {rJSX_BillsWithoutMatchingPayments()}
        {rJSX_PaymentsWithoutMatchingBills()}
        {rJSX_PaymentsMissingRequiredParams()}
        {rJSX_BillsWithDuplicateInvoiceIdNumbers()}
        {rJSX_PaymentsWithDuplicateUniqueIds()}
        {rJSX_PaymentsWithMultipleMatchingBillingIds()}
      </Box>
    )
    return resultsJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Dialog
        // TransitionComponent={ Transition }
        className="bp_dialog_xl_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 1, color: '#fff !important' }}
            >
              <Icon icon="file-invoice-dollar" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              <Box className="tw-inline-block">{rLIB('Reconcile Billing Payments')}</Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box className="tw-p-4 tw-text-center">
            {rJSX_ReconciliationResults()}
            {/* <Json data={us_reconciledInvoices} />
            PAYMENTS
            <Json data={pendingPayments} />
            BILLED INVOICES
            <Json data={us_unpaidBaseInvoices} /> */}
          </Box>
        </DialogContent>
      </Dialog>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
