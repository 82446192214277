/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Services contain business logic that is completely abstracted from user interfaces

		TODO:

			Image Uploads using the same folder key regardless of folder used
				Probably something with additionalFileUploadParams

			Add unix timestamp to end of file names before last "." to stop the wipe of duplicate uploads issue in database arraymor

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material/'
import { severityMessageTypes } from 'app/models/ml/logic_engine_ui'
import { returnIconKeyForFileType } from 'app/models/projects/project_document_folders'
import { returnImageTags } from 'app/models/projects/project_image_tags'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_TaskFormInstructions_Document,
  DatabaseRef_TaskFormProdPagesHistory_Document,
  DatabaseRef_TaskFormProdPages_Document,
  DatabaseRef_TaskFormStagingPages_Collection,
  DatabaseRef_TaskFormStagingPages_Document,
  DatabaseRef_TaskForm_Document,
} from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import { DatabaseRef_TaskWorkflowProd_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import {
  DatabaseRef_ProjectMLAggregateEvaluations_Collection,
  DatabaseRef_ProjectMLImageEvaluationsForTask_Query,
  DatabaseRef_ProjectTaskFormData_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  DatabaseRef_AdministrationTaskFormData_Document,
  DatabaseRef_DispatcherTask_Query,
  DatabaseRef_ProjectTasksForPrerequisiteTask_Query,
  DatabaseRef_Task_Document,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { StorageRef_AdministrationTaskPageFile } from 'rfbp_aux/services/storage_endpoints/administration'
import { StorageRef_ProjectTaskPageFile } from 'rfbp_aux/services/storage_endpoints/projects'
import { Json } from 'rfbp_core/components/code_display'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_FormInputOptionArray,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TsInterface_TableHooks } from 'rfbp_core/components/table'
import { TabsBasic, TsInterface_TabContentArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_Snackbar,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  StorageUploadFile,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import {
  cloneObjectWithoutReference,
  dynamicSort,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import {
  formInputs_EditTask,
  formInputs_EditTaskTeam,
  formInputs_EditTask_Direct,
  formInputs_EditTask_Dispatcher,
  formInputs_EditTask_Scheduled,
  formOnChange_EditTask,
  formOnChange_EditTaskTeam,
  formSettings_EditTask,
  formSettings_EditTaskTeam,
  formSubmission_EditTask,
  formSubmission_EditTaskTeam,
} from './task_forms'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_FilesToUpload {
  [$fileKey: string]: {
    file_name: string
    file: any
    data_url: any
    storage_url?: string | null
  }
}

interface TsInterface_EventDayMembershipDialog {
  clientKey: string
  taskKey: string
  userKey: string
  userName: string
  startEditable: boolean
  workflowKey: string | null
}

interface TsInterface_YoloObjectDetectionPreview {
  imageURL: string
  boundingBoxes: TsInterface_UnspecifiedObject[]
  maxWidth: string | number
  autoHeight: boolean
  showControlButtons: boolean
  startWithCategoryLabels: boolean
  startWithBoundingLabels: boolean
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_COMPLETE_THIS_TASK: JSX.Element = <Trans>Are you sure that you want to complete this task?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_IMAGE: JSX.Element = <Trans>Are you sure that you want to delete this image?</Trans>
const s_CHECKBOXES: JSX.Element = <Trans>Checkboxes</Trans>
const s_COMPLETE: JSX.Element = <Trans>Complete</Trans>
const s_COMPLETED_QUESTIONS: JSX.Element = <Trans>Completed Questions</Trans>
const s_COMPLETE_TASK: JSX.Element = <Trans>Complete Task</Trans>
const s_DATE: JSX.Element = <Trans>Date</Trans>
const s_DATETIME: JSX.Element = <Trans>Datetime</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_IMAGE: JSX.Element = <Trans>Delete Image</Trans>
const s_DROPDOWN: JSX.Element = <Trans>Dropdown</Trans>
const s_EDIT_TASK: JSX.Element = <Trans>Edit Task</Trans>
const s_FAILED_TO_DELETE_IMAGE: JSX.Element = <Trans>Failed to delete image</Trans>
const s_FAILED_TO_IMPORT_FILE: JSX.Element = <Trans>Failed to import file</Trans>
const s_FAILED_TO_OPEN_TASK_FORM: JSX.Element = <Trans>Failed to open task form</Trans>
const s_FAILED_TO_SUBMIT_PROJECT_PAGE: JSX.Element = <Trans>Failed to submit project page</Trans>
const s_FAILED_TO_UPDATE_IMAGE_TAGS: JSX.Element = <Trans>Failed to update image tags</Trans>
const s_FAILED_TO_UPLOAD_FILE: JSX.Element = <Trans>Failed to upload file</Trans>
const s_FILE_UPLOADS: JSX.Element = <Trans>File Uploads</Trans>
const s_FOLDER: JSX.Element = <Trans>Folder</Trans>
const s_FORM: JSX.Element = <Trans>Form</Trans>
const s_HIDE_BOXES: JSX.Element = <Trans>Hide Boxes</Trans>
const s_HIDE_LABELS: JSX.Element = <Trans>Hide Labels</Trans>
const s_IMAGES_FINISHED_WITH_OBJECT_DETECTION: JSX.Element = <Trans>Images finished with object detection</Trans>
const s_IMAGES_INCLUDED_IN_AGGREGATE_ANALYSIS: JSX.Element = <Trans>Images included in aggregate analysis</Trans>
const s_IMAGE_PREVIEW: JSX.Element = <Trans>Image Preview</Trans>
const s_IMAGE_UPLOADS: JSX.Element = <Trans>Image Uploads</Trans>
const s_INCOMPLETE_QUESTIONS: JSX.Element = <Trans>Incomplete Questions</Trans>
const s_INVALID_DOCUMENT_SELECTION: JSX.Element = <Trans>Invalid document selection</Trans>
const s_MAXIMUM_OF: JSX.Element = <Trans>Maximum of</Trans>
const s_MESSAGES: JSX.Element = <Trans>messages</Trans>
const s_MINIMUM_OF: JSX.Element = <Trans>Minimum of</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing required parameters</Trans>
const s_ML_AGGREGATE_RESULTS: JSX.Element = <Trans>ML Aggregate Results</Trans>
const s_ML_ANALYSIS_COMPLETE: JSX.Element = <Trans>ML Analysis Complete</Trans>
const s_ML_ANALYSIS_NOT_COMPLETE: JSX.Element = <Trans>ML Analysis Not Complete</Trans>
const s_MOVE_IMAGE: JSX.Element = <Trans>Move Image</Trans>
const s_MULTIPLE_CHOICE: JSX.Element = <Trans>Multiple Choice</Trans>
const s_NOT_EVALUATED_YET: JSX.Element = <Trans>Not evaluated yet</Trans>
const s_NO_ERROR_OR_WARNING_MESSAGES: JSX.Element = <Trans>No error or warning messages</Trans>
const s_NO_MAXIMUM: JSX.Element = <Trans>No maximum</Trans>
const s_NO_MINIMUM: JSX.Element = <Trans>No minimum</Trans>
const s_NO_TARGET_OBJECTS_DETECTED: JSX.Element = <Trans>No target objects detected</Trans>
const s_OF: JSX.Element = <Trans>of</Trans>
const s_OPTIONAL: JSX.Element = <Trans>Optional</Trans>
const s_PAGE: JSX.Element = <Trans>Page</Trans>
const s_PAGE_SUBMISSION: JSX.Element = <Trans>Page Submission</Trans>
const s_PARAGRAPH: JSX.Element = <Trans>Paragraph</Trans>
const s_REQUIRED: JSX.Element = <Trans>Required</Trans>
const s_RUN_ML_ANALYSIS: JSX.Element = <Trans>Run ML Analysis</Trans>
const s_SHORT_ANSWER: JSX.Element = <Trans>Short Answer</Trans>
const s_SHOWING: JSX.Element = <Trans>Showing</Trans>
const s_SHOW_BOXES: JSX.Element = <Trans>Show Boxes</Trans>
const s_SHOW_LABELS: JSX.Element = <Trans>Show Labels</Trans>
const s_SUBMIT_PAGE: JSX.Element = <Trans>Submit Page</Trans>
const s_TASK_COMPLETED: JSX.Element = <Trans>Task completed</Trans>
const s_TASK_COMPLETED_BY: JSX.Element = <Trans>Task completed by</Trans>
const s_TASK_INSTRUCTIONS: JSX.Element = <Trans>Task Instructions</Trans>
const s_TIME: JSX.Element = <Trans>Time</Trans>
const s_TOGGLE: JSX.Element = <Trans>Toggle</Trans>
const s_UNSUPPORTED_DATA_STRUCTURE: JSX.Element = <Trans>Unsupported data structure</Trans>
const s_UPLOAD: JSX.Element = <Trans>Upload</Trans>
const s_UPLOADS: JSX.Element = <Trans>Uploads</Trans>
const s_UPLOAD_FILE: JSX.Element = <Trans>Upload File</Trans>
const s_VIEWING_ALL_MESSAGES: JSX.Element = <Trans>Viewing all messages</Trans>
const s_VIEWING_ONLY_ERROR_MESSAGES: JSX.Element = <Trans>Viewing only error messages</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

function sortByTwoProperties(arr: TsInterface_UnspecifiedObject[], firstProp: string, secondProp: string) {
  arr.sort((a, b) => {
    if (a[firstProp] === b[firstProp]) {
      return a[secondProp] < b[secondProp] ? -1 : 1
    }
    return a[firstProp] < b[firstProp] ? -1 : 1
  })
  return arr
}

function removeItemAtIndex(arr: TsInterface_UnspecifiedObject[], index: number) {
  // Check if the index is valid
  if (index < 0 || index >= arr.length) {
    console.error('Invalid index')
    return arr // Return the original array if the index is out of bounds
  }
  // Create a new array with the item at the specified index removed
  const newArr = [...arr.slice(0, index), ...arr.slice(index + 1)]
  return newArr
}

///////////////////////////////
// Exports
///////////////////////////////

export const pageTypeOptions: TsInterface_UnspecifiedObject = {
  form: {
    key: 'form',
    value: s_FORM,
    icon: (
      <Icon
        icon="memo"
        type="solid"
      />
    ),
  },
  file_uploads: {
    key: 'file_uploads',
    value: s_FILE_UPLOADS,
    icon: (
      <Icon
        icon="cloud-arrow-up"
        type="solid"
      />
    ),
  },
  image_uploads: {
    key: 'image_uploads',
    value: s_IMAGE_UPLOADS,
    icon: (
      <Icon
        icon="camera"
        type="solid"
      />
    ),
  },
}

export const questionTypeOptions: TsInterface_UnspecifiedObject = {
  short_answer: {
    key: 'short_answer',
    value: s_SHORT_ANSWER,
    icon: (
      <Icon
        icon="horizontal-rule"
        type="solid"
      />
    ),
  },
  paragraph: {
    key: 'paragraph',
    value: s_PARAGRAPH,
    icon: (
      <Icon
        icon="align-justify"
        type="solid"
      />
    ),
  },
  multiple_choice: {
    key: 'multiple_choice',
    value: s_MULTIPLE_CHOICE,
    icon: (
      <Icon
        icon="circle-dot"
        type="regular"
      />
    ),
    has_options: true,
  },
  checkboxes: {
    key: 'checkboxes',
    value: s_CHECKBOXES,
    icon: (
      <Icon
        icon="square-check"
        type="solid"
      />
    ),
    has_options: true,
  },
  dropdown: {
    key: 'dropdown',
    value: s_DROPDOWN,
    icon: (
      <Icon
        icon="circle-caret-down"
        type="solid"
      />
    ),
    has_options: true,
  },
  // file_upload: {			key: "file_upload", 		value: s_FILE_UPLOAD,			icon: <Icon icon="cloud-arrow-up" type="solid" /> 							},
  date: {
    key: 'date',
    value: s_DATE,
    icon: (
      <Icon
        icon="calendar-day"
        type="regular"
      />
    ),
  },
  time: {
    key: 'time',
    value: s_TIME,
    icon: (
      <Icon
        icon="clock"
        type="regular"
      />
    ),
  },
  datetime: {
    key: 'datetime',
    value: s_DATETIME,
    icon: (
      <Icon
        icon="calendar-clock"
        type="regular"
      />
    ),
  },
  toggle: {
    key: 'toggle',
    value: s_TOGGLE,
    icon: (
      <Icon
        icon="toggle-on"
        type="regular"
      />
    ),
  },
}

const returnFormattedFormInputOptions = (unformattedOptionsObject: TsInterface_UnspecifiedObject): TsType_FormInputOptionArray => {
  let optionsArray: TsType_FormInputOptionArray = []
  let sortedUnformattedOptionsArray = objectToArray(unformattedOptionsObject).sort(dynamicSort('order', null))
  for (let loopIndex in sortedUnformattedOptionsArray) {
    let loopOption = sortedUnformattedOptionsArray[loopIndex]
    if (loopOption != null && loopOption.option_label != null) {
      optionsArray.push({
        key: loopOption.option_label,
        value: loopOption.option_label,
      })
    } else if (loopOption != null && loopOption.value != null) {
      optionsArray.push({
        key: loopOption.value,
        value: loopOption.value,
      })
    }
  }
  return optionsArray
}

export const returnBoilerplateFormFromTaskFormQuestions = (
  unsortedTaskFormObject: TsInterface_UnspecifiedObject,
  enabledForm: boolean,
): TsInterface_FormInputs => {
  let boilerplateFormInputs: TsInterface_FormInputs = {}
  let sortedQuestionArray = objectToArray(unsortedTaskFormObject).sort(dynamicSort('order', null))
  for (let loopQuestionIndex in sortedQuestionArray) {
    let loopQuestion = sortedQuestionArray[loopQuestionIndex]
    if (loopQuestion.input_type != null) {
      switch (loopQuestion.input_type) {
        case 'short_answer':
          boilerplateFormInputs[loopQuestion.key] = {
            data_type: 'string',
            input_type: 'text_basic',
            key: loopQuestion.key,
            label: getProp(loopQuestion, 'label', ''),
            caption: getProp(loopQuestion, 'caption', ''),
            placeholder: getProp(loopQuestion, 'placeholder', ''),
            required: getProp(loopQuestion, 'required', false),
            disabled: !enabledForm,
          }
          break
        case 'paragraph':
          boilerplateFormInputs[loopQuestion.key] = {
            data_type: 'string',
            input_type: 'text_multiline',
            key: loopQuestion.key,
            label: getProp(loopQuestion, 'label', ''),
            caption: getProp(loopQuestion, 'caption', ''),
            placeholder: getProp(loopQuestion, 'placeholder', ''),
            required: getProp(loopQuestion, 'required', false),
            disabled: !enabledForm,
          }
          break
        case 'multiple_choice':
          boilerplateFormInputs[loopQuestion.key] = {
            data_type: 'string',
            input_type: 'multiple_choice_radio',
            key: loopQuestion.key,
            label: getProp(loopQuestion, 'label', ''),
            caption: getProp(loopQuestion, 'caption', ''),
            options: returnFormattedFormInputOptions(loopQuestion.options),
            required: getProp(loopQuestion, 'required', false),
            disabled: !enabledForm,
          }
          break
        case 'checkboxes':
          boilerplateFormInputs[loopQuestion.key] = {
            data_type: 'string', // TODO - probably an object
            input_type: 'multiple_select_checklist',
            key: loopQuestion.key,
            label: getProp(loopQuestion, 'label', ''),
            caption: getProp(loopQuestion, 'caption', ''),
            options: returnFormattedFormInputOptions(loopQuestion.options),
            required: getProp(loopQuestion, 'required', false),
            disabled: !enabledForm,
          }
          break
        case 'dropdown':
          boilerplateFormInputs[loopQuestion.key] = {
            data_type: 'string',
            input_type: 'multiple_choice_select',
            key: loopQuestion.key,
            label: getProp(loopQuestion, 'label', ''),
            caption: getProp(loopQuestion, 'caption', ''),
            placeholder: getProp(loopQuestion, 'placeholder', ''),
            options: returnFormattedFormInputOptions(loopQuestion.options),
            required: getProp(loopQuestion, 'required', false),
            disabled: !enabledForm,
          }
          break
        case 'date':
          boilerplateFormInputs[loopQuestion.key] = {
            data_type: 'string',
            input_type: 'timestamp_date',
            key: loopQuestion.key,
            label: getProp(loopQuestion, 'label', ''),
            caption: getProp(loopQuestion, 'caption', ''),
            placeholder: getProp(loopQuestion, 'placeholder', ''),
            required: getProp(loopQuestion, 'required', false),
            disabled: !enabledForm,
          }
          break
        case 'time':
          boilerplateFormInputs[loopQuestion.key] = {
            data_type: 'string',
            input_type: 'timestamp_time',
            key: loopQuestion.key,
            label: getProp(loopQuestion, 'label', ''),
            caption: getProp(loopQuestion, 'caption', ''),
            placeholder: getProp(loopQuestion, 'placeholder', ''),
            required: getProp(loopQuestion, 'required', false),
            disabled: !enabledForm,
          }
          break
        case 'datetime':
          boilerplateFormInputs[loopQuestion.key] = {
            data_type: 'string',
            input_type: 'timestamp_datetime',
            key: loopQuestion.key,
            label: getProp(loopQuestion, 'label', ''),
            caption: getProp(loopQuestion, 'caption', ''),
            placeholder: getProp(loopQuestion, 'placeholder', ''),
            required: getProp(loopQuestion, 'required', false),
            disabled: !enabledForm,
          }
          break
        case 'toggle':
          boilerplateFormInputs[loopQuestion.key] = {
            data_type: 'boolean',
            input_type: 'boolean_switch',
            key: loopQuestion.key,
            label: getProp(loopQuestion, 'label', ''),
            caption: getProp(loopQuestion, 'caption', ''),
            required: getProp(loopQuestion, 'required', false),
            disabled: !enabledForm,
          }
          break
      }
    }
  }
  return boilerplateFormInputs
}

const checkIfTagIsChecked = (tag: string, file: TsInterface_UnspecifiedObject): boolean => {
  let checked = false
  if (tag != null && file != null && file['tags'] != null) {
    if (file['tags'].indexOf(tag) > -1) {
      checked = true
    }
  }
  return checked
}

const editImageTags = (
  clientKey: string,
  projectKey: string,
  taskKey: string,
  pageKey: string,
  folderKey: string,
  fileIndex: number,
  taskFormData: TsInterface_UnspecifiedObject,
  tagsUpdateArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let updateObject: TsInterface_UnspecifiedObject = {}
    updateObject[pageKey] = { folders: {} }
    if (
      taskFormData != null &&
      taskFormData[pageKey] != null &&
      taskFormData[pageKey]['folders'] != null &&
      taskFormData[pageKey]['folders'][folderKey] != null
    ) {
      updateObject[pageKey]['folders'][folderKey] = taskFormData[pageKey]['folders'][folderKey]
      if (updateObject[pageKey]['folders'][folderKey][fileIndex] != null) {
        updateObject[pageKey]['folders'][folderKey][fileIndex]['tags'] = tagsUpdateArray.sort()
        // taskFormData[ pageKey ]["folders"][ folderKey ][ fileIndex ]["tags"] = tagsUpdateArray.sort()
        // Update Database
        DatabaseSetMergeDocument(DatabaseRef_ProjectTaskFormData_Document(clientKey, projectKey, taskKey), updateObject)
          .then((res_DSMD) => {
            resolve(res_DSMD)
          })
          .catch((rej_DSMD) => {
            reject(rej_DSMD)
          })
      } else {
        reject({
          success: false,
          error: {
            message: s_FAILED_TO_UPDATE_IMAGE_TAGS,
            details: s_UNSUPPORTED_DATA_STRUCTURE,
            code: 'ER-D-TFS-EIT-01',
          },
        })
      }
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_UPDATE_IMAGE_TAGS,
          details: s_UNSUPPORTED_DATA_STRUCTURE,
          code: 'ER-D-TFS-EIT-02',
        },
      })
    }
  })
}

const YoloObjectDetectionPreview: React.FC<TsInterface_YoloObjectDetectionPreview> = ({
  imageURL,
  boundingBoxes,
  maxWidth,
  autoHeight,
  showControlButtons,
  startWithCategoryLabels,
  startWithBoundingLabels,
}) => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_showBoundingBoxes, us_setShowBoundingBoxes] = useState(startWithBoundingLabels)
  const [us_showCategoryLabels, us_setShowCategoryLabels] = useState(startWithCategoryLabels)
  const ur_canvasRef = useRef(null)
  const ur_imageRef = useRef(null)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    const img: any = ur_imageRef.current
    img.onload = redrawCanvas
  }, [imageURL, boundingBoxes])

  useEffect(() => {
    redrawCanvas()
  }, [us_showCategoryLabels, us_showBoundingBoxes])

  // Variables
  const NeonColors = [
    '#FF0000', // (Neon Red)
    '#FF9900', // (Neon Orange)
    '#FFCC00', // (Neon Amber)
    '#FFFF00', // (Neon Yellow)
    '#00FF00', // (Neon Green)
    '#00FF33', // (Neon Lime)
    '#33FF66', // (Neon Spring Green)
    '#66FF33', // (Neon Chartreuse)
    '#CCFF00', // (Neon Lime Yellow)
    '#00FFFF', // (Neon Cyan)
    '#00CCFF', // (Neon Sky Blue)
    '#33FFFF', // (Neon Aqua)
    '#CC33FF', // (Neon Purple)
    '#FF33FF', // (Neon Magenta)
    '#FF66CC', // (Neon Coral)
    '#FF00FF', // (Neon Pink)
  ]

  // Functions
  const redrawCanvas = () => {
    if (ur_canvasRef != null && ur_canvasRef.current != null) {
      // @ts-expect-error - TODO: reason for error
      const ctx = ur_canvasRef.current.getContext('2d')
      const img: any = ur_imageRef.current
      const parentWidth = img.parentNode.clientWidth
      let scale = 1
      // Check if maxWidth is a percentage
      if (typeof maxWidth === 'string' && maxWidth.endsWith('%')) {
        const percentage = parseFloat(maxWidth.slice(0, -1)) / 100
        scale = (parentWidth * percentage) / img.naturalWidth
      } else if (typeof maxWidth === 'number') {
        scale = maxWidth / img.naturalWidth
      }
      const newWidth = img.naturalWidth * scale
      const newHeight = img.naturalHeight * scale
      // @ts-expect-error - TODO: reason for error
      ur_canvasRef.current.width = newWidth
      // @ts-expect-error - TODO: reason for error
      ur_canvasRef.current.height = newHeight
      img.style.width = `${newWidth}px`
      img.style.height = autoHeight ? 'auto' : `${newHeight}px`
      ctx.clearRect(0, 0, newWidth, newHeight)
      ctx.drawImage(img, 0, 0, newWidth, newHeight)
      // Draw bounding boxes and possibly labels
      boundingBoxes.forEach((box: any, i: number) => {
        const color = NeonColors[i % NeonColors.length]
        ctx.strokeStyle = color
        ctx.lineWidth = 2
        ctx.font = '12px Arial'
        const x = box.x * newWidth - (box.width * newWidth) / 2
        const y = box.y * newHeight - (box.height * newHeight) / 2
        const width = box.width * newWidth
        const height = box.height * newHeight
        // Draw the bounding box
        if (us_showBoundingBoxes) {
          ctx.strokeRect(x, y, width, height)
        }
        if (us_showCategoryLabels) {
          const text = `${box.category_string} (${(box.confidence * 100).toFixed(1)}%)`
          const textMetrics = ctx.measureText(text)
          // Draw background rectangle for text
          ctx.fillStyle = `${color}aa`
          ctx.fillRect(x, y - 1, textMetrics.width + 10, 20)
          // Display the category and confidence
          ctx.fillStyle = '#000000'
          ctx.fillText(text, x + 5, y + 13)
        }
      })
    }
  }

  // JSX Generation
  const rJSX_ListBoxTags = (): JSX.Element => {
    let tagsJSX = <></>
    tagsJSX = (
      <Box>
        {boundingBoxes.map((box: TsInterface_UnspecifiedObject, index: number) => (
          <Box key={index}>
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: NeonColors[index % NeonColors.length] }}
            >
              <Icon icon="tag" />
            </Box>
            {box.category_string} ({(box.confidence * 100).toFixed(1)}%)
          </Box>
        ))}
      </Box>
    )

    return tagsJSX
  }

  const rJSX_ToggleButtons = (): JSX.Element => {
    let buttonJSX = <></>
    if (showControlButtons === true) {
      // Buttons
      let toggleLabelText = <></>
      let labelButtonVariant: 'contained' | 'outlined' = 'contained'
      if (us_showCategoryLabels === true) {
        toggleLabelText = <>{s_HIDE_LABELS}</>
      } else {
        toggleLabelText = <>{s_SHOW_LABELS}</>
        labelButtonVariant = 'outlined'
      }
      let toggleBoundingBoxText = <></>
      let boxButtonVariant: 'contained' | 'outlined' = 'contained'
      if (us_showBoundingBoxes === true) {
        toggleBoundingBoxText = <>{s_HIDE_BOXES}</>
      } else {
        toggleBoundingBoxText = <>{s_SHOW_BOXES}</>
        boxButtonVariant = 'outlined'
      }
      // JSX
      buttonJSX = (
        <Box>
          <Button
            variant={labelButtonVariant}
            color="info"
            className="tw-mb-2 tw-mr-2"
            onClick={() => {
              us_setShowCategoryLabels(!us_showCategoryLabels)
            }}
          >
            <Icon
              icon="tag"
              className="tw-mr-2"
            />
            {toggleLabelText}
          </Button>
          <Button
            variant={boxButtonVariant}
            color="info"
            className="tw-mb-2 tw-mr-2"
            onClick={() => {
              us_setShowBoundingBoxes(!us_showBoundingBoxes)
            }}
          >
            <Icon
              icon="brackets-square"
              className="tw-mr-2"
            />
            {toggleBoundingBoxText}
          </Button>
        </Box>
      )
    }
    return buttonJSX
  }

  // Return
  return (
    <Box>
      <Box style={{ position: 'relative' }}>
        <canvas
          ref={ur_canvasRef}
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
        <img
          ref={ur_imageRef}
          src={imageURL}
          alt="Object Detection"
        />
      </Box>
      {rJSX_ToggleButtons()}
      {rJSX_ListBoxTags()}
    </Box>
  )
}

export const TaskFormCustomDialog: React.FC<TsInterface_EventDayMembershipDialog> = ({
  clientKey,
  taskKey,
  workflowKey,
  startEditable,
  userKey,
  userName,
}): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_editable, us_setEditable] = useState<boolean>(startEditable)
  const [us_openImageFolderKey, us_setOpenImageFolderKey] = useState<string | null>(null)
  const [us_openImagePageKey, us_setOpenImagePageKey] = useState<string | null>(null)
  const [us_openImagePreview, us_setOpenImagePreview] = useState<boolean>(false)
  const [us_openImagePreviewData, us_setOpenImagePreviewData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_runningMLAnalysis, us_setRunningMLAnalysis] = useState<boolean>(false)
  const [us_showAggregateMessageType, us_setShowAggregateMessageType] = useState<string>('errors')
  const [us_taskData, us_setTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskForm, us_setTaskForm] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskFormData, us_setTaskFormData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskMLAggregateResults, us_setTaskMLAggregateResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskMLImages, us_setTaskMLImages] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (taskKey != null && newData != null && newData.tasks != null && newData.tasks[taskKey] != null) {
        us_setTaskData(newData.tasks[taskKey])
        if (newData.tasks[taskKey].status_complete === true) {
          us_setEditable(false)
        }
      }
      ur_forceRerender()
    }
    if (workflowKey != null) {
      us_setEditable(false)
      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskWorkflowProd_Document(clientKey, workflowKey), updateLiveData)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, workflowKey, taskKey, clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskData(newData)
      if (newData.status_complete === true) {
        us_setEditable(false)
      }
      ur_forceRerender()
    }
    if (workflowKey == null) {
      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Task_Document(clientKey, taskKey), updateLiveData)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, workflowKey, taskKey, clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskForm(newData)
      ur_forceRerender()
    }
    if (us_taskData != null && us_taskData.associated_task_form_key) {
      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskFormProdPages_Document(clientKey, us_taskData.associated_task_form_key), updateLiveData)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, workflowKey, taskKey, clientKey, us_taskData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskFormData(newData)
      ur_forceRerender()
    }
    if (us_taskData != null && us_taskData.associated_project_key) {
      unsubscribeLiveData = DatabaseGetLiveDocument(
        DatabaseRef_ProjectTaskFormData_Document(clientKey, us_taskData.associated_project_key, taskKey),
        updateLiveData,
      )
    } else if (us_taskData != null && us_taskData.task_category === 'administration') {
      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_AdministrationTaskFormData_Document(clientKey, taskKey), updateLiveData)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, workflowKey, taskKey, clientKey, us_taskData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskMLImages(newData)
      ur_forceRerender()
    }
    if (us_taskData != null && us_taskData.associated_project_key) {
      unsubscribeLiveData = DatabaseGetLiveCollection(
        DatabaseRef_ProjectMLImageEvaluationsForTask_Query(clientKey, us_taskData.associated_project_key, taskKey),
        updateLiveData,
      )
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, workflowKey, taskKey, clientKey, us_taskData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskMLAggregateResults(newData)
      ur_forceRerender()
    }
    if (us_taskData != null && us_taskData.associated_project_key) {
      unsubscribeLiveData = DatabaseGetLiveCollection(
        DatabaseRef_ProjectMLAggregateEvaluations_Collection(clientKey, us_taskData.associated_project_key),
        updateLiveData,
      )
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, workflowKey, taskKey, clientKey, us_taskData])

  // Functions
  const checkIfTaskSubmissionShouldBeDisabled = (
    task: TsInterface_UnspecifiedObject,
    taskForm: TsInterface_UnspecifiedObject,
    taskFormData: TsInterface_UnspecifiedObject,
  ): boolean => {
    let disabled = false
    if (task.status_complete === true) {
      disabled = true
    } else if (taskForm != null && taskForm.pages != null) {
      for (let loopPageKey in taskForm.pages) {
        let loopPage = taskForm.pages[loopPageKey]
        if (loopPage != null && loopPage['page_type'] === 'form' && loopPage['questions'] != null) {
          for (let loopQuestionKey in loopPage['questions']) {
            let loopQuestion = loopPage['questions'][loopQuestionKey]
            if (loopQuestion != null && loopQuestion['required'] === true) {
              if (
                taskFormData == null ||
                taskFormData[loopPageKey] == null ||
                taskFormData[loopPageKey][loopQuestionKey] == null ||
                taskFormData[loopPageKey][loopQuestionKey] === ''
              ) {
                disabled = true
              }
            }
          }
        } else if (loopPage != null && loopPage['page_type'] === 'file_uploads' && loopPage['folders'] != null) {
          for (let loopFolderKey in loopPage['folders']) {
            let loopFolder = loopPage['folders'][loopFolderKey]
            if (loopFolder != null && loopFolder['require_min_file_upload'] === true && loopFolder['min_file_upload_count'] != null) {
              let uploadedCount = 0
              if (
                taskFormData != null &&
                taskFormData[loopPageKey] != null &&
                taskFormData[loopPageKey]['folders'] != null &&
                taskFormData[loopPageKey]['folders'][loopFolderKey] != null
              ) {
                uploadedCount = taskFormData[loopPageKey]['folders'][loopFolderKey].length
              }
              if (loopFolder['require_min_file_upload'] === true && loopFolder['min_file_upload_count'] != null) {
                if (loopFolder['min_file_upload_count'] > uploadedCount) {
                  disabled = true
                }
              }
            }
          }
        } else if (loopPage != null && loopPage['page_type'] === 'image_uploads' && loopPage['folders'] != null) {
          for (let loopFolderKey in loopPage['folders']) {
            let loopFolder = loopPage['folders'][loopFolderKey]
            if (loopFolder != null && loopFolder['require_min_file_upload'] === true && loopFolder['min_file_upload_count'] != null) {
              let uploadedCount = 0
              if (
                taskFormData != null &&
                taskFormData[loopPageKey] != null &&
                taskFormData[loopPageKey]['folders'] != null &&
                taskFormData[loopPageKey]['folders'][loopFolderKey] != null
              ) {
                uploadedCount = taskFormData[loopPageKey]['folders'][loopFolderKey].length
              }
              if (loopFolder['require_min_file_upload'] === true && loopFolder['min_file_upload_count'] != null) {
                if (loopFolder['min_file_upload_count'] > uploadedCount) {
                  disabled = true
                }
              }
            }
          }
        }
      }
    }
    return disabled
  }

  const completeTask = (
    clientKey: string,
    task: TsInterface_UnspecifiedObject,
    uc_setUserInterface_ConfirmDialogDisplay: any,
    uc_setUserInterface_CustomDialogDisplay: any,
  ): void => {
    // Confirm
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'info',
        header: s_COMPLETE_TASK,
        icon: (
          <Icon
            icon="circle-check"
            type="solid"
          />
        ),
        submit_text: s_COMPLETE,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_COMPLETE_THIS_TASK,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            cloudFunctionManageRequest('manageTasks', {
              function: 'completeTask',
              client_key: clientKey,
              task_key: task.key,
            })
              .then((res_CFMTR: any) => {
                uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                resolve(res_CFMTR)
              })
              .catch((rej_CFMTR) => {
                reject(rej_CFMTR)
              })
          })
        },
      },
    })
  }

  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(file)
    })
  }

  const fileOnSelect = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: TsType_UnknownPromise[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (file != null && typeof file === 'object') {
            promiseArray.push(
              readAsDataURL(file)
                .then((res_RADURL) => {
                  readFiles[fileIndex] = {
                    file_name: file.name,
                    file: file,
                    data_url: res_RADURL,
                  }
                })
                .catch((rej_RADURL) => {
                  // Nothing
                }),
            )
          }
        }
        Promise.all(promiseArray).finally(() => {
          importTaskFiles(
            additionalFileUploadParams.clientKey,
            additionalFileUploadParams.userName,
            additionalFileUploadParams.projectKey,
            additionalFileUploadParams.taskCategory,
            additionalFileUploadParams.taskKey,
            additionalFileUploadParams.page,
            additionalFileUploadParams.pageKey,
            additionalFileUploadParams.folderKey,
            additionalFileUploadParams.folder,
            readFiles,
          )
            .then((res_ITF) => {
              resolve(res_ITF)
            })
            .catch((rej_ITF) => {
              reject(rej_ITF)
            })
        })
      } else {
        reject({
          success: false,
          error: {
            message: s_FAILED_TO_UPLOAD_FILE,
            details: s_INVALID_DOCUMENT_SELECTION,
            code: 'ER-D-TFS-FOS-02',
          },
        })
      }
    })
  }

  const runMLAnalysisOnFolder = (
    clientKey: string,
    projectKey: string,
    taskKey: string,
    pageKey: string,
    folderKey: string,
    taskFormData: TsInterface_UnspecifiedObject,
  ): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // If all the images have not been analyzed
      let imageArray: TsInterface_UnspecifiedObject[] = []
      if (
        taskKey != null &&
        pageKey != null &&
        folderKey != null &&
        taskFormData != null &&
        taskFormData[pageKey] != null &&
        taskFormData[pageKey]['folders'] != null &&
        taskFormData[pageKey]['folders'][folderKey] != null
      ) {
        for (let loopImageKey in taskFormData[pageKey]['folders'][folderKey]) {
          let loopImage = taskFormData[pageKey]['folders'][folderKey][loopImageKey]
          if (loopImage != null && loopImage.key != null && loopImage.url != null) {
            let analysisAlreadyRan = false
            if (
              us_taskMLImages != null &&
              us_taskMLImages[loopImage.key] != null &&
              us_taskMLImages[loopImage.key]['task_folder_model_mapping'] != null &&
              us_taskMLImages[loopImage.key]['task_folder_model_mapping'][taskKey] != null &&
              us_taskMLImages[loopImage.key]['task_folder_model_mapping'][taskKey][pageKey] != null &&
              us_taskMLImages[loopImage.key]['task_folder_model_mapping'][taskKey][pageKey][folderKey] != null
            ) {
              analysisAlreadyRan = true
            }
            if (analysisAlreadyRan === false) {
              imageArray.push({
                key: loopImage.key,
                url: loopImage.url,
              })
            }
          }
        }
      }
      if (imageArray.length > 0) {
        // Run ML Analysis on Images
        us_setRunningMLAnalysis(true)
        cloudFunctionManageRequest('manageMLFunctions', {
          function: 'runImagesAnalysis',
          client_key: clientKey,
          project_key: projectKey,
          task_key: taskKey,
          task_page_key: pageKey,
          task_folder_key: folderKey,
          image_url_array: imageArray, // [ { "key": "$imageKey", "url": "$imageUrl"}, ... ]
          show_logs: true,
        })
          .then((res_CFMMLFR) => {
            cloudFunctionManageRequest('manageMLFunctions', {
              function: 'runAggregateAnalysis',
              client_key: clientKey,
              project_key: projectKey,
              task_key: taskKey,
              task_page_key: pageKey,
              task_folder_key: folderKey,
            })
              .then((res_CFMMLFR) => {
                us_setRunningMLAnalysis(false)
                resolve(res_CFMMLFR)
              })
              .catch((rej_CFMMLFR) => {
                us_setRunningMLAnalysis(false)
                reject(rej_CFMMLFR)
              })
          })
          .catch((rej_CFMMLFR) => {
            us_setRunningMLAnalysis(false)
            reject(rej_CFMMLFR)
          })
      } else {
        us_setRunningMLAnalysis(true)
        cloudFunctionManageRequest('manageMLFunctions', {
          function: 'runAggregateAnalysis',
          client_key: clientKey,
          project_key: projectKey,
          task_key: taskKey,
          task_page_key: pageKey,
          task_folder_key: folderKey,
        })
          .then((res_CFMMLFR) => {
            us_setRunningMLAnalysis(false)
            resolve(res_CFMMLFR)
          })
          .catch((rej_CFMMLFR) => {
            us_setRunningMLAnalysis(false)
            reject(rej_CFMMLFR)
          })
      }
    })
  }

  const taskFormPageSubmission = (
    formSubmittedData: TsInterface_FormSubmittedData,
    formAdditionalData: TsInterface_FormAdditionalData,
    formHooks: TsInterface_FormHooksObject,
  ): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      console.log('taskFormPageSubmission', formSubmittedData, formAdditionalData)
      if (
        formAdditionalData != null &&
        formAdditionalData['clientKey'] != null &&
        formAdditionalData['taskKey'] != null &&
        formAdditionalData['pageKey'] != null &&
        formSubmittedData != null
      ) {
        if (formAdditionalData['projectKey'] && (formAdditionalData['taskCategory'] == null || formAdditionalData['taskCategory'] === 'project')) {
          let taskFormDataUpdateObject: TsInterface_UnspecifiedObject = {}
          taskFormDataUpdateObject[formAdditionalData['pageKey'] as string] = formSubmittedData
          let taskUpdateObject: TsInterface_UnspecifiedObject = {
            timestamp_last_updated: new Date(),
          }
          taskUpdateObject['timestamp_' + formAdditionalData['pageKey'] + '_completed'] = new Date()
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_ProjectTaskFormData_Document(
                formAdditionalData['clientKey'] as string,
                formAdditionalData['projectKey'] as string,
                formAdditionalData['taskKey'] as string,
              ),
              data: taskFormDataUpdateObject,
            },
            {
              type: 'setMerge',
              ref: DatabaseRef_Task_Document(formAdditionalData['clientKey'] as string, formAdditionalData['taskKey'] as string),
              data: taskUpdateObject,
            },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
              reject(rej_DBU)
            })
        } else if (formAdditionalData['taskCategory'] === 'administration') {
          let taskUpdateObject: TsInterface_UnspecifiedObject = {
            timestamp_last_updated: new Date(),
          }
          taskUpdateObject['timestamp_' + formAdditionalData['pageKey'] + '_completed'] = new Date()
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_Task_Document(formAdditionalData['clientKey'] as string, formAdditionalData['taskKey'] as string),
              data: taskUpdateObject,
            },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
              reject(rej_DBU)
            })
        }
      } else {
        let error = {
          message: s_FAILED_TO_SUBMIT_PROJECT_PAGE,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TFS-TFPS-01',
        }
        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
        reject({ success: false, error: error })
      }
    })
  }

  const taskUploadPageSubmission = (clientKey: string, taskKey: string, pageKey: string, taskData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      console.log('taskUploadPageSubmission', clientKey, taskKey, pageKey, taskData)
      if (clientKey != null && taskKey != null && pageKey != null) {
        let taskUpdateObject: TsInterface_UnspecifiedObject = {
          timestamp_last_updated: new Date(),
        }
        taskUpdateObject['timestamp_' + pageKey + '_completed'] = new Date()
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          {
            type: 'setMerge',
            ref: DatabaseRef_Task_Document(clientKey, taskKey),
            data: taskUpdateObject,
          },
        ]
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            // taskData[ "timestamp_" + pageKey + "_completed" ] = taskUpdateObject[ "timestamp_" + pageKey + "_completed" ]
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      } else {
        let error = {
          message: s_FAILED_TO_SUBMIT_PROJECT_PAGE,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TFS-TUPS-01',
        }
        reject({ success: false, error: error })
      }
    })
  }

  // JSX Generation
  const rJSX_ImagePreviewDialog = (): JSX.Element => {
    let dialogJSX = <></>

    let imageJSX = <></>
    if (
      us_openImagePreviewData != null &&
      us_openImagePreviewData.key != null &&
      us_taskMLImages != null &&
      us_openImagePageKey != null &&
      us_openImageFolderKey != null &&
      us_taskMLImages[us_openImagePreviewData.key] != null &&
      us_taskMLImages[us_openImagePreviewData.key]['image_url'] != null &&
      us_taskMLImages[us_openImagePreviewData.key]['task_folder_model_mapping'] != null &&
      us_taskMLImages[us_openImagePreviewData.key]['task_folder_model_mapping'][taskKey] != null &&
      us_taskMLImages[us_openImagePreviewData.key]['task_folder_model_mapping'][taskKey][us_openImagePageKey] != null &&
      us_taskMLImages[us_openImagePreviewData.key]['task_folder_model_mapping'][taskKey][us_openImagePageKey][us_openImageFolderKey] != null &&
      us_taskMLImages[us_openImagePreviewData.key]['model_results'] != null &&
      us_taskMLImages[us_openImagePreviewData.key]['model_results'][
        us_taskMLImages[us_openImagePreviewData.key]['task_folder_model_mapping'][taskKey][us_openImagePageKey][us_openImageFolderKey]
      ] != null &&
      us_taskMLImages[us_openImagePreviewData.key]['model_results'][
        us_taskMLImages[us_openImagePreviewData.key]['task_folder_model_mapping'][taskKey][us_openImagePageKey][us_openImageFolderKey]
      ]['model_output'] != null
    ) {
      imageJSX = (
        <YoloObjectDetectionPreview
          imageURL={us_taskMLImages[us_openImagePreviewData.key]['image_url']}
          boundingBoxes={us_taskMLImages[us_openImagePreviewData.key]['model_results'][
            us_taskMLImages[us_openImagePreviewData.key]['task_folder_model_mapping'][taskKey][us_openImagePageKey][us_openImageFolderKey]
          ]['model_output'].sort(dynamicSort('confidence', 'desc'))}
          maxWidth={'100%'}
          autoHeight={true}
          showControlButtons={true}
          startWithCategoryLabels={true}
          startWithBoundingLabels={true}
        />
      )
    } else {
      imageJSX = (
        <YoloObjectDetectionPreview
          imageURL={us_openImagePreviewData.url}
          boundingBoxes={[]}
          maxWidth={'100%'}
          autoHeight={true}
          showControlButtons={false}
          startWithCategoryLabels={true}
          startWithBoundingLabels={true}
        />
      )
    }

    // Dialog JSX
    dialogJSX = (
      <Dialog
        className="bp_dialog_lg_width"
        keepMounted
        onClose={() => {
          us_setOpenImagePreview(false)
          us_setOpenImagePreviewData({})
          us_setOpenImagePageKey(null)
          us_setOpenImageFolderKey(null)
        }}
        open={us_openImagePreview}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 2, color: '#fff !important' }}
            >
              <Icon icon="image" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              {s_IMAGE_PREVIEW}
            </Typography>
            <IconButton
              aria-label="menu"
              color="inherit"
              edge="start"
              size="large"
              className="tw-float-right tw-opacity-20 hover:tw-opacity-100 tw-cursor-pointer"
              onClick={() => {
                window.open(us_openImagePreviewData.url as string, '_blank')
              }}
            >
              <Icon
                icon="arrow-up-right-from-square"
                type="regular"
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box className="tw-p-2">{imageJSX}</Box>
        </DialogContent>
      </Dialog>
    )
    return dialogJSX
  }

  const rJSX_ImagePreview = (file: TsInterface_UnspecifiedObject): JSX.Element => {
    let imageJSX = <></>
    if (file != null && file.name.endsWith('.heic')) {
      imageJSX = (
        <Box
          sx={{
            width: '60px',
            height: '40px',
            border: '1px solid ' + themeVariables.background_json,
            borderRadius: '5px',
          }}
          className="tw-opacity-40 tw-p-1"
        >
          .heic
        </Box>
      )
    } else {
      imageJSX = (
        <img
          src={file.url}
          alt={file.name}
          loading="lazy"
          width={'60px'}
        />
      )
    }
    return imageJSX
  }

  const rJSX_RunMLAnalysisButton = (
    folder: TsInterface_UnspecifiedObject,
    file: TsInterface_UnspecifiedObject,
    projectKey: string,
    pageKey: string,
    folderKey: string,
  ): JSX.Element => {
    let buttonJSX = <></>
    if (us_runningMLAnalysis === true) {
      buttonJSX = (
        <Box className="tw-ml-2 tw-inline-block tw-opacity-30">
          <Icon icon="wand-magic-sparkles"></Icon>
        </Box>
      )
    } else {
      buttonJSX = (
        <Box
          className="tw-ml-2 tw-inline-block"
          onClick={(event) => {
            event.stopPropagation()
            console.log(file)
            us_setRunningMLAnalysis(true)
            let imageArray = [
              {
                key: file.key,
                url: file.url,
              },
            ]
            cloudFunctionManageRequest('manageMLFunctions', {
              function: 'runImagesAnalysis',
              client_key: clientKey,
              project_key: projectKey,
              task_key: taskKey,
              task_page_key: pageKey,
              task_folder_key: folderKey,
              image_url_array: imageArray, // [ { "key": "$imageKey", "url": "$imageUrl"}, ... ]
              show_logs: true,
            })
              .then((res_CFMMLFR) => {
                console.log(res_CFMMLFR)
                us_setRunningMLAnalysis(false)
              })
              .catch((rej_CFMMLFR) => {
                console.error(rej_CFMMLFR)
                us_setRunningMLAnalysis(false)
              })
          }}
        >
          <Icon icon="wand-magic-sparkles"></Icon>
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_MLAnalysisData = (
    folder: TsInterface_UnspecifiedObject,
    file: TsInterface_UnspecifiedObject,
    projectKey: string,
    pageKey: string,
    folderKey: string,
  ): JSX.Element => {
    let mlAnalysisJSX = <></>
    if (folder['run_ml_evaluation_procedure_on_images'] === true && folder['associated_ml_evaluation_procedure_key'] != null) {
      let mlAnalysisComplete: boolean = false
      let modelKey = null
      let boundingBoxListJSX = <></>
      let timestampCompleteJSX = <></>
      if (us_taskMLImages != null && us_taskMLImages[file.key] != null) {
        if (
          us_taskMLImages[file.key]['task_folder_model_mapping'] != null &&
          us_taskMLImages[file.key]['task_folder_model_mapping'][taskKey] != null &&
          us_taskMLImages[file.key]['task_folder_model_mapping'][taskKey][pageKey] != null &&
          us_taskMLImages[file.key]['task_folder_model_mapping'][taskKey][pageKey][folderKey] != null
        ) {
          modelKey = us_taskMLImages[file.key]['task_folder_model_mapping'][taskKey][pageKey][folderKey]
          mlAnalysisComplete = true
        }
        if (
          modelKey != null &&
          us_taskMLImages[file.key]['model_results'] != null &&
          us_taskMLImages[file.key]['model_results'][modelKey] != null &&
          us_taskMLImages[file.key]['model_results'][modelKey]['timestamp_evaluation_completed'] != null
        ) {
          timestampCompleteJSX = (
            <Box className="tw-ml-1 tw-opacity-20 tw-inline-block">
              ({returnFormattedDate(us_taskMLImages[file.key]['model_results'][modelKey]['timestamp_evaluation_completed'], 'D MMM YYYY h:mm a')})
            </Box>
          )
          if (
            us_taskMLImages[file.key]['model_results'][modelKey]['model_output'] != null &&
            us_taskMLImages[file.key]['model_results'][modelKey]['model_output'].length > 0
          ) {
            let boundingBoxes = us_taskMLImages[file.key]['model_results'][modelKey]['model_output']
            boundingBoxListJSX = (
              <Box className="tw-ml-6">
                <Box sx={{ color: themeVariables.white }}>
                  <Box
                    className="tw-inline-block"
                    sx={{ color: themeVariables.secondary_main }}
                  >
                    <Icon
                      icon="brackets-square"
                      className="tw-mr-2"
                      type="regular"
                    />
                  </Box>
                  {objectToArray(boundingBoxes)
                    .sort(dynamicSort('confidence', 'desc'))
                    .map((box: TsInterface_UnspecifiedObject, index: number) => (
                      <Box
                        key={index}
                        className="tw-inline-block tw-mr-1"
                      >
                        <Box
                          className="tw-inline-block"
                          sx={{ color: themeVariables.secondary_main }}
                        >
                          {box.category_string}
                        </Box>
                        <Box className="tw-inline-block tw-ml-1 tw-opacity-20">({(box.confidence * 100).toFixed(1)}%)</Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            )
          } else {
            boundingBoxListJSX = (
              <Box className="tw-ml-6">
                <Box sx={{ color: themeVariables.white }}>
                  <Box
                    className="tw-inline-block tw-opacity-20"
                    sx={{ color: themeVariables.white }}
                  >
                    <Icon
                      icon="brackets-square"
                      className="tw-mr-2"
                      type="regular"
                    />
                  </Box>
                  <Box className="tw-inline-block tw-opacity-20">{s_NO_TARGET_OBJECTS_DETECTED}</Box>
                </Box>
              </Box>
            )
          }
        }
      }
      if (mlAnalysisComplete === true) {
        mlAnalysisJSX = (
          <Box>
            <Box
              sx={{ color: themeVariables.success_main }}
              className="tw-inline-block"
            >
              <Icon
                icon="robot"
                className="tw-mr-2"
              />
              {s_ML_ANALYSIS_COMPLETE}
            </Box>
            {timestampCompleteJSX}
            {boundingBoxListJSX}
          </Box>
        )
      } else {
        mlAnalysisJSX = (
          <Box>
            <Box sx={{ opacity: 0.2 }}>
              <Icon
                icon="robot"
                className="tw-mr-2"
              />
              {s_ML_ANALYSIS_NOT_COMPLETE}
              {rJSX_RunMLAnalysisButton(folder, file, projectKey, pageKey, folderKey)}
            </Box>
          </Box>
        )
      }
    }
    return mlAnalysisJSX
  }

  const rJSX_DeleteImageButton = (
    clientKey: string,
    projectKey: string,
    taskKey: string,
    pageKey: string,
    folderKey: string,
    taskFormData: TsInterface_UnspecifiedObject,
    filesList: TsInterface_UnspecifiedObject[],
    file: TsInterface_UnspecifiedObject,
    fileIndex: number,
  ): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        size="small"
        variant="outlined"
        color="error"
        className="tw-mr-2 tw-mb-2"
        onClick={(event) => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash-xmark" />,
              header: s_DELETE_IMAGE,
              text: <>{s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_IMAGE}</>,
              submit_text: s_DELETE,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  if (
                    taskFormData != null &&
                    taskFormData[pageKey] != null &&
                    taskFormData[pageKey]['folders'] != null &&
                    taskFormData[pageKey]['folders'][folderKey] != null
                  ) {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [pageKey]: {
                        folders: {
                          [folderKey]: removeItemAtIndex(filesList, fileIndex),
                        },
                      },
                    }
                    DatabaseSetMergeDocument(DatabaseRef_ProjectTaskFormData_Document(clientKey, projectKey, taskKey), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: rej_DSMD.error,
                        })
                        reject(rej_DSMD)
                      })
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: s_FAILED_TO_DELETE_IMAGE,
                        details: s_UNSUPPORTED_DATA_STRUCTURE,
                        code: 'ER-D-TFS-DI-01',
                      },
                    })
                  }
                })
              },
            },
          })
        }}
      >
        <Icon
          icon="trash"
          type="light"
          sx={{ fontSize: '20px' }}
          className="tw-mr-2"
        />
        {s_DELETE_IMAGE}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_MoveImageButton = (
    clientKey: string,
    projectKey: string,
    taskKey: string,
    page: TsInterface_UnspecifiedObject,
    pageKey: string,
    folderKey: string,
    taskFormData: TsInterface_UnspecifiedObject,
    filesList: TsInterface_UnspecifiedObject[],
    file: TsInterface_UnspecifiedObject,
    fileIndex: number,
  ): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        size="small"
        variant="outlined"
        color="info"
        className="tw-mr-2 tw-mb-2"
        onClick={(event) => {
          // Get new Page and Folder
          let imagePages: TsInterface_UnspecifiedObject[] = []
          for (let loopPageKey in us_taskForm.pages) {
            let loopPage = us_taskForm.pages[loopPageKey]
            if (loopPage != null && loopPage['page_type'] === 'image_uploads') {
              imagePages.push({ key: loopPage.key, value: loopPage.name })
            }
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  associated_page: {
                    key: 'associated_page',
                    label: s_PAGE,
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    options: imagePages,
                  },
                  associated_folder: {
                    data_type: 'string',
                    key: 'associated_folder',
                    input_type: 'multiple_choice_radio',
                    label: s_FOLDER,
                    required: true,
                    options: [],
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {
                  let newPageKey = formData['associated_page']
                  let folderOtions: TsInterface_UnspecifiedObject[] = []
                  if (
                    newPageKey != null &&
                    us_taskForm != null &&
                    us_taskForm.pages != null &&
                    us_taskForm.pages[newPageKey as string] != null &&
                    us_taskForm.pages[newPageKey as string]['folders'] != null
                  ) {
                    for (let loopFolderKey in us_taskForm.pages[newPageKey as string]['folders']) {
                      let loopFolder = us_taskForm.pages[newPageKey as string]['folders'][loopFolderKey]
                      if (loopFolder != null) {
                        if (newPageKey === pageKey && loopFolderKey === folderKey) {
                          folderOtions.push({
                            key: loopFolderKey,
                            value: loopFolder.name,
                            disabled: true,
                          })
                        } else {
                          folderOtions.push({
                            key: loopFolderKey,
                            value: loopFolder.name,
                          })
                        }
                      }
                    }
                  }
                  formInputs['associated_folder']['options'] = folderOtions
                },
                formSettings: {
                  // submit_button_theme: "primary",
                  // submit_button_alignment: "right",
                  // submit_button_hide: false,
                  // submit_button_icon: <Icon icon="right-to-bracket"/>,
                  // submit_button_saving_icon: true,
                  // submit_button_text: s_MOVE_IMAGE,
                },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    // Delete Image and Add Image to new Folder Location
                    if (
                      taskFormData != null &&
                      taskFormData[pageKey] != null &&
                      taskFormData[pageKey]['folders'] != null &&
                      taskFormData[pageKey]['folders'][folderKey] != null
                    ) {
                      // Remove Image from Current Folder
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [pageKey]: {
                          folders: {
                            [folderKey]: removeItemAtIndex(filesList, fileIndex),
                          },
                        },
                      }
                      // Add image to new folder
                      let newPageKey = formSubmittedData['associated_page']
                      let newFolderKey = formSubmittedData['associated_folder']
                      if (newPageKey != null && newFolderKey != null) {
                        if (updateObject[newPageKey] == null) {
                          updateObject[newPageKey] = { folders: {} }
                        }
                        if (
                          taskFormData != null &&
                          taskFormData[newPageKey] != null &&
                          taskFormData[newPageKey]['folders'] != null &&
                          taskFormData[newPageKey]['folders'][newFolderKey] != null
                        ) {
                          updateObject[newPageKey]['folders'][newFolderKey] = objectToArray({ ...taskFormData[newPageKey]['folders'][newFolderKey] })
                        } else {
                          updateObject[newPageKey]['folders'][newFolderKey] = []
                        }
                        updateObject[newPageKey]['folders'][newFolderKey].push(file)
                        try {
                          DatabaseSetMergeDocument(DatabaseRef_ProjectTaskFormData_Document(clientKey, projectKey, taskKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({
                                display: true,
                                error: rej_DSMD.error,
                              })
                              reject(rej_DSMD)
                            })
                        } catch (err) {
                          console.error(err)
                          let error = {
                            message: s_FAILED_TO_DELETE_IMAGE,
                            details: rLIB('An Unexpected Error has Occurred'),
                            code: 'ER-D-TFS-DI-01',
                          }
                          reject({
                            success: false,
                            error: error,
                          })
                          uc_setUserInterface_ErrorDialogDisplay({
                            display: true,
                            error: error,
                          })
                        }
                      } else {
                        let error = {
                          message: s_FAILED_TO_DELETE_IMAGE,
                          details: s_MISSING_REQUIRED_PARAMETERS,
                          code: 'ER-D-TFS-DI-02',
                        }
                        reject({
                          success: false,
                          error: error,
                        })
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: error,
                        })
                      }
                    } else {
                      let error = {
                        message: s_FAILED_TO_DELETE_IMAGE,
                        details: s_UNSUPPORTED_DATA_STRUCTURE,
                        code: 'ER-D-TFS-DI-03',
                      }
                      reject({
                        success: false,
                        error: error,
                      })
                      uc_setUserInterface_ErrorDialogDisplay({
                        display: true,
                        error: error,
                      })
                    }
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'info',
                formDialogHeaderText: s_MOVE_IMAGE,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="arrows-left-right"
                  />
                ),
              },
            },
          })
        }}
      >
        <Icon
          icon="arrows-left-right"
          type="light"
          sx={{ fontSize: '20px' }}
          className="tw-mr-2"
        />
        {s_MOVE_IMAGE}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ImageTagsEditor = (
    clientKey: string,
    projectKey: string,
    taskKey: string,
    page: TsInterface_UnspecifiedObject,
    pageKey: string,
    folderKey: string,
    taskFormData: TsInterface_UnspecifiedObject,
    filesList: TsInterface_UnspecifiedObject[],
    file: TsInterface_UnspecifiedObject,
    fileIndex: number,
    formControlSX: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let checkboxesJSX = <></>
    let availableImageTags = returnImageTags(clientKey)
    checkboxesJSX = (
      <Box>
        {rJSX_DeleteImageButton(clientKey, projectKey, taskKey, pageKey, folderKey, taskFormData, filesList, file, fileIndex)}
        {rJSX_MoveImageButton(clientKey, projectKey, taskKey, page, pageKey, folderKey, taskFormData, filesList, file, fileIndex)}
        <FormControl>
          <FormGroup sx={formControlSX}>
            {availableImageTags.sort().map((tag: string, index: number) => (
              <FormControlLabel
                sx={{ display: 'inline-block' }}
                key={index}
                control={
                  <Checkbox
                    color="warning"
                    checked={checkIfTagIsChecked(tag, file)}
                    onChange={() => {
                      let isTagChecked = checkIfTagIsChecked(tag, file)
                      let updateArray: string[] = []
                      if (file.tags != null) {
                        updateArray = file.tags
                      } else {
                        updateArray = []
                      }
                      if (isTagChecked === true) {
                        let searchIndex = updateArray.indexOf(tag)
                        if (index > -1) {
                          updateArray.splice(searchIndex, 1)
                        }
                      } else {
                        updateArray.push(tag)
                      }
                      editImageTags(clientKey, projectKey, taskKey, pageKey, folderKey, fileIndex, taskFormData, updateArray)
                    }}
                  />
                }
                label={tag}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
    )
    return checkboxesJSX
  }

  const rJSX_ImageAccordian = (
    clientKey: string,
    projectKey: string,
    taskKey: string,
    page: TsInterface_UnspecifiedObject,
    pageKey: string,
    folder: TsInterface_UnspecifiedObject,
    folderKey: string,
    taskFormData: TsInterface_UnspecifiedObject,
    filesList: TsInterface_UnspecifiedObject[],
    file: TsInterface_UnspecifiedObject,
    fileIndex: number,
  ): JSX.Element => {
    let accordianJSX = (
      <Accordion sx={{ borderBottom: '1px solid ' + themeVariables.background_paper }}>
        <AccordionSummary
          expandIcon={<Icon icon="angle-down" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ display: 'block', width: '100%' }}>
            <Box
              className="tw-inline-block tw-mr-2 tw-mt-2 tw-align-top"
              sx={{ width: '70px' }}
              onClick={(event) => {
                event.stopPropagation()
                // if( event.detail === 2 ){ // Double Click
                // window.open(file.url as string, '_blank');
                us_setOpenImagePreview(true)
                us_setOpenImagePreviewData(file)
                us_setOpenImagePageKey(pageKey)
                us_setOpenImageFolderKey(folderKey)
                // }
              }}
            >
              {rJSX_ImagePreview(file)}
            </Box>
            <Box
              className="tw-inline-block"
              sx={{ width: 'calc( 100% - 80px )' }}
            >
              <Box>
                <Box className="tw-inline-block tw-mr-2">{file.name}</Box>
              </Box>
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              >
                <Icon
                  icon="tags"
                  className="tw-mr-2"
                />
                {getProp(file, 'tags', []).join(', ')}
              </Box>
              {rJSX_MLAnalysisData(folder, file, projectKey, pageKey, folderKey)}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ background: themeVariables.background_json }}>
          {rJSX_ImageTagsEditor(clientKey, projectKey, taskKey, page, pageKey, folderKey, taskFormData, filesList, file, fileIndex, {
            display: 'block',
            paddingLeft: '0px',
          })}
        </AccordionDetails>
      </Accordion>
    )
    return accordianJSX
  }

  const rJSX_ImagesUploaded = (
    clientKey: string,
    projectKey: string,
    taskKey: string,
    page: TsInterface_UnspecifiedObject,
    pageKey: string,
    folder: TsInterface_UnspecifiedObject,
    folderKey: string,
    taskFormData: TsInterface_UnspecifiedObject,
    uc_setUserInterface_CustomDialogDisplay: any,
  ): JSX.Element => {
    let filesJSX = <></>
    let filesList: TsInterface_UnspecifiedObject[] = []
    if (
      pageKey != null &&
      folderKey != null &&
      taskFormData != null &&
      taskFormData[pageKey] != null &&
      taskFormData[pageKey]['folders'] != null &&
      taskFormData[pageKey]['folders'][folderKey] != null
    ) {
      // filesList = taskFormData[pageKey]["folders"][folderKey].sort( dynamicSort("name", "asc" ))
      filesList = taskFormData[pageKey]['folders'][folderKey]
    }

    // TODO - if heic show preview square thing?

    // JSX
    filesJSX = (
      <Box className="tw-mb-2">
        {filesList.map((file: TsInterface_UnspecifiedObject, fileIndex: number) => (
          <Box key={fileIndex}>
            {rJSX_ImageAccordian(clientKey, projectKey, taskKey, page, pageKey, folder, folderKey, taskFormData, filesList, file, fileIndex)}
          </Box>
        ))}
      </Box>
    )
    return filesJSX
  }

  const rJSX_FilesUploaded = (pageKey: string, folderKey: string, taskFormData: TsInterface_UnspecifiedObject): JSX.Element => {
    let filesJSX = <></>
    filesJSX = <Json data={taskFormData} />
    let filesList: TsInterface_UnspecifiedObject[] = []
    if (
      pageKey != null &&
      folderKey != null &&
      taskFormData != null &&
      taskFormData[pageKey] != null &&
      taskFormData[pageKey]['folders'] != null &&
      taskFormData[pageKey]['folders'][folderKey] != null
    ) {
      filesList = taskFormData[pageKey]['folders'][folderKey]
    }
    const returnFileTypeIcon = (fileName: string | null): JSX.Element => {
      let iconJSX = <></>
      let fileType = null
      if (fileName != null && fileName.indexOf('.') > -1) {
        fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)
      }
      iconJSX = (
        <Box
          sx={{ color: returnIconKeyForFileType(fileType as string).color }}
          className="tw-my-1"
        >
          <Icon
            size="3x"
            icon={returnIconKeyForFileType(fileType as string).icon}
          />
        </Box>
      )
      return iconJSX
    }
    // JSX
    filesJSX = (
      <Box className="tw-mb-2">
        {filesList.sort(dynamicSort('name', 'asc')).map((file: TsInterface_UnspecifiedObject, fileIndex: number) => (
          <Box key={fileIndex}>
            <Box className="tw-inline-block tw-mr-2">{returnFileTypeIcon(file.name)}</Box>
            <Box className="tw-inline-block">
              {file.name}
              <Button
                sx={{ minWidth: '30px' }}
                className="tw-ml-2"
                variant="outlined"
                color="inherit"
                disabled={file == null || file.url == null}
                onClick={() => {
                  if (file != null && file.url != null) {
                    window.open(file.url as string, '_blank')
                  }
                }}
              >
                <Icon icon="magnifying-glass" />
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    )
    return filesJSX
  }

  const rJSX_ImageUploadTaskPreview = (
    clientKey: string,
    userName: string,
    projectKey: string,
    taskCategory: string,
    taskKey: string,
    page: TsInterface_UnspecifiedObject,
    taskFormData: TsInterface_UnspecifiedObject,
    editable: boolean,
    uc_setUserInterface_CustomDialogDisplay: any,
  ): JSX.Element => {
    let fileUploadJSX = (
      <Box>
        {objectToArray(page.folders)
          .sort(dynamicSort('order', 'asc'))
          .map((folder, folderIndex) => (
            <Box key={folderIndex}>
              {rJSX_ImageUploadFolder(
                clientKey,
                userName,
                projectKey,
                taskCategory,
                taskKey,
                page,
                page.key,
                folder,
                taskFormData,
                editable,
                uc_setUserInterface_CustomDialogDisplay,
              )}
              <Divider />
            </Box>
          ))}
      </Box>
    )
    return fileUploadJSX
  }

  const rJSX_NumberOfImagesAnalyzed = (
    filesList: any,
    modelResults: any,
    imagesIncludedInAggregateEvaluationCount: number,
    imagesNotIncludedInAggregateEvaluationCount: number,
    lastAggregateAnalysisTimestamp: number | null,
  ): JSX.Element => {
    let messageJSX = <></>

    let totalImagesCount = filesList.length
    let evaluatedImagesCount = objectToArray(modelResults).filter((photoResult) => photoResult.status === 'success').length

    let individualAnalysisTextJSX = <></>
    if (evaluatedImagesCount < totalImagesCount) {
      individualAnalysisTextJSX = (
        <Box
          className="tw-mb-1 tw-px-1 tw-rounded"
          sx={{ fontSize: '16px', background: themeVariables.warning_main }}
        >
          <Box className="tw-opacity-100">
            <Box className="tw-inline-block tw-mr-1">
              {evaluatedImagesCount} {s_OF} {totalImagesCount}
            </Box>
            {s_IMAGES_FINISHED_WITH_OBJECT_DETECTION}
          </Box>
        </Box>
      )
    } else {
      individualAnalysisTextJSX = (
        <Box
          className="tw-mb-1 tw-px-1 tw-rounded"
          sx={{ fontSize: '16px' }}
        >
          <Box className="tw-opacity-30">
            <Box className="tw-inline-block tw-mr-1">
              {evaluatedImagesCount} {s_OF} {totalImagesCount}
            </Box>
            {s_IMAGES_FINISHED_WITH_OBJECT_DETECTION}
          </Box>
        </Box>
      )
    }

    let aggregateAnalysisTextJSX = <></>
    if (imagesIncludedInAggregateEvaluationCount < totalImagesCount) {
      aggregateAnalysisTextJSX = (
        <Box
          className="tw-mb-1 tw-px-1 tw-rounded"
          sx={{ fontSize: '16px', background: themeVariables.warning_main }}
        >
          <Box className="tw-opacity-100">
            <Box className="tw-inline-block tw-mr-1">
              {imagesIncludedInAggregateEvaluationCount} {s_OF} {totalImagesCount}
            </Box>
            {s_IMAGES_INCLUDED_IN_AGGREGATE_ANALYSIS}
          </Box>
        </Box>
      )
    } else {
      aggregateAnalysisTextJSX = (
        <Box
          className="tw-mb-1 tw-px-1 tw-rounded"
          sx={{ fontSize: '16px' }}
        >
          <Box className="tw-opacity-30">
            <Box className="tw-inline-block tw-mr-1">
              {imagesIncludedInAggregateEvaluationCount} {s_OF} {totalImagesCount}
            </Box>
            {s_IMAGES_INCLUDED_IN_AGGREGATE_ANALYSIS}
          </Box>
        </Box>
      )
    }
    messageJSX = (
      <Box>
        {individualAnalysisTextJSX}
        {aggregateAnalysisTextJSX}
      </Box>
    )
    return messageJSX
  }

  const rJSX_toggleMLMessageTypes = (messageCount: number, visibleMessageCount: number): JSX.Element => {
    let toggleJSX = <></>
    if (us_showAggregateMessageType === 'errors') {
      toggleJSX = (
        <Box className="tw-mb-1 tw-px-1 tw-rounded">
          <Box
            className="tw-inline-block tw-opacity-30"
            sx={{ fontSize: '16px' }}
          >
            {s_SHOWING} {visibleMessageCount} {s_OF} {messageCount} {s_MESSAGES}
          </Box>
          <Tooltip
            title={s_VIEWING_ONLY_ERROR_MESSAGES}
            placement="right"
          >
            <Box
              className="tw-ml-2"
              component={'span'}
              onClick={() => {
                us_setShowAggregateMessageType('all')
              }}
            >
              <Icon
                icon="message-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_light }}
              />
            </Box>
          </Tooltip>
        </Box>
      )
    } else {
      toggleJSX = (
        <Box className="tw-mb-1 tw-px-1 tw-rounded">
          <Box
            className="tw-inline-block tw-opacity-30"
            sx={{ fontSize: '16px' }}
          >
            {s_SHOWING} {visibleMessageCount} {s_OF} {messageCount} {s_MESSAGES}
          </Box>
          <Tooltip
            title={s_VIEWING_ALL_MESSAGES}
            placement="right"
          >
            <Box
              className="tw-ml-2"
              component={'span'}
              onClick={() => {
                us_setShowAggregateMessageType('errors')
              }}
            >
              <Icon
                icon="message-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
            </Box>
          </Tooltip>
        </Box>
      )
    }
    return toggleJSX
  }

  const rJSX_ImageUploadFolder = (
    clientKey: string,
    userName: string,
    projectKey: string,
    taskCategory: string,
    taskKey: string,
    page: TsInterface_UnspecifiedObject,
    pageKey: string,
    folder: TsInterface_UnspecifiedObject,
    taskFormData: TsInterface_UnspecifiedObject,
    editable: boolean,
    uc_setUserInterface_CustomDialogDisplay: any,
  ): JSX.Element => {
    // Instantiate Variables
    let fileUploadTaskPreviewJSX = <></>
    let minimumFilesJSX = <></>
    let maximumFilesJSX = <></>
    let mlButtonJSX = <></>
    let mlAggregateResultsJSX = <></>
    let minUploadsTextJSX = s_UPLOADS
    let maxUploadsTextJSX = s_UPLOADS
    // JSX Generation
    if (folder['min_file_upload_count'] === 1) {
      minUploadsTextJSX = s_UPLOAD
    }
    if (folder['max_file_upload_count'] === 1) {
      maxUploadsTextJSX = s_UPLOAD
    }
    if (folder['run_ml_evaluation_procedure_on_images'] === true && folder['associated_ml_evaluation_procedure_key'] != null) {
      // Button JSX
      let mlButtonIconJSX = (
        <Icon
          icon="robot"
          className="tw-mr-2"
        />
      )
      if (us_runningMLAnalysis === true) {
        mlButtonIconJSX = (
          <Icon
            icon="arrows-rotate"
            className="bp_spin tw-mr-2"
          />
        )
      }

      let buttonVariant: 'contained' | 'outlined' = 'outlined'
      // If there are any images that have not been analyzed
      let mostRecentImageAnalysisTimestamp = 0
      let filesList: TsInterface_UnspecifiedObject[] = []
      if (
        pageKey != null &&
        folder != null &&
        folder.key != null &&
        taskFormData != null &&
        taskFormData[pageKey] != null &&
        taskFormData[pageKey]['folders'] != null &&
        taskFormData[pageKey]['folders'][folder.key] != null
      ) {
        filesList = taskFormData[pageKey]['folders'][folder.key]
      }
      let imageMLResults: TsInterface_UnspecifiedObject = {}
      let imagesIncludedInAggregateEvaluationCount = 0
      let imagesNotIncludedInAggregateEvaluationCount = 0
      let lastAggregateAnalysisTimestamp = null
      if (
        us_taskMLAggregateResults != null &&
        taskKey != null &&
        pageKey != null &&
        folder != null &&
        folder.key != null &&
        us_taskMLAggregateResults[taskKey + '_' + pageKey + '_' + folder.key] != null &&
        us_taskMLAggregateResults[taskKey + '_' + pageKey + '_' + folder.key].timestamp_evaluation_completed != null
      ) {
        lastAggregateAnalysisTimestamp = returnDateFromUnknownDateFormat(
          us_taskMLAggregateResults[taskKey + '_' + pageKey + '_' + folder.key].timestamp_evaluation_completed,
        ).getTime()
      }
      for (let fileKey in filesList) {
        let modelKey = null
        let file = filesList[fileKey]
        if (
          folder != null &&
          folder.key != null &&
          us_taskMLImages[file.key] != null &&
          us_taskMLImages[file.key]['task_folder_model_mapping'] != null &&
          us_taskMLImages[file.key]['task_folder_model_mapping'][taskKey] != null &&
          us_taskMLImages[file.key]['task_folder_model_mapping'][taskKey][pageKey] != null &&
          us_taskMLImages[file.key]['task_folder_model_mapping'][taskKey][pageKey][folder.key] != null
        ) {
          modelKey = us_taskMLImages[file.key]['task_folder_model_mapping'][taskKey][pageKey][folder.key]
        } else {
          buttonVariant = 'contained'
        }
        if (
          modelKey != null &&
          us_taskMLImages[file.key]['model_results'] != null &&
          us_taskMLImages[file.key]['model_results'][modelKey] != null &&
          us_taskMLImages[file.key]['model_results'][modelKey]['timestamp_evaluation_completed'] != null
        ) {
          imageMLResults[file.key] = us_taskMLImages[file.key]['model_results'][modelKey]
          let timestampCompleted = returnDateFromUnknownDateFormat(
            us_taskMLImages[file.key]['model_results'][modelKey]['timestamp_evaluation_completed'],
          ).getTime()
          if (timestampCompleted != null && timestampCompleted > mostRecentImageAnalysisTimestamp) {
            mostRecentImageAnalysisTimestamp = timestampCompleted
          }
          if (lastAggregateAnalysisTimestamp != null && lastAggregateAnalysisTimestamp >= timestampCompleted) {
            imagesIncludedInAggregateEvaluationCount++
          } else {
            imagesNotIncludedInAggregateEvaluationCount++
          }
        } else {
          imagesNotIncludedInAggregateEvaluationCount++
        }
      }
      // If there isn't an aggregate evaluation for this folder OR
      // If any image analysis dates are newer than the aggregate evaluation date
      if (lastAggregateAnalysisTimestamp != null && lastAggregateAnalysisTimestamp < mostRecentImageAnalysisTimestamp) {
        buttonVariant = 'contained'
      } else {
        buttonVariant = 'contained'
      }
      // JSX
      mlButtonJSX = (
        <Button
          className="tw-ml-2"
          color="secondary"
          variant={buttonVariant}
          disabled={us_runningMLAnalysis}
          onClick={() => {
            runMLAnalysisOnFolder(clientKey, projectKey, taskKey, pageKey, folder.key, taskFormData)
              .then((res_RMLAOF) => {
                uc_setUserInterface_SnackbarDisplay({
                  display: true,
                  snackbar: {
                    message: 'Analysis Complete',
                    alertType: 'success',
                    verticalAlignment: 'top',
                    horizontalAlignment: 'left',
                  },
                })
              })
              .catch((rej_RMLAOF) => {
                // uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_RMLAOF.error })
              })
          }}
        >
          {mlButtonIconJSX}
          {s_RUN_ML_ANALYSIS}
        </Button>
      )
      if (
        us_taskMLAggregateResults != null &&
        taskKey != null &&
        pageKey != null &&
        folder != null &&
        folder.key != null &&
        us_taskMLAggregateResults[taskKey + '_' + pageKey + '_' + folder.key] != null
      ) {
        // Generate Messages
        let messageCount = 0
        let visibleMessageCount = 0
        let evaluationMessages: TsInterface_UnspecifiedObject[] = []
        for (let loopMessageKey in us_taskMLAggregateResults[taskKey + '_' + pageKey + '_' + folder.key]['evaluation_messages']) {
          let loopMessage = us_taskMLAggregateResults[taskKey + '_' + pageKey + '_' + folder.key]['evaluation_messages'][loopMessageKey]
          if (loopMessage != null && loopMessage.message != null) {
            messageCount++
            let addMessageToList = false
            if (us_showAggregateMessageType === 'errors') {
              if (loopMessage.message_type === 'error') {
                addMessageToList = true
              }
            } else {
              addMessageToList = true
            }
            if (addMessageToList === true) {
              visibleMessageCount++
              let severity = 5
              let iconJSX = (
                <Box sx={{ opacity: 0.3, marginRight: '8px', display: 'inline-block' }}>
                  <Icon icon="circle-question" />
                </Box>
              )
              if (loopMessage.message_type != null && severityMessageTypes[loopMessage.message_type] != null) {
                severity = severityMessageTypes[loopMessage.message_type]['severity']
                iconJSX = severityMessageTypes[loopMessage.message_type]['icon']
              }
              evaluationMessages.push({
                message: loopMessage.message,
                severity: severity,
                iconJSX: iconJSX,
              })
            }
          }
        }
        // JSX
        let aggregateMessagesListJSX = <></>
        if (evaluationMessages.length > 0) {
          aggregateMessagesListJSX = (
            <Box>
              {sortByTwoProperties(evaluationMessages, 'severity', 'message').map((message: TsInterface_UnspecifiedObject, index: number) => (
                <Box
                  key={index}
                  className="tw-mt-2"
                >
                  {message.iconJSX}
                  {message.message}
                </Box>
              ))}
            </Box>
          )
        } else {
          aggregateMessagesListJSX = (
            <Typography
              variant="body1"
              className="tw-ml-8 tw-italic tw-opacity-20"
            >
              {s_NO_ERROR_OR_WARNING_MESSAGES}
            </Typography>
          )
        }
        mlAggregateResultsJSX = (
          <Box className="tw-py-2">
            <Box>
              <Typography
                variant="h6"
                sx={{ color: themeVariables.secondary_main }}
                className="tw-inline-block tw-mr-1"
              >
                <Icon
                  icon="robot"
                  className="tw-mr-2"
                />
                {s_ML_AGGREGATE_RESULTS}
              </Typography>
              <Box className="tw-opacity-20 tw-italic tw-inline-block">
                {returnFormattedDate(
                  us_taskMLAggregateResults[taskKey + '_' + pageKey + '_' + folder.key].timestamp_evaluation_completed,
                  'MMMM D, YYYY h:mm a',
                )}
              </Box>
            </Box>
            <Box className="tw-ml-8">
              {rJSX_NumberOfImagesAnalyzed(
                filesList,
                imageMLResults,
                imagesIncludedInAggregateEvaluationCount,
                imagesNotIncludedInAggregateEvaluationCount,
                lastAggregateAnalysisTimestamp,
              )}
            </Box>
            <Box className="tw-ml-8">{rJSX_toggleMLMessageTypes(messageCount, visibleMessageCount)}</Box>
            <Box className="tw-ml-8">{aggregateMessagesListJSX}</Box>
          </Box>
        )
      } else {
        mlAggregateResultsJSX = (
          <Box className="tw-py-2">
            <Typography
              variant="h6"
              sx={{ color: themeVariables.secondary_main }}
            >
              <Icon
                icon="robot"
                className="tw-mr-2"
              />
              {s_ML_AGGREGATE_RESULTS}
            </Typography>
            <Typography
              variant="body1"
              className="tw-ml-8 tw-italic tw-opacity-20"
            >
              {s_NOT_EVALUATED_YET}
            </Typography>
          </Box>
        )
      }
    }
    if (folder['require_min_file_upload'] === true && folder['min_file_upload_count'] != null) {
      minimumFilesJSX = (
        <Box className="tw-inline-block tw-mx-1">
          <Typography variant="body1">
            {s_MINIMUM_OF} {folder['min_file_upload_count']} {minUploadsTextJSX}
          </Typography>
        </Box>
      )
    } else {
      minimumFilesJSX = (
        <Box className="tw-inline-block tw-mx-1">
          <Typography
            variant="body1"
            className="tw-opacity-30 tw-italic"
          >
            {s_NO_MINIMUM}
          </Typography>
        </Box>
      )
    }
    if (folder['require_max_file_upload'] === true && folder['max_file_upload_count'] != null) {
      maximumFilesJSX = (
        <Box className="tw-inline-block tw-mx-1">
          <Typography variant="body1">
            {s_MAXIMUM_OF} {folder['max_file_upload_count']} {maxUploadsTextJSX}
          </Typography>
        </Box>
      )
    } else {
      maximumFilesJSX = (
        <Box className="tw-inline-block tw-mx-1">
          <Typography
            variant="body1"
            className="tw-opacity-30 tw-italic"
          >
            {s_NO_MAXIMUM}
          </Typography>
        </Box>
      )
    }
    // Determine if disabled
    // let disableUpload = !editable
    let disableUpload = false
    let uploadedCount = 0
    if (
      taskFormData != null &&
      taskFormData[pageKey] != null &&
      taskFormData[pageKey]['folders'] != null &&
      taskFormData[pageKey]['folders'][folder.key] != null
    ) {
      uploadedCount = taskFormData[pageKey]['folders'][folder.key].length
    }
    if (folder['require_max_file_upload'] === true && folder['max_file_upload_count'] != null) {
      if (folder['max_file_upload_count'] <= uploadedCount) {
        disableUpload = true
      }
    }
    // File Upload Component
    fileUploadTaskPreviewJSX = (
      <Box>
        <Typography variant="h6">
          <Icon
            icon="camera"
            type="light"
            className="tw-mr-2 tw-opacity-50"
          />
          {folder['name']}
        </Typography>
        <Box sx={{ paddingBottom: '12px' }}>
          {rJSX_ImagesUploaded(clientKey, projectKey, taskKey, page, pageKey, folder, folder.key, taskFormData, uc_setUserInterface_CustomDialogDisplay)}
          <Box>{mlAggregateResultsJSX}</Box>
          <Box className="tw-mb-2 tw-text-center">
            <FileUploadButton
              multiple={true}
              accept="image/*"
              onChange={fileOnSelect}
              button={{
                text: s_UPLOAD,
                icon: (
                  <Icon
                    icon="cloud-arrow-up"
                    className="tw-mr-2"
                  ></Icon>
                ),
                color: 'info',
                className: '',
                variant: 'contained',
                disabled: disableUpload,
              }}
              additionalFileUploadParams={{
                clientKey: clientKey,
                userName: userName,
                projectKey: projectKey,
                taskCategory: taskCategory,
                taskKey: taskKey,
                page: page,
                pageKey: pageKey,
                folderKey: folder.key,
                folder: folder,
              }}
            />
            {mlButtonJSX}
            <Box>
              <Box className="tw-inline-block tw-opacity-30">(</Box>
              <Box className="tw-inline-block">{minimumFilesJSX}</Box>
              <Box className="tw-inline-block tw-opacity-30">-</Box>
              <Box className="tw-inline-block">{maximumFilesJSX}</Box>
              <Box className="tw-inline-block tw-opacity-30">)</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
    return fileUploadTaskPreviewJSX
  }

  // TODO - actual uploads here
  const rJSX_FileUploadTaskPreview = (
    clientKey: string,
    userName: string,
    projectKey: string,
    taskCategory: string,
    taskKey: string,
    page: TsInterface_UnspecifiedObject,
    taskFormData: TsInterface_UnspecifiedObject,
    editable: boolean,
  ): JSX.Element => {
    let fileUploadJSX = (
      <Box>
        {objectToArray(page.folders)
          .sort(dynamicSort('order', 'asc'))
          .map((folder, folderIndex) => (
            <Box key={folderIndex}>
              {rJSX_FileUploadFolder(clientKey, userName, projectKey, taskCategory, taskKey, page, page.key, folder, taskFormData, editable)}
              <Divider />
            </Box>
          ))}
      </Box>
    )
    return fileUploadJSX
  }

  const rJSX_FileUploadFolder = (
    clientKey: string,
    userName: string,
    projectKey: string,
    taskCategory: string,
    taskKey: string,
    page: TsInterface_UnspecifiedObject,
    pageKey: string,
    folder: TsInterface_UnspecifiedObject,
    taskFormData: TsInterface_UnspecifiedObject,
    editable: boolean,
  ): JSX.Element => {
    let fileUploadTaskPreviewJSX = <></>
    let minimumFilesJSX = <></>
    let maximumFilesJSX = <></>
    let minUploadsTextJSX = s_UPLOADS
    let maxUploadsTextJSX = s_UPLOADS
    if (folder['min_file_upload_count'] === 1) {
      minUploadsTextJSX = s_UPLOAD
    }
    if (folder['max_file_upload_count'] === 1) {
      maxUploadsTextJSX = s_UPLOAD
    }
    if (folder['require_min_file_upload'] === true && folder['min_file_upload_count'] != null) {
      minimumFilesJSX = (
        <Box>
          <Typography variant="body1">
            {s_MINIMUM_OF} {folder['min_file_upload_count']} {minUploadsTextJSX}
          </Typography>
        </Box>
      )
    } else {
      minimumFilesJSX = (
        <Box>
          <Typography
            variant="body1"
            className="tw-opacity-30 tw-italic"
          >
            {s_NO_MINIMUM}
          </Typography>
        </Box>
      )
    }
    if (folder['require_max_file_upload'] === true && folder['max_file_upload_count'] != null) {
      maximumFilesJSX = (
        <Box>
          <Typography variant="body1">
            {s_MAXIMUM_OF} {folder['max_file_upload_count']} {maxUploadsTextJSX}
          </Typography>
        </Box>
      )
    } else {
      maximumFilesJSX = (
        <Box>
          <Typography
            variant="body1"
            className="tw-opacity-30 tw-italic"
          >
            {s_NO_MAXIMUM}
          </Typography>
        </Box>
      )
    }
    // Determine if disabled
    let disableUpload = !editable
    let uploadedCount = 0
    if (
      taskFormData != null &&
      taskFormData[pageKey] != null &&
      taskFormData[pageKey]['folders'] != null &&
      taskFormData[pageKey]['folders'][folder.key] != null
    ) {
      uploadedCount = taskFormData[pageKey]['folders'][folder.key].length
    }
    if (folder['require_max_file_upload'] === true && folder['max_file_upload_count'] != null) {
      if (folder['max_file_upload_count'] <= uploadedCount) {
        disableUpload = true
      }
    }
    // File Upload Component
    fileUploadTaskPreviewJSX = (
      <Box>
        <Typography variant="h6">{folder['name']}</Typography>
        <Box sx={{ paddingLeft: '24px', paddingBottom: '12px' }}>
          {minimumFilesJSX}
          {maximumFilesJSX}
          <Box className="tw-mb-2">
            <FileUploadButton
              multiple={true}
              accept="*"
              onChange={fileOnSelect}
              button={{
                text: s_UPLOAD_FILE,
                icon: (
                  <Icon
                    icon="cloud-arrow-up"
                    className="tw-mr-2"
                  ></Icon>
                ),
                color: 'info',
                className: '',
                variant: 'contained',
                disabled: disableUpload,
              }}
              additionalFileUploadParams={{
                clientKey: clientKey,
                userName: userName,
                projectKey: projectKey,
                taskKey: taskKey,
                taskCategory: taskCategory,
                page: page,
                pageKey: pageKey,
                folderKey: folder.key,
                folder: folder,
              }}
            />
          </Box>
          {rJSX_FilesUploaded(pageKey, folder.key, taskFormData)}
        </Box>
      </Box>
    )
    return fileUploadTaskPreviewJSX
  }

  const rJSX_InstructionsPageProgress = (
    page: TsInterface_UnspecifiedObject,
    task: TsInterface_UnspecifiedObject,
    taskFormData: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let progressBarsJSX = <></>
    if (page != null && page['page_type'] === 'form') {
      // Progress Bar Calculation
      let questionCounts: TsInterface_UnspecifiedObject = {
        total: 0,
        completed: 0,
        completed_required: 0,
        completed_optional: 0,
        not_completed_required: 0,
        not_completed_optional: 0,
      }
      if (page['questions'] != null) {
        for (let loopQuestionKey in page['questions']) {
          let loopQuestion = page['questions'][loopQuestionKey]
          questionCounts['total']++
          if (loopQuestion['required'] === true) {
            if (taskFormData != null && taskFormData[loopQuestionKey] != null && taskFormData[loopQuestionKey] !== '') {
              questionCounts['completed']++
              questionCounts['completed_required']++
            } else {
              questionCounts['not_completed_required']++
            }
          } else {
            if (taskFormData != null && taskFormData[loopQuestionKey] != null && taskFormData[loopQuestionKey] !== '') {
              questionCounts['completed']++
              questionCounts['completed_optional']++
            } else {
              questionCounts['not_completed_optional']++
            }
          }
        }
      }
      if (questionCounts['total'] === 0) {
        questionCounts['total'] = 1
      }
      // JSX
      progressBarsJSX = (
        <Box
          sx={{ width: '100%', maxWidth: '400px' }}
          className="tw-m-auto tw-text-left tw-mb-3"
        >
          <Typography variant="body1">
            {page.name} ({((getProp(questionCounts, 'completed', 0) / questionCounts.total) * 100).toFixed(1) + '%'})
          </Typography>
          <Box className="tw-opacity-30 tw-italic">{rJSX_PageSubmissionDate(page.key, task)}</Box>
          <Box
            sx={{ width: '100%', height: '20px' }}
            className="tw-mt-2 tw-rounded-lg tw-overflow-hidden"
          >
            <Stack direction="row">
              <Tooltip
                title={
                  <>
                    {s_COMPLETED_QUESTIONS} ({s_REQUIRED})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.success_dark,
                    width: (getProp(questionCounts, 'completed_required', 0) / questionCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    {s_COMPLETED_QUESTIONS} ({s_OPTIONAL})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.success_light,
                    width: (getProp(questionCounts, 'completed_optional', 0) / questionCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    {s_INCOMPLETE_QUESTIONS} ({s_REQUIRED})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.error_main,
                    width: (getProp(questionCounts, 'not_completed_required', 0) / questionCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    {s_INCOMPLETE_QUESTIONS} ({s_OPTIONAL})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.gray_500,
                    width: (getProp(questionCounts, 'not_completed_optional', 0) / questionCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
            </Stack>
          </Box>
        </Box>
      )
    } else if (page != null && page['page_type'] === 'file_uploads') {
      // Progress Bar Calculation
      let fileCounts: TsInterface_UnspecifiedObject = {
        total: 0,
        completed: 0,
        completed_required: 0,
        completed_optional: 0,
        not_completed_required: 0,
        not_completed_optional: 0,
      }
      if (page['folders'] != null) {
        for (let loopFolderKey in page['folders']) {
          let loopFolder = page['folders'][loopFolderKey]
          let uploadedCount = 0
          if (taskFormData != null && taskFormData['folders'] != null && taskFormData['folders'][loopFolderKey] != null) {
            uploadedCount = taskFormData['folders'][loopFolderKey].length
          }
          if (loopFolder['require_min_file_upload'] === true && loopFolder['min_file_upload_count'] != null) {
            if (loopFolder['min_file_upload_count'] > uploadedCount) {
              fileCounts['total'] += loopFolder['min_file_upload_count']
              fileCounts['not_completed_required'] += loopFolder['min_file_upload_count'] - uploadedCount
              fileCounts['completed_required'] += uploadedCount
            } else {
              fileCounts['total'] += uploadedCount
              fileCounts['completed_required'] += loopFolder['min_file_upload_count']
              fileCounts['completed_optional'] += uploadedCount - loopFolder['min_file_upload_count']
            }
          } else {
            if (uploadedCount > 0) {
              fileCounts['total'] += uploadedCount
              fileCounts['completed'] += uploadedCount
              fileCounts['completed_optional'] += uploadedCount
            } else {
              fileCounts['total'] += 1
              fileCounts['not_completed_optional'] += 1
            }
          }
        }
      }
      if (fileCounts['total'] === 0) {
        fileCounts['total'] = 1
      }
      // JSX
      progressBarsJSX = (
        <Box
          sx={{ width: '100%', maxWidth: '400px' }}
          className="tw-m-auto tw-text-left tw-mb-3"
        >
          <Typography variant="body1">
            {page.name} ({((getProp(fileCounts, 'completed', 0) / fileCounts.total) * 100).toFixed(1) + '%'})
          </Typography>
          <Box className="tw-opacity-30 tw-italic">{rJSX_PageSubmissionDate(page.key, task)}</Box>
          <Box
            sx={{ width: '100%', height: '20px' }}
            className="tw-mt-2 tw-rounded-lg tw-overflow-hidden"
          >
            <Stack direction="row">
              <Tooltip
                title={
                  <>
                    {s_COMPLETED_QUESTIONS} ({s_REQUIRED})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.success_dark,
                    width: (getProp(fileCounts, 'completed_required', 0) / fileCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    {s_COMPLETED_QUESTIONS} ({s_OPTIONAL})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.success_light,
                    width: (getProp(fileCounts, 'completed_optional', 0) / fileCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    {s_INCOMPLETE_QUESTIONS} ({s_REQUIRED})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.error_main,
                    width: (getProp(fileCounts, 'not_completed_required', 0) / fileCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    {s_INCOMPLETE_QUESTIONS} ({s_OPTIONAL})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.gray_500,
                    width: (getProp(fileCounts, 'not_completed_optional', 0) / fileCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
            </Stack>
          </Box>
        </Box>
      )
    } else if (page != null && page['page_type'] === 'image_uploads') {
      // Progress Bar Calculation
      let fileCounts: TsInterface_UnspecifiedObject = {
        total: 0,
        completed: 0,
        completed_required: 0,
        completed_optional: 0,
        not_completed_required: 0,
        not_completed_optional: 0,
      }
      if (page['folders'] != null) {
        for (let loopFolderKey in page['folders']) {
          let loopFolder = page['folders'][loopFolderKey]
          let uploadedCount = 0
          if (taskFormData != null && taskFormData['folders'] != null && taskFormData['folders'][loopFolderKey] != null) {
            uploadedCount = taskFormData['folders'][loopFolderKey].length
          }
          if (loopFolder['require_min_file_upload'] === true && loopFolder['min_file_upload_count'] != null) {
            if (loopFolder['min_file_upload_count'] > uploadedCount) {
              fileCounts['total'] += loopFolder['min_file_upload_count']
              fileCounts['not_completed_required'] += loopFolder['min_file_upload_count'] - uploadedCount
              fileCounts['completed_required'] += uploadedCount
            } else {
              fileCounts['total'] += uploadedCount
              fileCounts['completed_required'] += loopFolder['min_file_upload_count']
              fileCounts['completed_optional'] += uploadedCount - loopFolder['min_file_upload_count']
            }
          } else {
            if (uploadedCount > 0) {
              fileCounts['total'] += uploadedCount
              fileCounts['completed'] += uploadedCount
              fileCounts['completed_optional'] += uploadedCount
            } else {
              fileCounts['total'] += 1
              fileCounts['not_completed_optional'] += 1
            }
          }
        }
      }
      if (fileCounts['total'] === 0) {
        fileCounts['total'] = 1
      }
      // JSX
      progressBarsJSX = (
        <Box
          sx={{ width: '100%', maxWidth: '400px' }}
          className="tw-m-auto tw-text-left tw-mb-3"
        >
          <Typography variant="body1">
            {page.name} ({((getProp(fileCounts, 'completed', 0) / fileCounts.total) * 100).toFixed(1) + '%'})
          </Typography>
          <Box className="tw-opacity-30 tw-italic">{rJSX_PageSubmissionDate(page.key, task)}</Box>
          <Box
            sx={{ width: '100%', height: '20px' }}
            className="tw-mt-2 tw-rounded-lg tw-overflow-hidden"
          >
            <Stack direction="row">
              <Tooltip
                title={
                  <>
                    {s_COMPLETED_QUESTIONS} ({s_REQUIRED})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.success_dark,
                    width: (getProp(fileCounts, 'completed_required', 0) / fileCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    {s_COMPLETED_QUESTIONS} ({s_OPTIONAL})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.success_light,
                    width: (getProp(fileCounts, 'completed_optional', 0) / fileCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    {s_INCOMPLETE_QUESTIONS} ({s_REQUIRED})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.error_main,
                    width: (getProp(fileCounts, 'not_completed_required', 0) / fileCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    {s_INCOMPLETE_QUESTIONS} ({s_OPTIONAL})
                  </>
                }
                placement="top"
              >
                <Box
                  className="tw-text-center tw-py-2"
                  sx={{
                    background: themeVariables.gray_500,
                    width: (getProp(fileCounts, 'not_completed_optional', 0) / fileCounts.total) * 100 + '%',
                    height: '30px',
                  }}
                />
              </Tooltip>
            </Stack>
          </Box>
        </Box>
      )
    }
    return progressBarsJSX
  }

  const rJSX_CompleteTaskButton = (
    clientKey: string,
    task: TsInterface_UnspecifiedObject,
    taskForm: TsInterface_UnspecifiedObject,
    taskFormData: TsInterface_UnspecifiedObject,
    uc_setUserInterface_ConfirmDialogDisplay: any,
    uc_setUserInterface_CustomDialogDisplay: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
    userKey: string,
    userName: string,
    editable: boolean,
  ): JSX.Element => {
    let buttonJSX = (
      <Button
        sx={{ marginTop: '12px' }}
        color="inherit"
        variant="outlined"
        startIcon={<Icon icon="circle-check"></Icon>}
        onClick={() => {
          completeTask(clientKey, task, uc_setUserInterface_ConfirmDialogDisplay, uc_setUserInterface_CustomDialogDisplay)
        }}
        disabled={checkIfTaskSubmissionShouldBeDisabled(task, taskForm, taskFormData) || !editable}
      >
        {s_COMPLETE_TASK}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_InstructionsTab = (
    clientKey: string,
    task: TsInterface_UnspecifiedObject,
    taskForm: TsInterface_UnspecifiedObject,
    taskFormData: TsInterface_UnspecifiedObject,
    uc_setUserInterface_ConfirmDialogDisplay: any,
    uc_setUserInterface_CustomDialogDisplay: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
    userKey: string,
    userName: string,
    editable: boolean,
  ): JSX.Element => {
    const getTaskPageData = (taskFormDataInner: TsInterface_UnspecifiedObject, pageKey: string): TsInterface_UnspecifiedObject => {
      let pageFormData: TsInterface_UnspecifiedObject = {}
      if (taskFormDataInner != null && taskFormDataInner[pageKey] != null) {
        pageFormData = taskFormDataInner[pageKey]
      }
      return pageFormData
    }
    let completedTaskInfoJSX = <></>
    if (task.status_complete === true) {
      let timestampJSX = <></>
      if (task.timestamp_completed != null) {
        timestampJSX = <>{returnFormattedDate(task.timestamp_completed, 'MMMM D, YYYY h:mm a')}</>
      }
      if (task.timestamp_completed_by_name != null) {
        completedTaskInfoJSX = (
          <Box>
            <Typography variant="h6">
              {s_TASK_COMPLETED_BY} {task.timestamp_completed_by_name}
            </Typography>
            {timestampJSX}
          </Box>
        )
      } else {
        completedTaskInfoJSX = (
          <Box>
            <Typography variant="h6">{s_TASK_COMPLETED}</Typography>
            {timestampJSX}
          </Box>
        )
      }
    }
    let tabJSX = (
      <Box className="tw-p-4">
        <Box
          className="ql-scroll-html-render"
          dangerouslySetInnerHTML={{ __html: taskForm['instructions'] }}
        />
        <Divider className="tw-my-4" />
        <Box className="tw-text-center">
          {objectToArray(taskForm.pages)
            .sort(dynamicSort('order', null))
            .map((page, pageIndex) => (
              <Box key={pageIndex}>{rJSX_InstructionsPageProgress(page, task, getTaskPageData(taskFormData, page.key))}</Box>
            ))}
          {rJSX_CompleteTaskButton(
            clientKey,
            task,
            taskForm,
            taskFormData,
            uc_setUserInterface_ConfirmDialogDisplay,
            uc_setUserInterface_CustomDialogDisplay,
            uc_setUserInterface_ErrorDialogDisplay,
            userKey,
            userName,
            editable,
          )}
          {completedTaskInfoJSX}
          <Box className="tw-text-right tw-opacity-10">{task.key}</Box>
        </Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_PageSubmissionDate = (pageKey: string, task: TsInterface_UnspecifiedObject): JSX.Element => {
    let dateJSX = <></>
    if (pageKey != null && task != null && task['timestamp_' + pageKey + '_completed'] != null) {
      dateJSX = (
        <>
          {s_PAGE_SUBMISSION}: {returnFormattedDate(task['timestamp_' + pageKey + '_completed'], 'D MMM YYYY h:mm a')}{' '}
        </>
      )
    } else {
      dateJSX = (
        <>
          {s_PAGE_SUBMISSION}: ({s_MISSING}){' '}
        </>
      )
    }
    return dateJSX
  }

  const rJSX_FormTabs = (): JSX.Element => {
    let formPreviewPages: TsInterface_TabContentArray = []
    let taskCategory = us_taskData.task_category ? us_taskData.task_category : 'project'
    if (us_taskForm != null && us_taskForm.pages != null) {
      let sortedPagesArray = objectToArray(us_taskForm.pages).sort(dynamicSort('order', null))
      for (let loopPageIndex in sortedPagesArray) {
        let loopPage = sortedPagesArray[loopPageIndex]
        formPreviewPages[loopPageIndex] = {
          tabHeader: getProp(loopPage, 'name', ''),
          tabContent: <></>,
        }
        // Handle Different Page Types
        if (loopPage != null && loopPage['page_type'] === 'form') {
          if (loopPage['questions'] != null) {
            let formData: TsInterface_FormData = {}
            if (us_taskFormData != null && us_taskFormData[loopPage.key] != null) {
              formData = us_taskFormData[loopPage.key]
            }
            formPreviewPages[loopPageIndex]['tabContent'] = (
              <Box sx={{ marginLeft: '12px', marginRight: '12px', marginBottom: '12px' }}>
                <Box
                  sx={{ maxWidth: '800px' }}
                  className="tw-m-auto"
                >
                  <Form
                    formAdditionalData={{
                      projectKey: us_taskData.associated_project_key,
                      pageKey: loopPage.key,
                      clientKey: clientKey,
                      taskKey: taskKey,
                      taskCategory: taskCategory,
                    }}
                    formData={formData}
                    formInputs={returnBoilerplateFormFromTaskFormQuestions(loopPage['questions'], us_editable)}
                    formOnChange={(
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {
                      if (
                        formAdditionalData != null &&
                        formAdditionalData['clientKey'] != null &&
                        formAdditionalData['taskKey'] != null &&
                        formAdditionalData['pageKey'] != null &&
                        formData != null
                      ) {
                        if (
                          formAdditionalData['projectKey'] &&
                          (formAdditionalData['taskCategory'] == null || formAdditionalData['taskCategory'] === 'project')
                        ) {
                          let updateObject: TsInterface_UnspecifiedObject = {}
                          updateObject[formAdditionalData['pageKey'] as string] = formData
                          DatabaseSetMergeDocument(
                            DatabaseRef_ProjectTaskFormData_Document(
                              formAdditionalData['clientKey'] as string,
                              formAdditionalData['projectKey'] as string,
                              formAdditionalData['taskKey'] as string,
                            ),
                            updateObject,
                          )
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        } else if (formAdditionalData.taskCategory === 'administration') {
                          let updateObject: TsInterface_UnspecifiedObject = {}
                          updateObject[formAdditionalData.pageKey as string] = formData
                          DatabaseSetMergeDocument(
                            DatabaseRef_AdministrationTaskFormData_Document(formAdditionalData.clientKey as string, formAdditionalData.taskKey as string),
                            updateObject,
                          )
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        }
                      }
                    }}
                    formSettings={{
                      submit_button_text: s_SUBMIT_PAGE,
                      submit_button_icon: <Icon icon="floppy-disk" />,
                    }}
                    formSubmission={taskFormPageSubmission}
                  />
                  <Box className="tw-text-right tw-opacity-30 tw-italic">{rJSX_PageSubmissionDate(loopPage.key, us_taskData)}</Box>
                  <Box className="tw-text-right">
                    {rJSX_CompleteTaskButton(
                      clientKey,
                      us_taskData,
                      us_taskForm,
                      us_taskFormData,
                      uc_setUserInterface_ConfirmDialogDisplay,
                      uc_setUserInterface_CustomDialogDisplay,
                      uc_setUserInterface_ErrorDialogDisplay,
                      userKey,
                      userName,
                      us_editable,
                    )}
                  </Box>
                </Box>
              </Box>
            )
          }
        } else if (loopPage != null && loopPage['page_type'] === 'file_uploads') {
          formPreviewPages[loopPageIndex]['tabContent'] = (
            <Box sx={{ padding: '16px' }}>
              <Box
                sx={{ maxWidth: '800px' }}
                className="tw-m-auto"
              >
                {rJSX_FileUploadTaskPreview(
                  clientKey,
                  userName,
                  us_taskData.associated_project_key,
                  taskCategory,
                  taskKey,
                  loopPage,
                  us_taskFormData,
                  us_editable,
                )}
                <Box>
                  <Box className="tw-text-right tw-mt-2">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        taskUploadPageSubmission(clientKey, taskKey, loopPage.key, us_taskData)
                      }}
                    >
                      <Icon
                        icon="floppy-disk"
                        className="tw-mr-2"
                        size="lg"
                      />
                      {s_SUBMIT_PAGE}
                    </Button>
                  </Box>
                  <Box className="tw-text-right tw-opacity-30 tw-italic">{rJSX_PageSubmissionDate(loopPage.key, us_taskData)}</Box>
                  <Box className="tw-text-right">
                    {rJSX_CompleteTaskButton(
                      clientKey,
                      us_taskData,
                      us_taskForm,
                      us_taskFormData,
                      uc_setUserInterface_ConfirmDialogDisplay,
                      uc_setUserInterface_CustomDialogDisplay,
                      uc_setUserInterface_ErrorDialogDisplay,
                      userKey,
                      userName,
                      us_editable,
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        } else if (loopPage != null && loopPage['page_type'] === 'image_uploads') {
          formPreviewPages[loopPageIndex]['tabContent'] = (
            <Box sx={{ padding: '16px' }}>
              <Box
                sx={{ maxWidth: '800px' }}
                className="tw-m-auto"
              >
                {rJSX_ImageUploadTaskPreview(
                  clientKey,
                  userName,
                  us_taskData.associated_project_key,
                  taskCategory,
                  taskKey,
                  loopPage,
                  us_taskFormData,
                  us_editable,
                  uc_setUserInterface_CustomDialogDisplay,
                )}
                <Box>
                  <Box className="tw-text-right tw-mt-2">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        taskUploadPageSubmission(clientKey, taskKey, loopPage.key, us_taskData)
                      }}
                    >
                      <Icon
                        icon="floppy-disk"
                        className="tw-mr-2"
                        size="lg"
                      />
                      {s_SUBMIT_PAGE}
                    </Button>
                  </Box>
                  <Box className="tw-text-right tw-opacity-30 tw-italic">{rJSX_PageSubmissionDate(loopPage.key, us_taskData)}</Box>
                  <Box className="tw-text-right">
                    {rJSX_CompleteTaskButton(
                      clientKey,
                      us_taskData,
                      us_taskForm,
                      us_taskFormData,
                      uc_setUserInterface_ConfirmDialogDisplay,
                      uc_setUserInterface_CustomDialogDisplay,
                      uc_setUserInterface_ErrorDialogDisplay,
                      userKey,
                      userName,
                      us_editable,
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        } else {
          formPreviewPages[loopPageIndex]['tabContent'] = <Box></Box>
        }
      }
      formPreviewPages.unshift({
        tabHeader: s_TASK_INSTRUCTIONS,
        tabContent: rJSX_InstructionsTab(
          clientKey,
          us_taskData,
          us_taskForm,
          us_taskFormData,
          uc_setUserInterface_ConfirmDialogDisplay,
          uc_setUserInterface_CustomDialogDisplay,
          uc_setUserInterface_ErrorDialogDisplay,
          userKey,
          userName,
          us_editable,
        ),
      })
    }
    let tabsJSX = (
      <Box>
        <TabsBasic
          tabs={formPreviewPages}
          tabsSettings={{}}
        />
      </Box>
    )
    return tabsJSX
  }

  // Dialog JSX
  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          // TransitionComponent={ Transition }
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="clipboard-list-check" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {us_taskData.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>{rJSX_FormTabs()}</DialogContent>
          {rJSX_ImagePreviewDialog()}
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  // Return
  return <>{rJSX_Dialog()}</>
}

export const openTaskFormEditDialog = (
  clientKey: string,
  taskKey: string,
  workflowKey: string | null,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  userKey: string,
  userName: string,
  editable: boolean,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && taskKey != null) {
      uc_setUserInterface_CustomDialogDisplay({
        display: true,
        dialog: {
          dialog_jsx: (
            <Box>
              <TaskFormCustomDialog
                clientKey={clientKey}
                taskKey={taskKey}
                userKey={userKey}
                userName={userName}
                startEditable={editable}
                workflowKey={workflowKey}
              />
            </Box>
          ),
          settings: {
            max_width: 'md',
          },
        },
      })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_OPEN_TASK_FORM,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TFS-OTFED-01',
        },
      })
    }
  })
}

export const copyIndividualTaskFormBetweenClients = (originClientKey: string, destinationClientKey: string, taskFormKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let promiseArray: TsType_UnknownPromise[] = []
    let rootDocument: TsInterface_UnspecifiedObject = {}
    let prodDocument: TsInterface_UnspecifiedObject = {}
    let instructionDocument: TsInterface_UnspecifiedObject = {}
    let stagingCollection: TsInterface_UnspecifiedObject = {}
    // Get Data
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_TaskForm_Document(originClientKey, taskFormKey))
        .then((res_DGD) => {
          rootDocument = res_DGD.data
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_TaskFormProdPages_Document(originClientKey, taskFormKey))
        .then((res_DGD) => {
          prodDocument = res_DGD.data
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_TaskFormInstructions_Document(originClientKey, taskFormKey))
        .then((res_DGD) => {
          instructionDocument = res_DGD.data
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TaskFormStagingPages_Collection(originClientKey, taskFormKey))
        .then((res_DGD) => {
          stagingCollection = res_DGD.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    Promise.all(promiseArray).finally(() => {
      // Save
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_TaskForm_Document(destinationClientKey, taskFormKey), data: rootDocument },
        { type: 'setMerge', ref: DatabaseRef_TaskFormProdPages_Document(destinationClientKey, taskFormKey), data: prodDocument },
        {
          type: 'setMerge',
          ref: DatabaseRef_TaskFormProdPagesHistory_Document(destinationClientKey, taskFormKey, prodDocument['timestamp_published'].toString()),
          data: prodDocument,
        },
        { type: 'setMerge', ref: DatabaseRef_TaskFormInstructions_Document(destinationClientKey, taskFormKey), data: instructionDocument },
      ]
      for (let loopDocumentKey in stagingCollection) {
        let loopDocument = stagingCollection[loopDocumentKey]
        updateArray.push({
          type: 'setMerge',
          ref: DatabaseRef_TaskFormStagingPages_Document(destinationClientKey, taskFormKey, loopDocumentKey),
          data: loopDocument,
        })
      }
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    })
  })
}

const importTaskFiles = (
  clientKey: string,
  userName: string,
  projectKey: string,
  taskCategory: string,
  taskKey: string,
  page: TsInterface_UnspecifiedObject,
  pageKey: string,
  folderKey: string,
  folder: TsInterface_UnspecifiedObject,
  files: TsInterface_FilesToUpload,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (
      clientKey != null &&
      taskKey != null &&
      // projectKey != null &&
      pageKey != null
      // folderKey != null
    ) {
      let promiseArray1: TsType_UnknownPromise[] = []
      let promiseArray2: TsType_UnknownPromise[] = []
      let taskData: TsInterface_UnspecifiedObject = {}
      let taskDataExists = false
      let taskFormDataRef = null
      console.log('taskCategory', taskCategory)
      switch (taskCategory) {
        case 'project':
        default:
          taskFormDataRef = DatabaseRef_ProjectTaskFormData_Document(clientKey, projectKey, taskKey)
          break
        case 'administration':
          taskFormDataRef = DatabaseRef_AdministrationTaskFormData_Document(clientKey, taskKey)
          break
      }
      // Get Current Data for task
      promiseArray1.push(
        DatabaseGetDocument(taskFormDataRef)
          .then((res_DGD) => {
            if (res_DGD != null && res_DGD['data'] != null && res_DGD['data'][pageKey] != null) {
              taskData = res_DGD['data'][pageKey]
            } else {
              taskData = {}
            }
            taskData = getProp(res_DGD, 'data', {})
            taskDataExists = true
          })
          .catch((rej_DGD) => {
            // Document Doesn't Exist
          }),
      )
      // Aftr Form Data Loaded
      Promise.all(promiseArray1).finally(() => {
        for (let fileIndex in files) {
          let file = files[fileIndex]
          let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
          let nameWithoutType = file.file_name.substring(0, file.file_name.lastIndexOf('.'))
          let fileType = file.file_name.substring(file.file_name.lastIndexOf('.') + 1, file.file_name.length)
          let fileName = nameWithoutType + ' (' + dateString + ').' + fileType
          let storageRef = null
          switch (taskCategory) {
            case 'project':
            default:
              storageRef = StorageRef_ProjectTaskPageFile(clientKey, projectKey, fileName)
              break
            case 'administration':
              storageRef = StorageRef_AdministrationTaskPageFile(clientKey, taskKey, fileName)
              break
          }

          promiseArray2.push(
            StorageUploadFile(
              storageRef,
              // file.data_url,
              file.file,
              { clientKey: clientKey, projectKey: projectKey, folderKey: folderKey, taskKey: taskKey, pageKey: pageKey },
            )
              .then((res_SUF: any) => {
                files[fileIndex]['storage_url'] = getProp(res_SUF, 'url', null)
              })
              .catch((rej_SUF) => {
                console.error(rej_SUF)
              }),
          )
        }
        Promise.all(promiseArray2).finally(() => {
          // Upload File
          let updateObject: TsInterface_UnspecifiedObject = {}
          updateObject[pageKey] = { folders: {} }
          if (taskData != null && taskData[pageKey] != null && taskData[pageKey]['folders'] != null) {
            updateObject[pageKey]['folders'] = taskData[pageKey]['folders']
          }
          if (taskData != null && taskData[pageKey] != null && taskData[pageKey]['folders'] != null && taskData[pageKey]['folders'][folderKey] != null) {
            updateObject[pageKey]['folders'][folderKey] = taskData[pageKey]['folders'][folderKey]
          } else {
            updateObject[pageKey]['folders'][folderKey] = []
          }
          for (let fileIndex in files) {
            let file = files[fileIndex]
            let fileDatabaseRecord: TsInterface_UnspecifiedObject = {
              name: file.file_name,
              url: file.storage_url,
              timestamp_uploaded: new Date(),
              associated_uploader_name: userName,
              key: uuidv4(),
            }
            if (folder != null && folder.default_image_tags != null) {
              fileDatabaseRecord['tags'] = folder.default_image_tags
            }
            if (page != null && page['page_type'] === 'image_uploads') {
              fileDatabaseRecord['upload_type'] = 'image'
            } else if (page != null && page['page_type'] === 'file_uploads') {
              fileDatabaseRecord['upload_type'] = 'file'
            }
            updateObject[pageKey]['folders'][folderKey].push(fileDatabaseRecord)
          }
          if (taskCategory === 'project') {
            if (taskDataExists === true) {
              DatabaseUpdateDocument(DatabaseRef_ProjectTaskFormData_Document(clientKey, projectKey, taskKey), updateObject)
                .then((res_DUD) => {
                  resolve(res_DUD)
                })
                .catch((rej_DUD) => {
                  reject(rej_DUD)
                })
            } else {
              DatabaseSetMergeDocument(DatabaseRef_ProjectTaskFormData_Document(clientKey, projectKey, taskKey), updateObject)
                .then((res_DSMD) => {
                  resolve(res_DSMD)
                })
                .catch((rej_DSMD) => {
                  reject(rej_DSMD)
                })
            }
          } else if (taskCategory === 'administration') {
            DatabaseSetMergeDocument(DatabaseRef_AdministrationTaskFormData_Document(clientKey, taskKey), updateObject)
              .then((res_DSMD) => {
                resolve(res_DSMD)
              })
              .catch((rej_DSMD) => {
                reject(rej_DSMD)
              })
          }
        })
      })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_IMPORT_FILE,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TFS-ITF-01',
        },
      })
    }
  })
}

// Task Updates
export const editTask = (
  clientKey: string,
  task: TsInterface_UnspecifiedObject,
  projectTasks: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  tempTaskFormOptions: TsInterface_UnspecifiedObject[],
): void => {
  let formData = cloneObjectWithoutReference(task)
  // Get Data
  let databasePromiseArray: TsType_UnknownPromise[] = []
  let downstreamTasks: TsInterface_UnspecifiedObject = {}
  let dispatcherTask: TsInterface_UnspecifiedObject = {}
  databasePromiseArray.push(
    DatabaseGetCollection(DatabaseRef_ProjectTasksForPrerequisiteTask_Query(clientKey, task.key))
      .then((res_DGC) => {
        downstreamTasks = res_DGC.data
      })
      .catch((rej_DGC) => {
        console.error(rej_DGC)
      }),
  )
  databasePromiseArray.push(
    DatabaseGetCollection(DatabaseRef_DispatcherTask_Query(clientKey, task.key))
      .then((res_DGC) => {
        if (res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
          dispatcherTask = objectToArray(res_DGC.data)[0]
        }
      })
      .catch((rej_DGC) => {
        console.error(rej_DGC)
      }),
  )

  let formInputs = formInputs_EditTask

  if (task.task_completion_type === 'direct') {
    formInputs = formInputs_EditTask_Direct
  } else if (task.task_completion_type === 'dispatcher') {
    formInputs = formInputs_EditTask_Dispatcher
  } else if (task.task_completion_type === 'scheduled') {
    formInputs = formInputs_EditTask_Scheduled
  }

  // After Data Lated
  Promise.all(databasePromiseArray).finally(() => {
    // Create Upstream and Downstream task options
    let upstreamTaskOptions: TsInterface_UnspecifiedObject[] = []
    let downstreamTaskOptions: TsInterface_UnspecifiedObject[] = []
    if (projectTasks != null) {
      for (let loopTaskKey in projectTasks) {
        let loopTask = projectTasks[loopTaskKey]
        if (loopTask != null && loopTask.key != null && loopTask.name != null) {
          if (loopTask.status === 'deleted') {
            upstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
            downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
          } else {
            // Disable Self
            if (loopTask.key === task.key) {
              // Don't include self
            } else {
              // Upstream
              if (dispatcherTask != null && dispatcherTask.key != null && dispatcherTask.key === loopTask.key) {
                // Disable if the loop task is used to dispatch this task
                upstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
              } else {
                if (task.associated_dispatched_task_key != null && task.associated_dispatched_task_key === loopTask.key) {
                  // Disable if loop task is a dispatched from this task
                  upstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
                } else {
                  upstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: false })
                }
              }
              // Downstream
              if (dispatcherTask != null && dispatcherTask.key != null && dispatcherTask.key === loopTask.key) {
                // Disable if the loop task is used to dispatch this task
                downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
              } else if (task.associated_dispatched_task_key != null && task.associated_dispatched_task_key === loopTask.key) {
                // Disable if loop task is a dispatched from this task
                downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
              } else {
                if (loopTask.status_complete === true) {
                  // Disable if already completed
                  downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
                } else {
                  downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: false })
                }
              }
            }
          }
        }
      }
    }
    if (formInputs['upstream_tasks'] != null && upstreamTaskOptions != null) {
      formInputs['upstream_tasks']['options'] = upstreamTaskOptions
    }
    if (formInputs['downstream_tasks'] != null && downstreamTaskOptions != null) {
      formInputs['downstream_tasks']['options'] = downstreamTaskOptions
    }
    if (formInputs['associated_task_form_key'] != null && tempTaskFormOptions != null) {
      formInputs['associated_task_form_key']['options'] = tempTaskFormOptions
    }
    // Autofill Upstream and Downstream tasks
    formData.upstream_tasks = {}
    if (task != null && task.prerequisite_tasks != null) {
      for (let loopUpstreamTaskKey in task.prerequisite_tasks) {
        formData.upstream_tasks[loopUpstreamTaskKey] = true
      }
    }
    formData.downstream_tasks = {}
    for (let loopDownstreamTaskKey in downstreamTasks) {
      formData.downstream_tasks[loopDownstreamTaskKey] = true
    }
    // Open Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {
            task: task,
            projectTasks: projectTasks,
          },
          formData: formData,
          formInputs: formInputs,
          formOnChange: formOnChange_EditTask,
          formSettings: formSettings_EditTask,
          formSubmission: formSubmission_EditTask,
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {s_EDIT_TASK} - {task.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  })
}

export const editTaskTeam = (
  clientKey: string,
  task: TsInterface_UnspecifiedObject,
  projectTasks: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  tempTaskFormOptions: TsInterface_UnspecifiedObject[],
  tableHooks: TsInterface_TableHooks,
): void => {
  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
    .then((res_GCK) => {
      let clientUserRoles = returnClientUserRoles(res_GCK.clientKey)
      let formattedUserRoles: TsInterface_UnspecifiedObject = {}
      for (let loopUserRoleKey in clientUserRoles) {
        formattedUserRoles[loopUserRoleKey] = {
          key: loopUserRoleKey,
          value: clientUserRoles[loopUserRoleKey]['name'],
        }
      }
      let formInputs = { ...formInputs_EditTaskTeam }
      formInputs_EditTaskTeam['associated_owner_type']['options'] = objectToArray(formattedUserRoles)
      uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: {
              task: task,
              clientKey: clientKey,
            },
            formData: { ...task },
            formInputs: formInputs,
            formOnChange: formOnChange_EditTaskTeam,
            formSettings: formSettings_EditTaskTeam,
            formSubmission: formSubmission_EditTaskTeam,
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: (
              <>
                {rLIB('Edit Task Team')} - {task.name}
              </>
            ),
            formDialogIcon: (
              <Icon
                type="solid"
                icon="pen-to-square"
              />
            ),
          },
        },
      })
    })
    .catch((rej_GCK) => {
      console.error(rej_GCK)
    })
}
