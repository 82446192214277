import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material/'
import { tableColumns_User, tableSettings_AllUsers } from 'app/models/users/user_table'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, ClientUserRoles, returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_Users_Search } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_Users_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey, Context_RootData_ClientPermissions, Context_RootData_GlobalUser } from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey, sendPasswordResetToEmail } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Variables
///////////////////////////////

const pageKey: string = ApplicationPages['AdminDatabaseUserListPage']['key']

const s_ALL_DEACTIVATED_USERS: JSX.Element = <Trans>All Deactivated Users</Trans>
const s_ALL_EXTERNAL_USERS: JSX.Element = <Trans>All External Users</Trans>
const s_ALL_INTERNAL_USERS: JSX.Element = <Trans>All Internal Users</Trans>
const s_ALL_USERS: JSX.Element = <Trans>All Users</Trans>
const s_NEW_USER: JSX.Element = <Trans>New User</Trans>
const s_SHOW_ALL_USERS_WITH_TASK_ROLES: JSX.Element = <Trans>Show all users with task roles</Trans>
const s_USERS: JSX.Element = <Trans>Users</Trans>
const se_USERS = 'Users'

export const Container: React.FC = (): JSX.Element => {
  const [us_clientUserRoles, us_setClientUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesPartnersList, us_setSalesPartnersList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_showAllWithTaskRole, us_setShowAllWithTaskRole] = useState<boolean>(false)
  const [us_userTableFilter, us_setUserTableFilter] = useState<string>('ALL_INTERNAL_USERS')
  const [us_salesPartnerFilter, us_setSalesPartnerFilter] = useState<string>('ALL_SALES_PARTNERS')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)

  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<{ name: string; email: string } | null>(null)

  const [openResetDialog, setOpenResetDialog] = useState(false)
  const [resetEmail, setResetEmail] = useState<string | null>(null)

  const [emailPreview, setEmailPreview] = useState<string | null>(null)

  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false) // Add loading state

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)

  const handleOpenDialog = (userName: string, userEmail: string) => {
    setSelectedUser({ name: userName, email: userEmail })
    setIsLoadingPreview(true)

    const templateObjectPreview = generateEmailTemplateObject(userName, true) // Call the function with isPreview=true

    try {
      const htmlContent = generateHtmlForEmailFromTemplateObject(templateObjectPreview, {})
      setEmailPreview(htmlContent || '<p>Preview generation failed</p>')
    } catch (error) {
      console.error('Error generating email preview:', error)
      setEmailPreview('<p>Preview generation failed</p>')
    }

    setIsLoadingPreview(false)
    setOpen(true)
  }

  // Close dialog
  const handleCloseDialog = () => {
    setOpen(false)
    setSelectedUser(null)
    setEmailPreview(null) // Reset the preview when closing the dialog
  }

  const handleSendEmail = () => {
    if (selectedUser) {
      sendWelcomeEmail(selectedUser.name, selectedUser.email)
        .then((res) => {
          setOpenSuccessDialog(true) // Show success dialog
        })
        .catch((rej) => {
          console.error('Error sending email:', rej)
          setOpenErrorDialog(true) // Show error dialog
        })
    }
    handleCloseDialog() // Close the original dialog
  }

  const sendWelcomeEmail = (userName: string, userEmail: string) => {
    const templateObject = generateEmailTemplateObject(userName, false) // Call the function with isPreview=false

    const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, {})

    return cloudFunctionManageRequest('manageEmails', {
      function: 'sendSendgridHtmlEmail',
      to: [userEmail],
      subject: 'Welcome to ETW Energy!',
      html: htmlContent,
      cc: [],
      bcc: [],
    })
  }

  const rJSX_WelcomeEmailDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md" // Set appropriate size for the dialog
        PaperProps={{ style: { height: 'auto', maxHeight: '70vh' } }} // Adjust height as needed
      >
        <DialogTitle>Send Welcome Email</DialogTitle>
        <DialogContent
          dividers
          style={{ height: 'auto', overflowY: 'auto' }} // Ensure scrolling
        >
          <Typography
            variant="body1"
            gutterBottom
          >
            Are you sure you want to send a welcome email to "{selectedUser?.name}"?
          </Typography>

          {/* Only render the email preview here */}
          <Box
            className="ql-scroll-html-render"
            dangerouslySetInnerHTML={{ __html: emailPreview || '<p>Preview not available</p>' }} // Render the HTML
            style={{ marginTop: '16px', maxHeight: '70vh' }} // Adjust height and scrolling
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSendEmail}
            color="primary"
            autoFocus
          >
            Yes, Send Email
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  // Function to open the reset dialog
  const handleOpenResetDialog = (email: string) => {
    setResetEmail(email)
    setOpenResetDialog(true)
  }

  // Function to close the reset dialog
  const handleCloseResetDialog = () => {
    setOpenResetDialog(false)
    setResetEmail(null)
  }

  // Function to send password reset email
  const handleSendResetEmail = () => {
    if (resetEmail) {
      sendPasswordResetToEmail(resetEmail)
        .then((res) => {})
        .catch((error) => {
          console.error('Error sending password reset email:', error)
        })
        .finally(() => {
          handleCloseResetDialog()
        })
    }
  }

  const rJSX_ResetPasswordDialog = () => (
    <Dialog
      open={openResetDialog}
      onClose={handleCloseResetDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Send Password Reset Email</DialogTitle>
      <DialogContent>Are you sure you want to send a password reset email to "{resetEmail}"?</DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseResetDialog}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSendResetEmail}
          color="primary"
          autoFocus
        >
          Yes, Send Email
        </Button>
      </DialogActions>
    </Dialog>
  )

  // Success dialog
  const rJSX_SuccessDialog = () => (
    <Dialog
      open={openSuccessDialog}
      onClose={() => setOpenSuccessDialog(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Email Sent</DialogTitle>
      <DialogContent>
        <Typography>The welcome email was sent successfully!</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenSuccessDialog(false)}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )

  // Error dialog
  const rJSX_ErrorDialog = () => (
    <Dialog
      open={openErrorDialog}
      onClose={() => setOpenErrorDialog(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>There was an error sending the email. Please try again.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenErrorDialog(false)}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )

  // Function to generate the email template object
  const generateEmailTemplateObject = (userName: string, isPreview: boolean): TsInterface_EmailTemplateObject => {
    // Extract first name from full userName
    const firstName = userName.split(' ')[0]

    // Create a greeting message
    const greetingMessage = `Hi ${firstName},`

    // Define the common structure of the email
    const commonTemplateObject = {
      BODY_DIV: {
        element_type: 'div',
        style: {
          'box-sizing': 'border-box',
          'width': '100%',
          'background': isPreview ? 'none' : '#eeeeee', // Background depending on preview or final
          'padding': isPreview ? '0' : '16px', // Padding depending on preview or final
        },
        contents: {
          CARD_DIV: {
            element_type: 'div',
            style: {
              'max-width': '600px',
              'background': '#ffffff',
              'border-radius': '8px',
              'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
              'margin': '0 auto',
              'overflow': 'hidden',
            },
            contents: {
              HEADER_DIV: {
                element_type: 'div',
                style: {
                  'box-sizing': 'border-box',
                  'background': 'rgb(25, 30, 33)',
                  'font-size': '20px',
                  'width': '100%',
                  'min-height': '70px',
                  'padding': '8px',
                  'text-align': 'center',
                },
                contents: {
                  LOGO_IMG: {
                    element_type: 'img',
                    src_type: 'static',
                    src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                    alt: 'logo.png',
                    width: '80px',
                    height: 'auto',
                  },
                },
              },
              CONTENT_DIV: {
                element_type: 'div',
                style: {
                  padding: '24px',
                },
                contents: {
                  GREETING: {
                    element_type: 'p',
                    text_type: 'static',
                    text: greetingMessage,
                    style: {
                      'font-size': '16px',
                      'font-weight': 'bold',
                      'margin-bottom': '8px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  MESSAGE1: {
                    element_type: 'p',
                    text_type: 'static',
                    text: "Congratulations! We've created your login to ETW Energy's EOS desktop.",
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '12px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  LOGIN_URL: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'The URL is: etw.app/login',
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '8px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                    contents: {
                      LINK: {
                        element_type: 'a',
                        text: 'https://etw.app/login',
                        text_type: 'static',
                        href_type: 'static',
                        href_url: 'https://etw.app/login',
                        style: {
                          'font-weight': 'bold',
                          'font-size': '14px',
                          'color': 'blue',
                          'text-decoration': 'underline',
                          'cursor': 'pointer',
                        },
                      },
                    },
                  },
                  FIRST_TIME_LOGIN: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'When you log in for the first time, select "Log in with email" and then reset your password.',
                    style: {
                      'font-size': '14px',
                      'margin-top': '8px',
                      'margin-bottom': '16px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  SUPPORT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'If you have any questions or trouble logging in, please email us at helpdesk@etwenergy.com',
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '12px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  SIGN_OFF: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Thanks and welcome!',
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  TEAM: {
                    element_type: 'p',
                    text_type: 'static',
                    text: '- The ETW Team',
                    style: {
                      'font-size': '14px',
                      'font-weight': 'bold',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                },
              },
            },
          },
        },
      },
    } as TsInterface_EmailTemplateObject

    // Return the template object based on the condition
    return commonTemplateObject
  }

  useEffect(() => {
    document.title = se_USERS
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setClientUserRoles(returnClientUserRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSalesPartnersList(newData)
      ur_forceRerender()
    }
    if (uc_RootData_ClientKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SalesPartner_Collection(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSalesPartnersList({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Function for generating the appropriate query based on filters
  const tableDatabaseEndpoint_User = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]

    // Apply sales partner filter if it's not set to ALL_SALES_PARTNERS
    if (us_salesPartnerFilter !== 'ALL_SALES_PARTNERS') {
      queryOperatorsArray.push({ prop: 'associated_sales_partner_key', comparator: '==', value: us_salesPartnerFilter })
    }

    // Apply task role filter if enabled
    if (us_showAllWithTaskRole === true && tableAdditionalData != null && tableAdditionalData.user_role != null) {
      orderByArray = []
      queryOperatorsArray.push({ prop: 'task_roles.' + tableAdditionalData.user_role, comparator: '==', value: true })
    } else {
      // Apply status filter (active/deleted)
      if (tableAdditionalData != null && tableAdditionalData.user_status != null) {
        queryOperatorsArray.push({ prop: 'status', comparator: '==', value: tableAdditionalData.user_status })
      }

      // Handle different filters for user type
      if (us_userTableFilter === 'ALL_USERS') {
        // No need to filter by organization type
      } else if (us_userTableFilter === 'ALL_INTERNAL_USERS') {
        // Filter only internal users
        queryOperatorsArray.push({ prop: 'associated_organization_type', comparator: '==', value: 'internal' })
      } else if (us_userTableFilter === 'ALL_EXTERNAL_USERS') {
        // Filter only external users
        queryOperatorsArray.push({ prop: 'associated_organization_type', comparator: '==', value: 'external' })
      } else if (tableAdditionalData != null && tableAdditionalData.user_role != null) {
        // Filter by user role
        queryOperatorsArray.push({ prop: 'user_role', comparator: '==', value: tableAdditionalData.user_role })
      }
    }

    // Handle pagination cursors (startAt, startAfter, endAt, endBefore)
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }

    // Apply limit for pagination
    let limit = getProp(queryGenerationData, 'limit', 5)

    // Generate and return the Firestore query
    return generateDatabaseQuery(DatabaseRef_Users_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableSearchEndpoint_Users = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_AllUsers.rows_per_page
    if (limit === null) {
      limit = 20
    }
    return DatabaseRef_Users_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>

    if (tableSettings_AllUsers.search_settings_database == null) {
      tableSettings_AllUsers.search_settings_database = { search_type: 'firebase' }
    }
    tableSettings_AllUsers.search_settings_database.search_endpoint = tableSearchEndpoint_Users

    let userStatus = 'active'
    if (us_userTableFilter === 'ALL_DEACTIVATED_USERS') {
      userStatus = 'deleted'
    }

    let organizationType = 'internal'
    if (us_userTableFilter === 'ALL_EXTERNAL_USERS') {
      organizationType = 'external'
    }

    let tableAdditionalData_AllUsers: TsInterface_TableAdditionalData = {
      associated_organization_type: organizationType,
      task_roles: ClientUserRoles,
      client_type: uc_RootData_ClientPermissions.client_type,
      sales_partners: us_salesPartnersList,
      user_status: userStatus,
      uc_RootData_GlobalUser,
      handleOpenDialog, // For welcome email dialog with preview
      handleOpenResetDialog, // For password reset dialog
      handleCloseDialog, // For closing dialogs
      handleCloseResetDialog, // For closing reset dialog
    }

    tableJSX = (
      <Card className="tw-mt-4">
        <TableDatabase
          tableAdditionalData={tableAdditionalData_AllUsers}
          tableColumns={tableColumns_User}
          tableDatabaseEndpoint={tableDatabaseEndpoint_User}
          tableSettings={tableSettings_AllUsers}
        />
      </Card>
    )

    return tableJSX
  }

  const rJSX_UserTypeFilterDropdown = (): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_userTableFilter}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setUserTableFilter(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            <MenuItem value={'ALL_INTERNAL_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_INTERNAL_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            <MenuItem value={'ALL_EXTERNAL_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_EXTERNAL_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            <MenuItem value={'ALL_DEACTIVATED_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_DEACTIVATED_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            {objectToArray(us_clientUserRoles)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  <Chip
                    size={'small'}
                    label={ClientUserRoles[option['key']]['name']}
                    color={ClientUserRoles[option['key']]['color']}
                    icon={<Icon icon="users" />}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const rJSX_SalesPartnerFilterDropdown = (): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-align-top tw-ml-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_salesPartnerFilter}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSalesPartnerFilter(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_SALES_PARTNERS'}>
              <Chip
                size={'small'}
                label={'All Sales Partners'}
                color={'default'}
                icon={<Icon icon="handshake" />} // Adding handshake icon here
              />
            </MenuItem>
            {objectToArray(us_salesPartnersList)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  <Chip
                    size={'small'}
                    label={option['name']}
                    color={'default'}
                    icon={<Icon icon="handshake" />} // Adding handshake icon here as well
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = <></>
    if (uc_RootData_ClientKey != null) {
      pageJSX = (
        <AuthenticatedContainer
          pageHeader={s_USERS}
          pageKey={pageKey}
          content={
            <Box className="tw-align-top">
              <Button
                color="success"
                variant="contained"
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseUserNewPage.url())
                }}
                disableElevation
                startIcon={<Icon icon="square-plus" />}
                className="tw-mr-2"
              >
                {s_NEW_USER}
              </Button>
              {rJSX_UserTypeFilterDropdown()}
              {rJSX_SalesPartnerFilterDropdown()}
              {rJSX_Table()}
              {rJSX_WelcomeEmailDialog()}
              {rJSX_ResetPasswordDialog()}

              {rJSX_SuccessDialog()}
              {rJSX_ErrorDialog()}
            </Box>
          }
        />
      )
    } else {
      pageJSX = (
        <AuthenticatedContainer
          pageHeader={s_USERS}
          pageKey={pageKey}
          content={
            <Box className="tw-text-center">
              <CircularProgress />
            </Box>
          }
        />
      )
    }
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
