///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import InnerImageZoom from 'react-inner-image-zoom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_ProjectAdditionalImages_Document,
  DatabaseRef_ProjectTaskFormData_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { StorageRef_ProjectTaskPageFile } from 'rfbp_aux/services/storage_endpoints/projects'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons/icon'
import { HeicImage } from 'rfbp_core/components/images/heic_image'
import { rLIB } from 'rfbp_core/localization/library'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument, DownloadFromUrlsAsZip, StorageUploadFile } from 'rfbp_core/services/database_management'
import { cloneObjectWithoutReference, dynamicSort, getProp, objectToArray, returnFormattedDate, underscoresToSpaces } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Forms
const formInputs_ImageTags: TsInterface_FormInputs = {
  tags: {
    key: 'tags',
    label: rLIB('Image Tags'),
    input_type: 'multiple_select_checklist',
    required: false,
    data_type: 'string',
    options: [],
  },
  notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'notes',
    label: rLIB('Image Notes'),
    required: false,
  },
}

const formSettings_ImageTags: TsInterface_FormSettings = {
  //
}

///////////////////////////////
// Functions
///////////////////////////////

const openPhotoDialogFromFolderView = (
  readOrWrite: 'read' | 'write',
  clientKey: string,
  subfolderName: JSX.Element,
  fileIndex: number,
  photo: TsInterface_UnspecifiedObject,
  availableImageTags: any,
  uc_setUserInterface_FormDialogDisplay: any,
  selectedPhotosTaskFolderData: any,
  selectedPhotosTaskPageSubfolder: any,
  selectedPhotosTaskFolder: any,
  selectedPhotosTaskPageFolder: any,
  projectKey: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_AlertDialogDisplay: any,
  runningImageAnalysis: any,
  setRunningImageAnalysis: any,
  ur_forceRerender: any,
  uc_setUserInterface_SnackbarDisplay: any,
): void => {
  // TODO - consolidate with altered copy of this function below

  // Generate Form Inputs options for tags
  formInputs_ImageTags['tags']['options'] = []
  for (let imageIndex in availableImageTags) {
    formInputs_ImageTags['tags']['options'].push({
      key: availableImageTags[imageIndex],
      value: availableImageTags[imageIndex],
    })
  }
  let initialFormData: TsInterface_UnspecifiedObject = { tags: {}, notes: getProp(photo, 'notes', null) }
  for (let tagIndex in photo.tags) {
    initialFormData['tags'][photo.tags[tagIndex]] = true
  }
  // Open Tag Editor
  const openTagEditor = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: initialFormData,
          formInputs: formInputs_ImageTags,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_ImageTags,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let tagsUpdateArray: string[] = []
              if (formSubmittedData != null && formSubmittedData.tags != null) {
                for (let tagKey in formSubmittedData.tags) {
                  if (formSubmittedData.tags[tagKey] === true) {
                    tagsUpdateArray.push(tagKey)
                  }
                }
              }
              let taskFormData = selectedPhotosTaskFolderData
              let folderKey = getProp(selectedPhotosTaskPageSubfolder, 'key', null)
              let taskKey = getProp(selectedPhotosTaskFolder, 'key', null)
              let pageKey = getProp(selectedPhotosTaskPageFolder, 'key', null)
              let updateObject: TsInterface_UnspecifiedObject = {}
              updateObject[pageKey] = { folders: {} }
              if (
                taskFormData != null &&
                taskFormData[pageKey] != null &&
                taskFormData[pageKey]['folders'] != null &&
                taskFormData[pageKey]['folders'][folderKey] != null
              ) {
                updateObject[pageKey]['folders'][folderKey] = taskFormData[pageKey]['folders'][folderKey]
                if (updateObject[pageKey]['folders'][folderKey][fileIndex] != null) {
                  updateObject[pageKey]['folders'][folderKey][fileIndex]['tags'] = tagsUpdateArray.sort()
                  updateObject[pageKey]['folders'][folderKey][fileIndex]['notes'] = getProp(formSubmittedData, 'notes', null)
                  // Update Database
                  DatabaseSetMergeDocument(DatabaseRef_ProjectTaskFormData_Document(clientKey, projectKey, taskKey), updateObject)
                    .then((res_DSMD) => {
                      uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                    })
                } else {
                  reject({
                    success: false,
                    error: {
                      message: rLIB('Failed to update image tags'),
                      details: rLIB('Unsupported data structure'),
                      code: 'ER-D-APV-OTE-01',
                    },
                  })
                }
              } else {
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to update image tags'),
                    details: rLIB('Unsupported data structure'),
                    code: 'ER-D-APV-OTE-02',
                  },
                })
              }
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'warning',
          formDialogHeaderText: rLIB('Edit Image Tags'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="tags"
            />
          ),
        },
      },
    })
  }
  const rJSX_PhotoTags = (readOrWrite: 'read' | 'write'): JSX.Element => {
    let tagsJSX = <></>
    let imageTags = getProp(photo, 'tags', [])
    if (imageTags.length === 0) {
      tagsJSX = (
        <Box className="tw-p-2">
          <Chip
            color="warning"
            className="tw-cursor-pointer tw-pl-1 tw-mb-2"
            icon={
              <Icon
                icon="tag"
                type="light"
                size="xl"
              />
            }
            label={<Typography variant="h6">{rLIB('Photo not tagged')}</Typography>}
            variant="outlined"
            onClick={() => {
              if (readOrWrite === 'write') {
                openTagEditor()
              }
            }}
          />
        </Box>
      )
    } else {
      tagsJSX = (
        <Box className="tw-p-2">
          {getProp(photo, 'tags', []).map((tag: string, index: number) => (
            <Chip
              key={index}
              color="warning"
              className="tw-cursor-pointer tw-mr-2 tw-pl-1 tw-mb-2"
              icon={
                <Icon
                  icon="tag"
                  size="xl"
                />
              }
              label={<Typography variant="h6">{tag}</Typography>}
              variant="filled"
              onClick={() => {
                if (readOrWrite === 'write') {
                  openTagEditor()
                }
              }}
            />
          ))}
        </Box>
      )
    }
    return tagsJSX
  }
  let editIconJSX = <></>
  if (readOrWrite === 'write') {
    editIconJSX = (
      <Box
        className="tw-inline-block tw-opacity-50 tw-ml-2 hover:tw-opacity-100 tw-cursor-pointer"
        onClick={() => {
          openTagEditor()
        }}
      >
        <Icon icon="pen-to-square" />
      </Box>
    )
  }
  let photoNotesJSX = (
    <Box className="tw-inline-block tw-opacity-50 tw-ml-1">
      {photo.notes}
      {editIconJSX}
    </Box>
  )
  let photoMissingNotesJSX = (
    <Box className="tw-italic tw-opacity-20 tw-inline-block tw-ml-1">
      {rLIB('Missing')}
      {editIconJSX}
    </Box>
  )
  let photoMissingTimestampJSX = <Box className="tw-italic tw-opacity-20 tw-inline-block tw-ml-1">{rLIB('Missing')}</Box>
  // Dialog JSX
  let dialogJSX = (
    <Box>
      <Dialog
        // TransitionComponent={ Transition }
        className="bp_dialog_xl_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 2, color: '#fff !important' }}
            >
              <Icon icon="image" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              <Box className="tw-inline-block">{subfolderName}</Box>
              <Box className="tw-inline-block">
                {photo.name}
                {/* : ({ rLIB("Image") } { fileIndex + 1 }) */}
              </Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box>{rJSX_PhotoTags(readOrWrite)}</Box>
          <Box className="tw-px-4">
            <Typography variant="h6">
              {rLIB('Uploaded By')}:
              {photo.associated_uploader_name != null ? (
                <Box className="tw-inline-block tw-opacity-50 tw-ml-1">{photo.associated_uploader_name}</Box>
              ) : (
                photoMissingTimestampJSX
              )}
            </Typography>
          </Box>
          <Box className="tw-px-4">
            <Typography variant="h6">
              {rLIB('Uploaded At')}:
              {photo.timestamp_uploaded != null ? (
                <Box className="tw-inline-block tw-opacity-50 tw-ml-1">{returnFormattedDate(photo.timestamp_uploaded, 'D MMM YYYY h:mm a')}</Box>
              ) : (
                photoMissingTimestampJSX
              )}
            </Typography>
          </Box>
          <Box className="tw-px-4 tw-pb-2">
            <Typography variant="h6">
              {rLIB('Image Notes')}:{photo.notes != null ? photoNotesJSX : photoMissingNotesJSX}
            </Typography>
          </Box>
          {/* <Box className="tw-px-4 tw-pb-2">
						{ rJSX_AnalyzeImageButton(
							photo,
							uc_setUserInterface_ErrorDialogDisplay,
							uc_setUserInterface_AlertDialogDisplay,
							runningImageAnalysis,
							setRunningImageAnalysis,
							ur_forceRerender,
							uc_setUserInterface_CustomDialogDisplay,
							uc_setUserInterface_SnackbarDisplay,
						) }
					</Box> */}
          <Box className="tw-text-center">
            <InnerImageZoom src={photo.url} />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: dialogJSX,
      settings: {
        max_width: 'lg',
      },
    },
  })
}

const openPhotoDialogFromTagView = (
  readOrWrite: 'read' | 'write',
  clientKey: string,
  subfolderName: JSX.Element,
  fileIndex: number,
  photo: TsInterface_UnspecifiedObject,
  availableImageTags: any,
  uc_setUserInterface_FormDialogDisplay: any,
  allTaskFormData: any,
  projectKey: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_AlertDialogDisplay: any,
  runningImageAnalysis: any,
  setRunningImageAnalysis: any,
  ur_forceRerender: any,
  uc_setUserInterface_SnackbarDisplay: any,
): void => {
  // Generate Form Inputs options for tags
  formInputs_ImageTags['tags']['options'] = []
  for (let imageIndex in availableImageTags) {
    formInputs_ImageTags['tags']['options'].push({
      key: availableImageTags[imageIndex],
      value: availableImageTags[imageIndex],
    })
  }
  let initialFormData: TsInterface_UnspecifiedObject = { tags: {}, notes: getProp(photo, 'notes', null) }
  for (let tagIndex in photo.tags) {
    initialFormData['tags'][photo.tags[tagIndex]] = true
  }

  // Open Tag Editor
  const openTagEditor = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: initialFormData,
          formInputs: formInputs_ImageTags,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_ImageTags,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let tagsUpdateArray: string[] = []
              if (formSubmittedData && formSubmittedData.tags) {
                for (let tagKey in formSubmittedData.tags) {
                  if (formSubmittedData.tags[tagKey] === true) {
                    tagsUpdateArray.push(tagKey)
                  }
                }
              }
              const notes = getProp(formSubmittedData, 'notes', null)
              const folderKey = getProp(photo, 'TEMP_associated_folder_key', null)
              const taskKey = getProp(photo, 'TEMP_associated_task_key', null)
              const pageKey = getProp(photo, 'TEMP_associated_page_key', null)

              if (taskKey) {
                // Existing logic for task-associated images
                let updateObject: TsInterface_UnspecifiedObject = {}
                let taskFormData: TsInterface_UnspecifiedObject = {}
                if (allTaskFormData[taskKey]) {
                  taskFormData = allTaskFormData[taskKey]
                }
                updateObject[pageKey] = { folders: {} }
                if (taskFormData[pageKey] && taskFormData[pageKey]['folders'] && taskFormData[pageKey]['folders'][folderKey]) {
                  updateObject[pageKey]['folders'][folderKey] = taskFormData[pageKey]['folders'][folderKey]
                  if (updateObject[pageKey]['folders'][folderKey][fileIndex]) {
                    updateObject[pageKey]['folders'][folderKey][fileIndex]['tags'] = tagsUpdateArray.sort()
                    updateObject[pageKey]['folders'][folderKey][fileIndex]['notes'] = notes
                    // Clean up TEMP Fields
                    updateObject = cloneObjectWithoutReference(updateObject)
                    for (let loopPageKey in updateObject) {
                      let loopPage = updateObject[loopPageKey]
                      if (loopPage && loopPage['folders']) {
                        for (let loopFolderKey in loopPage['folders']) {
                          let loopFolder = loopPage['folders'][loopFolderKey]
                          for (let loopFileUploadIndex in loopFolder) {
                            let loopFileUpload = loopFolder[loopFileUploadIndex]
                            if (loopFileUpload && loopFileUpload['upload_type'] === 'image') {
                              delete loopFileUpload['TEMP_associated_task_key']
                              delete loopFileUpload['TEMP_associated_page_key']
                              delete loopFileUpload['TEMP_associated_folder_key']
                              delete loopFileUpload['TEMP_subfolder_name']
                              delete loopFileUpload['TEMP_upload_index']
                            }
                          }
                        }
                      }
                    }
                    // Update Database
                    DatabaseSetMergeDocument(DatabaseRef_ProjectTaskFormData_Document(clientKey, projectKey, taskKey), updateObject)
                      .then((res_DSMD) => {
                        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        reject(rej_DSMD)
                      })
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: rLIB('Failed to update image tags'),
                        details: rLIB('Unsupported data structure'),
                        code: 'ER-D-APV-OTE-01',
                      },
                    })
                  }
                } else {
                  reject({
                    success: false,
                    error: {
                      message: rLIB('Failed to update image tags'),
                      details: rLIB('Unsupported data structure'),
                      code: 'ER-D-APV-OTE-02',
                    },
                  })
                }
              } else {
                // New logic for additional images without task associations
                const imageKey = photo.key
                const updateObject = { tags: tagsUpdateArray, notes: notes }

                DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalImages_Document(clientKey, projectKey, imageKey), updateObject)
                  .then((res_DSMD) => {
                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    resolve(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    reject(rej_DSMD)
                  })
              }
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'warning',
          formDialogHeaderText: rLIB('Edit Image Tags'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="tags"
            />
          ),
        },
      },
    })
  }
  const rJSX_PhotoTags = (readOrWrite: 'read' | 'write'): JSX.Element => {
    let tagsJSX = <></>
    let imageTags = getProp(photo, 'tags', [])
    if (imageTags.length === 0) {
      tagsJSX = (
        <Box className="tw-p-2">
          <Chip
            color="warning"
            className="tw-cursor-pointer tw-pl-1 tw-mb-2"
            icon={
              <Icon
                icon="tag"
                type="light"
                size="xl"
              />
            }
            label={<Typography variant="h6">{rLIB('Photo not tagged')}</Typography>}
            variant="outlined"
            onClick={() => {
              if (readOrWrite === 'write') {
                openTagEditor()
              }
            }}
          />
        </Box>
      )
    } else {
      tagsJSX = (
        <Box className="tw-p-2">
          {getProp(photo, 'tags', []).map((tag: string, index: number) => (
            <Chip
              key={index}
              color="warning"
              className="tw-cursor-pointer tw-mr-2 tw-pl-1 tw-mb-2"
              icon={
                <Icon
                  icon="tag"
                  size="xl"
                />
              }
              label={<Typography variant="h6">{tag}</Typography>}
              variant="filled"
              onClick={() => {
                if (readOrWrite === 'write') {
                  openTagEditor()
                }
              }}
            />
          ))}
        </Box>
      )
    }
    return tagsJSX
  }
  let editIconJSX = <></>
  if (readOrWrite === 'write') {
    editIconJSX = (
      <Box
        className="tw-inline-block tw-opacity-50 tw-ml-2 hover:tw-opacity-100 tw-cursor-pointer"
        onClick={() => {
          openTagEditor()
        }}
      >
        <Icon icon="pen-to-square" />
      </Box>
    )
  }
  let photoNotesJSX = (
    <Box className="tw-inline-block tw-opacity-50 tw-ml-1">
      {photo.notes}
      {editIconJSX}
    </Box>
  )
  let photoMissingNotesJSX = (
    <Box className="tw-italic tw-opacity-20 tw-inline-block tw-ml-1">
      {rLIB('Missing')}
      {editIconJSX}
    </Box>
  )
  let photoMissingTimestampJSX = <Box className="tw-italic tw-opacity-20 tw-inline-block tw-ml-1">{rLIB('Missing')}</Box>
  let dialogJSX = (
    <Box>
      <Dialog
        // TransitionComponent={ Transition }
        className="bp_dialog_xl_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 2, color: '#fff !important' }}
            >
              <Icon icon="image" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              <Box className="tw-inline-block">{subfolderName}</Box>
              <Box className="tw-inline-block">
                {photo.name}
                {/* : ({ rLIB("Image") } { fileIndex + 1 }) */}
              </Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box>{rJSX_PhotoTags(readOrWrite)}</Box>
          <Box className="tw-px-4">
            <Typography variant="h6">
              {rLIB('Uploaded By')}:
              {photo.associated_uploader_name != null ? (
                <Box className="tw-inline-block tw-opacity-50 tw-ml-1">{photo.associated_uploader_name}</Box>
              ) : (
                photoMissingTimestampJSX
              )}
            </Typography>
          </Box>
          <Box className="tw-px-4">
            <Typography variant="h6">
              {rLIB('Uploaded At')}:
              {photo.timestamp_uploaded != null ? (
                <Box className="tw-inline-block tw-opacity-50 tw-ml-1">{returnFormattedDate(photo.timestamp_uploaded, 'D MMM YYYY h:mm a')}</Box>
              ) : (
                photoMissingTimestampJSX
              )}
            </Typography>
          </Box>
          <Box className="tw-px-4 tw-pb-2">
            <Typography variant="h6">
              {rLIB('Image Notes')}:{photo.notes != null ? photoNotesJSX : photoMissingNotesJSX}
            </Typography>
          </Box>
          {/* <Box className="tw-px-4 tw-pb-2">
						{ rJSX_AnalyzeImageButton(
							photo,
							uc_setUserInterface_ErrorDialogDisplay,
							uc_setUserInterface_AlertDialogDisplay,
							runningImageAnalysis,
							setRunningImageAnalysis,
							ur_forceRerender,
							uc_setUserInterface_CustomDialogDisplay,
							uc_setUserInterface_SnackbarDisplay,
						) }
					</Box> */}
          <Box className="tw-text-center tw-mb-2">
            {rJSX_Image(photo)}
            {/* <InnerImageZoom src={ photo.url } /> */}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: dialogJSX,
      settings: {
        max_width: 'lg',
      },
    },
  })
}

///////////////////////////////
// JSX
///////////////////////////////

const rJSX_Image = (photo: TsInterface_UnspecifiedObject): JSX.Element => {
  let imageJSX = <></>
  if (photo != null && photo.name != null && photo.name.endsWith('.heic')) {
    imageJSX = (
      <Box>
        <HeicImage
          sx={{
            height: '600px',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
          url={photo.url}
        />
      </Box>
    )
  } else {
    imageJSX = <InnerImageZoom src={photo.url} />
  }
  return imageJSX
}

const rJSX_FolderListCaretButton = (): JSX.Element => {
  let buttonJSX = (
    <Icon
      icon="chevron-right"
      className="tw-mr-2 tw-opacity-40"
    ></Icon>
  )
  return buttonJSX
}

const rJSX_SubPhotoFolderName = (folderKey: string): JSX.Element => {
  let subFolderNameJSX = <></>
  if (folderKey != null) {
    subFolderNameJSX = <Box>{underscoresToSpaces(folderKey.substring(0, folderKey.lastIndexOf('_')))}</Box>
  }
  return subFolderNameJSX
}

const rJSX_RootPhotoFolderName = (folderKey: string, projectKey: any, projectTaskWorkflow: any, projectTasks: any): JSX.Element => {
  let rootFolderNameJSX = <></>
  if (folderKey != null) {
    let taskKey = folderKey.substring(projectKey.length + 1, folderKey.length)
    if (
      taskKey != null &&
      projectTaskWorkflow != null &&
      projectTaskWorkflow['tasks'] != null &&
      projectTaskWorkflow['tasks'][taskKey] != null &&
      projectTaskWorkflow['tasks'][taskKey]['name'] != null
    ) {
      rootFolderNameJSX = <Box>{projectTaskWorkflow['tasks'][taskKey]['name']}</Box>
    } else if (folderKey != null && projectTasks != null && projectTasks[folderKey] != null && projectTasks[folderKey]['name'] != null) {
      rootFolderNameJSX = <Box>{projectTasks[folderKey]['name']}</Box>
    }
  }
  return rootFolderNameJSX
}

const rJSX_SelectedPhotoFolderButton = (folderName: JSX.Element | string): JSX.Element => {
  let buttonJSX = (
    <Button
      variant="outlined"
      color="inherit"
      className="tw-mr-3 tw-mb-1"
    >
      <Icon
        icon="folder-open"
        className="tw-mr-2"
      ></Icon>
      {folderName}
    </Button>
  )
  return buttonJSX
}

const rJSX_TogglePhotoViewTypeButton = (photosViewType: any, setPhotosViewType: any): JSX.Element => {
  let buttonJSX = <></>
  if (photosViewType === 'folder') {
    buttonJSX = (
      <Button
        variant="contained"
        color="info"
        className="tw-mr-2 tw-mb-1"
        onClick={() => {
          setPhotosViewType('tag')
        }}
      >
        <Icon
          icon="clipboard-list-check"
          className="tw-mr-2"
          type="solid"
        ></Icon>
        {rLIB('Task Folder View')}
      </Button>
    )
  } else if (photosViewType === 'tag') {
    buttonJSX = (
      <Button
        variant="contained"
        color="info"
        className="tw-mr-2 tw-mb-1"
        onClick={() => {
          setPhotosViewType('folder')
        }}
      >
        <Icon
          icon="tags"
          className="tw-mr-2"
          type="solid"
        ></Icon>
        {rLIB('Photo Tag View')}
      </Button>
    )
  } else {
    buttonJSX = (
      <Button
        variant="contained"
        color="info"
        className="tw-mr-2 tw-mb-1"
        onClick={() => {
          setPhotosViewType('tag')
        }}
      >
        <Icon
          icon="clipboard-list-check"
          className="tw-mr-2"
          type="solid"
        ></Icon>
        {rLIB('Task Folder View')}
      </Button>
    )
  }
  return buttonJSX
}

const rJSX_PhotosRootButton = (
  setPhotosViewLevel: any,
  setSelectedPhotosTaskFolder: any,
  setSelectedPhotosTaskFolderData: any,
  setSelectedPhotosTaskPageFolder: any,
  setSelectedPhotosTaskPageSubfolder: any,
): JSX.Element => {
  let buttonJSX = (
    <Button
      variant="outlined"
      color="inherit"
      className="tw-opacity-50 tw-mr-2 tw-mb-1"
      onClick={() => {
        setPhotosViewLevel('root')
        setSelectedPhotosTaskFolder({})
        setSelectedPhotosTaskFolderData({})
        setSelectedPhotosTaskPageFolder({})
        setSelectedPhotosTaskPageSubfolder({})
      }}
    >
      <Icon
        icon="folder"
        className="tw-mr-2"
        type="regular"
      ></Icon>
      {rLIB('Project')}
    </Button>
  )
  return buttonJSX
}

const rJSX_PhotosPageButton = (
  setPhotosViewLevel: any,
  setSelectedPhotosTaskPageFolder: any,
  setSelectedPhotosTaskPageSubfolder: any,
  selectedPhotosTaskFolder: any,
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
): JSX.Element => {
  let buttonJSX = (
    <Button
      variant="outlined"
      color="inherit"
      className="tw-opacity-50 tw-mr-2 tw-mb-1"
      onClick={() => {
        setPhotosViewLevel('task')
        setSelectedPhotosTaskPageFolder({})
        setSelectedPhotosTaskPageSubfolder({})
      }}
    >
      <Icon
        icon="folder"
        className="tw-mr-2"
        type="regular"
      ></Icon>
      {rJSX_RootPhotoFolderName(selectedPhotosTaskFolder.key, projectKey, projectTaskWorkflow, projectTasks)}
    </Button>
  )
  return buttonJSX
}

const rJSX_PhotosFolderButton = (setPhotosViewLevel: any, setSelectedPhotosTaskPageSubfolder: any, selectedPhotosTaskPageFolder: any): JSX.Element => {
  let buttonJSX = (
    <Button
      variant="outlined"
      color="inherit"
      className="tw-opacity-50 tw-mr-2 tw-mb-1"
      onClick={() => {
        setPhotosViewLevel('page')
        setSelectedPhotosTaskPageSubfolder({})
      }}
    >
      <Icon
        icon="folder"
        className="tw-mr-2"
        type="regular"
      ></Icon>
      {rJSX_SubPhotoFolderName(selectedPhotosTaskPageFolder.key)}
    </Button>
  )
  return buttonJSX
}

const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): Promise<any> => {
  return new Promise((resolve, reject) => {
    console.log('Params in handleFileUpload:', additionalFileUploadParams)

    if (event.target.files) {
      uploadFiles(event, additionalFileUploadParams)
        .then((urls) => {
          console.log('Uploaded URLs:', urls)
          resolve(urls)
        })
        .catch((error) => {
          console.error('Error uploading files:', error)
          reject(error)
        })
    } else {
      reject('No files selected')
    }
  })
}

const uploadFiles = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (event?.target?.files?.length) {
      const promiseArray: Promise<any>[] = []
      const promiseArray2: Promise<any>[] = []
      const files = event.target.files
      const readFiles: TsInterface_UnspecifiedObject = {}

      // Read files as Data URLs
      for (let fileIndex in files) {
        let file = files[fileIndex]
        if (file) {
          promiseArray.push(
            readAsDataURL(file)
              .then((dataURL) => {
                readFiles[fileIndex] = { file_name: file.name, file, data_url: dataURL }
              })
              .catch(console.error),
          )
        }
      }

      Promise.all(promiseArray)
        .then(() => {
          const uploadedUrls: string[] = []
          for (let loopFileKey in readFiles) {
            let loopFile = readFiles[loopFileKey]
            const imageKey = uuidv4() // Generate a unique key for each image
            const timestamp_uploaded = new Date()

            promiseArray2.push(
              StorageUploadFile(
                StorageRef_ProjectTaskPageFile(additionalFileUploadParams.clientKey, additionalFileUploadParams.projectKey, loopFile.file_name),
                loopFile.file,
                { clientKey: additionalFileUploadParams.clientKey, projectKey: additionalFileUploadParams.projectKey },
              )
                .then((res) => {
                  const url = (res as { url: string }).url
                  if (url) {
                    uploadedUrls.push(url)

                    const imageData = {
                      key: imageKey,
                      name: loopFile.file_name,
                      timestamp_uploaded,
                      url,
                      tags: additionalFileUploadParams.tags || [],
                      associated_uploader_name: additionalFileUploadParams.associated_uploader_name || null,
                    }
                    console.log('Image Data with Uploader:', imageData)

                    DatabaseSetMergeDocument(
                      DatabaseRef_ProjectAdditionalImages_Document(additionalFileUploadParams.clientKey, additionalFileUploadParams.projectKey, imageKey),
                      imageData,
                    ).catch((error) => {
                      console.error('Error saving image document:', error)
                    })
                  }
                })
                .catch(console.error),
            )
          }

          Promise.all(promiseArray2)
            .then(() => resolve(uploadedUrls))
            .catch(reject)
        })
        .catch(reject)
    } else {
      reject('No valid files to upload')
    }
  })
}

const readAsDataURL = (file: any) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onerror = reject
    fr.onload = () => resolve(fr.result)
    fr.readAsDataURL(file)
  })
}

const rJSX_uploadImageFromProjectButton = (
  clientKey: string,
  projectKey: string,
  selectedTags: string[],
  disabled: boolean,
  uploaderName: string | undefined,
): JSX.Element => {
  return (
    <Tooltip title={disabled ? 'Select at least one tag first' : ''}>
      <span>
        {' '}
        <FileUploadButton
          multiple={true}
          accept="image/*"
          onChange={(event) => handleFileUpload(event, { clientKey, projectKey, tags: selectedTags, associated_uploader_name: uploaderName })}
          additionalFileUploadParams={{ clientKey, projectKey, associated_uploader_name: uploaderName }} // Include uploader's name
          button={{
            text: <>Upload Images</>,
            icon: (
              <Icon
                icon="cloud-upload"
                className="tw-mr-2"
              />
            ),
            className: 'tw-mr-2',
            color: 'primary',
            variant: 'contained',
            disabled,
          }}
        />
      </span>
    </Tooltip>
  )
}

const rJSX_SelectImageTagsButton = ({
  availableImageTags,
  selectedTags,
  setSelectedTags,
}: {
  availableImageTags: string[]
  selectedTags: string[]
  setSelectedTags: (tags: string[]) => void
}): JSX.Element => {
  return (
    <Box sx={{ width: 'auto', marginRight: '10px' }}>
      <FormControl
        variant="outlined"
        size="small"
        sx={{ minWidth: 200 }}
      >
        <InputLabel>Select Tags</InputLabel>
        <Select
          multiple
          value={selectedTags}
          onChange={(event) => setSelectedTags(event.target.value as string[])}
          input={<OutlinedInput label="Select Tags" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {(selected as string[]).map((value) => (
                <Chip
                  key={value}
                  label={value}
                  size="small"
                />
              ))}
            </Box>
          )}
          sx={{ minWidth: 200 }}
        >
          {availableImageTags.map((tag) => (
            <MenuItem
              key={tag}
              value={tag}
            >
              {tag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

const rJSX_ProjectRootPhotosFolders = (
  photosViewType: any,
  setPhotosViewType: any,
  projectRootPhotosFolders: any,
  setPhotosViewLevel: any,
  setSelectedPhotosTaskFolder: any,
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
): JSX.Element => {
  let foldersJSX = (
    <Box>
      <Box className="tw-mb-2">
        {/* {rJSX_uploadImageFromProjectButton('etw_energy', projectKey)} */}
        {rJSX_TogglePhotoViewTypeButton(photosViewType, setPhotosViewType)}
        {rJSX_SelectedPhotoFolderButton(rLIB('Project'))}
      </Box>
      <Grid2
        container
        spacing={2}
        columns={24}
      >
        {objectToArray(projectRootPhotosFolders).map((folder: TsInterface_UnspecifiedObject, index: number) => (
          <Grid2
            key={index}
            xs={8}
            sm={6}
            md={4}
            lg={3}
          >
            <Box
              className="tw-cursor-pointer tw-text-center"
              onClick={(event) => {
                if (event.detail === 2) {
                  setPhotosViewLevel('task')
                  setSelectedPhotosTaskFolder(folder)
                }
              }}
            >
              <Icon
                icon="folder-open"
                size="4x"
              />
              {rJSX_RootPhotoFolderName(folder.key, projectKey, projectTaskWorkflow, projectTasks)}
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  )
  return foldersJSX
}

const rJSX_ProjectTaskPhotosFolders = (
  selectedPhotosTaskFolderData: any,
  selectedPhotosTaskFolderForm: any,
  photosViewType: any,
  setPhotosViewType: any,
  setPhotosViewLevel: any,
  setSelectedPhotosTaskFolder: any,
  setSelectedPhotosTaskFolderData: any,
  setSelectedPhotosTaskPageFolder: any,
  setSelectedPhotosTaskPageSubfolder: any,
  selectedPhotosTaskFolder: any,
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
): JSX.Element => {
  let pageFolders: TsInterface_UnspecifiedObject = {}
  for (let pageFolderKey in selectedPhotosTaskFolderData) {
    if (
      pageFolderKey != null &&
      selectedPhotosTaskFolderForm != null &&
      selectedPhotosTaskFolderForm['pages'] != null &&
      selectedPhotosTaskFolderForm['pages'][pageFolderKey] != null &&
      selectedPhotosTaskFolderForm['pages'][pageFolderKey]['page_type'] === 'image_uploads'
    ) {
      pageFolders[pageFolderKey] = {
        key: pageFolderKey,
        folders: getProp(selectedPhotosTaskFolderData[pageFolderKey], 'folders', {}),
      }
    }
  }
  let foldersJSX = (
    <Box>
      <Box className="tw-mb-2">
        {rJSX_TogglePhotoViewTypeButton(photosViewType, setPhotosViewType)}
        {rJSX_PhotosRootButton(
          setPhotosViewLevel,
          setSelectedPhotosTaskFolder,
          setSelectedPhotosTaskFolderData,
          setSelectedPhotosTaskPageFolder,
          setSelectedPhotosTaskPageSubfolder,
        )}

        {rJSX_FolderListCaretButton()}
        {rJSX_SelectedPhotoFolderButton(rJSX_RootPhotoFolderName(selectedPhotosTaskFolder.key, projectKey, projectTaskWorkflow, projectTasks))}
      </Box>
      <Grid2
        container
        spacing={2}
        columns={24}
      >
        {objectToArray(pageFolders).map((folder: TsInterface_UnspecifiedObject, index: number) => (
          <Grid2
            key={index}
            xs={8}
            sm={6}
            md={4}
            lg={3}
          >
            <Box
              className="tw-cursor-pointer tw-text-center"
              onClick={(event) => {
                if (event.detail === 2) {
                  setPhotosViewLevel('page')
                  setSelectedPhotosTaskPageFolder(folder)
                }
              }}
            >
              <Icon
                icon="folder-open"
                size="4x"
              />
              {rJSX_SubPhotoFolderName(folder.key)}
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  )
  return foldersJSX
}

const rJSX_ProjectTaskPagePhotosFolders = (
  selectedPhotosTaskPageFolder: any,
  photosViewType: any,
  setPhotosViewType: any,
  setPhotosViewLevel: any,
  setSelectedPhotosTaskFolder: any,
  setSelectedPhotosTaskFolderData: any,
  setSelectedPhotosTaskPageFolder: any,
  setSelectedPhotosTaskPageSubfolder: any,
  selectedPhotosTaskFolder: any,
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
): JSX.Element => {
  let pageSubfolders: TsInterface_UnspecifiedObject = {}
  if (selectedPhotosTaskPageFolder != null && selectedPhotosTaskPageFolder['folders'] != null) {
    for (let pageFolderKey in selectedPhotosTaskPageFolder['folders']) {
      pageSubfolders[pageFolderKey] = {
        key: pageFolderKey,
        images: selectedPhotosTaskPageFolder['folders'][pageFolderKey],
      }
    }
  }
  let foldersJSX = (
    <Box>
      <Box className="tw-mb-2">
        {rJSX_TogglePhotoViewTypeButton(photosViewType, setPhotosViewType)}
        {rJSX_PhotosRootButton(
          setPhotosViewLevel,
          setSelectedPhotosTaskFolder,
          setSelectedPhotosTaskFolderData,
          setSelectedPhotosTaskPageFolder,
          setSelectedPhotosTaskPageSubfolder,
        )}

        {rJSX_FolderListCaretButton()}
        {rJSX_PhotosPageButton(
          setPhotosViewLevel,
          setSelectedPhotosTaskPageFolder,
          setSelectedPhotosTaskPageSubfolder,
          selectedPhotosTaskFolder,
          projectKey,
          projectTaskWorkflow,
          projectTasks,
        )}
        {rJSX_FolderListCaretButton()}
        {rJSX_SelectedPhotoFolderButton(rJSX_SubPhotoFolderName(selectedPhotosTaskPageFolder.key))}
      </Box>
      <Grid2
        container
        spacing={2}
        columns={24}
      >
        {objectToArray(pageSubfolders).map((folder: TsInterface_UnspecifiedObject, index: number) => (
          <Grid2
            key={index}
            xs={8}
            sm={6}
            md={4}
            lg={3}
          >
            <Box
              className="tw-cursor-pointer tw-text-center"
              onClick={(event) => {
                if (event.detail === 2) {
                  setPhotosViewLevel('subfolder')
                  setSelectedPhotosTaskPageSubfolder(folder)
                }
              }}
            >
              <Icon
                icon="folder-open"
                size="4x"
              />
              {rJSX_SubPhotoFolderName(folder.key)}
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  )
  return foldersJSX
}

const rJSX_PhotoListCaption = (photo: TsInterface_UnspecifiedObject): JSX.Element => {
  let captionJSX = <></>
  let nameJSX = <></>
  let dateJSX = <></>
  if (photo.associated_uploader_name != null) {
    nameJSX = <Box className="tw-inline-block">{photo.associated_uploader_name}</Box>
  } else {
    nameJSX = <Box className="tw-inline-block tw-italics tw-opacity-50">{rLIB('Missing Uploader')}</Box>
  }
  if (photo.timestamp_uploaded != null) {
    dateJSX = <Box className="tw-inline-block tw-opacity-50">({returnFormattedDate(photo.timestamp_uploaded, 'D MMM YYYY h:mm a')})</Box>
  } else {
    dateJSX = <Box className="tw-inline-block tw-italics tw-opacity-20">({rLIB('Missing Date')})</Box>
  }
  captionJSX = (
    <Box>
      {nameJSX} {dateJSX}
    </Box>
  )
  return captionJSX
}

const rJSX_ProjectTaskPagePhotosSubfolder = (
  readOrWrite: 'read' | 'write',
  photosViewType: any,
  setPhotosViewType: any,
  setPhotosViewLevel: any,
  setSelectedPhotosTaskFolder: any,
  setSelectedPhotosTaskFolderData: any,
  setSelectedPhotosTaskPageFolder: any,
  setSelectedPhotosTaskPageSubfolder: any,
  selectedPhotosTaskFolder: any,
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
  selectedPhotosTaskPageSubfolder: any,
  selectedPhotosTaskPageFolder: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  availableImageTags: any,
  uc_setUserInterface_FormDialogDisplay: any,
  selectedPhotosTaskFolderData: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  downloadingPhotos: any,
  setDownloadingPhotos: any,
  ur_forceRerender: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_AlertDialogDisplay: any,
  runningImageAnalysis: any,
  setRunningImageAnalysis: any,
  uc_setUserInterface_SnackbarDisplay: any,
  screenSize: any,
): JSX.Element => {
  let subfolderPhotos: TsInterface_UnspecifiedObject = {}
  if (selectedPhotosTaskPageSubfolder != null && selectedPhotosTaskPageSubfolder['images'] != null) {
    for (let photoIndex in selectedPhotosTaskPageSubfolder['images']) {
      let loopPhoto = selectedPhotosTaskPageSubfolder['images'][photoIndex]
      subfolderPhotos[photoIndex] = loopPhoto
      subfolderPhotos[photoIndex]['name'] = getProp(loopPhoto, 'name', null)
      subfolderPhotos[photoIndex]['url'] = getProp(loopPhoto, 'url', null)
      subfolderPhotos[photoIndex]['upload_type'] = getProp(loopPhoto, 'upload_type', null)
      subfolderPhotos[photoIndex]['timestamp_uploaded'] = getProp(loopPhoto, 'timestamp_uploaded', null)
      subfolderPhotos[photoIndex]['associated_uploader_name'] = getProp(loopPhoto, 'associated_uploader_name', null)
      subfolderPhotos[photoIndex]['tags'] = getProp(loopPhoto, 'tags', [])
      subfolderPhotos[photoIndex]['notes'] = getProp(loopPhoto, 'notes', null)
    }
  }
  let subfolderName = rJSX_SubPhotoFolderName(selectedPhotosTaskPageSubfolder.key)

  let columns = 4
  if (screenSize === 'xs') {
    columns = 2
  } else if (screenSize === 'sm') {
    columns = 3
  } else if (screenSize === 'md') {
    columns = 4
  } else if (screenSize === 'lg') {
    columns = 5
  } else if (screenSize === 'xl') {
    columns = 6
  }

  let foldersJSX = (
    <Box>
      <Box className="tw-mb-2">
        {rJSX_TogglePhotoViewTypeButton(photosViewType, setPhotosViewType)}
        {rJSX_PhotosRootButton(
          setPhotosViewLevel,
          setSelectedPhotosTaskFolder,
          setSelectedPhotosTaskFolderData,
          setSelectedPhotosTaskPageFolder,
          setSelectedPhotosTaskPageSubfolder,
        )}
        {rJSX_FolderListCaretButton()}
        {rJSX_PhotosPageButton(
          setPhotosViewLevel,
          setSelectedPhotosTaskPageFolder,
          setSelectedPhotosTaskPageSubfolder,
          selectedPhotosTaskFolder,
          projectKey,
          projectTaskWorkflow,
          projectTasks,
        )}
        {rJSX_FolderListCaretButton()}
        {rJSX_PhotosFolderButton(setPhotosViewLevel, setSelectedPhotosTaskPageSubfolder, selectedPhotosTaskPageFolder)}
        {rJSX_FolderListCaretButton()}
        {rJSX_SelectedPhotoFolderButton(subfolderName)}
        {rJSX_DownloadZippedImagesButton(
          objectToArray(subfolderPhotos),
          selectedPhotosTaskPageSubfolder.key + '.zip',
          downloadingPhotos,
          setDownloadingPhotos,
          ur_forceRerender,
          uc_setUserInterface_ErrorDialogDisplay,
        )}
      </Box>
      <ImageList
        cols={columns}
        variant="standard"
      >
        {objectToArray(subfolderPhotos)
          .sort(dynamicSort('timestamp_uploaded', 'asc'))
          .map((photo: TsInterface_UnspecifiedObject, index: number) => (
            <ImageListItem
              key={index}
              onClick={(event) => {
                if (event.detail === 2) {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                    openPhotoDialogFromFolderView(
                      readOrWrite,
                      res_GCK.clientKey,
                      subfolderName,
                      index,
                      photo,
                      availableImageTags,
                      uc_setUserInterface_FormDialogDisplay,
                      selectedPhotosTaskFolderData,
                      selectedPhotosTaskPageSubfolder,
                      selectedPhotosTaskFolder,
                      selectedPhotosTaskPageFolder,
                      projectKey,
                      uc_setUserInterface_CustomDialogDisplay,
                      uc_setUserInterface_ErrorDialogDisplay,
                      uc_setUserInterface_AlertDialogDisplay,
                      runningImageAnalysis,
                      setRunningImageAnalysis,
                      ur_forceRerender,
                      uc_setUserInterface_SnackbarDisplay,
                    )
                  })
                }
              }}
            >
              {rJSX_ImagePreview(photo)}
            </ImageListItem>
          ))}
      </ImageList>
    </Box>
  )
  return foldersJSX
}

const rJSX_PhotosFolderView = (
  readOrWrite: 'read' | 'write',
  photosViewLevel: any,
  photosViewType: any,
  setPhotosViewType: any,
  projectRootPhotosFolders: any,
  setPhotosViewLevel: any,
  setSelectedPhotosTaskFolder: any,
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
  selectedPhotosTaskFolderData: any,
  selectedPhotosTaskFolderForm: any,
  setSelectedPhotosTaskFolderData: any,
  setSelectedPhotosTaskPageFolder: any,
  setSelectedPhotosTaskPageSubfolder: any,
  selectedPhotosTaskFolder: any,
  selectedPhotosTaskPageFolder: any,
  selectedPhotosTaskPageSubfolder: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  availableImageTags: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  downloadingPhotos: any,
  setDownloadingPhotos: any,
  ur_forceRerender: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_AlertDialogDisplay: any,
  runningImageAnalysis: any,
  setRunningImageAnalysis: any,
  uc_setUserInterface_SnackbarDisplay: any,
  screenSize: any,
): JSX.Element => {
  let viewJSX = <></>
  if (photosViewLevel === 'root') {
    viewJSX = (
      <Box>
        {rJSX_ProjectRootPhotosFolders(
          photosViewType,
          setPhotosViewType,
          projectRootPhotosFolders,
          setPhotosViewLevel,
          setSelectedPhotosTaskFolder,
          projectKey,
          projectTaskWorkflow,
          projectTasks,
        )}
      </Box>
    )
  } else if (photosViewLevel === 'task') {
    viewJSX = (
      <Box>
        {rJSX_ProjectTaskPhotosFolders(
          selectedPhotosTaskFolderData,
          selectedPhotosTaskFolderForm,
          photosViewType,
          setPhotosViewType,
          setPhotosViewLevel,
          setSelectedPhotosTaskFolder,
          setSelectedPhotosTaskFolderData,
          setSelectedPhotosTaskPageFolder,
          setSelectedPhotosTaskPageSubfolder,
          selectedPhotosTaskFolder,
          projectKey,
          projectTaskWorkflow,
          projectTasks,
        )}
      </Box>
    )
  } else if (photosViewLevel === 'page') {
    viewJSX = (
      <Box>
        {rJSX_ProjectTaskPagePhotosFolders(
          selectedPhotosTaskPageFolder,
          photosViewType,
          setPhotosViewType,
          setPhotosViewLevel,
          setSelectedPhotosTaskFolder,
          setSelectedPhotosTaskFolderData,
          setSelectedPhotosTaskPageFolder,
          setSelectedPhotosTaskPageSubfolder,
          selectedPhotosTaskFolder,
          projectKey,
          projectTaskWorkflow,
          projectTasks,
        )}
      </Box>
    )
  } else if (photosViewLevel === 'subfolder') {
    viewJSX = (
      <Box>
        {rJSX_ProjectTaskPagePhotosSubfolder(
          readOrWrite,
          photosViewType,
          setPhotosViewType,
          setPhotosViewLevel,
          setSelectedPhotosTaskFolder,
          setSelectedPhotosTaskFolderData,
          setSelectedPhotosTaskPageFolder,
          setSelectedPhotosTaskPageSubfolder,
          selectedPhotosTaskFolder,
          projectKey,
          projectTaskWorkflow,
          projectTasks,
          selectedPhotosTaskPageSubfolder,
          selectedPhotosTaskPageFolder,
          uc_RootData_ClientKey,
          uc_setRootData_ClientKey,
          availableImageTags,
          uc_setUserInterface_FormDialogDisplay,
          selectedPhotosTaskFolderData,
          uc_setUserInterface_CustomDialogDisplay,
          downloadingPhotos,
          setDownloadingPhotos,
          ur_forceRerender,
          uc_setUserInterface_ErrorDialogDisplay,
          uc_setUserInterface_AlertDialogDisplay,
          runningImageAnalysis,
          setRunningImageAnalysis,
          uc_setUserInterface_SnackbarDisplay,
          screenSize,
        )}
      </Box>
    )
  } else {
    viewJSX = (
      <Box>
        {rJSX_ProjectRootPhotosFolders(
          photosViewType,
          setPhotosViewType,
          projectRootPhotosFolders,
          setPhotosViewLevel,
          setSelectedPhotosTaskFolder,
          projectKey,
          projectTaskWorkflow,
          projectTasks,
        )}
      </Box>
    )
  }
  return viewJSX
}

const returnImageChipVariant = (tagKey: string, selectedAllImageFilterTag: any, selectedImageFilterTags: any): 'filled' | 'outlined' => {
  if (tagKey === 'all_photos') {
    return selectedAllImageFilterTag ? 'filled' : 'outlined'
  } else {
    return selectedImageFilterTags[tagKey] === true ? 'filled' : 'outlined'
  }
}

const rJSX_TagChip = (
  tagKey: string,
  tagName: JSX.Element | string,
  flatImageTagCounts: any,
  selectedAllImageFilterTag: any,
  selectedImageFilterTags: any,
  setSelectedAllImagesFilterTag: any,
  setSelectedImageFilterTags: any,
): JSX.Element => {
  let chipColor: 'error' | 'warning' | 'default' = 'warning'
  let chipCSS: string = 'tw-cursor-pointer tw-mr-2 tw-pl-1 tw-mb-2'
  let photoCount = getProp(flatImageTagCounts, tagKey, 0)
  if (photoCount === 0) {
    chipColor = 'default'
    chipCSS = 'tw-cursor-pointer tw-mr-2 tw-pl-1 tw-mb-2 tw-opacity-30'
    if (returnImageChipVariant(tagKey, selectedAllImageFilterTag, selectedImageFilterTags) === 'filled') {
      chipColor = 'error'
      chipCSS = 'tw-cursor-pointer tw-mr-2 tw-pl-1 tw-mb-2'
    }
  }
  let chipJSX = (
    <Chip
      color={chipColor}
      className={chipCSS}
      icon={
        <Icon
          icon="tag"
          size="xl"
        />
      }
      label={
        <Typography variant="h6">
          {tagName} ({getProp(flatImageTagCounts, tagKey, 0)})
        </Typography>
      }
      // variant={ selectedAllImageFilterTag === tagKey ? "filled" : "outlined" }
      variant={returnImageChipVariant(tagKey, selectedAllImageFilterTag, selectedImageFilterTags)}
      onClick={() => {
        if (tagKey === 'all_photos') {
          if (selectedAllImageFilterTag === true) {
            setSelectedAllImagesFilterTag(false)
          } else {
            setSelectedAllImagesFilterTag(true)
            setSelectedImageFilterTags({})
          }
        } else {
          setSelectedAllImagesFilterTag(false)
          let copiedSelectedImageFilterTags = cloneObjectWithoutReference(selectedImageFilterTags)
          if (copiedSelectedImageFilterTags[tagKey] === true) {
            delete copiedSelectedImageFilterTags[tagKey]
          } else {
            copiedSelectedImageFilterTags[tagKey] = true
          }
          if (objectToArray(copiedSelectedImageFilterTags).length === 0) {
            setSelectedImageFilterTags({})
          } else {
            setSelectedImageFilterTags(copiedSelectedImageFilterTags)
          }
        }
      }}
    />
  )
  return chipJSX
}

const rJSX_SelectableTagsList = (
  availableImageTags: any,
  flatImageTagCounts: any,
  selectedAllImageFilterTag: any,
  selectedImageFilterTags: any,
  setSelectedAllImagesFilterTag: any,
  setSelectedImageFilterTags: any,
): JSX.Element => {
  let tagsListJSX = (
    <Box>
      {rJSX_TagChip(
        'all_photos',
        rLIB('All Photos'),
        flatImageTagCounts,
        selectedAllImageFilterTag,
        selectedImageFilterTags,
        setSelectedAllImagesFilterTag,
        setSelectedImageFilterTags,
      )}
      {availableImageTags.map((tag: string, index: number) => (
        <Box
          key={index}
          className="tw-inline-block"
        >
          {rJSX_TagChip(
            tag,
            tag,
            flatImageTagCounts,
            selectedAllImageFilterTag,
            selectedImageFilterTags,
            setSelectedAllImagesFilterTag,
            setSelectedImageFilterTags,
          )}
        </Box>
      ))}

      {/* TASK TABS */}
    </Box>
  )
  return tagsListJSX
}

const rJSX_DownloadZippedImagesButton = (
  photosArray: TsInterface_UnspecifiedObject[],
  zipFolderName: string,
  downloadingPhotos: any,
  setDownloadingPhotos: any,
  ur_forceRerender: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  let buttonJSX = <></>
  let downloadIcon = (
    <Icon
      icon="download"
      className="tw-mr-2"
      type="solid"
    ></Icon>
  )
  if (downloadingPhotos === true) {
    downloadIcon = (
      <Icon
        icon="arrows-rotate"
        className="bp_spin tw-mr-2"
      />
    )
  }
  let imageTextJSX = rLIB('Images')
  if (objectToArray(photosArray).length === 1) {
    imageTextJSX = rLIB('Image')
  }
  buttonJSX = (
    <Button
      variant="outlined"
      color="info"
      className="tw-mr-2 tw-mb-1"
      disabled={downloadingPhotos === true || objectToArray(photosArray).length === 0}
      onClick={() => {
        setDownloadingPhotos(true)
        ur_forceRerender()
        DownloadFromUrlsAsZip(photosArray, zipFolderName)
          .then((res_DFUAZ) => {
            setDownloadingPhotos(false)
            ur_forceRerender()
          })
          .catch((rej_DFUAZ) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DFUAZ.error })
          })
      }}
    >
      {downloadIcon}
      {rLIB('Download')} {photosArray.length} {imageTextJSX}
    </Button>
  )
  return buttonJSX
}

const rJSX_ImagePreview = (photo: TsInterface_UnspecifiedObject): JSX.Element => {
  let imageJSX = <></>
  let heicWarningJSX = <></>
  let photoUrl = photo.url
  if (photo.thumbnail_url != null) {
    photoUrl = photo.thumbnail_url
  }

  // console.log( photo )

  // TODO - look at thumbnails

  if (photo != null && photo.name.endsWith('.heic')) {
    heicWarningJSX = <Box className="tw-opacity-50 tw-p-2">{rLIB('Double Click to view .heic image')}</Box>
    imageJSX = (
      <Box>
        <Box
          sx={{
            textAlign: 'center',
            height: '400px',
            backgroundImage: "url('" + photoUrl + "')",
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '5px',
            border: '1px solid ' + themeVariables.warning_main,
          }}
        >
          {heicWarningJSX}
        </Box>
        <Box className="tw-mb-4">{rJSX_PhotoListCaption(photo)}</Box>
      </Box>
    )
  } else {
    imageJSX = (
      <Box>
        <Box
          sx={{
            height: '400px',
            backgroundImage: "url('" + photoUrl + "')",
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '5px',
            border: '1px solid ' + themeVariables.background_paper,
          }}
        ></Box>
        <Box className="tw-mb-4">{rJSX_PhotoListCaption(photo)}</Box>
      </Box>
    )
  }
  return imageJSX
}

const rJSX_PhotosTagView = (
  readOrWrite: 'read' | 'write',
  photosViewType: any,
  setPhotosViewType: any,
  filteredPhotosList: any,
  downloadingPhotos: any,
  setDownloadingPhotos: any,
  ur_forceRerender: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  availableImageTags: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_FormDialogDisplay: any,
  allTaskFormData: any,
  projectKey: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  flatImageTagCounts: any,
  selectedAllImageFilterTag: any,
  selectedImageFilterTags: any,
  setSelectedAllImagesFilterTag: any,
  setSelectedImageFilterTags: any,
  uc_setUserInterface_AlertDialogDisplay: any,
  runningImageAnalysis: any,
  setRunningImageAnalysis: any,
  uc_setUserInterface_SnackbarDisplay: any,
  screenSize: any,
  selectedTags: string[] = [],
  setSelectedTags: (tags: string[]) => void = () => {},
  uc_RootData_ClientUser: TsInterface_UnspecifiedObject | null = null,
): JSX.Element => {
  const uploaderName = uc_RootData_ClientUser?.name
  let columns = 4
  if (screenSize === 'xs') {
    columns = 2
  } else if (screenSize === 'sm') {
    columns = 3
  } else if (screenSize === 'md') {
    columns = 4
  } else if (screenSize === 'lg') {
    columns = 5
  } else if (screenSize === 'xl') {
    columns = 6
  }
  let viewJSX = (
    <Box>
      <Box
        className="tw-mb-2"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {rJSX_TogglePhotoViewTypeButton(photosViewType, setPhotosViewType)}
        {rJSX_DownloadZippedImagesButton(
          filteredPhotosList,
          'tagged_photos.zip',
          downloadingPhotos,
          setDownloadingPhotos,
          ur_forceRerender,
          uc_setUserInterface_ErrorDialogDisplay,
        )}
        {rJSX_uploadImageFromProjectButton('etw_energy', projectKey, selectedTags, selectedTags.length === 0, uploaderName)}

        {rJSX_SelectImageTagsButton({
          availableImageTags,
          selectedTags,
          setSelectedTags,
        })}
      </Box>

      <Box>
        {rJSX_SelectableTagsList(
          availableImageTags,
          flatImageTagCounts,
          selectedAllImageFilterTag,
          selectedImageFilterTags,
          setSelectedAllImagesFilterTag,
          setSelectedImageFilterTags,
        )}
      </Box>
      <Box>
        <ImageList
          cols={columns}
          variant="standard"
        >
          {filteredPhotosList.sort(dynamicSort('timestamp_uploaded', 'asc')).map((photo: TsInterface_UnspecifiedObject, index: number) => (
            <ImageListItem
              key={index}
              onClick={(event) => {
                if (event.detail === 2) {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                    openPhotoDialogFromTagView(
                      readOrWrite,
                      res_GCK.clientKey,
                      photo.TEMP_subfolder_name,
                      photo.TEMP_upload_index,
                      photo,
                      availableImageTags,
                      uc_setUserInterface_FormDialogDisplay,
                      allTaskFormData,
                      projectKey,
                      uc_setUserInterface_CustomDialogDisplay,
                      uc_setUserInterface_ErrorDialogDisplay,
                      uc_setUserInterface_AlertDialogDisplay,
                      runningImageAnalysis,
                      setRunningImageAnalysis,
                      ur_forceRerender,
                      uc_setUserInterface_SnackbarDisplay,
                    )
                  })
                }
              }}
            >
              {rJSX_ImagePreview(photo)}
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  )
  return viewJSX
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const rJSX_PhotosTab = (
  readOrWrite: 'read' | 'write',
  uc_RootData_ClientKey: any,
  allTaskFormData: any, // TASKS
  availableImageTags: any,
  downloadingPhotos: any,
  filteredPhotosList: any,
  flatImageTagCounts: any,
  ur_forceRerender: any,
  photosViewLevel: any,
  photosViewType: any,
  projectKey: any,
  projectRootPhotosFolders: any,
  projectTaskWorkflow: any,
  projectTasks: any,
  selectedAllImageFilterTag: any,
  selectedImageFilterTags: any,
  selectedPhotosTaskFolder: any,
  selectedPhotosTaskFolderData: any,
  selectedPhotosTaskFolderForm: any,
  selectedPhotosTaskPageFolder: any,
  selectedPhotosTaskPageSubfolder: any,
  setDownloadingPhotos: any,
  setPhotosViewLevel: any,
  setPhotosViewType: any,
  uc_setRootData_ClientKey: any,
  setSelectedAllImagesFilterTag: any,
  setSelectedImageFilterTags: any,
  setSelectedPhotosTaskFolder: any,
  setSelectedPhotosTaskFolderData: any,
  setSelectedPhotosTaskPageFolder: any,
  setSelectedPhotosTaskPageSubfolder: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_setUserInterface_AlertDialogDisplay: any,
  runningImageAnalysis: any,
  setRunningImageAnalysis: any,
  uc_setUserInterface_SnackbarDisplay: any,
  screenSize: any,
  selectedTags: string[] = [],
  setSelectedTags: (tags: string[]) => void = () => {},
  uc_RootData_ClientUser: TsInterface_UnspecifiedObject | null = null,
): JSX.Element => {
  let tabJSX = <></>
  if (photosViewType === 'folder') {
    tabJSX = rJSX_PhotosFolderView(
      readOrWrite,
      photosViewLevel,
      photosViewType,
      setPhotosViewType,
      projectRootPhotosFolders,
      setPhotosViewLevel,
      setSelectedPhotosTaskFolder,
      projectKey,
      projectTaskWorkflow,
      projectTasks,
      selectedPhotosTaskFolderData,
      selectedPhotosTaskFolderForm,
      setSelectedPhotosTaskFolderData,
      setSelectedPhotosTaskPageFolder,
      setSelectedPhotosTaskPageSubfolder,
      selectedPhotosTaskFolder,
      selectedPhotosTaskPageFolder,
      selectedPhotosTaskPageSubfolder,
      uc_RootData_ClientKey,
      uc_setRootData_ClientKey,
      availableImageTags,
      uc_setUserInterface_FormDialogDisplay,
      uc_setUserInterface_CustomDialogDisplay,
      downloadingPhotos,
      setDownloadingPhotos,
      ur_forceRerender,
      uc_setUserInterface_ErrorDialogDisplay,
      uc_setUserInterface_AlertDialogDisplay,
      runningImageAnalysis,
      setRunningImageAnalysis,
      uc_setUserInterface_SnackbarDisplay,
      screenSize,
    )
  } else if (photosViewType === 'tag') {
    tabJSX = rJSX_PhotosTagView(
      readOrWrite,
      photosViewType,
      setPhotosViewType,
      filteredPhotosList,
      downloadingPhotos,
      setDownloadingPhotos,
      ur_forceRerender,
      uc_setUserInterface_ErrorDialogDisplay,
      availableImageTags,
      uc_RootData_ClientKey,
      uc_setRootData_ClientKey,
      uc_setUserInterface_FormDialogDisplay,
      allTaskFormData,
      projectKey,
      uc_setUserInterface_CustomDialogDisplay,
      flatImageTagCounts,
      selectedAllImageFilterTag,
      selectedImageFilterTags,
      setSelectedAllImagesFilterTag,
      setSelectedImageFilterTags,
      uc_setUserInterface_AlertDialogDisplay,
      runningImageAnalysis,
      setRunningImageAnalysis,
      uc_setUserInterface_SnackbarDisplay,
      screenSize,
      selectedTags, // Pass selectedTags
      setSelectedTags,
      uc_RootData_ClientUser,
    )
  } else {
    tabJSX = rJSX_PhotosFolderView(
      readOrWrite,
      photosViewLevel,
      photosViewType,
      setPhotosViewType,
      projectRootPhotosFolders,
      setPhotosViewLevel,
      setSelectedPhotosTaskFolder,
      projectKey,
      projectTaskWorkflow,
      projectTasks,
      selectedPhotosTaskFolderData,
      selectedPhotosTaskFolderForm,
      setSelectedPhotosTaskFolderData,
      setSelectedPhotosTaskPageFolder,
      setSelectedPhotosTaskPageSubfolder,
      selectedPhotosTaskFolder,
      selectedPhotosTaskPageFolder,
      selectedPhotosTaskPageSubfolder,
      uc_RootData_ClientKey,
      uc_setRootData_ClientKey,
      availableImageTags,
      uc_setUserInterface_FormDialogDisplay,
      uc_setUserInterface_CustomDialogDisplay,
      downloadingPhotos,
      setDownloadingPhotos,
      ur_forceRerender,
      uc_setUserInterface_ErrorDialogDisplay,
      uc_setUserInterface_AlertDialogDisplay,
      runningImageAnalysis,
      setRunningImageAnalysis,
      uc_setUserInterface_SnackbarDisplay,
      screenSize,
    )
  }
  return tabJSX
}
