///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material'
import { DatabaseRef_Region_Document } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Functions
///////////////////////////////

const calculateDistanceBetweenTwoPoints = (origin: string, destination: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let service = new google.maps.DistanceMatrixService()
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      },
      (response, status) => {
        if (
          status === 'OK' &&
          response != null &&
          response.rows != null &&
          response.rows[0] != null &&
          response.rows[0].elements != null &&
          response.rows[0].elements[0] != null &&
          response.rows[0].elements[0].distance != null &&
          response.rows[0].elements[0].distance.value != null
        ) {
          resolve({ success: true, miles: response.rows[0].elements[0].distance.value / 1609.344 })
        } else {
          reject({
            success: false,
            error: {
              message: rLIB('Failed to calculate miles'),
              details: rLIB('An unknown error occurred'),
              code: 'ER-D-TDS-CDBTP-01',
            },
          })
        }
      },
    )
  })
}

const openAddressDirectionsPage = (origin: string, destination: string): void => {
  let url = 'https://www.google.com/maps/dir/' + origin + '/' + destination
  window.open(url, '_blank')
}

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_ProjectCustomerDetails: TsInterface_FormInputs = {
  associated_customer_email: {
    key: 'associated_customer_email',
    label: rLIB('Customer Email'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  associated_customer_phone: {
    key: 'associated_customer_phone',
    label: rLIB('Customer Phone'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  associated_customer_additional_phone: {
    key: 'associated_customer_additional_phone',
    label: rLIB('Customer Additional Phone'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  location_distance_from_warehouse: {
    key: 'location_distance_from_warehouse',
    label: rLIB('Distance from warehouse'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  location_distance_from_warehouse_button: {
    data_type: 'string',
    key: 'location_distance_from_warehouse_button',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      let inputJSX = (
        <Box className="tw-text-center">
          <Button
            className="tw-mr-2"
            variant="contained"
            color="info"
            onClick={() => {
              if (formData != null && formData.associated_region_key != null) {
                if (formData.location_address != null && formData.location_city != null && formData.location_state != null && formData.location_zip != null) {
                  getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseGetDocument(DatabaseRef_Region_Document(res_GCK.clientKey, formData.associated_region_key as string))
                        .then((res_DGD) => {
                          if (
                            res_DGD != null &&
                            res_DGD.data != null &&
                            res_DGD.data.location_address != null &&
                            res_DGD.data.location_city != null &&
                            res_DGD.data.location_state != null &&
                            res_DGD.data.location_zip != null
                          ) {
                            let projectAddress =
                              formData.location_address + ', ' + formData.location_city + ', ' + formData.location_state + ' ' + formData.location_zip
                            let warehouseAddress =
                              res_DGD.data.location_address +
                              ', ' +
                              res_DGD.data.location_city +
                              ', ' +
                              res_DGD.data.location_state +
                              ' ' +
                              res_DGD.data.location_zip
                            calculateDistanceBetweenTwoPoints(warehouseAddress, projectAddress)
                              .then((res_CDBTP) => {
                                // @ts-expect-error - TODO: reason for error
                                formData.location_distance_from_warehouse = parseFloat(res_CDBTP.miles.toFixed(1))
                                formHooks.ur_forceRerender()
                              })
                              .catch((rej_CDBTP) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({
                                  display: true,
                                  error: rej_CDBTP.error,
                                })
                              })
                          } else {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({
                              display: true,
                              error: {
                                message: rLIB('Failed to calculate miles'),
                                details: rLIB('No address on region'),
                                code: 'ER-D-TDS-CDBTP-01',
                              },
                            })
                          }
                        })
                        .catch((rej_DGD) => {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                } else {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: {
                      message: rLIB('Failed to calculate miles'),
                      details: rLIB('No address on project'),
                      code: 'ER-D-TDS-CDBTP-02',
                    },
                  })
                }
              } else {
                formHooks.uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: {
                    message: rLIB('Failed to calculate miles'),
                    details: rLIB('Missing assigned region'),
                    code: 'ER-D-TDS-CDBTP-03',
                  },
                })
              }
            }}
          >
            <Icon
              icon="diamond-turn-right"
              className="tw-mr-2"
            />
            {rLIB('Calculate Distance')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (formData != null && formData.associated_region_key != null) {
                if (formData.location_address != null && formData.location_city != null && formData.location_state != null && formData.location_zip != null) {
                  getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseGetDocument(DatabaseRef_Region_Document(res_GCK.clientKey, formData.associated_region_key as string))
                        .then((res_DGD) => {
                          if (
                            res_DGD != null &&
                            res_DGD.data != null &&
                            res_DGD.data.location_address != null &&
                            res_DGD.data.location_city != null &&
                            res_DGD.data.location_state != null &&
                            res_DGD.data.location_zip != null
                          ) {
                            let projectAddress =
                              formData.location_address + ', ' + formData.location_city + ', ' + formData.location_state + ' ' + formData.location_zip
                            let warehouseAddress =
                              res_DGD.data.location_address +
                              ', ' +
                              res_DGD.data.location_city +
                              ', ' +
                              res_DGD.data.location_state +
                              ' ' +
                              res_DGD.data.location_zip
                            openAddressDirectionsPage(warehouseAddress, projectAddress)
                          } else {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({
                              display: true,
                              error: {
                                message: rLIB('Failed to calculate miles'),
                                details: rLIB('No address on region'),
                                code: 'ER-D-TDS-CDBTP-01',
                              },
                            })
                          }
                        })
                        .catch((rej_DGD) => {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                } else {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: {
                      message: rLIB('Failed to calculate miles'),
                      details: rLIB('No address on project'),
                      code: 'ER-D-TDS-CDBTP-02',
                    },
                  })
                }
              } else {
                formHooks.uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: {
                    message: rLIB('Failed to calculate miles'),
                    details: rLIB('Missing assigned region'),
                    code: 'ER-D-TDS-CDBTP-03',
                  },
                })
              }
            }}
          >
            <Icon
              icon="diamond-turn-right"
              className="tw-mr-2"
            />
            {rLIB('View Route')}
          </Button>
        </Box>
      )
      return inputJSX
    },
  },
  location_access_instructions: {
    key: 'location_access_instructions',
    label: rLIB('Gate Code / Access Instructions'),
    input_type: 'text_multiline',
    required: false,
    data_type: 'string',
  },
  associated_sales_rep_name: {
    key: 'associated_sales_rep_name',
    label: rLIB('Sales Rep Name'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  associated_sales_rep_phone: {
    key: 'associated_sales_rep_phone',
    label: rLIB('Sales Rep Phone'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  associated_sales_rep_email: {
    key: 'associated_sales_rep_email',
    label: rLIB('Sales Rep Email'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  associated_sales_rep_team_name: {
    key: 'associated_sales_rep_team_name',
    label: rLIB('Sales Rep Team Name'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
}
