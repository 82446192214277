///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, MenuItem, Select, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_AllActiveIncidentTypes_Query,
  DatabaseRef_IncidentReportFiles_Collection,
  DatabaseRef_IncidentReportFile_Document,
  DatabaseRef_IncidentReportForumMessages_Collection,
  DatabaseRef_IncidentReportForumMessage_Document,
  DatabaseRef_IncidentReportForumThreads_Collection,
  DatabaseRef_IncidentReportForumThread_Document,
  DatabaseRef_IncidentReport_Document,
} from 'rfbp_aux/services/database_endpoints/operations/incident_reports'
import { StorageRef_IncidentReportsFile, StorageRef_IncidentReports_MessageThread_File } from 'rfbp_aux/services/storage_endpoints/incident_reports'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import { ForumBasic } from 'rfbp_core/components/forum/forum_basic'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { formatCurrency } from '../../../rfbp_core/services/helper_functions/index'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseIncidentReportViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_BACK_TO_ALL_INCIDENT_REPORTS: JSX.Element = <Trans>Back to all incident reports</Trans>
const s_DRIVER: JSX.Element = <Trans>Driver</Trans>
const s_FILES: JSX.Element = <Trans>Files</Trans>
const s_INCIDENT_DATE: JSX.Element = <Trans>Incident Date</Trans>
const s_INCIDENT_LOCATION: JSX.Element = <Trans>Incident Location</Trans>
const s_INCIDENT_REPORT: JSX.Element = <Trans>Incident Report</Trans>
const s_INCIDENT_TYPE: JSX.Element = <Trans>Incident Type</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_STATUS: JSX.Element = <Trans>Status</Trans>
const s_VEHICLE_VIN: JSX.Element = <Trans>Vehicle VIN</Trans>
const s_WHAT_HAPPENED: JSX.Element = <Trans>What happened?</Trans>
const se_INCIDENT_REPORT: string = 'Incident Report'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const vehicleIncidentReportKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_vehicleIncidentReport, us_setIncidentReport] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_incidentTypes, us_setIncidentTypes] = useState<TsInterface_UnspecifiedObject>({})

  const [isEditingCost, setIsEditingCost] = useState<boolean>(false)
  const [tempCostValue, setTempCostValue] = useState<string | number>(us_vehicleIncidentReport.cost_of_damage || '')

  const [isEditingStatus, setIsEditingStatus] = useState(false)
  const [tempStatusValue, setTempStatusValue] = useState(us_vehicleIncidentReport['status'] || '')

  const [isEditingType, setIsEditingType] = useState(false) // To track edit mode
  const [tempTypeValue, setTempTypeValue] = useState('') // To hold the temp incident type value

  // Hooks - useEffect

  useEffect(() => {
    document.title = se_INCIDENT_REPORT
  }, [])

  //Get Incident Types
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    let status = 'active'
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setIncidentTypes(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllActiveIncidentTypes_Query(res_GCK.clientKey, status), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setIncidentReport(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_IncidentReport_Document(res_GCK.clientKey, vehicleIncidentReportKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, vehicleIncidentReportKey])

  // Functions

  // Function to handle saving the updated cost of damage
  const handleSaveCost = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          const updateObject = {
            cost_of_damage: tempCostValue,
          }

          // Perform the update on the Firestore document
          DatabaseSetMergeDocument(DatabaseRef_IncidentReport_Document(res_GCK.clientKey, vehicleIncidentReportKey), updateObject)
            .then((res_DSMD) => {
              // Update the local state after saving to Firestore
              us_setIncidentReport({
                ...us_vehicleIncidentReport,
                cost_of_damage: tempCostValue,
              })
              setIsEditingCost(false) // Exit the edit mode
              resolve() // Resolve without passing res_DSMD since it's not expected
            })
            .catch((rej_DSMD) => {
              console.error('Error updating cost of damage:', rej_DSMD)
              reject(rej_DSMD) // Reject the promise in case of an error
            })
        })
        .catch((rej_GCK) => {
          console.error('Error fetching client key:', rej_GCK)
          reject(rej_GCK) // Reject the promise if getting client key fails
        })
    })
  }

  // Function to handle canceling the edit
  const handleCancelCostEdit = () => {
    setTempCostValue(us_vehicleIncidentReport.cost_of_damage || '')
    setIsEditingCost(false)
  }

  const handleSaveStatus = () => {
    // Update Firestore with the new status
    handleSaveStatusToDB(tempStatusValue).then(() => {
      setIsEditingStatus(false) // Exit edit mode
      us_setIncidentReport({
        ...us_vehicleIncidentReport,
        status: tempStatusValue,
      })
    })
  }

  const handleCancelStatusEdit = () => {
    setTempStatusValue(us_vehicleIncidentReport['status']) // Reset to the current value
    setIsEditingStatus(false) // Exit edit mode
  }

  const handleSaveStatusToDB = (newStatus: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          const updateObject = {
            status: newStatus,
          }

          DatabaseSetMergeDocument(DatabaseRef_IncidentReport_Document(res_GCK.clientKey, vehicleIncidentReportKey), updateObject)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              console.error('Error updating status:', error)
              reject(error)
            })
        })
        .catch((error) => {
          console.error('Error fetching client key:', error)
          reject(error)
        })
    })
  }

  const handleSaveType = () => {
    // Make sure the user selected a valid type
    if (!tempTypeValue) return

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const updateObject = {
          incident_type: tempTypeValue, // Save the new incident type
        }

        // Update the database with the new incident type
        DatabaseSetMergeDocument(DatabaseRef_IncidentReport_Document(res_GCK.clientKey, vehicleIncidentReportKey), updateObject)
          .then((res_DSMD) => {
            // Successfully saved, now update the local state
            us_setIncidentReport((prevData) => ({
              ...prevData,
              incident_type: tempTypeValue,
            }))
            setIsEditingType(false) // Exit editing mode
          })
          .catch((err_DSMD) => {
            console.error('Error saving incident type:', err_DSMD)
          })
      })
      .catch((err_GCK) => {
        console.error('Error getting client key:', err_GCK)
      })
  }

  const handleCancelTypeEdit = () => {
    setTempTypeValue(us_vehicleIncidentReport.incident_type || '') // Reset the temp value
    setIsEditingType(false) // Exit editing mode
  }

  const handleStartTypeEdit = () => {
    setTempTypeValue(us_vehicleIncidentReport.incident_type || '') // Set the temp value to the current type
    setIsEditingType(true) // Enter editing mode
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseIncidentReportsListPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {s_BACK_TO_ALL_INCIDENT_REPORTS}
      </Button>
    )
    return buttonJSX
  }

  // Details Tab
  const rJSX_MissingText = (): JSX.Element => {
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-opacity-30 tw-italic"
      >
        {s_MISSING}
      </Box>
    )
    return missingTextJSX
  }

  const rJSX_TimestampDetails = (label: JSX.Element, propKey: string): JSX.Element => {
    let detailsJSX = <></>
    let dateJSX = rJSX_MissingText()
    if (us_vehicleIncidentReport[propKey] != null) {
      dateJSX = (
        <Box
          component={'span'}
          className="tw-mr-2 tw-opacity-50"
        >
          {returnFormattedDate(us_vehicleIncidentReport[propKey], 'D MMM YYYY h:mm a')}
        </Box>
      )
    }
    detailsJSX = (
      <Typography
        variant="h6"
        className=""
      >
        <Box
          component={'span'}
          className="tw-mr-2"
        >
          {label}:
        </Box>
        {dateJSX}
      </Typography>
    )
    return detailsJSX
  }

  const rJSX_StringDetails = (label: any, propKey: string): JSX.Element => {
    let detailsJSX = <></>
    let valueJSX = rJSX_MissingText()
    if (us_vehicleIncidentReport[propKey] != null) {
      valueJSX = (
        <Box
          component={'span'}
          className="tw-mr-2 tw-opacity-50"
        >
          {us_vehicleIncidentReport[propKey]}
        </Box>
      )
    }
    detailsJSX = (
      <Typography
        variant="h6"
        className=""
      >
        <Box
          component={'span'}
          className="tw-mr-2"
        >
          {label}:
        </Box>
        {valueJSX}
      </Typography>
    )
    return detailsJSX
  }

  const rJSX_EmployeeDetails = (label: any, propKey: string): JSX.Element => {
    let detailsJSX = <></>
    let valueJSX = rJSX_MissingText()

    // Log the us_vehicleIncidentReport for debugging
    console.log('us_vehicleIncidentReport:', us_vehicleIncidentReport)

    // Check if the propKey is associated_users and handle it as a map (object)
    if (propKey === 'associated_users' && typeof us_vehicleIncidentReport[propKey] === 'object') {
      const associatedUsers = us_vehicleIncidentReport[propKey] as Record<string, string>
      console.log('associated_users found:', associatedUsers)

      // Map over the associated_users object and display each user's name
      const usersList = Object.entries(associatedUsers).map(([key, name], index) => (
        <Box
          key={key}
          component={'span'}
          className="tw-mr-2 tw-opacity-50"
        >
          {name}
          {index < Object.keys(associatedUsers).length - 1 ? ', ' : ''} {/* Add comma between users except the last one */}
        </Box>
      ))

      valueJSX = <>{usersList}</>
    } else if (us_vehicleIncidentReport[propKey] != null && propKey !== 'associated_users') {
      // For non-array properties like associated_user_name, treat them as single values
      valueJSX = (
        <Box
          component={'span'}
          className="tw-mr-2 tw-opacity-50"
        >
          {us_vehicleIncidentReport[propKey]}
        </Box>
      )
    } else {
      console.log(`Property ${propKey} is not 'associated_users' or is not a map.`)
    }

    detailsJSX = (
      <Typography
        variant="h6"
        className=""
      >
        <Box
          component={'span'}
          className="tw-mr-2"
        >
          {label}:
        </Box>
        {valueJSX}
      </Typography>
    )

    return detailsJSX
  }

  const rJSX_StatusDetails = (
    label: JSX.Element,
    propKey: string,
    isEditingStatus: boolean,
    tempStatusValue: string,
    setTempStatusValue: (value: string) => void,
    handleSaveStatus: () => void,
    handleCancelStatusEdit: () => void,
    setIsEditingStatus: (value: boolean) => void,
  ): JSX.Element => {
    let detailsJSX = <></>
    let valueJSX = rJSX_MissingText()

    // Check if status exists in the incident report data
    if (us_vehicleIncidentReport[propKey] != null) {
      if (isEditingStatus) {
        // Ensure the tempStatusValue is set to the current status when editing starts
        if (tempStatusValue === '') {
          setTempStatusValue(us_vehicleIncidentReport[propKey]) // Autofill with the current status
        }

        // Show the select dropdown when in edit mode
        valueJSX = (
          <Box
            display="flex"
            alignItems="center"
          >
            <FormControl
              size="small"
              sx={{ minWidth: 200 }}
            >
              <Select
                value={tempStatusValue}
                onChange={(e) => setTempStatusValue(e.target.value)}
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="Under Investigation">Under Investigation</MenuItem>
                <MenuItem value="Reviewed/Closed">Reviewed/Closed</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveStatus}
              size="small"
              sx={{ marginLeft: '8px' }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancelStatusEdit}
              size="small"
              sx={{ marginLeft: '8px' }}
            >
              Cancel
            </Button>
          </Box>
        )
      } else {
        // Show the status value and edit icon when not in edit mode
        valueJSX = (
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography
              component="span"
              sx={{ marginRight: '8px', opacity: 0.7 }}
            >
              {us_vehicleIncidentReport[propKey]}
            </Typography>
            <Icon
              icon="pencil"
              className="tw-cursor-pointer"
              onClick={() => {
                setIsEditingStatus(true) // Switch to edit mode
                setTempStatusValue(us_vehicleIncidentReport[propKey]) // Autofill the current status value
              }}
              sx={{ cursor: 'pointer', opacity: 0.7 }}
            />
          </Box>
        )
      }
    }

    detailsJSX = (
      <Box
        display="flex"
        alignItems="center"
      >
        <Typography
          variant="h6"
          className=""
        >
          <Box
            component="span"
            className="tw-mr-2"
          >
            {label}:
          </Box>
        </Typography>
        {valueJSX}
      </Box>
    )

    return detailsJSX
  }

  const rJSX_CostDetails = (
    label: string,
    propKey: string,
    isEditing: boolean,
    tempValue: string | number,
    setTempValue: (value: string | number) => void,
    handleSave: () => void,
    handleCancel: () => void,
    setIsEditing: (value: boolean) => void,
  ): JSX.Element => {
    // Get the current value from the incident report or default to 0.00 if not present
    const currentValue = us_vehicleIncidentReport[propKey] != null ? formatCurrency(us_vehicleIncidentReport[propKey]) : formatCurrency(0)

    // In edit mode: Show an input field and buttons for saving/canceling
    const editModeJSX = (
      <Box
        display="flex"
        alignItems="center"
      >
        <input
          type="text"
          value={tempValue}
          onChange={(e) => setTempValue(e.target.value)}
          style={{
            padding: '2px 4px',
            border: '1px solid #ccc',
            outline: 'none',
            fontSize: 'inherit',
            width: 'auto',
            borderRadius: '4px',
            color: 'white',
            backgroundColor: 'transparent', // No background color
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          size="small"
          sx={{ marginLeft: '8px' }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancel}
          size="small"
          sx={{ marginLeft: '8px' }}
        >
          Cancel
        </Button>
      </Box>
    )

    // In view mode: Show the formatted currency (or default to 0.00) and a pencil icon to edit
    const viewModeJSX = (
      <Box
        display="flex"
        alignItems="center"
      >
        <Typography
          component="span"
          sx={{ marginRight: '8px', opacity: 0.7 }}
        >
          {currentValue}
        </Typography>
        <Icon
          icon="pencil"
          className="tw-cursor-pointer"
          onClick={() => {
            setIsEditing(true)
            setTempValue(us_vehicleIncidentReport[propKey] || 0) // Autofill the current value on edit click
          }}
          sx={{ cursor: 'pointer', opacity: 0.7 }}
        />
      </Box>
    )

    // JSX for the cost details section
    return (
      <Box
        display="flex"
        alignItems="center"
      >
        <Typography
          variant="h6"
          className=""
          sx={{ marginRight: '8px' }}
        >
          {label}:
        </Typography>
        {isEditing ? editModeJSX : viewModeJSX}
      </Box>
    )
  }

  const rJSX_TypeDetails = (
    label: JSX.Element,
    propKey: string,
    isEditingType: boolean,
    tempTypeValue: string,
    setTempTypeValue: (value: string) => void,
    handleSaveType: () => void,
    handleCancelTypeEdit: () => void,
    setIsEditingType: (value: boolean) => void,
  ): JSX.Element => {
    let detailsJSX = <></>

    // Check if there's a current incident type or default to an empty string
    const currentIncidentType = us_vehicleIncidentReport[propKey] || ''

    // Edit mode: Show a dropdown to select the type
    if (isEditingType) {
      detailsJSX = (
        <Box
          display="flex"
          alignItems="center"
        >
          <FormControl
            size="small"
            sx={{ minWidth: 200 }}
          >
            <Select
              value={tempTypeValue || currentIncidentType} // Default to current type or temp value
              onChange={(e) => setTempTypeValue(e.target.value)}
              renderValue={(selected) => {
                const incidentTypeData = us_incidentTypes[selected as string]
                const { incident_name, intensity, icon } = incidentTypeData || {}
                const backgroundColor = intensity === 'severe' ? 'error.main' : 'warning.main'
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      backgroundColor: backgroundColor,
                      borderRadius: '50px',
                      padding: '4px 12px',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    <Icon
                      icon={icon}
                      className="tw-mr-2"
                      sx={{ color: 'white' }}
                    />
                    <Typography variant="body1">{incident_name}</Typography>
                  </Box>
                )
              }}
            >
              {/* Map over available incident types */}
              {Object.keys(us_incidentTypes).map((typeKey) => {
                const incidentTypeData = us_incidentTypes[typeKey]
                const { incident_name, intensity, icon } = incidentTypeData || {}
                const backgroundColor = intensity === 'severe' ? 'error.main' : 'warning.main'
                return (
                  <MenuItem
                    key={typeKey}
                    value={typeKey}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        backgroundColor: backgroundColor,
                        borderRadius: '50px',
                        padding: '4px 12px',
                        color: 'white',
                        fontWeight: 'bold',
                        maxWidth: 'fit-content',
                      }}
                    >
                      <Icon
                        icon={icon}
                        className="tw-mr-2"
                        sx={{ color: 'white' }}
                      />
                      <Typography variant="body1">{incident_name}</Typography>
                    </Box>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveType}
            size="small"
            sx={{ marginLeft: '8px' }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancelTypeEdit}
            size="small"
            sx={{ marginLeft: '8px' }}
          >
            Cancel
          </Button>
        </Box>
      )
    } else {
      // View mode: Show the current incident type with an icon, or "No Type" if it's missing
      if (currentIncidentType && us_incidentTypes[currentIncidentType]) {
        const incidentTypeData = us_incidentTypes[currentIncidentType]
        const { incident_name, intensity, icon } = incidentTypeData
        const backgroundColor = intensity === 'severe' ? 'error.main' : 'warning.main'

        detailsJSX = (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: backgroundColor,
              borderRadius: '50px',
              padding: '4px 12px',
              color: 'white',
              fontWeight: 'bold',
              maxWidth: 'fit-content',
            }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
              sx={{ color: 'white' }}
            />
            <Typography variant="body1">{incident_name}</Typography>
            <Icon
              icon="pencil"
              className="tw-cursor-pointer tw-ml-2"
              onClick={() => setIsEditingType(true)}
              sx={{ cursor: 'pointer', opacity: 0.7, marginLeft: '8px' }}
            />
          </Box>
        )
      } else {
        detailsJSX = (
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="body1"
              sx={{ opacity: 0.7 }}
            >
              No Type Assigned
            </Typography>
            <Icon
              icon="pencil"
              className="tw-cursor-pointer tw-ml-4"
              onClick={() => setIsEditingType(true)}
              sx={{ cursor: 'pointer', opacity: 0.7, marginLeft: '8px' }}
            />
          </Box>
        )
      }
    }

    // Return the final JSX
    return (
      <Box
        display="flex"
        alignItems="center"
      >
        <Typography
          variant="h6"
          sx={{ marginRight: '8px' }}
        >
          {label}:
        </Typography>
        {detailsJSX}
      </Box>
    )
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    return (
      <Card className="tw-p-2">
        {rJSX_TimestampDetails(s_INCIDENT_DATE, 'timestamp_incident')}
        {rJSX_EmployeeDetails('Employee Name', 'associated_users')}
        {rJSX_StringDetails(s_VEHICLE_VIN, 'associated_vehicle_vin')}
        {rJSX_StringDetails(s_INCIDENT_LOCATION, 'incident_location')}
        {rJSX_StringDetails(s_WHAT_HAPPENED, 'notes')}
        {rJSX_TypeDetails(
          s_INCIDENT_TYPE,
          'incident_type',
          isEditingType, // Pass the editing state for type
          tempTypeValue, // Pass the temporary type value
          setTempTypeValue, // Pass the setter for the temp value
          handleSaveType, // Pass the save handler
          handleCancelTypeEdit, // Pass the cancel handler
          setIsEditingType, // Pass the function to toggle edit mode
        )}

        {/* Cost of Damage Field with Edit Functionality */}
        {rJSX_CostDetails(
          'Cost of Damage',
          'cost_of_damage',
          isEditingCost, // Pass the editing state
          tempCostValue, // Pass the temporary cost value
          setTempCostValue, // Pass the setter for the temp value
          handleSaveCost, // Pass the save handler
          handleCancelCostEdit, // Pass the cancel handler
          setIsEditingCost, // Pass the function to toggle edit mode
        )}

        {/* Status Field with Edit Functionality */}
        {rJSX_StatusDetails(
          s_STATUS,
          'status',
          isEditingStatus, // Whether we're editing the status
          tempStatusValue, // Temporary status value
          setTempStatusValue, // Function to update temporary status value
          handleSaveStatus, // Function to save the status change
          handleCancelStatusEdit, // Function to cancel editing the status
          setIsEditingStatus, // Function to toggle status editing mode
        )}
      </Card>
    )
  }

  /*
			associated_user_name


			timestamp_incident
			associated_user_name
			associated_vehicle_vin

			*/

  // Notes Tab
  const rJSX_NotesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <ForumBasic
          forumThreadCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_IncidentReportForumThreads_Collection(clientKey, vehicleIncidentReportKey)
          }}
          forumThreadDocumentDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_IncidentReportForumThread_Document(clientKey, vehicleIncidentReportKey, threadKey)
          }}
          forumMessageCollectionDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_IncidentReportForumMessages_Collection(clientKey, vehicleIncidentReportKey, threadKey)
          }}
          forumMessageDocumentDatabaseEndpoint={(clientKey, threadKey, messageKey) => {
            return DatabaseRef_IncidentReportForumMessage_Document(clientKey, vehicleIncidentReportKey, threadKey, messageKey)
          }}
          forumMessageStorageEndpoint={(clientKey, threadKey, messageKey, fileName) => {
            return StorageRef_IncidentReports_MessageThread_File(clientKey, vehicleIncidentReportKey, threadKey, fileName)
          }}
          forumSettings={{
            allow_thread_creation: true,
            scrollbar_track_color: '#272b33',
            scrollbar_thumb_color: '#888',
            input_text_color: '#fff',
            base_message_container_offset_px: 336,
          }}
        />
      </Box>
    )
    return tabJSX
  }

  // Files Tab
  const rJSX_FilesTab = (): JSX.Element => {
    let tabJSX = (
      <FileSystemBasic
        fileSystemCollectionDatabaseEndpoint={(clientKey) => {
          return DatabaseRef_IncidentReportFiles_Collection(clientKey, vehicleIncidentReportKey)
        }}
        fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
          return DatabaseRef_IncidentReportFile_Document(clientKey, vehicleIncidentReportKey, fileKey)
        }}
        fileSystemStorageEndpoint={(clientKey, fileName) => {
          return StorageRef_IncidentReportsFile(clientKey, vehicleIncidentReportKey, fileName)
        }}
        fileSystemHardCodedFolders={{}}
        fileSystemSettings={{
          allow_file_archiving: true,
          allow_file_movement: true,
          allow_file_unarchiving: true,
          allow_file_uploads: true,
          allow_folder_creation: true,
          allow_folder_deletion: true,
          allow_folder_movement: true,
          allow_folder_rename: true,
          archive_filter_visible: true,
        }}
      />
    )
    return tabJSX
  }

  // Page
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_INCIDENT_REPORT}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box>
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminDatabaseIncidentReportViewPage.url(vehicleIncidentReportKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: se_INCIDENT_REPORT,
                }}
                tabs={[
                  {
                    tabUrlKey: 'Details',
                    tabHeader: rLIB('Details'),
                    tabContent: rJSX_DetailsTab(),
                  },
                  {
                    tabUrlKey: 'Notes',
                    tabHeader: s_NOTES,
                    tabContent: rJSX_NotesTab(),
                  },
                  {
                    tabUrlKey: 'Files',
                    tabHeader: s_FILES,
                    tabContent: rJSX_FilesTab(),
                  },
                ]}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
