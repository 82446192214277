import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider as MuiSlider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic, TsInterface_MapMarker, TsInterface_MapMarkers, TsInterface_MapSettings } from 'rfbp_core/components/map'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getCoordinatesFromAddress } from 'rfbp_core/services/helper_functions/get_coordinates_from_address'
import { setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { authWithPassword } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { formatNumberToDollar } from './components/helper'
import { zipCodesAccepted } from './data/sales_opportunity_v3_data'
import { completeOpportunitySubmission, submitOffsetChange, updateOpportunity } from './functions/sales_opportunity_v3_functions'
import { TsInterface_LeadData } from './interfaces/i_sales_tools'
import {
  rJSX_AdditionalOptionsSection,
  rJSX_FinancingDetails,
  rJSX_FinancingSection,
  rJSX_HelpMeChooseSection,
  rJSX_SystemDetails,
  rJSX_SystemSizeSection,
  rJSX_UserDetails,
} from './unauthenticated_sales_opportunity_v3_components/building_page_sections'

const PHONE_NUMBER = '(312) 728-3403'
const DEFAULT_LAT = 37.77492
const DEFAULT_LNG = -122.4194
const DEFAULT_ADDRESS = '1580 Market Street'
const DEFAULT_CITY = 'San Francisco'
const DEFAULT_STATE = 'CA'
const DEFAULT_ZIP = '94102'

export const rJSX_EntryPage = (
  analyticsData: TsInterface_UnspecifiedObject,
  palette: TsInterface_UnspecifiedObject,
  stateGettersSetters: TsInterface_UnspecifiedObject,
  ur_mapRef: TsInterface_UnspecifiedObject,
  opportunityKey: string,
  clientKey: string,
  leadData?: TsInterface_LeadData,
) => {
  const mapSettings: TsInterface_MapSettings = {
    center_lat: leadData?.opportunityData?.location_latitude ?? stateGettersSetters.us_latitude,
    center_lng: leadData?.opportunityData?.location_longitude ?? stateGettersSetters.us_longitude,
    map_type_id: 'satellite',
    height: '300px',
    width: 'calc(90vw)',
    zoom: 20,
    tilt: 0,
    render_heatmap: false,
    additional_map_options: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    },
  }

  const houseMarker: TsInterface_MapMarker = {
    label: '',
    position: {
      lat: mapSettings.center_lat,
      lng: mapSettings.center_lng,
    },
    icon: {
      path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
      fillColor: palette.aqua,
      fillOpacity: 1,
      strokeWeight: 0.5,
      strokeColor: 'white',
      rotation: 0,
      scale: 0.1,
      anchor: new google.maps.Point(0, 0),
    },
  }

  let mapMarkers: TsInterface_MapMarkers = { houseMarker }

  const handleEditAddressClickOpen = () => {
    stateGettersSetters.us_setEditAddressOpen(true)
  }

  const updateMapWithNewAddress = async (newAddress: string) => {
    try {
      const geocodeResult = await getCoordinatesFromAddress(newAddress)
      const lat = getProp(geocodeResult, 'coordinates.latitude', stateGettersSetters.us_latitude)
      const lng = getProp(geocodeResult, 'coordinates.longitude', stateGettersSetters.us_longitude)

      if (leadData) {
        leadData.googleSolarData['center'] = {
          latitude: lat,
          longitude: lng,
        }
      } else {
        stateGettersSetters.us_setLatitude(lat)
        stateGettersSetters.us_setLongitude(lng)
        setPageLocalStorage('go_solar', 'location_latitude', lat)
        setPageLocalStorage('go_solar', 'location_longitude', lng)
      }

      mapMarkers.houseMarker.position.lat = lat
      mapMarkers.houseMarker.position.lng = lng
      stateGettersSetters.us_setMapMarkers(mapMarkers)
    } catch (error) {
      console.error('Error updating map with new address:', error)
    }
  }

  const handleEditAddressClickClose = (action: string, address?: string, city?: string, state?: string, zip?: string) => {
    if (action === 'save') {
      console.log('temp_address', stateGettersSetters.us_tempAddress)
      console.log('temp_city', stateGettersSetters.us_tempCity)
      console.log('temp_state', stateGettersSetters.us_tempState)
      console.log('temp_zip', stateGettersSetters.us_tempZip)

      if (
        (!stateGettersSetters.us_tempAddress && !address) ||
        (!stateGettersSetters.us_tempCity && !city) ||
        (!stateGettersSetters.us_tempState && !state) ||
        (!stateGettersSetters.us_tempZip && !zip)
      ) {
        console.log("Required fields are empty - Don't close")
        return // Don't close if required fields are empty
      }

      if (zipCodesAccepted.zipcodes.includes(parseInt(zip ? zip : stateGettersSetters.us_tempZip))) {
        console.log('Valid zip code - You live within our service area')
        stateGettersSetters.us_setAllowBuildSystem(true)
        stateGettersSetters.us_setZipError(null)
        setPageLocalStorage('go_solar', 'allow_build_system', true)
        setPageLocalStorage('go_solar', 'zip_error', null)
      } else {
        console.log('Invalid zip code - You live outside of our service area')
        stateGettersSetters.us_setAllowBuildSystem(false)
        stateGettersSetters.us_setZipError('Invalid zip code - You live outside of our service area')
        setPageLocalStorage('go_solar', 'allow_build_system', false)
        setPageLocalStorage('go_solar', 'zip_error', 'Invalid zip code - You live outside of our service area')
      }

      stateGettersSetters.us_setAddress(address ? address : stateGettersSetters.us_tempAddress)
      stateGettersSetters.us_setCity(city ? city : stateGettersSetters.us_tempCity)
      stateGettersSetters.us_setState(state ? state : stateGettersSetters.us_tempState)
      stateGettersSetters.us_setZip(zip ? zip : stateGettersSetters.us_tempZip)

      if (stateGettersSetters.us_isDatabaseMode) {
        console.log('Updating opportunity in database')
        cloudFunctionUnauthenticatedRequests({
          function: 'updateOpportunity_v2',
          client_key: clientKey,
          opportunity_key: opportunityKey,
          opportunity_data: {
            location_address: address ? address : stateGettersSetters.us_tempAddress,
            location_city: city ? city : stateGettersSetters.us_tempCity,
            location_state: state ? state : stateGettersSetters.us_tempState,
            location_zip: zip ? zip : stateGettersSetters.us_tempZip,
          },
          trigger_page_refresh: false,
        })
      } else {
        setPageLocalStorage('go_solar', 'location_address', address ? address : stateGettersSetters.us_tempAddress)
        setPageLocalStorage('go_solar', 'location_city', city ? city : stateGettersSetters.us_tempCity)
        setPageLocalStorage('go_solar', 'location_state', state ? state : stateGettersSetters.us_tempState)
        setPageLocalStorage('go_solar', 'location_zip', zip ? zip : stateGettersSetters.us_tempZip)
      }

      address = address ? address : stateGettersSetters.us_tempAddress
      city = city ? city : stateGettersSetters.us_tempCity
      state = state ? state : stateGettersSetters.us_tempState
      zip = zip ? zip : stateGettersSetters.us_tempZip

      updateMapWithNewAddress(address + ', ' + city + ', ' + state + ' ' + zip).then(() => {
        // Update map settings with new coordinates
        mapSettings.center_lat = mapMarkers.houseMarker.position.lat
        mapSettings.center_lng = mapMarkers.houseMarker.position.lng
      })
      stateGettersSetters.us_setEditAddressOpen(false)
    } else if (action == 'close') {
      stateGettersSetters.us_setEditAddressOpen(false)
    }
  }

  const getCurrentLocation = async (stateGettersSetters: any) => {
    if ('geolocation' in navigator) {
      try {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          })
        })

        const lat = position.coords.latitude
        const lng = position.coords.longitude

        try {
          // Use the Google Maps Geocoder service
          const geocoder = new google.maps.Geocoder()
          const response = await geocoder.geocode({
            location: { lat, lng },
          })

          if (response.results[0]) {
            const addressComponents = response.results[0].address_components
            let street_number = '',
              route = '',
              city = '',
              state = '',
              zip = ''

            for (const component of addressComponents) {
              if (component.types.includes('street_number')) {
                street_number = component.long_name
              } else if (component.types.includes('route')) {
                route = component.long_name
              } else if (component.types.includes('locality')) {
                city = component.long_name
              } else if (component.types.includes('administrative_area_level_1')) {
                state = component.short_name
              } else if (component.types.includes('postal_code')) {
                zip = component.long_name
              }
            }

            const address = `${street_number} ${route}`.trim()

            // Update all the address fields
            stateGettersSetters.us_setTempAddress(address)
            stateGettersSetters.us_setTempCity(city)
            stateGettersSetters.us_setTempState(state)
            stateGettersSetters.us_setTempZip(zip)

            await new Promise((resolve) => setTimeout(resolve, 0))
            // Trigger the save action
            handleEditAddressClickClose('save', address, city, state, zip)
          } else {
            throw new Error('No results found')
          }
        } catch (error) {
          console.error('Error in reverse geocoding:', error)
          //stateGettersSetters.us_setLocationError('Unable to find your address. Please enter it manually.')
          stateGettersSetters.us_setEditAddressOpen(true)
        }
      } catch (error: any) {
        console.error('Error getting current location:', error)

        let errorMessage = 'Error getting your location. Please try entering your address manually.'

        if (error.code === 1) {
          errorMessage = 'Location access was denied. Please allow location access or enter your address manually.'
        } else if (error.code === 2) {
          errorMessage = 'Location unavailable. Please try again or enter your address manually.'
        } else if (error.code === 3) {
          errorMessage = 'Location request timed out. Please try again or enter your address manually.'
        }
        console.log(errorMessage)
        //stateGettersSetters.us_setLocationError(errorMessage)
        stateGettersSetters.us_setEditAddressOpen(true)
      }
    } else {
      console.log('Geolocation is not supported by this browser')
      //stateGettersSetters.us_setLocationError("Your browser doesn't support geolocation. Please enter your address manually.")
      stateGettersSetters.us_setEditAddressOpen(true)
    }
  }

  const handleKeyDown = (event: any) => {
    if (event != null && (event.keyCode === 9 || event.keyCode === 13) && event.target != null && event.target.defaultValue != null) {
      console.log('Enter key pressed')
    }
  }

  let jsx = (
    <Box
      className="tw-flex tw-flex-col tw-bg-white"
      sx={{ alignItems: 'center', height: '100vh' }}
    >
      {!stateGettersSetters.us_address && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '60%',
            gap: 2,
            padding: '20px',
          }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', marginBottom: '20px', fontFamily: 'Poppins', color: '#939598', fontSize: '20px' }}
          >
            {rLIB('To get started, we need your location')}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon="location-dot" />}
            onClick={() => getCurrentLocation(stateGettersSetters)}
            sx={{
              backgroundColor: palette.aqua,
              marginBottom: '10px',
              borderRadius: '40px',
              padding: '10px 20px',
            }}
          >
            {rLIB('Use Current Location')}
          </Button>
          <Typography
            variant="body2"
            sx={{ textAlign: 'center' }}
          >
            {rLIB('Or')}
          </Typography>
          <Button
            variant="outlined"
            onClick={handleEditAddressClickOpen}
            sx={{
              borderRadius: '40px',
              padding: '10px 20px',
            }}
          >
            {rLIB('Enter Address Manually')}
          </Button>
        </Box>
      )}
      {stateGettersSetters.us_address && (
        <>
          <Typography
            variant="body2"
            className="tw-text-gray_500"
            sx={{ alignSelf: 'flex-start', marginLeft: 'calc(5vw)' }}
          >
            {rLIB('Not your home?')}{' '}
            <Button
              onClick={handleEditAddressClickOpen}
              sx={{ textTransform: 'none', padding: 0 }}
            >
              <b>
                <u style={{ color: 'black' }}>Click here</u>
              </b>
            </Button>{' '}
            to change your address
          </Typography>
          <Box sx={{ marginTop: '10px' }}>
            <MapBasic
              mapMarkers={mapMarkers}
              mapSettings={mapSettings}
              ref={ur_mapRef as React.Ref<google.maps.Map>}
            ></MapBasic>
          </Box>
        </>
      )}
      <Dialog
        open={stateGettersSetters.us_editAddressOpen}
        onClose={handleEditAddressClickClose}
      >
        <DialogTitle>Enter Your Address</DialogTitle>
        <DialogContent>
          <PlacesAutocomplete
            value={stateGettersSetters.us_tempAddress}
            onChange={(address) => {
              stateGettersSetters.us_setTempAddress(address)
            }}
            searchOptions={{
              types: ['address'],
              componentRestrictions: { country: 'us' },
            }}
            debounce={200}
            highlightFirstSuggestion
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
              return (
                <Autocomplete
                  options={suggestions}
                  freeSolo={true}
                  inputValue={stateGettersSetters.us_tempAddress}
                  onKeyDown={handleKeyDown}
                  getOptionLabel={(option: any) => option.description || ''}
                  onChange={(event, selectedSuggestion: any) => {
                    if (selectedSuggestion && selectedSuggestion.description) {
                      // Handle the selected suggestion
                      const geocoder = new google.maps.Geocoder()
                      geocoder.geocode({ address: selectedSuggestion.description }, (results, status) => {
                        if (status === 'OK' && results && results[0]) {
                          const addressComponents = results[0].address_components
                          let streetNumber = ''
                          let route = ''
                          let city = ''
                          let state = ''
                          let zip = ''

                          addressComponents.forEach((component) => {
                            const type = component.types[0]
                            switch (type) {
                              case 'street_number':
                                streetNumber = component.long_name
                                break
                              case 'route':
                                route = component.long_name
                                break
                              case 'locality':
                                city = component.long_name
                                break
                              case 'administrative_area_level_1':
                                state = component.short_name
                                break
                              case 'postal_code':
                                zip = component.long_name
                                break
                            }
                          })

                          const fullAddress = `${streetNumber} ${route}`.trim()
                          stateGettersSetters.us_setTempAddress(fullAddress)
                          stateGettersSetters.us_setTempCity(city)
                          stateGettersSetters.us_setTempState(state)
                          stateGettersSetters.us_setTempZip(zip)
                        }
                      })
                    }
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === 'clear') {
                      // Clear all address fields when the clear button is clicked
                      stateGettersSetters.us_setTempAddress('')
                      stateGettersSetters.us_setTempCity('')
                      stateGettersSetters.us_setTempState('')
                      stateGettersSetters.us_setTempZip('')
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...getInputProps({
                        placeholder: '',
                      })}
                      disabled={false}
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      name="search"
                      fullWidth
                    />
                  )}
                  renderOption={(props, suggestion: any) => (
                    <li
                      {...props}
                      {...getSuggestionItemProps(suggestion, {
                        onClick: () => {
                          // This handles clicking on a suggestion
                          const geocoder = new google.maps.Geocoder()
                          geocoder.geocode({ address: suggestion.description }, (results, status) => {
                            if (status === 'OK' && results && results[0]) {
                              const addressComponents = results[0].address_components
                              let streetNumber = ''
                              let route = ''
                              let city = ''
                              let state = ''
                              let zip = ''

                              addressComponents.forEach((component) => {
                                const type = component.types[0]
                                switch (type) {
                                  case 'street_number':
                                    streetNumber = component.long_name
                                    break
                                  case 'route':
                                    route = component.long_name
                                    break
                                  case 'locality':
                                    city = component.long_name
                                    break
                                  case 'administrative_area_level_1':
                                    state = component.short_name
                                    break
                                  case 'postal_code':
                                    zip = component.long_name
                                    break
                                }
                              })

                              const fullAddress = `${streetNumber} ${route}`.trim()
                              stateGettersSetters.us_setTempAddress(fullAddress)
                              stateGettersSetters.us_setTempCity(city)
                              stateGettersSetters.us_setTempState(state)
                              stateGettersSetters.us_setTempZip(zip)
                            }
                          })
                        },
                      })}
                    >
                      {suggestion.description}
                    </li>
                  )}
                />
              )
            }}
          </PlacesAutocomplete>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleEditAddressClickClose('close')}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleEditAddressClickClose('save')}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Box sx={{ marginTop: '10px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            saveSessionData(
              analyticsData.clientKey,
              analyticsData.opportunityKey,
              analyticsData.analyticsSessionKey,
              {
                actions_taken: {
                  clicked_show_confirm_house_modal_button: true,
                },
              },
              !(analyticsData.queryParams.get('tracking') == 'false'),
            )
          }}
          sx={{ marginRight: '20px' }}
        >
          Yes
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => console.log('No clicked')}
        >
          No
        </Button>
      </Box> */}
      {stateGettersSetters.us_address && (
        <Typography
          variant="body2"
          className="tw-text-gray_500 tw-mt-2"
          sx={{ alignSelf: 'flex-start', marginLeft: 'calc(5vw)' }}
        >
          {leadData
            ? // If leadData is not null, display this
              `${leadData.opportunityData.location_address}, ${leadData.opportunityData.location_city}, ${leadData.opportunityData.location_state} ${leadData.opportunityData.location_zip}`
            : // If leadData is null, use the existing logic
              `${stateGettersSetters.us_address ? stateGettersSetters.us_address : DEFAULT_ADDRESS},
      ${stateGettersSetters.us_city ? stateGettersSetters.us_city : DEFAULT_CITY},
      ${stateGettersSetters.us_state ? stateGettersSetters.us_state : DEFAULT_STATE}
      ${stateGettersSetters.us_zip ? stateGettersSetters.us_zip : DEFAULT_ZIP}`}
        </Typography>
      )}
      {stateGettersSetters.us_allowBuildSystem && stateGettersSetters.us_state && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
              variant="h5"
              className="tw-text-gray_600 tw-mt-7"
              sx={{ textAlign: 'center', marginBottom: '5px', fontWeight: 'bold' }}
            >
              {rLIB('Confirm Your Electricity Provider')}
            </Typography>
            {/* <Typography
          variant="h5"
          sx={{ marginBottom: '10px' }}
        >
          {us_utilityCompanyName ? us_utilityCompanyName : 'Your utility company here...'}
        </Typography> */}
            <Autocomplete
              sx={{ marginTop: '15px' }}
              fullWidth
              popupIcon={<KeyboardArrowDownIcon sx={{ color: 'gray', fontSize: '50px' }} />}
              value={stateGettersSetters.us_utilityCompanyName ? { label: stateGettersSetters.us_utilityCompanyName } : {}}
              onChange={(event, newValue) => {
                if (newValue != null && newValue.label != null) {
                  stateGettersSetters.us_setUtilityCompanyName(newValue ? newValue.label : '')
                  // updateOpportunity({ contact_utility_provider: newValue ? newValue.label : '' }, false)
                }
              }}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              options={stateGettersSetters.us_utilityCompanies}
              getOptionLabel={(option) => {
                if (option != null && option.label != null) {
                  return option.label
                } else {
                  return ''
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="tw-bg-gray_300"
                  // label={stateGettersSetters.us_utilityCompanyName}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Box>
          <Typography
            variant="h5"
            className="tw-text-gray_600 tw-mt-9"
            sx={{ fontWeight: 'bold', alignSelf: 'flex-start', marginLeft: 'calc(5vw)' }}
          >
            {rLIB('Set Your Average Bill')}
          </Typography>
          <Typography
            className="tw-text-gray_400"
            sx={{ alignSelf: 'flex-start', marginLeft: 'calc(5vw)' }}
          >
            {rLIB('Move the slider to change your utility below:')}
          </Typography>
          <Typography
            variant="h6"
            className="tw-text-black tw-mt-4"
          >
            {stateGettersSetters.us_electricityBill ? formatNumberToDollar(stateGettersSetters.us_electricityBill) : formatNumberToDollar(200)}
          </Typography>
          <MuiSlider
            sx={{ width: '90%', marginX: 'auto', color: palette.aqua, marginTop: '10px' }}
            value={stateGettersSetters.us_electricityBill ? stateGettersSetters.us_electricityBill : 200}
            valueLabelDisplay="off"
            slotProps={{
              rail: { className: 'tw-bg-gray_400 tw-h-5' },
              track: { className: 'tw-bg-gray_400 tw-h-5 tw-border-0' },
              thumb: { className: 'tw-size-8' },
            }}
            // marks
            step={5}
            min={0}
            max={500}
            onChange={(event, value) => {
              stateGettersSetters.us_setElectricityBill(value)
              if (!stateGettersSetters.us_isDatabaseMode) {
                setPageLocalStorage('go_solar', 'system_monthly_electricity_bill', value)
              }
            }}
            onChangeCommitted={(event, value) => {
              // saveSessionData(
              //   clientKey,
              //   opportunityKey,
              //   us_analyticsSessionKey,
              //   {
              //     actions_taken: {
              //       committed_utility_confirmation_MuiSlider_change: true,
              //     },
              //   },
              //   !(analyticsData.queryParams.get('tracking') == 'false'),
              // )
              stateGettersSetters.us_setElectricityBill(value as number)
              if (!stateGettersSetters.us_isDatabaseMode) {
                submitOffsetChange(stateGettersSetters, 100, false, opportunityKey, clientKey)
                setPageLocalStorage('go_solar', 'system_monthly_electricity_bill', value)
              } else {
                submitOffsetChange(stateGettersSetters, 100, false, opportunityKey, clientKey)

                cloudFunctionUnauthenticatedRequests({
                  function: 'updateOpportunity_v2',
                  client_key: clientKey,
                  opportunity_key: opportunityKey,
                  opportunity_data: {
                    system_monthly_electricity_billl: value,
                  },
                  trigger_page_refresh: false,
                })
              }
              //setPageLocalStorage('go_solar_' + opportunityKey, 'electricityBill', value)
            }}
          />
          <Box className="tw-mt-5">
            <Button
              variant="contained"
              color="primary"
              // onClick={() => {
              //   saveSessionData(
              //     clientKey,
              //     opportunityKey,
              //     us_analyticsSessionKey,
              //     {
              //       actions_taken: {
              //         clicked_confirm_utility_bill_button: true,
              //       },
              //     },
              //     !(ul_queryParams.get('tracking') == 'false'),
              //   )
              //   handleUtilityBillChange(us_electricityBill ? us_electricityBill : 200, true)
              // }}
              sx={{ backgroundColor: palette.aqua, borderRadius: '40px', color: palette.white, height: '60px' }}
              disableElevation
              onClick={() => {
                stateGettersSetters.us_setCurrentView('buildSystem')
                stateGettersSetters.us_setPastEntryPage(true)
                if (!stateGettersSetters.us_isDatabaseMode) {
                  setPageLocalStorage('go_solar', 'pastEntryPage', true)
                  setPageLocalStorage('go_solar', 'system_monthly_electricity_bill', stateGettersSetters.us_electricityBill)
                  setPageLocalStorage('go_solar', 'associated_utility_company_name', stateGettersSetters.us_utilityCompanyName)
                } else {
                  updateOpportunity(
                    stateGettersSetters,
                    stateGettersSetters.us_leadData?.clientKey,
                    opportunityKey,
                    {
                      past_entry_page: true,
                      system_monthly_electricity_bill: stateGettersSetters.us_electricityBill,
                      associated_utility_company_name: stateGettersSetters.us_utilityCompanyName,
                    },
                    true,
                  )
                }
              }}
            >
              {rLIB('Build Your System')}
            </Button>
          </Box>
        </>
      )}

      {!stateGettersSetters.us_allowBuildSystem && stateGettersSetters.us_zipError !== null && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            marginTop: '20px',
            width: '90%',
            backgroundColor: '#f8d7da', // Light red background
            borderRadius: '4px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#721c24', // Dark red text
              textAlign: 'center',
              fontWeight: 'medium',
            }}
          >
            {rLIB('Your address is not in our service area. Thank you for your interest!')}
          </Typography>
        </Box>
      )}
    </Box>
  )
  return jsx
}

export const rJSX_BuildSystemPage = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    // Scroll to the relevant section when the tab changes
    const sectionRefs = [
      stateGettersSetters.us_section1Ref,
      stateGettersSetters.us_section2Ref,
      stateGettersSetters.us_section3Ref,
      stateGettersSetters.us_section4Ref,
    ]

    stateGettersSetters.us_setSelectedTab(sectionRefs[newValue])

    const element = document.getElementById(sectionRefs[newValue])
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const systemSizeTabSelected = stateGettersSetters.us_selectedTab === 'systemSize' ? palette.aqua : palette.darkGray
  const financingTabSelected = stateGettersSetters.us_selectedTab === 'financing' ? palette.aqua : palette.darkGray
  const helpTabSelected = stateGettersSetters.us_selectedTab === 'help' ? palette.aqua : palette.darkGray
  const optionsTabSelected = stateGettersSetters.us_selectedTab === 'options' ? palette.aqua : palette.darkGray

  return (
    <Box
      className="tw-flex tw-flex-col tw-bg-white"
      sx={{ alignItems: 'center', padding: '5px' }}
    >
      <Box sx={{ marginBottom: '15px', display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <Button
          startIcon={
            <Icon
              icon="arrow-left"
              className="tw-text-gray_600"
              sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
            />
          }
          onClick={() => {
            stateGettersSetters.us_setPastEntryPage(false)
            stateGettersSetters.us_setCurrentView('entry')
          }}
          className="tw-text-gray_600 "
          sx={{
            'fontFamily': 'Poppins',
            'textTransform': 'none',
            'backgroundColor': 'transparent',
            'position': 'absolute',
            'left': '0',
            'padding': '0px 10px',
            'marginBottom': '10px',
            '&:hover': {
              backgroundColor: palette.gray_600,
            },
          }}
        >
          Back
        </Button>
      </Box>
      {/* Tabs */}
      <Tabs
        value={false}
        onChange={handleTabChange}
        centered
      >
        <Tab
          label={rLIB('System Size')}
          sx={{
            fontFamily: 'Poppins',
            fontSize: '9px',
            borderBottom: '2px solid',
            borderColor: systemSizeTabSelected,
            marginX: 0.3,
            color: systemSizeTabSelected,
          }}
        />
        <Tab
          label={rLIB('Financing')}
          sx={{
            fontFamily: 'Poppins',
            fontSize: '9px',
            borderBottom: '2px solid',
            borderColor: financingTabSelected,
            marginX: 0.3,
            color: financingTabSelected,
          }}
        />
        <Tab
          label={rLIB('Help Me Choose')}
          sx={{ fontFamily: 'Poppins', fontSize: '9px', borderBottom: '2px solid', borderColor: helpTabSelected, marginX: 0.3, color: helpTabSelected }}
        />
        <Tab
          label={rLIB('Options')}
          sx={{ fontFamily: 'Poppins', fontSize: '9px', borderBottom: '2px solid', borderColor: optionsTabSelected, marginX: 0.3, color: optionsTabSelected }}
        />
      </Tabs>

      {/* Sections */}
      <div
        id={stateGettersSetters.us_section1Ref}
        style={{ width: '100%', padding: '16px', backgroundColor: palette.white, marginBottom: '15px' }}
      >
        {rJSX_SystemSizeSection(stateGettersSetters, palette, opportunityKey, clientKey)}
      </div>

      <div
        id={stateGettersSetters.us_section2Ref}
        style={{ height: '550px', width: '100%', padding: '16px', backgroundColor: palette.white }}
      >
        {rJSX_FinancingSection(stateGettersSetters, palette, clientKey, opportunityKey)}
      </div>

      <div
        id={stateGettersSetters.us_section3Ref}
        style={{ width: '100%', padding: '16px', backgroundColor: palette.white }}
      >
        {rJSX_HelpMeChooseSection({}, palette)}
      </div>

      <div
        id={stateGettersSetters.us_section4Ref}
        style={{ width: '100%', padding: '16px', backgroundColor: palette.white }}
      >
        {rJSX_AdditionalOptionsSection(stateGettersSetters, palette, opportunityKey)}
      </div>
      <Button
        disableFocusRipple
        disableElevation
        sx={{
          'fontFamily': 'Poppins',
          'color': palette.white,
          'backgroundColor': palette.aqua,
          'marginBottom': '40px',
          'borderRadius': '25px',
          'padding': '15px',
          '&.MuiButton-root': {
            backgroundColor: palette.aqua,
          },
        }}
        onClick={(event) => {
          event.currentTarget.blur()
          window.scrollTo({ top: 0, behavior: 'smooth' })
          stateGettersSetters.us_setCurrentView('orderDetails')
          //stateGettersSetters.us_setCurrentView('login')
        }}
      >
        {rLIB('Review Your Order')}
      </Button>
    </Box>
  )
}

export const rJSX_OrderDetailsPage = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  return (
    <Box sx={{ background: palette.white, padding: '15px', minHeight: '100vh' }}>
      <Button
        startIcon={
          <Icon
            icon="arrow-left"
            className="tw-text-gray_600"
            sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
          />
        }
        onClick={() => {
          stateGettersSetters.us_setCurrentView('buildSystem')
        }}
        className="tw-text-gray_600 "
        sx={{
          'fontFamily': 'Poppins',
          'textTransform': 'none',
          'backgroundColor': 'transparent',
          '&:hover': {
            backgroundColor: palette.gray_600,
          },
        }}
      >
        Back
      </Button>
      <Typography
        variant="h4"
        sx={{ fontFamily: 'Poppins', marginBottom: '0px', fontWeight: 'bold', fontSize: '20px' }}
        className="tw-text-gray_600 tw-font-bold"
      >
        {rLIB('Order Details')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontFamily: 'Poppins', marginBottom: '0px', color: palette.darkGray, fontSize: '12px' }}
      >
        {rLIB('Review your solar system order details below.')}
      </Typography>

      <Box sx={{ marginBottom: '6px' }}>{rJSX_SystemDetails(stateGettersSetters, palette)}</Box>

      <Box sx={{ marginBottom: '6px' }}>{rJSX_FinancingDetails(stateGettersSetters, palette)}</Box>
      {stateGettersSetters.us_looksGood && <Box sx={{ marginBottom: '6px' }}>{rJSX_UserDetails(stateGettersSetters, palette, opportunityKey, clientKey)}</Box>}
      {stateGettersSetters.us_looksGood && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', width: '100%' }}>
          <Button
            disableFocusRipple
            disableElevation
            disabled={
              !stateGettersSetters.us_agreeTerms ||
              !stateGettersSetters.us_firstName ||
              !stateGettersSetters.us_lastName ||
              !stateGettersSetters.us_email ||
              !stateGettersSetters.us_phone
            }
            sx={{
              'textTransform': 'none',
              'color': palette.white,
              'backgroundColor': palette.aqua,
              'marginBottom': '20px',
              'borderRadius': '25px',
              'padding': '10px 20px',
              '&.Mui-disabled': {
                backgroundColor: '#F4F7F9',
              },
            }}
            onClick={(event) => {
              event.currentTarget.blur()
              window.scrollTo({ top: 0, behavior: 'smooth' })
              //stateGettersSetters.us_setCurrentView('welcome')
              stateGettersSetters.us_setCurrentView('email')
              completeOpportunitySubmission(stateGettersSetters, opportunityKey, clientKey)
              //stateGettersSetters.us_setCurrentView('login')
              //stateGettersSetters.us_setShowWelcomeBanner(true)
            }}
          >
            {rLIB('Place Your Order')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export const rJSX_LoginPage = (stateGettersSetters: any, palette: any, clientKey: string): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: palette.aqua, // Set the background color to blue (aqua)
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh', // Ensure the Box takes the full viewport height
        padding: '40px 20px', // Adds some padding to the top and sides
        boxSizing: 'border-box', // Ensures padding is included in the total height
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <Button
          startIcon={
            <Icon
              icon="arrow-left"
              className="tw-text-white"
              sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
            />
          }
          onClick={() => {
            stateGettersSetters.us_setCurrentView('email')
          }}
          className="tw-text-white"
          sx={{
            'fontFamily': 'Poppins',
            'textTransform': 'none',
            'backgroundColor': 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          {rLIB('Back')}
        </Button>
      </Box>
      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          color: palette.white,
          marginBottom: '8px', // Reduced margin
          fontWeight: 'bold',
          marginTop: '0', // Remove any default top margin
        }}
      >
        {rLIB('Welcome!')}
      </Typography>

      {/* Subtitle */}
      <Typography
        variant="h6"
        sx={{
          color: palette.white,
          marginBottom: '24px', // Adjusted margin
        }}
      >
        {rLIB('Login Below')}
      </Typography>

      {/* Email Input */}
      <TextField
        label="Email"
        variant="outlined"
        type="email"
        sx={{
          backgroundColor: palette.white,
          borderRadius: '4px',
          marginBottom: '16px',
          width: '300px',
        }}
        onChange={(e) => {
          stateGettersSetters.us_setLoginEmail(e.target.value.trim())
        }}
      />

      {/* Password Input */}
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        sx={{
          backgroundColor: palette.white,
          borderRadius: '4px',
          marginBottom: '24px',
          width: '300px',
        }}
        onChange={(e) => {
          stateGettersSetters.us_setLoginPassword(e.target.value.trim())
        }}
      />

      {/* Login Button */}
      <Button
        variant="contained"
        sx={{
          'marginTop': '20px',
          'backgroundColor': palette.white,
          'color': palette.aqua,
          'border': `1px solid ${palette.aqua}`,
          'width': '200px',
          'padding': '10px',
          'borderRadius': '25px',
          'textTransform': 'none',
          'fontWeight': 'bold',
          'fontSize': '16px',
          '&:hover': {
            backgroundColor: palette.aqua,
            color: palette.white,
            border: `2px solid ${palette.aqua}`,
          },
        }}
        onClick={() => {
          // TODO: Add login functionality
          authWithPassword(stateGettersSetters.us_loginEmail, stateGettersSetters.us_loginPassword)
            .then((res_A) => {
              stateGettersSetters.us_setCurrentView('welcome')
            })
            .catch((rej_A) => {
              console.error('Login failed:', rej_A)
            })
        }}
      >
        {rLIB('Login')}
      </Button>

      {/* Forgot Password Link */}
      <Typography
        variant="body2"
        sx={{
          color: palette.white,
          textDecoration: 'underline',
          fontSize: '18px',
          marginTop: '20px',
        }}
        onClick={() => {
          stateGettersSetters.us_setCurrentView('createAccountOrResetPassword')
        }}
      >
        {rLIB('Forgot Password?')}
      </Typography>
    </Box>
  )
}

export const rJSX_EmailPage = (stateGettersSetters: any, palette: any): JSX.Element => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: palette.white,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flex: 0.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: palette.aqua,
            marginBottom: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {rLIB('Welcome to ETW!')}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            color: palette.aqua,
            marginBottom: '24px',
            textAlign: 'center',
          }}
        >
          {rLIB('Thank you for choosing to work with us!')}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: palette.aqua,
            marginBottom: '32px',
            textAlign: 'center',
            maxWidth: '600px',
          }}
        >
          {rLIB("We've sent an email with your login information. Please check your inbox and follow the instructions to access your account.")}
        </Typography>

        <Icon
          icon="envelope"
          sx={{
            fontSize: '64px',
            color: palette.aqua,
            marginBottom: '32px',
          }}
        />

        <Button
          variant="contained"
          sx={{
            'backgroundColor': palette.aqua,
            'color': palette.white,
            'padding': '10px 20px',
            'borderRadius': '25px',
            'textTransform': 'none',
            'fontWeight': 'bold',
            'fontSize': '16px',
            'marginTop': '20px',
            '&:hover': {
              backgroundColor: palette.aqua,
              opacity: 0.9,
            },
          }}
          onClick={(event) => {
            directAppNavigation(stateGettersSetters.un_routerNavigation, ApplicationPages.UnauthenticatedSalesLoginPage.url())
          }}
        >
          {rLIB('Go to Homepage')}
        </Button>
      </Box>
    </Box>
  )
}

export const rJSX_AddressBanner = (stateGettersSetters: any, logoSrc: string, palette: TsInterface_UnspecifiedObject): JSX.Element => {
  let bannerJSX = (
    <Box
      className="tw-p-2"
      sx={{ background: palette.white }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '5px',
          paddingX: '5px',
          alignItems: 'center',
        }}
      >
        <Box
          className="tw-mb-2"
          component="img"
          sx={{ width: 189, height: 54, background: 'white', borderRadius: '5px', marginRight: '20px' }}
          alt="logo"
          src={logoSrc}
        />
        {!stateGettersSetters.us_showWelcomeBanner && (
          <Box sx={{ marginTop: '6px', marginRight: '20px' }}>
            <Card
              className="tw-bg-gray_300 tw-p-3"
              elevation={0}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  icon="phone"
                  size="xl"
                  sx={{ color: '#56d3ff' }}
                />
                <Box className="tw-flex-col tw-ml-2">
                  <Typography sx={{ fontSize: '10px' }}>{rLIB('Have questions?')}</Typography>
                  <Typography sx={{ fontSize: '10px', color: palette.black, fontWeight: 'bold' }}>
                    <u>{PHONE_NUMBER}</u> or <u>schedule a call back</u>
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
        )}
        {stateGettersSetters.us_showWelcomeBanner && (
          <Box
            sx={{
              marginTop: '6px',
              marginBottom: '10px',
              marginLeft: '25px',
              display: 'flex', // Added Flexbox
              flexDirection: 'column', // Changed to column layout
              alignItems: 'flex-start', // Align items vertically
              gap: '10px', // Space between buttons
            }}
          >
            <Button
              className="tw-text-gray_600"
              startIcon={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon
                    icon="circle-user"
                    sx={{ fontSize: '17px' }}
                  />
                </Box>
              }
              onClick={() => {
                stateGettersSetters.us_setCurrentView('login')
              }}
              sx={{
                paddingLeft: '65px',
                textTransform: 'none',
                fontSize: '12px',
                whiteSpace: 'nowrap', // Prevent text wrapping
              }}
            >
              {rLIB('Login')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                // TODO: open support modal
              }}
              sx={{
                'textTransform': 'none',
                'backgroundColor': '#42deff',
                'color': palette.white,
                '&:hover': {
                  backgroundColor: palette.aqua,
                },
                'borderRadius': '5px',
                'fontSize': '12px', // Increased font size for better readability
                'paddingX': '5px', // Added horizontal padding
                'paddingY': '8px', // Added vertical padding
                'whiteSpace': 'nowrap', // Prevent text wrapping
                'minWidth': '120px', // Ensures the button isn't too small
              }}
            >
              {rLIB('Request Support')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )

  return bannerJSX
}
