///////////////////////////////
// Description
///////////////////////////////

// SKIP - disable all menu items and selection of these components
// Looped Data Tables
// View Containers

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { Document, Image, Page, pdf, PDFViewer, Text, View } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import React from 'react'
import { SketchPicker } from 'react-color'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { rLIB } from 'rfbp_core/localization/library'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { dynamicSort, generateRandomString, getProp, mergeTwoObjects, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { FileUploadButton } from '../file_upload'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from '../form'
import { Icon } from '../icons'
import { placeholderImageBase64Url } from './placeholder_image'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_PdfTemplateComponent_StaticTable {
  component_type: 'static_table'
  key: string
  order: number
  table_style?: TsInterface_UnspecifiedObject
  rows: {
    [key: string]: {
      row_style?: TsInterface_UnspecifiedObject
      key: string
      order: number
      cells: {
        [key: string]: {
          cell_style?: TsInterface_UnspecifiedObject
          key: string
          order: number
          contents: {
            [key: string]: TsType_PdfTemplateComponent
          }
        }
      }
    }
  }
}

interface TsInterface_PdfTemplateComponent_LoopedDataTable {
  component_type: 'looped_data_table'
  key: string
  order: number
  table_style?: TsInterface_UnspecifiedObject
  table_data_variable_mapping_key: string
  row_style?: TsInterface_UnspecifiedObject
  columns: {
    [key: string]: {
      order: number
      key: string
      column_header_style?: TsInterface_UnspecifiedObject
      column_body_style?: TsInterface_UnspecifiedObject
      header_text: string
      variable_mapping_key: string
      fallback_text?: string
      fallback_text_style?: TsInterface_UnspecifiedObject
    }
  }
  // TODO
}

interface TsInterface_PdfTemplateComponent_StaticImage {
  component_type: 'static_image'
  key: string
  order: number
  image_style?: TsInterface_UnspecifiedObject
  src: string
}
interface TsInterface_PdfTemplateComponent_VariableImage {
  component_type: 'variable_image'
  key: string
  order: number
  image_style?: TsInterface_UnspecifiedObject
  variable_mapping_key: string
}

interface TsInterface_PdfTemplateComponent_StaticText {
  component_type: 'static_text'
  key: string
  order: number
  text_style?: TsInterface_UnspecifiedObject
  text: string
}

interface TsInterface_PdfTemplateComponent_VariableText {
  component_type: 'variable_text'
  key: string
  order: number
  text_style?: TsInterface_UnspecifiedObject
  variable_mapping_key: string
  fallback_text?: string
  fallback_text_style?: TsInterface_UnspecifiedObject
}

interface TsInterface_PdfTemplateComponent_View {
  component_type: 'view'
  key: string
  order: number
  view_style?: TsInterface_UnspecifiedObject
  contents: {
    [key: string]: TsType_PdfTemplateComponent
  }
}

interface TsInterface_PdfTemplateComponent_PageBreak {
  // component_type: 'page_break'
  // key: string
  // order: number
  component_type: 'page_break'
  key: string
  order: number
}

type TsType_PdfTemplateComponent =
  | TsInterface_PdfTemplateComponent_StaticTable
  | TsInterface_PdfTemplateComponent_LoopedDataTable
  | TsInterface_PdfTemplateComponent_StaticImage
  | TsInterface_PdfTemplateComponent_StaticText
  | TsInterface_PdfTemplateComponent_VariableText
  | TsInterface_PdfTemplateComponent_View
  | TsInterface_PdfTemplateComponent_VariableImage
  | TsInterface_PdfTemplateComponent_PageBreak

export interface TsInterface_PdfTemplate {
  page_settings: {
    flexDirection: string
    paddingTop: string | number
    paddingBottom: string | number
    paddingLeft: string | number
    paddingRight: string | number
  }
  page_contents: {
    [key: string]: TsType_PdfTemplateComponent
  }
}

///////////////////////////////
// Variables
///////////////////////////////

// TODO: Fill out
const propertiesWithSuffixes: TsInterface_UnspecifiedObject = {
  width: (allStyles: TsInterface_UnspecifiedObject, styleValue: string) => {
    if (!isNaN(parseFloat(styleValue))) {
      return styleValue + '% !important'
    } else {
      return styleValue
    }
  },
  height: (allStyles: TsInterface_UnspecifiedObject, styleValue: string) => {
    if (!isNaN(parseFloat(styleValue))) {
      return styleValue + '%'
    } else {
      return styleValue
    }
  },
}

// TODO: Fill out
const cssFormProperties: TsInterface_UnspecifiedObject = {
  //-------------------------
  // Allowed Component List
  //-------------------------
  // allowed_components: {
  // all_elements: true,
  // static_table_container: true,
  // static_table_row: true,
  // static_table_cell: true,
  // looped_data_table_container: true, ???
  // looped_data_table_column: true, ???
  // looped_data_table_row: true, ???
  // looped_data_table_cell: true, ???
  // static_image: true,
  // fallback_text_style: true,
  // variable_image: true,
  // variable_text: true,
  // view: true,
  // },
  //-------------------------
  // FLEXBOX
  //-------------------------
  FLEXBOX: {
    allowed_components: {
      // all_elements: true,
    },
    input: {
      data_type: 'string',
      key: 'FLEXBOX',
      input_type: 'custom_form_input_jsx',
      label: <></>,
      required: false,
      renderCustomFormInput: (formInput: any, formInputs: any, formData: any, formInputChange: any, formSettings: any, formAdditionalData: any) => {
        let inputJSX = (
          <Box sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              {rLIB('Flexbox')}
            </Typography>
          </Box>
        )
        return inputJSX
      },
    },
  },
  // alignContent
  // alignItems
  // alignSelf
  // flex
  // flexDirection
  // flexWrap
  // flexFlow
  // flexGrow
  // flexShrink
  // flexBasis
  // justifyContent
  //-------------------------
  // LAYOUT
  //-------------------------
  LAYOUT: {
    allowed_components: {
      static_image: true,
      fallback_text_style: true,
      variable_image: true,
      variable_text: true,
      view: true,
    },
    input: {
      data_type: 'string',
      key: 'LAYOUT',
      input_type: 'custom_form_input_jsx',
      label: <></>,
      required: false,
      renderCustomFormInput: (formInput: any, formInputs: any, formData: any, formInputChange: any, formSettings: any, formAdditionalData: any) => {
        let inputJSX = (
          <Box sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              {rLIB('Layout')}
            </Typography>
          </Box>
        )
        return inputJSX
      },
    },
  },
  // position
  // bottom
  // left
  // right
  // top
  // overflow
  // zIndex
  display: {
    allowed_components: {
      static_image: true,
      fallback_text_style: true,
      variable_image: true,
      variable_text: true,
      view: true,
    },
    input: {
      key: 'display',
      label: rLIB('Display'),
      input_type: 'multiple_choice_radio',
      data_type: 'string',
      required: false,
      options: [
        { key: 'block', value: rLIB('Block') },
        { key: 'inline-block', value: rLIB('Inline Block') },
        { key: 'inline', value: rLIB('Inline') },
        { key: 'flex', value: rLIB('Flex') },
      ],
    },
  },
  //-------------------------
  // DIMENSION
  //-------------------------
  DIMENSION: {
    allowed_components: {
      static_table_cell: true,
      static_image: true,
      variable_image: true,
      view: true,
    },
    input: {
      data_type: 'string',
      key: 'DIMENSION',
      input_type: 'custom_form_input_jsx',
      label: <></>,
      required: false,
      renderCustomFormInput: (formInput: any, formInputs: any, formData: any, formInputChange: any, formSettings: any, formAdditionalData: any) => {
        let inputJSX = (
          <Box sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              {rLIB('Dimensions')}
            </Typography>
          </Box>
        )
        return inputJSX
      },
    },
  },
  // maxHeight
  // maxWidth
  // minHeight
  // minWidth
  height: {
    allowed_components: {
      static_table_cell: true,
      static_image: true,
      variable_image: true,
      view: true,
    },
    isNumber: true,
    input: {
      key: 'height',
      label: rLIB('Height (Percent)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  width: {
    allowed_components: {
      static_table_cell: true,
      static_image: true,
      variable_image: true,
      view: true,
    },
    isNumber: true,
    input: {
      key: 'width',
      label: rLIB('Width (Percent)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  //-------------------------
  // COLOR
  //-------------------------
  COLOR: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      fallback_text_style: true,
      static_text: true,
      variable_text: true,
      view: true,
    },
    input: {
      data_type: 'string',
      key: 'COLOR',
      input_type: 'custom_form_input_jsx',
      label: <></>,
      required: false,
      renderCustomFormInput: (formInput: any, formInputs: any, formData: any, formInputChange: any, formSettings: any, formAdditionalData: any) => {
        let inputJSX = (
          <Box sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              {rLIB('Color')}
            </Typography>
          </Box>
        )
        return inputJSX
      },
    },
  },
  // opacity
  backgroundColor: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      data_type: 'string',
      key: 'backgroundColor',
      input_type: 'custom_form_input_jsx',
      label: rLIB('Background Color'),
      required: false,
      renderCustomFormInput: (
        formInput: any,
        formInputs: any,
        formData: any,
        formInputChange: any,
        formSettings: any,
        formAdditionalData: any,
        formHooks: any,
      ) => {
        let inputJSX = <></>
        inputJSX = (
          <Box>
            <Typography>{rLIB('Background Color')}</Typography>
            <SketchPicker
              color={getProp(formData, formInput.key, '#ffffff')}
              onChangeComplete={(event: any) => {
                formInputChange(formInput.key, event.hex, true)
              }}
            />
          </Box>
        )
        return inputJSX
      },
    },
  },
  color: {
    allowed_components: {
      static_text: true,
      fallback_text_style: true,
      variable_text: true,
    },
    input: {
      data_type: 'string',
      key: 'color',
      input_type: 'custom_form_input_jsx',
      label: rLIB('Font Color'),
      required: false,
      renderCustomFormInput: (
        formInput: any,
        formInputs: any,
        formData: any,
        formInputChange: any,
        formSettings: any,
        formAdditionalData: any,
        formHooks: any,
      ) => {
        let inputJSX = <></>
        inputJSX = (
          <Box>
            <Typography>{rLIB('Font Color')}</Typography>
            <SketchPicker
              color={getProp(formData, formInput.key, '#ffffff')}
              onChangeComplete={(event: any) => {
                formInputChange(formInput.key, event.hex, true)
              }}
            />
          </Box>
        )
        return inputJSX
      },
    },
  },
  //-------------------------
  // TEXT
  //-------------------------
  TEXT: {
    allowed_components: {
      static_text: true,
      fallback_text_style: true,
      variable_text: true,
    },
    input: {
      data_type: 'string',
      key: 'TEXT',
      input_type: 'custom_form_input_jsx',
      label: <></>,
      required: false,
      renderCustomFormInput: (formInput: any, formInputs: any, formData: any, formInputChange: any, formSettings: any, formAdditionalData: any) => {
        let inputJSX = (
          <Box sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              {rLIB('Text')}
            </Typography>
          </Box>
        )
        return inputJSX
      },
    },
  },
  // fontFamily
  // letterSpacing
  // lineHeight
  // maxLines
  // textDecorationColor
  // textDecorationStyle
  // textIndent
  // textOverflow
  // textTransform
  fontStyle: {
    allowed_components: {
      static_text: true,
      fallback_text_style: true,
      variable_text: true,
    },
    input: {
      key: 'fontStyle',
      label: rLIB('Font Style'),
      input_type: 'multiple_choice_radio',
      data_type: 'string',
      required: false,
      options: [
        { key: 'normal', value: rLIB('Normal') },
        { key: 'italic', value: rLIB('Italic') },
      ],
    },
  },
  fontWeight: {
    allowed_components: {
      static_text: true,
      fallback_text_style: true,
      variable_text: true,
    },
    input: {
      key: 'fontWeight',
      label: rLIB('Font Weight'),
      input_type: 'multiple_choice_radio',
      data_type: 'string',
      required: false,
      options: [
        { key: 'normal', value: rLIB('Normal') },
        { key: 'bold', value: rLIB('Bold') },
      ],
    },
  },
  textAlign: {
    allowed_components: {
      static_text: true,
      fallback_text_style: true,
      variable_text: true,
    },
    input: {
      key: 'textAlign',
      label: rLIB('Text Align'),
      input_type: 'multiple_choice_radio',
      data_type: 'string',
      required: false,
      options: [
        { key: 'left', value: rLIB('Left') },
        { key: 'right', value: rLIB('Right') },
        { key: 'center', value: rLIB('Center') },
        { key: 'justify', value: rLIB('Justify') },
      ],
    },
  },
  textDecoration: {
    allowed_components: {
      static_text: true,
      fallback_text_style: true,
      variable_text: true,
    },
    input: {
      key: 'textDecoration',
      label: rLIB('Text Decoration'),
      input_type: 'multiple_choice_radio',
      data_type: 'string',
      required: false,
      options: [
        { key: 'none', value: rLIB('None') },
        { key: 'underline', value: rLIB('Underline') },
        { key: 'line-through', value: rLIB('Line Through') },
        { key: 'overline', value: rLIB('Overline') },
      ],
    },
  },
  fontSize: {
    allowed_components: {
      static_text: true,
      fallback_text_style: true,
      variable_text: true,
    },
    isNumber: true,
    input: {
      key: 'fontSize',
      label: rLIB('Font Size'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  //-------------------------
  // SIZING/POSITIONING
  //-------------------------
  POSITIONING: {
    allowed_components: {
      // all_elements: true,
    },
    input: {
      data_type: 'string',
      key: 'POSITIONING',
      input_type: 'custom_form_input_jsx',
      label: <></>,
      required: false,
      renderCustomFormInput: (formInput: any, formInputs: any, formData: any, formInputChange: any, formSettings: any, formAdditionalData: any) => {
        let inputJSX = (
          <Box sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              {rLIB('Positioning')}
            </Typography>
          </Box>
        )
        return inputJSX
      },
    },
  },
  // object-fit
  // object-position
  //-------------------------
  // MARGIN/PADDING
  //-------------------------
  MARGIN: {
    allowed_components: {
      all_elements: true,
      page_container: true,
    },
    input: {
      data_type: 'string',
      key: 'MARGIN',
      input_type: 'custom_form_input_jsx',
      label: <></>,
      required: false,
      renderCustomFormInput: (formInput: any, formInputs: any, formData: any, formInputChange: any, formSettings: any, formAdditionalData: any) => {
        let inputJSX = (
          <Box sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              {rLIB('Margin and Padding')}
            </Typography>
          </Box>
        )
        return inputJSX
      },
    },
  },
  // margin
  // marginHorizontal
  // marginVertical
  // paddingHorizontal
  // paddingVertical
  marginTop: {
    allowed_components: {
      all_elements: true,
    },
    isNumber: true,
    input: {
      key: 'marginTop',
      label: rLIB('Margin Top (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  marginRight: {
    allowed_components: {
      all_elements: true,
    },
    isNumber: true,
    input: {
      key: 'marginRight',
      label: rLIB('Margin Right (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  marginBottom: {
    allowed_components: {
      all_elements: true,
    },
    isNumber: true,
    input: {
      key: 'marginBottom',
      label: rLIB('Margin Bottom (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  marginLeft: {
    allowed_components: {
      all_elements: true,
    },
    isNumber: true,
    input: {
      key: 'marginLeft',
      label: rLIB('Margin Left (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  paddingTop: {
    allowed_components: {
      all_elements: true,
      page_container: true,
    },
    isNumber: true,
    input: {
      key: 'paddingTop',
      label: rLIB('Padding Top (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  paddingRight: {
    allowed_components: {
      all_elements: true,
      page_container: true,
    },
    isNumber: true,
    input: {
      key: 'paddingRight',
      label: rLIB('Padding Right (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  paddingBottom: {
    allowed_components: {
      all_elements: true,
      page_container: true,
    },
    isNumber: true,
    input: {
      key: 'paddingBottom',
      label: rLIB('Padding Bottom (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  paddingLeft: {
    allowed_components: {
      all_elements: true,
      page_container: true,
    },
    isNumber: true,
    input: {
      key: 'paddingLeft',
      label: rLIB('Padding Left (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  padding: {
    allowed_components: {
      all_elements: true,
    },
    isNumber: true,
    input: {
      key: 'padding',
      label: rLIB('Padding All (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  //-------------------------
  // TRANSFORMATIONS
  //-------------------------
  TRANSFORMATIONS: {
    allowed_components: {
      // all_elements: true,
    },
    input: {
      data_type: 'string',
      key: 'TRANSFORMATIONS',
      input_type: 'custom_form_input_jsx',
      label: <></>,
      required: false,
      renderCustomFormInput: (formInput: any, formInputs: any, formData: any, formInputChange: any, formSettings: any, formAdditionalData: any) => {
        let inputJSX = (
          <Box sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              {rLIB('Transformations')}
            </Typography>
          </Box>
        )
        return inputJSX
      },
    },
  },
  // transform:rotate
  // transform:scale
  // transform:scaleX
  // transform:scaleY
  // transform:translate
  // transform:translateX
  // transform:translateY
  // transform:skew
  // transform:skewX
  // transform:skewY
  // transform:matrix
  // transformOrigin
  //-------------------------
  // BORDERS
  //-------------------------
  BORDERS: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      data_type: 'string',
      key: 'BORDERS',
      input_type: 'custom_form_input_jsx',
      label: <></>,
      required: false,
      renderCustomFormInput: (formInput: any, formInputs: any, formData: any, formInputChange: any, formSettings: any, formAdditionalData: any) => {
        let inputJSX = (
          <Box sx={{ marginBottom: '12px', marginTop: '12px' }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
            >
              {rLIB('Borders')}
            </Typography>
          </Box>
        )
        return inputJSX
      },
    },
  },
  // border
  // borderTop
  borderTopColor: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      data_type: 'string',
      key: 'borderTopColor',
      input_type: 'custom_form_input_jsx',
      label: rLIB('Border Top Color'),
      required: false,
      renderCustomFormInput: (
        formInput: any,
        formInputs: any,
        formData: any,
        formInputChange: any,
        formSettings: any,
        formAdditionalData: any,
        formHooks: any,
      ) => {
        let inputJSX = <></>
        inputJSX = (
          <Box>
            <Typography>{rLIB('Border Top Color')}</Typography>
            <SketchPicker
              color={getProp(formData, formInput.key, '#ffffff')}
              onChangeComplete={(event: any) => {
                formInputChange(formInput.key, event.hex, true)
              }}
            />
          </Box>
        )
        return inputJSX
      },
    },
  },
  borderTopStyle: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      key: 'borderTopStyle',
      label: rLIB('Border Top Style'),
      input_type: 'multiple_choice_radio',
      data_type: 'string',
      required: false,
      options: [
        { key: 'solid', value: rLIB('Solid') },
        { key: 'dashed', value: rLIB('Dashed') },
        { key: 'dotted', value: rLIB('Dotted') },
        { key: 'none', value: rLIB('None') },
      ],
    },
  },
  borderTopWidth: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    isNumber: true,
    input: {
      key: 'borderTopWidth',
      label: rLIB('Border Top Width (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  // borderRight
  borderRightColor: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      data_type: 'string',
      key: 'borderRightColor',
      input_type: 'custom_form_input_jsx',
      label: rLIB('Border Right Color'),
      required: false,
      renderCustomFormInput: (
        formInput: any,
        formInputs: any,
        formData: any,
        formInputChange: any,
        formSettings: any,
        formAdditionalData: any,
        formHooks: any,
      ) => {
        let inputJSX = <></>
        inputJSX = (
          <Box>
            <Typography>{rLIB('Border Right Color')}</Typography>
            <SketchPicker
              color={getProp(formData, formInput.key, '#ffffff')}
              onChangeComplete={(event: any) => {
                formInputChange(formInput.key, event.hex, true)
              }}
            />
          </Box>
        )
        return inputJSX
      },
    },
  },
  borderRightStyle: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      key: 'borderRightStyle',
      label: rLIB('Border Right Style'),
      input_type: 'multiple_choice_radio',
      data_type: 'string',
      required: false,
      options: [
        { key: 'solid', value: rLIB('Solid') },
        { key: 'dashed', value: rLIB('Dashed') },
        { key: 'dotted', value: rLIB('Dotted') },
        { key: 'none', value: rLIB('None') },
      ],
    },
  },
  borderRightWidth: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    isNumber: true,
    input: {
      key: 'borderRightWidth',
      label: rLIB('Border Right Width (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  // borderBottom
  borderBottomColor: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      data_type: 'string',
      key: 'borderBottomColor',
      input_type: 'custom_form_input_jsx',
      label: rLIB('Border Bottom Color'),
      required: false,
      renderCustomFormInput: (
        formInput: any,
        formInputs: any,
        formData: any,
        formInputChange: any,
        formSettings: any,
        formAdditionalData: any,
        formHooks: any,
      ) => {
        let inputJSX = <></>
        inputJSX = (
          <Box>
            <Typography>{rLIB('Border Bottom Color')}</Typography>
            <SketchPicker
              color={getProp(formData, formInput.key, '#ffffff')}
              onChangeComplete={(event: any) => {
                formInputChange(formInput.key, event.hex, true)
              }}
            />
          </Box>
        )
        return inputJSX
      },
    },
  },
  borderBottomStyle: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      key: 'borderBottomStyle',
      label: rLIB('Border Bottom Style'),
      input_type: 'multiple_choice_radio',
      data_type: 'string',
      required: false,
      options: [
        { key: 'solid', value: rLIB('Solid') },
        { key: 'dashed', value: rLIB('Dashed') },
        { key: 'dotted', value: rLIB('Dotted') },
        { key: 'none', value: rLIB('None') },
      ],
    },
  },
  borderBottomWidth: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    isNumber: true,
    input: {
      key: 'borderBottomWidth',
      label: rLIB('Border Bottom Width (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  // borderLeft
  borderLeftColor: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      data_type: 'string',
      key: 'borderLeftColor',
      input_type: 'custom_form_input_jsx',
      label: rLIB('Border Left Color'),
      required: false,
      renderCustomFormInput: (
        formInput: any,
        formInputs: any,
        formData: any,
        formInputChange: any,
        formSettings: any,
        formAdditionalData: any,
        formHooks: any,
      ) => {
        let inputJSX = <></>
        inputJSX = (
          <Box>
            <Typography>{rLIB('Border Left Color')}</Typography>
            <SketchPicker
              color={getProp(formData, formInput.key, '#ffffff')}
              onChangeComplete={(event: any) => {
                formInputChange(formInput.key, event.hex, true)
              }}
            />
          </Box>
        )
        return inputJSX
      },
    },
  },
  borderLeftStyle: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    input: {
      key: 'borderLeftStyle',
      label: rLIB('Border Left Style'),
      input_type: 'multiple_choice_radio',
      data_type: 'string',
      required: false,
      options: [
        { key: 'solid', value: rLIB('Solid') },
        { key: 'dashed', value: rLIB('Dashed') },
        { key: 'dotted', value: rLIB('Dotted') },
        { key: 'none', value: rLIB('None') },
      ],
    },
  },
  borderLeftWidth: {
    allowed_components: {
      static_table_container: true,
      static_table_row: true,
      static_table_cell: true,
      view: true,
    },
    isNumber: true,
    input: {
      key: 'borderLeftWidth',
      label: rLIB('Border Left Width (pt)'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  // borderTopLeftRadius
  // borderTopRightRadius
  // borderBottomRightRadius
  // borderBottomLeftRadius
  // borderColor: {
  //   allowed_components: {
  //     static_table_container: true,
  //     static_table_row: true,
  //     static_table_cell: true,
  //     view: true,
  //   },
  //   input: {
  //     data_type: 'string',
  //     key: 'borderColor',
  //     input_type: 'custom_form_input_jsx',
  //     label: rLIB('Border Color'),
  //     required: false,
  //     renderCustomFormInput: (
  //       formInput: any,
  //       formInputs: any,
  //       formData: any,
  //       formInputChange: any,
  //       formSettings: any,
  //       formAdditionalData: any,
  //       formHooks: any,
  //     ) => {
  //       let inputJSX = <></>
  //       inputJSX = (
  //         <Box>
  //           <Typography>{rLIB('Border Color')}</Typography>
  //           <SketchPicker
  //             color={getProp(formData, formInput.key, '#ffffff')}
  //             onChangeComplete={(event: any) => {
  //               formInputChange(formInput.key, event.hex, true)
  //             }}
  //           />
  //         </Box>
  //       )
  //       return inputJSX
  //     },
  //   },
  // },
  // borderStyle: {
  //   allowed_components: {
  //     static_table_container: true,
  //     static_table_row: true,
  //     static_table_cell: true,
  //     view: true,
  //   },
  //   input: {
  //     key: 'borderStyle',
  //     label: rLIB('Border Style'),
  //     input_type: 'multiple_choice_radio',
  //     data_type: 'string',
  //     required: false,
  //     options: [
  //       { key: 'solid', value: rLIB('Solid') },
  //       { key: 'dashed', value: rLIB('Dashed') },
  //       { key: 'dotted', value: rLIB('Dotted') },
  //       { key: 'none', value: rLIB('None') },
  //     ],
  //   },
  // },
  // borderWidth: {
  //   allowed_components: {
  //     static_table_container: true,
  //     static_table_row: true,
  //     static_table_cell: true,
  //     view: true,
  //   },
  //   isNumber: true,
  //   input: {
  //     key: 'borderWidth',
  //     label: rLIB('Border Width (pt)'),
  //     input_type: 'text_number',
  //     data_type: 'number',
  //     required: false,
  //   },
  // },
}

const elementEditMenuItems = {
  page_settings: {
    // ACTION: {
    //   onClick: ( editHooks: any, onChange: any) => {
    //     // Do Something
    //   },
    //   icon: "ASDF",
    //   text: rLIB('ASDF')
    // },
    openPageSettingsEditDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openPageSettingsEditDialog(editHooks, onChange)
      },
      icon: 'pen-to-square',
      text: rLIB('Edit Page Margins'),
    },
  },
  static_table_container: {
    addTableRow: {
      onClick: (editHooks: any, onChange: any) => {
        addTableRow(editHooks, onChange)
      },
      icon: 'circle-plus',
      text: rLIB('Add Table Row'),
    },
    openStylesEditDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openStylesEditDialog(editHooks, onChange, editHooks.us_selectedComponentType, 'table_style')
      },
      icon: 'brush',
      text: rLIB('Edit Table Styles'),
    },
    // eraseStyles: {
    //   onClick: (editHooks: any, onChange: any) => {
    //     eraseStyles(editHooks, onChange, 'table_style')
    //   },
    //   icon: 'delete-right',
    //   text: rLIB('Erase Table Styles'),
    // },
    moveElementUp: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementUp(editHooks, onChange)
      },
      icon: 'arrow-up',
      text: rLIB('Move Table Up'),
    },
    moveElementDown: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementDown(editHooks, onChange)
      },
      icon: 'arrow-down',
      text: rLIB('Move Table Down'),
    },
    deleteElement: {
      onClick: (editHooks: any, onChange: any) => {
        deleteElement(editHooks, onChange)
      },
      icon: 'trash',
      text: rLIB('Delete Table'),
    },
  },
  static_table_row: {
    openStylesEditDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openStylesEditDialog(editHooks, onChange, editHooks.us_selectedComponentType, 'row_style')
      },
      icon: 'brush',
      text: rLIB('Edit Table Row Styles'),
    },
    // eraseStyles: {
    //   onClick: (editHooks: any, onChange: any) => {
    //     eraseStyles(editHooks, onChange, 'row_style')
    //   },
    //   icon: 'delete-right',
    //   text: rLIB('Erase Table Row Styles'),
    // },
    moveElementUp: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementUp(editHooks, onChange)
      },
      icon: 'arrow-up',
      text: rLIB('Move Table Row Up'),
    },
    moveElementDown: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementDown(editHooks, onChange)
      },
      icon: 'arrow-down',
      text: rLIB('Move Table Row Down'),
    },
    addTableRowCell: {
      onClick: (editHooks: any, onChange: any) => {
        addTableRowCell(editHooks, onChange)
      },
      icon: 'circle-plus',
      text: rLIB('Add Table Row Cell'),
    },
    deleteElement: {
      onClick: (editHooks: any, onChange: any) => {
        deleteElement(editHooks, onChange)
      },
      icon: 'trash',
      text: rLIB('Delete Table Row'),
    },
  },
  static_table_cell: {
    addCellContent: {
      onClick: (editHooks: any, onChange: any) => {
        addContentToCell(editHooks, onChange)
      },
      icon: 'circle-plus',
      text: rLIB('Add Table Cell Content'),
    },
    openStylesEditDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openStylesEditDialog(editHooks, onChange, editHooks.us_selectedComponentType, 'cell_style')
      },
      icon: 'brush',
      text: rLIB('Edit Table Cell Styles'),
    },
    // eraseStyles: {
    //   onClick: (editHooks: any, onChange: any) => {
    //     eraseStyles(editHooks, onChange, 'cell_style')
    //   },
    //   icon: 'delete-right',
    //   text: rLIB('Erase Table Cell Styles'),
    // },
    moveElementUp: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementUp(editHooks, onChange)
      },
      icon: 'arrow-left',
      text: rLIB('Move Table Cell Left'),
    },
    moveElementDown: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementDown(editHooks, onChange)
      },
      icon: 'arrow-right',
      text: rLIB('Move Table Cell Right'),
    },
    deleteElement: {
      onClick: (editHooks: any, onChange: any) => {
        deleteElement(editHooks, onChange)
      },
      icon: 'trash',
      text: rLIB('Delete Table Cell'),
    },
  },
  looped_data_table: {},
  static_image: {
    openImageUploadDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openImageUploadDialog(editHooks, onChange)
      },
      icon: 'pen-to-square',
      text: rLIB('Edit Src'),
    },
    openStylesEditDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openStylesEditDialog(editHooks, onChange, editHooks.us_selectedComponentType, 'image_style')
      },
      icon: 'brush',
      text: rLIB('Edit Image Styles'),
    },
    moveElementUp: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementUp(editHooks, onChange)
      },
      icon: 'arrow-up',
      text: rLIB('Move Image Up'),
    },
    moveElementDown: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementDown(editHooks, onChange)
      },
      icon: 'arrow-down',
      text: rLIB('Move Image Down'),
    },
    deleteElement: {
      onClick: (editHooks: any, onChange: any) => {
        deleteElement(editHooks, onChange)
      },
      icon: 'trash',
      text: rLIB('Delete Image'),
    },
  },
  static_text: {
    openTextContentEditDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openTextContentEditDialog(editHooks, onChange)
      },
      icon: 'pen-to-square',
      text: rLIB('Edit Text'),
    },
    openStylesEditDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openStylesEditDialog(editHooks, onChange, editHooks.us_selectedComponentType, 'text_style')
      },
      icon: 'brush',
      text: rLIB('Edit Text Styles'),
    },
    // eraseStyles: {
    //   onClick: (editHooks: any, onChange: any) => {
    //     eraseStyles(editHooks, onChange, 'text_style')
    //   },
    //   icon: 'delete-right',
    //   text: rLIB('Erase Text Styles'),
    // },
    moveElementUp: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementUp(editHooks, onChange)
      },
      icon: 'arrow-up',
      text: rLIB('Move Text Up'),
    },
    moveElementDown: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementDown(editHooks, onChange)
      },
      icon: 'arrow-down',
      text: rLIB('Move Text Down'),
    },
    deleteElement: {
      onClick: (editHooks: any, onChange: any) => {
        deleteElement(editHooks, onChange)
      },
      icon: 'trash',
      text: rLIB('Delete Text'),
    },
  },
  variable_image: {
    openStylesEditDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openStylesEditDialog(editHooks, onChange, editHooks.us_selectedComponentType, 'image_style')
      },
      icon: 'brush',
      text: rLIB('Edit Variable Image Styles'),
    },
    moveElementUp: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementUp(editHooks, onChange)
      },
      icon: 'arrow-up',
      text: rLIB('Move Variable Image Up'),
    },
    moveElementDown: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementDown(editHooks, onChange)
      },
      icon: 'arrow-down',
      text: rLIB('Move Variable Image Down'),
    },
    deleteElement: {
      onClick: (editHooks: any, onChange: any) => {
        deleteElement(editHooks, onChange)
      },
      icon: 'trash',
      text: rLIB('Delete Variable Image'),
    },
  },
  variable_text: {
    openStylesEditDialog: {
      onClick: (editHooks: any, onChange: any) => {
        openStylesEditDialog(editHooks, onChange, editHooks.us_selectedComponentType, 'text_style')
      },
      icon: 'brush',
      text: rLIB('Edit Variable Text Styles'),
    },
    // openStylesEditDialog2: {
    // onClick: (editHooks: any, onChange: any) => {
    //   openStylesEditDialog(editHooks, onChange, editHooks.us_selectedComponentType, 'fallback_text_style')
    // },
    // icon: 'brush',
    // text: rLIB('Edit Fallback Text Styles'),
    // },
    // eraseStyles: {
    //   onClick: (editHooks: any, onChange: any) => {
    //     eraseStyles(editHooks, onChange, 'text_style')
    //   },
    //   icon: 'delete-right',
    //   text: rLIB('Erase Variable Text Styles'),
    // },
    moveElementUp: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementUp(editHooks, onChange)
      },
      icon: 'arrow-up',
      text: rLIB('Move Variable Text Up'),
    },
    moveElementDown: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementDown(editHooks, onChange)
      },
      icon: 'arrow-down',
      text: rLIB('Move Variable Text Down'),
    },
    deleteElement: {
      onClick: (editHooks: any, onChange: any) => {
        deleteElement(editHooks, onChange)
      },
      icon: 'trash',
      text: rLIB('Delete Variable Text'),
    },
  },
  view: {},
  page_break: {
    moveElementUp: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementUp(editHooks, onChange)
      },
      icon: 'arrow-up',
      text: rLIB('Move Page Break Up'),
    },
    moveElementDown: {
      onClick: (editHooks: any, onChange: any) => {
        moveElementDown(editHooks, onChange)
      },
      icon: 'arrow-down',
      text: rLIB('Move Page Break Down'),
    },
    deleteElement: {
      onClick: (editHooks: any, onChange: any) => {
        deleteElement(editHooks, onChange)
      },
      icon: 'trash',
      text: rLIB('Delete Page Break'),
    },
  },
}

const minTableRows = 1
const maxTableRows = 20
const minTableColumns = 1
const maxTableColumns = 20

const rootElementFormInputs: TsInterface_FormInputs = {
  mapped_data_field_key: {
    key: 'mapped_data_field_key',
    label: rLIB('New PDF Element Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [
      { key: 'static_text', value: rLIB('Static Text') },
      { key: 'variable_text', value: rLIB('Variable Text') },
      { key: 'static_table', value: rLIB('Table') },
      // { key: 'looped_data_table', value: rLIB('Looped Data Table'), disabled: true },
      { key: 'static_image', value: rLIB('Static Image') },
      { key: 'variable_image', value: rLIB('Variable Image') },
      // { key: 'view', value: rLIB('Container'), disabled: true },
      { key: 'page_break', value: rLIB('Page Break') },
    ],
  },
  text: {
    key: 'text',
    label: rLIB('Text'),
    input_type: 'text_basic',
    data_type: 'string',
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'static_text',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'static_text',
        conditions: [],
      },
    },
  },
  variable_mapping_key: {
    key: 'variable_mapping_key',
    label: rLIB('Variable to Display'),
    input_type: 'multiple_choice_radio',
    data_type: 'string',
    required: false,
    options: [],
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'mapped_data_field_key',
            comparator: '==',
            value: 'variable_text',
            conditions: [],
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'mapped_data_field_key',
            comparator: '==',
            value: 'variable_image',
            conditions: [],
          },
        ],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'mapped_data_field_key',
            comparator: '==',
            value: 'variable_text',
            conditions: [],
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'mapped_data_field_key',
            comparator: '==',
            value: 'variable_image',
            conditions: [],
          },
        ],
      },
    },
  },
  fallback_text: {
    key: 'fallback_text',
    label: rLIB('Fallback Text'),
    input_type: 'text_basic',
    data_type: 'string',
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'variable_text',
        conditions: [],
      },
    },
    // conditional_require: {
    //   active: true,
    //   logic: {
    //     active: true,
    //     logic_type: 'comparison',
    //     source: 'formData',
    //     prop: 'mapped_data_field_key',
    //     comparator: '==',
    //     value: 'variable_text',
    //     conditions: [],
    //   },
    // },
  },
  number_of_columns: {
    key: 'number_of_columns',
    label: rLIB('Number of Columns'),
    input_type: 'text_number',
    data_type: 'number',
    required: false,
    min: minTableColumns,
    max: maxTableColumns,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'static_table',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'static_table',
        conditions: [],
      },
    },
  },
  number_of_rows: {
    key: 'number_of_rows',
    label: rLIB('Number of Rows'),
    input_type: 'text_number',
    data_type: 'number',
    min: minTableRows,
    max: maxTableRows,
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'static_table',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'static_table',
        conditions: [],
      },
    },
  },
}

const nestedElementFormInputs: TsInterface_FormInputs = {
  mapped_data_field_key: {
    key: 'mapped_data_field_key',
    label: rLIB('New PDF Element Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [
      { key: 'static_text', value: rLIB('Static Text') },
      { key: 'variable_text', value: rLIB('Variable Text') },
      { key: 'static_image', value: rLIB('Static Image') },
      { key: 'variable_image', value: rLIB('Variable Image') },
    ],
  },
  text: {
    key: 'text',
    label: rLIB('Text'),
    input_type: 'text_basic',
    data_type: 'string',
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'static_text',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'static_text',
        conditions: [],
      },
    },
  },
  variable_mapping_key: {
    key: 'variable_mapping_key',
    label: rLIB('Variable to Display'),
    input_type: 'multiple_choice_select',
    data_type: 'string',
    required: false,
    options: [],
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'mapped_data_field_key',
            comparator: '==',
            value: 'variable_text',
            conditions: [],
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'mapped_data_field_key',
            comparator: '==',
            value: 'variable_image',
            conditions: [],
          },
        ],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'mapped_data_field_key',
            comparator: '==',
            value: 'variable_text',
            conditions: [],
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'mapped_data_field_key',
            comparator: '==',
            value: 'variable_image',
            conditions: [],
          },
        ],
      },
    },
  },
  fallback_text: {
    key: 'fallback_text',
    label: rLIB('Fallback Text'),
    input_type: 'text_basic',
    data_type: 'string',
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'mapped_data_field_key',
        comparator: '==',
        value: 'variable_text',
        conditions: [],
      },
    },
    // conditional_require: {
    //   active: true,
    //   logic: {
    //     active: true,
    //     logic_type: 'comparison',
    //     source: 'formData',
    //     prop: 'mapped_data_field_key',
    //     comparator: '==',
    //     value: 'variable_text',
    //     conditions: [],
    //   },
    // },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

// Edit - Create and Edit Element Functions
const openNewPdfElementDialog = (
  uc_setUserInterface_FormDialogDisplay: any,
  onChange: any,
  pdfTemplate: TsInterface_UnspecifiedObject,
  componentKeyPath: string[],
  rootOrNested: 'root' | 'nested',
  editHooks: any,
) => {
  let formInputs = rootElementFormInputs
  if (rootOrNested === 'nested') {
    formInputs = nestedElementFormInputs
  }
  // Generate Options for Associated Variable
  let associatedVariableOptions = []
  let associatedVariableOptionsObject: TsInterface_UnspecifiedObject = {}
  if (
    editHooks != null &&
    editHooks.us_pdfFullTemplate != null &&
    editHooks.us_pdfFullTemplate.pdf_variables != null &&
    editHooks.us_pdfPossibleVariables != null
  ) {
    for (let loopVariableKey in editHooks.us_pdfFullTemplate.pdf_variables) {
      let loopVariable = editHooks.us_pdfFullTemplate.pdf_variables[loopVariableKey]
      if (loopVariable.status != 'deleted') {
        associatedVariableOptions.push({
          key: loopVariableKey,
          value: loopVariable.name,
        })
        associatedVariableOptionsObject[loopVariableKey as string] = loopVariable.name
      }
    }
  }

  formInputs.variable_mapping_key.options = associatedVariableOptions.sort(dynamicSort('value', 'asc'))
  // Open Dialog
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: {},
        formInputs: formInputs,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {
          // hide_submit_button: true,
        },
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let componentKey = new Date().getTime().toString() + '_' + generateRandomString(5, null)
            let rowStartTime = new Date().getTime() + 1
            let matchingComponentType = false
            let failedValidation = false
            let failedValidationMessage = <></>
            let updateObject: TsInterface_UnspecifiedObject = {}
            switch (formSubmittedData.mapped_data_field_key) {
              case 'static_table':
                updateObject = {
                  component_type: 'static_table',
                  order: new Date().getTime(),
                  key: componentKey,
                  table_style: {},
                  rows: {},
                }
                for (let i = 0; i < formSubmittedData.number_of_rows; i++) {
                  let rowKey = (rowStartTime + (i + 1) * 1000).toString() + '_' + generateRandomString(5, null)
                  let rowOrder = rowStartTime + (i + 1) * 1000
                  updateObject.rows[rowKey] = {
                    key: rowKey,
                    row_style: {},
                    order: rowOrder,
                    cells: {},
                  }
                  for (let j = 0; j < formSubmittedData.number_of_columns; j++) {
                    let cellKey = (rowStartTime + (i + 1) * 1000 + j + 1).toString() + '_' + generateRandomString(5, null)
                    let cellOrder = rowOrder + j + 1
                    let cellStyle: TsInterface_UnspecifiedObject = {
                      width: 100 / formSubmittedData.number_of_columns,
                      padding: 4,
                      borderBottomColor: '#000000',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: 1,
                      borderRightColor: '#000000',
                      borderRightStyle: 'solid',
                      borderRightWidth: 1,
                    }
                    // If the first row
                    if (i === 0) {
                      cellStyle.borderTopColor = '#000000'
                      cellStyle.borderTopStyle = 'solid'
                      cellStyle.borderTopWidth = 1
                    }
                    // If the first column
                    if (j === 0) {
                      cellStyle.borderLeftColor = '#000000'
                      cellStyle.borderLeftStyle = 'solid'
                      cellStyle.borderLeftWidth = 1
                    }
                    updateObject.rows[rowKey].cells[cellKey] = {
                      key: cellKey,
                      cell_style: cellStyle,
                      order: cellOrder,
                      contents: {},
                    }
                  }
                }
                if (formSubmittedData.number_of_rows < minTableRows) {
                  failedValidation = true
                  failedValidationMessage = (
                    <>
                      {rLIB('Minimum number of rows is')} {minTableRows}
                    </>
                  )
                } else if (formSubmittedData.number_of_rows > maxTableRows) {
                  failedValidation = true
                  failedValidationMessage = (
                    <>
                      {rLIB('Maximum number of rows is')} {maxTableRows}
                    </>
                  )
                }
                if (formSubmittedData.number_of_columns < minTableColumns) {
                  failedValidation = true
                  failedValidationMessage = (
                    <>
                      {rLIB('Minimum number of columns is')} {minTableColumns}
                    </>
                  )
                } else if (formSubmittedData.number_of_columns > maxTableColumns) {
                  failedValidation = true
                  failedValidationMessage = (
                    <>
                      {rLIB('Maximum number of columns is')} {maxTableColumns}
                    </>
                  )
                }
                matchingComponentType = true
                break
              case 'looped_data_table':
                // TODO: Implement
                break
              case 'static_image':
                updateObject = {
                  component_type: 'static_image',
                  order: new Date().getTime(),
                  key: componentKey,
                  image_style: {},
                }
                matchingComponentType = true
                break
              case 'variable_image':
                updateObject = {
                  component_type: 'variable_image',
                  order: new Date().getTime(),
                  key: componentKey,
                  variable_mapping_key: getProp(formSubmittedData, 'variable_mapping_key', ''),
                  image_style: {},
                }
                matchingComponentType = true
                break
              case 'static_text':
                updateObject = {
                  component_type: 'static_text',
                  order: new Date().getTime(),
                  key: componentKey,
                  text: getProp(formSubmittedData, 'text', ''),
                  text_style: {
                    fontSize: 12,
                  },
                }
                matchingComponentType = true
                break
              case 'variable_text':
                updateObject = {
                  component_type: 'variable_text',
                  order: new Date().getTime(),
                  key: componentKey,
                  variable_mapping_name: getProp(associatedVariableOptionsObject, formSubmittedData.variable_mapping_key, null),
                  variable_mapping_key: getProp(formSubmittedData, 'variable_mapping_key', ''),
                  text_style: {
                    fontSize: 12,
                  },
                }
                matchingComponentType = true
                break
              case 'view':
                // TODO: Implement
                break
              case 'page_break':
                updateObject = {
                  component_type: 'page_break',
                  order: new Date().getTime(),
                  key: componentKey,
                }
                matchingComponentType = true
                break
            }
            // TODO: Pass through whole PDF Template Document
            if (failedValidation === true) {
              reject({
                success: false,
                error: {
                  message: rLIB('Invalid Form Data'),
                  details: failedValidationMessage,
                  code: 'ER-D-C-PDFFS-01',
                },
              })
            } else if (matchingComponentType === true) {
              let fullUpdateObject = {
                page_contents: generateNestedData(componentKeyPath, componentKey, updateObject),
              }
              onChange(mergeTwoObjects(fullUpdateObject, pdfTemplate))
                .then((res_OC: any) => {
                  resolve(res_OC)
                })
                .catch((rej_OC: any) => {
                  reject(rej_OC)
                })
            } else {
              reject({
                success: false,
                error: {
                  message: rLIB('Invalid Form Data'),
                  details: rLIB('No matching component type found'),
                  code: 'ER-D-C-PDFFS-02',
                },
              })
            }
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: <>{rLIB('New PDF Template Element')}</>,
        formDialogIcon: (
          <Icon
            type="solid"
            icon="circle-plus"
          />
        ),
      },
    },
  })
}

const determineComponentKeyPath = (editedComponentKey: string, pdfTemplateContents: TsInterface_UnspecifiedObject) => {
  let componentKeyPath: string[] = []
  let foundComponent = false
  // Root Level Components
  for (let loopComponentKey in pdfTemplateContents) {
    if (loopComponentKey === editedComponentKey) {
      componentKeyPath.push(loopComponentKey)
      foundComponent = true
      return componentKeyPath
    }
  }
  // Static Table Rows
  if (foundComponent === false) {
    for (let loopComponentKey in pdfTemplateContents) {
      if (pdfTemplateContents[loopComponentKey].component_type === 'static_table') {
        for (let loopRowKey in pdfTemplateContents[loopComponentKey].rows) {
          if (loopRowKey === editedComponentKey) {
            componentKeyPath.push(loopComponentKey)
            componentKeyPath.push('rows')
            componentKeyPath.push(loopRowKey)
            foundComponent = true
            return componentKeyPath
          }
        }
      }
    }
  }
  // Static Table Cells
  if (foundComponent === false) {
    for (let loopComponentKey in pdfTemplateContents) {
      if (pdfTemplateContents[loopComponentKey].component_type === 'static_table') {
        for (let loopRowKey in pdfTemplateContents[loopComponentKey].rows) {
          for (let loopCellKey in pdfTemplateContents[loopComponentKey].rows[loopRowKey].cells) {
            if (loopCellKey === editedComponentKey) {
              componentKeyPath.push(loopComponentKey)
              componentKeyPath.push('rows')
              componentKeyPath.push(loopRowKey)
              componentKeyPath.push('cells')
              componentKeyPath.push(loopCellKey)
              foundComponent = true
              return componentKeyPath
            }
          }
        }
      }
    }
  }
  // Static Table Cell Contents
  if (foundComponent === false) {
    for (let loopComponentKey in pdfTemplateContents) {
      if (pdfTemplateContents[loopComponentKey].component_type === 'static_table') {
        for (let loopRowKey in pdfTemplateContents[loopComponentKey].rows) {
          for (let loopCellKey in pdfTemplateContents[loopComponentKey].rows[loopRowKey].cells) {
            for (let loopContentKey in pdfTemplateContents[loopComponentKey].rows[loopRowKey].cells[loopCellKey].contents) {
              if (loopContentKey === editedComponentKey) {
                componentKeyPath.push(loopComponentKey)
                componentKeyPath.push('rows')
                componentKeyPath.push(loopRowKey)
                componentKeyPath.push('cells')
                componentKeyPath.push(loopCellKey)
                componentKeyPath.push('contents')
                componentKeyPath.push(loopContentKey)
                foundComponent = true
                return componentKeyPath
              }
            }
          }
        }
      }
    }
  }
  return componentKeyPath
}

const getComponentProps = (componentKey: string, pdfTemplate: TsInterface_PdfTemplate): TsInterface_UnspecifiedObject => {
  let componentProps: TsInterface_UnspecifiedObject = {}
  let componentKeyPath = determineComponentKeyPath(componentKey, pdfTemplate.page_contents)
  if (componentKeyPath.length > 0) {
    componentProps = getNestedData(componentKeyPath, pdfTemplate.page_contents)
  }
  return componentProps
}

const generateNestedData = (arr: any[], key: any, value: any) => {
  return arr.reduceRight((acc, current) => ({ [current]: acc }), { [key]: value })
}

const generateDeletedNestedData = (template: TsInterface_PdfTemplate, pathArray: any[]): TsInterface_UnspecifiedObject => {
  // Start with the reference to the original object
  let current: TsInterface_UnspecifiedObject = template
  pathArray.unshift('page_contents')
  // Iterate over the pathArray, except for the last element
  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i]
    // If the key doesn't exist, create an empty object at that key
    if (!current[key]) {
      current[key] = {}
    }
    // Move deeper into the object
    current = current[key]
  }
  // Set the value at the last key in the path
  delete current[pathArray[pathArray.length - 1]]
  // Return the modified object
  return template
}

const getNestedData = (arr: any[], obj: any) => {
  return arr.reduce((acc, current) => acc && acc[current], obj)
}

const generatePdfComponentUpdateObject = (
  componentKey: string,
  template: TsInterface_PdfTemplate,
  propKey: string,
  propValue: any,
): TsInterface_UnspecifiedObject => {
  let updateObject: TsInterface_UnspecifiedObject = {}
  let potentialComponentKeyPath = determineComponentKeyPath(componentKey, template.page_contents)
  if (potentialComponentKeyPath.length > 0) {
    updateObject = {
      page_contents: generateNestedData(potentialComponentKeyPath, propKey, propValue),
    }
  }
  return updateObject
}

const generatePdfComponentDeleteObject = (componentKey: string, template: TsInterface_PdfTemplate): TsInterface_UnspecifiedObject => {
  let updateObject: TsInterface_UnspecifiedObject = {}
  let potentialComponentKeyPath = determineComponentKeyPath(componentKey, template.page_contents)
  if (potentialComponentKeyPath.length > 0) {
    // let parentComponentPath = potentialComponentKeyPath.slice(0, -1)
    updateObject = generateDeletedNestedData({ ...template }, potentialComponentKeyPath)
  }
  return updateObject
}

const generatePdfComponentSwitchUpdateObject = (
  componentKey1: string,
  componentKey2: string,
  template: TsInterface_PdfTemplate,
  propKey: string,
  propValue1: any,
  propValue2: any,
): TsInterface_UnspecifiedObject => {
  let updateObject1: TsInterface_UnspecifiedObject = {}
  let updateObject2: TsInterface_UnspecifiedObject = {}
  if (template != null && template.page_contents != null) {
    // Determine Component Key Path
    let potentialComponentKeyPath1 = determineComponentKeyPath(componentKey1, template.page_contents)
    let potentialComponentKeyPath2 = determineComponentKeyPath(componentKey2, template.page_contents)
    if (potentialComponentKeyPath1.length > 0 && potentialComponentKeyPath2.length > 0) {
      updateObject1 = generateNestedData(potentialComponentKeyPath1, propKey, propValue2)
      updateObject2 = generateNestedData(potentialComponentKeyPath2, propKey, propValue1)
    }
  }
  let mergedUpdateObject = {
    page_contents: mergeTwoObjects(updateObject1, updateObject2),
  }
  return mergedUpdateObject
}

// Edit - All
const openStylesEditDialog = (editHooks: any, onChange: any, componentKey: string, stylePropKey: string) => {
  // Generate Form Inputs
  let formInputs: TsInterface_FormInputs = {}
  for (let loopInputKey in cssFormProperties) {
    let loopInput = cssFormProperties[loopInputKey]
    if (
      loopInput != null &&
      loopInput.allowed_components != null &&
      (loopInput.allowed_components[componentKey] === true || loopInput.allowed_components['all_elements'] === true)
    ) {
      formInputs[loopInputKey] = loopInput.input
    }
  }
  let formData: TsInterface_UnspecifiedObject = { ...getProp(getComponentProps(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate), stylePropKey, {}) }
  // text_style
  editHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: formData,
        formInputs: formInputs,
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let pdfTemplate = editHooks.us_pdfTemplate
            let initialUpdateObject: TsInterface_UnspecifiedObject = {}
            for (let loopInputKey in formSubmittedData) {
              let loopInputValue = formSubmittedData[loopInputKey]
              if (loopInputValue != null && loopInputValue !== '') {
                if (cssFormProperties[loopInputKey].isNumber === true) {
                  if (!isNaN(loopInputValue)) {
                    initialUpdateObject[loopInputKey] = loopInputValue
                  }
                } else {
                  initialUpdateObject[loopInputKey] = loopInputValue
                }
              }
            }
            // Generate Update Object
            let updateObject = generatePdfComponentUpdateObject(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate, stylePropKey, initialUpdateObject)
            onChange(mergeTwoObjects(updateObject, pdfTemplate))
              .then((res_OC: any) => {
                resolve(res_OC)
              })
              .catch((rej_OC: any) => {
                reject(rej_OC)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: <>{rLIB('Edit Text')}</>,
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

// const eraseStyles = (editHooks: any, onChange: any, stylePropKey: string) => {
// editHooks.uc_setUserInterface_ConfirmDialogDisplay({
//   display: true,
//   confirm: {
//     color: 'error',
//     icon: <Icon icon="delete-right" />,
//     header: rLIB('Erase Element Styles'),
//     text: rLIB('Are you sure that you want to erase the styles of this PDF Element? This cannot be undone'),
//     submit_text: rLIB('Delete'),
//     submit_callback: () => {
//       return new Promise((resolve, reject) => {
//         // Generate Update Object
//         let pdfTemplate = editHooks.us_pdfTemplate
//         let updateObject = generatePdfComponentUpdateObject(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate, stylePropKey, {})
//         console.log(editHooks.us_selectedComponentKey)
//         console.log(updateObject)
//         // console.log(mergeTwoObjects(updateObject, pdfTemplate))
//         console.log(mergeTwoObjects(pdfTemplate, updateObject))
//         resolve({})
//         // let updateObject = generatePdfComponentDeleteObject(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate)
//         // editHooks
//         //   .onChangeWithDelete(updateObject)
//         //   .then((res_OC: any) => {
//         //     resolve(res_OC)
//         //   })
//         //   .catch((rej_OC: any) => {
//         //     reject(rej_OC)
//         //   })
//       })
//     },
//   },
// })
// }

const moveElementUp = (editHooks: any, onChange: any) => {
  // Determine Component Key Path
  let pdfTemplate = editHooks.us_pdfTemplate
  let potentialComponentKeyPath = determineComponentKeyPath(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate.page_contents)
  if (potentialComponentKeyPath.length > 0) {
    // Get all components in the same parent
    let parentComponentPath = potentialComponentKeyPath.slice(0, -1)
    let sameLevelComponents = objectToArray(getNestedData(parentComponentPath, editHooks.us_pdfTemplate.page_contents)).sort(dynamicSort('order', 'asc'))
    // Get the index of the selected component
    for (let i = 0; i < sameLevelComponents.length; i++) {
      if (
        sameLevelComponents != null &&
        sameLevelComponents[i] != null &&
        sameLevelComponents[i]['key'] != null &&
        sameLevelComponents[i]['key'] === editHooks.us_selectedComponentKey
      ) {
        // Trade orders with the component above if not the first element
        if (i > 0) {
          let upstreamComponentKey = sameLevelComponents[i - 1]['key']
          let upstreamComponentOrder = sameLevelComponents[i - 1]['order']
          let downstreamComponentKey = sameLevelComponents[i]['key']
          let downstreamComponentOrder = sameLevelComponents[i]['order']
          let updateObject = generatePdfComponentSwitchUpdateObject(
            upstreamComponentKey,
            downstreamComponentKey,
            editHooks.us_pdfTemplate,
            'order',
            upstreamComponentOrder,
            downstreamComponentOrder,
          )
          onChange(mergeTwoObjects(updateObject, pdfTemplate))
        }
      }
    }
  }
}

const moveElementDown = (editHooks: any, onChange: any) => {
  // Determine Component Key Path
  let pdfTemplate = editHooks.us_pdfTemplate
  let potentialComponentKeyPath = determineComponentKeyPath(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate.page_contents)
  if (potentialComponentKeyPath.length > 0) {
    // Get all components in the same parent
    let parentComponentPath = potentialComponentKeyPath.slice(0, -1)
    let sameLevelComponents = objectToArray(getNestedData(parentComponentPath, editHooks.us_pdfTemplate.page_contents)).sort(dynamicSort('order', 'asc'))
    // Get the index of the selected component
    for (let i = 0; i < sameLevelComponents.length; i++) {
      if (
        sameLevelComponents != null &&
        sameLevelComponents[i] != null &&
        sameLevelComponents[i]['key'] != null &&
        sameLevelComponents[i]['key'] === editHooks.us_selectedComponentKey
      ) {
        // Trade orders with the component above if not the first element
        if (i < sameLevelComponents.length - 1) {
          let upstreamComponentKey = sameLevelComponents[i]['key']
          let upstreamComponentOrder = sameLevelComponents[i]['order']
          let downstreamComponentKey = sameLevelComponents[i + 1]['key']
          let downstreamComponentOrder = sameLevelComponents[i + 1]['order']
          let updateObject = generatePdfComponentSwitchUpdateObject(
            upstreamComponentKey,
            downstreamComponentKey,
            editHooks.us_pdfTemplate,
            'order',
            upstreamComponentOrder,
            downstreamComponentOrder,
          )
          onChange(mergeTwoObjects(updateObject, pdfTemplate))
        }
      }
    }
  }
}

const deleteElement = (editHooks: any, onChange: any) => {
  editHooks.uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'error',
      icon: <Icon icon="trash" />,
      header: rLIB('Delete Element'),
      text: rLIB('Are you sure that you want to delete this PDF Element? This cannot be undone'),
      submit_text: rLIB('Delete'),
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          let updateObject = generatePdfComponentDeleteObject(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate)
          editHooks
            .onChangeWithDelete(updateObject)
            .then((res_OC: any) => {
              resolve(res_OC)
            })
            .catch((rej_OC: any) => {
              reject(rej_OC)
            })
        })
      },
    },
  })
}

// Edit - Table Container
const addTableRow = (editHooks: any, onChange: any) => {
  // Determine Component Key Path
  let pdfTemplate = editHooks.us_pdfTemplate
  let potentialComponentKeyPath = determineComponentKeyPath(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate.page_contents)
  if (potentialComponentKeyPath.length > 0) {
    let tableComponent = getNestedData(potentialComponentKeyPath, pdfTemplate.page_contents)
    potentialComponentKeyPath.push('rows')
    // Get max number of cells for a row
    let maxNumberOfCells = 0
    for (let loopRowKey in tableComponent.rows) {
      let numberOfCells = Object.keys(tableComponent.rows[loopRowKey].cells).length
      if (numberOfCells > maxNumberOfCells) {
        maxNumberOfCells = numberOfCells
      }
    }
    // Generate New Row Update Object
    let newRowKey = new Date().getTime().toString() + '_' + generateRandomString(5, null)
    let newRowOrder = new Date().getTime()
    let newRowUpdateObject: TsInterface_UnspecifiedObject = {
      key: newRowKey,
      row_style: {},
      order: newRowOrder,
      cells: {},
    }
    // Add Cells to New Row
    for (let i = 0; i < maxNumberOfCells; i++) {
      let newCellKey = newRowOrder + i + 1 + '_' + generateRandomString(5, null)
      let newCellOrder = newRowOrder + i + 1
      let cellStyle: TsInterface_UnspecifiedObject = {
        width: 100 / maxNumberOfCells,
        padding: 4,
        borderBottomColor: '#000000',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderRightColor: '#000000',
        borderRightStyle: 'solid',
        borderRightWidth: 1,
      }
      // If the first column
      if (i === 0) {
        cellStyle.borderLeftColor = '#000000'
        cellStyle.borderLeftStyle = 'solid'
        cellStyle.borderLeftWidth = 1
      }
      newRowUpdateObject.cells[newCellKey] = {
        key: newCellKey,
        cell_style: cellStyle,
        order: newCellOrder,
        contents: {},
      }
    }
    let fullUpdateObject = {
      page_contents: generateNestedData(potentialComponentKeyPath, newRowKey, newRowUpdateObject),
    }
    onChange(mergeTwoObjects(fullUpdateObject, pdfTemplate))
  }
}

// Edit Table Row
const addTableRowCell = (editHooks: any, onChange: any) => {
  // Determine Component Key Path
  let pdfTemplate = editHooks.us_pdfTemplate
  let potentialComponentKeyPath = determineComponentKeyPath(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate.page_contents)
  if (potentialComponentKeyPath.length > 0) {
    potentialComponentKeyPath.push('cells')
    // Generate New Cell Update Object
    let newCellKey = new Date().getTime().toString() + '_' + generateRandomString(5, null)
    let newCellOrder = new Date().getTime()
    let newCellUpdateObject: TsInterface_UnspecifiedObject = {
      key: newCellKey,
      cell_style: {
        padding: 4,
      },
      order: newCellOrder,
      contents: {},
    }
    let fullUpdateObject = {
      page_contents: generateNestedData(potentialComponentKeyPath, newCellKey, newCellUpdateObject),
    }
    onChange(mergeTwoObjects(fullUpdateObject, pdfTemplate))
  }
}

// Edit - Table Cell
const addContentToCell = (editHooks: any, onChange: any) => {
  let componentKeyPath = determineComponentKeyPath(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate.page_contents)
  if (componentKeyPath.length > 0) {
    componentKeyPath.push('contents')
    openNewPdfElementDialog(editHooks.uc_setUserInterface_FormDialogDisplay, onChange, editHooks.us_pdfTemplate, componentKeyPath, 'nested', editHooks)
  }
}

// Edit - Page
const openPageSettingsEditDialog = (editHooks: any, onChange: any) => {
  // Generate Form Inputs
  let formInputs: TsInterface_FormInputs = {}
  for (let loopInputKey in cssFormProperties) {
    let loopInput = cssFormProperties[loopInputKey]
    if (loopInput != null && loopInput.allowed_components != null && loopInput.allowed_components['page_container'] === true) {
      formInputs[loopInputKey] = loopInput.input
    }
  }
  let formData: TsInterface_UnspecifiedObject = getProp(editHooks.us_pdfTemplate, 'page_settings', {})
  // text_style
  editHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: formData,
        formInputs: formInputs,
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let pdfTemplate = editHooks.us_pdfTemplate
            let initialUpdateObject: TsInterface_UnspecifiedObject = {}
            for (let loopInputKey in formSubmittedData) {
              let loopInputValue = formSubmittedData[loopInputKey]
              if (loopInputValue != null && loopInputValue !== '') {
                if (cssFormProperties[loopInputKey].isNumber === true) {
                  if (!isNaN(loopInputValue)) {
                    initialUpdateObject[loopInputKey] = loopInputValue
                  }
                } else {
                  initialUpdateObject[loopInputKey] = loopInputValue
                }
              }
            }
            // Generate Update Object
            let updateObject = {
              page_settings: initialUpdateObject,
            }
            onChange(mergeTwoObjects(updateObject, pdfTemplate))
              .then((res_OC: any) => {
                resolve(res_OC)
              })
              .catch((rej_OC: any) => {
                reject(rej_OC)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: <>{rLIB('Edit Text')}</>,
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

// Edit - Text
const openTextContentEditDialog = (editHooks: any, onChange: any) => {
  editHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: {
          text: getProp(getComponentProps(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate), 'text', ''),
        },
        formInputs: {
          text: {
            key: 'text',
            label: rLIB('Text'),
            input_type: 'text_basic',
            data_type: 'string',
            required: true,
          },
        },
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let pdfTemplate = editHooks.us_pdfTemplate
            // Generate Update Object
            let updateObject = generatePdfComponentUpdateObject(
              editHooks.us_selectedComponentKey,
              editHooks.us_pdfTemplate,
              'text',
              getProp(formSubmittedData, 'text', ''),
            )
            onChange(mergeTwoObjects(updateObject, pdfTemplate))
              .then((res_OC: any) => {
                resolve(res_OC)
              })
              .catch((rej_OC: any) => {
                reject(rej_OC)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: <>{rLIB('Edit Text')}</>,
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

// Edit - Image
const openImageUploadDialog = (editHooks: any, onChange: any) => {
  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(file)
    })
  }

  const uploadFiles = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: TsType_UnknownPromise[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (file != null && typeof file === 'object') {
            promiseArray.push(
              readAsDataURL(file)
                .then((res_RADURL) => {
                  readFiles[fileIndex] = {
                    file_name: file.name,
                    file: file,
                    data_url: res_RADURL,
                  }
                })
                .catch((rej_RADURL) => {
                  // Nothing
                }),
            )
          }
        }
        Promise.all(promiseArray).finally(() => {
          let pdfTemplate = editHooks.us_pdfTemplate
          if (readFiles != null && readFiles[0] != null && readFiles[0].data_url != null) {
            let updateObject = generatePdfComponentUpdateObject(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate, 'src', readFiles[0].data_url)
            onChange(mergeTwoObjects(updateObject, pdfTemplate))
              .then((res_OC: any) => {
                resolve(res_OC)
                editHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              })
              .catch((rej_OC: any) => {
                reject(rej_OC)
              })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Fail to upload image'),
                details: rLIB('Failed to read file'),
                code: 'ER-D-PDFE-UF-02',
              },
            })
          }
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Fail to upload image'),
            details: rLIB('Invalid file'),
            code: 'ER-D-PDFE-UF-02',
          },
        })
      }
    })
  }

  editHooks.uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: (
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            editHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: themeVariables.info_main }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="image" />
              </IconButton>
              <Typography
                variant={'h6'}
                sx={{ flexGrow: 1, color: themeVariables.white }}
              >
                {rLIB('Upload Image')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '16px', textAlign: 'center' }}>
            <FileUploadButton
              multiple={false}
              button={{
                text: <></>,
                icon: (
                  <Icon
                    icon="cloud-arrow-up"
                    sx={{ fontSize: '20px' }}
                  />
                ),
                color: 'info',
                className: 'tw-mr-2 bp_icon_only_button',
                variant: 'contained',
                disabled: false,
              }}
              accept="image/*"
              onChange={uploadFiles}
              additionalFileUploadParams={{}}
            />
          </DialogContent>
        </Dialog>
      ),
      settings: {
        max_width: 'lg',
      },
    },
  })

  // editHooks.uc_setUserInterface_FormDialogDisplay({
  //   display: true,
  //   form: {
  //     form: {
  //       formAdditionalData: {},
  //       formData: {
  //         src: getProp(getComponentProps(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate), 'src', ''),
  //       },
  //       formInputs: {
  //         src: {
  //           key: 'src',
  //           label: rLIB('Image SRC'),
  //           input_type: 'text_basic',
  //           data_type: 'string',
  //           required: true,
  //         },
  //       },
  //       formSettings: {},
  //       formSubmission: (
  //         formSubmittedData: TsInterface_FormSubmittedData,
  //         formAdditionalData: TsInterface_FormAdditionalData,
  //         formHooks: TsInterface_FormHooksObject,
  //       ) => {
  //         return new Promise((resolve, reject) => {
  //           let pdfTemplate = editHooks.us_pdfTemplate
  //           // Generate Update Object
  //           let updateObject = generatePdfComponentUpdateObject(
  //             editHooks.us_selectedComponentKey,
  //             editHooks.us_pdfTemplate,
  //             'src',
  //             getProp(formSubmittedData, 'src', ''),
  //           )
  //           onChange(mergeTwoObjects(updateObject, pdfTemplate))
  //             .then((res_OC: any) => {
  //               resolve(res_OC)
  //             })
  //             .catch((rej_OC: any) => {
  //               reject(rej_OC)
  //             })
  //         })
  //       },
  //     },
  //     dialog: {
  //       formDialogHeaderColor: 'success',
  //       formDialogHeaderText: <>{rLIB('Edit Src')}</>,
  //       formDialogIcon: (
  //         <Icon
  //           type="solid"
  //           icon="pen-to-square"
  //         />
  //       ),
  //     },
  //   },
  // })
}

///////////////////////////////
// JSX Generation Functions
///////////////////////////////

// Menu
const rJSX_editComponentMenu = (editHooks: TsInterface_UnspecifiedObject, onChange: (template: TsInterface_UnspecifiedObject) => Promise<any>) => {
  let editMenuJSX = <></>
  let menuActions = getProp(elementEditMenuItems, editHooks.us_selectedComponentType, {})
  editMenuJSX = (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={
        editHooks != null && editHooks.us_anchorPosition !== null ? { top: editHooks.us_anchorPosition.top, left: editHooks.us_anchorPosition.left } : undefined
      }
      open={Boolean(editHooks.us_anchorPosition)}
      onClose={() => {
        if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
          editHooks.us_setSelectedComponentKey(null)
          editHooks.us_setSelectedComponentType(null)
          editHooks.us_setAnchorPosition(null)
        }
      }}
    >
      {objectToArray(menuActions).map((action: TsInterface_UnspecifiedObject, index: number) => (
        <MenuItem
          key={index}
          onClick={(event) => {
            event.stopPropagation()
            action.onClick(editHooks, onChange)
            if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
              editHooks.us_setSelectedComponentKey(null)
              editHooks.us_setSelectedComponentType(null)
              editHooks.us_setAnchorPosition(null)
            }
          }}
        >
          <Icon
            icon={action.icon}
            sx={{ marginRight: '8px', width: '24px' }}
          />
          {action.text}
        </MenuItem>
      ))}
    </Menu>
  )
  return editMenuJSX
}

// Style Management
const returnStylePlusDefaults = (styles: TsInterface_UnspecifiedObject, styleKey: string): TsInterface_UnspecifiedObject => {
  let combinedStyles = { ...styles }
  if (styleKey === 'table_style') {
    // combinedStyles['display'] = 'flex'
    combinedStyles['width'] = 'auto'
    combinedStyles['borderCollapse'] = 'collapse'
  } else if (styleKey === 'row_style') {
    combinedStyles['display'] = 'flex'
    combinedStyles['flexDirection'] = 'row'
    combinedStyles['justifyContent'] = 'flex-start'
    combinedStyles['borderCollapse'] = 'collapse'
  } else if (styleKey === 'cell_style') {
    combinedStyles['flexShrink'] = 1
    combinedStyles['minWidth'] = '20px'
    combinedStyles['flexGrow'] = 1
    combinedStyles['display'] = 'inline-block'
    combinedStyles['borderCollapse'] = 'collapse'
  }
  for (let loopProp in propertiesWithSuffixes) {
    if (combinedStyles[loopProp] != null) {
      combinedStyles[loopProp] = propertiesWithSuffixes[loopProp](styles, combinedStyles[loopProp])
    }
  }
  return combinedStyles
}

// PDF Elements
const defaultUnderConstructionComponent = (): JSX.Element => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: themeVariables.background_json,
        color: themeVariables.white,
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <Typography variant="h4">{rLIB('Custom element not editable')}</Typography>
    </Box>
  )
}

const generatePdfComponent_StaticTable = (
  component: TsInterface_PdfTemplateComponent_StaticTable,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    // Determine Max Number of Cells
    let maxNumberOfCells = 0
    objectToArray(component.rows).forEach((row) => {
      let numberOfCells = Object.keys(row.cells).length
      if (numberOfCells > maxNumberOfCells) {
        maxNumberOfCells = numberOfCells
      }
    })

    // JSX
    componentJSX = (
      <View style={returnStylePlusDefaults(getProp(component, 'table_style', {}), 'table_style')}>
        {objectToArray(component.rows)
          .sort(dynamicSort('order', 'asc'))
          .map((row: TsInterface_UnspecifiedObject, rowIndex: number) => (
            <View
              key={rowIndex}
              style={returnStylePlusDefaults(getProp(row, 'row_style', {}), 'row_style')}
            >
              {objectToArray(row.cells)
                .sort(dynamicSort('order', 'asc'))
                .map((cell: TsInterface_UnspecifiedObject, cellIndex: number) => (
                  <View
                    key={cellIndex}
                    style={returnStylePlusDefaults(getProp(cell, 'cell_style', {}), 'cell_style')}
                  >
                    {objectToArray(cell.contents)
                      .sort(dynamicSort('order', 'asc'))
                      .map((component: TsType_PdfTemplateComponent, componentIndex: number) => (
                        <React.Fragment key={componentIndex}>{generatePdfComponent(component, pdfData, mode, editHooks)}</React.Fragment>
                      ))}
                  </View>
                ))}
            </View>
          ))}
      </View>
    )
  } else if (mode === 'edit') {
    componentJSX = (
      <Box
        style={returnStylePlusDefaults(getProp(component, 'table_style', {}), 'table_style')}
        className="tw-cursor-pointer tw-m-2 tw-p-2"
        sx={{
          'border': '1px solid rgba(0,0,0,0.1)',
          '&:hover': {
            border: '1px solid ' + themeVariables.warning_main + ' !important',
          },
        }}
        onClick={(event) => {
          event.stopPropagation()
          if (editHooks != null && editHooks.us_setAnchorPosition != null) {
            editHooks.us_setSelectedComponentKey(getProp(component, 'key', null))
            editHooks.us_setSelectedComponentType('static_table_container')
            editHooks.us_setAnchorPosition({
              top: event.clientY,
              left: event.clientX,
            })
          }
        }}
      >
        {objectToArray(component.rows)
          .sort(dynamicSort('order', 'asc'))
          .map((row: TsInterface_UnspecifiedObject, rowIndex: number) => (
            <Box
              key={rowIndex}
              style={returnStylePlusDefaults(getProp(row, 'row_style', {}), 'row_style')}
              className="tw-cursor-pointer tw-m-1 tw-p-1"
              sx={{
                'border': '1px solid rgba(0,0,0,.1)',
                '&:hover': {
                  border: '1px solid ' + themeVariables.warning_main + ' !important',
                },
              }}
              onClick={(event) => {
                event.stopPropagation()
                if (editHooks != null && editHooks.us_setAnchorPosition != null) {
                  editHooks.us_setSelectedComponentKey(getProp(row, 'key', null))
                  editHooks.us_setSelectedComponentType('static_table_row')
                  editHooks.us_setAnchorPosition({
                    top: event.clientY,
                    left: event.clientX,
                  })
                }
              }}
            >
              {objectToArray(row.cells)
                .sort(dynamicSort('order', 'asc'))
                .map((cell: TsInterface_UnspecifiedObject, cellIndex: number) => (
                  <Box
                    key={cellIndex}
                    style={returnStylePlusDefaults(getProp(cell, 'cell_style', {}), 'cell_style')}
                    className="tw-cursor-pointer tw-m-1 tw-p-3 tw-inline-block"
                    sx={{
                      'border': '1px solid rgba(0,0,0,.1)',
                      '&:hover': {
                        border: '1px solid ' + themeVariables.warning_main + ' !important',
                      },
                    }}
                    onClick={(event) => {
                      event.stopPropagation()
                      if (editHooks != null && editHooks.us_setAnchorPosition != null) {
                        editHooks.us_setSelectedComponentKey(getProp(cell, 'key', null))
                        editHooks.us_setSelectedComponentType('static_table_cell')
                        editHooks.us_setAnchorPosition({
                          top: event.clientY,
                          left: event.clientX,
                        })
                      }
                    }}
                  >
                    <Box sx={{ minWidth: '24px', minHeight: '24px' }}>
                      {objectToArray(cell.contents)
                        .sort(dynamicSort('order', 'asc'))
                        .map((component: TsType_PdfTemplateComponent, componentIndex: number) => (
                          <React.Fragment key={componentIndex}>{generatePdfComponent(component, pdfData, mode, editHooks)}</React.Fragment>
                        ))}
                    </Box>
                  </Box>
                ))}
            </Box>
          ))}
      </Box>
    )
  }
  return componentJSX
}

const generatePdfComponent_LoopedDataTable = (
  component: TsInterface_PdfTemplateComponent_LoopedDataTable,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let tableData = getProp(pdfData, component.table_data_variable_mapping_key, [])
  let componentJSX = <></>
  if (mode === 'view') {
    componentJSX = (
      <View style={returnStylePlusDefaults(getProp(component, 'table_style', {}), 'table_style')}>
        <View style={returnStylePlusDefaults(getProp(component, 'row_style', {}), 'row_style')}>
          {objectToArray(getProp(component, 'columns', {}))
            .sort(dynamicSort('order', 'asc'))
            .map((column: TsInterface_UnspecifiedObject, columnIndex: number) => (
              <View
                style={returnStylePlusDefaults(getProp(column, 'column_header_style', {}), 'column_header_style')}
                key={columnIndex}
              >
                <Text>{column.header_text}</Text>
              </View>
            ))}
        </View>
        {objectToArray(tableData).map(
          (
            rowData: TsInterface_UnspecifiedObject,
            rowIndex: number, // TODO: Add Sorting?
          ) => (
            <View
              style={returnStylePlusDefaults(getProp(component, 'row_style', {}), 'row_style')}
              key={rowIndex}
            >
              {objectToArray(component.columns)
                .sort(dynamicSort('order', 'asc'))
                .map((column: TsInterface_UnspecifiedObject, columnIndex: number) => (
                  <View
                    style={returnStylePlusDefaults(getProp(column, 'column_body_style', {}), 'column_body_style')}
                    key={columnIndex}
                  >
                    {generatePdfComponent_VariableText(column as TsInterface_PdfTemplateComponent_VariableText, rowData, mode, editHooks)}
                  </View>
                ))}
            </View>
          ),
        )}
      </View>
    )
  } else if (mode === 'edit') {
    // TODO: Edit Mode
    componentJSX = defaultUnderConstructionComponent()
  }
  return componentJSX
}

const generatePdfComponent_StaticImage = (
  component: TsInterface_PdfTemplateComponent_StaticImage,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    if (component.src != null) {
      componentJSX = (
        <Image
          src={component.src}
          style={returnStylePlusDefaults(getProp(component, 'image_style', {}), 'image_style')}
        />
      )
    }
  } else if (mode === 'edit') {
    // TODO: Edit Mode
    if (getProp(component, 'src', null) != null) {
      componentJSX = (
        <Box>
          <Box
            className="tw-cursor-pointer tw-m-2"
            sx={{
              'border': '1px solid rgba(0,0,0,0)',
              '&:hover': {
                border: '1px solid ' + themeVariables.warning_main + ' !important',
              },
            }}
            onClick={(event) => {
              event.stopPropagation()
              if (editHooks != null && editHooks.us_setAnchorPosition != null) {
                editHooks.us_setSelectedComponentKey(getProp(component, 'key', null))
                editHooks.us_setSelectedComponentType('static_image')
                editHooks.us_setAnchorPosition({
                  top: event.clientY,
                  left: event.clientX,
                })
              }
            }}
          >
            <Box
              className="tw-m-auto tw-rounded-md"
              component="img"
              style={returnStylePlusDefaults(getProp(component, 'image_style', {}), 'image_style')}
              src={getProp(component, 'src', null)}
            />
          </Box>
        </Box>
      )
    } else {
      componentJSX = (
        <Box>
          <Box
            className="tw-cursor-pointer tw-m-2"
            sx={{
              'border': '1px solid rgba(0,0,0,0)',
              '&:hover': {
                border: '1px solid ' + themeVariables.warning_main + ' !important',
              },
            }}
            onClick={(event) => {
              event.stopPropagation()
              if (editHooks != null && editHooks.us_setAnchorPosition != null) {
                editHooks.us_setSelectedComponentKey(getProp(component, 'key', null))
                editHooks.us_setSelectedComponentType('static_image')
                editHooks.us_setAnchorPosition({
                  top: event.clientY,
                  left: event.clientX,
                })
              }
            }}
          >
            <Box
              className="tw-m-auto tw-rounded-md"
              component="img"
              style={returnStylePlusDefaults(getProp(component, 'image_style', {}), 'image_style')}
              src={placeholderImageBase64Url}
            />
          </Box>
        </Box>
      )
    }

    // componentJSX = defaultUnderConstructionComponent()
  }
  return componentJSX
}

const generatePdfComponent_VariableImage = (
  component: TsInterface_PdfTemplateComponent_VariableImage,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  // Create PDF Data for Edit Mode Preview
  let src = null
  if (pdfData != null && component.variable_mapping_key != null && pdfData[component.variable_mapping_key] != null) {
    src = pdfData[component.variable_mapping_key]
  } else if (pdfData != null && component.variable_mapping_key != null && pdfData[component.variable_mapping_key + '_url'] != null) {
    src = pdfData[component.variable_mapping_key + '_url']
  }
  if (mode === 'view') {
    if (src != null) {
      componentJSX = (
        <Image
          src={src}
          style={returnStylePlusDefaults(getProp(component, 'image_style', {}), 'image_style')}
        />
      )
    } else {
      componentJSX = (
        <Image
          src={placeholderImageBase64Url}
          style={returnStylePlusDefaults(getProp(component, 'image_style', {}), 'image_style')}
        />
      )
    }
  } else if (mode === 'edit') {
    componentJSX = (
      <Box>
        <Box
          className="tw-cursor-pointer tw-m-2"
          sx={{
            'border': '1px solid rgba(0,0,0,0)',
            '&:hover': {
              border: '1px solid ' + themeVariables.warning_main + ' !important',
            },
          }}
          onClick={(event) => {
            event.stopPropagation()
            if (editHooks != null && editHooks.us_setAnchorPosition != null) {
              editHooks.us_setSelectedComponentKey(getProp(component, 'key', null))
              editHooks.us_setSelectedComponentType('variable_image')
              editHooks.us_setAnchorPosition({
                top: event.clientY,
                left: event.clientX,
              })
            }
          }}
        >
          <Box
            className="tw-m-auto tw-rounded-md"
            component="img"
            style={returnStylePlusDefaults(getProp(component, 'image_style', {}), 'image_style')}
            src={placeholderImageBase64Url}
          />
        </Box>
      </Box>
    )
  }
  return componentJSX
}

const generatePdfComponent_StaticText = (
  component: TsInterface_PdfTemplateComponent_StaticText,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    componentJSX = <Text style={returnStylePlusDefaults(getProp(component, 'text_style', {}), 'text_style')}>{component.text}</Text>
  } else if (mode === 'edit') {
    componentJSX = (
      <Box>
        <Box
          className="tw-cursor-pointer tw-m-2"
          sx={{
            'border': '1px solid rgba(0,0,0,0)',
            '&:hover': {
              border: '1px solid ' + themeVariables.warning_main + ' !important',
            },
          }}
          onClick={(event) => {
            event.stopPropagation()
            if (editHooks != null && editHooks.us_setAnchorPosition != null) {
              editHooks.us_setSelectedComponentKey(getProp(component, 'key', null))
              editHooks.us_setSelectedComponentType('static_text')
              editHooks.us_setAnchorPosition({
                top: event.clientY,
                left: event.clientX,
              })
            }
          }}
        >
          <Typography style={returnStylePlusDefaults(getProp(component, 'text_style', {}), 'text_style')}>{component.text}</Typography>
        </Box>
      </Box>
    )
  }
  return componentJSX
}

const generatePdfComponent_VariableText = (
  component: TsInterface_PdfTemplateComponent_VariableText,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  // Create PDF Data for Edit Mode Preview
  if (pdfData == null || objectToArray(pdfData).length === 0) {
    if (editHooks != null && editHooks.us_pdfFullTemplate != null && editHooks.us_pdfFullTemplate.pdf_variables != null) {
      for (let loopVariableKey in editHooks.us_pdfFullTemplate.pdf_variables) {
        let loopVariable = editHooks.us_pdfFullTemplate.pdf_variables[loopVariableKey]
        if (loopVariable != null && loopVariable.example_value != null) {
          pdfData[loopVariableKey] = loopVariable.example_value
        }
      }
    }
  }
  // Component
  let componentJSX = <></>
  let innerComponentJSX = <></>
  if (mode === 'view') {
    if (pdfData != null && component.variable_mapping_key != null && pdfData[component.variable_mapping_key] != null) {
      componentJSX = <Text style={returnStylePlusDefaults(getProp(component, 'text_style', {}), 'text_style')}>{pdfData[component.variable_mapping_key]}</Text>
    } else if (component.fallback_text != null) {
      let missingStyle = returnStylePlusDefaults(getProp(component, 'fallback_text_style', {}), 'fallback_text_style')
      if (getProp(component, 'fallback_text_style', null) == null) {
        missingStyle = returnStylePlusDefaults(getProp(component, 'text_style', {}), 'text_style')
      }
      componentJSX = <Text style={missingStyle}>{component.fallback_text}</Text>
    }
  } else if (mode === 'edit') {
    if (pdfData != null && component.variable_mapping_key != null && pdfData[component.variable_mapping_key] != null) {
      innerComponentJSX = (
        <Typography style={returnStylePlusDefaults(getProp(component, 'text_style', {}), 'text_style')}>{pdfData[component.variable_mapping_key]}</Typography>
      )
    } else if (component.fallback_text != null) {
      let missingStyle = returnStylePlusDefaults(getProp(component, 'fallback_text_style', {}), 'fallback_text_style')
      if (getProp(component, 'fallback_text_style', null) == null) {
        missingStyle = returnStylePlusDefaults(getProp(component, 'text_style', {}), 'text_style')
      }
      innerComponentJSX = <Typography style={missingStyle}>{component.fallback_text}</Typography>
    } else {
      let missingStyle = returnStylePlusDefaults(getProp(component, 'fallback_text_style', {}), 'fallback_text_style')
      if (getProp(component, 'fallback_text_style', null) == null) {
        missingStyle = returnStylePlusDefaults(getProp(component, 'text_style', {}), 'text_style')
      }
      innerComponentJSX = <Typography style={missingStyle}>({getProp(component, 'variable_mapping_name', null)})</Typography>
    }
    componentJSX = (
      <Box
        className="tw-cursor-pointer tw-m-2"
        sx={{
          'border': '1px solid rgba(0,0,0,0)',
          '&:hover': {
            border: '1px solid ' + themeVariables.warning_main + ' !important',
          },
        }}
        onClick={(event) => {
          event.stopPropagation()
          if (editHooks != null && editHooks.us_setAnchorPosition != null) {
            editHooks.us_setSelectedComponentKey(getProp(component, 'key', null))
            editHooks.us_setSelectedComponentType('variable_text')
            editHooks.us_setAnchorPosition({
              top: event.clientY,
              left: event.clientX,
            })
          }
        }}
      >
        {innerComponentJSX}
      </Box>
    )
  }
  return componentJSX
}

const generatePdfComponent_View = (
  component: TsInterface_PdfTemplateComponent_View,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    componentJSX = (
      <View style={returnStylePlusDefaults(getProp(component, 'view_style', {}), 'view_style')}>
        {objectToArray(component.contents)
          .sort(dynamicSort('order', 'asc'))
          .map((component: TsType_PdfTemplateComponent, index: number) => (
            <React.Fragment key={index}>{generatePdfComponent(component, pdfData, mode, editHooks)}</React.Fragment>
          ))}
      </View>
    )
  } else if (mode === 'edit') {
    // TODO: Edit Mode
    componentJSX = defaultUnderConstructionComponent()
  }
  return componentJSX
}

const generatePdfComponent_PageBreak = (
  component: TsInterface_PdfTemplateComponent_PageBreak,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    componentJSX = <View break></View>
  } else if (mode === 'edit') {
    componentJSX = (
      <Box
        className="tw-cursor-pointer tw-m-2"
        sx={{
          'border': '1px solid rgba(0,0,0,0)',
          '&:hover': {
            border: '1px solid ' + themeVariables.warning_main + ' !important',
          },
        }}
        onClick={(event) => {
          event.stopPropagation()
          if (editHooks != null && editHooks.us_setAnchorPosition != null) {
            editHooks.us_setSelectedComponentKey(getProp(component, 'key', null))
            editHooks.us_setSelectedComponentType('page_break')
            editHooks.us_setAnchorPosition({
              top: event.clientY,
              left: event.clientX,
            })
          }
        }}
      >
        {'======================================= '}
        {rLIB('Page Break')}
        {'======================================= '}
      </Box>
    )
  }
  return componentJSX
}

const generatePdfComponent = (
  component: TsType_PdfTemplateComponent,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  switch (component.component_type) {
    case 'view':
      componentJSX = generatePdfComponent_View(component as TsInterface_PdfTemplateComponent_View, pdfData, mode, editHooks)
      break
    case 'static_table':
      componentJSX = generatePdfComponent_StaticTable(component as TsInterface_PdfTemplateComponent_StaticTable, pdfData, mode, editHooks)
      break
    case 'looped_data_table':
      componentJSX = generatePdfComponent_LoopedDataTable(component as TsInterface_PdfTemplateComponent_LoopedDataTable, pdfData, mode, editHooks)
      break
    case 'static_image':
      componentJSX = generatePdfComponent_StaticImage(component as TsInterface_PdfTemplateComponent_StaticImage, pdfData, mode, editHooks)
      break
    case 'variable_image':
      componentJSX = generatePdfComponent_VariableImage(component as TsInterface_PdfTemplateComponent_VariableImage, pdfData, mode, editHooks)
      break
    case 'static_text':
      componentJSX = generatePdfComponent_StaticText(component as TsInterface_PdfTemplateComponent_StaticText, pdfData, mode, editHooks)
      break
    case 'variable_text':
      componentJSX = generatePdfComponent_VariableText(component as TsInterface_PdfTemplateComponent_VariableText, pdfData, mode, editHooks)
      break
    case 'page_break':
      componentJSX = generatePdfComponent_PageBreak(component as TsInterface_PdfTemplateComponent_PageBreak, pdfData, mode, editHooks)
      break
  }
  return componentJSX
}

///////////////////////////////
// Exports
///////////////////////////////

export const generatePdfFromData = (
  pdfTemplate: TsInterface_PdfTemplate,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit' | 'download',
  uc_setUserInterface_FormDialogDisplay: any,
  onChange: (template: TsInterface_UnspecifiedObject) => Promise<any>,
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let documentJSX = <></>
  if (mode === 'view') {
    documentJSX = (
      <PDFViewer style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
        <Document>
          <Page
            size="LETTER"
            style={getProp(pdfTemplate, 'page_settings', {})}
          >
            {objectToArray(pdfTemplate.page_contents)
              .sort(dynamicSort('order', 'asc'))
              .map((component: TsType_PdfTemplateComponent, index: number) => (
                <React.Fragment key={index}>{generatePdfComponent(component, pdfData, mode, editHooks)}</React.Fragment>
              ))}
          </Page>
        </Document>
      </PDFViewer>
    )
  } else if (mode === 'download') {
    documentJSX = (
      <Document>
        <Page
          size="LETTER"
          style={getProp(pdfTemplate, 'page_settings', {})}
        >
          {objectToArray(pdfTemplate.page_contents)
            .sort(dynamicSort('order', 'asc'))
            .map((component: TsType_PdfTemplateComponent, index: number) => (
              <React.Fragment key={index}>{generatePdfComponent(component, pdfData, 'view', editHooks)}</React.Fragment>
            ))}
        </Page>
      </Document>
    )
  } else if (mode === 'edit') {
    documentJSX = (
      <Box style={{ width: '100%', minHeight: 'calc(100vh - 300px)' }}>
        <Box className="tw-text-left tw-mt-2">
          <Box
            sx={{
              'border': '1px solid rgba(0,0,0,0)',
              '&:hover': {
                border: '1px solid ' + themeVariables.warning_main + ' !important',
              },
            }}
            className="tw-cursor-pointer"
            onClick={(event) => {
              event.stopPropagation()
              editHooks.us_setSelectedComponentKey('page_settings')
              editHooks.us_setSelectedComponentType('page_settings')
              editHooks.us_setAnchorPosition({
                top: event.clientY,
                left: event.clientX,
              })
            }}
          >
            {objectToArray(pdfTemplate.page_contents)
              .sort(dynamicSort('order', 'asc'))
              .map((component: TsType_PdfTemplateComponent, index: number) => (
                <React.Fragment key={index}>{generatePdfComponent(component, pdfData, mode, editHooks)}</React.Fragment>
              ))}
          </Box>
          <Box className="tw-text-center tw-mt-2">
            <Button
              className="tw-mb-2"
              variant="contained"
              color="success"
              startIcon={<Icon icon="circle-plus" />}
              onClick={() => {
                openNewPdfElementDialog(uc_setUserInterface_FormDialogDisplay, onChange, pdfTemplate, [], 'root', editHooks)
              }}
            >
              {rLIB('Add New PDF Element')}
            </Button>
          </Box>
          {rJSX_editComponentMenu(editHooks, onChange)}
        </Box>
      </Box>
    )
  }
  return documentJSX
}

// COPIED TO SERVER - MODIFIED
export const downloadPdfFromData = async (
  rootPDF: TsInterface_UnspecifiedObject,
  pdfTemplate: TsInterface_UnspecifiedObject,
  pdfData: TsInterface_UnspecifiedObject,
) => {
  return new Promise((resolve, reject) => {
    // process = 'base64' // TODO: TEST
    // Get PDF Name
    let pdfName = getProp(rootPDF, 'name', 'Download.pdf')
    // Add .pdf to name if it doesn't exist
    if (!pdfName.includes('.pdf')) {
      pdfName = pdfName + '.pdf'
    }
    // First Page Settings - Apply to all pages
    let firstPageSettings: TsInterface_UnspecifiedObject = {}
    let firstPageContent: TsInterface_UnspecifiedObject = {}
    if (
      objectToArray(pdfTemplate).length > 0 &&
      objectToArray(pdfTemplate)[0] != null &&
      objectToArray(pdfTemplate)[0]['pdf_template'] &&
      objectToArray(pdfTemplate)[0]['pdf_template']['page_settings'] != null
    ) {
      firstPageSettings = objectToArray(pdfTemplate)[0]['pdf_template']['page_settings']
    }
    if (
      objectToArray(pdfTemplate).length > 0 &&
      objectToArray(pdfTemplate)[0] != null &&
      objectToArray(pdfTemplate)[0]['pdf_template'] &&
      objectToArray(pdfTemplate)[0]['pdf_template']['page_contents'] != null
    ) {
      firstPageContent = objectToArray(pdfTemplate)[0]['pdf_template']['page_contents']
    }
    // Generate PDF Document
    let pdfDocument
    if (pdfData == null || objectToArray(pdfData).length === 0) {
      pdfDocument = (
        <Document>
          <Page size="LETTER">
            <Text>{rLIB('No Data', false)}</Text>
            {/* <View break></View> */}
          </Page>
        </Document>
      )
    } else {
      pdfDocument = (
        <Document>
          {objectToArray(pdfData)
            .sort(dynamicSort('timestamp_primary_query', 'asc'))
            .map((dataItem: TsInterface_UnspecifiedObject, index: number) => (
              <Page
                key={index}
                size="LETTER" // TODO: Option in future?
                style={firstPageSettings}
              >
                {objectToArray(firstPageContent)
                  .sort(dynamicSort('order', 'asc'))
                  .map((component: TsType_PdfTemplateComponent, index: number) => (
                    <React.Fragment key={index}>{generatePdfComponent(component, dataItem, 'view', {})}</React.Fragment>
                  ))}
              </Page>
            ))}
        </Document>
      )
    }
    pdf(pdfDocument)
      .toBlob()
      .then((blob) => {
        saveAs(blob, pdfName)
        resolve({ success: true })
      })
      .catch((error) => {
        reject({ success: false })
      })
  })
}

export const returnPdfBlobFromData = async (
  rootPDF: TsInterface_UnspecifiedObject,
  pdfTemplate: TsInterface_UnspecifiedObject,
  pdfData: TsInterface_UnspecifiedObject,
) => {
  return new Promise((resolve, reject) => {
    // process = 'base64' // TODO: TEST
    // Get PDF Name
    let pdfName = getProp(rootPDF, 'name', 'Download.pdf')
    // Add .pdf to name if it doesn't exist
    if (!pdfName.includes('.pdf')) {
      pdfName = pdfName + '.pdf'
    }
    // First Page Settings - Apply to all pages
    let firstPageSettings: TsInterface_UnspecifiedObject = {}
    let firstPageContent: TsInterface_UnspecifiedObject = {}
    if (
      objectToArray(pdfTemplate).length > 0 &&
      objectToArray(pdfTemplate)[0] != null &&
      objectToArray(pdfTemplate)[0]['pdf_template'] &&
      objectToArray(pdfTemplate)[0]['pdf_template']['page_settings'] != null
    ) {
      firstPageSettings = objectToArray(pdfTemplate)[0]['pdf_template']['page_settings']
    }
    if (
      objectToArray(pdfTemplate).length > 0 &&
      objectToArray(pdfTemplate)[0] != null &&
      objectToArray(pdfTemplate)[0]['pdf_template'] &&
      objectToArray(pdfTemplate)[0]['pdf_template']['page_contents'] != null
    ) {
      firstPageContent = objectToArray(pdfTemplate)[0]['pdf_template']['page_contents']
    }
    // Generate PDF Document
    let pdfDocument
    if (pdfData == null || objectToArray(pdfData).length === 0) {
      pdfDocument = (
        <Document>
          <Page size="LETTER">
            <Text>{rLIB('No Data', false)}</Text>
            {/* <View break></View> */}
          </Page>
        </Document>
      )
    } else {
      pdfDocument = (
        <Document>
          {objectToArray(pdfData)
            .sort(dynamicSort('timestamp_primary_query', 'asc'))
            .map((dataItem: TsInterface_UnspecifiedObject, index: number) => (
              <Page
                key={index}
                size="LETTER" // TODO: Option in future?
                style={firstPageSettings}
              >
                {objectToArray(firstPageContent)
                  .sort(dynamicSort('order', 'asc'))
                  .map((component: TsType_PdfTemplateComponent, index: number) => (
                    <React.Fragment key={index}>{generatePdfComponent(component, dataItem, 'view', {})}</React.Fragment>
                  ))}
              </Page>
            ))}
        </Document>
      )
    }
    pdf(pdfDocument)
      .toBlob()
      .then((blob) => {
        resolve({ success: true, blob: blob })
      })
      .catch((error) => {
        reject({ success: false })
      })
  })
}
