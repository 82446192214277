import { Alert, Box, Button, MenuItem, Select, Snackbar, styled, Switch, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { useEffect, useReducer, useState } from 'react'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import {
  TsInterface_CashDisclosureForm,
  TsInterface_DynamicFormInput,
  TsInterface_LeaseDisclosureAPIInputs,
  TsInterface_LeaseDisclosureForm,
} from '../interfaces/i_sales_tools'
import {
  cashDisclosureForm,
  leaseDisclosureForm,
  mapLeaseFormDataKeyToTsInterface_LeaseDisclosureDataKey,
  mapLeaseFormDataToTsInterface_LeaseDisclosureData,
  mapTsInterface_LeaseDisclosureDataKeyToFormDataKey,
} from './abp_helpers'

type Action =
  | { type: 'CHANGE_INPUT'; field: string; value: string; input: TsInterface_DynamicFormInput }
  | { type: 'RESET_FORM' }
  | { type: 'LOADED_PRESETS'; presets: TsInterface_UnspecifiedObject }

type ABPFormType = 'LEASE' | 'PURCHASE'
const formTypes: ABPFormType[] = ['LEASE', 'PURCHASE']

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '800px',
  margin: '0 auto',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}))

const StyledSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledFormControl = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 'bold',
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  'width': '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.light,
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  'width': '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.light,
    },
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const Container = (): JSX.Element => {
  return <StyledContainer>{rJSX_PageContent()}</StyledContainer>
}

const rJSX_PageContent = (): JSX.Element => {
  const [us_selectedPreset, us_setSelectedPreset] = useState('None')
  const [us_savedPresetLabels, us_setSavedPresetLabels] = useState<string[]>(['None'])
  const [us_savedPresets, us_setSavedPresets] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formType, us_setFormType] = useState<ABPFormType>('LEASE')
  const [us_showAllFields, us_setShowAllFields] = useState(false)
  const [us_savePreset, us_setSavePreset] = useState(false)
  // const [us_formPresets, us_setFormPresets] = useState<TsInterface_UnspecifiedObject>({})
  const [us_presetFields, us_setPresetFields] = useState<string[]>([])
  const [us_snackbar, us_setSnackbar] = useState({
    open: false,
    message: <></>,
    severity: 'success' as 'success' | 'error',
  })

  const leaseFormReducer = (state: TsInterface_LeaseDisclosureForm, action: Action): TsInterface_LeaseDisclosureForm => {
    switch (action.type) {
      case 'CHANGE_INPUT':
        return {
          ...state,
          [action.field]: {
            ...action.input,
            value: action.value,
          },
        }
      case 'LOADED_PRESETS':
        if (action.presets) {
          const updatedState = { ...state }
          for (const [key, value] of Object.entries(action.presets)) {
            if (updatedState[mapTsInterface_LeaseDisclosureDataKeyToFormDataKey(key as string) as keyof TsInterface_LeaseDisclosureForm]) {
              updatedState[mapTsInterface_LeaseDisclosureDataKeyToFormDataKey(key as string) as keyof TsInterface_LeaseDisclosureForm] = {
                ...updatedState[mapTsInterface_LeaseDisclosureDataKeyToFormDataKey(key as string) as keyof TsInterface_LeaseDisclosureForm],
                value: value,
              }
            }
          }
          return updatedState
        }
        return state
      case 'RESET_FORM':
        return { ...leaseDisclosureForm }
      default:
        return state
    }
  }

  const cashFormReducer = (state: TsInterface_CashDisclosureForm, action: Action): TsInterface_CashDisclosureForm => {
    switch (action.type) {
      case 'CHANGE_INPUT':
        return {
          ...state,
          [action.field]: {
            ...state[action.field as keyof TsInterface_CashDisclosureForm],
            value: action.value,
          },
        }
      case 'LOADED_PRESETS':
        if (action.presets) {
          const updatedState = { ...state }
          for (const [key, value] of Object.entries(action.presets)) {
            if (updatedState[key as string as keyof TsInterface_CashDisclosureForm]) {
              updatedState[key as string as keyof TsInterface_CashDisclosureForm] = {
                ...updatedState[key as string as keyof TsInterface_CashDisclosureForm],
                value: value,
              }
            }
          }
          return updatedState
        }
        return state
      case 'RESET_FORM':
        return { ...cashDisclosureForm }
      default:
        return state
    }
  }

  const [ur_leaseForm, ur_leaseFormDispatch] = useReducer(leaseFormReducer, { ...leaseDisclosureForm })
  const [ur_cashForm, ur_cashFormDispatch] = useReducer(cashFormReducer, { ...cashDisclosureForm })

  // load in the presets from the db
  useEffect(() => {
    // inline function to get the presets from the db
    const getABPDisclosureFormPresets = async () => {
      return new Promise((resolve, reject) => {
        cloudFunctionUnauthenticatedRequests({
          function: 'getABPDisclosureFormPresets',
        })
          .then((res: any) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    // get the presets from the db
    getABPDisclosureFormPresets()
      .then((res: any) => {
        // set the saved presets
        us_setSavedPresets(res)
        us_setSavedPresetLabels(['None', ...Object.keys(res)])
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const rJSX_DynamicFormTextInput = (input: TsInterface_DynamicFormInput, key: string): JSX.Element => {
    return (
      <StyledFormControl>
        <StyledLabel variant="subtitle1">{input.label}</StyledLabel>
        <StyledTextField
          name={key}
          value={input.value}
          helperText={input.instructions}
          onChange={(e) => {
            if (us_formType === 'LEASE') {
              ur_leaseFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value, input: input })
            } else {
              ur_cashFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value, input: input })
            }
          }}
        />
      </StyledFormControl>
    )
  }

  const rJSX_DynamicFormSelectInput = (input: TsInterface_DynamicFormInput, key: string): JSX.Element => {
    return (
      <StyledFormControl>
        <StyledLabel variant="subtitle1">{input.label}</StyledLabel>
        <StyledSelect
          labelId={`${key}-label`}
          id={key}
          name={key}
          value={input.value}
          onChange={(e) => {
            if (us_formType === 'LEASE') {
              ur_leaseFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value as string, input: input })
            } else {
              ur_cashFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value as string, input: input })
            }
          }}
        >
          {input.options?.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
        {input.instructions && (
          <Typography
            variant="caption"
            className="tw-text-gray_400 tw-ml-3"
          >
            {input.instructions}
          </Typography>
        )}
      </StyledFormControl>
    )
  }

  const determineIfConditionalDependencyIsMet = (conditional_dependencies: object): boolean => {
    return Object.entries(conditional_dependencies).every(([key, values]) => {
      if (us_formType === 'LEASE') {
        if (values.includes('any') && ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value.length > 0) {
          return true
        }
        return (
          values.includes(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value) ||
          values.includes(String(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value))
        )
      } else {
        if (values.includes('any') && ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value.length > 0) {
          return true
        }
        return (
          values.includes(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value) ||
          values.includes(String(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value))
        )
      }
    })
  }

  const rJSX_CreateDynamicForm = (): JSX.Element => {
    let formData: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm
    if (us_formType === 'LEASE') {
      formData = ur_leaseForm
    } else {
      formData = ur_cashForm
    }
    return (
      <Grid2
        container
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'flex-start' }}
      >
        {Object.keys(formData).map((key: any) => {
          // Handle preset logic
          if (
            us_formType === 'LEASE' &&
            us_selectedPreset !== 'None' &&
            us_savedPresets[us_selectedPreset] &&
            us_presetFields.includes(mapLeaseFormDataKeyToTsInterface_LeaseDisclosureDataKey(key)) &&
            key != 'project_lessor_legal_name'
          ) {
            return null
          } else if (
            us_formType === 'PURCHASE' &&
            us_selectedPreset !== 'None' &&
            us_savedPresets[us_selectedPreset] &&
            us_presetFields.includes(key) &&
            key != 'seller_legal_name'
          ) {
            return null
          }

          let formField: TsInterface_DynamicFormInput
          if (us_formType === 'LEASE') {
            formField = ur_leaseForm[key as keyof typeof ur_leaseForm]
          } else {
            formField = ur_cashForm[key as keyof typeof ur_cashForm]
          }

          const isTextOrNumber = formField.input_type === 'text' || formField.input_type === 'number'

          // Handle required fields
          if (isTextOrNumber && formField.is_required) {
            return <Box key={key}>{rJSX_DynamicFormTextInput(formField, key)}</Box>
          }

          if (formField.input_type === 'dropdown' && formField.is_required) {
            return <Box key={key}>{rJSX_DynamicFormSelectInput(formField, key)}</Box>
          }

          // Handle conditional fields
          if ((!formField.is_required && Object.keys(formField.conditional_dependencies).length > 0) || (us_showAllFields && key !== 'form_type')) {
            const conditional_dependencies_met = determineIfConditionalDependencyIsMet(formField.conditional_dependencies)

            if (conditional_dependencies_met || us_showAllFields) {
              if (isTextOrNumber) {
                return <Box key={key}>{rJSX_DynamicFormTextInput(formField, key)}</Box>
              }
              if (formField.input_type === 'dropdown') {
                return <Box key={key}>{rJSX_DynamicFormSelectInput(formField, key)}</Box>
              }
            }
          }

          return null
        })}
      </Grid2>
    )
  }

  const rJSX_SelectWrapper = (key: string, selectedState: any, stateOptions: any[], stateSetter: React.Dispatch<React.SetStateAction<any>>): JSX.Element => {
    return (
      <StyledSection>
        <StyledLabel variant="h6">{key.charAt(0).toUpperCase() + key.slice(1)}</StyledLabel>
        <StyledSelect
          labelId={`${key}-label`}
          id={key}
          name={key}
          value={selectedState}
          disabled={us_selectedPreset !== 'None' && key !== 'Preset'}
          onChange={(e) => {
            const val = e.target.value as string

            switch (key) {
              case 'Preset':
                stateSetter(val)
                if (val !== 'None') {
                  us_setSavePreset(false)
                  us_setPresetFields(Object.keys(us_savedPresets[val]))
                  if (us_formType === 'LEASE') {
                    ur_leaseFormDispatch({ type: 'LOADED_PRESETS', presets: us_savedPresets[val] })
                  } else {
                    ur_cashFormDispatch({ type: 'LOADED_PRESETS', presets: us_savedPresets[val] })
                  }
                } else {
                  if (us_formType === 'LEASE') {
                    ur_leaseFormDispatch({ type: 'RESET_FORM' })
                  } else {
                    ur_cashFormDispatch({ type: 'RESET_FORM' })
                  }
                }
                break
              case 'Form Type':
                stateSetter(val.toUpperCase())
                break
            }
          }}
        >
          {stateOptions.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledSection>
    )
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    us_setSnackbar({ ...us_snackbar, open: false })
  }

  const handleFormSubmit = () => {
    let formData: TsInterface_LeaseDisclosureAPIInputs | TsInterface_CashDisclosureForm // unfortunately the lease form requires mapping to different keys for the api
    if (us_formType === 'LEASE') {
      formData = mapLeaseFormDataToTsInterface_LeaseDisclosureData(ur_leaseForm)
    } else {
      formData = Object.entries(ur_cashForm).reduce((acc: any, [key, value]) => {
        acc[key] = value.value
        return acc
      }, {})
    }

    // submit a form to the backend
    cloudFunctionUnauthenticatedRequests({
      function: 'createDisclosureForm',
      form_data: formData,
      save_preset: us_savePreset,
      form_type: formData['form_type'],
    })
      .then((res: any) => {
        us_setSnackbar({
          open: true,
          message: (
            <Box>
              Form submitted successfully! {us_savePreset ? 'Preset saved.' : ''}
              <br />
              <br />
              {Object.entries(res.data).map(([key, value]) => (
                <Box key={key}>
                  <Typography variant="body1">
                    {key}: {value as string}
                  </Typography>
                </Box>
              ))}
            </Box>
          ),
          severity: 'success',
        })
      })
      .catch((err) => {
        us_setSnackbar({
          open: true,
          message: (
            <Box>
              Error submitting form. Please try again. Remember to check below each field for that field's instructions. Also ensure that your form name is
              unique and not one that you have previously submitted.
              <br />
              Details: {err.response.data}
            </Box>
          ),
          severity: 'error',
        })
      })
  }

  const presetLabels = us_savedPresetLabels.filter((label) => label.toLowerCase().includes('- ' + us_formType.toLowerCase()) || label == 'None')

  return (
    <Box>
      <StyledTitle variant="h4">ABP Disclosure Form</StyledTitle>
      <Typography variant="overline">Show all form fields</Typography>
      <Switch
        checked={us_showAllFields}
        onChange={() => us_setShowAllFields(!us_showAllFields)}
        color="primary"
      />
      {rJSX_SelectWrapper('Form Type', us_formType, formTypes, us_setFormType)}
      {us_savedPresetLabels && rJSX_SelectWrapper('Preset', us_selectedPreset, presetLabels, us_setSelectedPreset)}
      <StyledSection>{rJSX_CreateDynamicForm()}</StyledSection>
      <StyledSection>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <StyledLabel variant="h6">Save Preset</StyledLabel>
          <Switch
            checked={us_savePreset}
            onChange={() => us_setSavePreset(!us_savePreset)}
            color="primary"
            className="tw-mb-2"
            disabled={us_selectedPreset !== 'None'}
          />
        </Box>
        <Typography
          variant="caption"
          className="tw-text-gray_400"
        >
          Presets allow you to save the majority of your form data and load it later.
        </Typography>
      </StyledSection>
      <Box className="tw-flex tw-justify-center tw-mt-4">
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleFormSubmit}
        >
          Submit
        </StyledButton>
      </Box>
      <Snackbar
        open={us_snackbar.open}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={us_snackbar.severity}
          sx={{ width: '100%' }}
        >
          {us_snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
