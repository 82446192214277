///////////////////////////////
// Description
///////////////////////////////

/*
       DESCRIPTION / USAGE:
           containers are pages / views used in the app and are made up of components and can interact with services and models


       TODO:


   */

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material/'
import { globalTaskTypes } from 'app/models/tasks/global_tasks'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_OutboundCommunication_SMSMessageTemplates,
  DatabaseRef_OutboundCommunication_SMSMessageTemplate_Document,
} from 'rfbp_aux/services/database_endpoints/operations/outbound_communication'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs/tabs_url'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseDeleteDocument, DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminOutboundCommunicationIndexPage']['key']

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  console.log('Container rendered')

  // Dialog open/close state
  const [open, setOpen] = useState(false)

  const [us_SMSTemplates, us_setSMSTemplates] = useState<TsInterface_UnspecifiedObject>({})

  // Access the clientKey from context
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  const [selectedTaskType, setSelectedTaskType] = useState('')

  const [isEditMode, setIsEditMode] = useState(false)
  const [currentTemplate, setCurrentTemplate] = useState<{
    key: string
    name: string
    content: string
    task_type: string
  } | null>(null)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)

  const placeholderRegex = /\{(\w+)\}/g

  const [displayText, setDisplayText] = useState<React.ReactNode[]>([])

  const [taskType, setTaskType] = useState('')
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const [smsContent, setSmsContent] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // Define displayText to store the JSX elements for placeholders

  //Get SMS Templates
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSMSTemplates(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_OutboundCommunication_SMSMessageTemplates(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  const handleTemplateClick = (template: any) => {
    setIsEditMode(true)
    setCurrentTemplate(template)
    setTaskType(template.task_type)
    setSmsContent(template.content)
    setOpen(true)
  }

  // Function to confirm deletion
  const confirmDeleteTemplate = () => {
    setOpenDeleteConfirm(true)
  }

  // Open Dialog
  const handleOpenDialog = () => {
    console.log('Dialog opened')
    setOpen(true)
  }

  // Close Dialog
  const handleCloseDialog = () => {
    console.log('Dialog closed')
    setOpen(false)
  }

  // Placeholder options
  const placeholderOptions = ['{customerName}', '{taskName}', '{scheduledDate}', '{address}', '{foremanName}', '{arrivalTimeRange}']

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Message Library', false) as string
  }, [])

  const handleEditTemplate = (template: any) => {
    setIsEditMode(true)
    setCurrentTemplate(template)
    setTaskType(template.task_type) // Pre-fill task type
    setSmsContent(template.content) // Pre-fill SMS content
    setOpen(true)
  }

  // Delete Template Function
  const handleDeleteTemplate = () => {
    const clientKey = uc_RootData_ClientKey
    console.log('Attempting to delete template...')
    console.log('clientKey:', clientKey)
    console.log('currentTemplate:', currentTemplate)

    // Ensure clientKey and currentTemplate are defined
    if (!clientKey) {
      console.error('Client key is undefined')
      alert('Error: Unable to delete template. Client key is missing.')
      setOpenDeleteConfirm(false)
      return
    }
    if (!currentTemplate) {
      console.error('currentTemplate is undefined')
      alert('Error: Unable to delete template. Template data is missing.')
      setOpenDeleteConfirm(false)
      return
    }

    // Log the template key to be used
    console.log('currentTemplate.key:', currentTemplate.key)

    // Attempting to create document reference
    try {
      const documentRef = DatabaseRef_OutboundCommunication_SMSMessageTemplate_Document(clientKey, currentTemplate.key)
      console.log('documentRef:', documentRef)

      DatabaseDeleteDocument(documentRef)
        .then(() => {
          console.log('Template deleted successfully')
          setOpenDeleteConfirm(false)
          setOpen(false) // Close the edit dialog
          setIsEditMode(false)
          setCurrentTemplate(null)
          setTaskType('')
          setSmsContent('')
        })
        .catch((error) => {
          console.error('Error deleting template:', error)
          alert('An error occurred while deleting the template. Please try again.')
        })
    } catch (error) {
      console.error('Error creating document reference:', error)
    }
  }

  // Save template function with task type
  const handleSaveTemplate = (templateType: string, templateContent: string) => {
    const templateKey = isEditMode && currentTemplate ? currentTemplate.key : `${templateType}_sms_reminder_template`.toLowerCase()

    const templateName = `${globalTaskTypes[templateType].name_string} SMS Reminder Template`
    const documentRef = DatabaseRef_OutboundCommunication_SMSMessageTemplate_Document(uc_RootData_ClientKey as any, templateKey)

    // Include the key field in the updateObject
    const updateObject = {
      key: templateKey, // Add key field
      name: templateName,
      content: templateContent,
      timestamp: Date.now(),
      task_type: templateType,
    }

    DatabaseSetMergeDocument(documentRef, updateObject)
      .then(() => {
        setOpen(false) // Close the dialog
        setTaskType('')
        setSmsContent('')
        setIsEditMode(false) // Reset edit mode
        setCurrentTemplate(null) // Clear current template
      })
      .catch((error) => console.error('Error saving template:', error))
  }

  const renderSMSTaskReminderDialog = () => (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
        setIsEditMode(false)
        setCurrentTemplate(null)
        setTaskType('')
        setSmsContent('')
      }}
      fullWidth
      maxWidth="xl" // Use 'xl' to allow for a larger dialog
      TransitionComponent={Fade}
      sx={{
        '& .MuiDialog-paper': {
          width: '90%', // Makes the dialog 90% of the viewport width
          maxWidth: '1400px',
          // Cap to prevent excessive width on larger screens
        },
      }}
    >
      <DialogTitle>{isEditMode ? 'Edit SMS Task Reminder' : 'Create SMS Task Reminder'}</DialogTitle>
      <DialogContent>{renderCustomSMSTaskReminderForm(handleSaveTemplate)}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSaveTemplate(taskType, smsContent)}
        >
          Save Template
        </Button>
        {isEditMode && (
          <Button
            onClick={confirmDeleteTemplate}
            color="error"
          >
            Delete
          </Button>
        )}
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )

  // Delete confirmation dialog remains the same
  const renderDeleteConfirmDialog = () => (
    <Dialog
      open={openDeleteConfirm}
      onClose={() => setOpenDeleteConfirm(false)}
    >
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this template?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDeleteConfirm(false)}>Cancel</Button>
        <Button
          onClick={handleDeleteTemplate}
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )

  // Outside of the render function
  // Update displayText whenever smsContent updates
  useEffect(() => {
    setDisplayText(convertTextToElements(smsContent))
  }, [smsContent])

  // Converts plain text to JSX elements with placeholders styled as separate components
  // Updated convertTextToElements function to validate placeholders
  const convertTextToElements = (text: string): JSX.Element[] => {
    return text.split(/(\{.*?\})/).map((part, index) => {
      // Check if part matches one of the exact placeholder options
      if (placeholderOptions.includes(part)) {
        return (
          <Box
            key={index}
            component="span"
            sx={{
              backgroundColor: '#1976d2',
              color: 'white',
              padding: '2px 4px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            contentEditable={false}
          >
            {part}
          </Box>
        )
      }
      // Render as regular text if it's not a valid placeholder
      return <span key={index}>{part}</span>
    })
  }

  const renderCustomSMSTaskReminderForm = (onSave: (type: string, content: string) => void) => {
    const handleInsertPlaceholder = (placeholder: string) => {
      const cursorPosition = textareaRef.current?.selectionStart || 0
      const newContent = [smsContent.slice(0, cursorPosition), placeholder, smsContent.slice(cursorPosition)].join('')
      setSmsContent(newContent)
      setAnchorEl(null)
    }

    // Define desired initial height
    const desiredHeight = '400px' // Adjust this value as desired

    return (
      <Box sx={{ backgroundColor: 'black', color: 'white', padding: '16px', borderRadius: '8px' }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: 'white' }}
        >
          SMS Task Reminder Content
        </Typography>

        {isEditMode ? (
          <>
            <Typography
              variant="body1"
              sx={{ color: 'white', marginBottom: '16px' }}
            >
              <strong>Task Type:</strong> {globalTaskTypes[taskType]?.name_string || 'Unknown'}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: 'white', marginBottom: '16px' }}
            >
              <strong>Template Name:</strong> {currentTemplate?.name || 'Untitled Template'}
            </Typography>
          </>
        ) : (
          <>
            <TextField
              id="task_type"
              select
              variant="outlined"
              fullWidth
              value={taskType}
              onChange={(e) => setTaskType(e.target.value)}
              sx={{ marginBottom: '16px' }}
              InputLabelProps={{
                style: { color: 'white' },
                shrink: true,
              }}
              SelectProps={{
                displayEmpty: true,
                style: { color: 'white', backgroundColor: '#333' },
              }}
            >
              <MenuItem
                disabled
                value=""
              >
                Task Type
              </MenuItem>
              {Object.entries(globalTaskTypes).map(([key, type]) => {
                const isDisabled = Object.values(us_SMSTemplates).some((template) => template.task_type === key)
                return (
                  <MenuItem
                    key={key}
                    value={key}
                    disabled={isDisabled}
                  >
                    {type.name_string}
                  </MenuItem>
                )
              })}
            </TextField>

            <TextField
              id="template_name"
              variant="outlined"
              fullWidth
              value={taskType ? `${globalTaskTypes[taskType].name_string} SMS Reminder Template` : 'Name'}
              disabled
              sx={{ marginBottom: '16px', color: 'white' }}
              InputProps={{
                style: { color: 'white', backgroundColor: '#333' },
                placeholder: 'Name',
              }}
            />
          </>
        )}
        <Button
          variant="outlined"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{ color: 'white', borderColor: 'white', marginBottom: '8px' }}
        >
          Insert Placeholder
        </Button>

        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'stretch', mt: 2 }}>
          {/* Editable Textarea (left side) */}
          <Box
            sx={{
              flex: 1,
              minWidth: '50%',
              maxWidth: '50%',
              padding: '16px',
              backgroundColor: '#1e1e1e',
              borderRadius: '4px',
              border: '1px solid #444',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {placeholderOptions.map((placeholder) => (
                <MenuItem
                  key={placeholder}
                  onClick={() => handleInsertPlaceholder(placeholder)}
                >
                  {placeholder}
                </MenuItem>
              ))}
            </Menu>

            <textarea
              ref={textareaRef}
              value={smsContent}
              onChange={(e) => setSmsContent(e.target.value)}
              style={{
                width: '100%',
                flexGrow: 1,
                minHeight: desiredHeight, // Setting the minimum height
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: '#1e1e1e',
                color: 'white',
                border: 'none',
                fontSize: '16px',
                lineHeight: '1.5',
                whiteSpace: 'pre-wrap',
                fontFamily: 'inherit',
                overflowY: 'auto',
                resize: 'none',
              }}
            />
          </Box>

          {/* Preview Box (right side) */}
          <Box
            sx={{
              flex: 1,
              minWidth: '50%',
              maxWidth: '50%',
              padding: '16px',
              backgroundColor: '#1e1e1e',
              borderRadius: '4px',
              border: '1px solid #444',
              fontSize: '16px',
              lineHeight: '1.5',
              color: 'white',
              fontFamily: 'inherit',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              minHeight: desiredHeight, // Matching the height for the preview box
              overflowY: 'auto',
            }}
          >
            {convertTextToElements(smsContent)}
          </Box>
        </Box>
      </Box>
    )
  }

  // Placeholder menu function
  const renderPlaceholderMenu = (onInsert: (placeholder: string) => void) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      console.log('Opening placeholder menu')
      setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
      console.log('Closing placeholder menu')
      setAnchorEl(null)
    }

    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpenMenu}
          sx={{ marginBottom: '8px' }}
        >
          Insert Placeholder
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {placeholderOptions.map((placeholder) => (
            <MenuItem
              key={placeholder}
              onClick={() => {
                onInsert(placeholder)
                handleCloseMenu()
              }}
            >
              {placeholder}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }

  // Functions to render page content
  const renderCreateSMSTaskReminderButton = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={handleOpenDialog}
      sx={{ marginBottom: '16px' }}
    >
      Create New SMS Task Reminder
    </Button>
  )

  // Render SMS Templates with improved display and line breaks
  // Render SMS Templates with Hover Effect for Clickable Cards
  const renderSMSTemplates = () => (
    <List
      sx={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',
        justifyContent: 'flex-start',
      }}
    >
      {objectToArray(us_SMSTemplates).map((template, index) => (
        <Card
          key={index}
          elevation={2}
          onClick={() => handleTemplateClick(template)} // Clickable card
          sx={{
            'padding': '16px',
            'borderRadius': '8px',
            'border': '1px solid #ccc',
            'width': '48%',
            'minWidth': '280px',
            'maxWidth': '600px',
            'backgroundColor': '#333',
            'cursor': 'pointer',
            'transition': 'background-color 0.3s ease',
            '&:hover': {
              backgroundColor: '#444', // Darker background on hover
              opacity: 0.9, // Slight opacity change on hover
            },
          }}
        >
          <Box>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', color: 'white' }}
                  >
                    {template.name}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    sx={{ color: 'white', whiteSpace: 'pre-line' }}
                    dangerouslySetInnerHTML={{
                      __html: template.content.replace(/\n/g, '<br/>'),
                    }}
                  />
                }
              />
            </ListItem>
          </Box>
        </Card>
      ))}
    </List>
  )

  // Function to generate the email template object
  const generateEmailTemplateObject = (userName: string, isPreview: boolean): TsInterface_EmailTemplateObject => {
    // Extract first name from full userName

    // Create a greeting message
    const greetingMessage = 'Hi {User First Name}'

    // Define the common structure of the email
    const commonTemplateObject = {
      BODY_DIV: {
        element_type: 'div',
        style: {
          'box-sizing': 'border-box',
          'width': '100%',
          'background': isPreview ? 'none' : '#eeeeee', // Background depending on preview or final
          'padding': isPreview ? '0' : '16px', // Padding depending on preview or final
        },
        contents: {
          CARD_DIV: {
            element_type: 'div',
            style: {
              'max-width': '600px',
              'background': '#ffffff',
              'border-radius': '8px',
              'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
              'margin': '0 auto',
              'overflow': 'hidden',
            },
            contents: {
              HEADER_DIV: {
                element_type: 'div',
                style: {
                  'box-sizing': 'border-box',
                  'background': 'rgb(25, 30, 33)',
                  'font-size': '20px',
                  'width': '100%',
                  'min-height': '70px',
                  'padding': '8px',
                  'text-align': 'center',
                },
                contents: {
                  LOGO_IMG: {
                    element_type: 'img',
                    src_type: 'static',
                    src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                    alt: 'logo.png',
                    width: '80px',
                    height: 'auto',
                  },
                },
              },
              CONTENT_DIV: {
                element_type: 'div',
                style: {
                  padding: '24px',
                },
                contents: {
                  GREETING: {
                    element_type: 'p',
                    text_type: 'static',
                    text: greetingMessage,
                    style: {
                      'font-size': '16px',
                      'font-weight': 'bold',
                      'margin-bottom': '8px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  MESSAGE1: {
                    element_type: 'p',
                    text_type: 'static',
                    text: "Congratulations! We've created your login to ETW Energy's EOS desktop.",
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '12px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  LOGIN_URL: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'The URL is: etw.app/login',
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '8px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                    contents: {
                      LINK: {
                        element_type: 'a',
                        text: 'https://etw.app/login',
                        text_type: 'static',
                        href_type: 'static',
                        href_url: 'https://etw.app/login',
                        style: {
                          'font-weight': 'bold',
                          'font-size': '14px',
                          'color': 'blue',
                          'text-decoration': 'underline',
                          'cursor': 'pointer',
                        },
                      },
                    },
                  },
                  FIRST_TIME_LOGIN: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'When you log in for the first time, select "Log in with email" and then reset your password.',
                    style: {
                      'font-size': '14px',
                      'margin-top': '8px',
                      'margin-bottom': '16px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  SUPPORT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'If you have any questions or trouble logging in, please email us at helpdesk@etwenergy.com',
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '12px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  SIGN_OFF: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Thanks and welcome!',
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  TEAM: {
                    element_type: 'p',
                    text_type: 'static',
                    text: '- The ETW Team',
                    style: {
                      'font-size': '14px',
                      'font-weight': 'bold',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                },
              },
            },
          },
        },
      },
    } as TsInterface_EmailTemplateObject

    // Return the template object based on the condition
    return commonTemplateObject
  }

  const StaticWelcomeEmailPreview = () => {
    const userName = '{CustomerFirstName}'
    const emailTemplate = generateEmailTemplateObject(userName, false)
    const emailHtmlContent = generateHtmlForEmailFromTemplateObject(emailTemplate, {})

    return (
      <Box sx={{ padding: '8px', maxWidth: '600px', marginBottom: '16px' }}>
        <Box
          className="ql-scroll-html-render"
          dangerouslySetInnerHTML={{ __html: emailHtmlContent }}
          sx={{
            marginTop: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            backgroundColor: '#f9f9f9',
          }}
        />
      </Box>
    )
  }

  // SMS Tab Content
  const rJSX_SMSTabContent = (): JSX.Element => (
    <Box sx={{ padding: '16px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Typography variant="h6">Daily SMS Task Reminder</Typography>
        <Tooltip
          title={
            <Card sx={{ padding: '12px', maxWidth: '250px', margin: '8px 4px' }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', color: '#fff' }}
              >
                Daily SMS Task Reminder
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginTop: '4px', color: '#ddd', lineHeight: '1.4' }}
              >
                This SMS reminder is automatically sent to the customer the day before their scheduled task, helping them prepare for your team’s arrival.
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginTop: '8px', color: '#ddd', lineHeight: '1.4' }}
              >
                Ensure that all placeholders (like{' '}
                <Box
                  component="span"
                  sx={{ color: '#1976d2', fontWeight: 'bold' }}
                >
                  {'{customerName}'}
                </Box>
                ) appear with a blue background in the preview to confirm they’ll be correctly replaced in the message.
              </Typography>
            </Card>
          }
          placement="right"
        >
          <IconButton sx={{ marginLeft: '8px' }}>
            <Icon
              icon="circle-info"
              sx={{ color: 'white' }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      {renderCreateSMSTaskReminderButton()}
      {renderSMSTemplates()}
      {renderSMSTaskReminderDialog()}
      {renderDeleteConfirmDialog()}
    </Box>
  )

  // Email Tab Content
  const rJSX_EmailTabContent = (): JSX.Element => (
    <Box sx={{ padding: '16px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <Typography variant="h6">Welcome Email</Typography>

        {/* Tooltip with information on sending the welcome email */}
        <Tooltip
          title={
            <Card sx={{ padding: '12px', maxWidth: '250px', margin: '8px 4px' }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', color: '#fff' }}
              >
                Sending the Welcome Email
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginTop: '4px', color: '#ddd', lineHeight: '1.4' }}
              >
                You can send this welcome email by going to the Users tab, clicking the three dots next to the user you want to send it to, and selecting "Send
                Welcome Email."
              </Typography>
            </Card>
          }
          placement="right"
        >
          <IconButton sx={{ marginLeft: '8px' }}>
            <Icon
              icon="circle-info"
              sx={{ color: 'white' }}
            />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Static Welcome Email Preview */}
      {StaticWelcomeEmailPreview()}
    </Box>
  )

  const rJSX_MessageTemplatesTabs = (): JSX.Element => (
    <TabsUrl
      tabs={[
        {
          tabUrlKey: 'SMS_Templates',
          tabHeader: rLIB('SMS Templates'),
          tabContent: rJSX_SMSTabContent(),
          tabButtons: [],
        },
        {
          tabUrlKey: 'Email_Templates',
          tabHeader: rLIB('Email Templates'),
          tabContent: rJSX_EmailTabContent(),
          tabButtons: [],
        },
      ]}
      tabsSettings={{
        baseUrl: ApplicationPages.AdminOutboundCommunicationIndexPage.url(),
        tabQueryParam: 'tab',
        overridePageTitle: true,
        basePageTitle: 'Message Templates',
      }}
    ></TabsUrl>
  )

  // JSX Generation
  const rJSX_Page = (): JSX.Element => (
    <Box>
      <AuthenticatedContainer
        pageHeader={rLIB('Message Library')}
        pageKey={pageKey}
        content={rJSX_MessageTemplatesTabs()}
      />
    </Box>
  )

  // Render
  return <>{rJSX_Page()}</>
}

Container.displayName = 'Container'
