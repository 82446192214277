///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Chip, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import { delayStatusOptions } from 'app/data/general_form_options'
import { deleteProjectTask, determineTaskColorStatus, rJSX_TaskBreakdownBarFullColors, undeleteProjectTask } from 'app/models/projects/project_services'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages, schedulingTeamTypes } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_InternalUsers_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_ActiveVehicles_Query } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import { DatabaseRef_Reminder_Document } from 'rfbp_aux/services/database_endpoints/operations/reminders'
import { DatabaseRef_SchedulingActiveTeams_Query } from 'rfbp_aux/services/database_endpoints/operations/schedules'
import {
  DatabaseRef_TaskStatusLogs_Collection,
  DatabaseRef_TaskStatusLog_Document,
  DatabaseRef_Task_Document,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableManageAction,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TableCellLink } from 'rfbp_core/components/table/cells/table_cell_link'
import { rLIB } from 'rfbp_core/localization/library'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import {
  cloneObjectWithoutReference,
  dynamicSort,
  generateRandomString,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
  returnTimestampFromUnknownDateFormat,
  underscoresToSpaces,
} from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import {
  formInputs_RedoTask,
  formInputs_RetryTask,
  formOnChange_RedoTask,
  formOnChange_RetryTask,
  formSettings_RedoTask,
  formSettings_RetryTask,
  formSubmission_RedoTask,
  formSubmission_RetryTask,
  onSiteTaskRedoTasks,
} from './task_forms'
import { editTask, editTaskTeam, openTaskFormEditDialog } from './task_form_services'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Table
const tableSettings_StatusNotes: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: false,
  sort_direction: 'desc',
  sort_property_default: 'timestamp_recorded',
}

const tableColumns_StatusNotes: TsInterface_TableColumns = {
  timestamp_recorded: TableCellTimestamp('timestamp_recorded', rLIB('Log Date'), 'timestamp_recorded', 'D MMM YYYY - h:mm a', false),
  associated_user_name: TableCellBasic('associated_user_name', rLIB('Note Added By'), 'associated_user_name'),
  status_progression: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Task Status')
      },
      header_sort_by: 'status_progression',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box></Box>
        let iconJSX = <></>
        if (
          rowData != null &&
          rowData.status_progression != null &&
          delayStatusOptions != null &&
          delayStatusOptions[rowData.status_progression as string] != null &&
          delayStatusOptions[rowData.status_progression as string]['icon'] != null
        ) {
          iconJSX = delayStatusOptions[rowData.status_progression as string]['icon']
        }
        let statusSelectionJSX = <></>
        if (
          rowData != null &&
          rowData.status_progression != null &&
          delayStatusOptions != null &&
          delayStatusOptions[rowData.status_progression as string] != null &&
          delayStatusOptions[rowData.status_progression as string]['value'] != null
        ) {
          statusSelectionJSX = <>{delayStatusOptions[rowData.status_progression as string]['value']} </>
        } else {
          statusSelectionJSX = <>{rowData.status_progression}</>
        }
        cellJSX = (
          <Box>
            {iconJSX} {statusSelectionJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  status_progression_delay_reason: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Delay Reason')
      },
      header_sort_by: 'status_progression_delay_reason',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box></Box>
        if (rowData != null && rowData['status_progression'] === 'roadblock') {
          cellJSX = <Box>{rowData.status_progression_delay_reason}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  // status_progression_delay_reason: TableCellBasic( "status_progression_delay_reason", rLIB("Delay Reason"), "status_progression_delay_reason" ),
  status_progression_notes: TableCellBasic('status_progression_notes', rLIB('Notes'), 'status_progression_notes'),
}

const tableSettings_TaskRedos: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'asc',
  sort_property_default: 'timestamp',
}

const tableColumns_TaskRedos: TsInterface_TableColumns = {
  timestamp: TableCellTimestamp('timestamp', rLIB('Redo Date'), 'timestamp', 'D MMM YYYY - h:mm a', false),
  associated_user_name: TableCellBasic('associated_user_name', rLIB('Created By'), 'associated_user_name'),
  redo_reason: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Redo Reason')
      },
      header_sort_by: 'redo_reason',
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.redo_reason != null) {
          if (
            onSiteTaskRedoTasks != null &&
            onSiteTaskRedoTasks[rowData.redo_reason as string] != null &&
            onSiteTaskRedoTasks[rowData.redo_reason as string]['value'] != null
          ) {
            cellJSX = <Box>{onSiteTaskRedoTasks[rowData.redo_reason as string]['value']}</Box>
          } else {
            cellJSX = <Box>{underscoresToSpaces(rowData.redo_reason as string)}</Box>
          }
        } else {
          cellJSX = <Box className="tw-italic tw-opacity-30">{rLIB('Missing')}</Box>
        }
        return cellJSX
      },
    },
  },
  redo_notes: TableCellBasic('redo_notes', rLIB('Redo Reason Notes'), 'redo_notes'),
}

const formInputs_TaskProgressNotes: TsInterface_FormInputs = {
  status_progression: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'status_progression',
    label: rLIB('Task Status'),
    required: true,
    options: objectToArray(delayStatusOptions),
  },
  status_progression_delay_reason: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'status_progression_delay_reason',
    label: rLIB('Delay Reason'),
    required: false,
    // options: objectToArray( delayReasonOptions )
    options: [], // Dynamically Populated
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'status_progression',
        comparator: '==',
        value: 'roadblock',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'status_progression',
        comparator: '==',
        value: 'roadblock',
        conditions: [],
      },
    },
  },
  status_progression_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'status_progression_notes',
    label: rLIB('Notes'),
    required: false,
  },
  create_scheduled_reminder: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'create_scheduled_reminder',
    label: rLIB('Create Reminder'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'status_progression',
        comparator: '==',
        value: 'roadblock',
        conditions: [],
      },
    },
  },
  scheduled_reminder_timestamp: {
    key: 'scheduled_reminder_timestamp',
    label: rLIB('Reminder Date'),
    input_type: 'timestamp_datetime',
    required: false,
    data_type: 'number',
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status_progression',
            comparator: '==',
            value: 'roadblock',
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'create_scheduled_reminder',
            comparator: '==',
            value: true,
          },
        ],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status_progression',
            comparator: '==',
            value: 'roadblock',
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'create_scheduled_reminder',
            comparator: '==',
            value: true,
          },
        ],
      },
    },
  },
  scheduled_reminder_title: {
    key: 'scheduled_reminder_title',
    label: rLIB('Reminder Title'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status_progression',
            comparator: '==',
            value: 'roadblock',
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'create_scheduled_reminder',
            comparator: '==',
            value: true,
          },
        ],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status_progression',
            comparator: '==',
            value: 'roadblock',
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'create_scheduled_reminder',
            comparator: '==',
            value: true,
          },
        ],
      },
    },
  },
  scheduled_reminder_text: {
    key: 'scheduled_reminder_text',
    label: rLIB('Reminder Text'),
    input_type: 'text_multiline',
    required: false,
    data_type: 'string',
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status_progression',
            comparator: '==',
            value: 'roadblock',
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'create_scheduled_reminder',
            comparator: '==',
            value: true,
          },
        ],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status_progression',
            comparator: '==',
            value: 'roadblock',
          },
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'create_scheduled_reminder',
            comparator: '==',
            value: true,
          },
        ],
      },
    },
  },

  // ui_note_icon: {
  // data_type: "string",
  // input_type: "multiple_choice_radio",
  // key: "ui_note_icon",
  // label: s_ICON,
  // required: false,
  // options: objectToArray( alertSeverityIconOptions )
  // },
}

const formInputs_TaskSnooze: TsInterface_FormInputs = {
  timestamp_snooze_until: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'timestamp_snooze_until',
    label: rLIB('Next action date'),
    required: true,
  },
  next_action_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'next_action_notes',
    label: rLIB('Next Action Notes'),
    required: true,
  },
}

const formSettings_TaskSnooze: TsInterface_FormSettings = {}

///////////////////////////////
// Functions
///////////////////////////////

const openTaskDelayHistory = (tableHooks: TsInterface_TableHooks, rowData: TsInterface_UnspecifiedObject) => {
  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
    .then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_TaskStatusLogs_Collection(res_GCK.clientKey, rowData.key as string))
        .then((res_DGC) => {
          let taskStatusNotes = res_DGC.data
          let sortedStatusNotes = []
          for (let loopLogKey in taskStatusNotes) {
            let loopLog = taskStatusNotes[loopLogKey]
            loopLog['TEMP_timestamp_recorded'] = returnTimestampFromUnknownDateFormat(loopLog.timestamp_recorded)
            sortedStatusNotes.push(loopLog)
          }
          let logsJSX = <></>
          if (taskStatusNotes == null || objectToArray(taskStatusNotes).length === 0) {
            logsJSX = (
              <Box className="tw-text-center tw-m-2">
                <Typography>{rLIB('No status notes on this task')}</Typography>
              </Box>
            )
          } else {
            logsJSX = (
              <Box className="">
                <TableBasic
                  tableAdditionalData={{}}
                  tableColumns={tableColumns_StatusNotes}
                  tableData={objectToArray(taskStatusNotes)}
                  tableSettings={tableSettings_StatusNotes}
                />
                {/* {objectToArray( taskStatusNotes ).sort( dynamicSort("TEMP_timestamp_recorded", "asc" )).map(( statusLog, index ) => (
							<Box className="tw-p-1" key={index}>
								{ rJSX_TaskDelayStatusRow( statusLog ) }
							</Box>
						))} */}
              </Box>
            )
          }
          let dialogJSX = (
            <Box>
              <Dialog
                // TransitionComponent={ Transition }
                className="bp_dialog_xl_width"
                keepMounted
                onClose={() => {
                  tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                }}
                open={true}
              >
                <AppBar
                  position="static"
                  color="inherit"
                >
                  <Toolbar>
                    <IconButton
                      aria-label="menu"
                      color="inherit"
                      disabled
                      edge="start"
                      size="large"
                      sx={{ mr: 2, color: '#fff !important' }}
                    >
                      <Icon icon="book" />
                    </IconButton>
                    <Typography
                      component={'span'}
                      variant={'h6'}
                      sx={{ flexGrow: 1 }}
                    >
                      {rLIB('Status notes history')} - {rLIB('Task')} {rowData.id_number}: {rowData.name}
                    </Typography>
                  </Toolbar>
                </AppBar>
                <DialogContent sx={{ padding: '0px' }}>
                  {logsJSX}
                  <Box className="tw-px-2 tw-py-1 tw-opacity-10 tw-text-right">{rowData.key}</Box>
                </DialogContent>
              </Dialog>
            </Box>
          )
          tableHooks.uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: dialogJSX,
              settings: {
                max_width: 'lg',
              },
            },
          })
        })
        .catch((rej_DGC) => {
          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
        })
    })
    .catch((rej_GCK) => {
      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    })
}

let statusCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cssClassName = ''
      let taskColorObject = determineTaskColorStatus(rowData)
      if (taskColorObject['active'] === true) {
        cssClassName = 'tw-bg-warning_main'
      }
      return cssClassName
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      let taskColorObject = determineTaskColorStatus(rowData)
      cellJSX = (
        <Box>
          <Box
            sx={{ color: taskColorObject.color }}
            className="tw-inline-block"
          >
            <Tooltip
              title={taskColorObject.status}
              placement="right"
            >
              <Box>
                <Icon
                  size="xl"
                  icon={taskColorObject.icon}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      )
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return 'tw-w-10'
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <></>
    },
    header_sort_by: 'TEMP_completion_order',
  },
}

let ownerCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>

      if (rowData != null && rowData['timestamp_completed_by_name'] != null) {
        let sx = {}
        if (rowData['timestamp_completed_by_name'] !== rowData['associated_owner_name']) {
          sx = { color: themeVariables.warning_main }
        }
        cellJSX = (
          <Box>
            <Box sx={sx}>{rowData['timestamp_completed_by_name']}</Box>
            <Box className="tw-italic tw-opacity-30">{rowData.TEMP_associated_owner_type_name}</Box>
          </Box>
        )
      } else if (rowData != null && rowData['associated_owner_name'] != null) {
        cellJSX = (
          <Box>
            <Box>{rowData['associated_owner_name']}</Box>
            <Box className="tw-italic tw-opacity-30">{rowData.TEMP_associated_owner_type_name}</Box>
          </Box>
        )
      } else if (rowData != null && rowData.associated_team_key != null && rowData.task_completion_scheduled_team_names != null) {
        let listOfTeamMemberNames: TsInterface_UnspecifiedObject = {}
        // @ts-expect-error - TODO: reason for error
        for (let loopDayKey in rowData.task_completion_scheduled_team_names) {
          // @ts-expect-error - TODO: reason for error
          let loopDay = rowData.task_completion_scheduled_team_names[loopDayKey]
          for (let loopTeamMemberKey in loopDay) {
            listOfTeamMemberNames[loopTeamMemberKey] = loopDay[loopTeamMemberKey]
          }
        }
        let schedulingTeam = getProp(schedulingTeamTypes, rowData.associated_team_key, {})
        cellJSX = (
          <Box>
            <Box
              className="tw-italic"
              sx={{ color: themeVariables.warning_main }}
            >
              {objectToArray(listOfTeamMemberNames).join(', ')}
            </Box>
            <Box className="tw-italic tw-opacity-30">{getProp(schedulingTeam, 'value', rowData.TEMP_associated_owner_type_name)}</Box>
          </Box>
        )
      } else {
        cellJSX = (
          <Box>
            <Box
              className="tw-italic"
              sx={{ color: themeVariables.error_main }}
            >
              {rLIB('Not Assigned')}
            </Box>
            <Box className="tw-italic tw-opacity-30">{rowData.TEMP_associated_owner_type_name}</Box>
          </Box>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Owner')
    },
    header_sort_by: 'associated_owner_name',
  },
}

let taskNameCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      let redoHistoryIcon = <></>
      let taskNameButtonJSX = <></>
      let buttonSX = { 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }
      if (rowData.key != null && rowData.task_completion_type === 'scheduled') {
        buttonSX = { 'background': themeVariables.warning_main, '&:hover': { background: themeVariables.warning_dark } }
        taskNameButtonJSX = (
          <Box>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-py-1 tw-px-2 tw-text-center tw-inline-block"
              sx={buttonSX}
              onClick={() => {
                // Check if the read only user has access to this task via scheduled team
                let hasAccessViaScheduledTeam = false
                if (
                  tableHooks != null &&
                  tableHooks.uc_RootData_GlobalUser != null &&
                  tableHooks.uc_RootData_GlobalUser.key != null &&
                  rowData != null &&
                  rowData.task_completion_scheduled_team_roles != null
                ) {
                  for (let loopDateKey in getProp(rowData, 'task_completion_scheduled_team_roles', {})) {
                    let loopDate = getProp(rowData, 'task_completion_scheduled_team_roles', {})[loopDateKey]
                    for (let loopUserKey in loopDate) {
                      if (loopUserKey === tableHooks.uc_RootData_GlobalUser.key) {
                        hasAccessViaScheduledTeam = true
                      }
                    }
                  }
                }
                // Open Dialog
                if (tableAdditionalData.readOrWrite !== 'read' || hasAccessViaScheduledTeam === true) {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      openTaskFormEditDialog(
                        res_GCK.clientKey,
                        rowData.key as string,
                        null,
                        tableHooks.uc_setUserInterface_CustomDialogDisplay,
                        tableHooks.uc_setUserInterface_ConfirmDialogDisplay,
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                        getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                        getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                        true,
                      )
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            >
              {rowData.name}
            </Box>
            <Box className="tw-my-1 tw-opacity-20">{rowData.id_number}</Box>
          </Box>
        )
      } else if (rowData.key != null && rowData.task_completion_type === 'dispatcher') {
        buttonSX = { 'background': themeVariables.info_main, '&:hover': { background: themeVariables.info_dark } }
        taskNameButtonJSX = (
          <Box>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-py-1 tw-px-2 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.info_main, '&:hover': { background: themeVariables.info_dark } }}
              onClick={() => {
                if (tableAdditionalData.readOrWrite !== 'read') {
                  tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                    display: true,
                    confirm: {
                      color: 'primary',
                      header: rLIB('Schedule Task'),
                      icon: (
                        <Icon
                          icon="calendar"
                          type="solid"
                        />
                      ),
                      submit_text: rLIB('Open Scheduler'),
                      text: rLIB('Do you want to leave this page to schedule this task?'),
                      submit_callback: () => {
                        return new Promise((resolve, reject) => {
                          let formattedDate = returnFormattedDateKey(new Date())
                          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.TaskCalendarSchedulePage.url(formattedDate))
                          resolve({ success: true })
                        })
                      },
                    },
                  })
                }
              }}
            >
              {rowData.name}
            </Box>
            <Box className="tw-my-1 tw-opacity-20">{rowData.id_number}</Box>
          </Box>
        )
      } else if (rowData.key != null && rowData.task_completion_type !== 'dispatcher' && rowData.key != null && rowData.task_completion_type !== 'scheduled') {
        buttonSX = { 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }
        taskNameButtonJSX = (
          <Box>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-py-1 tw-px-2 tw-text-center tw-inline-block"
              sx={buttonSX}
              onClick={() => {
                if (tableAdditionalData.readOrWrite !== 'read') {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      openTaskFormEditDialog(
                        res_GCK.clientKey,
                        rowData.key as string,
                        null,
                        tableHooks.uc_setUserInterface_CustomDialogDisplay,
                        tableHooks.uc_setUserInterface_ConfirmDialogDisplay,
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                        getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                        getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                        true,
                      )
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            >
              {rowData.name}
            </Box>
            <Box className="tw-my-1 tw-opacity-20">{rowData.id_number}</Box>
          </Box>
        )
      } else if (rowData.key != null) {
        taskNameButtonJSX = (
          <Box>
            <Box
              className="tw-rounded-md tw-py-1 tw-px-2 tw-text-center tw-cursor-default tw-inline-block"
              sx={{ border: '1px solid ' + themeVariables.background_json }}
            >
              {rowData.name}
            </Box>
            <Box className="tw-my-1 tw-opacity-20">{rowData.id_number}</Box>
          </Box>
        )
      } else {
        // Nothing
      }
      if (rowData != null && rowData.redo_and_retry_attempts != null) {
        let redoAttempts = getProp(rowData, 'redo_and_retry_attempts', {})
        // Dialog JSX
        let dialogJSX = (
          <Box>
            <Dialog
              className="bp_dialog_xl_width"
              keepMounted
              onClose={() => {
                tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              }}
              open={true}
            >
              <AppBar
                position="static"
                color="inherit"
              >
                <Toolbar>
                  <IconButton
                    aria-label="menu"
                    color="inherit"
                    disabled
                    edge="start"
                    size="large"
                    sx={{ mr: 2, color: '#fff !important' }}
                  >
                    <Icon icon="clock-rotate-left" />
                  </IconButton>
                  <Typography
                    component={'span'}
                    variant={'h6'}
                    sx={{ flexGrow: 1 }}
                  >
                    {rLIB('Redo History')} - {rLIB('Task')} {rowData.id_number}: {rowData.name}
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent sx={{ padding: '0px' }}>
                <TableBasic
                  tableAdditionalData={{}}
                  tableColumns={tableColumns_TaskRedos}
                  tableData={objectToArray(redoAttempts)}
                  tableSettings={tableSettings_TaskRedos}
                />
              </DialogContent>
            </Dialog>
          </Box>
        )
        // Icon JSX
        redoHistoryIcon = (
          <Tooltip
            placement="right"
            title={rLIB('Redo History')}
          >
            <Box
              className="tw-ml-2 tw-inline-block tw-cursor-pointer"
              sx={{ color: themeVariables.warning_dark }}
              onClick={() => {
                tableHooks.uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: dialogJSX,
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              }}
            >
              <Icon icon="clock-rotate-left" />
            </Box>
          </Tooltip>
        )
      }
      if (rowData != null && rowData['name'] != null) {
        let scheduledDateJSX = <></>
        if (rowData['task_completion_type'] === 'scheduled') {
          let teamMemberNames: TsInterface_UnspecifiedObject = {}
          if (rowData != null && rowData['task_completion_scheduled_team_names'] != null) {
            let loopDates = getProp(rowData, 'task_completion_scheduled_team_names', {})
            for (let loopDateKey in loopDates) {
              let loopDate = loopDates[loopDateKey]
              for (let loopUserKey in loopDate) {
                let loopUserName = loopDate[loopUserKey]
                teamMemberNames[loopUserKey] = loopUserName
              }
            }
          }
          let teamMemberIconJSX = <></>
          if (teamMemberNames != null) {
            let memberListJSX = (
              <Box>
                {objectToArray(teamMemberNames)
                  .sort()
                  .map((userName: string, index: number) => (
                    <Box key={index}>{userName}</Box>
                  ))}
              </Box>
            )
            teamMemberIconJSX = (
              <Tooltip
                placement="right"
                title={memberListJSX}
              >
                <Box className="tw-inline-block">
                  <Icon
                    icon="users"
                    className="tw-ml-2"
                  />
                </Box>
              </Tooltip>
            )
          }
          let scheduledDates: string[] = getProp(rowData, 'task_completion_scheduled_dates', []).sort()
          if (scheduledDates.length > 1) {
            scheduledDateJSX = (
              <Box
                className="tw-italic"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="calendar"
                  className="tw-mr-2"
                />
                {scheduledDates[0]} {rLIB('to')} {scheduledDates[scheduledDates.length - 1]}
                {teamMemberIconJSX}
              </Box>
            )
          } else if (scheduledDates.length === 1) {
            scheduledDateJSX = (
              <Box
                className="tw-italic"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="calendar"
                  className="tw-mr-2"
                />
                {scheduledDates[0]}
                {teamMemberIconJSX}
              </Box>
            )
          } else {
            scheduledDateJSX = (
              <Box
                className="tw-italic"
                sx={{ color: themeVariables.warning_main }}
              >
                <Icon
                  icon="calendar"
                  className="tw-mr-2"
                />
                {rLIB('Not Scheduled Yet')}
              </Box>
            )
          }
        }
        cellJSX = (
          <Box>
            {taskNameButtonJSX}
            {redoHistoryIcon}
            {scheduledDateJSX}
          </Box>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Task Name')
    },
    header_sort_by: 'name',
  },
}

let elapsedDaysCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      let chipJSX = <></>
      if (rowData != null && rowData.TEMP_elapsed_days != null) {
        if (
          rowData.status_green_days_cutoff != null &&
          !isNaN(rowData.status_green_days_cutoff as number) != null &&
          ((rowData.TEMP_elapsed_days <= rowData.status_green_days_cutoff) as unknown as number)
        ) {
          chipJSX = (
            <Chip
              className="tw-cursor-pointer tw-px-2"
              label={rowData.TEMP_elapsed_days}
              variant="filled"
              color="success"
            ></Chip>
          )
        } else if (
          rowData.status_yellow_days_cutoff != null &&
          !isNaN(rowData.status_yellow_days_cutoff as number) != null &&
          ((rowData.TEMP_elapsed_days <= rowData.status_yellow_days_cutoff) as unknown as number)
        ) {
          chipJSX = (
            <Chip
              className="tw-cursor-pointer tw-px-2"
              label={rowData.TEMP_elapsed_days}
              variant="filled"
              color="warning"
            ></Chip>
          )
        } else {
          chipJSX = (
            <Chip
              className="tw-cursor-pointer tw-px-2"
              label={rowData.TEMP_elapsed_days}
              variant="filled"
              color="error"
            ></Chip>
          )
        }
        cellJSX = (
          <Tooltip
            title={
              <Box>
                <Box>
                  {rLIB('Green')}: {rowData.status_green_days_cutoff}
                </Box>
                <Box>
                  {rLIB('Yellow')}: {rowData.status_yellow_days_cutoff}
                </Box>
                <Box>
                  {rLIB('Red')}: {(rowData.status_yellow_days_cutoff as number) + 1}+
                </Box>
              </Box>
            }
            placement="left"
          >
            {chipJSX}
          </Tooltip>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Total Days')
    },
    header_sort_by: 'TEMP_elapsed_days',
  },
}

let prerequisiteTasksCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let validTaskPrerequisiteDataStructure: boolean = true
      if (tableAdditionalData != null && tableAdditionalData['taskPrerequisitesValidityCheck'] != null) {
        validTaskPrerequisiteDataStructure = getProp(tableAdditionalData['taskPrerequisitesValidityCheck'], rowData.key as string, null)
      }
      let cssClassName = ''
      if (validTaskPrerequisiteDataStructure === false) {
        cssClassName = 'tw-bg-warning_main'
      }
      return cssClassName
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      let validTaskPrerequisiteDataStructure: boolean = true
      if (tableAdditionalData != null && tableAdditionalData['taskPrerequisitesValidityCheck'] != null) {
        validTaskPrerequisiteDataStructure = getProp(tableAdditionalData['taskPrerequisitesValidityCheck'], rowData.key as string, null)
      }
      let invalidPrerequisitesWarning = <></>
      if (validTaskPrerequisiteDataStructure === false) {
        invalidPrerequisitesWarning = (
          <Box className="tw-mb-1">
            <Box
              sx={{ color: themeVariables.error_main }}
              className="tw-inline-block tw-mr-1"
            >
              <Icon
                size="xl"
                icon="skull"
              />
            </Box>
            <Box
              sx={{ color: themeVariables.error_main }}
              className="tw-inline-block"
            >
              {rLIB('Task Prerequisite Infinite Loop')}
            </Box>
          </Box>
        )
      }
      const getPrerequisiteTaskJSX = (prerequisiteTaskKey: string, validPrerequisites: boolean): JSX.Element => {
        let taskJSX = <></>
        if (
          prerequisiteTaskKey != null &&
          tableAdditionalData != null &&
          tableAdditionalData['projectTasks'] != null &&
          tableAdditionalData['projectTasks'][prerequisiteTaskKey] != null
        ) {
          let opacityCssClass = 'tw-mb-1 tw-opacity-50'
          if (validPrerequisites === false) {
            opacityCssClass = 'tw-mb-1 tw-opacity-100'
          }
          let prerequisiteTask = tableAdditionalData['projectTasks'][prerequisiteTaskKey]
          let prerequisiteTaskColor = determineTaskColorStatus(tableAdditionalData['projectTasks'][prerequisiteTaskKey])
          taskJSX = (
            <Box className={opacityCssClass}>
              <Box
                sx={{ color: prerequisiteTaskColor.color }}
                className="tw-inline-block tw-mr-1"
              >
                <Icon
                  size="xl"
                  icon={prerequisiteTaskColor.icon}
                />
              </Box>
              <Box className="tw-inline-block">{prerequisiteTask.name}</Box>
            </Box>
          )
        }
        return taskJSX
      }
      if (
        rowData != null &&
        rowData.prerequisite_tasks != null &&
        objectToArray(rowData.prerequisite_tasks as unknown as TsInterface_UnspecifiedObject).length > 0
      ) {
        cellJSX = (
          <Box>
            {invalidPrerequisitesWarning}
            {objectToArray(rowData.prerequisite_tasks as unknown as TsInterface_UnspecifiedObject).map((prerequisiteTaskKey, taskIndex) => (
              <Box key={taskIndex}>{getPrerequisiteTaskJSX(prerequisiteTaskKey, validTaskPrerequisiteDataStructure)}</Box>
            ))}
          </Box>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Prerequisite Tasks')
    },
    header_sort_by: null,
  },
}

let downstreamTaskCells = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let validTaskPrerequisiteDataStructure: boolean = true
      if (tableAdditionalData != null && tableAdditionalData['taskPrerequisitesValidityCheck'] != null) {
        validTaskPrerequisiteDataStructure = getProp(tableAdditionalData['taskPrerequisitesValidityCheck'], rowData.key as string, null)
      }
      let cssClassName = ''
      if (validTaskPrerequisiteDataStructure === false) {
        cssClassName = 'tw-bg-warning_main'
      }
      return cssClassName
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      let validTaskPrerequisiteDataStructure: boolean = true
      if (tableAdditionalData != null && tableAdditionalData['taskPrerequisitesValidityCheck'] != null) {
        validTaskPrerequisiteDataStructure = getProp(tableAdditionalData['taskPrerequisitesValidityCheck'], rowData.key as string, null)
      }
      let invalidPrerequisitesWarning = <></>
      if (validTaskPrerequisiteDataStructure === false) {
        invalidPrerequisitesWarning = (
          <Box className="tw-mb-1">
            <Box
              sx={{ color: themeVariables.error_main }}
              className="tw-inline-block tw-mr-1"
            >
              <Icon
                size="xl"
                icon="skull"
              />
            </Box>
            <Box
              sx={{ color: themeVariables.error_main }}
              className="tw-inline-block"
            >
              {rLIB('Task Prerequisite Infinite Loop')}
            </Box>
          </Box>
        )
      }
      const getDownstreamTaskJSX = (downstreamTaskKey: string, validPrerequisites: boolean): JSX.Element => {
        let taskJSX = <></>
        if (
          downstreamTaskKey != null &&
          tableAdditionalData != null &&
          tableAdditionalData['projectTasks'] != null &&
          tableAdditionalData['projectTasks'][downstreamTaskKey] != null
        ) {
          let opacityCssClass = 'tw-mb-1 tw-opacity-50'
          if (validPrerequisites === false) {
            opacityCssClass = 'tw-mb-1 tw-opacity-100'
          }
          let prerequisiteTask = tableAdditionalData['projectTasks'][downstreamTaskKey]
          let prerequisiteTaskColor = determineTaskColorStatus(tableAdditionalData['projectTasks'][downstreamTaskKey])
          taskJSX = (
            <Box className={opacityCssClass}>
              <Box
                sx={{ color: prerequisiteTaskColor.color }}
                className="tw-inline-block tw-mr-1"
              >
                <Icon
                  size="xl"
                  icon={prerequisiteTaskColor.icon}
                />
              </Box>
              <Box className="tw-inline-block">{prerequisiteTask.name}</Box>
            </Box>
          )
          // }
        }
        return taskJSX
      }
      let downstreamTasks: TsInterface_UnspecifiedObject = {}
      if (tableAdditionalData != null && tableAdditionalData['projectTasks'] != null) {
        for (let loopTaskKey in tableAdditionalData['projectTasks']) {
          let loopTask = tableAdditionalData['projectTasks'][loopTaskKey]
          if (
            rowData != null &&
            rowData.key != null &&
            loopTask != null &&
            loopTask['prerequisite_tasks'] != null &&
            loopTask['prerequisite_tasks'][rowData.key as string] != null
          ) {
            downstreamTasks[loopTaskKey] = loopTaskKey
          }
        }
      }
      if (objectToArray(downstreamTasks).length > 0) {
        cellJSX = (
          <Box>
            {invalidPrerequisitesWarning}
            {objectToArray(downstreamTasks).map((downstreamTaskKey, taskIndex) => (
              <Box key={taskIndex}>{getDownstreamTaskJSX(downstreamTaskKey, validTaskPrerequisiteDataStructure)}</Box>
            ))}
          </Box>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Followup Tasks')
    },
    header_sort_by: null,
  },
}

let snoozeTimestampCell = {
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Next action date')
    },
    header_sort_by: 'timestamp_snooze_until',
  },
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      let formData: TsInterface_UnspecifiedObject = {}
      if (rowData.timestamp_snooze_until != null) {
        formData['timestamp_snooze_until'] = returnFormattedDateKey(returnDateFromUnknownDateFormat(rowData.timestamp_snooze_until))
      }
      if (rowData.next_action_notes != null) {
        formData['next_action_notes'] = rowData.next_action_notes
      }
      let editIconJSX = (
        <Icon
          icon="pen-to-square"
          className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-align-text-top"
          tooltip={rLIB('Edit next action date')}
          tooltipPlacement="top"
          onClick={() => {
            tableHooks.uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: formData,
                  formInputs: formInputs_TaskSnooze,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: formSettings_TaskSnooze,
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      let parsedDate = returnDateFromUnknownDateFormat(formSubmittedData.timestamp_snooze_until)
                      let correctedDate = new Date(
                        parsedDate.getFullYear(),
                        parsedDate.getMonth(),
                        parsedDate.getDate(),
                        parsedDate.getHours() + parsedDate.getTimezoneOffset() / 60,
                      )
                      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          let updateObject = {
                            timestamp_snooze_until: correctedDate,
                            timestamp_last_updated: new Date(),
                            next_action_notes: formSubmittedData.next_action_notes,
                          }
                          DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              reject(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'info',
                  formDialogHeaderText: rLIB('Edit next action date'),
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="clock"
                    />
                  ),
                },
              },
            })
          }}
        />
      )

      if (rowData.timestamp_snooze_until == null) {
        cellJSX = (
          <Box>
            <Box className="tw-inline-block tw-opacity-30">{rLIB('New Task')}</Box>
            {editIconJSX}
          </Box>
        )
      } else {
        cellJSX = (
          <Box>
            <Box
              className="tw-inline-block tw-whitespace-break-spaces"
              sx={{ maxWidth: '300px' }}
            >
              <Box>
                <Icon
                  icon="clock"
                  className="tw-mr-1"
                />
                {returnFormattedDate(rowData.timestamp_snooze_until, 'DD MMM YYYY')}
                {editIconJSX}
              </Box>
              <Box className="tw-opacity-30">{getProp(rowData, 'next_action_notes', <></>)}</Box>
            </Box>
          </Box>
        )
      }
      return cellJSX
    },
  },
}

let taskDelayCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      // Edit Icon
      let editIconJSX = (
        <Tooltip
          title={rLIB('Edit')}
          placement="top"
        >
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer tw-align-text-top"
            onClick={() => {
              let delayReasonOptions: TsInterface_UnspecifiedObject = {}
              if (
                rowData != null &&
                rowData.delay_reasons != null &&
                objectToArray(rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject).length > 0
              ) {
                let delayReasons = objectToArray(rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject)
                for (let loopReasonIndex in delayReasons) {
                  let loopReason = delayReasons[loopReasonIndex]
                  delayReasonOptions[loopReason] = {
                    key: loopReason,
                    value: loopReason,
                  }
                }
              } else {
                delayReasonOptions = {
                  CustomerRequested: {
                    key: 'CustomerRequested',
                    value: 'Customer Requested',
                  },
                  TaskComplexity: {
                    key: 'TaskComplexity',
                    value: 'Unanticipated task complexity',
                  },
                  Weather: {
                    key: 'Weather',
                    value: 'Weather',
                  },
                  WaitingOnThirdParty: {
                    key: 'WaitingOnThirdParty',
                    value: 'Waiting on third party',
                  },
                  Inaccessible: {
                    key: 'Inaccessible',
                    value: 'Site, Vehicle or Task Inaccessible',
                  },
                  Other: {
                    key: 'Other',
                    value: 'Other (add detailed notes)',
                  },
                }
              }
              // Add Options to form
              formInputs_TaskProgressNotes['status_progression_delay_reason']['options'] = objectToArray(delayReasonOptions)
              // Open Dialog
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {
                      taskKey: rowData.key,
                    },
                    formData: rowData,
                    formInputs: formInputs_TaskProgressNotes,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        if (formAdditionalData.taskKey != null) {
                          getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              DatabaseGetDocument(DatabaseRef_Task_Document(res_GCK.clientKey, formAdditionalData.taskKey as string))
                                .then((res_DGD) => {
                                  let task = res_DGD.data
                                  let logTimestamp = new Date()
                                  let logKey = logTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
                                  let taskUpdateObject: TsInterface_UnspecifiedObject = {
                                    status_progression: getProp(formSubmittedData, 'status_progression', null),
                                    status_progression_notes: getProp(formSubmittedData, 'status_progression_notes', null),
                                    status_progression_delay_reason: getProp(formSubmittedData, 'status_progression_delay_reason', null),
                                    timestamp_last_updated: new Date(),
                                  }
                                  let logUpdateObject: TsInterface_UnspecifiedObject = {
                                    timestamp_recorded: logTimestamp,
                                    status_progression: getProp(formSubmittedData, 'status_progression', null),
                                    status_progression_notes: getProp(formSubmittedData, 'status_progression_notes', null),
                                    status_progression_delay_reason: getProp(formSubmittedData, 'status_progression_delay_reason', null),
                                    associated_user_name: getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                                    associated_user_key: getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                                  }
                                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                                    {
                                      type: 'setMerge',
                                      ref: DatabaseRef_Task_Document(res_GCK.clientKey, formAdditionalData.taskKey as string),
                                      data: taskUpdateObject,
                                    },
                                    {
                                      type: 'setMerge',
                                      ref: DatabaseRef_TaskStatusLog_Document(res_GCK.clientKey, formAdditionalData.taskKey as string, logKey),
                                      data: logUpdateObject,
                                    },
                                  ]
                                  if (
                                    formSubmittedData['status_progression'] === 'roadblock' &&
                                    formSubmittedData['create_scheduled_reminder'] === true &&
                                    formSubmittedData['scheduled_reminder_timestamp'] != null &&
                                    formSubmittedData['scheduled_reminder_title'] != null &&
                                    formSubmittedData['scheduled_reminder_text'] != null
                                  ) {
                                    let newReminderKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                                    let scheduledReminderUpdateObject = {
                                      timestamp_reminder: new Date(formSubmittedData['scheduled_reminder_timestamp']),
                                      title: formSubmittedData['scheduled_reminder_title'],
                                      text: formSubmittedData['scheduled_reminder_text'],
                                      key: newReminderKey,
                                      associated_user_key: getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                                      associated_project_key: task.associated_project_key || null,
                                      status: 'unread',
                                      timestamp_created: new Date(),
                                    }
                                    updateArray.push({
                                      type: 'setMerge',
                                      ref: DatabaseRef_Reminder_Document(res_GCK.clientKey, newReminderKey),
                                      data: scheduledReminderUpdateObject,
                                    })
                                  }
                                  DatabaseBatchUpdate(updateArray)
                                    .then((res_DBU) => {
                                      resolve(res_DBU)
                                    })
                                    .catch((rej_DBU) => {
                                      reject(rej_DBU)
                                    })
                                })
                                .catch((rej_DGD) => {
                                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGD.error })
                                })
                            })
                            .catch((rej_GCK) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            })
                        } else {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({
                            display: true,
                            error: {
                              message: rLIB('Failed to update task status notes'),
                              details: rLIB('Missing required parameters'),
                              code: 'ER-S-TT-DSTC-01',
                            },
                          })
                        }
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit Task Status Notes')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        </Tooltip>
      )
      // View Icon
      let viewIconJSX = <></>
      if (rowData != null && (rowData['status_progression_notes'] != null || rowData['status_progression'] != null)) {
        viewIconJSX = (
          <Tooltip
            title={rLIB('Delay History')}
            placement="top"
            sx={{ color: themeVariables.warning_dark }}
          >
            <Box
              className="tw-inline-block tw-ml-2 tw-cursor-pointer tw-align-text-top"
              onClick={() => {
                openTaskDelayHistory(tableHooks, rowData)
              }}
            >
              <Icon icon="clock-rotate-left" />
            </Box>
          </Tooltip>
        )
      }
      let cellJSX = <></>
      let statusIconJSX = <></>
      const rJSX_ProgressDelayReason = (): JSX.Element => {
        let delayReasonJSX = <></>
        if (rowData != null && rowData.status_progression_delay_reason != null) {
          delayReasonJSX = <>{rowData['status_progression_delay_reason']}</>
        }
        return delayReasonJSX
      }
      if (
        rowData != null &&
        rowData.status_progression != null &&
        delayStatusOptions != null &&
        delayStatusOptions[rowData.status_progression as string] != null &&
        delayStatusOptions[rowData.status_progression as string]['icon'] != null
      ) {
        statusIconJSX = delayStatusOptions[rowData.status_progression as string]['icon']
      }
      let statusSelectionJSX = <></>
      if (
        rowData != null &&
        rowData.status_progression != null &&
        delayStatusOptions != null &&
        delayStatusOptions[rowData.status_progression as string] != null &&
        delayStatusOptions[rowData.status_progression as string]['value'] != null
      ) {
        statusSelectionJSX = <>{delayStatusOptions[rowData.status_progression as string]['value']} </>
      }
      if (rowData != null && rowData.status === 'deleted') {
        cellJSX = (
          <Box>
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.gray_700 }}
            >
              {rLIB('Task Deleted')}
            </Box>
            {viewIconJSX}
          </Box>
        )
      } else if (rowData != null && rowData.status_complete === true) {
        cellJSX = (
          <Box>
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.info_main }}
            >
              {rLIB('Task Complete')}
            </Box>
            {viewIconJSX}
          </Box>
        )
      } else if (rowData != null && rowData['status_progression'] === 'roadblock') {
        cellJSX = (
          <Box>
            <Box className="tw-inline-block">
              <Box>
                <Box
                  className="tw-inline-block tw-whitespace-break-spaces"
                  sx={{ maxWidth: '300px' }}
                >
                  <Box>
                    <Box
                      className="tw-inline-block"
                      sx={{ color: themeVariables.error_light }}
                    >
                      <Box className="tw-inline-block tw-mr-1">{statusIconJSX}</Box>
                      {rJSX_ProgressDelayReason()}
                    </Box>
                    {editIconJSX}
                    {viewIconJSX}
                  </Box>
                  <Box className="tw-opacity-30 ">{rowData['status_progression_notes']}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      } else if (rowData != null && rowData['status_progression'] === 'no_roadblock') {
        cellJSX = (
          <Box>
            <Box
              className="tw-inline-block tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden"
              sx={{ maxWidth: '250px', color: themeVariables.success_light }}
            >
              <Box className="tw-inline-block tw-mr-1">{statusIconJSX}</Box>
              {statusSelectionJSX}
              {editIconJSX}
            </Box>
          </Box>
        )
      } else {
        cellJSX = (
          <Box>
            <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('No Notes')}</Box>
            {editIconJSX}
            {viewIconJSX}
          </Box>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return 'Task Notes'
    },
    header_sort_by: 'status_progression',
  },
}

let manageCellView: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="magnifying-glass"
    />
  ),
  label: <>{rLIB('View')}</>,
  conditional_disable: {
    active: true,
    logic_type: 'and',
    source: null,
    prop: null,
    comparator: null,
    value: null,
    conditions: [
      {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'task_completion_type',
        comparator: '==',
        value: 'dispatcher',
        conditions: [],
      },
    ],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          openTaskFormEditDialog(
            res_GCK.clientKey,
            rowData.key as string,
            null,
            tableHooks.uc_setUserInterface_CustomDialogDisplay,
            tableHooks.uc_setUserInterface_ConfirmDialogDisplay,
            tableHooks.uc_setUserInterface_ErrorDialogDisplay,
            getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
            getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
            false,
          )
        })
        .catch((rej_GCK) => {
          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  },
}

let manageCellComplete: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="badge-check"
    />
  ),
  label: <>{rLIB('Complete Task')}</>,
  conditional_disable: {
    active: true,
    logic_type: 'and',
    source: null,
    prop: null,
    comparator: null,
    value: null,
    conditions: [
      {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'task_completion_type',
        comparator: '==',
        value: 'dispatcher',
        conditions: [],
      },
    ],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          openTaskFormEditDialog(
            res_GCK.clientKey,
            rowData.key as string,
            null,
            tableHooks.uc_setUserInterface_CustomDialogDisplay,
            tableHooks.uc_setUserInterface_ConfirmDialogDisplay,
            tableHooks.uc_setUserInterface_ErrorDialogDisplay,
            getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
            getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
            true,
          )
        })
        .catch((rej_GCK) => {
          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  },
}

let manageCellDispatchTask: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="paper-plane"
    />
  ),
  label: <>{rLIB('Dispatch Associated Task')}</>,
  conditional_disable: {
    active: true,
    logic_type: 'comparison',
    source: 'rowData',
    prop: 'task_completion_type',
    comparator: '!=',
    value: 'dispatcher',
    conditions: [],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    let formattedDate = returnFormattedDateKey(new Date())
    directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.TaskCalendarSchedulePage.url(formattedDate))
  },
}

let manageCellDelayStatusView = {
  icon: (
    <Icon
      type="solid"
      icon="book"
    />
  ),
  label: <>{rLIB('Status notes history')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    openTaskDelayHistory(tableHooks, rowData)
  },
}

let manageCellDelete: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="trash"
    />
  ),
  label: <>{rLIB('Delete Task')}</>,
  conditional_disable: {
    active: true,
    logic_type: 'or',
    source: null,
    prop: null,
    comparator: null,
    value: null,
    conditions: [
      {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      // {
      // 	active: true,
      // 	logic_type: "comparison",
      // 	source: "rowData",
      // 	prop: "status",
      // 	comparator: "==",
      // 	value: "completed",
      // 	conditions: []
      // },
    ],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Task'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this task?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (rowData.key != null) {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  deleteProjectTask(
                    res_GCK.clientKey,
                    rowData.key as string,
                    getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                    getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                    'save_to_database',
                    null,
                  )
                    .then((res_DPT) => {
                      resolve(res_DPT)
                    })
                    .catch((rej_DPT) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DPT.error })
                      reject(rej_DPT)
                    })
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            }
          })
        },
      },
    })
  },
}

let manageCellUndelete: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="trash-undo"
    />
  ),
  label: <>{rLIB('Undelete Task')}</>,
  conditional_display: {
    active: true,
    logic_type: 'and',
    source: null,
    prop: null,
    comparator: null,
    value: null,
    conditions: [
      {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    ],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'info',
        header: rLIB('Undelete Task'),
        icon: (
          <Icon
            icon="trash-undo"
            type="solid"
          />
        ),
        submit_text: rLIB('Undelete'),
        text: rLIB('Are you sure that you want to undelete this task?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (rowData.key != null) {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  undeleteProjectTask(
                    res_GCK.clientKey,
                    rowData.key as string,
                    tableHooks.uc_RootData_ClientUser.key,
                    tableHooks.uc_RootData_ClientUser.name,
                    'save_to_database',
                  )
                    .then((res_UPT) => {
                      resolve(res_UPT)
                    })
                    .catch((rej_UPT) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_UPT.error })
                      reject(rej_UPT)
                    })
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            }
          })
        },
      },
    })
  },
}

let manageCellGoToProject: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="arrow-up-right-from-square"
    />
  ),
  conditional_disable: {
    active: true,
    logic_type: 'comparison',
    source: 'rowData',
    prop: 'associated_project_key',
    comparator: '==',
    value: null,
    conditions: [],
  },
  label: <>{rLIB('Go to Project')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    // TODO - handle other user types probably
    directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(rowData.associated_project_key as string))
  },
}

let manageCellGoToSalesProject: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="arrow-up-right-from-square"
    />
  ),
  label: <>{rLIB('Go to Project')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    // TODO - handle other user types probably
    directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.SalesProjectViewPage.url(rowData.associated_project_key as string))
  },
}

let manageCellGoToSubKProject: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="arrow-up-right-from-square"
    />
  ),
  label: <>{rLIB('Go to Project')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    // TODO - handle other user types probably
    directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.SubKProjectViewPage.url(rowData.associated_project_key as string))
  },
}

let manageRedoCell: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="rotate-right"
    />
  ),
  label: <>{rLIB('Redo Task')}</>,
  conditional_disable: {
    active: true,
    logic_type: 'or',
    source: null,
    prop: null,
    comparator: null,
    value: null,
    conditions: [
      // {
      // 	active: true,
      // 	logic_type: "comparison",
      // 	source: "rowData",
      // 	prop: "status",
      // 	comparator: "!=",
      // 	value: "completed",
      // 	conditions: []
      // },
      // {
      // 	active: true,
      // 	logic_type: "comparison",
      // 	source: "rowData",
      // 	prop: "task_completion_type",
      // 	comparator: "==",
      // 	value: "dispatcher",
      // 	conditions: []
      // },
      {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'TEMP_allow_task_redos',
        comparator: '!=',
        value: true,
        conditions: [],
      },
    ],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    let formData: TsInterface_UnspecifiedObject = { downstream_tasks: {} }
    if (rowData.task_completion_type === 'direct') {
      // Preselected Downstream Tasks
      if (
        rowData != null &&
        rowData['key'] != null &&
        tableAdditionalData != null &&
        tableAdditionalData['projectTaskWorkflow'] != null &&
        tableAdditionalData['projectTaskWorkflow']['tasks'] != null
      ) {
        for (let loopTaskKey in tableAdditionalData['projectTaskWorkflow']['tasks']) {
          let loopTask = tableAdditionalData['projectTaskWorkflow']['tasks'][loopTaskKey]
          if (
            loopTask != null &&
            loopTask['associated_task_key'] != null &&
            loopTask['prerequisite_tasks'] != null &&
            loopTask['prerequisite_tasks'][rowData['key'] as string] != null
          ) {
            formData['downstream_tasks'][loopTask['key']] = true
          }
        }
      }
      // Downstream Task Options
      let downstreamTaskOptions: TsInterface_UnspecifiedObject[] = []
      if (tableAdditionalData.projectTasks != null) {
        for (let loopTaskKey in tableAdditionalData.projectTasks) {
          let loopTask = tableAdditionalData.projectTasks[loopTaskKey]
          if (loopTask != null && loopTask.key != null && loopTask.name != null) {
            if (loopTask.status_complete === true) {
              downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
            } else {
              downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name })
            }
          }
        }
      }
      if (downstreamTaskOptions != null) {
        formInputs_RedoTask['downstream_tasks']['options'] = downstreamTaskOptions.sort(dynamicSort('value', 'asc'))
      }
      // Open Dialog
      tableHooks.uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: {
              task: rowData,
              task_key: rowData.key,
              projectTasks: tableAdditionalData.projectTasks,
            },
            formData: formData,
            formInputs: formInputs_RedoTask,

            formOnChange: formOnChange_RedoTask,
            formSettings: formSettings_RedoTask,
            formSubmission: formSubmission_RedoTask,
          },
          dialog: {
            formDialogHeaderColor: 'info',
            formDialogHeaderText: (
              <>
                {rLIB('Redo Office Task')} - {rowData.name}
              </>
            ),
            formDialogIcon: (
              <Icon
                type="solid"
                icon="rotate-right"
              />
            ),
          },
        },
      })
    } else if (rowData.task_completion_type === 'scheduled') {
      tableAdditionalData.task_key = rowData.key
      // Open Dialog
      tableHooks.uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: tableAdditionalData,
            formData: formData,
            formInputs: formInputs_RetryTask,

            formOnChange: formOnChange_RetryTask,
            formSettings: formSettings_RetryTask,
            formSubmission: formSubmission_RetryTask,
          },
          dialog: {
            formDialogHeaderColor: 'info',
            formDialogHeaderText: (
              <>
                {rLIB('Redo On Site Task')} - {rowData.name}
              </>
            ),
            formDialogIcon: (
              <Icon
                type="solid"
                icon="rotate-right"
              />
            ),
          },
        },
      })
    } else if (rowData.task_completion_type === 'dispatcher' && rowData.associated_dispatched_task_key != null) {
      let scheduledTask: TsInterface_UnspecifiedObject = cloneObjectWithoutReference(rowData)
      let scheduledTaskKey: string = rowData.associated_dispatched_task_key as string
      if (
        tableAdditionalData != null &&
        tableAdditionalData['projectTasks'] != null &&
        tableAdditionalData['projectTasks'][scheduledTaskKey] != null &&
        tableAdditionalData['projectTasks'][scheduledTaskKey]['associated_task_key'] != null &&
        tableAdditionalData['projectTaskWorkflow'] != null &&
        tableAdditionalData['projectTaskWorkflow']['tasks'] != null &&
        tableAdditionalData['projectTaskWorkflow']['tasks'][tableAdditionalData['projectTasks'][scheduledTaskKey]['associated_task_key']] != null
      ) {
        scheduledTask = tableAdditionalData['projectTasks'][scheduledTaskKey]
      }
      let formAdditionalData = cloneObjectWithoutReference(tableAdditionalData)
      formAdditionalData['task_key'] = rowData.associated_dispatched_task_key
      // Open Dialog
      tableHooks.uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: formAdditionalData,
            formData: formData,
            formInputs: formInputs_RetryTask,
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: formSettings_RetryTask,
            formSubmission: formSubmission_RetryTask,
          },
          dialog: {
            formDialogHeaderColor: 'info',
            formDialogHeaderText: (
              <>
                {rLIB('Redo On Site Task')} - {scheduledTask.name}
              </>
            ),
            formDialogIcon: (
              <Icon
                type="solid"
                icon="rotate-right"
              />
            ),
          },
        },
      })
    } else {
      tableHooks.uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: {
          message: rLIB('Failed to create redo task'),
          details: rLIB('Unsupport task type'),
          code: 'ER-D-TT-OC-01',
        },
      })
    }
  },
}

let editCell: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="pen-to-square"
    />
  ),
  label: <>{rLIB('Edit Task')}</>,
  // conditional_display: {
  conditional_disable: {
    active: true,
    // logic_type: "and",
    logic_type: 'or',
    source: null,
    prop: null,
    comparator: null,
    value: null,
    conditions: [
      // {
      // 	active: true,
      // 	logic_type: "comparison",
      // 	source: "rowData",
      // 	prop: "status",
      // 	// comparator: "==",
      // 	comparator: "!=",
      // 	value: "active",
      // 	conditions: []
      // },
      // {
      // 	active: true,
      // 	logic_type: "comparison",
      // 	source: "rowData",
      // 	prop: "task_completion_type",
      // 	// comparator: "==",
      // 	comparator: "!=",
      // 	value: "scheduled",
      // 	conditions: []
      // },
    ],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
      .then((res_GCK) => {
        editTask(
          res_GCK.clientKey,
          rowData,
          tableAdditionalData.projectTasks,
          tableHooks.uc_setUserInterface_FormDialogDisplay,
          tableAdditionalData.tempTaskFormOptions,
        )
      })
      .catch((rej_GCK) => {
        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  },
}

const manageTeamEditCell: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="people-group"
    />
  ),
  label: rLIB('Edit Team Type'),
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
      .then((res_GCK) => {
        editTaskTeam(
          res_GCK.clientKey,
          rowData,
          tableAdditionalData.projectTasks,
          tableHooks.uc_setUserInterface_FormDialogDisplay,
          tableAdditionalData.tempTaskFormOptions,
          tableHooks,
        )
      })
      .catch((rej_GCK) => {
        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  },
}

export const formInputs_LinkedVehicle: TsInterface_FormInputs = {
  linked_vehicle_name: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'linked_vehicle_name',
    label: 'Select New Vehicle',
    required: true,
    options: [],
  },
}

let linkedVehicleCell = {
  cell: {
    cell_css: () => '',
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      // Display "Add Linked Vehicle" if no linked_vehicle_name exists
      let vehicleLinkJSX
      if (!rowData.linked_vehicle_name) {
        vehicleLinkJSX = <Box className="tw-inline-block tw-opacity-30">{'Add Linked Vehicle'}</Box>
      } else {
        vehicleLinkJSX = TableCellLink(
          'linked_vehicle_name',
          'Linked Vehicle',
          'linked_vehicle_name',
          '/a/database/vehicles',
          'linked_vehicle_key',
        ).cell.cell_jsx(rowData, tableAdditionalData, tableHooks)
      }

      let editIconJSX = (
        <Tooltip
          title="Edit"
          placement="top"
        >
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
            onClick={() => {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                DatabaseGetCollection(DatabaseRef_ActiveVehicles_Query(res_GCK.clientKey)).then((res) => {
                  if (res.success) {
                    let activeVehicles = Object.values(res.data)
                      .filter((vehicle: any) => vehicle.status === 'active')
                      .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
                    const vehicleOptions = activeVehicles.map((vehicle: any) => ({ key: vehicle.key, value: vehicle.name }))
                    formInputs_LinkedVehicle.linked_vehicle_name.options = vehicleOptions

                    tableHooks.uc_setUserInterface_FormDialogDisplay({
                      display: true,
                      form: {
                        form: {
                          formAdditionalData: { taskKey: rowData.key },
                          formData: { linked_vehicle_name: rowData.linked_vehicle_name, linked_vehicle_key: rowData.linked_vehicle_key },
                          formInputs: formInputs_LinkedVehicle,
                          formOnChange: (_formAdditionalData: any, formData: any) => console.log('Form Data Changed:', formData),
                          formSettings: {},
                          formSubmission: (formSubmittedData: any, formAdditionalData: any, formHooks: any) => {
                            return new Promise((resolve, reject) => {
                              if (formAdditionalData.taskKey != null) {
                                const selectedVehicle = activeVehicles.find((vehicle) => vehicle.key === formSubmittedData.linked_vehicle_name)
                                if (selectedVehicle) {
                                  let updateObject = {
                                    linked_vehicle_name: selectedVehicle.name,
                                    linked_vehicle_key: selectedVehicle.key,
                                    timestamp_last_updated: new Date(),
                                  }
                                  DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, formAdditionalData.taskKey), updateObject)
                                    .then(resolve)
                                    .catch(reject)
                                } else console.error('Selected vehicle not found in active vehicles list.')
                              }
                            })
                          },
                        },
                        dialog: {
                          formDialogHeaderColor: 'info',
                          formDialogHeaderText: `Edit Linked Vehicle - ${rowData.name}`,
                          formDialogIcon: (
                            <Icon
                              type="solid"
                              icon="truck"
                            />
                          ),
                        },
                      },
                    })
                  }
                })
              })
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        </Tooltip>
      )

      // Display both the vehicle link (or "Add Linked Vehicle") and the edit icon
      return (
        <Box>
          {vehicleLinkJSX}
          {editIconJSX}
        </Box>
      )
    },
  },
  header: { header_css: () => '', header_jsx: () => 'Linked Vehicle', header_sort_by: 'linked_vehicle_name' },
}

export const formInputs_LinkedTeam: TsInterface_FormInputs = {
  linked_team_name: {
    data_type: 'string',
    input_type: 'multiple_choice_select', // Dropdown for selecting a single team
    key: 'linked_team_name',
    label: 'Select New Team',
    required: true,
    options: [], // Populated dynamically
  },
}

let linkedTeamCell = {
  cell: {
    cell_css: () => '',
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let teamLinkJSX

      // Display "Add Linked Team" if no linked_team_name exists
      if (!rowData.linked_team_name) {
        teamLinkJSX = <Box className="tw-inline-block tw-opacity-30">{'Add Linked Team'}</Box>
      } else {
        teamLinkJSX = TableCellLink('linked_team_name', 'Linked Team', 'linked_team_name', '/a/database/teams/view', 'linked_team_key').cell.cell_jsx(
          rowData,
          tableAdditionalData,
          tableHooks,
        )
      }

      let editIconJSX = (
        <Tooltip
          title="Edit"
          placement="top"
        >
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
            onClick={() => {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                DatabaseGetCollection(DatabaseRef_SchedulingActiveTeams_Query(res_GCK.clientKey)).then((res) => {
                  if (res.success) {
                    let activeTeams = Object.values(res.data)
                      .filter((team: any) => team.status === 'active')
                      .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
                    const teamOptions = activeTeams.map((team: any) => ({ key: team.key, value: team.name }))
                    formInputs_LinkedTeam.linked_team_name.options = teamOptions

                    tableHooks.uc_setUserInterface_FormDialogDisplay({
                      display: true,
                      form: {
                        form: {
                          formAdditionalData: { taskKey: rowData.key },
                          formData: { linked_team_name: rowData.linked_team_name, linked_team_key: rowData.linked_team_key },
                          formInputs: formInputs_LinkedTeam,
                          formOnChange: (_formAdditionalData: any, formData: any) => console.log('Form Data Changed:', formData),
                          formSettings: {},
                          formSubmission: (formSubmittedData: any, formAdditionalData: any, formHooks: any) => {
                            return new Promise((resolve, reject) => {
                              if (formAdditionalData.taskKey != null) {
                                const selectedTeam = activeTeams.find((team) => team.key === formSubmittedData.linked_team_name)
                                if (selectedTeam) {
                                  let updateObject = {
                                    linked_team_name: selectedTeam.name,
                                    linked_team_key: selectedTeam.key,
                                    timestamp_last_updated: new Date(),
                                  }
                                  DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, formAdditionalData.taskKey), updateObject)
                                    .then(resolve)
                                    .catch(reject)
                                } else console.error('Selected team not found in active teams list.')
                              }
                            })
                          },
                        },
                        dialog: {
                          formDialogHeaderColor: 'info',
                          formDialogHeaderText: `Edit Linked Team - ${rowData.name}`,
                          formDialogIcon: (
                            <Icon
                              type="solid"
                              icon="users-cog"
                            />
                          ),
                        },
                      },
                    })
                  }
                })
              })
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        </Tooltip>
      )

      return (
        <Box>
          {teamLinkJSX}
          {editIconJSX}
        </Box>
      )
    },
  },
  header: { header_css: () => '', header_jsx: () => 'Linked Team', header_sort_by: 'linked_team_name' },
}

export const formInputs_LinkedUser: TsInterface_FormInputs = {
  linked_user_name: {
    data_type: 'string',
    input_type: 'multiple_choice_select', // Dropdown for selecting a single user
    key: 'linked_user_name',
    label: 'Select New User',
    required: true,
    options: [], // This will be populated with active users
  },
}

let linkedUserCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      console.log('Linked User Data:', rowData.linked_user_name)

      // Display "Add Linked User" if no linked_user_name exists
      let userLinkJSX
      if (!rowData.linked_user_name) {
        userLinkJSX = <Box className="tw-inline-block tw-opacity-30">{'Add Linked User'}</Box>
      } else {
        userLinkJSX = TableCellLink(
          'linked_user_name',
          'Linked User', // Display name for the column header
          'linked_user_name',
          '/a/database/users/view', // URL to the user's profile
          'linked_user_key', // The key used in the URL
        ).cell.cell_jsx(rowData, tableAdditionalData, tableHooks)
      }

      // Edit Icon for opening the edit user dialog
      let editIconJSX = (
        <Tooltip
          title="Edit"
          placement="top"
        >
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer tw-align-text-top"
            onClick={() => {
              console.log('Opening edit dialog for linked user')
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_InternalUsers_Query(res_GCK.clientKey))
                    .then((res) => {
                      if (res.success) {
                        let activeUsers = Object.values(res.data).filter((user: any) => user.status === 'active')
                        activeUsers = activeUsers.sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''))

                        // Transform the active user data into options for the dropdown
                        const userOptions = activeUsers.map((user: any) => ({
                          key: user.key,
                          value: user.name, // Display the user's name in the dropdown
                        }))

                        console.log('Fetched Active User Options:', userOptions)

                        // Set the options dynamically in the form inputs
                        formInputs_LinkedUser.linked_user_name.options = userOptions

                        // Open Dialog for selecting a new user with fetched user options
                        tableHooks.uc_setUserInterface_FormDialogDisplay({
                          display: true,
                          form: {
                            form: {
                              formAdditionalData: { taskKey: rowData.key },
                              formData: {
                                linked_user_name: rowData.linked_user_name,
                                linked_user_key: rowData.linked_user_key,
                              },
                              formInputs: formInputs_LinkedUser,
                              formOnChange: (_formAdditionalData: any, formData: any) => {
                                console.log('Form Data Changed:', formData)
                              },
                              formSettings: {},
                              formSubmission: (formSubmittedData: any, formAdditionalData: any, formHooks: any) => {
                                console.log('Form Submitted Data:', formSubmittedData)

                                return new Promise((resolve, reject) => {
                                  if (formAdditionalData.taskKey != null) {
                                    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                                      .then((res_GCK) => {
                                        const selectedUser = activeUsers.find((user: any) => user.key === formSubmittedData.linked_user_name)
                                        if (selectedUser) {
                                          let updateObject = {
                                            linked_user_name: selectedUser.name,
                                            linked_user_key: selectedUser.key,
                                            timestamp_last_updated: new Date(),
                                          }

                                          console.log('Updating task with:', updateObject)

                                          DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, formAdditionalData.taskKey), updateObject)
                                            .then((res_DSMD) => {
                                              console.log('Task successfully updated:', res_DSMD)
                                              resolve(res_DSMD)
                                            })
                                            .catch((rej_DSMD) => {
                                              console.error('Failed to update task:', rej_DSMD)
                                              reject(rej_DSMD)
                                            })
                                        } else {
                                          console.error('Selected user not found in active users list.')
                                        }
                                      })
                                      .catch((rej_GCK) => {
                                        console.error('Failed to get client key:', rej_GCK)
                                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                        reject(rej_GCK)
                                      })
                                  } else {
                                    console.error('Missing task key in formAdditionalData')
                                    formHooks.uc_setUserInterface_ErrorDialogDisplay({
                                      display: true,
                                      error: {
                                        message: 'Failed to update user',
                                        details: 'Missing required parameters',
                                        code: 'ER-LU-TT-LUC-01',
                                      },
                                    })
                                  }
                                })
                              },
                            },
                            dialog: {
                              formDialogHeaderColor: 'info',
                              formDialogHeaderText: `Edit Linked User - ${rowData.name}`,
                              formDialogIcon: (
                                <Icon
                                  type="solid"
                                  icon="user-edit"
                                />
                              ),
                            },
                          },
                        })
                      } else {
                        console.error('Failed to fetch user data:', res.error)
                      }
                    })
                    .catch((error) => {
                      console.error('Error fetching users:', error)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
                    })
                })
                .catch((error) => {
                  console.error('Error fetching client key:', error)
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
                })
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        </Tooltip>
      )

      // Display both the user link (or "Add Linked User") and the edit icon
      return (
        <Box>
          {userLinkJSX}
          {editIconJSX}
        </Box>
      )
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => '',
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => 'Linked User',
    header_sort_by: 'linked_user_name',
  },
}

export const tableColumns_ProjectTasksFull: TsInterface_TableColumns = {
  manage: TableCellManage({
    complete: manageCellComplete,
    edit: editCell,
    edit_team: manageTeamEditCell,
    dispatch: manageCellDispatchTask,
    status: manageCellDelayStatusView,
    redo: manageRedoCell,
    delete: manageCellDelete,
    undelete: manageCellUndelete,
  }),
  // status: statusCell,
  TEMP_elapsed_days: elapsedDaysCell,
  name: taskNameCell,
  associated_owner_name: ownerCell,
  TEMP_prerequisite_tasks: prerequisiteTasksCell,
  TEMP_downstream_tasks: downstreamTaskCells,
  status_progression_notes: taskDelayCell,
  TEMP_timestamp_assigned: TableCellTimestamp('TEMP_timestamp_assigned', rLIB('Start Date'), 'TEMP_timestamp_assigned', 'DD MMM YYYY', false),
  TEMP_timestamp_completed: TableCellTimestamp('TEMP_timestamp_completed', rLIB('Complete Date'), 'TEMP_timestamp_completed', 'DD MMM YYYY - h:mm a', false),
  associated_task_group_name: TableCellBasic('associated_task_group_name', rLIB('Task Group'), 'associated_task_group_name'),
  manage2: TableCellManage({
    complete: manageCellComplete,
    edit: editCell,
    edit_team: manageTeamEditCell,
    dispatch: manageCellDispatchTask,
    status: manageCellDelayStatusView,
    redo: manageRedoCell,
    delete: manageCellDelete,
    undelete: manageCellUndelete,
  }),
}

export const tableColumns_MyProjectsOpenTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    // view: manageCellView,
    complete: manageCellComplete,
    status: manageCellDelayStatusView,
    go_to_project: manageCellGoToProject,
    delete: manageCellDelete,
    dispatch: manageCellDispatchTask,
  }),
  // status: statusCell,
  TEMP_elapsed_days: elapsedDaysCell,
  // task_category: TableCellBasic('task_category', rLIB("Task")_CATEGORY, 'task_category'),
  // id_number: taskIdNumberCell,
  // name: TableCellBasic( "name", rLIB("Task Name"), "name" ),
  name: taskNameCell,
  associated_owner_name: ownerCell,
  TEMP_timestamp_assigned: TableCellTimestamp('TEMP_timestamp_assigned', rLIB('Start Date'), 'TEMP_timestamp_assigned', 'DD MMM YYYY', false),
  taskDueDate: TableCellTimestamp('timestamp_last_scheduled_date_key', 'Due Date', 'timestamp_last_scheduled_date_key', 'DD MMM YYYY', false),
  timestamp_snooze_until: snoozeTimestampCell,
  status_progression_notes: taskDelayCell,
  // linked_user: TableCellLink('linked_user_name', rLIB("Linked User"), 'linked_user_name', '/a/database/users/view', 'linked_user_key'),
  linked_user: linkedUserCell,
  linked_team: linkedTeamCell,
  linked_vehicle: linkedVehicleCell,
  manage2: TableCellManage({
    // view: manageCellView,
    complete: manageCellComplete,
    status: manageCellDelayStatusView,
    go_to_project: manageCellGoToProject,
    delete: manageCellDelete,
    dispatch: manageCellDispatchTask,
  }),
}

export const tableColumns_AllProjectsOpenTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    // view: manageCellView,
    complete: manageCellComplete,
    status: manageCellDelayStatusView,
    go_to_project: manageCellGoToProject,
    dispatch: manageCellDispatchTask,
  }),
  name: taskNameCell,
  associated_owner_name: ownerCell,
  TEMP_timestamp_assigned: TableCellTimestamp('TEMP_timestamp_assigned', rLIB('Start Date'), 'TEMP_timestamp_assigned', 'DD MMM YYYY', false),
  taskDueDate: TableCellTimestamp('timestamp_last_scheduled_date_key', 'Due Date', 'timestamp_last_scheduled_date_key', 'DD MMM YYYY', false),
  timestamp_snooze_until: snoozeTimestampCell,
  status_progression_notes: taskDelayCell,
  // linked_user: TableCellLink('linked_user_name', rLIB("Linked User"), 'linked_user_name', '/a/database/users/view', 'linked_user_key'),
  linked_user: linkedUserCell,
  linked_team: linkedTeamCell,
  linked_vehicle: linkedVehicleCell,
  manage2: TableCellManage({
    // view: manageCellView,
    complete: manageCellComplete,
    status: manageCellDelayStatusView,
    go_to_project: manageCellGoToProject,
    // delete: manageCellDelete,
    dispatch: manageCellDispatchTask,
  }),
}

export const tableColumns_ProjectRecentlyClosedTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: manageCellView,
    go_to_project: manageCellGoToProject,
  }),
  // status: statusCell,
  TEMP_elapsed_days: elapsedDaysCell,
  // task_category: TableCellBasic('task_category', rLIB("Task")_CATEGORY, 'task_category'),
  name: taskNameCell,
  associated_owner_name: ownerCell,
  // TEMP_timestamp_assigned: TableCellTimestamp('TEMP_timestamp_assigned', rLIB("Start Date"), 'TEMP_timestamp_assigned', 'DD MMM YYYY - h:mm a', false),
  linked_user: TableCellBasic('linked_user_name', rLIB('Linked User'), 'linked_user_name'),
  linked_team: TableCellBasic('linked_team_name', rLIB('Linked Team'), 'linked_team_name'),
  linked_vehicle: TableCellBasic('linked_vehicle_name', rLIB('Linked Vehicle'), 'linked_vehicle_name'),
  manage2: TableCellManage({
    view: manageCellView,
    go_to_project: manageCellGoToProject,
  }),
}

// Sales
export const tableColumns_SalesOpenTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    complete: manageCellComplete,
    go_to_project: manageCellGoToSalesProject,
  }),
  status: statusCell,
  name: taskNameCell,
  TEMP_timestamp_assigned: TableCellTimestamp('TEMP_timestamp_assigned', rLIB('Assigned Date'), 'TEMP_timestamp_assigned', 'DD MMM YYYY - h:mm a', false),
}

export const tableColumns_SalesRecentlyClosedTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: manageCellView,
    go_to_project: manageCellGoToSalesProject,
  }),
  status: statusCell,
  name: taskNameCell,
  TEMP_timestamp_assigned: TableCellTimestamp('TEMP_timestamp_assigned', rLIB('Assigned Date'), 'TEMP_timestamp_assigned', 'DD MMM YYYY - h:mm a', false),
  TEMP_timestamp_completed: TableCellTimestamp('TEMP_timestamp_completed', rLIB('Complete Date'), 'TEMP_timestamp_completed', 'DD MMM YYYY - h:mm a', false),
}

export const tableColumns_SalesTasksFull: TsInterface_TableColumns = {
  status: statusCell,
  TEMP_elapsed_days: elapsedDaysCell,
  name: taskNameCell,
  associated_owner_name: ownerCell,
  TEMP_prerequisite_tasks: prerequisiteTasksCell,
  TEMP_downstream_tasks: downstreamTaskCells,
  // status_progression_notes: taskDelayCell,
  TEMP_timestamp_assigned: TableCellTimestamp('TEMP_timestamp_assigned', rLIB('Start Date'), 'TEMP_timestamp_assigned', 'DD MMM YYYY - h:mm a', false),
  TEMP_timestamp_completed: TableCellTimestamp('TEMP_timestamp_completed', rLIB('Complete Date'), 'TEMP_timestamp_completed', 'DD MMM YYYY - h:mm a', false),
}

// Groups
export const tableColumns_ProjectTaskGroups: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Task Group'), 'name'),
  taskBreakdownBar2: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-w-10'
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cssClassName = ''
        return cssClassName
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let taskColorObject: TsInterface_UnspecifiedObject = getProp(rowData, 'task_color_counts', { total: 0 })
        cellJSX = <Box>{rJSX_TaskBreakdownBarFullColors(taskColorObject)}</Box>
        return cellJSX
      },
    },
  },
}

// SubK
export const tableColumns_SubKOpenTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    complete: manageCellComplete,
    go_to_project: manageCellGoToSubKProject,
  }),
  status: statusCell,
  name: taskNameCell,
  TEMP_timestamp_assigned: TableCellTimestamp('TEMP_timestamp_assigned', rLIB('Assigned Date'), 'TEMP_timestamp_assigned', 'DD MMM YYYY - h:mm a', false),
}

export const tableColumns_SubKRecentlyClosedTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: manageCellView,
    go_to_project: manageCellGoToSubKProject,
  }),
  status: statusCell,
  name: taskNameCell,
  TEMP_timestamp_assigned: TableCellTimestamp('TEMP_timestamp_assigned', rLIB('Assigned Date'), 'TEMP_timestamp_assigned', 'DD MMM YYYY - h:mm a', false),
  TEMP_timestamp_completed: TableCellTimestamp('TEMP_timestamp_completed', rLIB('Complete Date'), 'TEMP_timestamp_completed', 'DD MMM YYYY - h:mm a', false),
}
