///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Link, Typography } from '@mui/material/'

import { useContext, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_Jurisdictions_Collection,
  DatabaseRef_Jurisdictions_Search,
  DatabaseRef_Jurisdiction_Document,
} from 'rfbp_aux/services/database_endpoints/directory/jurisdictions'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import {
  TableCellBasic,
  TableCellManage,
  TableCellPhone,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumn,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsType_TableSortBy,
} from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseJurisdictionsListPage']['key']

export const TableCellBoolean = (propKey: string, propName: string | JSX.Element, sortBy: TsType_TableSortBy): TsInterface_TableColumn => {
  let booleanCell = {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{propName}</>
      },
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Check if the value is a boolean and render "Yes" or "No"
        const value = rowData[propKey]
        return <>{value === true ? 'Yes' : value === false ? 'No' : ''}</>
      },
    },
  }
  return booleanCell
}

export const TableCellURL = (propKey: string, propName: string | JSX.Element, sortBy: TsType_TableSortBy): TsInterface_TableColumn => {
  let urlCell = {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{propName}</>
      },
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const urlValue = rowData[propKey] ? String(rowData[propKey]) : '' // Convert to string and handle null or undefined

        return urlValue ? (
          <Link
            href={urlValue.startsWith('http') ? urlValue : `http://${urlValue}`} // Ensure it has http:// or https://
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
            color="primary"
          >
            {urlValue}
          </Link>
        ) : (
          <span>No URL</span> // Display fallback text if URL is not available
        )
      },
    },
  }
  return urlCell
}

// Contact Information Section
const formInputs_ContactInfo: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: 'Jurisdiction Name',
    placeholder: 'East Peoria, Elgin, New Berlin',
    required: true,
  },

  state: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'state',
    label: 'State',
    required: true,
    options: [
      { key: 'IL', label: 'IL', value: 'IL' },
      { key: 'TX', label: 'TX', value: 'TX' },
    ],
  },
  type: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'type',
    label: 'Type',
    required: true,
    options: [
      { key: 'city', label: 'City', value: 'City' },
      { key: 'village', label: 'Village', value: 'Village' },
      { key: 'county', label: 'County', value: 'County' },
      { key: 'town', label: 'Town', value: 'Town' },
      { key: 'township', label: 'Township', value: 'Township' },
    ],
  },
  contact_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'contact_name',
    label: 'Contact Name',
  },
  address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'address',
    label: 'Address',
  },
  phone_number: {
    data_type: 'string',
    input_type: 'phone_number_usa',
    key: 'phone_number',
    label: 'Phone Number',
  },
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: 'Email',
  },
  website: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'website',
    label: 'Website',
  },
}

// Design Guidance Section
const formInputs_DesignGuidance: TsInterface_FormInputs = {
  eave_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'eave_setback',
    label: 'Eave Setback',
  },
  valley_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'valley_setback',
    label: 'Valley Setback',
  },
  ridge_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'ridge_setback',
    label: 'Ridge Setback',
  },
  rake_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'rake_setback',
    label: 'Rake Setback',
  },
  hip_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'hip_setback',
    label: 'Hip Setback',
  },
  snow_load: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'snow_load',
    label: 'Snow Load',
  },
  wind_load: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'wind_load',
    label: 'Wind Load',
  },
  code_years: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'code_years',
    label: 'Code Years',
  },
  grounding_requirement_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'grounding_requirement_notes',
    label: 'Grounding Requirement Notes',
    placeholder: 'Cold Water Ground Supplemental Ground Rod',
  },
  disconnect_requirement_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'disconnect_requirement_notes',
    label: 'Disconnect Requirement Notes',
  },
  wire_sizing_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'wire_sizing_notes',
    label: 'Wire Sizing Notes',
    placeholder: '8 AWG ground throughout',
  },
  conduit_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'conduit_notes',
    label: 'Conduit Notes',
  },
  placard_requirement_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'placard_requirement_notes',
    label: 'Placard Requirement Notes',
  },
}

const submissionModesOptions = [
  { key: 'online_portal', value: 'Online Portal' },
  { key: 'mail_in', value: 'Mail-in' },
  { key: 'counter', value: 'Counter' },
]

// Permit Submission Section
const formInputs_PermitSubmissions: TsInterface_FormInputs = {
  permit_fees: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'permit_fees',
    label: 'Permit Fees',
  },
  estimated_turnaround: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'estimated_turnaround',
    label: 'Estimated Turnaround time (business days)',
  },
  attachments_requirement: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'attachments_requirement',
    label: 'Attachments Requirement',
    options: [
      { key: 'permit_sheet', label: 'Permit application sheet', value: 'Permit application sheet' },
      { key: 'sld', label: 'SLD', value: 'SLD' },
      { key: 'three_ld', label: '3LD', value: '3LD' },
      { key: 'elevation', label: 'Elevation', value: 'Elevation' },
      { key: 'equipment_docs', label: 'Equipment Spec Documents', value: 'Equipment Spec Documents' },
    ],
  },

  submission_modes: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'submission_modes',
    label: 'Submission Modes',
    options: objectToArray(submissionModesOptions),
  },
  solarapp_enabled: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'solarapp_enabled',
    label: 'SolarApp enabled',
  },
  portal_website_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'portal_website_address',
    label: 'Portal Website Address',
  },
  solar_application_link: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'solar_application_link',
    label: 'Solar Application Link',
  },
  hu_application_link: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'hu_application_link',
    label: 'HU Application Link',
  },
  payment_modes_accepted: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'payment_modes_accepted',
    label: 'Payment Modes Accepted',
    options: [
      { key: 'cash', label: 'Cash', value: 'Cash' },
      { key: 'check', label: 'Check', value: 'Check' },
      { key: 'card', label: 'Card', value: 'Card' },
      { key: 'ach', label: 'ACH', value: 'ACH' },
    ],
  },
  pe_stamp_requirement: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'pe_stamp_requirement',
    label: 'PE Stamp Requirement',
    placeholder: 'When do they want PE stamps?',
  },
  ee_stamp_requirements: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'ee_stamp_requirements',
    label: 'EE Stamp Requirements',
    options: [
      { key: 'digital_stamp', label: 'Digital Stamp', value: 'Digital Stamp' },
      { key: 'wet_stamp', label: 'Wet Stamp', value: 'Wet Stamp' },
      { key: 'always_required', label: 'Always Required', value: 'Always Required' },
      { key: 'required_for_battery_installs', label: 'Required for Battery Installs', value: 'Required for Battery Installs' },
    ],
  },
  as_built_requirements: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'as_built_requirements',
    label: 'As Built Requirements',
  },
  hoa_pre_approval_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'hoa_pre_approval_required',
    label: 'HOA Pre-Approval Required',
  },
}

// Inspections Section
const formInputs_Inspections: TsInterface_FormInputs = {
  inspection_type: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'inspection_type',
    label: 'Inspection Type',
    options: [
      { key: 'no_inspection', label: 'No inspection', value: 'No inspection' },
      { key: 'in_person', label: 'In-person', value: 'In-person' },
      { key: 'virtual', label: 'Virtual', value: 'Virtual' },
      { key: 'by_utility', label: 'By utility', value: 'By utility' },
    ],
  },
  solar_inspection_scheduling_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'solar_inspection_scheduling_notes',
    label: 'Solar Inspection Scheduling Notes',
  },
  hu_inspection_scheduling_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'hu_inspection_scheduling_notes',
    label: 'HU Inspection Scheduling Notes',
    placeholder: 'Call to schedule',
  },
}

// ETW Permit Section
const formInputs_ETWPermit: TsInterface_FormInputs = {
  permit_holder: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'permit_holder',
    label: 'Permit Holder',
  },
  permit_renewal_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'permit_renewal_date',
    label: 'Permit Renewal Date',
  },
  coi_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'coi_required',
    label: 'COI Required',
  },
  registration_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'registration_required',
    label: 'Registration Required',
  },
  applied_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'applied_date',
    label: 'Applied Date',
  },
  approved_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'approved_date',
    label: 'Approved Date',
  },
}

// Bringing all sections together into one final form configuration
const formInputs_JurisdictionForm = {
  ...formInputs_ContactInfo,
  ...formInputs_DesignGuidance,
  ...formInputs_PermitSubmissions,
  ...formInputs_Inspections,
  ...formInputs_ETWPermit,
}

// Table
const tableColumns_Jurisdictions: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseJurisdictionViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{'Delete'}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: 'Delete Jurisdiction', // Custom header for jurisdiction deletion
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: 'Delete',
              text: 'Are you sure you want to delete this jurisdiction?', // Custom confirmation message
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    status: 'deleted',
                  }
                  // Update the jurisdiction document with the new status
                  DatabaseSetMergeDocument(DatabaseRef_Jurisdiction_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then((res_DSMD) => {
                      // Optional: Add logging here
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),

  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'Name'
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return (
          <Box
            className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
            sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
            onClick={(event) => {
              if (rowData.key != null) {
                onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseJurisdictionViewPage.url(rowData.key as string))
              }
            }}
          >
            {rowData.name}
          </Box>
        )
      },
    },
  },

  phone: TableCellPhone('phone_number', rLIB('Phone'), 'phone_number'),
  email: TableCellBasic('email', rLIB('Email'), 'email'),
  website: TableCellURL('website', 'Website', 'website'),
  state: TableCellBasic('state', 'State', 'state'),

  permit_fees: TableCellCurrency('permit_fees', 'Permit Fees', 'permit_fees'),

  solar_application_link: TableCellURL('solar_application_link', 'Solar Application Link', 'solar_application_link'),
}

const tableSettings_Jurisdictions: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: true,
  search_settings_database: {
    search_type: 'firebase',
    search_no_data_message: <Typography>{rLIB('No jurisdiction found by search')}</Typography>,

    search_property_options: [{ key: 'name', value: rLIB('Jurisdiction Name') }],
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Hooks - useContext, useState, useReducer, other
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  useEffect(() => {
    document.title = 'Jurisdictions'
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const tableDatabaseEndpoint_Jurisdictions = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_Jurisdictions_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const rJSX_NewJurisdictionButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createJurisdiction(uc_setUserInterface_FormDialogDisplay)
        }}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Jurisdiction')}
      </Button>
    )
    return buttonJSX
  }

  const createJurisdiction = (uc_setUserInterface_FormDialogDisplay: any): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_JurisdictionForm,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  const clientKey = res_GCK.clientKey

                  // Generate the custom document ID in the format state_type_name
                  const documentId = `${formSubmittedData.state}_${formSubmittedData.type}_${formSubmittedData.name}`.toLowerCase().replace(/\s+/g, '_')

                  // Set the key field in formSubmittedData to match the custom document ID
                  formSubmittedData.key = documentId

                  // Add a default status to the submitted data
                  formSubmittedData.status = 'active'

                  // Reference the collection and document with the custom ID
                  const documentRef = DatabaseRef_Jurisdiction_Document(clientKey, documentId)

                  // Use `DatabaseSetMergeDocument` to save data with the custom ID
                  DatabaseSetMergeDocument(documentRef, formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      // Redirect using the custom document ID
                      directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseJurisdictionViewPage.url(documentId))
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            }).catch((error) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Create Jurisdiction')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableSearchEndpoint_Jurisdictions = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_Jurisdictions.rows_per_page
    if (limit === null) {
      limit = 20
    }
    return DatabaseRef_Jurisdictions_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  const rJSX_ImportJurisdictionsButton = (shrink: boolean): JSX.Element => (
    <BasicImportButtonAndDialog
      importAdditionalData={{}}
      importButtonColor={'info'}
      importButtonShrink={shrink}
      importButtonText={rLIB('Import Jurisdictions')}
      importDialogHeader={rLIB('Import Jurisdictions')}
      importMappingOptions={{
        name: { key: 'name', required: true, label: rLIB('Name'), automatch_properties: ['Name'] },
      }}
      importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
        return new Promise((resolve, reject) => {
          try {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                for (let loopRowKey in spreadsheetData) {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_Jurisdiction_Document(res_GCK.clientKey as string, keyFromString(spreadsheetData[loopRowKey].name)),
                    data: { name: spreadsheetData[loopRowKey].name, status: 'active', key: keyFromString(spreadsheetData[loopRowKey].name) },
                  })
                }
                DatabaseStagedBatchUpdate(updateArray)
                  .then((res_DSBU) => {
                    resolve(res_DSBU)
                  })
                  .catch((rej_DSBU) => {
                    console.error('Batch Update Error:', rej_DSBU)
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                    reject(rej_DSBU)
                  })
              })
              .catch((rej_GCK) => {
                console.error('Client Key Error:', rej_GCK)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          } catch (error) {
            console.error('Error during import submission:', error)
            reject(error)
          }
        })
      }}
    />
  )

  const rJSX_JurisdictionTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (tableSettings_Jurisdictions.search_settings_database == null) {
        tableSettings_Jurisdictions.search_settings_database = { search_type: 'firebase' }
      }
      tableSettings_Jurisdictions.search_settings_database.search_endpoint = tableSearchEndpoint_Jurisdictions
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{ visible_active_tasks: {} }}
              tableColumns={tableColumns_Jurisdictions}
              tableDatabaseEndpoint={tableDatabaseEndpoint_Jurisdictions}
              tableSettings={tableSettings_Jurisdictions}
            />
          </Card>
        </Box>
      )
    }
    return tableJSX
  }
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Jurisdiction')}
        pageKey={pageKey}
        content={
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>{[rJSX_NewJurisdictionButton(), rJSX_ImportJurisdictionsButton(true)]}</Box>
            </Box>
            <Box className="tw-mt-2">{rJSX_JurisdictionTable()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
