///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_IncidentReports_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main')
}

export const DatabaseRef_IncidentReport_Document = (clientKey: string, incidentReportKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey)
}

export const DatabaseRef_IncidentReportTypes_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'types')
}

export const DatabaseRef_IncidentReportTypes_Document = (clientKey: string, incidentTypeKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'types', incidentTypeKey)
}

// Files
export const DatabaseRef_IncidentReportFiles_Collection = (clientKey: string, incidentReportKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'files')
}

export const DatabaseRef_IncidentReportFile_Document = (clientKey: string, incidentReportKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_IncidentReportForumThreads_Collection = (clientKey: string, incidentReportKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'forum')
}

export const DatabaseRef_IncidentReportForumThread_Document = (
  clientKey: string,
  incidentReportKey: string,
  threadKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'forum', threadKey)
}

export const DatabaseRef_IncidentReportForumMessages_Collection = (
  clientKey: string,
  incidentReportKey: string,
  threadKey: string,
): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_IncidentReportForumMessage_Document = (
  clientKey: string,
  incidentReportKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'forum', threadKey, 'messages', messageKey)
}

export const DatabaseRef_ActiveIncidentTypes_Query = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'types') as CollectionReference<DocumentData>
}

export const DatabaseRef_AllActiveIncidentTypes_Query = (clientKey: string, status: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: status }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'types'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}
