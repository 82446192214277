///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_ProjectHomeDetails: TsInterface_FormInputs = {
  home_roof_type: {
    key: 'home_roof_type',
    label: rLIB('Roof Type'),
    input_type: 'multiple_choice_radio',
    required: false,
    data_type: 'boolean',
    options: [
      { key: 'CalPak', value: 'CalPak' },
      { key: 'Clay Tile', value: 'Clay Tile' },
      { key: 'Comp Shingle', value: 'Comp Shingle' },
      { key: 'Concrete Tile', value: 'Concrete Tile' },
      { key: 'Corrugated Metal', value: 'Corrugated Metal' },
      { key: 'Ground Mount', value: 'Ground Mount' },
      { key: 'Metal Shingle', value: 'Metal Shingle' },
      { key: 'Other', value: 'Other' },
      { key: 'Standing Seam - Metal Roof', value: 'Standing Seam - Metal Roof' },
      { key: 'Standing Seam Metal', value: 'Standing Seam Metal' },
      { key: 'Unknown', value: 'Unknown' },
    ],
  },
}

export const formInputs_ProjectHomeDetails_v2: TsInterface_FormInputs = {
  home_roof_type: {
    key: 'home_roof_type',
    label: rLIB('Roof Type'),
    input_type: 'multiple_choice_radio',
    required: false,
    data_type: 'boolean',
    options: [
      { key: 'clay_tile', value: 'Clay Tile' },
      { key: 'asphalt_shingle', value: 'Asphalt Shingle' },
      { key: 'corrugated_metal', value: 'Corrugated Metal' },
      { key: 'other', value: 'Other' },
      { key: 'standing_seam_metal', value: 'Standing Seam Metal' },
    ],
  },
}
