///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Services contain business logic that is completely abstracted from user interfaces

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Avatar, Stack, Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { returnTaskRows } from 'app/models/tasks'
import { findRecursiveTasks, returnTaskPrerequisiteAnalysisObject } from 'app/models/tasks/task_workflow_services'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveTaskBlueprints_Query } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import {
  DatabaseRef_BaseInvoicesForProject_Query,
  DatabaseRef_BaseInvoicesForTask_Query,
  DatabaseRef_ProjectBaseInvoice_Document,
} from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import { DatabaseRef_MessageThread_Document } from 'rfbp_aux/services/database_endpoints/operations/messages'
import {
  DatabaseRef_ProjectIdNumber_Query,
  DatabaseRef_ProjectLogs_Document,
  DatabaseRef_ProjectRoleAssignments_Document,
  DatabaseRef_ProjectTaskWorkflow_Document,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  DatabaseRef_AllProjectTasksFromDeletedWorkflow_Query,
  DatabaseRef_AllProjectTasksFromWorkflow_Query,
  DatabaseRef_AllProjectTasks_Query,
  DatabaseRef_ProjectTasksForPrerequisiteTask_Query,
  DatabaseRef_ProjectTasksForSpecificOwnerRole_Query,
  DatabaseRef_Task_Document,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatabaseRef_PowerwallOrder_Document } from 'rfbp_aux/services/database_endpoints/webstore/orders'
import { TsInterface_ChatThreads } from 'rfbp_core/components/chat'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TsInterface_TableData } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import {
  arrayToObject,
  cloneObjectWithoutReference,
  dynamicSort,
  generateRandomString,
  getProp,
  mergeTwoObjects,
  millisecondsPerDay,
  objectToArray,
  returnStandardizedUSPhoneNumber,
  returnTimestampFromUnknownDateFormat,
} from 'rfbp_core/services/helper_functions'
import { TsInterface_PromiseArray, TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

type TsType_TaskTableFilterOptions =
  | 'all'
  | 'active_tasks'
  | 'completed_tasks'
  | 'future_tasks'
  | 'deleted'
  | 'not_deleted'
  | 'user_tasks'
  | 'unassigned'
  | 'invalid_prereq_data_structure'

///////////////////////////////
// Variables
///////////////////////////////

// Forms
const formInputs_ProjectStatus: TsInterface_FormInputs = {
  status: {
    key: 'status',
    label: rLIB('Project Status'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [], // Handled Dynamically
  },
  create_completion_cleanup_tasks: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'create_completion_cleanup_tasks',
    label: rLIB('Create Completion Cleanup Tasks'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status',
            comparator: '==',
            value: 'completed',
          },
        ],
      },
    },
  },
  create_cancellation_cleanup_tasks: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'create_cancellation_cleanup_tasks',
    label: rLIB('Create Cancellation Cleanup Tasks'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status',
            comparator: '==',
            value: 'cancelled',
          },
        ],
      },
    },
  },
  undelete_incomplete_main_tasks: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'undelete_incomplete_main_tasks',
    label: rLIB('Undelete Incomplete Tasks'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status',
            comparator: '==',
            value: 'active',
          },
        ],
      },
    },
  },
  delete_completion_cleanup_tasks: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'delete_completion_cleanup_tasks',
    label: rLIB('Delete Completion Cleanup Tasks'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status',
            comparator: '!=',
            value: 'completed',
          },
        ],
      },
    },
  },
  delete_cancellation_cleanup_tasks: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'delete_cancellation_cleanup_tasks',
    label: rLIB('Delete Cancellation Cleanup Tasks'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status',
            comparator: '!=',
            value: 'cancelled',
          },
        ],
      },
    },
  },
  delete_incomplete_main_tasks: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'delete_incomplete_main_tasks',
    label: rLIB('Delete Incomplete Tasks'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'or',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'status',
            comparator: '!=',
            value: 'active',
          },
        ],
      },
    },
  },
}

// CSS
let progressBarCss: string =
  `
		.project_progress_bar_unassigned {
			background-image: repeating-linear-gradient(
				45deg,
				` +
  themeVariables.gray_600 +
  `,
				` +
  themeVariables.gray_600 +
  ` 4px,
				` +
  themeVariables.gray_500 +
  ` 4px,
				` +
  themeVariables.gray_500 +
  ` 8px
			)
		}
		.project_progress_bar_complete_blue_green {
			background: linear-gradient(to bottom, ` +
  themeVariables.info_light +
  ` 75%, ` +
  themeVariables.success_main +
  ` 75%);
			background-repeat: no-repeat;
		}
		.project_progress_bar_complete_blue_yellow {
			background: linear-gradient(to bottom, ` +
  themeVariables.info_main +
  ` 75%, ` +
  themeVariables.warning_main +
  ` 75%);
			background-repeat: no-repeat;
		}
		.project_progress_bar_complete_blue_red {
			background: linear-gradient(to bottom, ` +
  themeVariables.info_dark +
  ` 75%, ` +
  themeVariables.error_main +
  ` 75%);
			background-repeat: no-repeat;
		}
	`

///////////////////////////////
// Functions
///////////////////////////////

const generateReadableIDForTask = (task: TsInterface_UnspecifiedObject, idNumber: number): string => {
  let readableID = ''
  let codeNumber
  let codeText
  // Last 3 of ID Number
  if (idNumber != null) {
    codeNumber = (idNumber % 1000).toString()
    if (codeNumber.length === 0) {
      codeNumber = '000'
    } else if (codeNumber.length === 1) {
      codeNumber = '00' + codeNumber
    } else if (codeNumber.length === 2) {
      codeNumber = '0' + codeNumber
    }
  } else {
    codeNumber = '000'
  }
  // Acronym taken from name
  if (task.abbreviation != null && task.abbreviation !== '' && task.length !== 0) {
    if (task.abbreviation.length === 1) {
      codeText = '000' + task.abbreviation
    } else if (task.abbreviation.length === 2) {
      codeText = '00' + task.abbreviation
    } else if (task.abbreviation.length === 3) {
      codeText = '0' + task.abbreviation
    } else if (task.abbreviation.length === 4) {
      codeText = task.abbreviation
    } else {
      codeText = task.abbreviation.substring(0, 4)
    }
  } else if (task.name != null && task.name !== '') {
    let nameWordsArray = task.name.replace(/\W/g, '').toUpperCase().split(' ')
    codeText = nameWordsArray[0].substring(0, 4)
    if (codeText.length === 3) {
      codeText += '000'
    } else if (codeText.length === 2) {
      codeText += '00'
    } else if (codeText.length === 1) {
      codeText += '0'
    } else if (codeText.length === 0) {
      codeText = 'UNKN'
    }
  }
  readableID = codeNumber + codeText
  return readableID
}

const sortTasksByPrerequisites = (objects: TsInterface_UnspecifiedObject[], recursiveTasks: TsInterface_UnspecifiedObject | null, debugSource: string[]) => {
  const sortedObjects: TsInterface_UnspecifiedObject[] = []
  debugSource.push('sortTasksByPrerequisites')
  let sortedTaskRows = returnTaskRows(objects, {}, recursiveTasks, debugSource)
  for (let loopRowKey in sortedTaskRows) {
    let loopRow = sortedTaskRows[loopRowKey]
    if (loopRow != null && loopRow['tasks'] != null) {
      for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
        let loopTask = loopRow['tasks'][loopTaskIndex]
        sortedObjects.push(loopTask)
      }
    }
  }
  return sortedObjects
}

///////////////////////////////
// Exports
///////////////////////////////

// Instantiate Powerwall Project
export const instantiatePowerwallProject = (clientKey: string, powerwallOrderKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && powerwallOrderKey != null) {
      DatabaseGetDocument(DatabaseRef_PowerwallOrder_Document(clientKey, powerwallOrderKey))
        .then((res_DGD) => {
          let powerwallOrderData = res_DGD.data
          let addressComponents = getProp(powerwallOrderData, 'address_components', {})
          let projectKey = 'pw_' + powerwallOrderKey + '_' + new Date().getTime().toString()
          // Update Object
          let projectUpdateObject = {
            key: projectKey,
            status: 'unassigned',
            status_complete: false,
            // id_number "210T-00000000",
            associated_opportunity_id: null,
            associated_proposal_of_record_id: null,
            status_service_contract_status: null,
            status_sales_opportunity_stage: null,
            associated_sales_partner_key: 'tesla',
            associated_sales_partner_name: 'Tesla',
            associated_vendor_name: 'Tesla',
            associated_product_name: 'Powerwall',
            associated_customer_name: getProp(powerwallOrderData, 'first_name', '') + ' ' + getProp(powerwallOrderData, 'last_name', ''),
            associated_customer_phone: getProp(powerwallOrderData, 'phone', null),
            associated_customer_phone_standardized: returnStandardizedUSPhoneNumber(getProp(powerwallOrderData, 'phone', null)),
            associated_customer_key: powerwallOrderKey,
            associated_customer_email: getProp(powerwallOrderData, 'email', null),
            associated_sales_channel: null,
            associated_utility_company_name: getProp(powerwallOrderData, 'utility_company', null),
            associated_hoa_name: null,
            associated_sales_rep_name: 'Tesla Powerwall',
            associated_sales_rep_email: null,
            associated_sales_rep_team_name: null,
            associated_build_partner_name: null,
            associated_install_branch_name: null,
            timestamp_customer_signed: null,
            timestamp_customer_proposal_approved: null,
            timestamp_install_completed: null,
            timestamp_system_activation: null,
            timestamp_project_cancelled: null,
            timestamp_pto: null,
            timestamp_salesforce_last_modified: null,
            location_zip: getProp(addressComponents, 'postal_code', null),
            location_city: getProp(addressComponents, 'locality', null),
            location_county: getProp(addressComponents, 'administrative_area_level_2', null),
            location_address: getProp(addressComponents, 'street_number', '') + ' ' + getProp(addressComponents, 'route', ''),
            location_state: getProp(addressComponents, 'administrative_area_level_1', null),
            location_jurisdiction: null,
            system_panel_manufacturer: null,
            system_panel_model: null,
            system_panel_quantity: null,
            system_storage_manufacturer: 'Tesla',
            system_storage_model: 'Powerwall',
            system_storage_quantity: parseInt(getProp(powerwallOrderData, 'number_of_powerwalls_to_install', 0)),
            system_storage_total_kwh: null,
            system_inverter_manufacturer: null,
            system_inverter_model: null,
            system_inverter_quantity: null,
            system_size_dc: null,
            system_usage_offset: null,
          }
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: projectUpdateObject },
            { type: 'setMerge', ref: DatabaseRef_PowerwallOrder_Document(clientKey, powerwallOrderKey), data: { associated_project_key: projectKey } },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        })
        .catch((rej_DGD) => {
          reject(rej_DGD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to instantiate powerwall project'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PT-IPP-XX',
        },
      })
    }
  })
}

export const archivePowerwallProject = (clientKey: string, powerwallOrderKey: string, currentOrderStatus: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && powerwallOrderKey != null) {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        {
          type: 'setMerge',
          ref: DatabaseRef_PowerwallOrder_Document(clientKey, powerwallOrderKey),
          data: { status: 'archived', status_before_archive: currentOrderStatus },
        },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to archive powerwall project'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-APP-01',
        },
      })
    }
  })
}

export const unarchivePowerwallProject = (clientKey: string, powerwallOrderKey: string, newOrderStatus: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (newOrderStatus == null) {
      newOrderStatus = 'new'
    }
    if (clientKey != null && powerwallOrderKey != null) {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        {
          type: 'setMerge',
          ref: DatabaseRef_PowerwallOrder_Document(clientKey, powerwallOrderKey),
          data: { status: newOrderStatus },
        },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to unarchive powerwall project'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-UPP-01',
        },
      })
    }
  })
}

// Job Code
function isValidEntry(inputString: string) {
  let pattern = /^\d{3}[A-Za-z]{1}-\d{3}[A-Za-z]{4}$/
  return pattern.test(inputString)
}

export const openProjectIDGenerationDialog = (
  clientKey: string,
  projectKey: string,
  project: TsInterface_UnspecifiedObject,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_PromptDialogDisplay: any,
): void => {
  let suggestedProjectCode = generateReadableIDForProject(project)
  uc_setUserInterface_PromptDialogDisplay({
    display: true,
    prompt: {
      color: 'error',
      confirm_text: rLIB('Submit'),
      default_value: suggestedProjectCode,
      header: rLIB('Generate Project ID'),
      icon: (
        <Icon
          icon="barcode"
          type="solid"
        />
      ),
      input_label: rLIB('Project ID'),
      input_type: 'text',
      text: rLIB('Generate 7 Digit Project ID'),
      submit_callback: (promptValue: string) => {
        return new Promise((resolve, reject) => {
          if (promptValue.length !== 12 || isValidEntry(promptValue) === false) {
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: {
                message: rLIB('Failed to add ID to project'),
                details: rLIB('Project IDs must be exactly 13 characters long of the form NNNS-NNNSSSS where N is a number and S is a string character.'),
                code: 'ER-D-PS-UTWOP-01',
              },
            })
            resolve({ close_dialog: false })
          } else {
            checkForDuplicateProjectCodes(clientKey, promptValue)
              .then((res_CFDPC: any) => {
                if (res_CFDPC.matches === 0) {
                  let updateObject = {
                    id_number: promptValue,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(clientKey, projectKey), updateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      resolve({ close_dialog: false })
                    })
                } else {
                  uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: {
                      message: rLIB('Failed to add ID to project'),
                      details: (
                        <>
                          {rLIB('This project ID has already been used.')} {rLIB('Please change the proposed project ID and resubmit')}
                        </>
                      ),
                      code: 'ER-D-PS-UTWOP-02',
                    },
                  })
                  resolve({ close_dialog: false })
                }
              })
              .catch((rej_CFDPC) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFDPC.error })
                resolve({ close_dialog: false })
              })
          }
        })
      },
    },
  })
}

export const logProjectEvent = (clientKey: string, projectKey: string, userKey: string, userName: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null) {
      let logTimestamp = new Date().getTime()
      let logKey = logTimestamp.toString() + '_' + generateRandomString(6, null)
      let logUpdateObject = {
        timestamp: logTimestamp,
        associated_user_key: userKey,
        associated_user_name: userName,
        event: 'create',
      }
      DatabaseSetMergeDocument(DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), logUpdateObject)
        .then((res_DSMD) => {
          resolve(res_DSMD)
        })
        .catch((rej_DSMD) => {
          reject(rej_DSMD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to log project event'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-LPE-01',
        },
      })
    }
  })
}

export const generateReadableIDForProject = (project: TsInterface_UnspecifiedObject): string | null => {
  let projectCode = null
  let projectPrefix = ''
  let projectNumber = ''
  let projectString = ''
  try {
    // Prefix
    if (project.associated_sales_partner_key === 'tesla') {
      projectPrefix = '210T-'
    } else {
      projectPrefix = '0000-'
    }
    // Number
    if (project != null && project.location_address != null) {
      let cleanedAddress = project.location_address.replace(/\W/g, '').toUpperCase()
      projectNumber = cleanedAddress.substring(0, 3).toString()
    } else {
      projectNumber = generateRandomString(3, '0123456789')
    }
    if (project != null && project.associated_customer_name != null) {
      let customerName = project.associated_customer_name
      let customerNameArray = customerName.split(' ')
      if (customerNameArray.length >= 2) {
        let cleanedFirstName = customerNameArray[0].replace(/\W/g, '').toUpperCase()
        let cleanedLastName = customerNameArray[1].replace(/\W/g, '').toUpperCase()
        projectString = cleanedLastName.substring(0, 4)
        if (projectString.length === 0) {
          projectString += cleanedFirstName.substring(0, 4)
        } else if (projectString.length === 1) {
          projectString += cleanedFirstName.substring(0, 3)
        } else if (projectString.length === 2) {
          projectString += cleanedFirstName.substring(0, 2)
        } else if (projectString.length === 3) {
          projectString += cleanedFirstName.substring(0, 1)
        }
        if (projectString.length === 0) {
          projectString += generateRandomString(4, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        } else if (projectString.length === 1) {
          projectString += generateRandomString(3, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        } else if (projectString.length === 2) {
          projectString += generateRandomString(2, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        } else if (projectString.length === 3) {
          projectString += generateRandomString(1, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        }
      } else {
        let cleanedName = project.associated_customer_name.replace(/\W/g, '').toUpperCase().split(' ')
        projectString = cleanedName.substring(0, 4)
      }
    } else {
      projectString = generateRandomString(4, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ')
    }
  } catch (rej_T) {
    console.error(rej_T)
  }
  projectCode = projectPrefix + projectNumber + projectString
  return projectCode
}

export const checkForDuplicateProjectCodes = (clientKey: string, projectCode: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectCode != null) {
      DatabaseGetCollection(DatabaseRef_ProjectIdNumber_Query(clientKey, projectCode))
        .then((res_DGC) => {
          let matchingProjectCount = objectToArray(res_DGC.data).length
          resolve({ success: true, matches: matchingProjectCount })
        })
        .catch((rej_DGC) => {
          reject(rej_DGC)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to check for duplicate project codes'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-LPE-01',
        },
      })
    }
  })
}

export const addTaskWorkflowToProject = (
  clientKey: string,
  projectKey: string,
  workflowKey: string,
  userKey: string,
  userName: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && workflowKey != null) {
      DatabaseGetDocument(DatabaseRef_TaskWorkflow_Document(clientKey, workflowKey))
        .then((res_DGD) => {
          let rootTaskWorkflow = res_DGD.data
          if (rootTaskWorkflow.workflow_version_number === 2) {
            addV2TaskWorkflowToProject(clientKey, projectKey, workflowKey, userKey, userName, rootTaskWorkflow)
              .then((res_AV2TWTP) => {
                resolve(res_AV2TWTP)
              })
              .catch((rej_AV2TWTP) => {
                reject(rej_AV2TWTP)
              })
          } else {
            addV1TaskWorkflowToProject(clientKey, projectKey, workflowKey, userKey, userName)
              .then((res_AV1TWTP) => {
                resolve(res_AV1TWTP)
              })
              .catch((rej_AV1TWTP) => {
                reject(rej_AV1TWTP)
              })
          }
        })
        .catch((rej_DGD) => {
          reject(rej_DGD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to add workflow to project'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-ATWTP-01',
        },
      })
    }
  })
}

const addV1TaskWorkflowToProject = (clientKey: string, projectKey: string, workflowKey: string, userKey: string, userName: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
      .then((res_DGD) => {
        let databasePromiseArray: TsType_UnknownPromise[] = []
        let project = res_DGD.data
        let allProjectTasks: TsInterface_UnspecifiedObject = {}
        let deletedProjectTasks: TsInterface_UnspecifiedObject = {}
        let rootTaskWorkflow: TsInterface_UnspecifiedObject = {}
        databasePromiseArray.push(
          DatabaseGetCollection(DatabaseRef_AllProjectTasksFromDeletedWorkflow_Query(clientKey, projectKey))
            .then((res_DGC) => {
              deletedProjectTasks = res_DGC.data
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        databasePromiseArray.push(
          DatabaseGetCollection(DatabaseRef_AllProjectTasks_Query(clientKey, projectKey))
            .then((res_DGC) => {
              allProjectTasks = res_DGC.data
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        databasePromiseArray.push(
          DatabaseGetDocument(DatabaseRef_TaskWorkflow_Document(clientKey, workflowKey))
            .then((res_DGD) => {
              rootTaskWorkflow = res_DGD.data
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            }),
        )
        // After Data is Loaded
        Promise.all(databasePromiseArray).finally(() => {
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(clientKey, workflowKey))
            .then((res_DGD) => {
              // Instantiate Variables
              let prodTaskWorkflow = res_DGD.data
              let taskGroups: TsInterface_UnspecifiedObject = {}
              if (prodTaskWorkflow != null && prodTaskWorkflow['task_groups'] != null) {
                taskGroups = arrayToObject(prodTaskWorkflow['task_groups'], 'key')
              }
              let currentTaskID = 1
              let taskIDNumbersObject: TsInterface_UnspecifiedObject = {}
              let logTimestamp = new Date()
              let logKey = logTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
              let rootProjectUpdateObject: TsInterface_UnspecifiedObject = {
                associated_task_workflow_key: workflowKey,
                associated_task_workflow_name: getProp(rootTaskWorkflow, 'name', null),
                associated_task_workflow_version_key: getProp(prodTaskWorkflow, 'associated_prod_history_key', null),
              }
              let logUpdateObject = {
                timestamp: logTimestamp,
                associated_user_key: userKey,
                associated_user_name: userName,
                event: 'add_task_workflow',
                additional_data: {
                  associated_task_workflow_key: workflowKey,
                  associated_task_workflow_name: getProp(rootTaskWorkflow, 'name', null),
                  associated_task_workflow_version_key: getProp(prodTaskWorkflow, 'associated_prod_history_key', null),
                },
              }
              let projectTaskWorkflowUpdateObject = prodTaskWorkflow
              let taskUpdateObjects: TsInterface_UnspecifiedObject = {}
              let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(prodTaskWorkflow['tasks']))
              let sortedTaskRows = returnTaskRows(prodTaskWorkflow['tasks'], {}, tasksThatReferenceThemselves, ['addTaskWorkflowToProject'])
              // Determine Task ID Numbers
              for (let loopRowKey in sortedTaskRows) {
                let loopRow = sortedTaskRows[loopRowKey]
                if (loopRow != null && loopRow['tasks'] != null) {
                  for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
                    let loopTask = loopRow['tasks'][loopTaskIndex]
                    if (
                      loopTask != null &&
                      loopTask.key != null &&
                      loopTask['completion_time'] !== 'cancellation' &&
                      loopTask['completion_time'] !== 'completion'
                    ) {
                      taskIDNumbersObject[loopTask.key] = currentTaskID
                      currentTaskID++
                    }
                  }
                }
              }
              if (prodTaskWorkflow != null && prodTaskWorkflow['tasks'] != null) {
                for (let loopTaskKey in prodTaskWorkflow['tasks']) {
                  // If the task already exists
                  let updateTaskStatuses = true
                  let existingTask: TsInterface_UnspecifiedObject = {}
                  let loopTask = prodTaskWorkflow['tasks'][loopTaskKey]
                  let loopTaskKeyProper = projectKey + '_' + loopTaskKey
                  if (deletedProjectTasks != null && deletedProjectTasks[loopTaskKeyProper] != null) {
                    updateTaskStatuses = false
                    existingTask = deletedProjectTasks[loopTaskKeyProper]
                  } else if (allProjectTasks != null && allProjectTasks[loopTaskKeyProper] != null) {
                    updateTaskStatuses = false
                    existingTask = allProjectTasks[loopTaskKeyProper]
                  }
                  loopTask['associated_task_key'] = loopTaskKey
                  loopTask['associated_task_origin'] = 'workflow'
                  loopTask['key'] = loopTaskKeyProper
                  loopTask['associated_project_key'] = projectKey
                  loopTask['associated_project_id_number'] = project['id_number']
                  if (
                    loopTask['associated_task_group_key'] != null &&
                    taskGroups != null &&
                    taskGroups[loopTask['associated_task_group_key']] != null &&
                    taskGroups[loopTask['associated_task_group_key']]['name'] != null
                  ) {
                    loopTask['associated_task_group_name'] = taskGroups[loopTask['associated_task_group_key']]['name']
                  }
                  if (updateTaskStatuses === true && existingTask['ready_to_start'] == null) {
                    if (loopTask['prerequisite_tasks'] == null || objectToArray(loopTask['prerequisite_tasks']).length === 0) {
                      loopTask['ready_to_start'] = true
                    } else {
                      loopTask['ready_to_start'] = false
                    }
                  }
                  for (let loopPrerequisiteTaskKey in loopTask['prerequisite_tasks']) {
                    loopTask['prerequisite_tasks'][projectKey + '_' + loopPrerequisiteTaskKey] = projectKey + '_' + loopPrerequisiteTaskKey
                    delete loopTask['prerequisite_tasks'][loopPrerequisiteTaskKey]
                  }
                  if (loopTask['associated_dispatched_task_key'] != null) {
                    loopTask['associated_dispatched_task_key'] = projectKey + '_' + loopTask['associated_dispatched_task_key']
                  }
                  if (updateTaskStatuses === true && existingTask['timestamp_created'] == null) {
                    loopTask['timestamp_created'] = logTimestamp
                  }
                  if (updateTaskStatuses === true && existingTask['status'] == null) {
                    // loopTask["status"] = "unassigned"
                    if (loopTask['completion_time'] === 'main') {
                      loopTask['status'] = 'unassigned'
                    } else if (loopTask['completion_time'] === 'cancellation') {
                      if (project['status'] === 'cancelled') {
                        if (updateTaskStatuses === true) {
                          loopTask['status'] = 'unassigned'
                        }
                      } else {
                        if (updateTaskStatuses === true) {
                          loopTask['status'] = 'deleted'
                        }
                      }
                    } else if (loopTask['completion_time'] === 'completion') {
                      if (project['status'] === 'completed') {
                        if (updateTaskStatuses === true) {
                          loopTask['status'] = 'unassigned'
                        }
                      } else {
                        if (updateTaskStatuses === true) {
                          loopTask['status'] = 'deleted'
                        }
                      }
                    } else {
                      loopTask['status'] = 'unassigned'
                    }
                  }
                  if (updateTaskStatuses === true && existingTask['status_complete'] == null) {
                    loopTask['status_complete'] = false
                  }
                  loopTask['id_number'] = generateReadableIDForTask(loopTask, getProp(taskIDNumbersObject, loopTaskKey, null))
                  if (project['id_number'] != null) {
                    loopTask['id_number'] = project['id_number'] + '-' + loopTask['id_number']
                  }
                  // If the project already has certain user roles assigned then update tasks accordingly
                  if (
                    loopTask != null &&
                    loopTask['associated_owner_type'] != null &&
                    project['associated_' + loopTask['associated_owner_type'] + '_key'] != null &&
                    project['associated_' + loopTask['associated_owner_type'] + '_name'] != null
                  ) {
                    loopTask['associated_owner_key'] = project['associated_' + loopTask['associated_owner_type'] + '_key']
                    loopTask['associated_owner_name'] = project['associated_' + loopTask['associated_owner_type'] + '_name']
                    if (updateTaskStatuses === true && existingTask['timestamp_assigned'] == null) {
                      loopTask['timestamp_assigned'] = logTimestamp
                    }
                    if (updateTaskStatuses === true && existingTask['status'] == null) {
                      // loopTask["status"] = "active"
                      if (loopTask['completion_time'] === 'main') {
                        loopTask['status'] = 'active'
                      } else if (loopTask['completion_time'] === 'cancellation') {
                        if (project['status'] === 'cancelled') {
                          if (updateTaskStatuses === true) {
                            loopTask['status'] = 'active'
                          }
                        } else {
                          if (updateTaskStatuses === true) {
                            loopTask['status'] = 'deleted'
                          }
                        }
                      } else if (loopTask['completion_time'] === 'completion') {
                        if (project['status'] === 'completed') {
                          if (updateTaskStatuses === true) {
                            loopTask['status'] = 'active'
                          }
                        } else {
                          if (updateTaskStatuses === true) {
                            loopTask['status'] = 'deleted'
                          }
                        }
                      } else {
                        loopTask['status'] = 'active'
                      }
                    }
                  }
                  if (existingTask != null && existingTask['status_before_deletion'] != null) {
                    loopTask['status'] = existingTask['status_before_deletion']
                  }
                  if (existingTask != null && existingTask['status_compelete_before_deletion'] != null) {
                    loopTask['status_complete'] = existingTask['status_compelete_before_deletion']
                  }
                  if (existingTask != null) {
                    for (let propKey in existingTask) {
                      if (propKey.startsWith('OVERRIDE_')) {
                        let overridePropKey = propKey.replace('OVERRIDE_', '')
                        loopTask[overridePropKey] = existingTask[propKey]
                      }
                    }
                  }
                  // Add to update objects
                  taskUpdateObjects[loopTaskKeyProper] = loopTask
                }
              }
              // Sort Tasks and add ID numbers to them to them
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: rootProjectUpdateObject },
                { type: 'setOverwrite', ref: DatabaseRef_ProjectTaskWorkflow_Document(clientKey, projectKey), data: projectTaskWorkflowUpdateObject },
                { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), data: logUpdateObject },
              ]
              for (let loopTaskUpdateKey in taskUpdateObjects) {
                let loopTaskUpdateObject = taskUpdateObjects[loopTaskUpdateKey]
                loopTaskUpdateObject['associated_project_id_number'] = getProp(project, 'id_number', null)
                loopTaskUpdateObject['location_latitude'] = getProp(project, 'location_latitude', null)
                loopTaskUpdateObject['location_longitude'] = getProp(project, 'location_longitude', null)
                loopTaskUpdateObject['timestamp_last_updated'] = new Date()
                updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskUpdateKey), data: loopTaskUpdateObject })
              }
              DatabaseBatchUpdate(updateArray)
                .then((res_DBU) => {
                  cloudFunctionManageRequest('manageTasks', {
                    function: 'refreshProjectTaskProgressBar',
                    client_key: clientKey,
                    project_key: projectKey,
                  })
                  // generateProjectTaskStats( clientKey, projectKey, {}, {} ).finally(() => {
                  resolve(res_DBU)
                  // })
                })
                .catch((rej_DBU) => {
                  reject(rej_DBU)
                })
            })
            .catch((rej_DGD) => {
              reject(rej_DGD)
            })
        })
      })
      .catch((rej_DGD) => {
        reject(rej_DGD)
      })
  })
}

const mergeWorkflowWithTaskBlueprint = (workflowTask: TsInterface_UnspecifiedObject, blueprintTask: TsInterface_UnspecifiedObject) => {
  const mergedObj = cloneObjectWithoutReference(blueprintTask)
  // const mergedObj = { ...blueprintTask }
  for (const key in workflowTask) {
    // eslint-disable-next-line no-prototype-builtins
    if (workflowTask.hasOwnProperty(key)) {
      // if (workflowTask.hasOwnProperty(key)) {
      mergedObj[key] = workflowTask[key]
    }
  }
  return mergedObj
}

const addV2TaskWorkflowToProject = (
  clientKey: string,
  projectKey: string,
  workflowKey: string,
  userKey: string,
  userName: string,
  rootTaskWorkflow: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
      .then((res_DGD) => {
        let databasePromiseArray: TsType_UnknownPromise[] = []
        let project = res_DGD.data
        let activeBlueprintTasks: TsInterface_UnspecifiedObject = {}
        let allProjectTasks: TsInterface_UnspecifiedObject = {}
        let deletedProjectTasks: TsInterface_UnspecifiedObject = {}
        let rootTaskWorkflow: TsInterface_UnspecifiedObject = {}
        databasePromiseArray.push(
          DatabaseGetCollection(DatabaseRef_AllProjectTasksFromDeletedWorkflow_Query(clientKey, projectKey))
            .then((res_DGC) => {
              deletedProjectTasks = res_DGC.data
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        databasePromiseArray.push(
          DatabaseGetCollection(DatabaseRef_AllProjectTasks_Query(clientKey, projectKey))
            .then((res_DGC) => {
              allProjectTasks = res_DGC.data
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        databasePromiseArray.push(
          DatabaseGetDocument(DatabaseRef_TaskWorkflow_Document(clientKey, workflowKey))
            .then((res_DGD) => {
              rootTaskWorkflow = res_DGD.data
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            }),
        )
        databasePromiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveTaskBlueprints_Query(clientKey))
            .then((res_DGC) => {
              activeBlueprintTasks = res_DGC.data
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        // After Data is Loaded
        Promise.all(databasePromiseArray).finally(() => {
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(clientKey, workflowKey))
            .then((res_DGD) => {
              // Instantiate Variables
              let prodTaskWorkflow = res_DGD.data
              let currentTaskID = 1
              let taskIDNumbersObject: TsInterface_UnspecifiedObject = {}
              let logTimestamp = new Date()
              let logKey = logTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
              let rootProjectUpdateObject: TsInterface_UnspecifiedObject = {
                associated_task_workflow_key: workflowKey,
                associated_task_workflow_name: getProp(rootTaskWorkflow, 'name', null),
                associated_task_workflow_version_key: getProp(prodTaskWorkflow, 'associated_prod_history_key', null),
                associated_task_workflow_version_number: getProp(prodTaskWorkflow, 'workflow_version_number', 1),
              }
              let logUpdateObject = {
                timestamp: logTimestamp,
                associated_user_key: userKey,
                associated_user_name: userName,
                event: 'add_task_workflow',
                additional_data: {
                  associated_task_workflow_key: workflowKey,
                  associated_task_workflow_name: getProp(rootTaskWorkflow, 'name', null),
                  associated_task_workflow_version_key: getProp(prodTaskWorkflow, 'associated_prod_history_key', null),
                  associated_task_workflow_version_number: getProp(prodTaskWorkflow, 'workflow_version_number', 1),
                },
              }
              let failedToFindBlueprintTask = false
              // Generate Calendar Schedule Tasks for all On Site Tasks AND combine overrides with blueprint data
              let allTasksObject: TsInterface_UnspecifiedObject = {}
              for (let loopTaskKey in prodTaskWorkflow['tasks']) {
                let loopTask = prodTaskWorkflow['tasks'][loopTaskKey]
                if (loopTask != null) {
                  let blueprintTask = getProp(activeBlueprintTasks, loopTaskKey, null)
                  if (blueprintTask == null) {
                    failedToFindBlueprintTask = true
                  }
                  // Combine Blueprint with Workflow level overrides
                  let mergedTask = mergeWorkflowWithTaskBlueprint({ ...loopTask }, getProp(activeBlueprintTasks, loopTaskKey, {}))
                  // IF the task is a scheduled task, generate calendar dispatch task
                  if (mergedTask['task_completion_type'] === 'scheduled') {
                    // Update scheduled task to have the new dispatch task as a prerequisite
                    let copyOfMergedTask = cloneObjectWithoutReference(mergedTask)
                    if (mergedTask['prerequisite_tasks'] == null) {
                      mergedTask['prerequisite_tasks'] = {}
                    }
                    mergedTask['prerequisite_tasks']['SCHEDULE_' + loopTaskKey] = 'SCHEDULE_' + loopTaskKey
                    if (copyOfMergedTask['prerequisite_tasks'] == null) {
                      copyOfMergedTask['prerequisite_tasks'] = {}
                    }
                    let calendarScheduleTask: TsInterface_UnspecifiedObject = {
                      abbreviation: 'S' + copyOfMergedTask['abbreviation'],
                      associated_dispatched_task_key: loopTaskKey,
                      associated_owner_type: copyOfMergedTask['associated_dispatcher_type'],
                      associated_task_group_key: copyOfMergedTask['associated_task_group_key'],
                      associated_task_group_name: copyOfMergedTask['associated_task_group_name'],
                      associated_team_type: copyOfMergedTask['associated_team_type'],
                      delay_reasons: copyOfMergedTask['associated_task_group_name'],
                      key: 'SCHEDULE_' + loopTaskKey,
                      name: 'Schedule ' + copyOfMergedTask['name'],
                      prerequisite_tasks: copyOfMergedTask['prerequisite_tasks'],
                      status_green_days_cutoff: copyOfMergedTask['status_green_days_cutoff'],
                      status_yellow_days_cutoff: copyOfMergedTask['status_yellow_days_cutoff'],
                      task_completion_type: 'dispatcher',
                      timestamp_created: copyOfMergedTask['timestamp_created'],
                    }
                    allTasksObject[loopTaskKey] = mergedTask
                    allTasksObject['SCHEDULE_' + loopTaskKey] = calendarScheduleTask
                  } else {
                    if (mergedTask['prerequisite_tasks'] == null) {
                      mergedTask['prerequisite_tasks'] = {}
                    }
                    allTasksObject[loopTaskKey] = mergedTask
                  }
                }
              }
              // After tasks are cleaned up, proceed
              let projectTaskWorkflowUpdateObject = prodTaskWorkflow
              let taskUpdateObjects: TsInterface_UnspecifiedObject = {}
              let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(allTasksObject))
              let sortedTaskRows = returnTaskRows(allTasksObject, {}, tasksThatReferenceThemselves, ['addTaskWorkflowToProject'])
              // Determine Task ID Numbers
              for (let loopRowKey in sortedTaskRows) {
                let loopRow = sortedTaskRows[loopRowKey]
                if (loopRow != null && loopRow['tasks'] != null) {
                  for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
                    let loopTask = loopRow['tasks'][loopTaskIndex]
                    if (
                      loopTask != null &&
                      loopTask.key != null &&
                      loopTask['completion_time'] !== 'cancellation' &&
                      loopTask['completion_time'] !== 'completion'
                    ) {
                      taskIDNumbersObject[loopTask.key] = currentTaskID
                      currentTaskID++
                    }
                  }
                  // NEW - handle tasks that are cancelled or completed
                  for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
                    let loopTask = loopRow['tasks'][loopTaskIndex]
                    if (
                      loopTask != null &&
                      loopTask.key != null &&
                      (loopTask['completion_time'] === 'cancellation' || loopTask['completion_time'] === 'completion')
                    ) {
                      taskIDNumbersObject[loopTask.key] = currentTaskID
                      currentTaskID++
                    }
                  }
                }
              }
              if (prodTaskWorkflow != null && allTasksObject != null) {
                for (let loopTaskKey in allTasksObject) {
                  // If the task already exists
                  let updateTaskStatuses = true
                  let existingTask: TsInterface_UnspecifiedObject = {}
                  let loopTask = allTasksObject[loopTaskKey]
                  let loopTaskKeyProper = projectKey + '_' + loopTaskKey
                  if (deletedProjectTasks != null && deletedProjectTasks[loopTaskKeyProper] != null) {
                    updateTaskStatuses = false
                    existingTask = deletedProjectTasks[loopTaskKeyProper]
                  } else if (allProjectTasks != null && allProjectTasks[loopTaskKeyProper] != null) {
                    updateTaskStatuses = false
                    existingTask = allProjectTasks[loopTaskKeyProper]
                  }
                  loopTask['associated_task_key'] = loopTaskKey
                  loopTask['associated_task_origin'] = 'workflow'
                  loopTask['key'] = loopTaskKeyProper
                  loopTask['associated_project_key'] = projectKey
                  loopTask['associated_project_id_number'] = project['id_number']
                  // if( updateTaskStatuses === true && existingTask["ready_to_start"] == null ){
                  if (loopTask['prerequisite_tasks'] == null || objectToArray(loopTask['prerequisite_tasks']).length === 0) {
                    loopTask['ready_to_start'] = true
                  } else if (updateTaskStatuses === true && existingTask['ready_to_start'] == null) {
                    loopTask['ready_to_start'] = false
                  }
                  // }
                  for (let loopPrerequisiteTaskKey in loopTask['prerequisite_tasks']) {
                    loopTask['prerequisite_tasks'][projectKey + '_' + loopPrerequisiteTaskKey] = projectKey + '_' + loopPrerequisiteTaskKey
                    delete loopTask['prerequisite_tasks'][loopPrerequisiteTaskKey]
                  }
                  if (loopTask['associated_dispatched_task_key'] != null) {
                    loopTask['associated_dispatched_task_key'] = projectKey + '_' + loopTask['associated_dispatched_task_key']
                  }
                  if (updateTaskStatuses === true && existingTask['timestamp_created'] == null) {
                    loopTask['timestamp_created'] = logTimestamp
                  }
                  if (updateTaskStatuses === true && existingTask['status'] == null) {
                    // loopTask["status"] = "unassigned"
                    if (loopTask['completion_time'] === 'main') {
                      loopTask['status'] = 'unassigned'
                    } else if (loopTask['completion_time'] === 'cancellation') {
                      if (project['status'] === 'cancelled') {
                        if (updateTaskStatuses === true) {
                          loopTask['status'] = 'unassigned'
                        }
                      } else {
                        if (updateTaskStatuses === true) {
                          loopTask['status'] = 'deleted'
                        }
                      }
                    } else if (loopTask['completion_time'] === 'completion') {
                      if (project['status'] === 'completed') {
                        if (updateTaskStatuses === true) {
                          loopTask['status'] = 'unassigned'
                        }
                      } else {
                        if (updateTaskStatuses === true) {
                          loopTask['status'] = 'deleted'
                        }
                      }
                    } else {
                      loopTask['status'] = 'unassigned'
                    }
                  }
                  if (updateTaskStatuses === true && existingTask['status_complete'] == null) {
                    loopTask['status_complete'] = false
                  }
                  loopTask['id_number'] = generateReadableIDForTask(loopTask, getProp(taskIDNumbersObject, loopTaskKey, null))
                  if (project['id_number'] != null) {
                    loopTask['id_number'] = project['id_number'] + '-' + loopTask['id_number']
                  }
                  // If the project already has certain user roles assigned then update tasks accordingly
                  if (
                    loopTask != null &&
                    loopTask['associated_owner_type'] != null &&
                    project['associated_' + loopTask['associated_owner_type'] + '_key'] != null &&
                    project['associated_' + loopTask['associated_owner_type'] + '_name'] != null
                  ) {
                    loopTask['associated_owner_key'] = project['associated_' + loopTask['associated_owner_type'] + '_key']
                    loopTask['associated_owner_name'] = project['associated_' + loopTask['associated_owner_type'] + '_name']
                    if (updateTaskStatuses === true && existingTask['timestamp_assigned'] == null) {
                      loopTask['timestamp_assigned'] = logTimestamp
                    }
                    if (updateTaskStatuses === true && existingTask['status'] == null) {
                      // loopTask["status"] = "active"
                      if (loopTask['completion_time'] === 'main') {
                        loopTask['status'] = 'active'
                      } else if (loopTask['completion_time'] === 'cancellation') {
                        if (project['status'] === 'cancelled') {
                          if (updateTaskStatuses === true) {
                            loopTask['status'] = 'active'
                          }
                        } else {
                          if (updateTaskStatuses === true) {
                            loopTask['status'] = 'deleted'
                          }
                        }
                      } else if (loopTask['completion_time'] === 'completion') {
                        if (project['status'] === 'completed') {
                          if (updateTaskStatuses === true) {
                            loopTask['status'] = 'active'
                          }
                        } else {
                          if (updateTaskStatuses === true) {
                            loopTask['status'] = 'deleted'
                          }
                        }
                      } else {
                        loopTask['status'] = 'active'
                      }
                    }
                  }
                  if (existingTask != null && existingTask['status_before_deletion'] != null) {
                    loopTask['status'] = existingTask['status_before_deletion']
                  }
                  if (existingTask != null && existingTask['status_compelete_before_deletion'] != null) {
                    loopTask['status_complete'] = existingTask['status_compelete_before_deletion']
                  }
                  if (existingTask != null) {
                    for (let propKey in existingTask) {
                      if (propKey.startsWith('OVERRIDE_')) {
                        let overridePropKey = propKey.replace('OVERRIDE_', '')
                        loopTask[overridePropKey] = existingTask[propKey]
                      }
                    }
                  }
                  // Add to update objects
                  taskUpdateObjects[loopTaskKeyProper] = loopTask
                }
              }
              // Sort Tasks and add ID numbers to them to them
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: rootProjectUpdateObject },
                { type: 'setOverwrite', ref: DatabaseRef_ProjectTaskWorkflow_Document(clientKey, projectKey), data: projectTaskWorkflowUpdateObject },
                { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), data: logUpdateObject },
              ]
              for (let loopTaskUpdateKey in taskUpdateObjects) {
                let loopTaskUpdateObject = taskUpdateObjects[loopTaskUpdateKey]
                loopTaskUpdateObject['associated_project_id_number'] = getProp(project, 'id_number', null)
                loopTaskUpdateObject['location_latitude'] = getProp(project, 'location_latitude', null)
                loopTaskUpdateObject['location_longitude'] = getProp(project, 'location_longitude', null)
                loopTaskUpdateObject['timestamp_last_updated'] = new Date()
                updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskUpdateKey), data: loopTaskUpdateObject })
              }
              if (failedToFindBlueprintTask === false) {
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    cloudFunctionManageRequest('manageTasks', {
                      function: 'refreshProjectTaskProgressBar',
                      client_key: clientKey,
                      project_key: projectKey,
                    })
                    // generateProjectTaskStats( clientKey, projectKey, {}, {} ).finally(() => {
                    resolve(res_DBU)
                    // })
                  })
                  .catch((rej_DBU) => {
                    reject(rej_DBU)
                  })
              } else {
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to add workflow to project'),
                    details: rLIB('Unsupported task library entry'),
                    code: 'ER-D-PS-AWTP-01',
                  },
                })
              }
            })
            .catch((rej_DGD) => {
              reject(rej_DGD)
            })
        })
      })
      .catch((rej_DGD) => {
        reject(rej_DGD)
      })
  })
}

export const removeWorkflowFromProject = (clientKey: string, projectKey: string, userKey: string, userName: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null) {
      DatabaseGetCollection(DatabaseRef_AllProjectTasksFromWorkflow_Query(clientKey, projectKey))
        .then((res_DGC) => {
          let projectTasks = res_DGC.data
          let logTimestamp = new Date()
          let logKey = logTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
          let rootProjectUpdateObject: TsInterface_UnspecifiedObject = {
            associated_task_workflow_key: null,
            associated_task_workflow_version_key: null,
          }
          let logUpdateObject = {
            timestamp: logTimestamp,
            associated_user_key: userKey,
            associated_user_name: userName,
            event: 'remove_task_workflow',
            additional_data: {},
          }
          // BatchUpdates
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            { type: 'update', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: rootProjectUpdateObject },
            { type: 'setOverwrite', ref: DatabaseRef_ProjectTaskWorkflow_Document(clientKey, projectKey), data: {} },
            { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), data: logUpdateObject },
          ]
          for (let loopTaskKey in projectTasks) {
            let loopTask = projectTasks[loopTaskKey]
            let loopTaskUpdateObject = {
              associated_project_key: projectKey + '_deleted',
              status_before_deletion: getProp(loopTask, 'status', null),
              status_compelete_before_deletion: getProp(loopTask, 'status_complete', null),
              status: 'deleted',
              status_complete: true,
              timestamp_last_updated: new Date(),
            }
            updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskKey), data: loopTaskUpdateObject })
          }
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              cloudFunctionManageRequest('manageTasks', {
                function: 'refreshProjectTaskProgressBar',
                client_key: clientKey,
                project_key: projectKey,
              })
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        })
        .catch((rej_DGC) => {
          reject(rej_DGC)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to remove workflow from project'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-RWFP-01',
        },
      })
    }
  })
}

export const assignUserToProjectTaskRole = (
  clientKey: string,
  projectKey: string,
  assignedUserKey: string,
  assignedUserName: string,
  assignedUserRoleTypeKey: string,
  logUserKey: string,
  logUserName: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && assignedUserKey != null && assignedUserName != null && assignedUserRoleTypeKey != null) {
      let databasePromiseArray: TsInterface_PromiseArray = []
      let ownerTasks: TsInterface_UnspecifiedObject = {}
      databasePromiseArray.push(
        DatabaseGetCollection(DatabaseRef_ProjectTasksForSpecificOwnerRole_Query(clientKey, projectKey, assignedUserRoleTypeKey))
          .then((res_DGC) => {
            ownerTasks = res_DGC.data
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          }),
      )
      Promise.all(databasePromiseArray).finally(() => {
        let logTimestamp = new Date()
        let logKey = logTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
        let rootProjectUpdateObject: TsInterface_UnspecifiedObject = {}
        rootProjectUpdateObject['associated_' + assignedUserRoleTypeKey + '_key'] = assignedUserKey
        rootProjectUpdateObject['associated_' + assignedUserRoleTypeKey + '_name'] = assignedUserName
        let logUpdateObject = {
          timestamp: logTimestamp,
          associated_user_key: logUserKey,
          associated_user_name: logUserName,
          event: 'assigned_to_project_role',
          additional_data: {
            associated_owner_key: assignedUserKey,
            associated_owner_name: assignedUserName,
            associated_owner_type: assignedUserRoleTypeKey,
          },
        }
        if (assignedUserRoleTypeKey === 'css_rep') {
          rootProjectUpdateObject['status'] = 'active'
        }
        let roleAssignmentUpdateObject: TsInterface_UnspecifiedObject = {
          timestamp_assignment: {},
        }
        roleAssignmentUpdateObject['timestamp_assignment'][assignedUserRoleTypeKey] = {}
        roleAssignmentUpdateObject['timestamp_assignment'][assignedUserRoleTypeKey][assignedUserKey] = new Date()
        roleAssignmentUpdateObject['associated_' + assignedUserRoleTypeKey + '_key'] = assignedUserKey
        roleAssignmentUpdateObject['associated_' + assignedUserRoleTypeKey + '_name'] = assignedUserName
        let taskUpdateObjects: TsInterface_UnspecifiedObject = {}
        for (let loopTaskKey in ownerTasks) {
          let loopTask = ownerTasks[loopTaskKey]
          if (loopTask != null && loopTask.status_complete === true) {
            taskUpdateObjects[loopTaskKey] = {
              associated_owner_key: assignedUserKey,
              associated_owner_name: assignedUserName,
              // timestamp_assigned: logTimestamp,
              // status: "active"
            }
          } else if (loopTask != null && loopTask.status === 'deleted') {
            taskUpdateObjects[loopTaskKey] = {
              associated_owner_key: assignedUserKey,
              associated_owner_name: assignedUserName,
              timestamp_assigned: logTimestamp,
              // status: "active"
            }
          } else {
            taskUpdateObjects[loopTaskKey] = {
              associated_owner_key: assignedUserKey,
              associated_owner_name: assignedUserName,
              timestamp_assigned: logTimestamp,
              status: 'active',
            }
          }
        }
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: rootProjectUpdateObject },
          { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), data: logUpdateObject },
          { type: 'setMerge', ref: DatabaseRef_ProjectRoleAssignments_Document(clientKey, projectKey), data: roleAssignmentUpdateObject },
        ]
        for (let loopTaskUpdateKey in taskUpdateObjects) {
          let loopTaskUpdateObject = taskUpdateObjects[loopTaskUpdateKey]
          loopTaskUpdateObject['timestamp_last_updated'] = new Date()
          updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskUpdateKey), data: loopTaskUpdateObject })
        }
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            cloudFunctionManageRequest('manageTasks', {
              function: 'refreshProjectTaskProgressBar',
              client_key: clientKey,
              project_key: projectKey,
            })
              .then((res_CFMTR) => {
                // Nothing
              })
              .catch((rej_CFMTR) => {
                console.error(rej_CFMTR)
              })
            resolve(res_DBU)
            // generateProjectTaskStats( clientKey, projectKey, {}, {} ).finally(() => {
            // resolve(res_DBU)
            // })
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to add workflow to project'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-AUTPTR-01',
        },
      })
    }
  })
}

export const deleteProjectTask = (
  clientKey: string,
  taskKey: string,
  logUserKey: string,
  logUserName: string,
  operation: 'save_to_database' | 'return_update_objects',
  replacementTaskKey: string | null,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && taskKey != null) {
      DatabaseGetDocument(DatabaseRef_Task_Document(clientKey, taskKey))
        .then((res_DGD) => {
          let task = res_DGD.data
          let databasePromiseArray: TsType_UnknownPromise[] = []
          let downstreamTasks: TsInterface_UnspecifiedObject = {}
          databasePromiseArray.push(
            DatabaseGetCollection(DatabaseRef_ProjectTasksForPrerequisiteTask_Query(clientKey, taskKey))
              .then((res_DGC) => {
                downstreamTasks = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          let baseTaskInvoicesLinkedToTask: TsInterface_UnspecifiedObject = {}
          if (task.associated_task_key != null) {
            databasePromiseArray.push(
              DatabaseGetCollection(DatabaseRef_BaseInvoicesForTask_Query(clientKey, task.associated_task_key))
                .then((res_DGC) => {
                  baseTaskInvoicesLinkedToTask = res_DGC.data
                })
                .catch((rej_DGC) => {
                  console.error(rej_DGC)
                }),
            )
          }
          Promise.all(databasePromiseArray).finally(() => {
            if (baseTaskInvoicesLinkedToTask != null && objectToArray(baseTaskInvoicesLinkedToTask).length > 0) {
              reject({
                success: false,
                error: {
                  message: rLIB('Not allowed to delete task'),
                  details: rLIB('Cannot delete task with linked base invoices'),
                  code: 'ER-D-PS-RWFP-01',
                },
              })
            } else {
              let deletedTimestamp = new Date()
              let logKey = deletedTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
              let mainTaskUpdateObject = {
                status_before_deletion: getProp(task, 'status', null),
                status_compelete_before_deletion: getProp(task, 'status_complete', null),
                // timestamp_completed_before_deletion: getProp( task, "timestamp_completed", null ),
                status: 'deleted',
                status_complete: true,
                // timestamp_completed: deletedTimestamp,
                timestamp_last_updated: new Date(),
              }
              let logUpdateObject = {
                timestamp: deletedTimestamp,
                associated_user_key: logUserKey,
                associated_user_name: logUserName,
                event: 'delete_task',
                additional_data: {
                  associated_task_name: getProp(task, 'name', null),
                  associated_task_key: taskKey,
                },
              }
              let downstreamTasksUpdateObject: TsInterface_UnspecifiedObject = {
                ready_to_start: true,
                timestamp_last_updated: new Date(),
                prerequisite_tasks_completion: {},
              }
              downstreamTasksUpdateObject['prerequisite_tasks_completion'][taskKey] = getProp(task, 'timestamp_completed', deletedTimestamp)
              // Handle replacement Tasks (merging of projects with proto projects)
              if (replacementTaskKey != null) {
                downstreamTasksUpdateObject['prerequisite_tasks'] = {}
                downstreamTasksUpdateObject['prerequisite_tasks'][replacementTaskKey] = replacementTaskKey
              }
              if (operation === 'save_to_database') {
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  { type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, taskKey), data: mainTaskUpdateObject },
                  { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, task.associated_project_key, logKey), data: logUpdateObject },
                ]
                for (let loopTaskUpdateKey in downstreamTasks) {
                  updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskUpdateKey), data: downstreamTasksUpdateObject })
                }
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    cloudFunctionManageRequest('manageTasks', {
                      function: 'refreshProjectTaskProgressBar',
                      client_key: clientKey,
                      project_key: task.associated_project_key,
                    })
                    resolve(res_DBU)
                  })
                  .catch((rej_DBU) => {
                    reject(rej_DBU)
                  })
              } else if (operation === 'return_update_objects') {
                let updateObjects: TsInterface_UnspecifiedObject = {}
                updateObjects[taskKey] = mainTaskUpdateObject
                for (let loopTaskUpdateKey in downstreamTasks) {
                  updateObjects[loopTaskUpdateKey] = downstreamTasksUpdateObject
                }
                resolve({
                  success: true,
                  updateObjects: updateObjects,
                })
              } else {
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to delete project task'),
                    details: rLIB('Unsupported Operation'),
                    code: 'ER-D-PS-RWFP-01',
                  },
                })
              }
            }
          })
        })
        .catch((rej_DGD) => {
          reject(rej_DGD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to delete project task'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-RWFP-02',
        },
      })
    }
  })
}

export const undeleteProjectTask = (
  clientKey: string,
  taskKey: string,
  logUserKey: string | null,
  logUserName: string | null,
  operation: 'save_to_database' | 'return_update_objects',
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && taskKey != null) {
      DatabaseGetDocument(DatabaseRef_Task_Document(clientKey, taskKey))
        .then((res_DGD) => {
          let task = res_DGD.data
          let databasePromiseArray: TsType_UnknownPromise[] = []
          let downstreamTasks: TsInterface_UnspecifiedObject = {}
          databasePromiseArray.push(
            DatabaseGetCollection(DatabaseRef_ProjectTasksForPrerequisiteTask_Query(clientKey, taskKey))
              .then((res_DGC) => {
                downstreamTasks = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          Promise.all(databasePromiseArray).finally(() => {
            let undeletedTimestamp = new Date()
            let logKey = undeletedTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
            let statusBeforeDeletion = getProp(task, 'status_before_deletion', 'active')
            if (statusBeforeDeletion === 'deleted') {
              statusBeforeDeletion = 'active'
            }
            let statusCompleteBeforeDeletion = getProp(task, 'status_compelete_before_deletion', false)
            let mainTaskUpdateObject = {
              status: statusBeforeDeletion,
              status_complete: statusCompleteBeforeDeletion,
              timestamp_last_updated: new Date(),
              // timestamp_completed: null,
            }
            if (logUserKey == null) {
              logUserKey = null
            }
            if (logUserName == null) {
              logUserName = null
            }
            let logUpdateObject = {
              timestamp: undeletedTimestamp,
              associated_user_key: logUserKey,
              associated_user_name: logUserName,
              event: 'undelete_task',
              additional_data: {
                associated_task_name: getProp(task, 'name', null),
                associated_task_key: taskKey,
              },
            }
            let downstreamTasksUpdateObject: TsInterface_UnspecifiedObject = {
              timestamp_last_updated: new Date(),
              // ready_to_start: false,
              // prerequisite_tasks_completion: {}
            }
            if (statusCompleteBeforeDeletion !== true) {
              downstreamTasksUpdateObject['prerequisite_tasks_completion'] = {}
              downstreamTasksUpdateObject['prerequisite_tasks_completion'][taskKey] = null
            }
            // downstreamTasksUpdateObject["prerequisite_tasks_completion"][ taskKey ] = null
            if (operation === 'save_to_database') {
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                { type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, taskKey), data: mainTaskUpdateObject },
                { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, task.associated_project_key, logKey), data: logUpdateObject },
              ]
              for (let loopTaskUpdateKey in downstreamTasks) {
                updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskUpdateKey), data: downstreamTasksUpdateObject })
              }
              DatabaseBatchUpdate(updateArray)
                .then((res_DBU) => {
                  cloudFunctionManageRequest('manageTasks', {
                    function: 'refreshProjectTaskProgressBar',
                    client_key: clientKey,
                    project_key: task.associated_project_key,
                  })
                  resolve(res_DBU)
                })
                .catch((rej_DBU) => {
                  reject(rej_DBU)
                })
            } else if (operation === 'return_update_objects') {
              let updateObjects: TsInterface_UnspecifiedObject = {}
              updateObjects[taskKey] = mainTaskUpdateObject
              for (let loopTaskUpdateKey in downstreamTasks) {
                updateObjects[loopTaskUpdateKey] = downstreamTasksUpdateObject
              }
              resolve({
                success: true,
                updateObjects: updateObjects,
              })
            } else {
              reject({
                success: false,
                error: {
                  message: rLIB('Failed to undelete project task'),
                  details: rLIB('Unsupported Operation'),
                  code: 'ER-D-PS-RWFP-01',
                },
              })
            }
          })
        })
        .catch((rej_DGD) => {
          reject(rej_DGD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to undelete project task'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-RWFP-02',
        },
      })
    }
  })
}

export const returnTaskCalculatedTimesAndStatus = (task: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let readyAndWaiting = true
  let startTimestamp: null | number = null
  let endTimestamp: null | number = null
  let elapsedDays: null | number = null
  if (task != null && task['prerequisite_tasks'] != null && objectToArray(task['prerequisite_tasks']).length > 0) {
    for (let loopPrerequisiteTaskKey in task['prerequisite_tasks']) {
      if (task['prerequisite_tasks_completion'] == null || task['prerequisite_tasks_completion'][loopPrerequisiteTaskKey] == null) {
        readyAndWaiting = false
      } else {
        let loopPrerequisiteTimestamp = returnTimestampFromUnknownDateFormat(task['prerequisite_tasks_completion'][loopPrerequisiteTaskKey])
        if (startTimestamp == null || (!isNaN(loopPrerequisiteTimestamp) && loopPrerequisiteTimestamp > startTimestamp)) {
          startTimestamp = returnTimestampFromUnknownDateFormat(loopPrerequisiteTimestamp)
        }
        // if(
        // 	startTimestamp == null || ( !isNaN(task["prerequisite_tasks_completion"][ loopPrerequisiteTaskKey ]) &&
        // 	task["prerequisite_tasks_completion"][ loopPrerequisiteTaskKey ] > startTimestamp)
        // ){
        // 	startTimestamp = returnTimestampFromUnknownDateFormat( task["prerequisite_tasks_completion"][ loopPrerequisiteTaskKey ] )
        // }
      }
    }
    if (task.task_completion_type === 'scheduled' && task.timestamp_scheduled != null) {
      startTimestamp = returnTimestampFromUnknownDateFormat(task.timestamp_scheduled)
    }
  } else {
    startTimestamp = getProp(task, 'timestamp_assigned', null)
  }
  // Override start timestamp if create date is later
  // let creationTimestamp = returnTimestampFromUnknownDateFormat( getProp( task, "timestamp_created", null ) )
  // if(
  // 	startTimestamp == null ||
  // 	creationTimestamp > startTimestamp
  // ){
  // 	startTimestamp = creationTimestamp
  // }
  // Override start timestamp if redo date is later
  let redoTimestamp = returnTimestampFromUnknownDateFormat(getProp(task, 'timestamp_redo', null))
  if (startTimestamp == null || redoTimestamp > startTimestamp) {
    startTimestamp = redoTimestamp
  }
  // End Timestamp
  if (task['timestamp_completed'] != null) {
    endTimestamp = returnTimestampFromUnknownDateFormat(task['timestamp_completed'])
  }
  if (startTimestamp != null && readyAndWaiting === true) {
    if (endTimestamp != null) {
      // elapsedDays = parseInt( ( (endTimestamp - startTimestamp ) / millisecondsPerDay ).toString() )
      elapsedDays = parseInt(
        ((returnTimestampFromUnknownDateFormat(endTimestamp) - returnTimestampFromUnknownDateFormat(startTimestamp)) / millisecondsPerDay).toString(),
      )
    } else {
      // elapsedDays = parseInt( ( ( new Date().getTime() - startTimestamp ) / millisecondsPerDay ).toString() )
      elapsedDays = parseInt(((new Date().getTime() - returnTimestampFromUnknownDateFormat(startTimestamp)) / millisecondsPerDay).toString())
    }
  } else {
    startTimestamp = null
  }
  return {
    status: null,
    startTimestamp: startTimestamp,
    endTimestamp: endTimestamp,
    elapsedDays: elapsedDays,
  }
}

export const returnCombinedTaskRoles = (clientKey: string | null): TsInterface_UnspecifiedObject => {
  if (clientKey == null) {
    clientKey = ''
  }
  let clientUserRoles = returnClientUserRoles(clientKey)
  let combinedRoleObject: TsInterface_UnspecifiedObject = {}
  for (let loopRoleKey in clientUserRoles) {
    combinedRoleObject[loopRoleKey] = clientUserRoles[loopRoleKey]
  }
  return combinedRoleObject
}

// Sort of Copied to Cloud Functions
export const determineTaskColorStatus = (task: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let taskStatusColorObject: TsInterface_UnspecifiedObject = {
    timestamp_completed: getProp(task, 'timestamp_completed', null),
  }
  let readyAndWaiting = true
  let startTimestamp: null | number = null
  if (task != null && task['prerequisite_tasks'] != null && objectToArray(task['prerequisite_tasks']).length > 0) {
    for (let loopPrerequisiteTaskKey in task['prerequisite_tasks']) {
      if (task['prerequisite_tasks_completion'] == null || task['prerequisite_tasks_completion'][loopPrerequisiteTaskKey] == null) {
        readyAndWaiting = false
      } else {
        let loopPrerequisiteTimestamp = returnTimestampFromUnknownDateFormat(task['prerequisite_tasks_completion'][loopPrerequisiteTaskKey])
        if (startTimestamp == null || (!isNaN(loopPrerequisiteTimestamp) && loopPrerequisiteTimestamp > startTimestamp)) {
          startTimestamp = returnTimestampFromUnknownDateFormat(loopPrerequisiteTimestamp)
        }
        // if( startTimestamp == null || ( !isNaN(task["prerequisite_tasks_completion"][ loopPrerequisiteTaskKey ]) && task["prerequisite_tasks_completion"][ loopPrerequisiteTaskKey ] > startTimestamp) ){
        // 	startTimestamp = returnTimestampFromUnknownDateFormat( task["prerequisite_tasks_completion"][ loopPrerequisiteTaskKey ] )
        // }
      }
    }
    if (task.task_completion_type === 'scheduled' && task.timestamp_scheduled != null) {
      startTimestamp = returnTimestampFromUnknownDateFormat(task.timestamp_scheduled)
    }
  } else {
    startTimestamp = returnTimestampFromUnknownDateFormat(getProp(task, 'timestamp_assigned', null))
  }
  // Override start timestamp if create date is later
  // let creationTimestamp = returnTimestampFromUnknownDateFormat( getProp( task, "timestamp_created", null ) )
  // if(
  // 	startTimestamp == null ||
  // 	creationTimestamp > startTimestamp
  // ){
  // 	startTimestamp = creationTimestamp
  // }
  // Override start timestamp if redo date is later
  let redoTimestamp = returnTimestampFromUnknownDateFormat(getProp(task, 'timestamp_redo', null))
  if (startTimestamp == null || redoTimestamp > startTimestamp) {
    startTimestamp = redoTimestamp
  }
  taskStatusColorObject['timestamp_assigned'] = startTimestamp
  if (task['status'] === 'completed') {
    taskStatusColorObject = {
      active: false,
      status: rLIB('Completed Task'),
      color_status: 'completed',
      icon: 'circle-check',
      color: themeVariables.info_main,
    }
  } else if (task['status'] === 'unassigned') {
    taskStatusColorObject = {
      active: false,
      status: rLIB('Unassigned Task'),
      color_status: 'unassigned',
      icon: 'square-question',
      color: themeVariables.gray_500,
    }
  } else if (task['status'] === 'deleted') {
    taskStatusColorObject = {
      active: false,
      status: rLIB('Unassigned Task'),
      color_status: 'deleted',
      icon: 'trash',
      color: themeVariables.gray_700,
    }
  } else {
    // Time from breakdown
    if (readyAndWaiting === false) {
      taskStatusColorObject = {
        active: false,
        status: rLIB('Waiting on Prerequisites'),
        color_status: 'not_ready',
        icon: 'circle-pause',
        color: themeVariables.gray_600,
      }
    } else {
      if (startTimestamp != null) {
        let endTimestamp = new Date().getTime()
        if (taskStatusColorObject['timestamp_completed'] != null) {
          endTimestamp = taskStatusColorObject['timestamp_completed']
        }
        // let elapsedDays = ( endTimestamp - startTimestamp ) / millisecondsPerDay
        let elapsedDays = parseInt(((endTimestamp - startTimestamp) / millisecondsPerDay).toString())
        if (task['status_green_days_cutoff'] != null && elapsedDays <= task['status_green_days_cutoff']) {
          taskStatusColorObject = {
            active: true,
            status: (
              <>
                {rLIB('Active Task')}: {rLIB('Green Status')}
              </>
            ),
            color_status: 'ready_green',
            icon: 'circle-play',
            color: themeVariables.success_main,
          }
        } else if (task['status_yellow_days_cutoff'] != null && elapsedDays <= task['status_yellow_days_cutoff']) {
          taskStatusColorObject = {
            active: true,
            status: (
              <>
                {rLIB('Active Task')}: {rLIB('Yellow Status')}
              </>
            ),
            color_status: 'ready_yellow',
            icon: 'triangle-exclamation',
            color: themeVariables.warning_dark,
          }
        } else {
          taskStatusColorObject = {
            active: true,
            status: (
              <>
                {rLIB('Active Task')}: {rLIB('Red Status')}
              </>
            ),
            color_status: 'ready_red',
            icon: 'siren-on',
            color: themeVariables.error_main,
          }
        }
      }
    }
  }
  return taskStatusColorObject
}

export const returnExpandedTaskTableData = (
  clientKey: string | null,
  projectTasks: TsInterface_UnspecifiedObject,
  projectTaskWorkflow: TsInterface_UnspecifiedObject,
  dataStatus: TsType_TaskTableFilterOptions,
  userKey: string | null,
  recursiveTasks: TsInterface_UnspecifiedObject | null,
  debugSource: string[],
): TsInterface_TableData => {
  let projectTasksCopy = cloneObjectWithoutReference(projectTasks)
  // Loop through tasks
  for (let loopTaskKey in projectTasksCopy) {
    let loopTask = projectTasksCopy[loopTaskKey]
    let combinedUserRoles = returnCombinedTaskRoles(clientKey)
    if (loopTask != null) {
      let taskColorStatusData = determineTaskColorStatus(loopTask)
      // Direct or Scheduled Tasks
      loopTask['TEMP_allow_task_redos'] = true
      // Handle Sort
      loopTask.TEMP_prerequisite_tasks = {}
      if (loopTask.prerequisite_tasks != null) {
        for (let loopPrerequisiteKey in loopTask.prerequisite_tasks) {
          loopTask.TEMP_prerequisite_tasks[loopPrerequisiteKey] = true
        }
      }
      // OWNER TYPE NAME
      if (
        combinedUserRoles != null &&
        loopTask['associated_owner_type'] != null &&
        combinedUserRoles[loopTask['associated_owner_type']] != null &&
        combinedUserRoles[loopTask['associated_owner_type']]['name'] != null
      ) {
        projectTasksCopy[loopTaskKey]['TEMP_associated_owner_type_name'] = combinedUserRoles[loopTask['associated_owner_type']]['name']
      }
      // TASK TIMES AND STATUSES
      let calculatedTaskData = returnTaskCalculatedTimesAndStatus(loopTask)
      projectTasksCopy[loopTaskKey]['TEMP_timestamp_assigned'] = calculatedTaskData.startTimestamp
      projectTasksCopy[loopTaskKey]['TEMP_timestamp_completed'] = calculatedTaskData.endTimestamp
      projectTasksCopy[loopTaskKey]['TEMP_elapsed_days'] = calculatedTaskData.elapsedDays
      if (dataStatus === 'all') {
        // Nothing
      } else if (dataStatus === 'unassigned' && taskColorStatusData.color_status !== 'unassigned') {
        delete projectTasksCopy[loopTaskKey]
      } else if (dataStatus === 'active_tasks' && taskColorStatusData.active !== true) {
        delete projectTasksCopy[loopTaskKey]
      } else if (dataStatus === 'completed_tasks' && loopTask.status !== 'completed') {
        delete projectTasksCopy[loopTaskKey]
      } else if (dataStatus === 'future_tasks' && taskColorStatusData.color_status !== 'not_ready') {
        delete projectTasksCopy[loopTaskKey]
      } else if (dataStatus === 'deleted' && loopTask.status !== 'deleted') {
        delete projectTasksCopy[loopTaskKey]
      } else if (dataStatus === 'not_deleted' && loopTask.status === 'deleted') {
        delete projectTasksCopy[loopTaskKey]
      } else if (dataStatus === 'user_tasks') {
        if (userKey != null && loopTask['associated_owner_key'] !== userKey) {
          delete projectTasksCopy[loopTaskKey]
        }
      } else if (dataStatus === 'invalid_prereq_data_structure') {
        if (getProp(recursiveTasks, loopTaskKey, false) === false) {
          delete projectTasksCopy[loopTaskKey]
        }
      }
    } else {
      delete projectTasksCopy[loopTaskKey]
    }
  }
  let fullySortedTableData = sortTasksByPrerequisites(projectTasksCopy, recursiveTasks, debugSource)
  for (let loopTaskIndex in fullySortedTableData) {
    if (fullySortedTableData[loopTaskIndex] != null) {
      fullySortedTableData[loopTaskIndex]['TEMP_completion_order'] = 1000 + parseInt(loopTaskIndex) + 1
    }
  }
  return fullySortedTableData
}

// Completion / Deletion / Undeletion
export const completeProject = (
  clientKey: string,
  projectKey: string,
  task: TsInterface_UnspecifiedObject,
  userKey: string,
  userName: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && userKey != null && userName != null) {
      // Project
      let rootProjectUpdateObject = {
        status: 'completed',
        status_complete: true,
        timestamp_completed: new Date(),
        timestamp_deleted: null,
        timestamp_undeleted: null,
      }
      // Log
      let logTimestamp = new Date().getTime()
      let logKey = logTimestamp.toString() + '_' + generateRandomString(6, null)
      let logUpdateObject = {
        timestamp: logTimestamp,
        associated_user_key: userKey,
        associated_user_name: userName,
        event: 'complete_project',
      }
      // Update
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: rootProjectUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), data: logUpdateObject },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          cloudFunctionManageRequest('manageTasks', {
            function: 'refreshProjectTaskProgressBar',
            client_key: clientKey,
            project_key: projectKey,
          })
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to log project event'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-CP-01',
        },
      })
    }
  })
}

export const deleteProject = (
  clientKey: string,
  projectKey: string,
  task: TsInterface_UnspecifiedObject,
  userKey: string,
  userName: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && userKey != null && userName != null) {
      // Project
      let rootProjectUpdateObject = {
        status: 'deleted',
        status_complete: true,
        timestamp_completed: null,
        timestamp_deleted: new Date(),
        timestamp_undeleted: null,
      }
      // Log
      let logTimestamp = new Date().getTime()
      let logKey = logTimestamp.toString() + '_' + generateRandomString(6, null)
      let logUpdateObject = {
        timestamp: logTimestamp,
        associated_user_key: userKey,
        associated_user_name: userName,
        event: 'delete_project',
      }
      // Update
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: rootProjectUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), data: logUpdateObject },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          cloudFunctionManageRequest('manageTasks', {
            function: 'refreshProjectTaskProgressBar',
            client_key: clientKey,
            project_key: projectKey,
          })
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to log project event'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-DP-01',
        },
      })
    }
  })
}

export const undeleteProject = (
  clientKey: string,
  projectKey: string,
  task: TsInterface_UnspecifiedObject,
  userKey: string,
  userName: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let undeleteStatus = 'unassigned'
    if (task != null && task.associated_css_rep_key != null) {
      undeleteStatus = 'active'
    }
    if (clientKey != null && projectKey != null && userKey != null && userName != null) {
      // Project
      let rootProjectUpdateObject = {
        status: undeleteStatus,
        status_complete: true,
        timestamp_completed: null,
        timestamp_deleted: null,
        timestamp_undeleted: new Date(),
      }
      // Log
      let logTimestamp = new Date().getTime()
      let logKey = logTimestamp.toString() + '_' + generateRandomString(6, null)
      let logUpdateObject = {
        timestamp: logTimestamp,
        associated_user_key: userKey,
        associated_user_name: userName,
        event: 'undelete_project',
      }
      // Update
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: rootProjectUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), data: logUpdateObject },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          cloudFunctionManageRequest('manageTasks', {
            function: 'refreshProjectTaskProgressBar',
            client_key: clientKey,
            project_key: projectKey,
          })
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to log project event'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-UP-01',
        },
      })
    }
  })
}

export const changeProjectStatus = (
  clientKey: string,
  projectKey: string,
  taskStatusChangeInstructions: TsInterface_UnspecifiedObject,
  logUserKey: string,
  logUserName: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null) {
      // Get Data
      let promiseArray1: TsType_UnknownPromise[] = []
      let promiseArray2: TsType_UnknownPromise[] = []
      let projectTasks: TsInterface_UnspecifiedObject = {}
      // let projectTaskWorkflow: TsInterface_UnspecifiedObject = {}
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_AllProjectTasks_Query(clientKey, projectKey))
          .then((res_DGD) => {
            projectTasks = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      let projectBaseInvoices: TsInterface_UnspecifiedObject = {}
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_BaseInvoicesForProject_Query(clientKey, projectKey))
          .then((res_DGD) => {
            projectBaseInvoices = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )

      // promiseArray1.push( DatabaseGetDocument( DatabaseRef_ProjectTaskWorkflow_Document( clientKey, projectKey ) ).then((res_DGD) => {
      // 	projectTaskWorkflow = res_DGD.data
      // }).catch((rej_DGD) => {
      // 	console.error(rej_DGD)
      // }) )
      // After Data Loaded
      Promise.all(promiseArray1).finally(() => {
        let failureCount: number = 0
        let rawReturnObjects: TsInterface_UnspecifiedObject = {}
        let flattenedTaskUpdateObjects: TsInterface_UnspecifiedObject = {}
        // Loop through existing tasks
        for (let loopTaskKey in projectTasks) {
          let loopTask = projectTasks[loopTaskKey]
          if (
            (loopTask['status'] === 'deleted' &&
              loopTask['completion_time'] === 'cancellation' &&
              taskStatusChangeInstructions['create_cancellation'] === true) ||
            (loopTask['status'] === 'deleted' && loopTask['completion_time'] === 'completion' && taskStatusChangeInstructions['create_completion'] === true) ||
            (loopTask['status'] === 'deleted' &&
              (loopTask['completion_time'] === 'main' || loopTask['completion_time'] == null) &&
              taskStatusChangeInstructions['undelete_main'] === true)
          ) {
            promiseArray2.push(
              undeleteProjectTask(clientKey, loopTaskKey, logUserKey, logUserName, 'return_update_objects')
                .then((res_UPT: any) => {
                  rawReturnObjects[loopTaskKey] = res_UPT['updateObjects']
                  // eslint-disable-next-line no-loop-func
                })
                .catch((rej_UPT) => {
                  failureCount++
                  console.error(rej_UPT)
                }),
            )
          }
          if (
            (loopTask['completion_time'] === 'cancellation' && taskStatusChangeInstructions['delete_cancellation'] === true) ||
            (loopTask['completion_time'] === 'completion' && taskStatusChangeInstructions['delete_completion'] === true) ||
            (loopTask['status_complete'] !== true &&
              (loopTask['completion_time'] === 'main' || loopTask['completion_time'] == null) &&
              taskStatusChangeInstructions['delete_main'] === true)
          ) {
            promiseArray2.push(
              deleteProjectTask(clientKey, loopTaskKey, logUserKey, logUserName, 'return_update_objects', null)
                .then((res_DPT: any) => {
                  rawReturnObjects[loopTaskKey] = res_DPT['updateObjects']
                  // eslint-disable-next-line no-loop-func
                })
                .catch((rej_DPT) => {
                  failureCount++
                  console.error(rej_DPT)
                }),
            )
          }
        }
        // eslint-disable-next-line no-loop-func
        Promise.all(promiseArray2).finally(() => {
          if (failureCount === 0) {
            // Loop through and merge task update objects
            for (let loopRequestTaskKey in rawReturnObjects) {
              let loopRequestTask = rawReturnObjects[loopRequestTaskKey]
              for (let loopUpdateObjectTaskKey in loopRequestTask) {
                if (flattenedTaskUpdateObjects[loopUpdateObjectTaskKey] == null) {
                  flattenedTaskUpdateObjects[loopUpdateObjectTaskKey] = loopRequestTask[loopUpdateObjectTaskKey]
                } else {
                  flattenedTaskUpdateObjects[loopUpdateObjectTaskKey] = mergeTwoObjects(
                    loopRequestTask[loopUpdateObjectTaskKey],
                    flattenedTaskUpdateObjects[loopUpdateObjectTaskKey],
                  )
                }
              }
            }
            let projectUpdateObject: TsInterface_UnspecifiedObject = {
              status: taskStatusChangeInstructions['status'],
            }
            if (taskStatusChangeInstructions['status'] === 'active') {
              projectUpdateObject['status_complete'] = false
            } else if (taskStatusChangeInstructions['status'] === 'cancelled' || taskStatusChangeInstructions['status'] === 'completed') {
              projectUpdateObject['status_complete'] = true
            }
            let logTimestamp = new Date()
            let logKey = logTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
            let logUpdateObject = {
              timestamp: logTimestamp,
              associated_user_key: logUserKey,
              associated_user_name: logUserName,
              event: 'update_project_status',
              additional_data: {
                new_status: taskStatusChangeInstructions.status,
              },
            }
            let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
              { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: projectUpdateObject },
              { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), data: logUpdateObject },
            ]
            for (let loopTaskKey in flattenedTaskUpdateObjects) {
              flattenedTaskUpdateObjects[loopTaskKey]['timestamp_last_updated'] = new Date()
              updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskKey), data: flattenedTaskUpdateObjects[loopTaskKey] })
            }
            // Handle base invoices
            if (taskStatusChangeInstructions['status'] === 'cancelled') {
              for (let loopBaseInvoiceKey in projectBaseInvoices) {
                let loopBaseInvoice = projectBaseInvoices[loopBaseInvoiceKey]
                updateArray.push({
                  type: 'setMerge',
                  ref: DatabaseRef_ProjectBaseInvoice_Document(clientKey, loopBaseInvoiceKey),
                  data: {
                    status: 'cancelled',
                    status_before_cancelled: loopBaseInvoice['status'],
                  },
                })
              }
            } else {
              for (let loopBaseInvoiceKey in projectBaseInvoices) {
                let loopBaseInvoice = projectBaseInvoices[loopBaseInvoiceKey]
                if (loopBaseInvoice['status'] === 'cancelled' && loopBaseInvoice['status_before_cancelled'] != null) {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_ProjectBaseInvoice_Document(clientKey, loopBaseInvoiceKey),
                    data: {
                      status: loopBaseInvoice['status_before_cancelled'],
                      status_before_cancelled: null,
                    },
                  })
                }
              }
            }
            DatabaseBatchUpdate(updateArray)
              .then((res_DBU) => {
                cloudFunctionManageRequest('manageTasks', {
                  function: 'refreshProjectTaskProgressBar',
                  client_key: clientKey,
                  project_key: projectKey,
                })
                resolve(res_DBU)
              })
              .catch((rej_DBU) => {
                reject(rej_DBU)
              })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to change project status'),
                details: rLIB('Failed to create necessary task change orders'),
                code: 'ER-D-PS-CPS-XX',
              },
            })
          }
        })
      })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to change project status'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-CPS-01',
        },
      })
    }
  })
}

export const editProjectStatus = (
  clientKey: string,
  projectKey: string,
  project: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_RootData_ClientUser: TsInterface_UnspecifiedObject,
): void => {
  let options = [
    { key: 'unassigned', value: rLIB('Unassigned'), disabled: true },
    { key: 'active', value: rLIB('Active'), disabled: false },
    { key: 'on_hold', value: rLIB('On Hold'), disabled: false },
    { key: 'cancelled', value: rLIB('Cancelled'), disabled: false },
    { key: 'completed', value: rLIB('Completed'), disabled: false },
  ]
  if (project.status === 'active') {
    options[1]['disabled'] = true
  } else if (project.status === 'on_hold') {
    options[2]['disabled'] = true
  } else if (project.status === 'cancelled') {
    options[3]['disabled'] = true
  } else if (project.status === 'completed') {
    options[4]['disabled'] = true
  }
  formInputs_ProjectStatus['status']['options'] = options
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: { status: getProp(project, 'status', null) },
        formInputs: formInputs_ProjectStatus,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let taskStatusChangeInstructions: TsInterface_UnspecifiedObject = {
              status: formSubmittedData['status'],
            }
            if (formSubmittedData['status'] === 'active') {
              if (formSubmittedData['undelete_incomplete_main_tasks'] === true) {
                taskStatusChangeInstructions['undelete_main'] = true
              }
              if (formSubmittedData['delete_completion_cleanup_tasks'] === true) {
                taskStatusChangeInstructions['delete_completion'] = true
              }
              if (formSubmittedData['delete_cancellation_cleanup_tasks'] === true) {
                taskStatusChangeInstructions['delete_cancellation'] = true
              }
            } else if (formSubmittedData['status'] === 'on_hold') {
              if (formSubmittedData['delete_incomplete_main_tasks'] === true) {
                taskStatusChangeInstructions['delete_main'] = true
              }
              if (formSubmittedData['delete_completion_cleanup_tasks'] === true) {
                taskStatusChangeInstructions['delete_completion'] = true
              }
              if (formSubmittedData['delete_cancellation_cleanup_tasks'] === true) {
                taskStatusChangeInstructions['delete_cancellation'] = true
              }
            } else if (formSubmittedData['status'] === 'cancelled') {
              if (formSubmittedData['delete_incomplete_main_tasks'] === true) {
                taskStatusChangeInstructions['delete_main'] = true
              }
              if (formSubmittedData['delete_completion_cleanup_tasks'] === true) {
                taskStatusChangeInstructions['delete_completion'] = true
              }
              if (formSubmittedData['create_cancellation_cleanup_tasks'] === true) {
                taskStatusChangeInstructions['create_cancellation'] = true
              }
            } else if (formSubmittedData['status'] === 'completed') {
              if (formSubmittedData['delete_incomplete_main_tasks'] === true) {
                taskStatusChangeInstructions['delete_main'] = true
              }
              if (formSubmittedData['create_completion_cleanup_tasks'] === true) {
                taskStatusChangeInstructions['create_completion'] = true
              }
              if (formSubmittedData['delete_cancellation_cleanup_tasks'] === true) {
                taskStatusChangeInstructions['delete_cancellation'] = true
              }
            }
            changeProjectStatus(
              clientKey,
              projectKey,
              taskStatusChangeInstructions,
              getProp(uc_RootData_ClientUser, 'key', null),
              getProp(uc_RootData_ClientUser, 'name', null),
            )
              .then((res_CPS) => {
                resolve(res_CPS)
              })
              .catch((rej_CPS) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CPS.error })
                reject(rej_CPS)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: rLIB('Change Project Status'),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

// TODO - implement into project creation
export const generateStandardProjectMessages = (
  setChatThreads: any,
  databaseChatThreads: TsInterface_UnspecifiedObject,
  projectKey: string,
  selectedMessageThreadKey: string | null,
  setSelectedMessageThread: any,
  operation: 'set_threads_to_state' | 'just_return_default_threads',
): TsInterface_ChatThreads => {
  if (databaseChatThreads == null) {
    databaseChatThreads = {}
  }
  let autoGeneratedChatThreads: TsInterface_ChatThreads = {}
  let noMessageJSX = (
    <Box
      component="span"
      className="tw-italic tw-opacity-30 tw-inline-block"
    >
      {rLIB('No messages yet')}
    </Box>
  )
  autoGeneratedChatThreads[projectKey + '_customer'] = {
    associated_member_keys: getProp(databaseChatThreads[projectKey + '_customer'], 'associated_member_keys', {}),
    associated_member_last_read: getProp(databaseChatThreads[projectKey + '_customer'], 'associated_member_last_read', {}),
    associated_member_names: getProp(databaseChatThreads[projectKey + '_customer'], 'associated_member_names', {}),
    associated_member_unread: getProp(databaseChatThreads[projectKey + '_customer'], 'associated_member_unread', {}),
    associated_member_unread_count: getProp(databaseChatThreads[projectKey + '_customer'], 'associated_member_unread_count', {}),
    additional_thread_members: getProp(databaseChatThreads[projectKey + '_customer'], 'additional_thread_members', {}),
    associated_project_key: projectKey,
    key: projectKey + '_customer',
    last_message_sender_key: getProp(databaseChatThreads[projectKey + '_customer'], 'last_message_sender_key', null),
    last_message_sender_name: getProp(databaseChatThreads[projectKey + '_customer'], 'last_message_sender_name', null),
    last_message_text: getProp(databaseChatThreads[projectKey + '_customer'], 'last_message_text', null),
    last_message_timestamp: getProp(databaseChatThreads[projectKey + '_customer'], 'last_message_timestamp', null),
    thread_name_override_desktop: 'Customer and Sales Chat',
    total_members: getProp(databaseChatThreads[projectKey + '_customer'], 'total_members', 0),
  }
  if (operation === 'set_threads_to_state') {
    autoGeneratedChatThreads[projectKey + '_customer']['last_message_text'] = getProp(
      databaseChatThreads[projectKey + '_customer'],
      'last_message_text',
      noMessageJSX,
    )
    autoGeneratedChatThreads[projectKey + '_customer']['avatar_color_override'] = themeVariables.warning_main
    autoGeneratedChatThreads[projectKey + '_customer']['avatar_override'] = (
      <Avatar
        sx={{ bgcolor: themeVariables.warning_main, color: themeVariables.white }}
        className="tw-w-9 tw-h-9"
        variant="rounded"
      >
        <Icon
          icon="comments"
          type="solid"
        />
      </Avatar>
    )
  }
  autoGeneratedChatThreads[projectKey + '_sales'] = {
    associated_member_keys: getProp(databaseChatThreads[projectKey + '_sales'], 'associated_member_keys', {}),
    associated_member_last_read: getProp(databaseChatThreads[projectKey + '_sales'], 'associated_member_last_read', {}),
    associated_member_names: getProp(databaseChatThreads[projectKey + '_sales'], 'associated_member_names', {}),
    associated_member_unread: getProp(databaseChatThreads[projectKey + '_sales'], 'associated_member_unread', {}),
    associated_member_unread_count: getProp(databaseChatThreads[projectKey + '_sales'], 'associated_member_unread_count', {}),
    additional_thread_members: getProp(databaseChatThreads[projectKey + '_sales'], 'additional_thread_members', {}),
    associated_project_key: projectKey,
    key: projectKey + '_sales',
    last_message_sender_key: getProp(databaseChatThreads[projectKey + '_sales'], 'last_message_sender_key', null),
    last_message_sender_name: getProp(databaseChatThreads[projectKey + '_sales'], 'last_message_sender_name', null),
    last_message_text: getProp(databaseChatThreads[projectKey + '_sales'], 'last_message_text', null),
    last_message_timestamp: getProp(databaseChatThreads[projectKey + '_sales'], 'last_message_timestamp', null),
    thread_name_override_desktop: 'Sales Chat',
    total_members: getProp(databaseChatThreads[projectKey + '_sales'], 'total_members', 0),
  }
  if (operation === 'set_threads_to_state') {
    autoGeneratedChatThreads[projectKey + '_sales']['last_message_text'] = getProp(
      databaseChatThreads[projectKey + '_customer'],
      'last_message_text',
      noMessageJSX,
    )
    autoGeneratedChatThreads[projectKey + '_sales']['avatar_color_override'] = themeVariables.success_main
    autoGeneratedChatThreads[projectKey + '_sales']['avatar_override'] = (
      <Avatar
        sx={{ bgcolor: themeVariables.success_main, color: themeVariables.white }}
        className="tw-w-9 tw-h-9"
        variant="rounded"
      >
        <Icon
          icon="comments"
          type="solid"
        />
      </Avatar>
    )
  }
  autoGeneratedChatThreads[projectKey + '_internal1'] = {
    associated_member_keys: getProp(databaseChatThreads[projectKey + '_internal1'], 'associated_member_keys', {}),
    associated_member_last_read: getProp(databaseChatThreads[projectKey + '_internal1'], 'associated_member_last_read', {}),
    associated_member_names: getProp(databaseChatThreads[projectKey + '_internal1'], 'associated_member_names', {}),
    associated_member_unread: getProp(databaseChatThreads[projectKey + '_internal1'], 'associated_member_unread', {}),
    associated_member_unread_count: getProp(databaseChatThreads[projectKey + '_internal1'], 'associated_member_unread_count', {}),
    additional_thread_members: getProp(databaseChatThreads[projectKey + '_internal1'], 'additional_thread_members', {}),
    associated_project_key: projectKey,
    key: projectKey + '_internal1',
    last_message_sender_key: getProp(databaseChatThreads[projectKey + '_internal1'], 'last_message_sender_key', null),
    last_message_sender_name: getProp(databaseChatThreads[projectKey + '_internal1'], 'last_message_sender_name', null),
    last_message_text: getProp(databaseChatThreads[projectKey + '_internal1'], 'last_message_text', null),
    last_message_timestamp: getProp(databaseChatThreads[projectKey + '_internal1'], 'last_message_timestamp', null),
    thread_name_override_desktop: 'Internal Chat - Sale to CAP',
    total_members: getProp(databaseChatThreads[projectKey + '_internal1'], 'total_members', 0),
  }
  if (operation === 'set_threads_to_state') {
    autoGeneratedChatThreads[projectKey + '_internal1']['last_message_text'] = getProp(
      databaseChatThreads[projectKey + '_customer'],
      'last_message_text',
      noMessageJSX,
    )
    autoGeneratedChatThreads[projectKey + '_internal1']['avatar_color_override'] = themeVariables.primary_main
    autoGeneratedChatThreads[projectKey + '_internal1']['avatar_override'] = (
      <Avatar
        sx={{ bgcolor: themeVariables.primary_main, color: themeVariables.white }}
        className="tw-w-9 tw-h-9"
        variant="rounded"
      >
        <Icon
          icon="comments"
          type="solid"
        />
      </Avatar>
    )
  }
  autoGeneratedChatThreads[projectKey + '_internal2'] = {
    associated_member_keys: getProp(databaseChatThreads[projectKey + '_internal2'], 'associated_member_keys', {}),
    associated_member_last_read: getProp(databaseChatThreads[projectKey + '_internal2'], 'associated_member_last_read', {}),
    associated_member_names: getProp(databaseChatThreads[projectKey + '_internal2'], 'associated_member_names', {}),
    associated_member_unread: getProp(databaseChatThreads[projectKey + '_internal2'], 'associated_member_unread', {}),
    associated_member_unread_count: getProp(databaseChatThreads[projectKey + '_internal2'], 'associated_member_unread_count', {}),
    additional_thread_members: getProp(databaseChatThreads[projectKey + '_internal2'], 'additional_thread_members', {}),
    associated_project_key: projectKey,
    key: projectKey + '_internal2',
    last_message_sender_key: getProp(databaseChatThreads[projectKey + '_internal2'], 'last_message_sender_key', null),
    last_message_sender_name: getProp(databaseChatThreads[projectKey + '_internal2'], 'last_message_sender_name', null),
    last_message_text: getProp(databaseChatThreads[projectKey + '_internal2'], 'last_message_text', null),
    last_message_timestamp: getProp(databaseChatThreads[projectKey + '_internal2'], 'last_message_timestamp', null),
    thread_name_override_desktop: 'Internal Chat - CAP to Install',
    total_members: getProp(databaseChatThreads[projectKey + '_internal2'], 'total_members', 0),
  }
  if (operation === 'set_threads_to_state') {
    autoGeneratedChatThreads[projectKey + '_internal2']['last_message_text'] = getProp(
      databaseChatThreads[projectKey + '_customer'],
      'last_message_text',
      noMessageJSX,
    )
    autoGeneratedChatThreads[projectKey + '_internal2']['avatar_color_override'] = themeVariables.primary_main
    autoGeneratedChatThreads[projectKey + '_internal2']['avatar_override'] = (
      <Avatar
        sx={{ bgcolor: themeVariables.primary_main, color: themeVariables.white }}
        className="tw-w-9 tw-h-9"
        variant="rounded"
      >
        <Icon
          icon="comments"
          type="solid"
        />
      </Avatar>
    )
  }
  autoGeneratedChatThreads[projectKey + '_internal3'] = {
    associated_member_keys: getProp(databaseChatThreads[projectKey + '_internal3'], 'associated_member_keys', {}),
    associated_member_last_read: getProp(databaseChatThreads[projectKey + '_internal3'], 'associated_member_last_read', {}),
    associated_member_names: getProp(databaseChatThreads[projectKey + '_internal3'], 'associated_member_names', {}),
    associated_member_unread: getProp(databaseChatThreads[projectKey + '_internal3'], 'associated_member_unread', {}),
    associated_member_unread_count: getProp(databaseChatThreads[projectKey + '_internal3'], 'associated_member_unread_count', {}),
    additional_thread_members: getProp(databaseChatThreads[projectKey + '_internal3'], 'additional_thread_members', {}),
    associated_project_key: projectKey,
    key: projectKey + '_internal3',
    last_message_sender_key: getProp(databaseChatThreads[projectKey + '_internal3'], 'last_message_sender_key', null),
    last_message_sender_name: getProp(databaseChatThreads[projectKey + '_internal3'], 'last_message_sender_name', null),
    last_message_text: getProp(databaseChatThreads[projectKey + '_internal3'], 'last_message_text', null),
    last_message_timestamp: getProp(databaseChatThreads[projectKey + '_internal3'], 'last_message_timestamp', null),
    thread_name_override_desktop: 'Internal Chat - Install to Activation',
    total_members: getProp(databaseChatThreads[projectKey + '_internal3'], 'total_members', 0),
  }
  if (operation === 'set_threads_to_state') {
    autoGeneratedChatThreads[projectKey + '_internal3']['last_message_text'] = getProp(
      databaseChatThreads[projectKey + '_customer'],
      'last_message_text',
      noMessageJSX,
    )
    autoGeneratedChatThreads[projectKey + '_internal3']['avatar_color_override'] = themeVariables.primary_main
    autoGeneratedChatThreads[projectKey + '_internal3']['avatar_override'] = (
      <Avatar
        sx={{ bgcolor: themeVariables.primary_main, color: themeVariables.white }}
        className="tw-w-9 tw-h-9"
        variant="rounded"
      >
        <Icon
          icon="comments"
          type="solid"
        />
      </Avatar>
    )
  }

  // TODO - add custom chat threads in here

  if (operation === 'set_threads_to_state') {
    if (selectedMessageThreadKey != null && autoGeneratedChatThreads[selectedMessageThreadKey] != null) {
      setSelectedMessageThread(autoGeneratedChatThreads[selectedMessageThreadKey])
    }
    setChatThreads(autoGeneratedChatThreads)
    // return autoGeneratedChatThreads
    // } else if( operation === "return_default_threads" ){
    // return autoGeneratedChatThreads
  }
  return autoGeneratedChatThreads
}

// TODO - include in project creation?
export const generateDefaultMessageThreadsInDatabase = (
  clientKey: string,
  projectKey: string,
  messageThreadsToAddToDatabase: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && messageThreadsToAddToDatabase != null) {
      let promiseArray: TsType_UnknownPromise[] = []
      let databaseMessageThreads: TsInterface_UnspecifiedObject = {}
      for (let loopMessageThreadKey in messageThreadsToAddToDatabase) {
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_MessageThread_Document(clientKey, loopMessageThreadKey))
            .then((res_DGD) => {
              databaseMessageThreads[loopMessageThreadKey] = res_DGD.data
            })
            .catch((rej_DGD) => {
              // Nothing
            }),
        )
      }
      Promise.all(promiseArray).finally(() => {
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopMessageThreadKey in messageThreadsToAddToDatabase) {
          let loopMessageToAdd = messageThreadsToAddToDatabase[loopMessageThreadKey]
          delete loopMessageToAdd['avatar_color_override']
          delete loopMessageToAdd['avatar_override']
          delete loopMessageToAdd['last_message_text']
          if (databaseMessageThreads[loopMessageThreadKey] == null) {
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_MessageThread_Document(clientKey, loopMessageThreadKey),
              data: loopMessageToAdd,
            })
          }
        }
        if (updateArray.length > 0) {
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_Project_Document(clientKey, projectKey),
            data: { additional_project_data: { messages_threads_instantiated: true } },
          })
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        } else {
          resolve({ success: true })
        }
      })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to save default message threads'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-PS-GDMTID-01',
        },
      })
    }
  })
}

// Project Filters
export const addFilterOptionsForOneProject = (clientKey: string, projectKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {})
}

export const updateAllProjectFilterOptions = (clientKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // TODO - set cutoff?
  })
}

// Task Groups
export const determineTaskColorStatusSingle = (task: TsInterface_UnspecifiedObject) => {
  let status = 'unknown'
  if (task != null) {
    // Generate Timestamps
    let readyAndWaiting = true
    let startTimestamp = null
    let completedTimestamp = new Date().getTime()
    if (getProp(task, 'timestamp_completed', null) != null) {
      completedTimestamp = returnTimestampFromUnknownDateFormat(getProp(task, 'timestamp_completed', null))
    }
    if (task != null && task['prerequisite_tasks'] != null && objectToArray(task['prerequisite_tasks']).length > 0) {
      for (let loopPrerequisiteTaskKey in task['prerequisite_tasks']) {
        if (task['prerequisite_tasks_completion'] == null || task['prerequisite_tasks_completion'][loopPrerequisiteTaskKey] == null) {
          readyAndWaiting = false
        } else {
          if (
            startTimestamp == null ||
            (!isNaN(task['prerequisite_tasks_completion'][loopPrerequisiteTaskKey]) &&
              task['prerequisite_tasks_completion'][loopPrerequisiteTaskKey] > startTimestamp)
          ) {
            startTimestamp = returnTimestampFromUnknownDateFormat(task['prerequisite_tasks_completion'][loopPrerequisiteTaskKey])
          }
        }
      }
    } else if (getProp(task, 'timestamp_assigned', null) != null) {
      startTimestamp = returnTimestampFromUnknownDateFormat(getProp(task, 'timestamp_assigned', null))
    }
    if (startTimestamp == null) {
      startTimestamp = new Date().getTime()
    }
    let elapsedDays = parseInt(((completedTimestamp - startTimestamp) / millisecondsPerDay).toString())
    // Generate List of Next Open Tasks / Determine Task Status
    if (task['status'] === 'unassigned') {
      status = 'unassigned'
    } else if (task['status'] === 'deleted') {
      status = 'deleted'
    } else if (readyAndWaiting === false) {
      status = 'not_ready'
    } else if (task.status_complete === true) {
      if (task['status_green_days_cutoff'] != null && elapsedDays <= task['status_green_days_cutoff']) {
        status = 'completed_green'
      } else if (task['status_yellow_days_cutoff'] != null && elapsedDays <= task['status_yellow_days_cutoff']) {
        status = 'completed_yellow'
      } else {
        status = 'completed_red'
      }
    } else if (readyAndWaiting === true) {
      if (task['status_green_days_cutoff'] != null && elapsedDays <= task['status_green_days_cutoff']) {
        status = 'ready_green'
      } else if (task['status_yellow_days_cutoff'] != null && elapsedDays <= task['status_yellow_days_cutoff']) {
        status = 'ready_yellow'
      } else {
        status = 'ready_red'
      }
    } else {
      status = 'unknown'
    }
  }
  return status
}

export const returnTaskColorCounts = (tasks: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let taskColorCounts: TsInterface_UnspecifiedObject = {
    total: 0,
    completed: 0,
    completed_total: 0,
    completed_green: 0,
    completed_yellow: 0,
    completed_red: 0,
    ready_total: 0,
    ready_green: 0,
    ready_yellow: 0,
    ready_red: 0,
    not_ready: 0,
    unassigned: 0,
    unknown: 0,
    deleted: 0,
  }
  // Loop through tasks
  for (let loopTaskKey in tasks) {
    let loopTask = tasks[loopTaskKey]
    if (loopTask != null) {
      // Generate Timestamps
      let readyAndWaiting = true
      let startTimestamp = null
      let completedTimestamp = new Date().getTime()
      if (getProp(loopTask, 'timestamp_completed', null) != null) {
        completedTimestamp = returnTimestampFromUnknownDateFormat(getProp(loopTask, 'timestamp_completed', null))
      }
      if (loopTask != null && loopTask['prerequisite_tasks'] != null && objectToArray(loopTask['prerequisite_tasks']).length > 0) {
        for (let loopPrerequisiteTaskKey in loopTask['prerequisite_tasks']) {
          if (loopTask['prerequisite_tasks_completion'] == null || loopTask['prerequisite_tasks_completion'][loopPrerequisiteTaskKey] == null) {
            readyAndWaiting = false
          } else {
            if (
              startTimestamp == null ||
              (!isNaN(loopTask['prerequisite_tasks_completion'][loopPrerequisiteTaskKey]) &&
                loopTask['prerequisite_tasks_completion'][loopPrerequisiteTaskKey] > startTimestamp)
            ) {
              startTimestamp = returnTimestampFromUnknownDateFormat(loopTask['prerequisite_tasks_completion'][loopPrerequisiteTaskKey])
            }
          }
        }
      } else if (getProp(loopTask, 'timestamp_assigned', null) != null) {
        startTimestamp = returnTimestampFromUnknownDateFormat(getProp(loopTask, 'timestamp_assigned', null))
      }
      if (startTimestamp == null) {
        startTimestamp = new Date().getTime()
      }
      let elapsedDays = parseInt(((completedTimestamp - startTimestamp) / millisecondsPerDay).toString())
      // Generate Count Stats
      taskColorCounts['total']++
      // Generate List of Next Open Tasks / Determine Task Status
      if (loopTask['status'] === 'unassigned') {
        taskColorCounts['unassigned']++
      } else if (loopTask['status'] === 'deleted') {
        taskColorCounts['deleted']++
        taskColorCounts['total']--
      } else if (readyAndWaiting === false) {
        taskColorCounts['not_ready']++
      } else if (loopTask.status_complete === true) {
        taskColorCounts['completed_total']++
        taskColorCounts['completed']++
        if (loopTask['status_green_days_cutoff'] != null && elapsedDays <= loopTask['status_green_days_cutoff']) {
          taskColorCounts['completed_green']++
        } else if (loopTask['status_yellow_days_cutoff'] != null && elapsedDays <= loopTask['status_yellow_days_cutoff']) {
          taskColorCounts['completed_yellow']++
        } else {
          taskColorCounts['completed_red']++
        }
        if (completedTimestamp > taskColorCounts['timestamp_last_task_completed']) {
          taskColorCounts['timestamp_last_task_completed'] = completedTimestamp
        }
      } else if (readyAndWaiting === true) {
        taskColorCounts['ready_total']++
        if (loopTask['status_green_days_cutoff'] != null && elapsedDays <= loopTask['status_green_days_cutoff']) {
          taskColorCounts['ready_green']++
        } else if (loopTask['status_yellow_days_cutoff'] != null && elapsedDays <= loopTask['status_yellow_days_cutoff']) {
          taskColorCounts['ready_yellow']++
        } else {
          taskColorCounts['ready_red']++
        }
      } else {
        taskColorCounts['unknown']++
      }
    }
  }
  return taskColorCounts
}

export const rJSX_TaskBreakdownBarFullColors = (taskCounts: TsInterface_UnspecifiedObject): JSX.Element => {
  let breakdownBarJSX = <></>
  if (taskCounts == null || taskCounts['total'] == null || taskCounts['total'] === 0) {
    breakdownBarJSX = (
      <Box>
        <Box
          sx={{ width: '100%', height: '40px', minWidth: '300px', background: themeVariables.background_default }}
          className="tw-rounded-lg tw-text-center tw-p-2"
        >
          <Typography
            variant="body1"
            className="tw-opacity-30"
          >
            {rLIB('No Tasks')}
          </Typography>
        </Box>
      </Box>
    )
  } else {
    breakdownBarJSX = (
      <Box
        sx={{ width: '100%', height: '40px', minWidth: '300px' }}
        className="tw-rounded-lg tw-overflow-hidden"
      >
        <Stack direction="row">
          {/* <Tooltip title={ rLIB("Completed Tasks") } placement="top">
						<Box
							className="tw-text-center tw-py-2"
							sx={{
								background: themeVariables.info_main,
								width: (getProp(taskCounts, "completed", 0) / taskCounts.total * 100) + "%",
								height: "40px"
						}}>
							{ getProp(taskCounts, "completed", "") }
						</Box>
					</Tooltip> */}
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'completed_green', 0)} {rLIB('Completed Tasks')}: {rLIB('Green Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2 project_progress_bar_complete_blue_green"
              sx={{
                borderTop: '0px solid ' + themeVariables.info_main,
                // background: themeVariables.success_main,
                width: (getProp(taskCounts, 'completed_green', 0) / taskCounts.total) * 100 + '%',
                height: '40px',
              }}
            >
              {getProp(taskCounts, 'completed_green', '')}
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'completed_yellow', 0)} {rLIB('Completed Tasks')}: {rLIB('Yellow Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2 project_progress_bar_complete_blue_yellow"
              sx={{
                borderTop: '0px solid ' + themeVariables.info_main,
                // background: themeVariables.warning_main,
                width: (getProp(taskCounts, 'completed_yellow', 0) / taskCounts.total) * 100 + '%',
                height: '40px',
              }}
            >
              {getProp(taskCounts, 'completed_yellow', '')}
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'completed_red', 0)} {rLIB('Completed Tasks')}: {rLIB('Red Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2 project_progress_bar_complete_blue_red"
              sx={{
                borderTop: '0px solid ' + themeVariables.info_main,
                // background: themeVariables.error_main,
                width: (getProp(taskCounts, 'completed_red', 0) / taskCounts.total) * 100 + '%',
                height: '40px',
              }}
            >
              {getProp(taskCounts, 'completed_red', '')}
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {rLIB('Active Tasks')}: {rLIB('Green Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.success_main,
                width: (getProp(taskCounts, 'ready_green', 0) / taskCounts.total) * 100 + '%',
                height: '40px',
              }}
            >
              {getProp(taskCounts, 'ready_green', '')}
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {rLIB('Active Tasks')}: {rLIB('Yellow Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.warning_main,
                width: (getProp(taskCounts, 'ready_yellow', 0) / taskCounts.total) * 100 + '%',
                height: '40px',
              }}
            >
              {getProp(taskCounts, 'ready_yellow', '')}
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {rLIB('Active Tasks')}: {rLIB('Red Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.error_main,
                width: (getProp(taskCounts, 'ready_red', 0) / taskCounts.total) * 100 + '%',
                height: '40px',
              }}
            >
              {getProp(taskCounts, 'ready_red', '')}
            </Box>
          </Tooltip>
          <Tooltip
            title={rLIB('Tasks Waiting on Prerequisites')}
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.gray_600,
                width: (getProp(taskCounts, 'not_ready', 0) / taskCounts.total) * 100 + '%',
                height: '40px',
              }}
            >
              {getProp(taskCounts, 'not_ready', '')}
            </Box>
          </Tooltip>
          <Tooltip
            title={rLIB('Unassigned Tasks')}
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2 project_progress_bar_unassigned"
              sx={{
                background: themeVariables.gray_500,
                width: (getProp(taskCounts, 'unassigned', 0) / taskCounts.total) * 100 + '%',
                height: '40px',
              }}
            >
              {getProp(taskCounts, 'unassigned', '')}
            </Box>
          </Tooltip>
          <Tooltip
            title={rLIB('Deleted Tasks')}
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.gray_700,
                width: (getProp(taskCounts, 'deleted', 0) / taskCounts.total) * 100 + '%',
                height: '40px',
              }}
            >
              {getProp(taskCounts, 'deleted', '')}
            </Box>
          </Tooltip>
        </Stack>
        <style>{progressBarCss}</style>
      </Box>
    )
  }
  return breakdownBarJSX
}

export const returnGroupedTasksData = (
  taskGroupsArray: TsInterface_UnspecifiedObject[],
  tasks: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject[] => {
  const groupedTasks = []

  // Create a map of task group keys for quick lookup
  const taskGroupKeys = new Set(taskGroupsArray.map((group) => group.key))

  // Iterate over each task group
  for (const taskGroup of taskGroupsArray) {
    const { key } = taskGroup

    // Filter tasks based on associated_task_group_key
    const filteredTasks = Object.values(tasks).filter((task) => task.associated_task_group_key === key)

    // Create a new task group object with taskCount and tasks properties
    const modifiedTaskGroup = {
      ...taskGroup,
      taskCount: filteredTasks.length,
      tasks: filteredTasks.reduce((acc, task) => {
        acc[task.key] = task
        return acc
      }, {}),
    }

    // Add the modified task group to the groupedTasks array
    groupedTasks.push(modifiedTaskGroup)
  }

  // Check for tasks without a matching task group or unspecified task group
  const unspecifiedTasks = Object.values(tasks).filter((task) => !taskGroupKeys.has(task.associated_task_group_key))

  if (unspecifiedTasks.length > 0) {
    const unspecifiedTaskGroup = {
      key: 'unspecified',
      name: 'Unspecified',
      type: '',
      taskCount: unspecifiedTasks.length,
      tasks: unspecifiedTasks.reduce((acc, task) => {
        acc[task.key] = task
        return acc
      }, {}),
    }
    groupedTasks.push(unspecifiedTaskGroup)
  }

  // Loop through and determine progress bars
  for (let loopGroupIndex in groupedTasks) {
    let loopGroup: TsInterface_UnspecifiedObject = groupedTasks[loopGroupIndex]
    if (loopGroup.tasks != null) {
      loopGroup['task_color_counts'] = returnTaskColorCounts(loopGroup.tasks)
    }
  }

  return groupedTasks
}
