import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

export const DatabaseRef_SalesOpportunities_v2_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities_v2', 'main')
}

export const DatabaseRef_SalesOpportunity_v2_Document = (clientKey: string, opportunityKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'opportunities_v2', 'main', opportunityKey)
}

export const DatabaseRef_SaleOpportunityFiles_Collection = (clientKey: string, opportunityKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities_v2', 'main', opportunityKey, 'files')
}

export const DatabaseRef_SaleOpportunityFile_Document = (clientKey: string, opportunityKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'opportunities_v2', 'main', opportunityKey, 'files', fileKey)
}

export const DatabaseRef_SaleOpportunityPhotos_Collection = (clientKey: string, opportunityKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities_v2', 'main', opportunityKey, 'photos')
}

export const DatabaseRef_SaleOpportunityPhoto_Document = (clientKey: string, opportunityKey: string, photoKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'opportunities_v2', 'main', opportunityKey, 'photos', photoKey)
}
