///////////////////////////////
// Description
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { globalTaskTypes } from 'app/models/tasks/global_tasks'
import { downloadAdditionalWorkQuotePDF } from 'app/pages/invoices/services/invoice_pdf_templates'
import { createAdditionalWorkInvoice } from 'app/pages/invoices/services/invoice_progress_functions'
import { useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_ActiveTaskBlueprints_Query } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_SalesPartner_InvoiceRates_Collection } from 'rfbp_aux/services/database_endpoints/finances/invoice_rates'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TableBasic } from 'rfbp_core/components/table/table_basic'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { dynamicSort, formatCurrency, getProp, objectToArray, underscoresToSpacesAndCapitalize } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_NewAdditionalWorkQuoteDialog {
  clientKey: string
  projectKey: string
  project: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

const tableColumns_SowItems: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
  task_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData['task_type'] != null &&
          globalTaskTypes != null &&
          globalTaskTypes[rowData['task_type'] as string] != null &&
          globalTaskTypes[rowData['task_type'] as string]['name'] != null
        ) {
          cellJSX = globalTaskTypes[rowData['task_type'] as string]['name']
        } else {
          cellJSX = getProp(rowData, 'task_type', '')
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Task Type')
      },
      header_sort_by: null,
    },
  },
  TEMP_completion_time: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData['key'] != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_selectedTasks != null &&
          tableAdditionalData.us_selectedTasks[rowData['key'] as string] === true
        ) {
          cellJSX = (
            <Box>
              <Tooltip
                title={rLIB('Remove from quote')}
                placement="right"
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  startIcon={<Icon icon="circle-xmark" />}
                  className="bp_icon_only_button"
                  onClick={() => {
                    tableAdditionalData.us_setSelectedTasks({
                      ...tableAdditionalData.us_selectedTasks,
                      [rowData['key'] as string]: false,
                    })
                  }}
                />
              </Tooltip>
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Tooltip
                title={rLIB('Add to quote')}
                placement="right"
              >
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  startIcon={<Icon icon="circle-plus" />}
                  className="bp_icon_only_button"
                  onClick={() => {
                    tableAdditionalData.us_setSelectedTasks({
                      ...tableAdditionalData.us_selectedTasks,
                      [rowData['key'] as string]: true,
                    })
                  }}
                />
              </Tooltip>
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
}

const tableSettings_SowItems: TsInterface_TableSettings = {
  paginated: false,
  // pagination_rows_per_page_default: 100,
  // pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

/* eslint-disable react/prop-types */
export const NewAdditionalWorkQuoteDialog: React.FC<TsInterface_NewAdditionalWorkQuoteDialog> = ({ clientKey, projectKey, project }): JSX.Element => {
  // Hooks - useContext, useState, useReducer, other
  // const [us_billingInvoice, us_setBillingInvoice] = useState<boolean>(false)
  const [us_activeBlueprintTasks, us_setActiveBlueprintTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalWorkTaskType, us_setAdditionalWorkTaskType] = useState<TsInterface_UnspecifiedObject>({})
  const [us_billableIdNumber, us_setBillableIdNumber] = useState<string>('')
  const [us_creatingQuote, us_setCreatingQuote] = useState<boolean>(false)
  const [us_downloadingQuote, us_setDownloadingQuote] = useState<boolean>(false)
  const [us_filteredTasks, us_setFilteredTasks] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_salesPartnerInvoiceRates, us_setSalesPartnerInvoiceRates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedTaskVariablePricing, us_setSelectedTaskVariablePricing] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedTasks, us_setSelectedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskSearch, us_setTaskSearch] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Hooks - useEffect
  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setActiveBlueprintTasks(newData)
      }
      ur_forceRerender()
    }
    if (projectKey != null) {
      unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTaskBlueprints_Query(clientKey), updateLiveData)
    } else {
      us_setActiveBlueprintTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [clientKey, projectKey, ur_forceRerender])

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSalesPartnerInvoiceRates(newData)
      }
      ur_forceRerender()
    }
    if (project != null && project.associated_sales_partner_key != null) {
      unsubscribeLiveData = DatabaseGetLiveCollection(
        DatabaseRef_SalesPartner_InvoiceRates_Collection(clientKey, project.associated_sales_partner_key),
        updateLiveData,
      )
    } else {
      us_setActiveBlueprintTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [clientKey, project, projectKey, ur_forceRerender])

  useEffect(() => {
    let filteredTasks: TsInterface_UnspecifiedObject[] = []
    for (let loopTaskKey in us_activeBlueprintTasks) {
      let loopTask = us_activeBlueprintTasks[loopTaskKey]
      // Checkbox Filter
      let addedTask = false
      if (loopTask != null && loopTask.task_type != null) {
        if (us_additionalWorkTaskType[loopTask.task_type] === true) {
          filteredTasks.push(loopTask)
          addedTask = true
        }
      }
      // Search Filter
      if (addedTask === false && us_taskSearch != null && us_taskSearch !== '') {
        if (loopTask.name.toLowerCase().includes(us_taskSearch.toLowerCase())) {
          filteredTasks.push(loopTask)
          addedTask = true
        }
      }
    }
    us_setFilteredTasks(filteredTasks)
    ur_forceRerender()
    return () => {}
  }, [us_activeBlueprintTasks, us_additionalWorkTaskType, us_taskSearch, ur_forceRerender])

  // Functions
  const returnTasksToAddToQuote = (): TsInterface_UnspecifiedObject => {
    const tasksToAddToQuote: TsInterface_UnspecifiedObject = {}
    for (const taskKey in us_selectedTasks) {
      if (us_selectedTasks[taskKey] === true && us_activeBlueprintTasks[taskKey] != null) {
        tasksToAddToQuote[taskKey] = us_activeBlueprintTasks[taskKey]
      }
    }
    return tasksToAddToQuote
  }

  const returnSalesPartnerInvoiceRates = (taskKey: string): TsInterface_UnspecifiedObject => {
    if (taskKey != null && us_salesPartnerInvoiceRates != null && us_salesPartnerInvoiceRates[taskKey] != null) {
      return us_salesPartnerInvoiceRates[taskKey]
    }
    return {}
  }

  const returnTaskLineItems = (taskKey: string): TsInterface_UnspecifiedObject => {
    let lineItems = {}
    if (taskKey != null) {
      let taskInvoiceRates = returnSalesPartnerInvoiceRates(taskKey)
      if (
        project != null &&
        project.associated_region_key != null &&
        taskInvoiceRates != null &&
        taskInvoiceRates['line_items_' + project.associated_region_key] != null &&
        objectToArray(taskInvoiceRates['line_items_' + project.associated_region_key]).length > 0
      ) {
        lineItems = taskInvoiceRates['line_items_' + project.associated_region_key]
      } else if (
        taskInvoiceRates != null &&
        taskInvoiceRates['line_items_default'] != null &&
        objectToArray(taskInvoiceRates['line_items_default']).length > 0
      ) {
        lineItems = taskInvoiceRates['line_items_default']
      }
    }
    return lineItems
  }

  const returnFormattedLineItems = (tasksToBill: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject[] => {
    let tasksWithLineItems: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in tasksToBill) {
      if (us_selectedTasks[loopTaskKey] == true) {
        tasksWithLineItems[loopTaskKey] = {
          name: tasksToBill[loopTaskKey].name,
          line_items: returnTaskLineItems(loopTaskKey),
        }
      }
    }
    // Format the line items for the PDF
    let formattedLineItems: TsInterface_UnspecifiedObject[] = []
    // Loop through tasks
    for (let loopTaskKey in tasksWithLineItems) {
      let loopTask = tasksWithLineItems[loopTaskKey]
      for (let loopLineItemKey in loopTask.line_items) {
        let loopLineItem = loopTask.line_items[loopLineItemKey]
        let pdfLineItem = {
          permitting_fees: 0,
          line_item_total: null,
          job_code: project.id_number,
          description: loopTask.name + ' - ' + loopLineItem.name,
          amount: getProp(loopLineItem, 'price', 0),
          rate: null,
          adders: null,
          notes: null,
          order: getProp(loopLineItem, 'order', 0),
        }
        // Add Variable Pricing
        if (loopLineItem.price_type === 'variable') {
          if (us_selectedTaskVariablePricing != null && us_selectedTaskVariablePricing[loopTaskKey + '_' + loopLineItemKey] != null) {
            pdfLineItem.amount = parseFloat(getProp(us_selectedTaskVariablePricing, loopTaskKey + '_' + loopLineItemKey, '0'))
          }
        }
        formattedLineItems.push(pdfLineItem)
      }
    }
    return formattedLineItems.sort(dynamicSort('order', 'asc'))
  }

  const createQuote = () => {
    us_setCreatingQuote(true)
    let billToResponse = checkFormMatchingBillTo()
    let tasksToBill = returnTasksToAddToQuote()
    let approvalTypes: TsInterface_UnspecifiedObject = {}
    if (
      billToResponse.success === true &&
      billToResponse.billTo != null &&
      objectToArray(tasksToBill).length > 0 &&
      us_billableIdNumber != null &&
      us_billableIdNumber !== ''
    ) {
      let associatedTasks: TsInterface_UnspecifiedObject = {}
      for (let loopTaskKey in tasksToBill) {
        associatedTasks[loopTaskKey] = {
          key: loopTaskKey,
          name: tasksToBill[loopTaskKey].name,
          status: 'not_created',
        }
        if (
          us_salesPartnerInvoiceRates != null &&
          us_salesPartnerInvoiceRates[loopTaskKey] != null &&
          getProp(us_salesPartnerInvoiceRates[loopTaskKey], 'approval_type', null) != null
        ) {
          approvalTypes[getProp(us_salesPartnerInvoiceRates[loopTaskKey], 'approval_type', null)] = true
        }
      }
      let approvalType = 'internal_optional_evidence'
      if (objectToArray(approvalTypes).length === 1) {
        for (let loopApprovalTypeKey in approvalTypes) {
          approvalType = loopApprovalTypeKey
        }
      } else if (approvalTypes['internal_required_evidence'] === true) {
        approvalType = 'internal_required_evidence'
      }
      createAdditionalWorkInvoice(
        clientKey,
        projectKey,
        approvalType,
        billToResponse.billTo as 'sales_partner' | 'customer',
        us_billableIdNumber,
        returnFormattedLineItems(tasksToBill),
        associatedTasks,
      )
        .then(() => {
          us_setCreatingQuote(false)
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        })
        .catch((rej_CAWI) => {
          console.error(rej_CAWI)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CAWI.error })
          us_setCreatingQuote(false)
        })
    }
  }

  const downloadPreliminaryQuote = () => {
    us_setDownloadingQuote(true)
    let billToResponse = checkFormMatchingBillTo()
    let tasksToBill = returnTasksToAddToQuote()
    if (
      billToResponse.success === true &&
      billToResponse.billTo != null &&
      objectToArray(tasksToBill).length > 0 &&
      us_billableIdNumber != null &&
      us_billableIdNumber !== ''
    ) {
      downloadAdditionalWorkQuotePDF(clientKey, projectKey, billToResponse.billTo, us_billableIdNumber, returnFormattedLineItems(tasksToBill), 'download')
        .then(() => {
          us_setDownloadingQuote(false)
        })
        .catch((rej_DPQPDF) => {
          console.error(rej_DPQPDF)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DPQPDF.error })
          us_setDownloadingQuote(false)
        })
    }
  }

  const checkFormMatchingBillTo = () => {
    // Loop through selected tasks and verify that they all have the same bill_to value
    let billToValues: TsInterface_UnspecifiedObject = {}
    let billTo: string | null = null
    let missingBillToValues: boolean = false
    let missingLineItems: boolean = false
    for (let loopSelectedTaskKey in us_selectedTasks) {
      if (us_selectedTasks[loopSelectedTaskKey] === true) {
        // Check for Bill To discrepancies
        if (
          us_salesPartnerInvoiceRates != null &&
          us_salesPartnerInvoiceRates[loopSelectedTaskKey] != null &&
          us_salesPartnerInvoiceRates[loopSelectedTaskKey].bill_to != null
        ) {
          billTo = us_salesPartnerInvoiceRates[loopSelectedTaskKey].bill_to
          billToValues[us_salesPartnerInvoiceRates[loopSelectedTaskKey].bill_to] = true
        } else {
          missingBillToValues = true
        }
        // Check for Line Item discrepancies
        if (objectToArray(returnTaskLineItems(loopSelectedTaskKey)).length === 0) {
          missingLineItems = true
        }
      }
    }
    if (missingBillToValues === true) {
      return {
        success: false,
        error: rLIB('Tasks missing bill to configuration'),
      }
    } else if (objectToArray(billToValues).length > 1) {
      return {
        success: false,
        error: rLIB('Tasks have different bill to configurations'),
      }
    } else if (missingLineItems === true) {
      return {
        success: false,
        error: rLIB('Tasks missing billable line items'),
      }
    } else if (us_billableIdNumber === null || us_billableIdNumber === '') {
      return {
        success: false,
        error: rLIB('Billable reference number is required'),
      }
    } else {
      return {
        success: true,
        billTo: billTo,
      }
    }
  }

  // JSX Generation
  const rJSX_CreateQuoteButton = (): JSX.Element => {
    const taskCount = objectToArray(returnTasksToAddToQuote()).length
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={
          us_creatingQuote ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="circle-plus" />
          )
        }
        disabled={taskCount === 0 || checkFormMatchingBillTo().success === false || us_creatingQuote === true}
        onClick={() => {
          createQuote()
        }}
      >
        {rLIB('Create Quote')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_TaskTag = (task: TsInterface_UnspecifiedObject): JSX.Element => {
    let tagJSX = <></>
    if (task != null && task.key != null) {
      let taskInvoiceRates = returnSalesPartnerInvoiceRates(task.key)
      if (taskInvoiceRates != null && taskInvoiceRates.require_quote === false) {
        tagJSX = (
          <Chip
            size="small"
            variant="outlined"
            label={rLIB('Quote not required')}
            color="warning"
          />
        )
      }
    }
    return tagJSX
  }

  const rJSX_BillTo = (task: TsInterface_UnspecifiedObject): JSX.Element => {
    let tagJSX = <></>
    if (task != null && task.key != null) {
      let taskInvoiceRates = returnSalesPartnerInvoiceRates(task.key)
      if (taskInvoiceRates != null && taskInvoiceRates.bill_to != null) {
        tagJSX = (
          <Box>
            <Typography variant="body1">
              {rLIB('Bill to')}: {underscoresToSpacesAndCapitalize(taskInvoiceRates.bill_to)}
            </Typography>
          </Box>
        )
      }
    }
    return tagJSX
  }

  const rJSX_LineItemCostCellContent = (task: TsInterface_UnspecifiedObject, lineItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let contentJSX = <></>
    if (lineItem != null && lineItem.price_type === 'flat') {
      contentJSX = <Typography variant="body1">{formatCurrency(lineItem.price)}</Typography>
    } else if (lineItem != null && lineItem.price_type === 'variable') {
      let variablePriceValue = 0
      if (us_selectedTaskVariablePricing != null && us_selectedTaskVariablePricing[task.key + '_' + lineItem.key] != null) {
        variablePriceValue = us_selectedTaskVariablePricing[task.key + '_' + lineItem.key]
      }
      contentJSX = (
        <Box>
          <FormControl
            fullWidth
            sx={{ margin: '0px !important' }}
          >
            <TextField
              color="primary"
              value={variablePriceValue}
              margin="normal"
              type="number"
              sx={{ margin: '0px !important' }}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  us_setSelectedTaskVariablePricing({
                    ...us_selectedTaskVariablePricing,
                    [task.key + '_' + lineItem.key]: event.target.value,
                  })
                }
              }}
              InputProps={{
                startAdornment: (
                  <Icon
                    icon="dollar-sign"
                    className="tw-mr-2"
                  />
                ),
              }}
              variant="outlined"
            />
          </FormControl>
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_TaskLineItemsTable = (task: TsInterface_UnspecifiedObject): JSX.Element => {
    let tableJSX = <></>
    if (task != null && task.key != null) {
      let lineItemList = returnTaskLineItems(task.key)
      if (lineItemList != null && objectToArray(lineItemList).length > 0) {
        tableJSX = (
          <Box>
            <Card>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {objectToArray(lineItemList)
                      .sort(dynamicSort('order', 'asc'))
                      .map((lineItem: TsInterface_UnspecifiedObject, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{lineItem.name}</TableCell>
                          <TableCell>{rJSX_LineItemCostCellContent(task, lineItem)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Box>
        )
      } else {
        tableJSX = (
          <Box className="tw-text-left">
            <Stack
              direction="row"
              spacing={1}
              className="tw-items-center"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-text-warning_main"
              />
              <Typography
                variant="h6"
                className="tw-opacity-40"
              >
                {rLIB('No line items found')}
              </Typography>
            </Stack>
          </Box>
        )
      }
    }
    return tableJSX
  }

  const rJSX_TasksToAddToQuote = (): JSX.Element => {
    const taskCount = objectToArray(returnTasksToAddToQuote()).length
    if (taskCount === 0) {
      return (
        <Box className="tw-text-center">
          <Typography
            variant="h6"
            className="tw-opacity-40"
          >
            {rLIB('Select tasks to add to quote')}
          </Typography>
          <Box className="tw-mt-2 tw-text-center">{rJSX_CreateQuoteButton()}</Box>
        </Box>
      )
    } else {
      return (
        <Box>
          {objectToArray(returnTasksToAddToQuote())
            .sort(dynamicSort('name', 'asc'))
            .map((task: TsInterface_UnspecifiedObject, index: number) => (
              <Box
                key={index}
                className="tw-mb-4"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  className="tw-items-center tw-justify-between"
                >
                  <Typography variant="h6">
                    <Icon
                      icon="square-check"
                      type="regular"
                      className="tw-mr-2 tw-text-primary_main"
                    />
                    {task.name}
                    <Icon
                      icon="trash"
                      className="tw-ml-2 tw-cursor-pointer tw-opacity-40 hover:tw-opacity-100 hover:tw-text-error_main"
                      tooltip={rLIB('Remove from quote')}
                      tooltipPlacement="right"
                      onClick={() => {
                        us_setSelectedTasks({
                          ...us_selectedTasks,
                          [task.key]: false,
                        })
                      }}
                    />
                  </Typography>
                  {rJSX_TaskTag(task)}
                </Stack>
                <Box className="tw-ml-6">
                  {rJSX_BillTo(task)}
                  {rJSX_TaskLineItemsTable(task)}
                </Box>
              </Box>
            ))}
          <Box className="tw-mt-2 tw-text-center">{rJSX_BillableReferenceNumberInput()}</Box>
          <Box className="tw-mt-2 tw-text-center">
            {rJSX_DownloadQuoteTemplateButton()}
            {rJSX_CreateQuoteButton()}
            {rJSX_ErrorMessages()}
          </Box>
        </Box>
      )
    }
  }

  const rJSX_ErrorMessages = (): JSX.Element => {
    let errorMessagesJSX = <></>
    if (checkFormMatchingBillTo().success === false) {
      errorMessagesJSX = (
        <Typography
          variant="body1"
          className="tw-text-warning_main"
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2 tw-text-warning_main"
          />
          {checkFormMatchingBillTo().error}
        </Typography>
      )
    }
    return errorMessagesJSX
  }

  const rJSX_DownloadQuoteTemplateButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        color="error"
        startIcon={
          us_downloadingQuote ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="file-pdf" />
          )
        }
        className="tw-mr-2"
        disabled={checkFormMatchingBillTo().success === false || us_downloadingQuote === true}
        onClick={() => {
          downloadPreliminaryQuote()
        }}
      >
        {rLIB('Download Quote')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_BillableReferenceNumberInput = (): JSX.Element => {
    let inputJSX = <></>
    inputJSX = (
      <Box>
        <FormControl sx={{ width: '100%' }}>
          <TextField
            label={rLIB('Billable Reference Number')}
            value={us_billableIdNumber}
            sx={{ width: '100%' }}
            onChange={(e) => {
              us_setBillableIdNumber(e.target.value)
            }}
          />
        </FormControl>
      </Box>
    )
    return inputJSX
  }

  const returnInputDataAsArray = (): any[] => {
    let formattedInputData: any[] = []
    // Causes problems to send this over in value but an empty array works with custom render that always displays even when empty
    return formattedInputData
  }

  const changeCheckboxValue = (option: TsInterface_UnspecifiedObject): void => {
    if (option != null && option.target != null && option.target.value != null && option.target.value[0] != null) {
      let optionKey = option.target.value[0]
      let copyOfTaskTypeArray: TsInterface_UnspecifiedObject = { ...us_additionalWorkTaskType }
      if (copyOfTaskTypeArray[optionKey] == null || copyOfTaskTypeArray[optionKey] === false) {
        copyOfTaskTypeArray[optionKey] = true
      } else {
        copyOfTaskTypeArray[optionKey] = false
      }
      us_setAdditionalWorkTaskType(copyOfTaskTypeArray)
    }
  }

  const checkIfChecked = (task: TsInterface_UnspecifiedObject): boolean => {
    let checked = false
    if (task != null && task['key'] != null && us_additionalWorkTaskType != null && us_additionalWorkTaskType[task['key']] === true) {
      checked = true
    }
    return checked
  }

  const rJSX_TaskTypeSelector = (): JSX.Element => {
    let taskTypesArray = objectToArray(globalTaskTypes).filter((taskType: TsInterface_UnspecifiedObject) => taskType.additional_work_task === true)
    let taskCategory = 'project'
    taskTypesArray = taskTypesArray.filter((taskType: TsInterface_UnspecifiedObject) => taskType.task_category === taskCategory)
    let taskTypeSelectorJSX = (
      <FormControl>
        <Select
          color="primary"
          value={returnInputDataAsArray()}
          multiple
          onChange={(option) => {
            changeCheckboxValue(option)
          }}
          displayEmpty={true}
          renderValue={(selected: any) => {
            let displayString = ''
            let optionsObject = globalTaskTypes
            let loopOptions: TsInterface_UnspecifiedObject = { ...us_additionalWorkTaskType }
            for (let loopOptionKey in loopOptions) {
              if (loopOptions[loopOptionKey] === true) {
                if (optionsObject != null && optionsObject[loopOptionKey] != null && optionsObject[loopOptionKey]['name_string'] != null) {
                  if (displayString.length > 0) {
                    displayString += ', '
                  }
                  displayString += optionsObject[loopOptionKey]['name_string']
                }
              }
            }
            return displayString
          }}
          variant="outlined"
          notched={true}
        >
          {taskTypesArray.sort(dynamicSort('value', 'asc')).map((task: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={task.key}
            >
              <Checkbox checked={checkIfChecked(task)} />
              <ListItemText primary={task.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )

    return taskTypeSelectorJSX
  }

  const rJSX_TaskSearch = (): JSX.Element => {
    let searchJSX = (
      <Box>
        <TextField
          label={rLIB('Search')}
          value={us_taskSearch}
          onChange={(e) => {
            us_setTaskSearch(e.target.value)
          }}
        />
      </Box>
    )
    return searchJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Dialog
        className="bp_dialog_xl_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 1, color: '#fff !important' }}
            >
              <Icon icon="circle-plus" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              <Box className="tw-inline-block">{rLIB('New Additional Work Quote')}</Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box className="tw-p-4">
            <Grid2
              container
              spacing={2}
            >
              <Grid2
                xs={6}
                sm={6}
                md={6}
                lg={6}
              >
                <Stack
                  direction="row"
                  spacing={1}
                >
                  {rJSX_TaskTypeSelector()}
                  {rJSX_TaskSearch()}
                </Stack>
                <Card className="tw-mt-2">
                  <TableBasic
                    tableAdditionalData={{
                      us_selectedTasks: us_selectedTasks,
                      us_setSelectedTasks: us_setSelectedTasks,
                    }}
                    tableColumns={tableColumns_SowItems}
                    tableData={us_filteredTasks}
                    tableSettings={tableSettings_SowItems}
                  />
                </Card>
              </Grid2>
              <Grid2
                xs={6}
                sm={6}
                md={6}
                lg={6}
              >
                <Box className="tw-text-left">
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {rLIB('Tasks to add to quote')}
                  </Typography>
                </Box>
                <Box className="tw-ml-6">{rJSX_TasksToAddToQuote()}</Box>
              </Grid2>
            </Grid2>
          </Box>
        </DialogContent>
      </Dialog>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
