import { TsInterface_AcceptedZipCodes, TsInterface_UtilityCompanyMap } from '../interfaces/i_sales_tools'

const illinoisUtilityCompanies = {
  'Ameren Illinois': 'A',
  'Commonwealth Edison Co': 'B',
  'Tri-County Electric Coop, Inc': 'A',
  'Village of Chatham': 'A',
  'City Water Light & Power': 'A',
  'Rural Electric Conven Coop': 'A',
  'City of Highland': 'A',
  'Menard Electric Coop': 'A',
  'Wayne-White Counties Elec Coop': 'A',
  'Corn Belt Energy Corporation': 'A',
  'Southwestern Electric Coop Inc': 'A',
  'Southern Illinois Elec Coop': 'A',
  'Rock Energy Cooperative': 'B',
  'Shelby Electric Coop, Inc': 'A',
  'City of Batavia': 'B',
}

const texasUtilityCompanies = {
  'CPS Energy': 'A',
  'Bluebonnet Electric Coop': 'A',
  'Oncor': 'A',
  'Austin Energy': 'A',
  'CenterPoint Energy': 'A',
  'Pedernales': 'A',
  'GVEC': 'A',
}

const utahUtilityCompanies = {}

export const utilityCompanyMap: TsInterface_UtilityCompanyMap = {
  IL: illinoisUtilityCompanies,
  TX: texasUtilityCompanies,
}

export const zipCodesAccepted: TsInterface_AcceptedZipCodes = {
  zipcodes: [
    78219, 78234, 78208, 78202, 78218, 78203, 78215, 78291, 78292, 78293, 78294, 78295, 78296, 78297, 78298, 78299, 78206, 78205, 78285, 78289, 78209, 78212,
    78220, 78244, 78283, 78204, 78210, 78284, 78201, 78239, 78207, 78222, 78265, 78217, 78225, 78228, 78233, 78213, 78109, 78246, 78279, 78280, 78270, 78235,
    78226, 78263, 78247, 78148, 78216, 78241, 78237, 78229, 78223, 78154, 78211, 78288, 78230, 78214, 78101, 78227, 78232, 78243, 78150, 78238, 78268, 78278,
    78152, 78248, 78249, 78221, 78112, 78236, 78240, 78224, 78231, 78259, 78269, 78242, 78108, 78250, 78266, 78073, 78251, 78258, 78121, 78252, 78023, 78256,
    78054, 78124, 78069, 78245, 78260, 78257, 78261, 78131, 78002, 78264, 78255, 78123, 78161, 78254, 78052, 78130, 78156, 78155, 78039, 78009, 78015, 78253,
    78114, 78135, 78163, 78070, 78050, 78132, 78006, 78115, 78059, 78160, 78004, 78064, 78147, 78016, 78066, 78065, 78638, 78026, 78056, 78667, 78143, 78133,
    78005, 78113, 78666, 78861, 78057, 78140, 78074, 78063, 78117, 78604, 78655, 78011, 78116, 78670, 78606, 78013, 78648, 78027, 78640, 78622, 78656, 78144,
    78008, 78623, 78850, 78012, 78118, 78159, 78661, 78676, 78003, 78886, 78658, 78122, 78111, 78610, 78632, 78061, 78010, 78119, 78677, 78644, 78075, 78062,
    78652, 78636, 78614, 78881, 78619, 78747, 78629, 77994, 78748, 78739, 78028, 78635, 78772, 78029, 78715, 78745, 78055, 78749, 78164, 78760, 78620, 78744,
    73301, 78671, 78151, 78704, 73344, 78959, 78741, 78737, 78767, 78764, 78768, 78783, 78799, 78616, 78762, 78778, 78612, 78711, 78774, 78624, 78702, 78712,
    78713, 78701, 78072, 78146, 78719, 78662, 78742, 78722, 78071, 78709, 78663, 78705, 78617, 78716, 78017, 78751, 78765, 78870, 78746, 78141, 78703, 78752,
    78025, 78773, 78761, 78162, 78883, 78735, 78723, 78021, 78714, 78763, 78756, 78721, 78779, 77993, 78710, 78757, 78060, 78766, 78733, 78724, 78755, 78736,
    78754, 77954, 78941, 78731, 78142, 78708, 78718, 78758, 78753, 78024, 78759, 78725, 77967, 77984, 78022, 78720, 78953, 78602, 77974, 77975, 78738, 78058,
    78654, 78727, 78631, 78838, 78884, 78829, 78728, 78730, 78691, 78734, 78726, 78653, 78145, 78007, 78125, 78675, 78682, 78750, 78651, 78732, 78729, 78885,
    78669, 78683, 78664, 78680, 78660, 78657, 78949, 78681, 78107, 78717, 78014, 78613, 77995, 77989, 77963, 78630, 78956, 78618, 78957, 78665, 78639, 78621,
    78646, 78879, 78627, 78641, 77964, 78350, 78611, 78634, 77976, 77987, 78659, 78872, 78615, 78963, 78830, 78962, 77960, 78628, 78873, 78945, 78001, 78609,
    78102, 78952, 78104, 76574, 78626, 78645, 78642, 78650, 77902, 78674, 78633, 77905, 77901, 78643, 78368, 77986, 77903, 78673, 78951, 78942, 78802, 78948,
    76578, 76820, 78391, 78801, 76831, 76537, 78943, 78607, 78960, 78357, 76856, 78961, 78383, 78605, 78672, 77988, 78934, 76849, 76577, 76527, 76573, 78938,
    78827, 78954, 76530, 78839, 78377, 78389, 76885, 78834, 78935, 78608, 78352, 77977, 77951, 78940, 76550, 78019, 77475, 78372, 76511, 76571, 78947, 77853,
    78946, 77973, 78833, 76832, 78836, 78333, 78370, 76854, 77412, 77904, 78932, 78332, 77470, 78828, 78387, 78832, 77835, 77990, 76883, 76567, 76869, 76534,
    77852, 78339, 76513, 76842, 77957, 78950, 78426, 78460, 76539, 77969, 77968, 76549, 77434, 76523, 78410, 78944, 78342, 78384, 77979, 76542, 78933, 78380,
    77836, 77442, 76848, 77950, 78409, 78330, 76547, 78390, 76502, 76853, 77460, 76554, 76508, 76541, 76540, 76887, 76556, 76503, 76559, 76877, 77454, 78408,
    77978, 76518, 77962, 76874, 78407, 76533, 78931, 78406, 78880, 77474, 78469, 77838, 76824, 77834, 78405, 76544, 78403, 76548, 78401, 76569, 78465, 78416,
    76520, 76825, 78463, 76504, 78404, 77418, 78359, 78402, 78853, 77971, 78351, 78467, 78417, 77863, 77991, 78852, 76579, 76522, 78393, 78374, 77983, 76564,
    76859, 78411, 77833, 77436, 78466, 77879, 76543, 78860, 77473, 76501, 77857, 77455, 78341, 77435, 78468, 77970, 78362, 76525, 78412, 78427, 78472, 78340,
    78343, 78413, 77426, 77485, 78376, 78843, 78336, 77423, 77443, 76557, 77437, 76630, 77466, 77452, 78415, 78375, 77807, 76524, 76872, 78414, 77867, 76871,
    78347, 78480, 78361, 78344, 77961, 77982, 76841, 77464, 61611, 61535, 61602, 61610, 61629, 61650, 61651, 61652, 61653, 61654, 61655, 61656, 61636, 61639,
    61601, 61630, 61550, 61637, 61634, 61603, 61606, 61625, 61635, 61643, 61613, 61633, 61612, 61616, 61604, 61614, 61641, 61558, 61605, 61554, 61568, 61555,
    61733, 61607, 61638, 61571, 61552, 61755, 61564, 61742, 61547, 61615, 61548, 61528, 61536, 61729, 61539, 61562, 61747, 61734, 61525, 61530, 61523, 61534,
    61725, 61533, 61569, 61545, 61517, 61759, 61561, 61546, 61570, 61732, 61526, 61559, 62635, 61771, 61531, 62643, 61774, 61565, 61721, 61540, 61520, 61451,
    61529, 61479, 61790, 61516, 61541, 61702, 61532, 62682, 61705, 61701, 61572, 61761, 61738, 61553, 61748, 61489, 61375, 61424, 62656, 61491, 61776, 61799,
    61791, 61537, 61710, 61524, 61543, 61563, 61369, 61432, 61772, 61704, 61723, 61709, 61567, 61754, 62671, 61760, 61428, 61483, 61758, 61744, 61336, 61458,
    61560, 61433, 61358, 61751, 61519, 61426, 61544, 61427, 61542, 61421, 62664, 61736, 61753, 61448, 61449, 62666, 61431, 62634, 61377, 61740, 61485, 61745,
    61778, 61321, 61335, 61430, 61436, 61730, 62644, 61327, 61368, 61402, 61315, 62548, 61477, 62633, 62512, 61401, 61743, 61333, 61474, 61737, 61752, 62541,
    61728, 61777, 62693, 61340, 61726, 61443, 61334, 61434, 62518, 61415, 62642, 62519, 61470, 61363, 61459, 61326, 61414, 61441, 61314, 61722, 61488, 62617,
    61345, 61356, 61720, 61311, 62543, 61727, 61322, 62684, 61370, 61316, 61410, 61379, 61362, 62655, 61419, 61749, 61323, 61332, 61467, 61337, 62561, 62515,
    62625, 62520, 61359, 61361, 62613, 61501, 61482, 61842, 61731, 61422, 61320, 61354, 61770, 61364, 62673, 62573, 61329, 62707, 62766, 61472, 62702, 61739,
    61234, 61348, 61750, 61473, 62769, 61416, 61764, 61423, 62739, 62675, 61735, 61484, 62781, 61338, 62761, 61435, 62786, 61490, 62767, 62777, 61773, 62706,
    61724, 61344, 62539, 61775, 62627, 61317, 62764, 61756, 61466, 62551, 62715, 62719, 62726, 62736, 62776, 62794, 62796, 62708, 62791, 61411, 62712, 62757,
    62723, 62701, 62705, 62722, 61413, 60936, 62763, 62762, 62537, 62716, 62765, 61312, 61854, 61346, 61412, 62756, 62703, 61328, 62659, 62704, 61741, 62563,
    61882, 61330, 61301, 61374, 61373, 61839, 61462, 62624, 61325, 61235, 62547, 61376, 61465, 61438, 61417, 61845, 61258, 62522, 61468, 61455, 61349, 62525,
    62523, 61440, 61843, 61262, 62711, 61853, 60952, 60933, 62691, 62535, 61319, 61350, 60460, 61233, 61243, 61460, 60921, 61471, 62688, 61475, 62514, 62681,
    61372, 61769, 62639, 62629, 61884, 61453, 62501, 62526, 62524, 62545, 61313, 61840, 61480, 61830, 62677, 62554, 60962, 61447, 62532, 61371, 62536, 61274,
    61856, 61273, 61486, 61367, 62531, 62622, 62326, 61238, 62618, 62513, 61815, 61875, 61826, 60959, 61283, 61420, 61452, 62530, 60470, 60968, 62374, 62660,
    62612, 62558, 61277, 61310, 62544, 60420, 60948, 61866, 61824, 61825, 61821, 60957, 61281, 60934, 60929, 62661, 62670, 61820, 61822, 61263, 61342, 62517,
    61476, 62549, 61042, 62540, 62521, 61478, 62570, 62615, 61418, 61240, 61425, 60918, 61469, 61803, 61818, 61802, 61801, 61813, 61241, 61071, 62319, 61261,
    60920, 61450, 61331, 61231, 61874, 60557, 60437, 62568, 61454, 61855, 61239, 60946, 62689, 62550, 61324, 60949, 61276, 62533, 60518, 62611, 61037, 62567,
    61282, 60955, 61878, 61847, 62631, 61442, 62601, 61437, 60938, 61872, 62690, 61236, 60919, 61021, 61244, 62367, 60549, 60424, 60961, 60479, 61254, 61851,
    62662, 60444, 61925, 61278, 61279, 61256, 62344, 61353, 61873, 61360, 61318, 60960, 61260, 62330, 61265, 61031, 61081, 61862, 61270, 62572, 60474, 61275,
    62353, 60924, 61936, 61264, 62665, 62555, 61929, 60930, 61266, 61880, 60551, 61864, 60917, 62692, 60407, 62556, 61341, 62640, 60531, 61091, 61378, 62510,
    62378, 60541, 61859, 61871, 60928, 62311, 61812, 62621, 62638, 61201, 61204, 61299, 62346, 60969, 60932, 61914, 61877, 62329, 60926, 60408, 60935, 60537,
    61863, 60416, 60941, 60911, 61242, 61257, 62672, 62546, 62339, 62323, 60450, 62538, 62358, 61057, 62560, 62650, 62651, 61272, 60530, 62375, 60552, 60553,
    62324, 61865, 60550, 60536, 61051, 61844, 61284, 61913, 61937, 61237, 62083, 61849, 60927, 62321, 60939, 62316, 61232, 62667, 60953, 61857, 62557, 60970,
    60481, 60913, 60548, 61064, 61956, 62695, 62075, 60942, 61250, 61951, 60974, 62362, 61953, 62674, 62076, 61259, 61858, 61061, 62320, 60545, 60556, 61014,
    60973, 62534, 60560, 61848, 61852, 62336, 62668, 61054, 61911, 61068, 62056, 61230, 60922, 62015, 62354, 62334, 61841, 62683, 62571, 60113, 62349, 61006,
    60512, 60967, 62553, 62313, 61831, 60410, 61810, 61919, 60963, 60901, 62325, 61251, 61811, 60951, 62626, 62649, 62049, 61816, 62565, 60966, 61046, 61047,
    62341, 60520, 61834, 62351, 60150, 61030, 61053, 60915, 61832, 62359, 60421, 60945, 62663, 60447, 61833, 61049, 62089, 61817, 62380, 60914, 61910, 62628,
    61078, 62051, 62033, 60956, 62694, 60436, 61941, 62094, 61252, 60404, 62023, 60910, 62069, 60431, 61957, 60931, 60538, 60129, 60554, 60964, 62438, 61876,
    60506, 61883, 60511, 62376, 60434, 61007, 61043, 60912, 62085, 62091, 62032, 60568, 62082, 62009, 62338, 61052, 62379, 62610, 60442, 60435, 60115, 62017,
    62080, 61931, 62630, 61942, 62347, 60586, 62348, 62088, 62431, 61020, 62093, 61850, 62019, 62373, 62011, 60950, 60111, 61285, 61930, 60542, 62092, 61846,
    61015, 60468, 60510, 62058, 60433, 61032, 61932, 60544, 60112, 60432, 61814, 62044, 61062, 60954, 62077, 61917, 61109, 61039, 62465, 60151, 62444, 60146,
    62078, 62306, 60403, 61084, 60144, 62422, 62098, 60958, 60451, 60441, 60585, 62074, 61940, 62301, 60944, 61870, 61943, 61013, 62097, 62305, 60178, 61126,
    62014, 62685, 60490, 61125, 60446, 62063, 60564, 62001, 60449, 62086, 61112, 61016, 62046, 62050, 61010, 60119, 61938, 61027, 60440, 62030, 61108, 60543,
    61104, 60145, 60423, 61044, 62352, 61107, 62016, 60503, 60401, 62463, 60565, 60448, 61048, 62414, 62340, 62079, 61085, 61074, 62246, 60507, 60504, 61920,
    60147, 62081, 62471, 60471, 61949, 62440, 60598, 60484, 62021, 61105, 61106, 61110, 61924, 62363, 60487, 61018, 60135, 60505, 60540, 61060, 60567, 60566,
    60443, 60491, 62262, 60517, 61102, 60940, 62357, 60599, 60467, 61933, 60519, 60466, 61912, 61008,
  ],
}
