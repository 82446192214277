///////////////////////////////
// Description
///////////////////////////////

///////////////////////////////
// Imports
///////////////////////////////
import { DatabaseRef_SalesPartner_Document } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_SalesPartner_InvoiceRates_Task_Document } from 'rfbp_aux/services/database_endpoints/finances/invoice_rates'
import {
  DatabaseRef_ProjectAdditionalWorkInvoices_Collection,
  DatabaseRef_ProjectAdditionalWorkInvoice_Document,
} from 'rfbp_aux/services/database_endpoints/finances/project_additional_work_invoices'
import { DatabaseRef_ProjectBaseInvoice_Document } from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import { DatabaseRef_ProjectFinance_Document, DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasksFromSameWorkflowTask_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { AdditionalWorkInvoiceViewDialog } from '../dialogs/additional_work_invoice_view'
import { ApproveAdditionalWorkInvoiceDialog } from '../dialogs/approve_additional_work_quote_dialog'
import { ApproveBaseInvoiceDialog } from '../dialogs/approve_base_invoice'
import { BillAdditionalWorkInvoiceDialog } from '../dialogs/bill_additional_work_invoice'
import { BillBaseInvoiceDialog } from '../dialogs/bill_base_invoice'
import { projectInvoicePriceTypeOptions } from '../tables/project_base_pricing'
import { downloadAdditionalWorkQuotePDF, downloadBasePricingPDF, returnProjectBasePricingLineItems } from './invoice_pdf_templates'

///////////////////////////////
// Typescript
///////////////////////////////

export interface TsInterface_ProjectData {
  system_size_dc?: number
  system_panel_quantity?: number
  location_distance_from_warehouse?: number
  system_max_roof_pitch?: number
  system_storage_quantity?: number
  home_roof_type?: string
  // roof_replacement?: boolean
  // roof_size_sqft?: number
}

///////////////////////////////
// Variables
///////////////////////////////

const invoiceEmailTemplate: TsInterface_EmailTemplateObject = {
  BODY_DIV: {
    element_type: 'div',
    style: {
      'box-sizing': 'border-box',
      'width': '100%',
      'background': '#eeeeee',
      'padding': '16px',
    },
    contents: {
      CARD_DIV: {
        element_type: 'div',
        style: {
          'max-width': '600px',
          'background': '#ffffff',
          'border-radius': '8px',
          'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
          'margin': '0 auto',
          'overflow': 'hidden',
        },
        contents: {
          HEADER_DIV: {
            element_type: 'div',
            style: {
              'box-sizing': 'border-box',
              'background': 'rgb(25, 30, 33)',
              'font-size': '20px',
              'width': '100%',
              'min-height': '70px',
              'padding': '8px',
              'text-align': 'center',
            },
            contents: {
              LOGO_IMG: {
                element_type: 'img',
                src_type: 'static',
                src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                alt: 'logo.png',
                width: '80px',
                height: 'auto',
              },
            },
          },
          CONTENT_DIV: {
            element_type: 'div',
            style: {
              padding: '16px',
            },
            contents: {
              INVOICE1: {
                element_type: 'p',
                text_type: 'static',
                text: 'Invoice from ETW Energy LLC',
                style: {
                  'font-size': '16px',
                  'margin-bottom': '4px',
                  'color': 'rgba(0, 0, 0, 0.87)',
                },
              },
              TOTAL: {
                element_type: 'p',
                text_type: 'dynamic',
                data_object_key: 'invoice',
                data_object_prop_key: 'total',
                style: {
                  'font-size': '36px',
                  'font-weight': 'bold',
                  'margin-top': '0px',
                  'margin-bottom': '4px',
                  'color': 'rgba(0, 0, 0, 0.87)',
                },
                // prefix_text: 'Hi ',
                // suffix_text: ',',
              },
              INVOICE2: {
                element_type: 'p',
                text_type: 'static',
                text: 'See the attached invoice for full details.',
                style: {
                  'font-size': '16px',
                  'margin-bottom': '4px',
                  'color': 'rgba(0, 0, 0, 0.87)',
                },
              },
              INVOICE3: {
                element_type: 'p',
                text_type: 'static',
                text: 'If you have any questions, please contact us at support@etwenergy.com.',
                style: {
                  'font-size': '16px',
                  'margin-bottom': '4px',
                  'color': 'rgba(0, 0, 0, 0.87)',
                },
              },
            },
          },
        },
      },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

// Base Project
export const returnInvoicePricing = (
  clientKey: string,
  salesPartnerKey: string,
  workflowKey: string,
  regionKey: string,
  additionalData: TsInterface_ProjectData,
): Promise<TsInterface_UnspecifiedObject> => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && salesPartnerKey != null && workflowKey != null && regionKey != null) {
      let baseRateKey = 'BASE_RATES_' + workflowKey
      DatabaseGetDocument(DatabaseRef_SalesPartner_InvoiceRates_Task_Document(clientKey, salesPartnerKey, baseRateKey))
        .then((res_DGD) => {
          let baseRates = res_DGD.data
          let basePrice = 0
          let adderPrice = 0
          let invoiceLineItems: TsInterface_UnspecifiedObject = {}
          let foundMatchingRates: boolean = false
          if (
            baseRates != null &&
            getProp(baseRates, 'line_items_' + regionKey, null) != null &&
            objectToArray(getProp(baseRates, 'line_items_' + regionKey, {})).length > 0
          ) {
            invoiceLineItems = getProp(baseRates, 'line_items_' + regionKey, {})
            foundMatchingRates = true
          } else if (
            baseRates != null &&
            getProp(baseRates, 'line_items_default', null) != null &&
            objectToArray(getProp(baseRates, 'line_items_default', {})).length > 0
          ) {
            invoiceLineItems = getProp(baseRates, 'line_items_default', {})
            foundMatchingRates = true
          }
          if (foundMatchingRates === true) {
            let basePricing: TsInterface_UnspecifiedObject = {}
            let adderPricing: TsInterface_UnspecifiedObject = {}
            for (let loopLineItemKey in invoiceLineItems) {
              let loopLineItem = invoiceLineItems[loopLineItemKey]
              if (loopLineItem != null) {
                let calculatedPrice = null
                if (
                  loopLineItem.price_type != null &&
                  projectInvoicePriceTypeOptions != null &&
                  projectInvoicePriceTypeOptions[loopLineItem.price_type] != null &&
                  projectInvoicePriceTypeOptions[loopLineItem.price_type].calculate != null
                ) {
                  calculatedPrice = projectInvoicePriceTypeOptions[loopLineItem.price_type].calculate(loopLineItem, additionalData)
                }
                if (calculatedPrice != null) {
                  if (loopLineItemKey === 'base_amount') {
                    basePricing[loopLineItemKey] = {
                      key: loopLineItemKey,
                      name: loopLineItem.name,
                      calculated_price: calculatedPrice,
                      // price_type: loopLineItem.price_type,
                      order: loopLineItem.order,
                    }
                    basePrice += calculatedPrice
                  } else {
                    adderPricing[loopLineItemKey] = {
                      key: loopLineItemKey,
                      name: loopLineItem.name,
                      calculated_price: calculatedPrice,
                      // price_type: loopLineItem.price_type,
                      order: loopLineItem.order,
                    }
                    adderPrice += calculatedPrice
                  }
                }
              }
            }
            // Resolve
            resolve({
              success: true,
              // timestamp_invoice_generated: new Date(), // TODO: check if this is needed
              approval_type: getProp(baseRates, 'approval_type', null),
              bill_to: getProp(baseRates, 'bill_to', null),
              billing_type: getProp(baseRates, 'billing_type', null),
              billing_times: getProp(baseRates, 'billing_times', null),
              base_pricing: basePricing,
              adder_pricing: adderPricing,
              base_price: basePrice,
              adder_price: adderPrice,
              total_price: basePrice + adderPrice,
            })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to return invoice pricing'),
                details: rLIB('Unable to find matching invoice rates'),
                code: 'ER-D-IP-RIP-01',
              },
            })
          }
        })
        .catch((rej_DGD) => {
          reject({
            success: false,
            error: {
              message: rLIB('Failed to return invoice pricing'),
              details: rLIB('Missing Required Parameters'),
              code: 'ER-D-IP-RIP-02',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to return invoice pricing'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-D-IP-RIP-03',
        },
      })
    }
  })
}

export const generateProjectInvoice = (
  us_rootProject: TsInterface_UnspecifiedObject,
  uc_RootData_ClientKey: any,
  pr_projectKey: string,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
) => {
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    .then((res_GCK) => {
      let clientKey = res_GCK.clientKey
      let salesPartnerKey = us_rootProject.associated_sales_partner_key
      let workflowKey = us_rootProject.associated_task_workflow_key
      let regionKey = us_rootProject.associated_region_key
      returnInvoicePricing(clientKey, salesPartnerKey, workflowKey, regionKey, {
        system_size_dc: us_rootProject.system_size_dc,
        system_panel_quantity: us_rootProject.system_panel_quantity,
        location_distance_from_warehouse: us_rootProject.location_distance_from_warehouse,
        system_max_roof_pitch: us_rootProject.system_max_roof_pitch,
        system_storage_quantity: us_rootProject.system_storage_quantity,
        home_roof_type: us_rootProject.home_roof_type,
      })
        .then((res_RIP) => {
          let projectUpdateObject: TsInterface_UnspecifiedObject = {
            timestamp_invoice_generated: new Date(),
            invoice_bill_to: getProp(res_RIP, 'bill_to', null),
            invoice_billing_type: getProp(res_RIP, 'billing_type', null),
          }
          if (getProp(us_rootProject, 'invoice_status', null) === 'missing') {
            projectUpdateObject.invoice_status = 'unapproved'
          }
          // Update Project
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_Project_Document(clientKey, pr_projectKey),
              data: projectUpdateObject,
            },
            {
              type: 'setOverwrite',
              ref: DatabaseRef_ProjectFinance_Document(clientKey, pr_projectKey, 'base'),
              data: res_RIP,
            },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              if (getProp(res_RIP, 'approval_type', null) === 'auto_approved') {
                approveProjectBaseInvoice(clientKey, pr_projectKey, true)
              }
              // Nothing
            })
            .catch((rej_DBU) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
            })
        })
        .catch((rej_RIP) => {
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              message: rLIB('Failed to generate base invoice'),
              details: rej_RIP.error.message,
              code: rej_RIP.error.code,
            },
          })
        })
    })
    .catch((rej_GCK) => {
      uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: rej_GCK.error,
      })
    })
}

export const openApproveBaseInvoiceDialog = (clientKey: string, projectKey: string, uc_setUserInterface_CustomDialogDisplay: any) => {
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: (
        <ApproveBaseInvoiceDialog
          clientKey={clientKey}
          projectKey={projectKey}
        />
      ),
      settings: {
        max_width: 'lg',
      },
    },
  })
}

export const approveProjectBaseInvoice = (clientKey: string, projectKey: string, showError: boolean) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null) {
      // Get Project and Project Base Data
      DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
        .then((res_GDD) => {
          let project = res_GDD.data
          DatabaseGetDocument(DatabaseRef_ProjectFinance_Document(clientKey, projectKey, 'base'))
            .then((res_GDD) => {
              let projectFinances = res_GDD.data
              if (projectFinances != null && projectFinances.billing_times != null && objectToArray(projectFinances.billing_times).length > 0) {
                // Loop through and get tasks on the project for each billing event task
                let promiseArray1: TsType_UnknownPromise[] = []
                let billingEventTasks: TsInterface_UnspecifiedObject = {}
                for (let loopTaskKey in projectFinances.billing_times) {
                  billingEventTasks[loopTaskKey] = {
                    key: loopTaskKey,
                    name: getProp(projectFinances.billing_times[loopTaskKey], 'associated_task_name', null),
                    invoice_suffix: getProp(projectFinances.billing_times[loopTaskKey], 'invoice_suffix', null),
                    found_task: false,
                    task_status: null,
                    task: {},
                  }
                  promiseArray1.push(
                    DatabaseGetCollection(DatabaseRef_AllProjectTasksFromSameWorkflowTask_Query(clientKey, projectKey, loopTaskKey)).then((res_DGC) => {
                      let matchingTasks = res_DGC.data
                      if (matchingTasks != null && objectToArray(matchingTasks).length > 0) {
                        billingEventTasks[loopTaskKey]['found_task'] = true
                        billingEventTasks[loopTaskKey]['task_status'] = getProp(objectToArray(matchingTasks)[0], 'status', null)
                        billingEventTasks[loopTaskKey]['task'] = objectToArray(matchingTasks)[0]
                      }
                    }),
                  )
                }
                // After Tasks Loaded
                // let projectFinanceUpdateObject: TsInterface_UnspecifiedObject = {}
                Promise.all(promiseArray1).finally(() => {
                  // Check if all tasks are found
                  let allTasksFound = true
                  let missingTasks: string[] = []
                  for (let loopTaskKey in billingEventTasks) {
                    if (billingEventTasks[loopTaskKey]['found_task'] === false) {
                      allTasksFound = false
                      missingTasks.push(billingEventTasks[loopTaskKey]['name'])
                    }
                  }
                  if (allTasksFound === true) {
                    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_Project_Document(clientKey, projectKey),
                        data: { invoice_status: 'approved' },
                      },
                    ]
                    let usingDeletedTask = false
                    let deletedTasks: string[] = []
                    // Loop through and create base_project_invoices for each billing event task
                    for (let loopTaskKey in billingEventTasks) {
                      let projectBaseInvoiceUpdateObject: TsInterface_UnspecifiedObject = {}
                      let invoiceStatus = 'approved'
                      if (billingEventTasks[loopTaskKey]['task_status'] === 'completed') {
                        invoiceStatus = 'completed'
                      } else if (billingEventTasks[loopTaskKey]['task_status'] === 'deleted') {
                        invoiceStatus = 'approved'
                        usingDeletedTask = true
                        deletedTasks.push(billingEventTasks[loopTaskKey]['name'])
                      }
                      // Get Line Items and Total
                      let projectBaseInvoiceKey = projectKey + '_' + loopTaskKey
                      let invoiceLineItems = returnProjectBasePricingLineItems(projectFinances, loopTaskKey, project.id_number)
                      let invoiceTotal = invoiceLineItems.reduce((acc, curr) => acc + curr.amount, 0)
                      // Update Object
                      projectBaseInvoiceUpdateObject['key'] = projectBaseInvoiceKey
                      projectBaseInvoiceUpdateObject['associated_project_key'] = projectKey
                      projectBaseInvoiceUpdateObject['associated_sales_partner_key'] = getProp(project, 'associated_sales_partner_key', null)
                      projectBaseInvoiceUpdateObject['associated_region_key'] = getProp(project, 'associated_region_key', null)
                      projectBaseInvoiceUpdateObject['associated_finance_partner_key'] = getProp(project, 'associated_finance_partner_key', null)
                      projectBaseInvoiceUpdateObject['associated_task_workflow_key'] = getProp(project, 'associated_task_workflow_key', null)
                      projectBaseInvoiceUpdateObject['associated_project_job_code'] = project.id_number
                      projectBaseInvoiceUpdateObject['invoice_id_number'] = project.id_number + '-' + billingEventTasks[loopTaskKey]['invoice_suffix']
                      projectBaseInvoiceUpdateObject['invoice_suffix'] = billingEventTasks[loopTaskKey]['invoice_suffix']
                      projectBaseInvoiceUpdateObject['associated_task_key'] = billingEventTasks[loopTaskKey]['key']
                      projectBaseInvoiceUpdateObject['associated_task_name'] = billingEventTasks[loopTaskKey]['name']
                      projectBaseInvoiceUpdateObject['associated_task_status'] = getProp(billingEventTasks[loopTaskKey], 'task_status', null)
                      projectBaseInvoiceUpdateObject['status'] = invoiceStatus
                      projectBaseInvoiceUpdateObject['line_items'] = invoiceLineItems
                      projectBaseInvoiceUpdateObject['total_price'] = invoiceTotal
                      projectBaseInvoiceUpdateObject['timestamp_created'] = new Date()
                      updateArray.push({
                        type: 'setMerge',
                        ref: DatabaseRef_ProjectBaseInvoice_Document(clientKey, projectBaseInvoiceKey),
                        data: projectBaseInvoiceUpdateObject,
                      })
                    }
                    updateArray.push({
                      type: 'setOverwrite',
                      ref: DatabaseRef_ProjectFinance_Document(clientKey, projectKey, 'base'),
                      data: {
                        approval_evidence_files: getProp(projectFinances, 'approval_evidence_files', {}),
                        approval_evidence_uploaded: getProp(projectFinances, 'approval_evidence_uploaded', null),
                        approval_type: getProp(projectFinances, 'approval_type', null),
                        timestamp_converted_to_invoice: new Date(),
                      },
                    })
                    // Batch Update
                    DatabaseBatchUpdate(updateArray)
                      .then((res_DBU) => {
                        if (usingDeletedTask === false || showError === false) {
                          resolve(res_DBU)
                        } else {
                          reject({
                            success: false,
                            error: {
                              message: rLIB('Invoices created but some milestone tasks have been deleted'),
                              details: (
                                <>
                                  {rLIB('The following tasks are deleted and will not trigger billing without intervention:')} {deletedTasks.join(', ')}
                                </>
                              ),
                              code: 'ER-D-IPF-APBI-01',
                            },
                          })
                        }
                      })
                      .catch((rej_DBU) => {
                        reject(rej_DBU)
                      })
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: rLIB('Failed to approve project base invoice'),
                        details: rLIB('The project is missing the following tasks:') + ' ' + missingTasks.join(', '),
                        code: 'ER-D-IPF-APBI-02',
                      },
                    })
                  }
                })
              } else {
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to approve project base invoice'),
                    details: rLIB('No billing task events set up for this project'),
                    code: 'ER-D-IPF-APBI-03',
                  },
                })
              }
            })
            .catch((rej_GDD) => {
              reject(rej_GDD)
            })
        })
        .catch((rej_GDD) => {
          reject(rej_GDD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to approve project base invoice'),
          details: rLIB('Invalid client or project key'),
          code: 'ER-D-IPF-APBI-04',
        },
      })
    }
  })
}

export const openSendProjectBaseInvoiceEmailDialog = (
  clientKey: string,
  projectKey: string,
  taskKey: string,
  invoiceKey: string,
  invoice: TsInterface_UnspecifiedObject,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
) => {
  let promiseArray1: TsType_UnknownPromise[] = []
  let project: TsInterface_UnspecifiedObject = {}
  let salesPartner: TsInterface_UnspecifiedObject = {}
  // Get Project Data
  DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
    .then((res_GDD) => {
      project = res_GDD.data
      let invoiceKey = projectKey + '_' + taskKey
      DatabaseGetDocument(DatabaseRef_ProjectBaseInvoice_Document(clientKey, invoiceKey))
        .then((res_GDD) => {
          let loadedInvoice = res_GDD.data
          let emailAndBillingContactInfo: TsInterface_UnspecifiedObject = {}
          if (project.invoice_bill_to === 'sales_partner' && project.associated_sales_partner_key != null) {
            // Get Sales Partner Data
            promiseArray1.push(
              DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, project.associated_sales_partner_key as string)).then((res_GDD) => {
                salesPartner = res_GDD.data
                emailAndBillingContactInfo = {
                  email: getProp(salesPartner, 'billing_email', null),
                  location_address: getProp(salesPartner, 'location_address', null),
                  location_city: getProp(salesPartner, 'location_city', null),
                  location_state: getProp(salesPartner, 'location_state', null),
                  location_zip: getProp(salesPartner, 'location_zip', null),
                }
              }),
            )
          } else if (project.invoice_bill_to === 'customer' && project.associated_customer_email != null) {
            emailAndBillingContactInfo = {
              email: getProp(project, 'associated_customer_email', null),
              location_address: getProp(project, 'location_address', null),
              location_city: getProp(project, 'location_city', null),
              location_state: getProp(project, 'location_state', null),
              location_zip: getProp(project, 'location_zip', null),
            }
          }
          Promise.all(promiseArray1).finally(() => {
            uc_setUserInterface_CustomDialogDisplay({
              display: true,
              dialog: {
                dialog_jsx: (
                  <BillBaseInvoiceDialog
                    clientKey={clientKey}
                    taskKey={taskKey}
                    invoiceKey={invoiceKey}
                    projectKey={projectKey}
                    project={project}
                    emailAndBillingContactInfo={emailAndBillingContactInfo}
                    invoice={loadedInvoice}
                  />
                ),
                settings: {
                  max_width: 'lg',
                },
              },
            })
          })
        })
        .catch((rej_GDD) => {
          console.error(rej_GDD)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: rej_GDD.error,
          })
        })
    })
    .catch((rej_GDD) => {
      console.error(rej_GDD)
      uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: rej_GDD.error,
      })
    })
}

export const sendProjectBaseInvoiceEmail = (clientKey: string, projectKey: string, taskKey: string, invoiceKey: string) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && taskKey != null && invoiceKey != null) {
      downloadBasePricingPDF(clientKey, projectKey, taskKey, 'base64', 'invoice')
        .then((res_DBPP) => {
          DatabaseGetDocument(DatabaseRef_ProjectBaseInvoice_Document(clientKey, invoiceKey))
            .then((res_GDD) => {
              let promiseArray1: TsType_UnknownPromise[] = []
              let invoice: TsInterface_UnspecifiedObject = res_GDD.data
              let project: TsInterface_UnspecifiedObject = {}
              let salesPartner: TsInterface_UnspecifiedObject = {}
              // Get Project Data
              DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
                .then((res_GDD) => {
                  project = res_GDD.data
                  let emailAndBillingContactInfo: TsInterface_UnspecifiedObject = {}
                  if (project.invoice_bill_to === 'sales_partner' && project.associated_sales_partner_key != null) {
                    // Get Sales Partner Data
                    promiseArray1.push(
                      DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, project.associated_sales_partner_key as string)).then((res_GDD) => {
                        salesPartner = res_GDD.data
                        emailAndBillingContactInfo = {
                          email: getProp(salesPartner, 'billing_email', null),
                        }
                      }),
                    )
                  } else if (project.invoice_bill_to === 'customer' && project.associated_customer_email != null) {
                    emailAndBillingContactInfo = {
                      email: getProp(project, 'associated_customer_email', null),
                    }
                  }
                  Promise.all(promiseArray1).finally(() => {
                    // TODO: TEMP
                    let projectJobCode = project.id_number
                    let invoiceLineItems = invoice.line_items
                    let invoiceTotal = invoiceLineItems.reduce((acc: number, curr: TsInterface_UnspecifiedObject) => acc + curr.amount, 0)
                    let invoiceEmailDataObject: TsInterface_UnspecifiedObject = {
                      invoice: {
                        total: formatCurrency(invoiceTotal),
                      },
                    }
                    // Send Email
                    cloudFunctionManageRequest('manageEmails', {
                      function: 'sendSendgridHtmlEmailWithAttachments',
                      to: [emailAndBillingContactInfo.email],
                      subject: 'Your invoice from ETW Energy: ' + getProp(res_DBPP, 'invoice_id_number', projectJobCode),
                      html: generateHtmlForEmailFromTemplateObject(invoiceEmailTemplate, invoiceEmailDataObject),
                      cc: [], // TODO: Fill this in?
                      bcc: [],
                      attachments: [
                        {
                          content: getProp(res_DBPP, 'data', null),
                          filename: getProp(res_DBPP, 'file_name', 'Invoice ' + projectJobCode + '.pdf'),
                          type: 'application/pdf',
                          disposition: 'attachment',
                        },
                      ],
                    })
                      .then((res) => {
                        // Update Invoice
                        let invoiceUpdateObject = {
                          status: 'billed',
                          timestamp_invoice_billed: new Date(),
                        }
                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                          {
                            type: 'setMerge',
                            ref: DatabaseRef_ProjectBaseInvoice_Document(clientKey, invoiceKey),
                            data: invoiceUpdateObject,
                          },
                        ]
                        DatabaseBatchUpdate(updateArray)
                          .then((res_SMD) => {
                            resolve(res_SMD)
                          })
                          .catch((rej_SMD) => {
                            console.error('Error updating invoice:', rej_SMD)
                            reject(rej_SMD)
                          })
                      })
                      .catch((rej) => {
                        console.error('Error sending email:', rej)
                        reject(rej)
                      })
                  })
                })
                .catch((rej_GDD) => {
                  console.error(rej_GDD)
                  reject(rej_GDD)
                })
            })
            .catch((rej_GDD) => {
              console.error(rej_GDD)
              reject(rej_GDD)
            })
        })
        .catch((rej_DBPP) => {
          reject(rej_DBPP)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to send project base invoice email'),
          details: rLIB('Invalid client, project, task, or invoice key'),
          code: 'ER-D-IPF-SPBIE-01',
        },
      })
    }
  })
}

// Additional Work
export const createAdditionalWorkInvoice = (
  clientKey: string,
  projectKey: string,
  approvalType: string,
  billTo: 'sales_partner' | 'customer',
  billableReferenceNumber: string,
  formattedLineItems: TsInterface_UnspecifiedObject[],
  associatedTasks: TsInterface_UnspecifiedObject,
) => {
  return new Promise((resolve, reject) => {
    // Get Project Data
    let promiseArray1: Promise<any>[] = []
    let project: TsInterface_UnspecifiedObject = {}
    // Get Project Data
    promiseArray1.push(
      DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
        .then((res_DGD) => {
          project = res_DGD.data
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    // After Data Loaded
    Promise.all(promiseArray1).finally(() => {
      // Update Object
      let invoiceTotal = formattedLineItems.reduce((acc, curr) => acc + curr.amount, 0)
      let updateObject: TsInterface_UnspecifiedObject = {
        approval_type: approvalType,
        associated_finance_partner_key: getProp(project, 'associated_finance_partner_key', null),
        associated_finance_partner_name: getProp(project, 'associated_finance_partner_name', null),
        associated_project_job_code: getProp(project, 'id_number', null),
        associated_project_key: projectKey,
        associated_region_key: getProp(project, 'associated_region_key', null),
        associated_region_name: getProp(project, 'associated_region_name', null),
        associated_sales_partner_key: getProp(project, 'associated_sales_partner_key', null),
        associated_sales_partner_name: getProp(project, 'associated_sales_partner_name', null),
        associated_tasks: associatedTasks,
        associated_task_workflow_key: getProp(project, 'associated_task_workflow_key', null),
        bill_to: billTo,
        invoice_id_number: billableReferenceNumber,
        line_items: formattedLineItems,
        status: 'unapproved',
        timestamp_created: new Date(),
        total_price: invoiceTotal,
      }
      DatabaseAddDocument(DatabaseRef_ProjectAdditionalWorkInvoices_Collection(clientKey), updateObject, true)
        .then((res_AD) => {
          if (approvalType === 'auto_approved') {
            approveAdditionalWorkInvoice(clientKey, res_AD.key, false)
              .then(() => {
                resolve(res_AD)
              })
              .catch((rej_AWI) => {
                console.error(rej_AWI)
                resolve(res_AD)
              })
          } else {
            resolve(res_AD)
          }
        })
        .catch((rej_AD) => {
          console.error(rej_AD)
          reject(rej_AD)
        })
    })
  })
}

export const openAdditionalWorkInvoiceDialog = (clientKey: string, invoiceKey: string, uc_setUserInterface_CustomDialogDisplay: any) => {
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: (
        <AdditionalWorkInvoiceViewDialog
          clientKey={clientKey}
          invoiceKey={invoiceKey}
        />
      ),
      settings: {
        max_width: 'lg',
      },
    },
  })
}

export const openApproveAdditionalWorkInvoiceDialog = (
  clientKey: string,
  projectKey: string,
  invoiceKey: string,
  uc_setUserInterface_CustomDialogDisplay: any,
) => {
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: (
        <ApproveAdditionalWorkInvoiceDialog
          clientKey={clientKey}
          projectKey={projectKey}
          quoteKey={invoiceKey}
        />
      ),
      settings: {
        max_width: 'lg',
      },
    },
  })
}

// TODO: Create Tasks
export const approveAdditionalWorkInvoice = (clientKey: string, invoiceKey: string, showError: boolean) => {
  return new Promise((resolve, reject) => {
    // TODO: Create tasks listed on this invoice
    // Update invoice status
    let updateObject: TsInterface_UnspecifiedObject = {
      status: 'approved',
      timestamp_invoice_approved: new Date(),
    }
    DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey), updateObject)
      .then((res_SMD) => {
        resolve(res_SMD)
      })
      .catch((rej_SMD) => {
        console.error(rej_SMD)
        reject(rej_SMD)
      })
  })
}

export const openSendAdditionalWorkInvoiceEmailDialog = (
  clientKey: string,
  projectKey: string,
  invoiceKey: string,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
) => {
  let promiseArray1: TsType_UnknownPromise[] = []
  let project: TsInterface_UnspecifiedObject = {}
  let salesPartner: TsInterface_UnspecifiedObject = {}
  // Get Project Data
  DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
    .then((res_GDD) => {
      project = res_GDD.data
      DatabaseGetDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey))
        .then((res_GDD) => {
          let loadedInvoice = res_GDD.data
          let emailAndBillingContactInfo: TsInterface_UnspecifiedObject = {}
          if (loadedInvoice.bill_to === 'sales_partner' && loadedInvoice.associated_sales_partner_key != null) {
            // Get Sales Partner Data
            promiseArray1.push(
              DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, project.associated_sales_partner_key as string)).then((res_GDD) => {
                salesPartner = res_GDD.data
                emailAndBillingContactInfo = {
                  email: getProp(salesPartner, 'billing_email', null),
                  location_address: getProp(salesPartner, 'location_address', null),
                  location_city: getProp(salesPartner, 'location_city', null),
                  location_state: getProp(salesPartner, 'location_state', null),
                  location_zip: getProp(salesPartner, 'location_zip', null),
                }
              }),
            )
          } else if (project.invoice_bill_to === 'customer' && project.associated_customer_email != null) {
            emailAndBillingContactInfo = {
              email: getProp(project, 'associated_customer_email', null),
              location_address: getProp(project, 'location_address', null),
              location_city: getProp(project, 'location_city', null),
              location_state: getProp(project, 'location_state', null),
              location_zip: getProp(project, 'location_zip', null),
            }
          }
          Promise.all(promiseArray1).finally(() => {
            uc_setUserInterface_CustomDialogDisplay({
              display: true,
              dialog: {
                dialog_jsx: (
                  <BillAdditionalWorkInvoiceDialog
                    clientKey={clientKey}
                    invoiceKey={invoiceKey}
                    projectKey={projectKey}
                    project={project}
                    emailAndBillingContactInfo={emailAndBillingContactInfo}
                    invoice={loadedInvoice}
                  />
                ),
                settings: {
                  max_width: 'lg',
                },
              },
            })
          })
        })
        .catch((rej_GDD) => {
          console.error(rej_GDD)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: rej_GDD.error,
          })
        })
    })
    .catch((rej_GDD) => {
      console.error(rej_GDD)
      uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: rej_GDD.error,
      })
    })
}

export const sendProjectAdditionalWorkInvoiceEmail = (clientKey: string, projectKey: string, invoiceKey: string) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && invoiceKey != null) {
      DatabaseGetDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey))
        .then((res_GDD) => {
          let invoice: TsInterface_UnspecifiedObject = res_GDD.data
          downloadAdditionalWorkQuotePDF(
            clientKey,
            projectKey,
            getProp(invoice, 'bill_to', null),
            getProp(invoice, 'invoice_id_number', null),
            objectToArray(getProp(invoice, 'line_items', {})),
            'base64',
          )
            .then((res_DBPP) => {
              let promiseArray1: TsType_UnknownPromise[] = []
              let project: TsInterface_UnspecifiedObject = {}
              let salesPartner: TsInterface_UnspecifiedObject = {}
              // Get Project Data
              DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
                .then((res_GDD) => {
                  project = res_GDD.data
                  let emailAndBillingContactInfo: TsInterface_UnspecifiedObject = {}
                  if (project.invoice_bill_to === 'sales_partner' && project.associated_sales_partner_key != null) {
                    // Get Sales Partner Data
                    promiseArray1.push(
                      DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, project.associated_sales_partner_key as string)).then((res_GDD) => {
                        salesPartner = res_GDD.data
                        emailAndBillingContactInfo = {
                          email: getProp(salesPartner, 'billing_email', null),
                        }
                      }),
                    )
                  } else if (project.invoice_bill_to === 'customer' && project.associated_customer_email != null) {
                    emailAndBillingContactInfo = {
                      email: getProp(project, 'associated_customer_email', null),
                    }
                  }
                  Promise.all(promiseArray1).finally(() => {
                    // TODO: TEMP
                    let projectJobCode = project.id_number
                    let invoiceLineItems = invoice.line_items
                    let invoiceTotal = invoiceLineItems.reduce((acc: number, curr: TsInterface_UnspecifiedObject) => acc + curr.amount, 0)
                    let invoiceEmailDataObject: TsInterface_UnspecifiedObject = {
                      invoice: {
                        total: formatCurrency(invoiceTotal),
                      },
                    }
                    // Send Email
                    cloudFunctionManageRequest('manageEmails', {
                      function: 'sendSendgridHtmlEmailWithAttachments',
                      to: [emailAndBillingContactInfo.email],
                      subject: 'Your invoice from ETW Energy: ' + getProp(res_DBPP, 'invoice_id_number', projectJobCode),
                      html: generateHtmlForEmailFromTemplateObject(invoiceEmailTemplate, invoiceEmailDataObject),
                      cc: [], // TODO: Fill this in?
                      bcc: [],
                      attachments: [
                        {
                          content: getProp(res_DBPP, 'data', null),
                          filename: getProp(res_DBPP, 'file_name', 'Invoice ' + projectJobCode + '.pdf'),
                          type: 'application/pdf',
                          disposition: 'attachment',
                        },
                      ],
                    })
                      .then((res) => {
                        // Update Invoice
                        let invoiceUpdateObject = {
                          status: 'billed',
                          timestamp_invoice_billed: new Date(),
                        }
                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                          {
                            type: 'setMerge',
                            ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
                            data: invoiceUpdateObject,
                          },
                        ]
                        DatabaseBatchUpdate(updateArray)
                          .then((res_SMD) => {
                            resolve(res_SMD)
                          })
                          .catch((rej_SMD) => {
                            console.error('Error updating invoice:', rej_SMD)
                            reject(rej_SMD)
                          })
                      })
                      .catch((rej) => {
                        console.error('Error sending email:', rej)
                        reject(rej)
                      })
                  })
                })
                .catch((rej_GDD) => {
                  console.error(rej_GDD)
                  reject(rej_GDD)
                })
            })
            .catch((rej_DBPP) => {
              reject(rej_DBPP)
            })
        })
        .catch((rej_GDD) => {
          console.error(rej_GDD)
          reject(rej_GDD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to send project additional work invoice email'),
          details: rLIB('Invalid client, project, or invoice key'),
          code: 'ER-D-IPF-SPAWIE-01',
        },
      })
    }
  })
}
