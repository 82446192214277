///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, MenuItem, Select, Typography } from '@mui/material/'
import { pdf } from '@react-pdf/renderer'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ProjectAdditionalWorkInvoices_Collection } from 'rfbp_aux/services/database_endpoints/finances/project_additional_work_invoices'
import { DatabaseRef_ProjectBaseInvoices_Collection } from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import { DatabaseRef_Projects_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { generatePdfFromData } from 'rfbp_core/components/pdf/custom'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_CustomDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { formatCurrency, getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { ReconcileBaseInvoicePaymentsDialog } from './dialogs/reconcile_base_invoice_payment'
import { detailedInvoicePdfTemplate, invoicePdfTemplate, quotePdfTemplate } from './services/invoice_pdf_templates'
import { tableColumns_AdditionalWorkIndexInvoices, tableSettings_AdditionalWorkIndexInvoices } from './tables/additional_work_invoices'
import {
  tableColumns_ApprovedInvoices,
  tableColumns_BilledInvoices,
  tableColumns_CancelledInvoices,
  tableColumns_CompletedInvoices,
  tableColumns_PaidInvoices,
  tableColumns_ProjectsMissingInvoiceApproval,
  tableColumns_ProjectsPendingInvoiceApproval,
  tableSettings_BaseInvoices,
  tableSettings_InvoiceProjects,
} from './tables/base_invoices'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminInvoiceIndexPage']['key']

// Table

// ViewBaseInvoiceDialog

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const [us_selectedInvoiceType, us_setSelectedInvoiceType] = useState<string>('projects') // additional_work
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void

  const un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Invoicing', false) as string
  }, [])

  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage(pageKey)
    if (getProp(pageLocalStorageData, 'us_selectedInvoiceType', null) != null) {
      us_setSelectedInvoiceType(getProp(pageLocalStorageData, 'us_selectedInvoiceType', 'projects'))
    }
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Nothing
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const onChange = async (data: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  }

  function serialToDate(serial: number) {
    // Base date in JavaScript (January 1, 1900)
    let baseDate = new Date(1900, 0, 1)

    // Adjust for the fact that Excel treats 1900 as a leap year incorrectly
    let daysToAdd = serial - 1 // Excel serial number 1 corresponds to Jan 1, 1900

    // Create a new date by adding the serial number as days to the base date
    baseDate.setDate(baseDate.getDate() + daysToAdd)

    return baseDate
  }

  const downloadInvoiceZip = (spreadsheetData: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      const zip = new JSZip()
      // Group Rows by Invoice Number
      let groupedInvoices: TsInterface_UnspecifiedObject = {}
      for (let loopRowIndex in spreadsheetData) {
        let loopRow = spreadsheetData[loopRowIndex]
        let loopKey = loopRow.invoice_number
        if (loopRow != null && loopRow['download_type'] != null && loopRow['download_type'].toLowerCase().indexOf('quote') > -1) {
          loopKey = loopRow.invoice_number + '_quote'
        } else if (loopRow != null && loopRow['download_type'] != null && loopRow['download_type'].toLowerCase().indexOf('detail') > -1) {
          loopKey = loopRow.invoice_number + '_detailed'
        } else {
          loopKey = loopRow.invoice_number + '_invoice'
        }
        if (groupedInvoices[loopKey] == null) {
          groupedInvoices[loopKey] = []
        }
        groupedInvoices[loopKey].push(loopRow)
      }
      // Generate Formatted Data with combined line items
      let formattedDataList: TsInterface_UnspecifiedObject[] = []
      for (let invoiceNumber in groupedInvoices) {
        let invoiceData = groupedInvoices[invoiceNumber]
        let formattedData: TsInterface_UnspecifiedObject = {
          download_type: invoiceData[0].download_type,
          invoice_number: invoiceData[0].invoice_number,
          permitting_fees: null,
          // permitting_fees: invoiceData[0].permitting_fees,
          invoice_date: returnFormattedDate(serialToDate(invoiceData[0].invoice_date), 'MM/DD/YYYY'),
          client_name: invoiceData[0].client_name,
          location_address: invoiceData[0].location_address,
          location_city: invoiceData[0].location_city,
          location_state: invoiceData[0].location_state,
          location_zip: invoiceData[0].location_zip,
          location_city_plus_state: invoiceData[0].location_city + ', ' + invoiceData[0].location_state,
          job_code: invoiceData[0].job_code,
          line_items: [],
          line_item_total: 0,
          line_item_notes: '',
        }
        for (let lineItem of invoiceData) {
          // Add first permitting fee found
          if (
            lineItem.permitting_fees != null &&
            !isNaN(parseFloat(lineItem.permitting_fees)) &&
            lineItem.permitting_fees > 0 &&
            formattedData.permitting_fees == null
          ) {
            formattedData.permitting_fees = formatCurrency(parseFloat(lineItem.permitting_fees))
            formattedData.line_item_total += parseFloat(lineItem.permitting_fees)
          }
          formattedData.line_items.push({
            item_job_code: lineItem.job_code,
            item_description: lineItem.description,
            item_total: formatCurrency(lineItem.amount),
            // NEW
            item_rate: formatCurrency(lineItem.rate),
            item_adders: formatCurrency(lineItem.adders),
          })
          if (!isNaN(parseFloat(lineItem.amount))) {
            formattedData.line_item_total += parseFloat(lineItem.amount)
          }
          if (lineItem.notes) {
            formattedData.line_item_notes += lineItem.notes + '\n'
          }
        }
        formattedData.line_item_total = formatCurrency(formattedData.line_item_total)
        formattedDataList.push(formattedData)
      }
      // Download Zip
      let promiseArray: Promise<any>[] = []
      for (let i = 0; i < formattedDataList.length; i++) {
        let invoiceNumber = formattedDataList[i].invoice_number
        let downloadType = formattedDataList[i].download_type
        let template = invoicePdfTemplate
        if (downloadType != null && downloadType.toLowerCase().indexOf('quote') > -1) {
          template = quotePdfTemplate
        } else if (downloadType != null && downloadType.toLowerCase().indexOf('detail') > -1) {
          template = detailedInvoicePdfTemplate
        }
        // template = detailedInvoicePdfTemplate // TESTING
        let dataForDownload = []
        dataForDownload.push(formattedDataList[i])
        let pdfJSX = generatePdfFromData(template, formattedDataList[i], 'download', uc_setUserInterface_FormDialogDisplay, onChange, {})
        // downloadPdfFromData({ name: 'test' + i + '.pdf' }, pdfContent, dataForDownload)
        const pdfBlob = pdf(pdfJSX).toBlob()
        zip.file(invoiceNumber + `.pdf`, pdfBlob)
      }
      Promise.all(promiseArray).finally(() => {
        zip
          .generateAsync({ type: 'blob' })
          .then((blob) => {
            saveAs(blob, 'invoices.zip')
            resolve({ success: true })
          })
          .catch((error) => {
            console.error(error)
            resolve({ success: false })
          })
      })
    })
  }

  const tableDatabaseEndpoint_InvoiceProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'invoice_status', comparator: '==', value: getProp(tableAdditionalData, 'invoice_status', '') },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'id_number', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Projects_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_BaseInvoices = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: getProp(tableAdditionalData, 'status', '') }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_ProjectBaseInvoices_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_AdditionalWorkInvoices = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: getProp(tableAdditionalData, 'status', '') }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_ProjectAdditionalWorkInvoices_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_SettingsButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminInvoiceSettingsPage.url())
        }}
        disableElevation
        className="tw-mr-2 tw-align-top"
        sx={{ height: '36px' }}
      >
        <Icon
          icon="gear"
          sx={{ fontSize: '18px' }}
        />
      </Button>
    )
    return buttonJSX
  }

  // const rJSX_Pdf2Content = (): JSX.Element => {
  //   let tabJSX = (
  //     <>
  //       {LayeredPdfEditor({
  //         pdfUrl:
  //           'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/temp%2Fpdf%20test.pdf?alt=media&token=b7a9ea87-166d-466d-a65f-40a1337623d1',
  //         initialAnnotations: [
  //           {
  //             x: 100,
  //             y: 400,
  //             text: 'Test Annotation 1',
  //             page: 1,
  //           },
  //           {
  //             x: 170,
  //             y: 142,
  //             text: 'Test Annotation 2a',
  //             page: 2,
  //           },
  //           {
  //             x: 10,
  //             y: 10,
  //             text: 'Test Annotation 2b',
  //             page: 2,
  //           },
  //         ],
  //       })}
  //     </>
  //   )

  //   return tabJSX
  // }

  // const rJSX_Pdf2Tab = (): JSX.Element => {
  //   let tabJSX = <>{rJSX_Pdf2Content()}</>

  //   return tabJSX
  // }

  const rJSX_BulkInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-text-center tw-p-4">
        <Typography>{rLIB('Use the import button above to upload a CSV with your invoices.')}</Typography>
      </Card>
    )
    return tabJSX
  }

  const rJSX_ImportInvoicesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Invoices')}
        importDialogHeader={rLIB('Confirm Invoices Import')}
        importMappingOptions={{
          invoice_number: {
            key: 'invoice_number',
            required: false,
            label: rLIB('Invoice Number'),
            automatch_properties: ['Invoice Number'],
          },
          invoice_date: {
            key: 'invoice_date',
            required: false,
            label: rLIB('Invoice Date'),
            automatch_properties: ['Invoice Date'],
          },
          client_name: {
            key: 'client_name',
            required: false,
            label: rLIB('Client Name'),
            automatch_properties: ['Client Name'],
          },
          location_address: {
            key: 'location_address',
            required: false,
            label: rLIB('Street'),
            automatch_properties: ['Street'],
          },
          location_city: {
            key: 'location_city',
            required: false,
            label: rLIB('City'),
            automatch_properties: ['City'],
          },
          location_state: {
            key: 'location_state',
            required: false,
            label: rLIB('State'),
            automatch_properties: ['State'],
          },
          location_zip: {
            key: 'location_zip',
            required: false,
            label: rLIB('Zip Code'),
            automatch_properties: ['ZIP Code'],
          },
          job_code: {
            key: 'job_code',
            required: false,
            label: rLIB('Project'),
            automatch_properties: ['Project'],
          },
          description: {
            key: 'description',
            required: false,
            label: rLIB('Description'),
            automatch_properties: ['Description'],
          },
          rate: {
            key: 'rate',
            required: false,
            label: rLIB('Rate'),
            automatch_properties: ['Rate'],
          },
          adders: {
            key: 'adders',
            required: false,
            label: rLIB('Adders'),
            automatch_properties: ['Adders'],
          },
          amount: {
            key: 'amount',
            required: false,
            label: rLIB('Amount'),
            automatch_properties: ['Amount'],
          },
          permitting_fees: {
            key: 'permitting_fees',
            required: false,
            label: rLIB('Permitting Fees'),
            automatch_properties: ['Permitting Fees'],
          },
          notes: {
            key: 'notes',
            required: false,
            label: rLIB('Notes'),
            automatch_properties: ['Notes'],
          },
          download_type: {
            key: 'download_type',
            required: false,
            label: rLIB('Download Type'),
            automatch_properties: ['Download Type'],
          },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            downloadInvoiceZip(spreadsheetData)
              .then((res) => {
                resolve({ success: true })
              })
              .catch((err) => {
                reject({ success: false })
              })
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_InvoiceTypeDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedInvoiceType}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedInvoiceType(event.target.value)
                setPageLocalStorage(pageKey, 'us_selectedInvoiceType', event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'projects'}>{rLIB('Projects')} </MenuItem>
            <MenuItem value={'additional_work'}>{rLIB('Additional Work')}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_ImportBaseInvoiceBillingButton = (): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={false}
        importButtonText={rLIB('Import Base Invoices')}
        importDialogHeader={rLIB('Confirm Base Invoices Import')}
        importMappingOptions={{
          unique_identifier: {
            key: 'unique_identifier',
            required: true,
            label: rLIB('Invoice Number'),
            automatch_properties: ['Invoice Number'],
          },
          amount: {
            key: 'amount',
            required: true,
            label: rLIB('Amount'),
            automatch_properties: ['Amount'],
          },
          date: {
            key: 'date',
            required: true,
            label: rLIB('Date'),
            automatch_properties: ['Date'],
          },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <ReconcileBaseInvoicePaymentsDialog
                        clientKey={res_GCK.clientKey}
                        pendingPayments={spreadsheetData}
                      />
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              })
              .catch((err) => {
                reject({ success: false })
              })
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_ProjectsMissingBaseInvoiceTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ invoice_status: 'missing' }}
                tableColumns={tableColumns_ProjectsMissingInvoiceApproval}
                tableDatabaseEndpoint={tableDatabaseEndpoint_InvoiceProjects}
                tableSettings={tableSettings_InvoiceProjects}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Projects missing base invoice')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Card className="tw-text-center tw-p-4">
            <Typography>{rLIB('Not relevant for additional work')}</Typography>
          </Card>
        )
      }
    }
    return tabJSX
  }

  const rJSX_InvoicesPendingApprovalTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ invoice_status: 'unapproved' }}
                tableColumns={tableColumns_ProjectsPendingInvoiceApproval}
                tableDatabaseEndpoint={tableDatabaseEndpoint_InvoiceProjects}
                tableSettings={tableSettings_InvoiceProjects}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Projects pending base invoice approval')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'unapproved' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_ApprovedInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'approved' }}
                tableColumns={tableColumns_ApprovedInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'approved' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_CompletedInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'completed' }}
                tableColumns={tableColumns_CompletedInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices linked to completed tasks that have not been billed')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'completed' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_BilledInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'billed' }}
                tableColumns={tableColumns_BilledInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices that have been billed but not paid')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'billed' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_PaidInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'paid' }}
                tableColumns={tableColumns_PaidInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices that have been paid')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'paid' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_CancelledInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'cancelled' }}
                tableColumns={tableColumns_CancelledInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices that have been cancelled')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'cancelled' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Invoicing')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminInvoiceIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Invoicing', false) as string,
              }}
              tabs={[
                {
                  tabUrlKey: 'Bulk_Invoices',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Bulk Invoice and Quotes'),
                  tabContent: <Box>{rJSX_BulkInvoicesTab()}</Box>,
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportInvoicesButton(false), minJSX: rJSX_ImportInvoicesButton(true), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'missing',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Missing'),
                  tabContent: rJSX_ProjectsMissingBaseInvoiceTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'pending',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Pending'),
                  tabContent: rJSX_InvoicesPendingApprovalTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'approved',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Approved'),
                  tabContent: rJSX_ApprovedInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'completed',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Completed'),
                  tabContent: rJSX_CompletedInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'billed',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Billed'),
                  tabContent: rJSX_BilledInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportBaseInvoiceBillingButton(), minJSX: rJSX_ImportBaseInvoiceBillingButton(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'paid',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Paid'),
                  tabContent: rJSX_PaidInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'cancelled',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Cancelled'),
                  tabContent: rJSX_CancelledInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                // {
                //   tabUrlKey: 'PDF2',
                //   tabOnChange: () => {},
                //   tabHeader: 'PDF2',
                //   tabContent: <Box>{rJSX_Pdf2Tab()}</Box>,
                //   tabButtons: [{ fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 }],
                // },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
