/* eslint-disable react/prop-types */
import { Box, CircularProgress, createTheme, ThemeProvider } from '@mui/material'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DatabaseRef_UnprotectedOpportunityUpdateTime_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { TsInterface_MapMarkers } from 'rfbp_core/components/map'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_CustomDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
// @ts-expect-error - TODO: reason for error
import logoSrc from '../../images/logos/logo_temp.png'
import { utilityCompanyMap } from './data/sales_opportunity_v3_data'
import { submitOffsetChange } from './functions/sales_opportunity_v3_functions'
import { TsInterface_LeadData } from './interfaces/i_sales_tools'
import { rJSX_AddressBanner, rJSX_BuildSystemPage, rJSX_EmailPage, rJSX_EntryPage, rJSX_OrderDetailsPage } from './unauthenticated_sales_opportunity_v3_jsx'

///////////////////////////////
// Typescript
///////////////////////////////
interface ContainerProps {
  isAuthenticatedUser: boolean
}

///////////////////////////////
// Variables
///////////////////////////////
const black = '#000000'
const white = '#ffffff'
const teal = '#1E83A1'
const lightTeal = '#33b3d9'
const darkTeal = '#166076'
const lightGray = '#F4F7F9'
const lightGray2 = '#dbe5eb'
const darkGray = '#9C9C9E'
const green = '#1ea13c'
const lightGreen = '#33d95e'
const yellow = '#f7a830'
const aqua = '#3fdeff'
const lightAqua = '#a7f3fd'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: teal,
      light: aqua,
      dark: darkTeal,
      contrastText: white,
    },
  },
})

const DEFAULT_LAT = 37.77492
const DEFAULT_LNG = -122.4194
const DEFAULT_ADDRESS = '1580 Market Street'
const DEFAULT_CITY = 'San Francisco'
const DEFAULT_STATE = 'CA'
const DEFAULT_ZIP = '94102'

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////
export const Container: React.FC<ContainerProps> = ({ isAuthenticatedUser }): JSX.Element => {
  // Hooks - useContext, useState, useReducer, other
  const params = useParams()
  //const clientKey: string = 'etw_energy'
  const clientKey: string = 'etw_energy_sales'
  let opportunityKey: string = params.id as string

  if (opportunityKey == null) {
    opportunityKey = ' '
  }

  // General
  const ur_mapRef = useRef(null)
  const [us_leadData, us_setLeadData] = useState<TsInterface_LeadData | undefined>(undefined)
  const [us_pastEntryPage, us_setPastEntryPage] = useState<boolean>(false)
  const [us_allowBuildSystem, us_setAllowBuildSystem] = useState<boolean>(true)
  const [us_showAddressErrorMessage, us_setShowAddressErrorMessage] = useState<boolean>(false)
  const [us_dataLoaded, us_setDataLoaded] = useState<boolean>(false)

  // Page structure/admin settings
  const [us_hideEditPanelLayoutButton, us_setHideEditPanelLayoutButton] = useState<boolean>(false)
  const [us_hideEditUtilityButton, us_setHideEditUtilityButton] = useState<boolean>(false)
  const [us_hideOffsetSlider, us_setHideOffsetSlider] = useState<boolean>(false)

  const [us_tcpaAccepted, us_setTcpaAccepted] = useState<boolean>(false)

  // Customer submission data
  const [us_submitted, us_setSubmitted] = useState<boolean>(false)
  const [us_submittedDate, us_setSubmittedDate] = useState<Date | null>(null)
  const [us_submittedSize, us_setSubmittedSize] = useState<number>(0)
  const [us_submittedProduction, us_setSubmittedProduction] = useState<number>(0)
  const [us_submittedNumPanels, us_setSubmittedNumPanels] = useState<number>(0)
  const [us_submittedFinancingType, us_setSubmittedFinancingType] = useState<string>('')
  const [us_submittedPrice, us_setSubmittedPrice] = useState<number>(0)

  // const [us_showUtilityCompanyConfirmationModal, us_setShowUtilityCompanyConfirmationModal] = useState<boolean>(true)
  const [us_propertyCoordinates, us_setPropertyCoordinates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_confirmedPhoneNumber, us_setConfirmedPhoneNumber] = useState<boolean>(false)
  const [us_dataLoadedStatus, us_setDataLoadedStatus] = useState<string>('loading')

  // Analytics
  const [us_initialSessionSaved, us_setInitialSessionSaved] = useState<boolean>(false)
  const [us_analyticsSessionKey] = useState<string>(uuidv4())
  const [us_initialPageLoadTime] = useState<Date>(new Date())

  const [us_utilityCompanies, us_setUtilityCompanies] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_systemRegressionSliderData, us_setSystemRegressionSliderData] = useState<TsInterface_UnspecifiedObject[] | []>([])
  const [us_customerName, us_setCustomerName] = useState<string>('')
  const [us_customerEmail, us_setCustomerEmail] = useState<string>('')
  const [us_customerPhone, us_setCustomerPhone] = useState<string>('')
  const [us_utilityCompanyName, us_setUtilityCompanyName] = useState<string>('Commonwealth Edison Co') // TODO: change so that this is based on zip code
  const [us_roofSizeInFeet, us_setRoofSizeInFeet] = useState(0)
  const [us_savingInitialActivePanels, us_setSavingInitialActivePanels] = useState<boolean>(false)
  const [us_activeProposePanels, us_setActiveProposePanels] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_alreadySentEmail, us_setAlreadySentEmail] = useState<boolean>(false)
  const [us_electricityBill, us_setElectricityBill] = useState<number>(200)

  // EagleView Data
  const [us_loadingEagleviewProposeDataStartTime, us_setLoadingEagleviewProposeDataStartTime] = useState<number>(0)
  const [us_loadingEagleviewProposeDataSecondsSinceStart, us_setLoadingEagleviewProposeDataSecondsSinceStart] = useState<number>(0)

  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ul_queryParams = new URLSearchParams(useLocation().search)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)

  // Build System Page State
  const [us_selectedTab, us_setSelectedTab] = useState('systemSize')
  const [us_selectedFinancingType, us_setSelectedFinancingType] = useState<string>('cash')
  const [us_section1Ref, us_setSection1Ref] = useState<string>('systemSize')
  const [us_section2Ref, us_setSection2Ref] = useState<string>('financing')
  const [us_section3Ref, us_setSection3Ref] = useState<string>('help')
  const [us_section4Ref, us_setSection4Ref] = useState<string>('options')
  const [us_selectedRoofType, us_setSelectedRoofType] = useState<string>('')
  const [us_selectedMountType, us_setSelectedMountType] = useState<string>('roof')
  const [us_numBatteries, us_setNumBatteries] = useState<number>(0)
  const [us_roofReplacement, us_setRoofReplacement] = useState<boolean>(false)
  const [us_systemSizeInWatts, us_setSystemSizeInWatts] = useState<number>(0)
  const [us_offset, us_setOffset] = useState<number>(100)
  const [us_showCashPriceNoteCard, us_setShowCashPriceNoteCard] = useState<boolean>(true)
  const [us_showSolarLeaseNoteCard, us_setShowSolarLeaseNoteCard] = useState<boolean>(true)
  const [us_cashSavings, us_setCashSavings] = useState<number>(0)
  const [us_leaseMonthlySavings, us_setLeaseMonthlySavings] = useState<number>(0)
  const [us_leaseCumulativeSavings, us_setLeaseCumulativeSavings] = useState<number>(0)
  const [us_numPanels, us_setNumPanels] = useState(0)
  const [us_baseCashCost, us_setBaseCashCost] = useState<number>(0)
  const [us_currentCashCost, us_setCurrentCashCost] = useState<number>(0)
  const [us_leaseCost, us_setLeaseCost] = useState<number>(0)
  const [us_systemAnnualProductionInKWh, us_setSystemAnnualProductionInKwh] = useState<number>(0)
  const [us_federalIncentive, us_setFederalIncentive] = useState<number>(0)
  const [us_srecIncentive, us_setSrecIncentive] = useState<number>(0)
  const [us_currentView, us_setCurrentView] = useState<string>('buildSystem')
  const [us_showIncentives, us_setShowIncentives] = useState<boolean>(false)
  const [us_firstName, us_setFirstName] = useState<string>('')
  const [us_lastName, us_setLastName] = useState<string>('')
  const [us_email, us_setEmail] = useState<string>('')
  const [us_phone, us_setPhone] = useState<string>('')
  const [us_looksGood, us_setLooksGood] = useState<boolean>(false)
  const [us_agreeTerms, us_setAgreeTerms] = useState<boolean>(false)
  const [us_showWelcomeBanner, us_setShowWelcomeBanner] = useState<boolean>(false)
  const [us_homeDetailsExpanded, us_setHomeDetailsExpanded] = useState<boolean>(true)
  const [us_editingField, us_setEditingField] = useState<string | null>(null)
  const [us_editValue, us_setEditValue] = useState('')
  const [us_isDatabaseMode, us_setIsDatabaseMode] = useState<boolean>(true)
  const [us_address, us_setAddress] = useState<string>('')
  const [us_city, us_setCity] = useState<string>('')
  const [us_state, us_setState] = useState<string>('')
  const [us_zip, us_setZip] = useState<string>('')
  const [us_editAddressOpen, us_setEditAddressOpen] = useState<boolean>(false)
  const [us_latitude, us_setLatitude] = useState<number>(DEFAULT_LAT)
  const [us_longitude, us_setLongitude] = useState<number>(DEFAULT_LNG)
  const [us_mapMarkers, us_setMapMarkers] = useState<TsInterface_MapMarkers>({})
  const [us_tempState, us_setTempState] = useState<string>('')
  const [us_tempZip, us_setTempZip] = useState<string>('')
  const [us_tempCity, us_setTempCity] = useState<string>('')
  const [us_tempAddress, us_setTempAddress] = useState<string>('')
  const [us_depositRequired, us_setDepositRequired] = useState<boolean>(false)
  const [us_isCashCardFlipped, us_setIsCashCardFlipped] = useState(false)
  const [us_isLeaseCardFlipped, us_setIsLeaseCardFlipped] = useState(false)
  const [us_depositText, us_setDepositText] = useState<boolean>(false)
  const [us_emailError, us_setEmailError] = useState<string | null>(null)
  const [us_phoneError, us_setPhoneError] = useState<string | null>(null)
  const [us_zipError, us_setZipError] = useState<string | null>(null)

  const un_routerNavigation = useNavigate()

  const gettersSetters = {
    us_setElectricityBill,
    us_setNumPanels,
    us_setBaseCashCost,
    us_setCurrentCashCost,
    us_setLeaseCost,
    us_setSystemSizeInWatts,
    us_setSystemAnnualProductionInKwh,
    us_setCashSavings,
    us_setLeaseMonthlySavings,
    us_setLeaseCumulativeSavings,
    us_setOffset,
    us_setFederalIncentive,
    us_setSrecIncentive,
  }

  // Function to load saved data on component mount
  const loadSavedSystemData = () => {
    const savedData = getProp(getPageLocalStorage('go_solar'), 'solarSystemData', null)
    if (savedData) {
      us_setNumPanels(savedData.panel_quantity)
      us_setBaseCashCost(savedData.financials_cash_base_price)
      us_setCurrentCashCost(savedData.financials_cash_price_after_incentives)
      us_setLeaseCost(savedData.financials_lease_monthly_payment)
      us_setSystemSizeInWatts(savedData.selected_system_size)
      us_setSystemAnnualProductionInKwh(savedData.system_estimated_annual_production)
      us_setCashSavings(savedData.cash_savings)
      us_setLeaseMonthlySavings(savedData.financials_lease_monthly_savings)
      us_setLeaseCumulativeSavings(savedData.financials_lease_year_one_savings)
      us_setOffset(savedData.system_usage_offset)
      us_setElectricityBill(savedData.system_monthly_electricity_bill)
      us_setFederalIncentive(savedData.financials_federal_incentive)
      us_setSrecIncentive(savedData.financials_srec_incentive)
    }
  }

  // Functions necessary for useEffect
  const loadPageData = useCallback((clientKey: string, opportunityKey: string) => {
    const handleOpportunityData = (opportunityData: any, leadData: any) => {
      if (!opportunityData) return

      const {
        confirmed,
        shown_sections,
        associated_utility_company_name,
        system_monthly_electricity_bill,
        hide_edit_panel_layout_button,
        hide_edit_utility_button,
        hide_offset_slider,
        associated_customer_name,
        associated_customer_email,
        associated_customer_phone,
        financials_cash_price_after_incentives,
        system_usage_offset,
        financials_cash_base_price,
        lease_cost, //update to v2 label
        cash_savings, //update to v2 label
        financials_lease_monthly_savings, //update to v2 label
        financials_lease_year_one_savings,
        financials_federal_incentive,
        financials_srec_incentive,
        panel_quantity,
        selected_system_size, //still need to be update for v2 database
        system_estimated_annual_production, //still need to be update for v2 database
        tcpa_accepted,
        submitted,
        submitted_size,
        submitted_production,
        submitted_num_panels,
        submitted_financing_type,
        submitted_price,
        submission_date,
        past_entry_page,
        associated_financing_type,
        home_roof_type,
        system_mount_type,
        system_storage_quantity,
        roof_replacement,
        associated_customer_first_name,
        associated_customer_last_name,
        location_latitude,
        location_longitude,
        location_address,
        location_city,
        location_state,
        location_zip,
      } = opportunityData

      const electricityBill = parseInt(system_monthly_electricity_bill)

      // us_setShowUtilityCompanyConfirmationModal(!confirmed)
      us_setUtilityCompanyName(associated_utility_company_name || us_utilityCompanyName)
      us_setElectricityBill(electricityBill || us_electricityBill)
      us_setHideEditPanelLayoutButton(hide_edit_panel_layout_button || false)
      us_setHideEditUtilityButton(hide_edit_utility_button || false)
      us_setHideOffsetSlider(hide_offset_slider || false)
      us_setCustomerName(associated_customer_name || us_customerName)
      us_setCustomerEmail(associated_customer_email || us_customerEmail)
      us_setCustomerPhone(associated_customer_phone || us_customerPhone)
      us_setCurrentCashCost(financials_cash_price_after_incentives || us_currentCashCost)
      us_setOffset(system_usage_offset || us_offset)
      us_setBaseCashCost(financials_cash_base_price || us_baseCashCost)
      us_setLeaseCost(lease_cost || us_leaseCost)
      us_setCashSavings(cash_savings || us_cashSavings)
      us_setLeaseMonthlySavings(financials_lease_monthly_savings || us_leaseMonthlySavings)
      us_setLeaseCumulativeSavings(financials_lease_year_one_savings || us_leaseCumulativeSavings)
      us_setFederalIncentive(financials_federal_incentive || us_federalIncentive)
      us_setSrecIncentive(financials_srec_incentive || us_srecIncentive)
      us_setNumPanels(panel_quantity || us_numPanels)
      us_setSystemSizeInWatts(selected_system_size || us_systemSizeInWatts)
      us_setSystemAnnualProductionInKwh(system_estimated_annual_production || us_systemAnnualProductionInKWh)
      us_setTcpaAccepted(tcpa_accepted || us_tcpaAccepted)
      us_setPastEntryPage(past_entry_page || false)
      us_setSelectedFinancingType(associated_financing_type || us_selectedFinancingType)
      us_setSelectedRoofType(home_roof_type || us_selectedRoofType)
      us_setSelectedMountType(system_mount_type || us_selectedMountType)
      us_setNumBatteries(system_storage_quantity || us_numBatteries)
      us_setRoofReplacement(roof_replacement || us_roofReplacement)
      us_setFirstName(associated_customer_first_name || us_firstName)
      us_setLastName(associated_customer_last_name || us_lastName)
      us_setEmail(associated_customer_email || us_email)
      us_setPhone(associated_customer_phone || us_phone)
      us_setLatitude(location_latitude || us_latitude)
      us_setLongitude(location_longitude || us_longitude)
      us_setAddress(location_address || us_address)
      us_setCity(location_city || us_city)
      us_setState(location_state || us_state)
      us_setZip(location_zip || us_zip)

      const gettersSetters = {
        us_electricityBill: electricityBill,
        us_setNumPanels,
        us_setBaseCashCost,
        us_setCurrentCashCost,
        us_setLeaseCost,
        us_setSystemSizeInWatts,
        us_setSystemAnnualProductionInKwh,
        us_setCashSavings,
        us_setLeaseMonthlySavings,
        us_setLeaseCumulativeSavings,
        us_setOffset,
        us_setFederalIncentive,
        us_setSrecIncentive,
        us_leadData: leadData,
        us_utilityCompanyName: associated_utility_company_name,
        us_isDatabaseMode,
      }

      //submitOffsetChange(gettersSetters, system_usage_offset || 100, false, opportunityKey, clientKey)

      if (submitted) {
        us_setSubmitted(true)
        us_setSubmittedSize(submitted_size || us_systemSizeInWatts)
        us_setSubmittedProduction(submitted_production || us_systemAnnualProductionInKWh)
        us_setSubmittedNumPanels(submitted_num_panels || us_numPanels)
        us_setSubmittedFinancingType(submitted_financing_type || us_selectedFinancingType)
        us_setSubmittedPrice(submitted_price || us_currentCashCost)
        us_setSubmittedDate(new Date(submission_date))
      }
    }

    // const handleEagleviewData = (data: any) => {
    //   const { eagleviewProspectData, eagleviewProposeData } = data
    //   let evRegressionData: TsInterface_UnspecifiedObject[] = []

    //   if (!eagleviewProposeData) {
    //     evRegressionData = getEagleViewSliderData(getEagleViewSystemSizesAndProductionRatios(eagleviewProspectData.ev_slider_data))
    //   } else {
    //     evRegressionData = getEagleViewProposeSliderData(eagleviewProposeData.dataOutput.energyTable)
    //   }

    //   us_setSystemRegressionSliderData(evRegressionData)
    // }

    // if (opportunityKey == '') {
    //   return
    // }

    cloudFunctionUnauthenticatedRequests({
      function: 'getSalesOpportunityData_v2',
      client_key: clientKey,
      opportunity_key: opportunityKey,
    })
      .then((res_CFUR: any) => {
        us_setIsDatabaseMode(true)

        const { data } = res_CFUR
        if (!data) return

        us_setLeadData(data)
        // load prospect data if it's missing
        // const prospectDataMissing = !data.eagleviewProspectData || Object.keys(data.eagleviewProspectData).length === 0
        // const isEagleviewSource =
        //   data.opportunityData.first_page_data_source === 'eagleview_prospect' || us_dataLoadedStatus === 'loading_eagleview_propose_data'

        // if (prospectDataMissing && isEagleviewSource) {
        //   return cloudFunctionUnauthenticatedRequests({
        //     function: 'generateNewOpportunityData_v2',
        //     client_key: clientKey,
        //     opportunity_key: opportunityKey,
        //     formSubmittedData: data.opportunityData,
        //     generateFlags: { google: false, ev_prospect: true, ev_propose: false },
        //   })
        // }

        // // handle Eagleview and Google data
        // if (Object.keys(data.eagleviewProspectData).length > 0) {
        //   handleEagleviewData(data)
        // } else if (data.googleSolarData?.solarPotential) {
        //   us_setSystemRegressionSliderData(getGoogleRegressionSliderData(data.googleSolarData))
        //   us_setRoofSizeInFeet(data.googleSolarData.solarPotential.buildingStats.areaMeters2 * 10.7639)
        //   if (data.googleSolarData.center) {
        //     us_setPropertyCoordinates(data.googleSolarData.center)
        //   }
        // }

        // Handle Opportunity Data
        handleOpportunityData(data.opportunityData, data)

        // // Update data loaded status
        // const shouldWaitForProposeData = us_dataLoadedStatus === 'loading_eagleview_propose_data' || isEagleviewSource
        // if (shouldWaitForProposeData && (!data.eagleviewProspectData || !data.eagleviewProposeData)) return

        us_setDataLoaded(true)

        // const pageLocalStorageData = getPageLocalStorage('go_solar_' + opportunityKey)
        // const storedOffset = getProp(pageLocalStorageData, 'offset', null)

        // us_setOffset(storedOffset !== null ? storedOffset : res_CFUR.data.opportunityData.offset || 100)
      })
      .catch((rej_CFUR) => {
        //us_setDataLoadedStatus('error')\
        us_setIsDatabaseMode(false)

        let pageLocalStorageData = getPageLocalStorage('go_solar')
        if (Object.keys(pageLocalStorageData).length > 0) {
          if (getProp(pageLocalStorageData, 'financingType', null) != null) {
            us_setSelectedFinancingType(getProp(pageLocalStorageData, 'financingType', 'cash'))
          }
          if (getProp(pageLocalStorageData, 'home_roof_type', null) != null) {
            us_setSelectedRoofType(getProp(pageLocalStorageData, 'home_roof_type', ''))
          }
          if (getProp(pageLocalStorageData, 'system_mount_type', null) != null) {
            us_setSelectedMountType(getProp(pageLocalStorageData, 'system_mount_type', 'roof'))
          }
          if (getProp(pageLocalStorageData, 'home_roof_quality', null) != null) {
            us_setRoofReplacement(getProp(pageLocalStorageData, 'home_roof_quality', false))
          }
          if (getProp(pageLocalStorageData, 'system_storage_quantity', null) != null) {
            us_setNumBatteries(getProp(pageLocalStorageData, 'system_storage_quantity', 0))
          }
          if (getProp(pageLocalStorageData, 'system_monthly_electricity_bill', null) != null) {
            us_setElectricityBill(getProp(pageLocalStorageData, 'system_monthly_electricity_bill', 200))
          }
          if (getProp(pageLocalStorageData, 'location_latitude', null) != null) {
            us_setLatitude(getProp(pageLocalStorageData, 'location_latitude', DEFAULT_LAT))
          }
          if (getProp(pageLocalStorageData, 'location_longitude', null) != null) {
            us_setLongitude(getProp(pageLocalStorageData, 'location_longitude', DEFAULT_LNG))
          }
          if (getProp(pageLocalStorageData, 'location_state', null) != null) {
            us_setState(getProp(pageLocalStorageData, 'location_state', DEFAULT_STATE))
          }
          if (getProp(pageLocalStorageData, 'location_zip', null) != null) {
            us_setZip(getProp(pageLocalStorageData, 'location_zip', DEFAULT_ZIP))
          }
          if (getProp(pageLocalStorageData, 'location_city', null) != null) {
            us_setCity(getProp(pageLocalStorageData, 'location_city', DEFAULT_CITY))
          }
          if (getProp(pageLocalStorageData, 'location_address', null) != null) {
            us_setAddress(getProp(pageLocalStorageData, 'location_address', DEFAULT_ADDRESS))
          }
          if (getProp(pageLocalStorageData, 'associated_customer_first_name', null) != null) {
            us_setFirstName(getProp(pageLocalStorageData, 'associated_customer_first_name', ''))
          }
          if (getProp(pageLocalStorageData, 'associated_customer_last_name', null) != null) {
            us_setLastName(getProp(pageLocalStorageData, 'associated_customer_last_name', ''))
          }
          if (getProp(pageLocalStorageData, 'associated_customer_email', null) != null) {
            us_setEmail(getProp(pageLocalStorageData, 'associated_customer_email', ''))
          }
          if (getProp(pageLocalStorageData, 'associated_customer_phone', null) != null) {
            us_setPhone(getProp(pageLocalStorageData, 'associated_customer_phone', ''))
          }
          if (getProp(pageLocalStorageData, 'solarSystemData', null) != null) {
            loadSavedSystemData()
          }
          if (getProp(pageLocalStorageData, 'pastEntryPage', null) != null) {
            us_setPastEntryPage(getProp(pageLocalStorageData, 'pastEntryPage', false))
          }
          if (getProp(pageLocalStorageData, 'zip_error', null) != null) {
            us_setZipError(getProp(pageLocalStorageData, 'zip_error', null))
          }
          if (getProp(pageLocalStorageData, 'allow_build_system', null) != null) {
            us_setAllowBuildSystem(getProp(pageLocalStorageData, 'allow_build_system', false))
          }
          us_setDataLoaded(true)
        } else {
          us_setPastEntryPage(false)
          // Set default values if no local storage data is available
          us_setSelectedFinancingType('cash')
          us_setSelectedMountType('roof')
          us_setRoofReplacement(false)
          us_setNumBatteries(0)
          us_setElectricityBill(200)
          us_setUtilityCompanies(objectToArray(utilityCompanyMap[us_state]).sort(dynamicSort('label', 'asc')))
          //us_setLatitude(DEFAULT_LAT)
          //us_setLongitude(DEFAULT_LNG)
          setPageLocalStorage('go_solar', 'databaseMode', false)
          setPageLocalStorage('go_solar', 'associated_financing_type', 'cash')
          setPageLocalStorage('go_solar', 'home_roof_type', 'shingle')
          setPageLocalStorage('go_solar', 'system_mount_type', 'roof')
          setPageLocalStorage('go_solar', 'home_roof_quality', false)
          setPageLocalStorage('go_solar', 'system_storage_quantity', 0)
          setPageLocalStorage('go_solar', 'system_monthly_electricity_bill', 200)
          //setPageLocalStorage('go_solar', 'location_latitude', DEFAULT_LAT)
          //setPageLocalStorage('go_solar', 'location_longitude', DEFAULT_LNG)

          const defaultGettersSetters = {
            us_electricityBill,
            us_setNumPanels,
            us_setBaseCashCost,
            us_setCurrentCashCost,
            us_setLeaseCost,
            us_setSystemSizeInWatts,
            us_setSystemAnnualProductionInKwh,
            us_setCashSavings,
            us_setLeaseMonthlySavings,
            us_setLeaseCumulativeSavings,
            us_setOffset,
            us_setFederalIncentive,
            us_setSrecIncentive,
            us_utilityCompanyName,
            us_isDatabaseMode: false,
          }
          submitOffsetChange(defaultGettersSetters, 100, false, opportunityKey, clientKey)
          us_setDataLoaded(true)
        }
      })
  }, [])

  // load in the page data
  useEffect(() => {
    document.title = rLIB('Go Solar with ETW Energy', false) as string
  }, [])

  // set region from Local Storage
  useEffect(() => {
    let pageLocalStorageData = getPageLocalStorage('go_solar_' + opportunityKey)
    if (getProp(pageLocalStorageData, 'confirmed_phone_number', null) == true) {
      us_setConfirmedPhoneNumber(true)
    }
    // if (getProp(pageLocalStorageData, 'financingType', null) != null) {
    //   us_setSelectedFinancingType(getProp(pageLocalStorageData, 'financingType', 'cash'))
    // }
    // if (getProp(pageLocalStorageData, 'selectedRoofType', null) != null) {
    //   us_setSelectedRoofType(getProp(pageLocalStorageData, 'selectedRoofType', 'shingle'))
    // }
    // if (getProp(pageLocalStorageData, 'selectedMountType', null) != null) {
    //   us_setSelectedMountType(getProp(pageLocalStorageData, 'selectedMountType', 'roof'))
    // }
    // if (getProp(pageLocalStorageData, 'roofReplacement', null) != null) {
    //   us_setRoofReplacement(getProp(pageLocalStorageData, 'roofReplacement', false))
    // }
    // if (getProp(pageLocalStorageData, 'numBatteries', null) != null) {
    //   us_setNumBatteries(getProp(pageLocalStorageData, 'numBatteries', 0))
    // }
    // if (getProp(pageLocalStorageData, 'solarSystemData', null) != null) {
    //   loadSavedSystemData(gettersSetters, opportunityKey)
    // }
  }, [opportunityKey, gettersSetters])

  // load all lead, google, and eagleview data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      loadPageData(clientKey, opportunityKey)
      ur_forceRerender()
    }

    unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_UnprotectedOpportunityUpdateTime_Document(opportunityKey), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [opportunityKey, loadPageData, ur_forceRerender])

  // Save Initial Analytics Session
  // useEffect(() => {
  //   if (us_initialSessionSaved === false) {
  //     let sessionMetadata: TsInterface_UnspecifiedObject = {
  //       metadata_version: '1.0.0',
  //       cookies_enabled: getProp(navigator, 'cookieEnabled', null),
  //       device_memory: getProp(navigator, 'deviceMemory', null),
  //       language: getProp(navigator, 'language', null),
  //       platform: getProp(navigator, 'platform', null),
  //       referrer: document.referrer,
  //       screen_available_height: getProp(screen, 'availHeight', null),
  //       screen_available_width: getProp(screen, 'availWidth', null),
  //       screen_height: getProp(screen, 'height', null),
  //       screen_width: getProp(screen, 'width', null),
  //       timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //       user_agent: getProp(navigator, 'userAgent', null),
  //     }
  //     // Get IP Address
  //     let promiseArray: Promise<unknown>[] = []
  //     promiseArray.push(
  //       fetch('https://api.ipify.org?format=json')
  //         .then((response) => response.json())
  //         .then((data) => {
  //           sessionMetadata['ip_address'] = getProp(data, 'ip', null)
  //         }),
  //     )
  //     // After IP Address Loaded
  //     Promise.all(promiseArray).finally(() => {
  //       saveSessionData(
  //         clientKey,
  //         opportunityKey,
  //         us_analyticsSessionKey,
  //         {
  //           key: us_analyticsSessionKey,
  //           associated_opportunity_key: opportunityKey,
  //           associated_client_key: clientKey,
  //           timestamp_session_started: us_initialPageLoadTime,
  //           initialPageLoadURL: window.location.href,
  //           metadata: sessionMetadata,
  //         },
  //         !(ul_queryParams.get('tracking') == 'false'),
  //       )
  //       us_setInitialSessionSaved(true)
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [opportunityKey, us_analyticsSessionKey, us_initialPageLoadTime, us_initialSessionSaved])

  // Interval Update Session Length
  // useEffect(() => {
  //   let currentLoop = 1
  //   let intervalId = setInterval(() => {
  //     let sessionLength = currentLoop * 5
  //     if (sessionLength <= 60) {
  //       // 1 minute
  //       // Save Data to Analytics Session
  //       saveSessionData(
  //         clientKey,
  //         opportunityKey,
  //         us_analyticsSessionKey,
  //         {
  //           timestamp_session_last_updated: new Date(),
  //           session_length_seconds: sessionLength,
  //         },
  //         !(ul_queryParams.get('tracking') == 'false'),
  //       )
  //     } else if (sessionLength <= 180) {
  //       // 3 minutes
  //       // Save Data to Analytics Session on 10 second intervals
  //       if (sessionLength % 10 === 0) {
  //         saveSessionData(
  //           clientKey,
  //           opportunityKey,
  //           us_analyticsSessionKey,
  //           {
  //             timestamp_session_last_updated: new Date(),
  //             session_length_seconds: sessionLength,
  //           },
  //           !(ul_queryParams.get('tracking') == 'false'),
  //         )
  //       }
  //     } else if (sessionLength <= 600) {
  //       // 10 minutes
  //       // Save Data to Analytics Session on 30 second intervals
  //       if (sessionLength % 30 === 0) {
  //         saveSessionData(
  //           clientKey,
  //           opportunityKey,
  //           us_analyticsSessionKey,
  //           {
  //             timestamp_session_last_updated: new Date(),
  //             session_length_seconds: sessionLength,
  //           },
  //           !(ul_queryParams.get('tracking') == 'false'),
  //         )
  //       }
  //     }
  //     currentLoop++
  //     return () => {
  //       if (intervalId != null) {
  //         clearInterval(intervalId)
  //       }
  //     }
  //   }, 5000)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // get the list of utility companies in the state containing the address
  useEffect(() => {
    if (us_isDatabaseMode) {
      if (us_leadData) {
        if (us_leadData?.opportunityData?.location_state) {
          let utilityCompanyList = []
          for (let loopCompanyKey of Object.keys(utilityCompanyMap[us_leadData?.opportunityData?.location_state])) {
            utilityCompanyList.push({
              label: loopCompanyKey,
              key: loopCompanyKey,
            })
          }
          us_setUtilityCompanies(objectToArray(utilityCompanyList).sort(dynamicSort('label', 'asc')))
        }
      }
    } else {
      if (us_state) {
        let utilityCompanyList = []
        // Check if the state exists in the utility company map
        if (utilityCompanyMap[us_state]) {
          for (let loopCompanyKey of Object.keys(utilityCompanyMap[us_state])) {
            utilityCompanyList.push({
              label: loopCompanyKey,
              key: loopCompanyKey,
            })
          }
          us_setUtilityCompanies(objectToArray(utilityCompanyList).sort(dynamicSort('label', 'asc')))
        } else {
          // Handle states not in our system
          us_setUtilityCompanies([
            {
              label: 'No utility companies available for this state',
              key: 'none',
            },
          ])
        }
      }
      //us_setUtilityCompanies(objectToArray(utilityCompanyList).sort(dynamicSort('label', 'asc')))
    }
  }, [us_leadData, us_state, us_isDatabaseMode])

  // useEffect(() => {
  //   if (!us_address) {
  //     us_setEditAddressOpen(true)
  //   }
  // }, [us_address])

  // useEffect(() => {
  //   let intervalId: any = null
  //   if (us_dataLoadedStatus === 'loading_eagleview_propose_data' && us_loadingEagleviewProposeDataStartTime > 0) {
  //     intervalId = setInterval(() => {
  //       let currentTime = new Date().getTime()
  //       // Difference in seconds
  //       let differenceInSeconds = (currentTime - us_loadingEagleviewProposeDataStartTime) / 1000
  //       us_setLoadingEagleviewProposeDataSecondsSinceStart(differenceInSeconds)
  //     }, 100)
  //   }
  //   return () => {
  //     if (intervalId != null) {
  //       clearInterval(intervalId)
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [us_dataLoadedStatus])

  // useEffect(() => {
  //   if (
  //     us_systemAnnualProductionInKWh > 0 &&
  //     us_savingInitialActivePanels === false &&
  //     us_dataLoadedStatus === 'success' &&
  //     us_leadData != null &&
  //     us_leadData.eagleviewProposeData != null &&
  //     objectToArray(us_leadData.eagleviewProposeData).length > 0 &&
  //     us_leadData.opportunityData != null &&
  //     us_leadData.opportunityData['active_propose_panels'] == null
  //   ) {
  //     us_setSavingInitialActivePanels(true)
  //     // Set Initial Panels - One Time Only based on System Size
  //     let activePanelSelection: TsInterface_UnspecifiedObject = {}
  //     let systemSize = us_systemAnnualProductionInKWh
  //     let passedSystemSize = false
  //     for (let loopPanelIndex in getProp(us_leadData.eagleviewProposeData, 'dataOutput', {})['energyTable']) {
  //       let loopPanel = getProp(us_leadData.eagleviewProposeData, 'dataOutput', {})['energyTable'][loopPanelIndex]
  //       if (loopPanel != null) {
  //         activePanelSelection[loopPanelIndex] = true
  //         if (passedSystemSize === false) {
  //           activePanelSelection[loopPanelIndex] = true
  //         } else {
  //           activePanelSelection[loopPanelIndex] = false
  //         }
  //         if (loopPanel['kWh/yr(Cumulative)'] >= systemSize) {
  //           passedSystemSize = true
  //         }
  //       }
  //     }
  //     // updateOpportunity({ active_propose_panels: activePanelSelection })
  //     us_setActiveProposePanels(activePanelSelection)
  //     ur_forceRerender()
  //   } else if (us_leadData != null && us_leadData.opportunityData != null && us_leadData.opportunityData['active_propose_panels'] != null) {
  //     us_setActiveProposePanels(us_leadData.opportunityData['active_propose_panels'])
  //     ur_forceRerender()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [us_dataLoadedStatus, us_leadData, us_savingInitialActivePanels, ur_forceRerender, us_systemAnnualProductionInKWh])

  // Function to reset the map frame
  const resetMapFrame = () => {
    setTimeout(() => {
      // @ts-expect-error - TODO: reason for error
      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)
  }

  //Example usage of resetMapFrame, e.g., after updating map markers
  useEffect(() => {
    // Assuming mapMarkers is updated somewhere in your component
    resetMapFrame()
  }, [us_latitude, us_longitude])

  // TODO: fix so that it only sends an email when we open the page for the first time ehhh maybe not actually
  // useEffect(() => {
  //   if (!isAuthenticatedUser && us_alreadySentEmail === false && us_leadData != null) {
  //     console.log('PROCEED')
  //     cloudFunctionUnauthenticatedRequests({
  //       function: 'sendSalesLeadEmail',
  //       to_array: ['leads.curiosity@etwenergy.com'], // this is set on the server so either way it will only send to this email
  //       cc_array: ['brady.richardson@etwenergy.com', 'raphael@etwenergy.com', 'john.morrell@etwenergy.com'],
  //       bcc_array: [''],
  //       subject: `Customer Page Opened - ${us_leadData?.opportunityData.address1}`,
  //       body: `A customer has opened the page for the property at:\n\n${us_leadData?.opportunityData.address1}`,
  //     })
  //       .then((res_CFUR) => {
  //         console.log(res_CFUR)
  //       })
  //       .catch((rej_CFUR) => {
  //         console.error(rej_CFUR)
  //       })
  //     us_setAlreadySentEmail(true)
  //   }
  // }, [isAuthenticatedUser, us_leadData, us_alreadySentEmail])

  // Functions

  ///////////////////////////////
  // JSX Generation
  ///////////////////////////////

  const rJSX_PageContent = (isAuthenticatedUser: boolean): JSX.Element => {
    let jsx = <></>

    jsx = (
      <>
        {(us_leadData || !us_isDatabaseMode) &&
          rJSX_AddressBanner({ us_showWelcomeBanner, us_setShowWelcomeBanner, us_setCurrentView }, logoSrc, { teal, white, aqua })}
        {(us_leadData || !us_isDatabaseMode) &&
          (!us_pastEntryPage || us_currentView === 'entry') &&
          rJSX_EntryPage(
            {
              clientKey: clientKey,
              opportunityKey: opportunityKey,
              analyticsSessionKey: us_analyticsSessionKey,
              queryParams: ul_queryParams,
            },
            { teal, white, aqua, lightGray, darkGray, black },
            {
              us_leadData,
              us_setLeadData,
              us_electricityBill,
              us_setElectricityBill,
              us_utilityCompanies,
              us_setUtilityCompanies,
              us_utilityCompanyName,
              us_setUtilityCompanyName,
              us_pastEntryPage,
              us_setPastEntryPage,
              us_address,
              us_setAddress,
              us_city,
              us_setCity,
              us_state,
              us_setState,
              us_zip,
              us_setZip,
              us_editAddressOpen,
              us_setEditAddressOpen,
              us_latitude,
              us_setLatitude,
              us_longitude,
              us_setLongitude,
              us_isDatabaseMode,
              us_setIsDatabaseMode,
              us_setFederalIncentive,
              us_setSrecIncentive,
              us_federalIncentive,
              us_srecIncentive,
              us_setNumPanels,
              us_setSystemSizeInWatts,
              us_setBaseCashCost,
              us_setCurrentCashCost,
              us_setLeaseCost,
              us_setSystemAnnualProductionInKwh,
              us_setLeaseMonthlySavings,
              us_setLeaseCumulativeSavings,
              us_numPanels,
              us_systemAnnualProductionInKWh,
              us_systemSizeInWatts,
              us_baseCashCost,
              us_currentCashCost,
              us_leaseCost,
              us_leaseMonthlySavings,
              us_leaseCumulativeSavings,
              us_setCashSavings,
              us_cashSavings,
              us_setOffset,
              us_offset,
              us_mapMarkers,
              us_setMapMarkers,
              us_tempState,
              us_setTempState,
              us_tempCity,
              us_setTempCity,
              us_tempZip,
              us_setTempZip,
              us_tempAddress,
              us_setTempAddress,
              us_setCurrentView,
              us_currentView,
              us_zipError,
              us_setZipError,
              us_allowBuildSystem,
              us_setAllowBuildSystem,
              us_showAddressErrorMessage,
              us_setShowAddressErrorMessage,
            },
            ur_mapRef,
            opportunityKey,
            clientKey,
            us_leadData,
          )}
        {(us_leadData || !us_isDatabaseMode) &&
          us_pastEntryPage &&
          us_currentView === 'buildSystem' &&
          rJSX_BuildSystemPage(
            {
              us_leadData,
              us_setLeadData,
              us_selectedTab,
              us_setSelectedTab,
              us_section1Ref,
              us_section2Ref,
              us_section3Ref,
              us_section4Ref,
              us_selectedFinancingType,
              us_setSelectedFinancingType,
              us_selectedRoofType,
              us_setSelectedRoofType,
              us_selectedMountType,
              us_setSelectedMountType,
              us_numBatteries,
              us_setNumBatteries,
              us_roofReplacement,
              us_setRoofReplacement,
              us_offset,
              us_setOffset,
              us_showCashPriceNoteCard,
              us_setShowCashPriceNoteCard,
              us_showSolarLeaseNoteCard,
              us_setShowSolarLeaseNoteCard,
              us_systemSizeInWatts,
              us_setSystemSizeInWatts,
              us_cashSavings,
              us_setCashSavings,
              us_numPanels,
              us_setNumPanels,
              us_baseCashCost,
              us_setBaseCashCost,
              us_currentCashCost,
              us_setCurrentCashCost,
              us_leaseCost,
              us_setLeaseCost,
              us_systemAnnualProductionInKWh,
              us_setSystemAnnualProductionInKwh,
              us_leaseMonthlySavings,
              us_setLeaseMonthlySavings,
              us_leaseCumulativeSavings,
              us_setLeaseCumulativeSavings,
              us_electricityBill,
              us_setElectricityBill,
              us_utilityCompanyName,
              us_setUtilityCompanyName,
              us_federalIncentive,
              us_setFederalIncentive,
              us_srecIncentive,
              us_setSrecIncentive,
              us_setCurrentView,
              us_showWelcomeBanner,
              us_setShowWelcomeBanner,
              us_editingField,
              us_setEditingField,
              us_editValue,
              us_setEditValue,
              us_isDatabaseMode,
              us_setIsDatabaseMode,
              us_latitude,
              us_setLatitude,
              us_longitude,
              us_setLongitude,
              us_setPastEntryPage,
              us_pastEntryPage,
              us_isCashCardFlipped,
              us_setIsCashCardFlipped,
              us_isLeaseCardFlipped,
              us_setIsLeaseCardFlipped,
              us_state,
              us_setState,
            },
            { teal, white, aqua, darkGray, lightGray, lightGray2, green, lightGreen, yellow },
            opportunityKey,
            clientKey,
          )}
        {us_pastEntryPage &&
          us_currentView === 'orderDetails' &&
          rJSX_OrderDetailsPage(
            {
              us_setCurrentView,
              us_showIncentives,
              us_setShowIncentives,
              us_firstName,
              us_setFirstName,
              us_lastName,
              us_setLastName,
              us_email,
              us_setEmail,
              us_phone,
              us_setPhone,
              us_looksGood,
              us_setLooksGood,
              us_agreeTerms,
              us_setAgreeTerms,
              us_selectedFinancingType,
              us_setSelectedFinancingType,
              us_selectedRoofType,
              us_setSelectedRoofType,
              us_selectedMountType,
              us_setSelectedMountType,
              us_numBatteries,
              us_setNumBatteries,
              us_roofReplacement,
              us_setRoofReplacement,
              us_offset,
              us_setOffset,
              us_showCashPriceNoteCard,
              us_setShowCashPriceNoteCard,
              us_showSolarLeaseNoteCard,
              us_setShowSolarLeaseNoteCard,
              us_systemSizeInWatts,
              us_setSystemSizeInWatts,
              us_cashSavings,
              us_setCashSavings,
              us_numPanels,
              us_setNumPanels,
              us_baseCashCost,
              us_setBaseCashCost,
              us_currentCashCost,
              us_setCurrentCashCost,
              us_leaseCost,
              us_setLeaseCost,
              us_systemAnnualProductionInKWh,
              us_setSystemAnnualProductionInKwh,
              us_leaseMonthlySavings,
              us_setLeaseMonthlySavings,
              us_leaseCumulativeSavings,
              us_setLeaseCumulativeSavings,
              us_electricityBill,
              us_setElectricityBill,
              us_utilityCompanyName,
              us_setUtilityCompanyName,
              us_federalIncentive,
              us_setFederalIncentive,
              us_srecIncentive,
              us_setSrecIncentive,
              us_showWelcomeBanner,
              us_setShowWelcomeBanner,
              us_selectedTab,
              us_setSelectedTab,
              us_section1Ref,
              us_section2Ref,
              us_section3Ref,
              us_section4Ref,
              us_isDatabaseMode,
              us_setIsDatabaseMode,
              us_depositRequired,
              us_setDepositRequired,
              us_setDepositText,
              us_depositText,
              us_emailError,
              us_setEmailError,
              us_phoneError,
              us_setPhoneError,
              us_latitude,
              us_setLatitude,
              us_longitude,
              us_setLongitude,
              us_address,
              us_setAddress,
              us_city,
              us_setCity,
              us_state,
              us_setState,
              us_zip,
              us_setZip,
            },
            { teal, white, aqua, darkGray, lightGray, lightGray2, green, lightGreen, yellow },
            opportunityKey,
            clientKey,
          )}
        {us_pastEntryPage &&
          us_currentView === 'email' &&
          rJSX_EmailPage(
            {
              us_leadData,
              us_setLeadData,
              us_setCurrentView,
              us_showWelcomeBanner,
              us_setShowWelcomeBanner,
              un_routerNavigation,
            },
            { teal, white, aqua },
          )}
      </>
    )

    return jsx
  }

  const LoadingScreen = (): JSX.Element => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: white,
          gap: 4,
        }}
      >
        <img
          src={logoSrc}
          alt="ETW Energy Logo"
          style={{
            maxWidth: '200px',
            marginBottom: '20px',
          }}
        />
        <CircularProgress
          size={50}
          sx={{
            color: teal,
          }}
        />
      </Box>
    )
  }

  const rJSX_Page = (isAuthenticatedUser: boolean): JSX.Element => {
    let pageJSX = (
      <Box>
        <ThemeProvider theme={lightTheme}>
          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
          {!us_leadData && us_isDatabaseMode ? <LoadingScreen /> : rJSX_PageContent(isAuthenticatedUser)}
        </ThemeProvider>
      </Box>
    )
    return pageJSX
  }

  return <>{rJSX_Page(isAuthenticatedUser)}</>
}
