import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

// SMS Message Templates Collection
export const DatabaseRef_OutboundCommunication_SMSMessageTemplates = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'outbound_communication', 'sms_message_templates')
}

// Individual SMS Message Template Document
export const DatabaseRef_OutboundCommunication_SMSMessageTemplate_Document = (clientKey: string, templateKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'outbound_communication', 'sms_message_templates', templateKey)
}

// Email Message Templates Collection
export const DatabaseRef_OutboundCommunication_EmailMessageTemplates = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'outbound_communication', 'email_message_templates')
}

// Individual Email Message Template Document
export const DatabaseRef_OutboundCommunication_EmailMessageTemplate_Document = (clientKey: string, templateKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'outbound_communication', 'email_message_templates', templateKey)
}
