import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { DatabaseRef_SalesOpportunity_v2_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import { DatabaseRef_Proposal_Document } from 'rfbp_aux/services/database_endpoints/sales/proposals'
import { DatabaseUpdateDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { TsInterface_GenericPromiseReject, TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { utilityCompanyMap } from '../data/sales_opportunity_v3_data'
import { TsInterface_LeadData } from '../interfaces/i_sales_tools'

const PANEL_WATT_CAPACITY = 405 // note that this matches the value used to query eagleview on the server side
const KWH_RATE = 0.09
const FEDERAL_INCENTIVE_RATE = 0.3
const DEFAULT_EPC = 2.5

////////////
// EagleView Data
////////////
export const getEagleViewSliderData = (systemSizes: TsInterface_UnspecifiedObject) => {
  let systemSizesArray = Object.keys(systemSizes)
    .map((systemSize) => parseFloat(systemSize))
    .sort((a, b) => a - b) // sorts the array in ascending order

  let annualProductionArray = Object.values(systemSizes)
    .map((systemSize) => systemSize.annual_production)
    .sort((a, b) => a - b) // sorts the array in ascending order

  let currentSystemSize = 0
  let currentSystemProduction = 0

  let regressionSliderData: TsInterface_UnspecifiedObject[] = []

  // use outer for-loop to calculate the needed production increment estimate per panel for the gap in production data
  for (let i = 1; i < annualProductionArray.length; i++) {
    const sizeDiff = systemSizesArray[i] - systemSizesArray[i - 1]
    const productionDiff = annualProductionArray[i] - annualProductionArray[i - 1]
    const intermediateNumPanels = Math.round((sizeDiff * 1000) / PANEL_WATT_CAPACITY)
    const productionIncrement = productionDiff / intermediateNumPanels

    // use inner for-loop to populate the data array that will be used for the slider
    for (let j = 0; j < intermediateNumPanels; j++) {
      regressionSliderData.push({
        size: currentSystemSize,
        production: currentSystemProduction,
      })
      currentSystemProduction = currentSystemProduction + productionIncrement
      currentSystemSize = currentSystemSize + 405
    }
  }

  // add the max values to the end of the array
  regressionSliderData.push({
    size: systemSizesArray[systemSizesArray.length - 1] * 1000,
    production: annualProductionArray[annualProductionArray.length - 1],
  })

  return regressionSliderData
}

export const getEagleViewSystemSizesAndProductionRatios = (systemSliderData: any) => {
  let possibleSystems: TsInterface_UnspecifiedObject = {}

  for (const sliderData of systemSliderData) {
    const systemSize = sliderData.data.dataOutput.roof['Max kW system']
    const pr = sliderData.production_ratio as number

    if (!possibleSystems[systemSize]) {
      possibleSystems[systemSize] = { production_ratio: sliderData.production_ratio, annual_production: sliderData.data.dataOutput.roof['Max kWh/yr system'] }
    } else {
      if (possibleSystems[systemSize] > pr) {
        possibleSystems[systemSize] = {
          production_ratio: sliderData.production_ratio,
          annual_production: sliderData.data.dataOutput.roof['Max kWh/yr system'],
        }
      }
    }
  }

  return possibleSystems
}

export const getEagleViewProposeSliderData = (proposeEnergyTable: TsInterface_UnspecifiedObject[]) => {
  let regressionSliderData: TsInterface_UnspecifiedObject[] = []
  let currentProduction = 0
  let currentSize = 0

  for (const panel of proposeEnergyTable) {
    currentProduction += panel['kWh/yr']
    currentSize += panel['kW size'] * 1000

    regressionSliderData.push({
      size: currentSize,
      production: currentProduction,
    })
  }

  return regressionSliderData
}

////////////
// Google Data
////////////
export const getGoogleRegressionSliderData = (googleBuildingInsights: TsInterface_UnspecifiedObject) => {
  const minPanelCount = googleBuildingInsights.solarPotential.solarPanelConfigs[0]['panelsCount']
  // const maxPanelCount = googleBuildingInsights.solarPotential.maxArrayPanelsCount
  const solarPanelConfigs = googleBuildingInsights['solarPotential']['solarPanelConfigs']
  const defaultPanelCapacityWatts = googleBuildingInsights['solarPotential']['panelCapacityWatts']
  const panelCapacityRatio = PANEL_WATT_CAPACITY / defaultPanelCapacityWatts
  // const maxAnnualEnergyOutput = Math.round(solarPanelConfigs[solarPanelConfigs.length - 1]['yearlyEnergyDcKwh'] * panelCapacityRatio)

  let regressionSliderData: TsInterface_UnspecifiedObject[] = []

  // google starts at 4 panels, so regressively determine the first 3 panel production values
  for (let i = 0; i < minPanelCount; i++) {
    const productionIncrement = (solarPanelConfigs[0]['yearlyEnergyDcKwh'] * panelCapacityRatio) / minPanelCount

    regressionSliderData.push({
      size: i * PANEL_WATT_CAPACITY,
      production: productionIncrement * i,
    })
  }

  // start at 4 panels using the google solar panel config data, filling in regression gaps where necessary
  for (let i = 0; i < solarPanelConfigs.length; i++) {
    if (i !== solarPanelConfigs.length - 1 && solarPanelConfigs[i + 1]['panelsCount'] - solarPanelConfigs[i]['panelsCount'] > 1) {
      regressionSliderData = regressionSliderData.concat(
        fillGoogleRegressionGaps(
          solarPanelConfigs[i]['panelsCount'],
          solarPanelConfigs[i + 1]['panelsCount'],
          Math.round(solarPanelConfigs[i]['yearlyEnergyDcKwh'] * panelCapacityRatio),
          Math.round(solarPanelConfigs[i + 1]['yearlyEnergyDcKwh'] * panelCapacityRatio),
        ),
      )
    } else {
      regressionSliderData.push({
        size: solarPanelConfigs[i]['panelsCount'] * PANEL_WATT_CAPACITY,
        production: Math.round(solarPanelConfigs[i]['yearlyEnergyDcKwh'] * panelCapacityRatio),
      })
    }
  }

  return regressionSliderData
}

export const fillGoogleRegressionGaps = (currentNumPanels: number, nextNumPanels: number, currentProduction: number, nextProduction: number) => {
  let regressionSliderData: TsInterface_UnspecifiedObject = []

  const sizeDiff = nextNumPanels - currentNumPanels
  const productionDiff = nextProduction - currentProduction
  const productionIncrement = productionDiff / sizeDiff

  for (let i = 0; i < sizeDiff; i++) {
    regressionSliderData.push({
      size: (currentNumPanels + i) * PANEL_WATT_CAPACITY,
      production: currentProduction + productionIncrement * i,
    })
  }

  return regressionSliderData
}

// Analytics
export const saveSessionData = (
  clientKey: string,
  opportunityKey: string,
  analyticsSessionKey: string,
  sessionData: TsInterface_UnspecifiedObject,
  saveAnalyticsSessionData: boolean,
) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && opportunityKey != null && analyticsSessionKey != null && saveAnalyticsSessionData === true && opportunityKey != '') {
      cloudFunctionUnauthenticatedRequests({
        function: 'saveSalesOpportunitySessionData_v2',
        client_key: clientKey,
        opportunity_key: opportunityKey,
        session_key: analyticsSessionKey,
        session_data: sessionData,
      })
        .then((res_CFUR) => {
          resolve(res_CFUR)
        })
        .catch((rej_CFUR) => {
          reject(rej_CFUR)
        })
    } else {
      resolve({ success: false })
    }
  })
}

// updates the opportunity in the database
export const updateOpportunity = (
  stateGettersSetters: any,
  clientKey: string,
  opportunityKey: string,
  updatedFields: TsInterface_UnspecifiedObject,
  triggerPageRefresh: boolean = true,
) => {
  return new Promise((resolve, reject) => {
    if (stateGettersSetters.us_leadData) {
      let updatedOpportunity = {}

      for (const entry of Object.entries(updatedFields)) {
        updatedOpportunity = { ...updatedOpportunity, [entry[0]]: entry[1] }
      }
      if (stateGettersSetters.us_isDatabaseMode) {
        cloudFunctionUnauthenticatedRequests({
          function: 'updateOpportunity_v2',
          client_key: clientKey,
          opportunity_key: opportunityKey,
          opportunity_data: { ...updatedOpportunity },
          trigger_page_refresh: triggerPageRefresh,
        })
          .then((res_CFUR) => {
            resolve(res_CFUR)
          })
          .catch((rej_CFUR) => {
            reject(rej_CFUR)
          })
      } else {
        resolve({ success: false })
      }
    } else {
      resolve({ success: false })
    }
  })
}

export const submitOffsetChange = (stateGettersSetters: any, offset: number, triggerRefresh: boolean = false, opportunityKey: string, clientKey: string) => {
  // calculate and set the pricing state variables
  const [baseCashCost, currentCashCost, leaseCost, hypotheticalSize, hypotheticalProduction, federalIncentive, srecIncentive] = recalculateCostsOnOffsetChange(
    offset,
    stateGettersSetters,
  )

  // calculate and set the savings state variables

  // calculate and set the savings state variables
  const [cashSavings, leaseMonthlySavings, leaseCumulativeSavings] = calculateSavings(
    stateGettersSetters.us_electricityBill,
    currentCashCost,
    leaseCost,
    hypotheticalProduction,
  )
  // updateOpportunity(
  //   {
  //     lease_cost: leaseCost,
  //     base_cash_cost: baseCashCost,
  //     current_cash_cost: currentCashCost,
  //     num_panels: hypotheticalSize / PANEL_WATT_CAPACITY,
  //     selected_system_size: hypotheticalSize,
  //     selected_annual_production: hypotheticalProduction,
  //     cash_savings: cashSavings,
  //     lease_monthly_savings: leaseMonthlySavings,
  //     lease_cumulative_savings: leaseCumulativeSavings,
  //     contact_monthly_electric_bill: us_electricityBill,
  //     offset: offset,
  //     confirmed: true,
  //   },
  //   triggerRefresh,
  // )

  stateGettersSetters.us_setNumPanels(Math.round(hypotheticalSize / PANEL_WATT_CAPACITY))
  stateGettersSetters.us_setBaseCashCost(parseFloat(baseCashCost.toFixed(2)))
  stateGettersSetters.us_setCurrentCashCost(parseFloat(currentCashCost.toFixed(2)))
  stateGettersSetters.us_setLeaseCost(parseFloat(leaseCost.toFixed(2)))
  stateGettersSetters.us_setSystemSizeInWatts(hypotheticalSize / 1000)
  stateGettersSetters.us_setSystemAnnualProductionInKwh(hypotheticalProduction / 1000)
  stateGettersSetters.us_setCashSavings(parseFloat(cashSavings.toFixed(2)))
  stateGettersSetters.us_setLeaseMonthlySavings(parseFloat(leaseMonthlySavings.toFixed(2)))
  stateGettersSetters.us_setLeaseCumulativeSavings(parseFloat(leaseCumulativeSavings.toFixed(2)))
  stateGettersSetters.us_setOffset(Math.round(offset))
  stateGettersSetters.us_setFederalIncentive(federalIncentive)
  stateGettersSetters.us_setSrecIncentive(srecIncentive)

  const dataToSave = {
    panel_quantity: Math.round(hypotheticalSize / PANEL_WATT_CAPACITY),
    financials_cash_base_price: parseFloat(baseCashCost.toFixed(2)),
    financials_cash_price_after_incentives: parseFloat(currentCashCost.toFixed(2)),
    financials_lease_monthly_payment: parseFloat(leaseCost.toFixed(2)),
    selected_system_size: parseFloat((hypotheticalSize / 1000).toFixed(2)), //still need to be update for v2 database
    system_estimated_annual_production: parseFloat((hypotheticalProduction / 1000).toFixed(2)), //still need to be update for v2 database
    cash_savings: parseFloat(cashSavings.toFixed(2)), //still need to be update for v2 database
    financials_lease_monthly_savings: parseFloat(leaseMonthlySavings.toFixed(2)), //still need to be update for v2 database
    financials_lease_year_one_savings: parseFloat(leaseCumulativeSavings.toFixed(2)),
    system_usage_offset: Math.round(offset),
    system_monthly_electricity_bill: stateGettersSetters.us_electricityBill,
    financials_federal_incentive: federalIncentive,
    financials_srec_incentive: srecIncentive,
  }

  if (stateGettersSetters.us_isDatabaseMode) {
    cloudFunctionUnauthenticatedRequests({
      function: 'updateOpportunity_v2',
      client_key: clientKey,
      opportunity_key: opportunityKey,
      opportunity_data: dataToSave,
      trigger_page_refresh: false,
    })
  } else {
    setPageLocalStorage('go_solar', 'solarSystemData', dataToSave)
  }
}

export const submitSystemChange = (
  stateGettersSetters: any,
  changedValue: string,
  newValue: number,
  opportunityKey: string,
  clientKey: string,
  page?: string,
  proposalKeysRef?: any,
  proposalSelected?: number,
) => {
  let hypotheticalSize: number
  let hypotheticalProduction: number
  let numPanels: number
  let offset: number
  let proposalKey: string

  // Calculate the changed values based on the input
  switch (changedValue) {
    case 'systemSize':
      hypotheticalSize = parseFloat(newValue.toFixed(2))
      numPanels = Math.round(hypotheticalSize / PANEL_WATT_CAPACITY)
      hypotheticalProduction = parseFloat(calculateProduction(hypotheticalSize).toFixed(3))
      offset = calculateOffset(stateGettersSetters.us_electricityBill, hypotheticalProduction)
      break
    case 'numPanels':
      numPanels = Math.round(newValue)
      hypotheticalSize = parseFloat((numPanels * PANEL_WATT_CAPACITY).toFixed(2))
      hypotheticalProduction = parseFloat(calculateProduction(hypotheticalSize).toFixed(3))
      offset = calculateOffset(stateGettersSetters.us_electricityBill, hypotheticalProduction)
      break
    case 'annualProduction':
      hypotheticalProduction = parseFloat(newValue.toFixed(3))
      hypotheticalSize = parseFloat(calculateSize(hypotheticalProduction).toFixed(2))
      numPanels = Math.round(hypotheticalSize / PANEL_WATT_CAPACITY)
      offset = calculateOffset(stateGettersSetters.us_electricityBill, hypotheticalProduction)
      break
    case 'offset':
      offset = newValue
      hypotheticalProduction = parseFloat(calculateProductionFromOffset(offset, stateGettersSetters.us_electricityBill).toFixed(3))
      hypotheticalSize = parseFloat(calculateSize(hypotheticalProduction).toFixed(2))
      numPanels = Math.round(hypotheticalSize / PANEL_WATT_CAPACITY)
      break
    default:
      throw new Error('Invalid changed value')
  }

  // Calculate costs and savings
  const [baseCashCost, currentCashCost, leaseCost, federalIncentive, srecIncentive] = recalculateCosts(
    hypotheticalSize,
    hypotheticalProduction,
    stateGettersSetters,
  )

  const [cashSavings, leaseMonthlySavings, leaseCumulativeSavings] = calculateSavings(
    stateGettersSetters.us_electricityBill,
    currentCashCost,
    leaseCost,
    hypotheticalProduction,
  )

  if (proposalSelected) {
    if (proposalSelected === 3) {
      stateGettersSetters.us_setTertiaryNumPanels(Math.round(hypotheticalSize / PANEL_WATT_CAPACITY))
      stateGettersSetters.us_setTertiaryBaseCashCost(parseFloat(baseCashCost.toFixed(2)))
      stateGettersSetters.us_setTertiaryCurrentCashCost(parseFloat(currentCashCost.toFixed(2)))
      stateGettersSetters.us_setTertiaryLeaseCost(parseFloat(leaseCost.toFixed(2)))
      stateGettersSetters.us_setTertiarySystemSizeInWatts(hypotheticalSize / 1000)
      stateGettersSetters.us_setTertiarySystemAnnualProductionInKwh(hypotheticalProduction / 1000)
      stateGettersSetters.us_setTertiaryCashSavings(parseFloat(cashSavings.toFixed(2)))
      stateGettersSetters.us_setTertiaryLeaseMonthlySavings(parseFloat(leaseMonthlySavings.toFixed(2)))
      stateGettersSetters.us_setTertiaryLeaseCumulativeSavings(parseFloat(leaseCumulativeSavings.toFixed(2)))
      stateGettersSetters.us_setTertiaryOffset(Math.round(offset))
      stateGettersSetters.us_setTertiaryFederalIncentive(federalIncentive)
      stateGettersSetters.us_setTertiarySrecIncentive(srecIncentive)
      proposalKey = proposalKeysRef.current.tertiary
    } else if (proposalSelected === 2) {
      stateGettersSetters.us_setSecondaryNumPanels(Math.round(hypotheticalSize / PANEL_WATT_CAPACITY))
      stateGettersSetters.us_setSecondaryBaseCashCost(parseFloat(baseCashCost.toFixed(2)))
      stateGettersSetters.us_setSecondaryCurrentCashCost(parseFloat(currentCashCost.toFixed(2)))
      stateGettersSetters.us_setSecondaryLeaseCost(parseFloat(leaseCost.toFixed(2)))
      stateGettersSetters.us_setSecondarySystemSizeInWatts(hypotheticalSize / 1000)
      stateGettersSetters.us_setSecondarySystemAnnualProductionInKwh(hypotheticalProduction / 1000)
      stateGettersSetters.us_setSecondaryCashSavings(parseFloat(cashSavings.toFixed(2)))
      stateGettersSetters.us_setSecondaryLeaseMonthlySavings(parseFloat(leaseMonthlySavings.toFixed(2)))
      stateGettersSetters.us_setSecondaryLeaseCumulativeSavings(parseFloat(leaseCumulativeSavings.toFixed(2)))
      stateGettersSetters.us_setSecondaryOffset(Math.round(offset))
      stateGettersSetters.us_setSecondaryFederalIncentive(federalIncentive)
      stateGettersSetters.us_setSecondarySrecIncentive(srecIncentive)
      proposalKey = proposalKeysRef.current.secondary
    } else {
      // Update state
      stateGettersSetters.us_setNumPanels(Math.round(hypotheticalSize / PANEL_WATT_CAPACITY))
      stateGettersSetters.us_setBaseCashCost(parseFloat(baseCashCost.toFixed(2)))
      stateGettersSetters.us_setCurrentCashCost(parseFloat(currentCashCost.toFixed(2)))
      stateGettersSetters.us_setLeaseCost(parseFloat(leaseCost.toFixed(2)))
      stateGettersSetters.us_setSystemSizeInWatts(hypotheticalSize / 1000)
      stateGettersSetters.us_setSystemAnnualProductionInKwh(hypotheticalProduction / 1000)
      stateGettersSetters.us_setCashSavings(parseFloat(cashSavings.toFixed(2)))
      stateGettersSetters.us_setLeaseMonthlySavings(parseFloat(leaseMonthlySavings.toFixed(2)))
      stateGettersSetters.us_setLeaseCumulativeSavings(parseFloat(leaseCumulativeSavings.toFixed(2)))
      stateGettersSetters.us_setOffset(Math.round(offset))
      stateGettersSetters.us_setFederalIncentive(federalIncentive)
      stateGettersSetters.us_setSrecIncentive(srecIncentive)
      proposalKey = proposalKeysRef.current.primary
    }
  } else {
    stateGettersSetters.us_setNumPanels(Math.round(hypotheticalSize / PANEL_WATT_CAPACITY))
    stateGettersSetters.us_setBaseCashCost(parseFloat(baseCashCost.toFixed(2)))
    stateGettersSetters.us_setCurrentCashCost(parseFloat(currentCashCost.toFixed(2)))
    stateGettersSetters.us_setLeaseCost(parseFloat(leaseCost.toFixed(2)))
    stateGettersSetters.us_setSystemSizeInWatts(hypotheticalSize / 1000)
    stateGettersSetters.us_setSystemAnnualProductionInKwh(hypotheticalProduction / 1000)
    stateGettersSetters.us_setCashSavings(parseFloat(cashSavings.toFixed(2)))
    stateGettersSetters.us_setLeaseMonthlySavings(parseFloat(leaseMonthlySavings.toFixed(2)))
    stateGettersSetters.us_setLeaseCumulativeSavings(parseFloat(leaseCumulativeSavings.toFixed(2)))
    stateGettersSetters.us_setOffset(Math.round(offset))
    stateGettersSetters.us_setFederalIncentive(federalIncentive)
    stateGettersSetters.us_setSrecIncentive(srecIncentive)
    proposalKey = proposalKeysRef.current.primary
  }

  // Save to local storage
  const dataToSave = {
    panel_quantity: Math.round(hypotheticalSize / PANEL_WATT_CAPACITY),
    financials_cash_base_price: parseFloat(baseCashCost.toFixed(2)),
    financials_cash_price_after_incentives: parseFloat(currentCashCost.toFixed(2)),
    financials_lease_monthly_payment: parseFloat(leaseCost.toFixed(2)),
    selected_system_size: parseFloat((hypotheticalSize / 1000).toFixed(2)), //still need to be update for v2 database
    system_estimated_annual_production: parseFloat((hypotheticalProduction / 1000).toFixed(2)), //still need to be update for v2 database
    cash_savings: parseFloat(cashSavings.toFixed(2)), //still need to be update for v2 database
    financials_lease_monthly_savings: parseFloat(leaseMonthlySavings.toFixed(2)), //still need to be update for v2 database
    financials_lease_year_one_savings: parseFloat(leaseCumulativeSavings.toFixed(2)), //still need to be update for v2 database
    system_usage_offset: Math.round(offset),
    system_monthly_electricity_bill: stateGettersSetters.us_electricityBill,
    financials_federal_incentive: federalIncentive,
    financials_srec_incentive: srecIncentive,
  }

  if (stateGettersSetters.us_isDatabaseMode) {
    cloudFunctionUnauthenticatedRequests({
      function: 'updateOpportunity_v2',
      client_key: clientKey,
      opportunity_key: opportunityKey,
      opportunity_data: dataToSave,
      trigger_page_refresh: false,
    })
  } else if (page === 'welcome') {
    DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, proposalKey), {
      panel_quantity: Math.round(hypotheticalSize / PANEL_WATT_CAPACITY),
      financials_cash_base_price: parseFloat(baseCashCost.toFixed(2)),
      financials_cash_price_after_incentives: parseFloat(currentCashCost.toFixed(2)),
      financials_lease_monthly_payment: parseFloat(leaseCost.toFixed(2)),
      selected_system_size: parseFloat((hypotheticalSize / 1000).toFixed(2)), //still need to be update for v2 database
      system_estimated_annual_production: parseFloat((hypotheticalProduction / 1000).toFixed(2)), //still need to be update for v2 database
      cash_savings: parseFloat(cashSavings.toFixed(2)), //still need to be update for v2 database
      financials_lease_monthly_savings: parseFloat(leaseMonthlySavings.toFixed(2)), //still need to be update for v2 database
      financials_lease_year_one_savings: parseFloat(leaseCumulativeSavings.toFixed(2)), //still need to be update for v2 database
      system_usage_offset: Math.round(offset),
      system_monthly_electricity_bill: stateGettersSetters.us_electricityBill,
      financials_federal_incentive: federalIncentive,
      financials_srec_incentive: srecIncentive,
    })
  } else {
    setPageLocalStorage('go_solar', 'solarSystemData', dataToSave)
  }
}

// Helper functions
const calculateProduction = (size: number): number => {
  // Implement your production calculation logic here
  // This is a placeholder calculation
  return size * 1.1 // Assuming 1.1 kWh per watt per year
}

const calculateSize = (production: number): number => {
  // Implement your size calculation logic here
  // This is a placeholder calculation
  return production / 1.1 // Inverse of the production calculation
}

const calculateProductionFromOffset = (offset: number, electricityBill: number): number => {
  const annualBill = electricityBill * 12
  const estimatedProduction = annualBill / 0.15
  return (offset / 100) * estimatedProduction
}

const recalculateCosts = (size: number, production: number, stateGettersSetters: any): [number, number, number, number, number] => {
  const baseCashCost = DEFAULT_EPC * (size / PANEL_WATT_CAPACITY) * PANEL_WATT_CAPACITY
  let [currentCashCost, federalIncentive, srecIncentive] = applyIncentives(baseCashCost, production, size, stateGettersSetters)
  const leaseCost = calculateLeaseCost(production)
  return [baseCashCost, currentCashCost, leaseCost, federalIncentive, srecIncentive]
}

const recalculateCostsOnOffsetChange = (offset: number, stateGettersSetters: any) => {
  let baseCashCost = 0
  let leaseCost = 0

  const hypotheticalProductionRatio = 1.1
  const productionBasedOnBill = ((offset / 100) * stateGettersSetters.us_electricityBill * 12) / 0.15
  // TODO: use below code to use the google regression data
  // const numPanelsBasedOnBill = getNumPanelsFromProduction(productionBasedOnBill)
  // const sizeBasedOnBill = numPanelsBasedOnBill * PANEL_WATT_CAPACITY
  // const preciseProduction = getProductionFromNumPanels(numPanelsBasedOnBill)

  // TODO: use below code to use the hypothetical production ratio
  const sizeBasedOnBill = productionBasedOnBill / hypotheticalProductionRatio
  const preciseProduction = hypotheticalProductionRatio * sizeBasedOnBill
  // TODO: eventually we will need to incorporate a dynamic EPC when we introduce adders

  // TODO: eventually we will need to incorporate a dynamic EPC when we introduce adders
  baseCashCost = DEFAULT_EPC * (sizeBasedOnBill / 405) * PANEL_WATT_CAPACITY
  let [currentCashCost, federalIncentive, srecIncentive] = applyIncentives(baseCashCost, preciseProduction, sizeBasedOnBill, stateGettersSetters)
  leaseCost = calculateLeaseCost(preciseProduction)

  return [baseCashCost, currentCashCost, leaseCost, sizeBasedOnBill, preciseProduction, federalIncentive, srecIncentive]
}

const calculateSavings = (electricityBill: number, currentCashCost: number, leaseCost: number, productionInWatts: number): number[] => {
  if (currentCashCost === 0 || leaseCost === 0) {
    return [0, 0, 0]
  }
  const offset = calculateOffset(electricityBill, productionInWatts) / 100
  let cashSavings
  let leaseMonthlySavings
  let leaseCumulativeSavings

  // here we multiply the electricity bill by the offset to account for the rest of the bill that is not covered by the offset if not fully covered
  if (offset < 1) {
    cashSavings = offset * electricityBill * 12 * 25 - currentCashCost // 12 mo * 25 years
    leaseMonthlySavings = offset * electricityBill - leaseCost
    leaseCumulativeSavings = offset * electricityBill * 12 * 25 - leaseCost * 12 * 25 // 12 mo * 25 years
  } else {
    cashSavings = electricityBill * 12 * 25 - currentCashCost // 12 mo * 25 years
    leaseMonthlySavings = electricityBill - leaseCost
    leaseCumulativeSavings = electricityBill * 12 * 25 - leaseCost * 12 * 25 // 12 mo * 25 years
  }

  return [cashSavings, leaseMonthlySavings, leaseCumulativeSavings]
}

const calculateOffset = (monthlyBill: string | number, selectedSystemProduction: number) => {
  let monthlyBillTemp

  if (typeof monthlyBill === 'string') {
    // Remove the dollar sign and parse the number
    monthlyBillTemp = parseInt(monthlyBill.replace(/^\$/, ''))
  } else {
    monthlyBillTemp = monthlyBill
  }

  const annualBill = monthlyBillTemp * 12
  const estimatedProduction = annualBill / 0.15

  return (selectedSystemProduction / estimatedProduction) * 100
}

const applyIncentives = (
  cost: number,
  production: number,
  size: number,
  stateGettersSetters: any,
  federalRate: number = FEDERAL_INCENTIVE_RATE,
  leadData: TsInterface_LeadData | null = null,
  utilityCompanyName: string = '',
): [number, number, number] => {
  const degradation = 0.005
  const fifteenYearProductionInMWh = calculateFifteenYearProductionRounded(production, degradation)
  const federalIncentive = cost * federalRate
  const srecIncentive = calculateSRECRate(size, leadData, utilityCompanyName, stateGettersSetters) * fifteenYearProductionInMWh * 0.8
  const costAfterSRECIncentive = cost - federalIncentive - srecIncentive

  return [costAfterSRECIncentive, federalIncentive, srecIncentive]
}

const calculateLeaseCost = (production: number) => {
  return (production / 12) * KWH_RATE
}

const calculateFifteenYearProductionRounded = (production: number, degradation: number): number => {
  const result = (production * ((1 - Math.pow(1 - degradation, 15)) / 0.005)) / 1000
  return Math.floor(result)
}

const getSRECSizeBracket = (size: number) => {
  if (size <= 10000) {
    return '<=10'
  } else if (size <= 25000) {
    return '10-25'
  } else {
    return '>25'
  }
}

// these values correspond directly to the Zero-Cost Solar lookup values in the google sheet
const getSRECRate = (srecGroup: string, srecSizeBracket: string) => {
  if (srecGroup === 'A') {
    if (srecSizeBracket === '<=10') {
      return 75.21
    } else if (srecSizeBracket === '10-25') {
      return 64.71
    } else {
      return 59.28
    }
  } else if (srecGroup === 'B') {
    if (srecSizeBracket === '<=10') {
      return 83.75
    } else if (srecSizeBracket === '10-25') {
      return 76.27
    } else {
      return 69.47
    }
  } else {
    console.error('Invalid SREC group or size bracket')
    return 0
  }
}

const calculateSRECRate = (size: number, leadData: TsInterface_LeadData | null = null, utilityCompanyName: string = '', stateGettersSetters: any) => {
  const srecGroup = getSRECGroup(stateGettersSetters, utilityCompanyName)
  const srecSizeBracket = getSRECSizeBracket(size)
  const srecRate = getSRECRate(srecGroup, srecSizeBracket)
  return srecRate
}

const getSRECGroup = (stateGettersSetters: any, companyName: string = '') => {
  if (stateGettersSetters.us_isDatabaseMode) {
    const state =
      stateGettersSetters.us_leadData && stateGettersSetters.us_leadData.opportunityData
        ? stateGettersSetters.us_leadData.opportunityData.location_state
        : stateGettersSetters.us_leadData?.opportunityData.location_state
    const utilityCompanyName = companyName === '' ? stateGettersSetters.us_utilityCompanyName : companyName

    if (state && utilityCompanyName) {
      // const state = us_leadData.opportunityData.state
      const relevantUtilityCompanies = getProp(utilityCompanyMap, state, null)

      if (relevantUtilityCompanies && relevantUtilityCompanies[utilityCompanyName as string]) {
        return relevantUtilityCompanies[utilityCompanyName as string]
      } else {
        // uc_setUserInterface_ErrorDialogDisplay({ display: true, error: { message: 'Utility company not found.', code: '', details: '' } })
        console.error('Utility company not found.')
      }
    } else {
      //   uc_setUserInterface_ErrorDialogDisplay({ display: true, error: { message: 'No lead data available.', code: '', details: '' } })
      // }
      console.error('No lead data available.')
    }
  } else {
    const state = stateGettersSetters.us_state
    const utilityCompanyName = stateGettersSetters.us_utilityCompanyName
    const relevantUtilityCompanies = getProp(utilityCompanyMap, state, null)
    if (relevantUtilityCompanies && relevantUtilityCompanies[utilityCompanyName as string]) {
      return relevantUtilityCompanies[utilityCompanyName as string]
    } else {
      console.error('Utility company not found.')
    }
  }
}

export const completeOpportunitySubmission = (stateGettersSetters: any, opportunityKey: string, clientKey: string) => {
  let pageLocalStorageData = null
  if (!stateGettersSetters.us_isDatabaseMode) {
    pageLocalStorageData = getPageLocalStorage('go_solar')

    try {
      cloudFunctionUnauthenticatedRequests({
        function: 'completeOpportunitySubmission',
        client_key: clientKey,
        opportunity_key: opportunityKey,
        user_data: {
          name: stateGettersSetters.us_firstName + ' ' + stateGettersSetters.us_lastName,
          email: stateGettersSetters.us_email,
          phone: stateGettersSetters.us_phone,
          page_local_storage_data: pageLocalStorageData,
          location_latitude: stateGettersSetters.us_latitude,
          location_longitude: stateGettersSetters.us_longitude,
          location_address: stateGettersSetters.us_address,
          location_city: stateGettersSetters.us_city,
          location_state: stateGettersSetters.us_state,
          location_zip: stateGettersSetters.us_zip,
        },
        trigger_page_refresh: false,
      })
        .then((res_CFMUR: any) => {})
        .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
          console.error('Error instantiating user:', rej_CFMUR)
        })
    } catch (error) {
      console.error('Error completing opportunity submission:', error)
    }
  } else {
    cloudFunctionUnauthenticatedRequests({
      function: 'completeOpportunitySubmission',
      client_key: clientKey,
      opportunity_key: opportunityKey,
      user_data: {
        name: stateGettersSetters.us_firstName + ' ' + stateGettersSetters.us_lastName,
        email: stateGettersSetters.us_email,
        phone: stateGettersSetters.us_phone,
        location_latitude: stateGettersSetters.us_latitude,
        location_longitude: stateGettersSetters.us_longitude,
        location_address: stateGettersSetters.us_address,
        location_city: stateGettersSetters.us_city,
        location_state: stateGettersSetters.us_state,
        location_zip: stateGettersSetters.us_zip,
        page_local_storage_data: pageLocalStorageData,
        associated_financing_type: stateGettersSetters.us_selectedFinancingType,
        system_storage_quantity: stateGettersSetters.us_numBatteries,
        system_mount_type: stateGettersSetters.us_selectedMountType,
        system_estimated_annual_production: stateGettersSetters.us_systemAnnualProductionInKWh,
        selected_system_size: stateGettersSetters.us_systemSizeInWatts,
        panel_quantity: stateGettersSetters.us_numPanels,
        cash_savings: stateGettersSetters.us_cashSavings,
        financials_cash_base_price: stateGettersSetters.us_baseCashCost,
        financials_cash_price_after_incentives: stateGettersSetters.us_currentCashCost,
        financials_lease_monthly_payment: stateGettersSetters.us_leaseCost,
        financials_lease_year_one_savings: stateGettersSetters.us_leaseCumulativeSavings,
        financials_lease_monthly_savings: stateGettersSetters.us_leaseMonthlySavings,
        financials_federal_incentive: stateGettersSetters.us_federalIncentive,
        financials_srec_incentive: stateGettersSetters.us_srecIncentive,
        system_usage_offset: stateGettersSetters.us_offset,
      },
    })
  }
}

// export const convertEvEnergyTableDataToSolarPanels = (evEnergyTableData: any) => {
//   const panels: { corners: { lat: number; lng: number }[] }[] = []

//   // Convert to array if it's not already one
//   const dataArray = Array.isArray(evEnergyTableData) ? evEnergyTableData : Object.values(evEnergyTableData || {})

//   if (!dataArray.length) {
//     console.warn('No energy table data found')
//     return panels
//   }

//   for (const row of dataArray) {
//     if (!row?.coordinates) {
//       console.warn('Invalid row data:', row)
//       continue
//     }

//     // Convert coordinates to array if it's not already one
//     const coordinates = Array.isArray(row.coordinates) ? row.coordinates : Object.values(row.coordinates || {})
//     const corners = []
//     for (const coordinate of coordinates) {
//       if (Array.isArray(coordinate) && coordinate.length >= 2) {
//         corners.push({ lat: coordinate[0], lng: coordinate[1] })
//       }
//     }
//     panels.push({ corners })
//   }
//   return panels
// }

export const convertEvEnergyTableDataToSolarPanels = (evEnergyTableData: any) => {
  const panels: { row: any }[] = []

  // Convert to array if it's not already one
  const dataArray = Array.isArray(evEnergyTableData) ? evEnergyTableData : Object.values(evEnergyTableData || {})

  if (!dataArray.length) {
    console.warn('No energy table data found')
    return panels
  }

  for (const row of dataArray) {
    if (!row?.coordinates) {
      console.warn('Invalid row data:', row)
      continue
    }

    panels.push({ row })
  }
  return panels
}

// export const updateSurveyResponse = (clientKey: string, opportunityKey: string, stateGettersSetters: any) => {
//   DatabaseUpdateDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), {
//     person_purchase_timeframe: stateGettersSetters.us_selectedPurchaseTimeline,
//     associated_hoa_phone: stateGettersSetters.us_hoaPhone,
//     associated_hoa_name: stateGettersSetters.us_hoaName,
//     home_house_type: stateGettersSetters.us_selectedHomeType,
//     home_house_age: stateGettersSetters.us_selectedHomeAge,
//     home_roof_age: stateGettersSetters.us_selectedRoofAge,
//     home_outside_wall_access: stateGettersSetters.us_accessToAllExteriorWalls,
//     home_outside_wall_access_description: stateGettersSetters.us_noAccessReason,
//     home_has_attic: stateGettersSetters.us_hasAttic,
//     home_attic_access: stateGettersSetters.us_atticAccess,
//     electric_main_breaker_rating: stateGettersSetters.us_mainBreakerMainPanelRating,
//     electric_main_panel_num_breakers: stateGettersSetters.us_breakerMainPanelCount,
//     electric_main_panel_sum_breakers: stateGettersSetters.us_breakerMainPanelSum,
//     electric_sub_panel_number: stateGettersSetters.us_subPanelCount,
//     electric_sub_panel_locations: stateGettersSetters.us_subPanelLocation,
//     home_existing_solar_system: stateGettersSetters.us_hasSolarSystem,
//     home_battery_storage: stateGettersSetters.us_hasBatteryStorage,
//     home_has_generator: stateGettersSetters.us_hasGenerator,
//     home_ev_charger: stateGettersSetters.us_hasEVCharger,
//     home_has_pool: stateGettersSetters.us_hasPool,
//     home_has_hot_tub: stateGettersSetters.us_hasHotTub,
//     electric_water_heater: stateGettersSetters.us_hasElectricWaterHeater,
//     electric_dryer: stateGettersSetters.us_hasElectricDryer,
//     electric_heating: stateGettersSetters.us_hasElectricHeating,
//     electric_specialized_equipment: stateGettersSetters.us_hasSpecializedElectricalEquipment,
//     home_electric_other: stateGettersSetters.us_hasOtherHighLoadItem,
//     contact_preferred_method: stateGettersSetters.us_preferredContactMethod,
//     contact_preferred_contact_time: stateGettersSetters.us_preferredContactTimes,
//     home_has_pets: stateGettersSetters.us_hasPets,
//     home_gate_code: stateGettersSetters.us_gateCode,
//     system_placement_preferences: stateGettersSetters.us_panelPlacementPreference,
//     system_inverter_preferences: stateGettersSetters.us_inverterPlacementPreference,
//   })
// }

export const updateSurveyResponse = (clientKey: string, opportunityKey: string, databaseKey: string, useState: any) => {
  DatabaseUpdateDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), {
    [databaseKey]: useState,
  })
}
