///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Link, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_JurisdictionFiles_Collection,
  DatabaseRef_JurisdictionFile_Document,
  DatabaseRef_JurisdictionForumMessages_Collection,
  DatabaseRef_JurisdictionForumMessage_Document,
  DatabaseRef_JurisdictionForumThreads_Collection,
  DatabaseRef_JurisdictionForumThread_Document,
  DatabaseRef_Jurisdiction_Document,
} from 'rfbp_aux/services/database_endpoints/directory/jurisdictions'
import { StorageRef_JurisdictionFile, StorageRef_Jurisdiction_MessageThread_File } from 'rfbp_aux/services/storage_endpoints/jurisdictions'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { ForumBasic } from 'rfbp_core/components/forum/forum_basic'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseJurisdictionViewPage']['key']
const se_JURISDICTION: string = 'Jurisdiction'

// Forms
// Contact Information Section
const formInputs_JurisdictionName: TsInterface_FormInputs = {
  jurisdiction_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'jurisdiction_name',
    label: 'Jurisdiction Name',
  },
}

const stateOptions = [
  { key: 'IL', label: 'IL', value: 'IL' },
  { key: 'TX', label: 'TX', value: 'TX' },
]

const formInputs_State: TsInterface_FormInputs = {
  state: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'state',
    label: 'State',
    options: objectToArray(stateOptions),
  },
}

const typeOptions = [
  { key: 'city', label: 'City', value: 'City' },
  { key: 'village', label: 'Village', value: 'Village' },
  { key: 'county', label: 'County', value: 'County' },
  { key: 'town', label: 'Town', value: 'Town' },
  { key: 'township', label: 'Township', value: 'Township' },
]

const formInputs_Type: TsInterface_FormInputs = {
  type: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'type',
    label: 'Type',
    options: objectToArray(typeOptions),
  },
}

const formInputs_ContactName: TsInterface_FormInputs = {
  contact_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'contact_name',
    label: 'Contact Name',
  },
}

const formInputs_Address: TsInterface_FormInputs = {
  address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'address',
    label: 'Address',
  },
}

const formInputs_PhoneNumber: TsInterface_FormInputs = {
  phone_number: {
    data_type: 'string',
    input_type: 'phone_number_usa',
    key: 'phone_number',
    label: 'Phone Number',
  },
}

const formInputs_Email: TsInterface_FormInputs = {
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: 'Email',
  },
}

const formInputs_Website: TsInterface_FormInputs = {
  website: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'website',
    label: 'Website',
  },
}

const formInputs_EaveSetback: TsInterface_FormInputs = {
  eave_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'eave_setback',
    label: 'Eave Setback',
  },
}

const formInputs_ValleySetback: TsInterface_FormInputs = {
  valley_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'valley_setback',
    label: 'Valley Setback',
  },
}

const formInputs_RidgeSetback: TsInterface_FormInputs = {
  ridge_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'ridge_setback',
    label: 'Ridge Setback',
  },
}

const formInputs_RakeSetback: TsInterface_FormInputs = {
  rake_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'rake_setback',
    label: 'Rake Setback',
  },
}

const formInputs_HipSetback: TsInterface_FormInputs = {
  hip_setback: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'hip_setback',
    label: 'Hip Setback',
  },
}

const formInputs_SnowLoad: TsInterface_FormInputs = {
  snow_load: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'snow_load',
    label: 'Snow Load',
  },
}

const formInputs_WindLoad: TsInterface_FormInputs = {
  wind_load: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'wind_load',
    label: 'Wind Load',
  },
}

const formInputs_CodeYears: TsInterface_FormInputs = {
  code_years: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'code_years',
    label: 'Code Years',
  },
}

const formInputs_GroundingRequirementNotes: TsInterface_FormInputs = {
  grounding_requirement_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'grounding_requirement_notes',
    label: 'Grounding Requirement Notes',
  },
}

const formInputs_DisconnectRequirementNotes: TsInterface_FormInputs = {
  disconnect_requirement_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'disconnect_requirement_notes',
    label: 'Disconnect Requirement Notes',
  },
}

const formInputs_WireSizingNotes: TsInterface_FormInputs = {
  wire_sizing_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'wire_sizing_notes',
    label: 'Wire Sizing Notes',
  },
}

const formInputs_ConduitNotes: TsInterface_FormInputs = {
  conduit_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'conduit_notes',
    label: 'Conduit Notes',
  },
}

const formInputs_PlacardRequirementNotes: TsInterface_FormInputs = {
  placard_requirement_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'placard_requirement_notes',
    label: 'Placard Requirement Notes',
  },
}

const formInputs_PermitFees: TsInterface_FormInputs = {
  permit_fees: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'permit_fees',
    label: 'Permit Fees',
  },
}

const formInputs_EstimatedTurnaround: TsInterface_FormInputs = {
  estimated_turnaround: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'estimated_turnaround',
    label: 'Estimated Turnaround time (business days)',
  },
}

const attachmentsRequirementOptions = [
  { key: 'permit_sheet', label: 'Permit application sheet', value: 'Permit application sheet' },
  { key: 'sld', label: 'SLD', value: 'SLD' },
  { key: 'three_ld', label: '3LD', value: '3LD' },
  { key: 'elevation', label: 'Elevation', value: 'Elevation' },
  { key: 'equipment_docs', label: 'Equipment Spec Documents', value: 'Equipment Spec Documents' },
]

const formInputs_AttachmentsRequirement: TsInterface_FormInputs = {
  attachments_requirement: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'attachments_requirement',
    label: 'Attachments Requirement',
    options: objectToArray(attachmentsRequirementOptions),
  },
}

const submissionModesOptions = [
  { key: 'online_portal', label: 'Online Portal', value: 'Online Portal' },
  { key: 'mail_in', label: 'Mail-in', value: 'Mail-in' },
  { key: 'counter', label: 'Counter', value: 'Counter' },
]

const formInputs_SubmissionModes: TsInterface_FormInputs = {
  submission_modes: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'submission_modes',
    label: 'Submission Modes',
    options: objectToArray(submissionModesOptions),
  },
}

const formInputs_SolarAppEnabled: TsInterface_FormInputs = {
  solarapp_enabled: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'solarapp_enabled',
    label: 'SolarApp enabled',
  },
}

const formInputs_PortalWebsiteAddress: TsInterface_FormInputs = {
  portal_website_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'portal_website_address',
    label: 'Portal Website Address',
  },
}

const formInputs_SolarApplicationLink: TsInterface_FormInputs = {
  solar_application_link: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'solar_application_link',
    label: 'Solar Application Link',
  },
}

const formInputs_HuApplicationLink: TsInterface_FormInputs = {
  hu_application_link: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'hu_application_link',
    label: 'HU Application Link',
  },
}

const paymentModesAcceptedOptions = [
  { key: 'cash', label: 'Cash', value: 'Cash' },
  { key: 'check', label: 'Check', value: 'Check' },
  { key: 'card', label: 'Card', value: 'Card' },
  { key: 'ach', label: 'ACH', value: 'ACH' },
]

const formInputs_PaymentModesAccepted: TsInterface_FormInputs = {
  payment_modes_accepted: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'payment_modes_accepted',
    label: 'Payment Modes Accepted',
    options: objectToArray(paymentModesAcceptedOptions),
  },
}

const formInputs_PeStampRequirement: TsInterface_FormInputs = {
  pe_stamp_requirement: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'pe_stamp_requirement',
    label: 'PE Stamp Requirement',
  },
}

const eeStampRequirementsOptions = [
  { key: 'digital_stamp', label: 'Digital Stamp', value: 'Digital Stamp' },
  { key: 'wet_stamp', label: 'Wet Stamp', value: 'Wet Stamp' },
  { key: 'always_required', label: 'Always Required', value: 'Always Required' },
  { key: 'required_for_battery_installs', label: 'Required for Battery Installs', value: 'Required for Battery Installs' },
]

const formInputs_EeStampRequirements: TsInterface_FormInputs = {
  ee_stamp_requirements: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'ee_stamp_requirements',
    label: 'EE Stamp Requirements',
    options: objectToArray(eeStampRequirementsOptions),
  },
}

const formInputs_AsBuiltRequirements: TsInterface_FormInputs = {
  as_built_requirements: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'as_built_requirements',
    label: 'As Built Requirements',
  },
}

const formInputs_HoaPreApprovalRequired: TsInterface_FormInputs = {
  hoa_pre_approval_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'hoa_pre_approval_required',
    label: 'HOA Pre-Approval Required',
  },
}

const inspectionTypeOptions = [
  { key: 'no_inspection', label: 'No inspection', value: 'No inspection' },
  { key: 'in_person', label: 'In-person', value: 'In-person' },
  { key: 'virtual', label: 'Virtual', value: 'Virtual' },
  { key: 'by_utility', label: 'By utility', value: 'By utility' },
]

const formInputs_InspectionType: TsInterface_FormInputs = {
  inspection_type: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'inspection_type',
    label: 'Inspection Type',
    options: objectToArray(inspectionTypeOptions),
  },
}

const formInputs_SolarInspectionSchedulingNotes: TsInterface_FormInputs = {
  solar_inspection_scheduling_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'solar_inspection_scheduling_notes',
    label: 'Solar Inspection Scheduling Notes',
  },
}

const formInputs_HuInspectionSchedulingNotes: TsInterface_FormInputs = {
  hu_inspection_scheduling_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'hu_inspection_scheduling_notes',
    label: 'HU Inspection Scheduling Notes',
  },
}

const formInputs_PermitHolder: TsInterface_FormInputs = {
  permit_holder: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'permit_holder',
    label: 'Permit Holder',
  },
}

const formInputs_PermitRenewalDate: TsInterface_FormInputs = {
  permit_renewal_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'permit_renewal_date',
    label: 'Permit Renewal Date',
  },
}

const formInputs_CoiRequired: TsInterface_FormInputs = {
  coi_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'coi_required',
    label: 'COI Required',
  },
}

const formInputs_RegistrationRequired: TsInterface_FormInputs = {
  registration_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'registration_required',
    label: 'Registration Required',
  },
}

const formInputs_AppliedDate: TsInterface_FormInputs = {
  applied_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'applied_date',
    label: 'Applied Date',
  },
}

const formInputs_ApprovedDate: TsInterface_FormInputs = {
  approved_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'approved_date',
    label: 'Approved Date',
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const jurisdictionKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_jurisdiction, us_setJurisdiction] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect

  useEffect(() => {
    document.title = se_JURISDICTION
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setJurisdiction(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Jurisdiction_Document(res_GCK.clientKey, jurisdictionKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, jurisdictionKey])

  // Functions
  const editItemProperty = (formInputs: TsInterface_FormInputs) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_jurisdiction,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_Jurisdiction_Document(res_GCK.clientKey, jurisdictionKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Jurisdiction')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editSingleSelectProperty = (formInputs: TsInterface_FormInputs, propKey: string) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_jurisdiction,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Convert the selected `key` to its corresponding `value` before saving
                  const selectedOption = formInputs[propKey].options?.find((option) => option.key === formSubmittedData[propKey])

                  const valueToSave = selectedOption ? selectedOption.value : formSubmittedData[propKey]

                  // Update formSubmittedData to only include the `value`
                  const updatedFormSubmittedData = { ...formSubmittedData, [propKey]: valueToSave }

                  DatabaseSetMergeDocument(DatabaseRef_Jurisdiction_Document(res_GCK.clientKey, jurisdictionKey), updatedFormSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Jurisdiction')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseJurisdictionsListPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {rLIB('Back to all Jurisdictions')}
      </Button>
    )
    return buttonJSX
  }

  // const rJSX_ViewOnlyLineItem = (displayText: JSX.Element, propKey: string): JSX.Element => {
  //   let lineJSX = (
  //     <Box>
  //       <Typography variant="h6">
  //         <Box
  //           className="tw-pr-2 tw-font-bold"
  //           component={'span'}
  //         >
  //           {displayText}:
  //         </Box>
  //         <Box
  //           className="tw-pr-1 tw-opacity-50 tw-font-normal"
  //           component={'span'}
  //         >
  //           {getProp(us_jurisdiction, propKey, rLIB('Missing'))}
  //         </Box>
  //       </Typography>
  //     </Box>
  //   )
  //   return lineJSX
  // }

  const rJSX_EditableLineItem = (displayText: JSX.Element, propKey: string, formInputs: TsInterface_FormInputs): JSX.Element => {
    let lineJSX = <></>

    // Missing Prop display for undefined properties
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-opacity-80 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )

    // Edit Icon for each line item
    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
        onClick={() => editItemProperty(formInputs)}
      />
    )

    // Get the property value
    const propValue = getProp(us_jurisdiction, propKey, missingTextJSX)

    // Determine how to render the propValue based on its type
    let contentToRender
    if (typeof propValue === 'boolean') {
      // Handle booleans
      contentToRender = propValue ? 'Yes' : 'No'
    } else if (typeof propValue === 'object' && propValue !== null && propValue !== missingTextJSX) {
      // Handle objects (for example, multi-select values)
      contentToRender = Object.entries(propValue)
        .filter(([key, value]) => value) // Filter for truthy values
        .map(([key]) => {
          // Find the corresponding label for each selected value
          const item = formInputs[propKey].options?.find((option) => option.key === key)
          return item ? item.label : 'Unknown Item' // Fallback for unmatched items
        })
        .join(', ') // Join items with commas for display
    } else {
      // Directly use value if it's not an object or boolean
      contentToRender = propValue
    }

    // Full JSX
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component={'span'}
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component={'span'}
          >
            {contentToRender}
          </Box>
          {editIconJSX}
        </Typography>
      </Box>
    )

    return lineJSX
  }

  const rJSX_EditableSingleSelectLineItem = (displayText: JSX.Element, propKey: string, formInputs: TsInterface_FormInputs): JSX.Element => {
    let lineJSX = <></>

    // Missing Prop display for undefined properties
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-opacity-80 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )

    // Edit Icon for each line item
    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
        onClick={() => editSingleSelectProperty(formInputs, propKey)} // Pass propKey to identify the field
      />
    )

    // Get the property value for the single-select
    const propValue = getProp(us_jurisdiction, propKey, missingTextJSX)

    // Display label based on value for single-select
    let contentToRender
    if (typeof propValue === 'string') {
      // Match the stored `value` with its corresponding `label`
      const item = formInputs[propKey].options?.find((option) => option.value === propValue)
      contentToRender = item ? item.label : propValue // Use the label if found, otherwise display the raw value
    } else {
      // Fallback for other types or missing values
      contentToRender = propValue
    }

    // Full JSX
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component={'span'}
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component={'span'}
          >
            {contentToRender}
          </Box>
          {editIconJSX}
        </Typography>
      </Box>
    )

    return lineJSX
  }

  const rJSX_EditableLink = (displayText: JSX.Element, propKey: string, formInputs: TsInterface_FormInputs): JSX.Element => {
    let lineJSX = <></>
    // Missing Prop
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-opacity-80 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )

    // Edit Icon
    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
        onClick={() => {
          editItemProperty(formInputs)
        }}
      />
    )

    // Get the URL value from the propKey
    const urlValue = getProp(us_jurisdiction, propKey, '') || ''

    // Full JSX
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component={'span'}
          >
            {displayText}:
          </Box>
          {/* Ensure the URL has the correct protocol and is external */}
          {urlValue && (
            <Link
              href={urlValue.startsWith('http') ? urlValue : `http://${urlValue}`} // Ensure it starts with http:// or https://
              target="_blank" // Opens in a new tab
              rel="noopener noreferrer" // Security
              sx={{
                'pr': 1,
                'opacity': 1,
                'fontWeight': 'normal',
                'color': themeVariables.primary_main,
                '&:hover': {
                  opacity: 1,
                  cursor: 'pointer',
                },
              }}
            >
              {urlValue}
            </Link>
          )}
          {!urlValue && missingTextJSX} {/* Show missing text if URL is not available */}
          {editIconJSX}
        </Typography>
      </Box>
    )

    return lineJSX
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Box>
          {/* Contact Information Section */}
          <Card className="tw-mb-4 tw-p-2">
            <Typography
              variant="h5"
              className="tw-mb-2"
            >
              <Trans>Contact Information</Trans>
            </Typography>
            {rJSX_EditableLineItem(<Trans>Jurisdiction Name</Trans>, 'name', formInputs_JurisdictionName)}
            {rJSX_EditableSingleSelectLineItem(<Trans>State</Trans>, 'state', formInputs_State)}
            {rJSX_EditableSingleSelectLineItem(<Trans>Type</Trans>, 'type', formInputs_Type)}
            {rJSX_EditableLineItem(<Trans>Contact Name</Trans>, 'contact_name', formInputs_ContactName)}
            {rJSX_EditableLineItem(<Trans>Address</Trans>, 'address', formInputs_Address)}
            {rJSX_EditableLineItem(<Trans>Phone Number</Trans>, 'phone_number', formInputs_PhoneNumber)}
            {rJSX_EditableLineItem(<Trans>Email</Trans>, 'email', formInputs_Email)}
            {rJSX_EditableLink(<Trans>Website</Trans>, 'website', formInputs_Website)}
          </Card>

          {/* Design Guidance Section */}
          <Card className="tw-mb-4 tw-p-2">
            <Typography
              variant="h5"
              className="tw-mb-2"
            >
              <Trans>Design Guidance</Trans>
            </Typography>
            {rJSX_EditableLineItem(<Trans>Eave Setback</Trans>, 'eave_setback', formInputs_EaveSetback)}
            {rJSX_EditableLineItem(<Trans>Valley Setback</Trans>, 'valley_setback', formInputs_ValleySetback)}
            {rJSX_EditableLineItem(<Trans>Ridge Setback</Trans>, 'ridge_setback', formInputs_RidgeSetback)}
            {rJSX_EditableLineItem(<Trans>Rake Setback</Trans>, 'rake_setback', formInputs_RakeSetback)}
            {rJSX_EditableLineItem(<Trans>Hip Setback</Trans>, 'hip_setback', formInputs_HipSetback)}
            {rJSX_EditableLineItem(<Trans>Snow Load</Trans>, 'snow_load', formInputs_SnowLoad)}
            {rJSX_EditableLineItem(<Trans>Wind Load</Trans>, 'wind_load', formInputs_WindLoad)}
            {rJSX_EditableLineItem(<Trans>Code Years</Trans>, 'code_years', formInputs_CodeYears)}
            {rJSX_EditableLineItem(<Trans>Grounding Requirement Notes</Trans>, 'grounding_requirement_notes', formInputs_GroundingRequirementNotes)}
            {rJSX_EditableLineItem(<Trans>Disconnect Requirement Notes</Trans>, 'disconnect_requirement_notes', formInputs_DisconnectRequirementNotes)}
            {rJSX_EditableLineItem(<Trans>Wire Sizing Notes</Trans>, 'wire_sizing_notes', formInputs_WireSizingNotes)}
            {rJSX_EditableLineItem(<Trans>Conduit Notes</Trans>, 'conduit_notes', formInputs_ConduitNotes)}
            {rJSX_EditableLineItem(<Trans>Placard Requirement Notes</Trans>, 'placard_requirement_notes', formInputs_PlacardRequirementNotes)}
          </Card>

          {/* Permit Submission Section */}
          <Card className="tw-mb-4 tw-p-2">
            <Typography
              variant="h5"
              className="tw-mb-2"
            >
              <Trans>Permit Submission</Trans>
            </Typography>
            {rJSX_EditableLineItem(<Trans>Permit Fees</Trans>, 'permit_fees', formInputs_PermitFees)}
            {rJSX_EditableLineItem(<Trans>Estimated Turnaround Time</Trans>, 'estimated_turnaround', formInputs_EstimatedTurnaround)}
            {rJSX_EditableLineItem(<Trans>Attachments Requirement</Trans>, 'attachments_requirement', formInputs_AttachmentsRequirement)}
            {rJSX_EditableLineItem(<Trans>Submission Modes</Trans>, 'submission_modes', formInputs_SubmissionModes)}
            {rJSX_EditableLineItem(<Trans>SolarApp Enabled</Trans>, 'solarapp_enabled', formInputs_SolarAppEnabled)}
            {rJSX_EditableLink(<Trans>Portal Website Address</Trans>, 'portal_website_address', formInputs_PortalWebsiteAddress)}
            {rJSX_EditableLink(<Trans>Solar Application Link</Trans>, 'solar_application_link', formInputs_SolarApplicationLink)}
            {rJSX_EditableLink(<Trans>HU Application Link</Trans>, 'hu_application_link', formInputs_HuApplicationLink)}
            {rJSX_EditableLineItem(<Trans>Payment Modes Accepted</Trans>, 'payment_modes_accepted', formInputs_PaymentModesAccepted)}
            {rJSX_EditableLineItem(<Trans>PE Stamp Requirement</Trans>, 'pe_stamp_requirement', formInputs_PeStampRequirement)}
            {rJSX_EditableLineItem(<Trans>EE Stamp Requirements</Trans>, 'ee_stamp_requirements', formInputs_EeStampRequirements)}
            {rJSX_EditableLineItem(<Trans>As Built Requirements</Trans>, 'as_built_requirements', formInputs_AsBuiltRequirements)}
            {rJSX_EditableLineItem(<Trans>HOA Pre-Approval Required</Trans>, 'hoa_pre_approval_required', formInputs_HoaPreApprovalRequired)}
          </Card>

          {/* Inspections Section */}
          <Card className="tw-mb-4 tw-p-2">
            <Typography
              variant="h5"
              className="tw-mb-2"
            >
              <Trans>Inspections</Trans>
            </Typography>
            {rJSX_EditableLineItem(<Trans>Inspection Type</Trans>, 'inspection_type', formInputs_InspectionType)}
            {rJSX_EditableLineItem(
              <Trans>Solar Inspection Scheduling Notes</Trans>,
              'solar_inspection_scheduling_notes',
              formInputs_SolarInspectionSchedulingNotes,
            )}
            {rJSX_EditableLineItem(<Trans>HU Inspection Scheduling Notes</Trans>, 'hu_inspection_scheduling_notes', formInputs_HuInspectionSchedulingNotes)}
          </Card>

          {/* ETW Permit Section */}
          <Card className="tw-mb-4 tw-p-2">
            <Typography
              variant="h5"
              className="tw-mb-2"
            >
              <Trans>ETW Permit</Trans>
            </Typography>
            {rJSX_EditableLineItem(<Trans>Permit Holder</Trans>, 'permit_holder', formInputs_PermitHolder)}
            {rJSX_EditableLineItem(<Trans>Permit Renewal Date</Trans>, 'permit_renewal_date', formInputs_PermitRenewalDate)}
            {rJSX_EditableLineItem(<Trans>COI Required</Trans>, 'coi_required', formInputs_CoiRequired)}
            {rJSX_EditableLineItem(<Trans>Registration Required</Trans>, 'registration_required', formInputs_RegistrationRequired)}
            {rJSX_EditableLineItem(<Trans>Applied Date</Trans>, 'applied_date', formInputs_AppliedDate)}
            {rJSX_EditableLineItem(<Trans>Approved Date</Trans>, 'approved_date', formInputs_ApprovedDate)}
          </Card>
        </Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_NotesTab = (): JSX.Element => {
    let tabJSX = (
      <ForumBasic
        forumThreadCollectionDatabaseEndpoint={(clientKey) => {
          return DatabaseRef_JurisdictionForumThreads_Collection(clientKey, jurisdictionKey)
        }}
        forumThreadDocumentDatabaseEndpoint={(clientKey, threadKey) => {
          return DatabaseRef_JurisdictionForumThread_Document(clientKey, jurisdictionKey, threadKey)
        }}
        forumMessageCollectionDatabaseEndpoint={(clientKey, threadKey) => {
          return DatabaseRef_JurisdictionForumMessages_Collection(clientKey, jurisdictionKey, threadKey)
        }}
        forumMessageDocumentDatabaseEndpoint={(clientKey, threadKey, messageKey) => {
          return DatabaseRef_JurisdictionForumMessage_Document(clientKey, jurisdictionKey, threadKey, messageKey)
        }}
        forumMessageStorageEndpoint={(clientKey, threadKey, messageKey, fileName) => {
          return StorageRef_Jurisdiction_MessageThread_File(clientKey, jurisdictionKey, threadKey, fileName)
        }}
        forumSettings={{
          allow_thread_creation: true,
          scrollbar_track_color: '#272b33',
          scrollbar_thumb_color: '#888',
          input_text_color: '#fff',
          base_message_container_offset_px: 336,
        }}
      />
    )
    return tabJSX
  }

  const rJSX_FilesTab = (): JSX.Element => {
    let tabJSX = (
      <FileSystemBasic
        fileSystemCollectionDatabaseEndpoint={(clientKey) => {
          return DatabaseRef_JurisdictionFiles_Collection(clientKey, jurisdictionKey)
        }}
        fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
          return DatabaseRef_JurisdictionFile_Document(clientKey, jurisdictionKey, fileKey)
        }}
        fileSystemStorageEndpoint={(clientKey, fileName) => {
          return StorageRef_JurisdictionFile(clientKey, jurisdictionKey, fileName)
        }}
        fileSystemHardCodedFolders={{}}
        fileSystemSettings={{
          allow_file_archiving: true,
          allow_file_movement: true,
          allow_file_unarchiving: true,
          allow_file_uploads: true,
          allow_folder_creation: true,
          allow_folder_deletion: true,
          allow_folder_movement: true,
          allow_folder_rename: true,
          archive_filter_visible: true,
        }}
      />
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Jurisdictions')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box>
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminDatabaseJurisdictionViewPage.url(jurisdictionKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: se_JURISDICTION,
                }}
                tabs={[
                  {
                    tabUrlKey: 'Details',
                    tabHeader: rLIB('Details'),
                    tabContent: rJSX_DetailsTab(),
                  },
                  {
                    tabUrlKey: 'Notes',
                    tabHeader: rLIB('Notes'),
                    tabContent: rJSX_NotesTab(),
                  },
                  {
                    tabUrlKey: 'Files',
                    tabHeader: rLIB('Files'),
                    tabContent: rJSX_FilesTab(),
                  },
                ]}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/*

	AHJ
	In Person
	Registered
	Website
	Permitting Portal
	Login
	HU Inspection
	Inspection Source
	Phone
	Address
	Email
	COI Required
	COI Required
	PE Package
	Fire Setbacks
	Code Years IRC/IFC/IBC/NEC
	Notes

*/
