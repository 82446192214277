///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Tooltip, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { importProjectDocument, returnIconKeyForFileType } from 'app/models/projects/project_document_folders'
import { Trans } from 'react-i18next'
import { DatabaseRef_Project_Documents_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ALL_DOCUMENTS: JSX.Element = <Trans>All Documents</Trans>
const s_ARCHIVE: JSX.Element = <Trans>Archive</Trans>
const s_ARCHIVED_DOCUMENTS: JSX.Element = <Trans>Archived Documents</Trans>
const s_ARCHIVE_DOCUMENT: JSX.Element = <Trans>Archive Document</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_ARCHIVE_THIS_DOCUMENT: JSX.Element = <Trans>Are you sure that you want to archive this document?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNARCHIVE_THIS_DOCUMENT: JSX.Element = <Trans>Are you sure that you want to unarchive this document?</Trans>
const s_FAILED_TO_UPLOAD_FILE: JSX.Element = <Trans>Failed to upload file</Trans>
const s_HIDDEN_FROM_CUSTOMER: JSX.Element = <Trans>Hidden from Customer</Trans>
const s_HIDE_ARCHIVED_DOCUMENTS: JSX.Element = <Trans>Hide Archived Documents</Trans>
const s_INVALID_DOCUMENT_SELECTION: JSX.Element = <Trans>Invalid document selection</Trans>
const s_NO_DOCUMENTS: JSX.Element = <Trans>No documents</Trans>
const s_PROJECT: JSX.Element = <Trans>Project</Trans>
const s_SELECT_A_FILTER: JSX.Element = <Trans>Select a Filter</Trans>
const s_TABLE_FILTER: JSX.Element = <Trans>Table Filter</Trans>
const s_UNARCHIVE: JSX.Element = <Trans>Unarchive</Trans>
const s_UNARCHIVE_DOCUMENT: JSX.Element = <Trans>Unarchive Document</Trans>
const s_UPLOADED_AT: JSX.Element = <Trans>Uploaded At</Trans>
const s_UPLOADED_BY: JSX.Element = <Trans>Uploaded By</Trans>
const s_UPLOAD_DOCUMENT: JSX.Element = <Trans>Upload Document</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const s_VISIBLE_TO_CUSTOMER: JSX.Element = <Trans>Visible to Customer</Trans>
// { sort-end } - displayed text

// Tables
const tableSettings_DocumentsFolder: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'file_name',
  sortable: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'archived',
        conditions: [],
      },
    },
  ],
}

const tableColumns_DocumentsFolder = {
  manage: TableCellManage({
    view: {
      icon: <Icon icon="magnifying-glass" />,
      label: <>{s_VIEW}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        window.open(rowData.url as string, '_blank')
      },
    },
    archive: {
      icon: <Icon icon="box-archive" />,
      label: <>{s_ARCHIVE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'archived',
        conditions: [],
      },
      conditional_disable: {
        active: true,
        logic_type: 'comparison',
        source: 'tableAdditionalData',
        prop: 'readOrWrite',
        comparator: '!=',
        value: 'write',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: s_ARCHIVE_DOCUMENT,
            icon: (
              <Icon
                icon="box-archive"
                type="solid"
              />
            ),
            submit_text: s_ARCHIVE,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_ARCHIVE_THIS_DOCUMENT,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject = {
                      status: 'archived',
                      visible_to_customer: false,
                    }
                    DatabaseSetMergeDocument(
                      DatabaseRef_Project_Documents_Document(
                        res_GCK.clientKey,
                        tableAdditionalData.projectKey,
                        tableAdditionalData.folderKey,
                        rowData.key as string,
                      ),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        tableHooks.ur_forceRerender()
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
    unarchive: {
      icon: <Icon icon="wand-magic-sparkles" />,
      label: <>{s_UNARCHIVE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'archived',
        conditions: [],
      },
      conditional_disable: {
        active: true,
        logic_type: 'comparison',
        source: 'tableAdditionalData',
        prop: 'readOrWrite',
        comparator: '!=',
        value: 'write',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'secondary',
            header: s_UNARCHIVE_DOCUMENT,
            icon: (
              <Icon
                icon="wand-magic-sparkles"
                type="solid"
              />
            ),
            submit_text: s_UNARCHIVE,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNARCHIVE_THIS_DOCUMENT,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject = {
                      status: 'active',
                    }
                    DatabaseSetMergeDocument(
                      DatabaseRef_Project_Documents_Document(
                        res_GCK.clientKey,
                        tableAdditionalData.projectKey,
                        tableAdditionalData.folderKey,
                        rowData.key as string,
                      ),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        tableHooks.ur_forceRerender()
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
  }),
  file_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box sx={{ color: returnIconKeyForFileType(rowData.file_type as string).color }}>
            <Icon
              size="3x"
              icon={returnIconKeyForFileType(rowData.file_type as string).icon}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  file_name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('File')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Typography variant="subtitle1">{getProp(rowData, 'file_name', '')}</Typography>
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  associated_uploader_name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_UPLOADED_BY
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Typography variant="subtitle1">{getProp(rowData, 'associated_uploader_name', '')}</Typography>
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  timestamp_uploaded: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_UPLOADED_AT
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let timestampText = <></>
        let uploadTimestamp = getProp(rowData, 'timestamp_uploaded', null)
        if (uploadTimestamp != null) {
          timestampText = <Box>{returnFormattedDate(uploadTimestamp, 'D MMM YYYY - h:mm a')}</Box>
        }
        let cellJSX = (
          <Box className="tw-opacity-50 tw-italic">
            <Typography variant="subtitle1">{timestampText}</Typography>
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  visible_to_customer: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_VISIBLE_TO_CUSTOMER
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Switch
              disabled={rowData == null || rowData['status'] === 'archived' || tableAdditionalData['readOrWrite'] === 'read'}
              checked={rowData.visible_to_customer as boolean}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.checked != null && rowData != null && rowData.key != null) {
                  let updateObject = {
                    visible_to_customer: event.target.checked,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_Project_Documents_Document(
                          res_GCK.clientKey,
                          tableAdditionalData.projectKey,
                          tableAdditionalData.folderKey,
                          rowData.key as string,
                        ),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

// Form
const documentFilterOptions: TsInterface_UnspecifiedObject = {
  all: {
    key: 'all',
    value: s_ALL_DOCUMENTS,
  },
  archived: {
    key: 'archived',
    value: s_ARCHIVED_DOCUMENTS,
  },
  not_archived: {
    key: 'not_archived',
    value: s_HIDE_ARCHIVED_DOCUMENTS,
  },
  visible_to_customer: {
    key: 'visible_to_customer',
    value: s_VISIBLE_TO_CUSTOMER,
  },
  hidden_from_customer: {
    key: 'hidden_from_customer',
    value: s_HIDDEN_FROM_CUSTOMER,
  },
}

const formInputs_DocumentTableFilter: TsInterface_FormInputs = {
  table_filter: {
    key: 'table_filter',
    label: s_TABLE_FILTER,
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(documentFilterOptions),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const uploadProjectDocument = (
  event: React.ChangeEvent<HTMLInputElement>,
  additionalFileUploadParams: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
      let file = event.target.files[0]
      const fileSize = file.size // File size in bytes

      const reader = new FileReader()
      reader.addEventListener('load', (imageLoadEvent) => {
        if (imageLoadEvent != null && imageLoadEvent.target != null && imageLoadEvent.target.result != null) {
          getClientKey(additionalFileUploadParams.uc_RootData_ClientKey, additionalFileUploadParams.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              // Pass file size to the importProjectDocument function
              importProjectDocument(
                res_GCK.clientKey,
                getProp(additionalFileUploadParams.uc_RootData_ClientUser, 'name', null),
                getProp(additionalFileUploadParams.uc_RootData_ClientUser, 'key', null),
                additionalFileUploadParams.projectKey,
                additionalFileUploadParams.folderKey,
                file,
                file.name,
                fileSize, // Pass file size
              )
                .then((res_IPD: any) => {
                  resolve(res_IPD)
                })
                .catch((rej_IPD: any) => {
                  additionalFileUploadParams.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_IPD.error })
                  reject(rej_IPD)
                })
            })
            .catch((rej_GCK) => {
              additionalFileUploadParams.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              reject(rej_GCK)
            })
        } else {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_UPLOAD_FILE,
              details: s_INVALID_DOCUMENT_SELECTION,
              code: 'ER-D-APV-UPD-01',
            },
          })
        }
      })
      reader.readAsDataURL(file)
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_UPLOAD_FILE,
          details: s_INVALID_DOCUMENT_SELECTION,
          code: 'ER-D-APV-UPD-02',
        },
      })
    }
  })
}

const returnTaskDocuments = (documentTableFilter: any, selectedDocumentsFolderData: any): TsInterface_UnspecifiedObject[] => {
  let filteredDocuments: TsInterface_UnspecifiedObject[] = []
  if (documentTableFilter === 'all') {
    filteredDocuments = objectToArray(selectedDocumentsFolderData)
  } else if (documentTableFilter === 'archived') {
    for (let loopDocumentKey in selectedDocumentsFolderData) {
      let loopDocument = selectedDocumentsFolderData[loopDocumentKey]
      if (loopDocument['status'] === 'archived') {
        filteredDocuments.push(loopDocument)
      }
    }
  } else if (documentTableFilter === 'not_archived') {
    for (let loopDocumentKey in selectedDocumentsFolderData) {
      let loopDocument = selectedDocumentsFolderData[loopDocumentKey]
      if (loopDocument['status'] !== 'archived') {
        filteredDocuments.push(loopDocument)
      }
    }
  } else if (documentTableFilter === 'visible_to_customer') {
    for (let loopDocumentKey in selectedDocumentsFolderData) {
      let loopDocument = selectedDocumentsFolderData[loopDocumentKey]
      if (loopDocument['visible_to_customer'] === true) {
        filteredDocuments.push(loopDocument)
      }
    }
  } else if (documentTableFilter === 'hidden_from_customer') {
    for (let loopDocumentKey in selectedDocumentsFolderData) {
      let loopDocument = selectedDocumentsFolderData[loopDocumentKey]
      if (loopDocument['visible_to_customer'] !== true) {
        filteredDocuments.push(loopDocument)
      }
    }
  } else {
    filteredDocuments = objectToArray(selectedDocumentsFolderData)
  }
  return filteredDocuments
}

///////////////////////////////
// JSX
///////////////////////////////

const rJSX_DocumentsRootButton = (setDocumentsViewLevel: any, setSelectedDocumentsFolder: any, setSelectedDocumentsFolderData: any): JSX.Element => {
  let buttonJSX = (
    <Button
      variant="outlined"
      color="inherit"
      className="tw-opacity-50 tw-mr-2 tw-mb-1"
      onClick={() => {
        setDocumentsViewLevel('root')
        setSelectedDocumentsFolder({})
        setSelectedDocumentsFolderData({})
      }}
    >
      <Icon
        icon="folder"
        className="tw-mr-2.5"
        type="regular"
      ></Icon>
      {s_PROJECT}
    </Button>
  )
  return buttonJSX
}

const rJSX_DocumentsUploadButton = (
  projectKey: string,
  availableFolders: Array<{ key: string; name: string }>, // List of available folders
  uc_RootData_ClientKey: string,
  uc_setRootData_ClientKey: any,
  uc_RootData_ClientUser: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  selectedFolder: string | null, // Add selectedFolder as a parameter
  setSelectedFolder: (folder: string | null) => void, //
): JSX.Element => {
  // Handle folder selection with the correct MUI SelectChangeEvent
  const handleFolderChange = (event: SelectChangeEvent<string>) => {
    setSelectedFolder(event.target.value)
  }

  // Handle file upload and make sure a folder is selected
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): Promise<any> => {
    if (selectedFolder) {
      return uploadProjectDocument(event, {
        projectKey: projectKey,
        folderKey: selectedFolder,
        uc_RootData_ClientKey: uc_RootData_ClientKey,
        uc_setRootData_ClientKey: uc_setRootData_ClientKey,
        uc_RootData_ClientUser: uc_RootData_ClientUser,
        uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
      })
        .then((res) => {
          console.log('Document uploaded successfully', res)
        })
        .catch((error) => {
          console.error('Failed to upload document', error)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: error.message || 'Unknown error occurred during file upload',
          })
        })
    } else {
      return Promise.reject({
        success: false,
        error: {
          message: 'Please select a folder before uploading.',
        },
      })
    }
  }

  return (
    <Box className="tw-mb-4 tw-mt-2">
      {/* Folder Selection Dropdown */}
      <FormControl
        variant="outlined"
        sx={{ width: '300px', height: '40px' }} // Adjust the height
        className="tw-mr-2"
        size="small" // Make the select smaller
      >
        <InputLabel>Select Folder to Upload Document</InputLabel>
        <Select
          value={selectedFolder || ''}
          onChange={handleFolderChange}
          label="Select Folder to Upload Document"
          sx={{ height: '38px', lineHeight: '1.5' }} // Adjust the Select height
        >
          {availableFolders.map((folder) => (
            <MenuItem
              key={folder.key}
              value={folder.key}
            >
              {folder.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Tooltip for disabled upload button */}
      <Tooltip title={!selectedFolder ? 'Please select a folder before uploading' : ''}>
        <span>
          {' '}
          {/* Wrap in a span to ensure the tooltip works with disabled button */}
          <FileUploadButton
            multiple={false}
            button={{
              text: <span>Upload Document</span>,
              icon: (
                <Icon
                  icon="cloud-arrow-up"
                  className="tw-mr-2"
                ></Icon>
              ),
              color: 'info',
              variant: 'contained',
              className: '',
              disabled: !selectedFolder, // Disable if no folder is selected
            }}
            accept="*"
            onChange={handleFileChange} // Pass the file change handler correctly
            additionalFileUploadParams={{
              projectKey: projectKey,
              folderKey: selectedFolder,
              uc_RootData_ClientKey: uc_RootData_ClientKey,
              uc_setRootData_ClientKey: uc_setRootData_ClientKey,
              uc_RootData_ClientUser: uc_RootData_ClientUser,
              uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
            }}
          />
        </span>
      </Tooltip>
    </Box>
  )
}

const rJSX_SelectedDocumentsFolderButton = (folderName: JSX.Element | string): JSX.Element => {
  let buttonJSX = (
    <Button
      variant="outlined"
      color="inherit"
      className="tw-mr-2 tw-mb-1"
    >
      <Icon
        icon="folder-open"
        className="tw-mr-2"
      ></Icon>
      {folderName}
    </Button>
  )
  return buttonJSX
}

const rJSX_ProjectRootDocumentsFolders = (projectDocumentFolders: any, setDocumentsViewLevel: any, setSelectedDocumentsFolder: any): JSX.Element => {
  let foldersJSX = (
    <Box>
      <Box className="tw-mb-2">{rJSX_SelectedDocumentsFolderButton(s_PROJECT)}</Box>
      <Grid2
        container
        spacing={2}
        columns={24}
      >
        {objectToArray(projectDocumentFolders).map((folder: TsInterface_UnspecifiedObject, index: number) => (
          <Grid2
            key={index}
            xs={8}
            sm={6}
            md={4}
            lg={3}
          >
            <Box
              className="tw-cursor-pointer tw-text-center"
              onClick={(event) => {
                if (event.detail === 2) {
                  setDocumentsViewLevel('folder')
                  setSelectedDocumentsFolder(folder)
                }
              }}
            >
              <Icon
                icon="folder-open"
                size="4x"
              />
              <br />
              {folder.name}
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  )
  return foldersJSX
}

const rJSX_DocumentFilterButton = (uc_setUserInterface_FormDialogDisplay: any, documentTableFilter: any, setDocumentTableFilter: any): JSX.Element => {
  let documentFilterButton = (
    <Button
      className="tw-m-auto tw-opacity-30 tw-ml-2"
      color="inherit"
      variant="outlined"
      startIcon={
        <Icon
          icon="filter"
          type="light"
        ></Icon>
      }
      onClick={() => {
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {
                table_filter: documentTableFilter,
              },
              formInputs: formInputs_DocumentTableFilter,
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  setDocumentTableFilter(formSubmittedData.table_filter)
                  resolve({ success: true })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: s_SELECT_A_FILTER,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="filter"
                />
              ),
            },
          },
        })
      }}
    >
      {documentFilterOptions[documentTableFilter]['value']}
    </Button>
  )
  return documentFilterButton
}

const rJSX_FolderListCaretButton = (): JSX.Element => {
  let buttonJSX = (
    <Icon
      icon="chevron-right"
      className="tw-mr-2 tw-opacity-40"
    ></Icon>
  )
  return buttonJSX
}

const rJSX_SelectedPhotoFolderButton = (folderName: JSX.Element | string): JSX.Element => {
  let buttonJSX = (
    <Button
      variant="outlined"
      color="inherit"
      className="tw-mr-3 tw-mb-1"
    >
      <Icon
        icon="folder-open"
        className="tw-mr-2"
      ></Icon>
      {folderName}
    </Button>
  )
  return buttonJSX
}

const rJSX_ProjectTaskDocumentsFolders = (
  readOrWrite: 'read' | 'write',
  selectedDocumentsFolderData: any,
  documentTableFilter: any,
  projectKey: any,
  selectedDocumentsFolder: any,
  setDocumentsViewLevel: any,
  setSelectedDocumentsFolder: any,
  setSelectedDocumentsFolderData: any,
  uc_setUserInterface_FormDialogDisplay: any,
  setDocumentTableFilter: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  uc_RootData_ClientUser: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  // Fetch available folders using the clientKey

  let documentTableJSX = <></>
  if (selectedDocumentsFolderData != null && returnTaskDocuments(documentTableFilter, selectedDocumentsFolderData).length > 0) {
    documentTableJSX = (
      <Card>
        <TableBasic
          tableAdditionalData={{
            projectKey: projectKey,
            folderKey: selectedDocumentsFolder.key,
            readOrWrite: readOrWrite,
          }}
          tableColumns={tableColumns_DocumentsFolder}
          tableData={returnTaskDocuments(documentTableFilter, selectedDocumentsFolderData)}
          tableSettings={tableSettings_DocumentsFolder}
        />
      </Card>
    )
  } else {
    documentTableJSX = (
      <Box className="tw-text-center">
        <Typography variant="h6">{s_NO_DOCUMENTS}</Typography>
      </Box>
    )
  }
  let uploadButtonJSX = <></>
  if (readOrWrite === 'write') {
    uploadButtonJSX = (
      <FileUploadButton
        multiple={false}
        button={{
          text: s_UPLOAD_DOCUMENT,
          icon: (
            <Icon
              icon="cloud-arrow-up"
              className="tw-mr-2"
            ></Icon>
          ),
          color: 'info',
          className: '',
          variant: 'contained',
          disabled: false,
        }}
        accept="*"
        onChange={uploadProjectDocument}
        additionalFileUploadParams={{
          projectKey: projectKey,
          folderKey: selectedDocumentsFolder.key,
          uc_RootData_ClientKey: uc_RootData_ClientKey,
          uc_setRootData_ClientKey: uc_setRootData_ClientKey,
          uc_RootData_ClientUser: uc_RootData_ClientUser,
          uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
        }}
      />
    )
  }
  let documentsListJSX = (
    <Box>
      <Box className="tw-mb-2">
        {rJSX_DocumentsRootButton(setDocumentsViewLevel, setSelectedDocumentsFolder, setSelectedDocumentsFolderData)}
        {rJSX_FolderListCaretButton()}
        {rJSX_SelectedPhotoFolderButton(selectedDocumentsFolder.name)}
        {uploadButtonJSX}
        {rJSX_DocumentFilterButton(uc_setUserInterface_FormDialogDisplay, documentTableFilter, setDocumentTableFilter)}
      </Box>
      {documentTableJSX}
    </Box>
  )

  return documentsListJSX
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const rJSX_DocumentsTab = (
  readOrWrite: 'read' | 'write',
  uc_RootData_ClientKey: any,
  uc_RootData_ClientUser: any,
  documentTableFilter: any,
  documentsViewLevel: any,
  projectDocumentFolders: any,
  projectKey: any,
  selectedDocumentsFolder: any,
  selectedDocumentsFolderData: any,
  setDocumentTableFilter: any,
  setDocumentsViewLevel: any,
  uc_setRootData_ClientKey: any,
  setSelectedDocumentsFolder: any,
  setSelectedDocumentsFolderData: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_FormDialogDisplay: any,
  selectedFolder?: string | null, // Make selectedFolder optional
  setSelectedFolder?: (folder: string | null) => void, // Make setSelectedFolder o
): JSX.Element => {
  // Transform folder data into an array of { key, name }
  const availableFolders = Object.values(projectDocumentFolders).map((folder: any) => ({
    key: folder.key,
    name: folder.name,
  }))
  let tabJSX = <></>
  if (documentsViewLevel === 'root') {
    tabJSX = (
      <Box>
        {/* Render the upload button at the root level */}
        {rJSX_DocumentsUploadButton(
          projectKey,
          availableFolders, // Pass the already available folders
          uc_RootData_ClientKey,
          uc_setRootData_ClientKey,
          uc_RootData_ClientUser,
          uc_setUserInterface_ErrorDialogDisplay,
          selectedFolder ?? null, // Pass the selected folder, default to null if not provided
          setSelectedFolder ?? (() => {}), // Pass the setter, default to a no-op function if not provided
        )}
        {/* Render the root document folders */}
        {rJSX_ProjectRootDocumentsFolders(projectDocumentFolders, setDocumentsViewLevel, setSelectedDocumentsFolder)}
      </Box>
    )
  } else if (documentsViewLevel === 'folder') {
    tabJSX = (
      <Box>
        {rJSX_ProjectTaskDocumentsFolders(
          readOrWrite,
          selectedDocumentsFolderData,
          documentTableFilter,
          projectKey,
          selectedDocumentsFolder,
          setDocumentsViewLevel,
          setSelectedDocumentsFolder,
          setSelectedDocumentsFolderData,
          uc_setUserInterface_FormDialogDisplay,
          setDocumentTableFilter,
          uc_RootData_ClientKey,
          uc_setRootData_ClientKey,
          uc_RootData_ClientUser,
          uc_setUserInterface_ErrorDialogDisplay,
        )}
      </Box>
    )
  } else {
    tabJSX = <Box>{rJSX_ProjectRootDocumentsFolders(projectDocumentFolders, setDocumentsViewLevel, setSelectedDocumentsFolder)}</Box>
  }
  return tabJSX
}
