///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card, Stack, Tooltip, Typography } from '@mui/material'
import { rJSX_TaskTypeSelector } from 'app/models/tasks/global_tasks'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { arrayToObject, cloneObjectWithoutReference, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { createAdHocTask } from './tab_tasks'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Tables
const tableColumns_SowItems: TsInterface_TableColumns = {
  manage0: TableCellManage({
    create_sow_task: {
      icon: <Icon icon="circle-plus" />,
      label: <>{rLIB('Create task')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        createAdHocTask(
          'sow',
          tableAdditionalData.projectKey,
          tableAdditionalData.projectTaskWorkflow,
          tableAdditionalData.projectTasks,
          tableAdditionalData.tempTaskOwnerRolesOptions,
          tableAdditionalData.tempTaskFormOptions,
          tableAdditionalData.uc_setUserInterface_FormDialogDisplay,
          rowData,
          getProp(rowData, 'key', null),
        )
      },
    },
  }),
  TEMP_create_status: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-w-4'
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData['key'] != null &&
          tableAdditionalData != null &&
          tableAdditionalData.associated_project_key != null &&
          tableAdditionalData['tasks'] != null &&
          tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']] != null
        ) {
          let associatedAdditionalWorkTask = tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']]
          if (associatedAdditionalWorkTask['status'] === 'completed') {
            cellJSX = (
              <Tooltip
                placement="top"
                title={rLIB('Additional Work Task Complete')}
              >
                <Box sx={{ color: themeVariables.info_main }}>
                  <Icon
                    size="xl"
                    icon="circle-check"
                  />
                </Box>
              </Tooltip>
            )
          } else if (associatedAdditionalWorkTask['status'] === 'deleted') {
            cellJSX = (
              <Tooltip
                placement="top"
                title={rLIB('Additional Work Task Deleted')}
              >
                <Box sx={{ color: themeVariables.gray_700 }}>
                  <Icon
                    size="xl"
                    icon="trash"
                  />
                </Box>
              </Tooltip>
            )
          } else {
            cellJSX = (
              <Tooltip
                placement="top"
                title={rLIB('Additional Work Task created but not completed')}
              >
                <Box sx={{ color: themeVariables.success_main }}>
                  <Icon
                    size="xl"
                    icon="circle-play"
                  />
                </Box>
              </Tooltip>
            )
          }
        } else {
          cellJSX = (
            <Tooltip
              placement="top"
              title={rLIB('No Task created for this Additional Work item')}
            >
              <Box sx={{ color: themeVariables.gray_500 }}>
                <Icon
                  size="xl"
                  icon="circle-pause"
                />
              </Box>
            </Tooltip>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData['name'] != null) {
          if (
            rowData != null &&
            rowData['key'] != null &&
            tableAdditionalData != null &&
            tableAdditionalData.associated_project_key != null &&
            tableAdditionalData['tasks'] != null &&
            tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']] != null
          ) {
            let associatedAdditionalWorkTask = tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']]
            if (associatedAdditionalWorkTask['status'] === 'completed') {
              cellJSX = (
                <Box
                  className="tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                  // sx={{ background: themeVariables.info_main }}
                  sx={{ background: themeVariables.warning_main }}
                >
                  {rowData.name}
                </Box>
              )
            } else if (associatedAdditionalWorkTask['status'] === 'deleted') {
              cellJSX = (
                <Box
                  className="tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                  // sx={{ background: themeVariables.gray_700 }}
                  sx={{ background: themeVariables.warning_main }}
                >
                  {rowData.name}
                </Box>
              )
            } else {
              cellJSX = (
                <Box
                  className="tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                  // sx={{ background: themeVariables.success_main }}
                  sx={{ background: themeVariables.warning_main }}
                >
                  {rowData.name}
                </Box>
              )
            }
          } else {
            cellJSX = (
              <Box
                className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
                onClick={() => {
                  if (tableAdditionalData.readOrWrite === 'write') {
                    if (
                      rowData != null &&
                      rowData.associated_task_group_key == null &&
                      tableAdditionalData != null &&
                      tableAdditionalData.us_additionalWorkTaskType != null
                    ) {
                      rowData['associated_task_group_key'] = tableAdditionalData.us_additionalWorkTaskType
                    }
                    createAdHocTask(
                      'sow',
                      tableAdditionalData.projectKey,
                      tableAdditionalData.projectTaskWorkflow,
                      tableAdditionalData.projectTasks,
                      tableAdditionalData.tempTaskOwnerRolesOptions,
                      tableAdditionalData.tempTaskFormOptions,
                      tableAdditionalData.uc_setUserInterface_FormDialogDisplay,
                      rowData,
                      getProp(rowData, 'key', null),
                    )
                  }
                }}
              >
                {rowData.name}
              </Box>
            )
          }
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Additional Work Item')
      },
      header_sort_by: 'name',
    },
  },
  TEMP_start_time: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData['key'] != null &&
          tableAdditionalData != null &&
          tableAdditionalData.associated_project_key != null &&
          tableAdditionalData['tasks'] != null &&
          tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']] != null
        ) {
          let associatedAdditionalWorkTask = tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']]
          if (associatedAdditionalWorkTask.timestamp_created != null) {
            cellJSX = <>{returnFormattedDate(associatedAdditionalWorkTask.timestamp_created, 'D MMM YYYY h:mm a')}</>
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-italic">{rLIB('Missing')}</Box>
          }
        } else {
          // cellJSX = <></>
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Creation date')
      },
      header_sort_by: null,
    },
  },
  TEMP_completion_time: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData['key'] != null &&
          tableAdditionalData != null &&
          tableAdditionalData.associated_project_key != null &&
          tableAdditionalData['tasks'] != null &&
          tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']] != null
        ) {
          let associatedAdditionalWorkTask = tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']]
          if (associatedAdditionalWorkTask.timestamp_completed != null) {
            cellJSX = <>{returnFormattedDate(associatedAdditionalWorkTask.timestamp_completed, 'D MMM YYYY h:mm a')}</>
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-italic">{rLIB('Missing')}</Box>
          }
        } else {
          // cellJSX = <></>
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Completion date')
      },
      header_sort_by: null,
    },
  },
  manage: TableCellManage({
    create_sow_task: {
      icon: <Icon icon="circle-plus" />,
      label: <>{rLIB('Create task')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        createAdHocTask(
          'sow',
          tableAdditionalData.projectKey,
          tableAdditionalData.projectTaskWorkflow,
          tableAdditionalData.projectTasks,
          tableAdditionalData.tempTaskOwnerRolesOptions,
          tableAdditionalData.tempTaskFormOptions,
          tableAdditionalData.uc_setUserInterface_FormDialogDisplay,
          rowData,
          getProp(rowData, 'key', null),
        )
      },
    },
  }),
}

const tableColumns_SowItemsReadOnly: TsInterface_TableColumns = {
  TEMP_create_status: tableColumns_SowItems['TEMP_create_status'],
  name: tableColumns_SowItems['name'],
  TEMP_start_time: tableColumns_SowItems['TEMP_start_time'],
  TEMP_completion_time: tableColumns_SowItems['TEMP_completion_time'],
}

const tableSettings_SowItems: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// JSX
///////////////////////////////

///////////////////////////////
// JSX Exports
///////////////////////////////

const rJSX_AdditionalWorkTabV1 = (
  readOrWrite: 'read' | 'write',
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
  uc_setUserInterface_FormDialogDisplay: any,
  tempTaskFormOptions: any,
  tempTaskOwnerRolesOptions: any,
): JSX.Element => {
  let tabJSX = <></>
  if (projectTaskWorkflow != null && projectTaskWorkflow['sow_items'] != null && objectToArray(projectTaskWorkflow['sow_items']).length > 0) {
    let sowTableColumns = tableColumns_SowItemsReadOnly
    if (readOrWrite === 'write') {
      sowTableColumns = tableColumns_SowItems
    }
    tabJSX = (
      <Card className="">
        <TableBasic
          tableAdditionalData={{
            readOrWrite: readOrWrite,
            associated_project_key: projectKey,
            task_groups: arrayToObject(projectTaskWorkflow.task_groups, 'key'),
            tasks: projectTasks,
            projectKey: projectKey,
            projectTaskWorkflow: projectTaskWorkflow,
            projectTasks: projectTasks,
            tempTaskOwnerRolesOptions: tempTaskOwnerRolesOptions,
            tempTaskFormOptions: tempTaskFormOptions,
            uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
          }}
          tableColumns={sowTableColumns}
          tableData={objectToArray(projectTaskWorkflow['sow_items'])}
          tableSettings={tableSettings_SowItems}
        />
      </Card>
    )
  } else {
    tabJSX = (
      <Box className="tw-text-center tw-mt-4">
        <Typography
          variant="h6"
          className="tw-opacity-30 tw-italic"
        >
          {rLIB('No Additional Work Items defined on selected workflow')}
        </Typography>
      </Box>
    )
  }
  return tabJSX
}

const mergeWorkflowWithTaskBlueprint = (workflowTask: TsInterface_UnspecifiedObject, blueprintTask: TsInterface_UnspecifiedObject) => {
  const mergedObj = cloneObjectWithoutReference(blueprintTask)
  for (const key in workflowTask) {
    // eslint-disable-next-line no-prototype-builtins
    if (workflowTask.hasOwnProperty(key)) {
      mergedObj[key] = workflowTask[key]
    }
  }
  return mergedObj
}

const returnCombinedTableData = (
  blueprintTasks: TsInterface_UnspecifiedObject[],
  workflowAdditionalWorkTasks: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject[] => {
  let allTasksObject: TsInterface_UnspecifiedObject = {}
  for (let loopBlueprintTaskIndex in blueprintTasks) {
    let loopBlueprintTask = blueprintTasks[loopBlueprintTaskIndex]
    if (loopBlueprintTask != null && loopBlueprintTask['key'] != null) {
      let workflowTask = getProp(workflowAdditionalWorkTasks, loopBlueprintTask['key'], null)
      // Combine Blueprint with Workflow level overrides
      let mergedTask = mergeWorkflowWithTaskBlueprint({ ...workflowTask }, loopBlueprintTask)
      allTasksObject[loopBlueprintTask['key']] = mergedTask
    }
  }
  return objectToArray(allTasksObject)
}

const rJSX_AdditionalWorkTabV2 = (
  readOrWrite: 'read' | 'write',
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
  uc_setUserInterface_FormDialogDisplay: any,
  tempTaskFormOptions: any,
  tempTaskOwnerRolesOptions: any,
  us_activeBlueprintTasks: any,
  us_additionalWorkTaskType: any,
  us_setAdditionalWorkTaskType: any,
): JSX.Element => {
  let tabJSX = <></>
  let sowTableColumns = tableColumns_SowItemsReadOnly
  if (readOrWrite === 'write') {
    sowTableColumns = tableColumns_SowItems
  }
  if (us_additionalWorkTaskType == null) {
    tabJSX = (
      <Box className="tw-py-4 tw-text-center tw-opacity-50 tw-mb-2">
        <Typography variant="h6">{rLIB('Select a task type')}</Typography>
        {rJSX_TaskTypeSelector(us_additionalWorkTaskType, us_setAdditionalWorkTaskType, 'additional_work', 'project')}
      </Box>
    )
  } else {
    tabJSX = (
      <Box>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-2"
        >
          {rJSX_TaskTypeSelector(us_additionalWorkTaskType, us_setAdditionalWorkTaskType, 'additional_work', 'project')}
        </Stack>
        <Card>
          <TableBasic
            tableAdditionalData={{
              readOrWrite: readOrWrite,
              associated_project_key: projectKey,
              task_groups: arrayToObject(projectTaskWorkflow.task_groups, 'key'),
              tasks: projectTasks,
              projectKey: projectKey,
              projectTaskWorkflow: projectTaskWorkflow,
              projectTasks: projectTasks,
              tempTaskOwnerRolesOptions: tempTaskOwnerRolesOptions,
              tempTaskFormOptions: tempTaskFormOptions,
              uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
              us_additionalWorkTaskType: us_additionalWorkTaskType,
            }}
            tableColumns={sowTableColumns}
            // tableData={ objectToArray( us_activeBlueprintTasks ).filter((task: TsInterface_UnspecifiedObject) => task.task_type === us_additionalWorkTaskType ) }
            tableData={returnCombinedTableData(
              objectToArray(us_activeBlueprintTasks).filter((task: TsInterface_UnspecifiedObject) => task.task_type === us_additionalWorkTaskType),
              projectTaskWorkflow.additional_work_tasks,
            )}
            tableSettings={tableSettings_SowItems}
          />
        </Card>
      </Box>
    )
  }
  return tabJSX
}

export const rJSX_AdditionalWorkTab = (
  readOrWrite: 'read' | 'write',
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
  uc_setUserInterface_FormDialogDisplay: any,
  tempTaskFormOptions: any,
  tempTaskOwnerRolesOptions: any,
  us_activeBlueprintTasks: any,
  us_additionalWorkTaskType: any,
  us_setAdditionalWorkTaskType: any,
): JSX.Element => {
  let tabJSX = <></>
  if (projectTaskWorkflow.workflow_version_number === 2) {
    tabJSX = rJSX_AdditionalWorkTabV2(
      readOrWrite,
      projectKey,
      projectTaskWorkflow,
      projectTasks,
      uc_setUserInterface_FormDialogDisplay,
      tempTaskFormOptions,
      tempTaskOwnerRolesOptions,
      us_activeBlueprintTasks,
      us_additionalWorkTaskType,
      us_setAdditionalWorkTaskType,
    )
  } else {
    tabJSX = rJSX_AdditionalWorkTabV1(
      readOrWrite,
      projectKey,
      projectTaskWorkflow,
      projectTasks,
      uc_setUserInterface_FormDialogDisplay,
      tempTaskFormOptions,
      tempTaskOwnerRolesOptions,
    )
  }
  return tabJSX
}
