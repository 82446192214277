///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { DatabaseRef_SalesPartner_Document } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_ProjectBaseInvoice_Document } from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import { DatabaseRef_ProjectFinance_Document, DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { downloadPdfFromData, returnPdfBlobFromData, TsInterface_PdfTemplate } from 'rfbp_core/components/pdf/custom'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetDocument } from 'rfbp_core/services/database_management'
import { formatCurrency, getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { logoBase64 } from '../images/logo_base_64'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const convertBlobToBase64 = (blob: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob) // Converts Blob to a Base64-encoded string
    reader.onloadend = () => {
      // @ts-ignore
      const base64data = reader.result.split(',')[1] // Strip metadata (like "data:application/pdf;base64,")
      resolve(base64data)
    }
    reader.onerror = reject
  })
}

///////////////////////////////
// Exports
///////////////////////////////

export const invoicePdfTemplate: TsInterface_PdfTemplate = {
  page_settings: {
    flexDirection: 'column',
    paddingTop: '0in',
    paddingBottom: '0in',
    paddingLeft: '0in',
    paddingRight: '0in',
  },
  page_contents: {
    COLOR_HEADER: {
      key: 'COLOR_HEADER',
      order: 1,
      component_type: 'static_table',
      table_style: {
        backgroundColor: '#929598',
        display: 'flex',
        width: 100,
      },
      rows: {
        ROW1: {
          key: 'ROW1',
          order: 1,
          row_style: {
            flexDirection: 'row',
          },
          cells: {
            PLACEHOLDER_CELL: {
              key: 'PLACEHOLDER_CELL',
              order: 2,
              cell_style: {
                width: 40,
              },
              contents: {
                EMPTY_TEXT: {
                  component_type: 'static_text',
                  key: 'EMPTY_TEXT',
                  order: 1,
                  text_style: {
                    fontSize: 24,
                    textAlign: 'right',
                    color: '#22c6ff',
                    marginBottom: 10,
                  },
                  text: ' ',
                },
              },
            },
          },
        },
      },
    },
    CONTENT_VIEW: {
      key: 'CONTENT_VIEW',
      order: 2,
      component_type: 'view',
      view_style: {
        paddingTop: '0.5in',
        paddingBottom: '0.5in',
        paddingLeft: '1in',
        paddingRight: '1in',
      },
      contents: {
        LOGO_HEADER: {
          key: 'LOGO_HEADER',
          order: 1,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                LOGO_CELL: {
                  key: 'LOGO_CELL',
                  order: 1,
                  cell_style: {
                    width: 50,
                  },
                  contents: {
                    LOGO: {
                      component_type: 'static_image',
                      key: 'LOGO',
                      order: 1,
                      image_style: {
                        width: 70,
                        marginTop: 16,
                        marginBottom: 16,
                      },
                      src: logoBase64,
                    },
                  },
                },
                INVOICE_CELL: {
                  key: 'INVOICE_CELL',
                  order: 2,
                  cell_style: {
                    width: 50,
                  },
                  contents: {
                    INVOICE_TEXT: {
                      component_type: 'static_text',
                      key: 'INVOICE_TEXT',
                      order: 1,
                      text_style: {
                        fontSize: 24,
                        textAlign: 'right',
                        color: '#22c6ff',
                        marginBottom: 10,
                      },
                      text: 'INVOICE',
                    },
                    TABLE: {
                      component_type: 'static_table',
                      key: 'TABLE',
                      order: 1,
                      table_style: {
                        display: 'flex',
                        width: 100,
                        textAlign: 'right',
                      },
                      rows: {
                        ROW1: {
                          key: 'ROW1',
                          order: 1,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 60,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER_LABEL: {
                                  component_type: 'static_text',
                                  key: 'INVOICE_NUMBER_LABEL',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'INVOICE NUMBER',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 40,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_NUMBER',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    color: '#000000',
                                  },
                                  variable_mapping_key: 'invoice_number',
                                },
                              },
                            },
                          },
                        },
                        ROW2: {
                          key: 'ROW2',
                          order: 2,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 60,
                                padding: 2,
                              },
                              contents: {
                                DATE: {
                                  component_type: 'static_text',
                                  key: 'DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'DATE OF ISSUE ',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 40,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_DATE: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                  },
                                  variable_mapping_key: 'invoice_date',
                                  fallback_text: ' ',
                                  fallback_text_style: {
                                    fontSize: 10,
                                    color: '#FF0000',
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ADDRESS_TABLE: {
          key: 'ADDRESS_TABLE',
          order: 2,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '3331 N. PanAm Expy',
                      text: '98 San Jacinto Blvd',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'Accounts Payable',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: 'San Antonio, TX',
                      text: '4th Floor',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'ap@etwenergy.com',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '78219',
                      text: 'Austin, TX 78701',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: '801-864-1503',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
          },
        },
        BILLED_TO_TABLE: {
          key: 'BILLED_TO_TABLE',
          order: 3,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginBottom: 32,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 16,
                  },
                  contents: {
                    BILLED_TO: {
                      component_type: 'static_text',
                      key: 'BILLED_TO',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#BBBBBB',
                      },
                      text: 'BILLED TO',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      key: 'INVOICE_DATE',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_name',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    STREET: {
                      component_type: 'variable_text',
                      key: 'STREET',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_address',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW4: {
              key: 'ROW4',
              order: 4,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    CITY: {
                      component_type: 'variable_text',
                      key: 'CITY',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                        display: 'inline-block',
                      },
                      variable_mapping_key: 'location_city_plus_state',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW5: {
              key: 'ROW5',
              order: 5,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    ZIP: {
                      component_type: 'variable_text',
                      key: 'ZIP',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_zip',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        LINE_ITEMS_TABLE: {
          key: 'LINE_ITEMS_TABLE',
          order: 5,
          component_type: 'looped_data_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          row_style: {
            flexDirection: 'row',
          },
          table_data_variable_mapping_key: 'line_items',
          columns: {
            COL0: {
              key: 'COL0',
              order: 0,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'PROJECT',
              variable_mapping_key: 'item_job_code',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL1: {
              key: 'COL1',
              order: 1,
              column_header_style: {
                width: 60,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 60,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'DESCRIPTION',
              variable_mapping_key: 'item_description',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL4: {
              key: 'COL4',
              order: 4,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'AMOUNT ($)',
              variable_mapping_key: 'item_total',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
          },
        },
        TOTAL_TABLE: {
          key: 'TOTAL_TABLE',
          order: 6,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 10,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                    borderTop: '1px solid #444444',
                    marginTop: 10,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        paddingTop: 18,
                        fontSize: 10,
                        textAlign: 'right',
                        color: '#BBBBBB',
                      },
                      text: 'INVOICE TOTAL',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                  },
                  contents: {
                    LINE_ITEM_TOTAL: {
                      component_type: 'variable_text',
                      key: 'LINE_ITEM_TOTAL',
                      order: 1,
                      text_style: {
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'right',
                      },
                      variable_mapping_key: 'line_item_total',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 16,
                        color: '#FF0000',
                        textAlign: 'right',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        NOTES_TABLE: {
          component_type: 'static_table',
          key: 'NOTES_TABLE',
          order: 7,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#BBBBBB',
                      },
                      text: 'NOTES:',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      key: 'NOTES',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        fontWeight: 'bold',
                      },
                      variable_mapping_key: 'line_item_notes',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TERMS_TABLE: {
          key: 'TERMS_TABLE',
          order: 8,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 40,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'NET10',
                      // text: 'This is a quote. Invoice charges may vary and are based on actual work required.',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    borderBottom: '1px solid #444444',
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: ' ',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

export const detailedInvoicePdfTemplate: TsInterface_PdfTemplate = {
  page_settings: {
    flexDirection: 'column',
    paddingTop: '0in',
    paddingBottom: '0in',
    paddingLeft: '0in',
    paddingRight: '0in',
  },
  page_contents: {
    COLOR_HEADER: {
      key: 'COLOR_HEADER',
      order: 1,
      component_type: 'static_table',
      table_style: {
        backgroundColor: '#929598',
        display: 'flex',
        width: 100,
      },
      rows: {
        ROW1: {
          key: 'ROW1',
          order: 1,
          row_style: {
            flexDirection: 'row',
          },
          cells: {
            PLACEHOLDER_CELL: {
              key: 'PLACEHOLDER_CELL',
              order: 2,
              cell_style: {
                width: 40,
              },
              contents: {
                EMPTY_TEXT: {
                  component_type: 'static_text',
                  key: 'EMPTY_TEXT',
                  order: 1,
                  text_style: {
                    fontSize: 24,
                    textAlign: 'right',
                    color: '#22c6ff',
                    marginBottom: 10,
                  },
                  text: ' ',
                },
              },
            },
          },
        },
      },
    },
    CONTENT_VIEW: {
      key: 'CONTENT_VIEW',
      order: 2,
      component_type: 'view',
      view_style: {
        paddingTop: '0.5in',
        paddingBottom: '0.5in',
        paddingLeft: '1in',
        paddingRight: '1in',
      },
      contents: {
        LOGO_HEADER: {
          key: 'LOGO_HEADER',
          order: 1,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                LOGO_CELL: {
                  key: 'LOGO_CELL',
                  order: 1,
                  cell_style: {
                    width: 50,
                  },
                  contents: {
                    LOGO: {
                      component_type: 'static_image',
                      key: 'LOGO',
                      order: 1,
                      image_style: {
                        width: 70,
                        marginTop: 16,
                        marginBottom: 16,
                      },
                      src: logoBase64,
                    },
                  },
                },
                INVOICE_CELL: {
                  key: 'INVOICE_CELL',
                  order: 2,
                  cell_style: {
                    width: 50,
                  },
                  contents: {
                    INVOICE_TEXT: {
                      component_type: 'static_text',
                      key: 'INVOICE_TEXT',
                      order: 1,
                      text_style: {
                        fontSize: 24,
                        textAlign: 'right',
                        color: '#22c6ff',
                        marginBottom: 10,
                      },
                      text: 'INVOICE',
                    },
                    TABLE: {
                      component_type: 'static_table',
                      key: 'TABLE',
                      order: 1,
                      table_style: {
                        display: 'flex',
                        width: 100,
                        textAlign: 'right',
                      },
                      rows: {
                        ROW1: {
                          key: 'ROW1',
                          order: 1,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 60,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER_LABEL: {
                                  component_type: 'static_text',
                                  key: 'INVOICE_NUMBER_LABEL',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'INVOICE NUMBER',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 40,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_NUMBER',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    color: '#000000',
                                  },
                                  variable_mapping_key: 'invoice_number',
                                },
                              },
                            },
                          },
                        },
                        ROW2: {
                          key: 'ROW2',
                          order: 2,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 60,
                                padding: 2,
                              },
                              contents: {
                                DATE: {
                                  component_type: 'static_text',
                                  key: 'DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'DATE OF ISSUE ',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 40,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_DATE: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                  },
                                  variable_mapping_key: 'invoice_date',
                                  fallback_text: ' ',
                                  fallback_text_style: {
                                    fontSize: 10,
                                    color: '#FF0000',
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ADDRESS_TABLE: {
          key: 'ADDRESS_TABLE',
          order: 2,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '3331 N. PanAm Expy',
                      text: '98 San Jacinto Blvd',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'Accounts Payable',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: 'San Antonio, TX',
                      text: '4th Floor',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'ap@etwenergy.com',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '78219',
                      text: 'Austin, TX 78701',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: '801-864-1503',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
          },
        },
        BILLED_TO_TABLE: {
          key: 'BILLED_TO_TABLE',
          order: 3,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginBottom: 32,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 16,
                  },
                  contents: {
                    BILLED_TO: {
                      component_type: 'static_text',
                      key: 'BILLED_TO',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#BBBBBB',
                      },
                      text: 'BILLED TO',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      key: 'INVOICE_DATE',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_name',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    STREET: {
                      component_type: 'variable_text',
                      key: 'STREET',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_address',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW4: {
              key: 'ROW4',
              order: 4,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    CITY: {
                      component_type: 'variable_text',
                      key: 'CITY',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                        display: 'inline-block',
                      },
                      variable_mapping_key: 'location_city_plus_state',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW5: {
              key: 'ROW5',
              order: 5,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    ZIP: {
                      component_type: 'variable_text',
                      key: 'ZIP',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_zip',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        JOB_CODE_TABLE: {
          component_type: 'static_table',
          key: 'JOB_CODE_TABLE',
          order: 4,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    JOB_CODE: {
                      key: 'JOB_CODE',
                      order: 1,
                      component_type: 'variable_text',
                      text_style: {
                        fontSize: 14,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#22c6ff',
                      },
                      variable_mapping_key: 'job_code',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 14,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#22c6ff',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        LINE_ITEMS_TABLE: {
          key: 'LINE_ITEMS_TABLE',
          order: 5,
          component_type: 'looped_data_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          row_style: {
            flexDirection: 'row',
          },
          table_data_variable_mapping_key: 'line_items',
          columns: {
            COL1: {
              key: 'COL1',
              order: 1,
              column_header_style: {
                width: 50,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 50,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'DESCRIPTION',
              variable_mapping_key: 'item_description',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL2: {
              key: 'COL2',
              order: 2,
              column_header_style: {
                width: 15,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 15,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'RATE ($)',
              variable_mapping_key: 'item_rate',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL3: {
              key: 'COL3',
              order: 3,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'ADDERS ($)',
              variable_mapping_key: 'item_adders',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL4: {
              key: 'COL4',
              order: 4,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'AMOUNT ($)',
              variable_mapping_key: 'item_total',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
          },
        },
        FEES_TABLE: {
          component_type: 'static_table',
          key: 'FEES_TABLE',
          order: 6,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 18,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    LABEL: {
                      key: 'LABEL',
                      order: 1,
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#BBBBBB',
                      },
                      text: 'Permitting Fees:',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 82,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      key: 'NOTES',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        fontWeight: 'bold',
                      },
                      variable_mapping_key: 'permitting_fees',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        NOTES_TABLE: {
          component_type: 'static_table',
          key: 'NOTES_TABLE',
          order: 7,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 8,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    LABEL: {
                      key: 'LABEL',
                      order: 1,
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#BBBBBB',
                      },
                      text: 'NOTES:',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 92,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      key: 'NOTES',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        fontWeight: 'bold',
                      },
                      variable_mapping_key: 'line_item_notes',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TOTAL_TABLE: {
          key: 'TOTAL_TABLE',
          order: 8,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 10,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                    borderTop: '1px solid #444444',
                    marginTop: 10,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        paddingTop: 18,
                        fontSize: 10,
                        textAlign: 'right',
                        color: '#BBBBBB',
                      },
                      text: 'INVOICE TOTAL',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                  },
                  contents: {
                    LINE_ITEM_TOTAL: {
                      component_type: 'variable_text',
                      key: 'LINE_ITEM_TOTAL',
                      order: 1,
                      text_style: {
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'right',
                      },
                      variable_mapping_key: 'line_item_total',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 16,
                        color: '#FF0000',
                        textAlign: 'right',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TERMS_TABLE: {
          key: 'TERMS_TABLE',
          order: 9,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 40,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'NET10',
                      // text: 'This is a quote. Invoice charges may vary and are based on actual work required.',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    borderBottom: '1px solid #444444',
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: ' ',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

export const quotePdfTemplate: TsInterface_PdfTemplate = {
  page_settings: {
    flexDirection: 'column',
    paddingTop: '0in',
    paddingBottom: '0in',
    paddingLeft: '0in',
    paddingRight: '0in',
  },
  page_contents: {
    COLOR_HEADER: {
      key: 'COLOR_HEADER',
      order: 1,
      component_type: 'static_table',
      table_style: {
        backgroundColor: '#929598',
        display: 'flex',
        width: 100,
      },
      rows: {
        ROW1: {
          key: 'ROW1',
          order: 1,
          row_style: {
            flexDirection: 'row',
          },
          cells: {
            PLACEHOLDER_CELL: {
              key: 'PLACEHOLDER_CELL',
              order: 2,
              cell_style: {
                width: 40,
              },
              contents: {
                EMPTY_TEXT: {
                  component_type: 'static_text',
                  key: 'EMPTY_TEXT',
                  order: 1,
                  text_style: {
                    fontSize: 24,
                    textAlign: 'right',
                    color: '#22c6ff',
                    marginBottom: 10,
                  },
                  text: ' ',
                },
              },
            },
          },
        },
      },
    },
    CONTENT_VIEW: {
      key: 'CONTENT_VIEW',
      order: 2,
      component_type: 'view',
      view_style: {
        paddingTop: '0.5in',
        paddingBottom: '0.5in',
        paddingLeft: '1in',
        paddingRight: '1in',
      },
      contents: {
        LOGO_HEADER: {
          key: 'LOGO_HEADER',
          order: 1,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                LOGO_CELL: {
                  key: 'LOGO_CELL',
                  order: 1,
                  cell_style: {
                    width: 50,
                  },
                  contents: {
                    LOGO: {
                      component_type: 'static_image',
                      key: 'LOGO',
                      order: 1,
                      image_style: {
                        width: 70,
                        marginTop: 16,
                        marginBottom: 16,
                      },
                      src: logoBase64,
                    },
                  },
                },
                INVOICE_CELL: {
                  key: 'INVOICE_CELL',
                  order: 2,
                  cell_style: {
                    width: 50,
                  },
                  contents: {
                    INVOICE_TEXT: {
                      component_type: 'static_text',
                      key: 'INVOICE_TEXT',
                      order: 1,
                      text_style: {
                        fontSize: 24,
                        textAlign: 'right',
                        color: '#22c6ff',
                        marginBottom: 10,
                      },
                      text: 'QUOTE',
                    },
                    TABLE: {
                      component_type: 'static_table',
                      key: 'TABLE',
                      order: 1,
                      table_style: {
                        display: 'flex',
                        width: 100,
                        textAlign: 'right',
                      },
                      rows: {
                        ROW1: {
                          key: 'ROW1',
                          order: 1,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 60,
                                padding: 2,
                              },
                              contents: {
                                QUOTE_NUMBER_LABEL: {
                                  component_type: 'static_text',
                                  key: 'QUOTE_NUMBER_LABEL',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'QUOTE NUMBER',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 40,
                                padding: 2,
                              },
                              contents: {
                                QUOTE_NUMBER: {
                                  component_type: 'variable_text',
                                  key: 'QUOTE_NUMBER',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    color: '#000000',
                                  },
                                  variable_mapping_key: 'invoice_number',
                                },
                              },
                            },
                          },
                        },
                        ROW2: {
                          key: 'ROW2',
                          order: 2,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 60,
                                padding: 2,
                              },
                              contents: {
                                DATE: {
                                  component_type: 'static_text',
                                  key: 'DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'DATE OF ISSUE ',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 40,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_DATE: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                  },
                                  variable_mapping_key: 'invoice_date',
                                  fallback_text: ' ',
                                  fallback_text_style: {
                                    fontSize: 10,
                                    color: '#FF0000',
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ADDRESS_TABLE: {
          key: 'ADDRESS_TABLE',
          order: 2,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '3331 N. PanAm Expy',
                      text: '98 San Jacinto Blvd',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'Accounts Payable',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: 'San Antonio, TX',
                      text: '4th Floor',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'ap@etwenergy.com',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '78219',
                      text: 'Austin, TX 78701',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: '801-864-1503',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
          },
        },
        BILLED_TO_TABLE: {
          key: 'BILLED_TO_TABLE',
          order: 3,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginBottom: 32,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 16,
                  },
                  contents: {
                    BILLED_TO: {
                      component_type: 'static_text',
                      key: 'BILLED_TO',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#BBBBBB',
                      },
                      text: 'BILLED TO',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      key: 'INVOICE_DATE',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_name',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    STREET: {
                      component_type: 'variable_text',
                      key: 'STREET',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_address',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW4: {
              key: 'ROW4',
              order: 4,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    CITY: {
                      component_type: 'variable_text',
                      key: 'CITY',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                        display: 'inline-block',
                      },
                      variable_mapping_key: 'location_city_plus_state',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW5: {
              key: 'ROW5',
              order: 5,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    ZIP: {
                      component_type: 'variable_text',
                      key: 'ZIP',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_zip',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        LINE_ITEMS_TABLE: {
          key: 'LINE_ITEMS_TABLE',
          order: 5,
          component_type: 'looped_data_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          row_style: {
            flexDirection: 'row',
          },
          table_data_variable_mapping_key: 'line_items',
          columns: {
            COL0: {
              key: 'COL0',
              order: 0,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'PROJECT',
              variable_mapping_key: 'item_job_code',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL1: {
              key: 'COL1',
              order: 1,
              column_header_style: {
                width: 60,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 60,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'DESCRIPTION',
              variable_mapping_key: 'item_description',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL4: {
              key: 'COL4',
              order: 4,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'AMOUNT ($)',
              variable_mapping_key: 'item_total',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
          },
        },
        TOTAL_TABLE: {
          key: 'TOTAL_TABLE',
          order: 6,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 10,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                    borderTop: '1px solid #444444',
                    marginTop: 10,
                  },
                  contents: {
                    QUOTE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'QUOTE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        paddingTop: 18,
                        fontSize: 10,
                        textAlign: 'right',
                        color: '#BBBBBB',
                      },
                      text: 'QUOTE TOTAL',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                  },
                  contents: {
                    LINE_ITEM_TOTAL: {
                      component_type: 'variable_text',
                      key: 'LINE_ITEM_TOTAL',
                      order: 1,
                      text_style: {
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'right',
                      },
                      variable_mapping_key: 'line_item_total',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 16,
                        color: '#FF0000',
                        textAlign: 'right',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        NOTES_TABLE: {
          component_type: 'static_table',
          key: 'NOTES_TABLE',
          order: 7,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#BBBBBB',
                      },
                      text: 'NOTES:',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      key: 'NOTES',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        fontWeight: 'bold',
                      },
                      variable_mapping_key: 'line_item_notes',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TERMS_TABLE: {
          key: 'TERMS_TABLE',
          order: 8,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 40,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: 'NET10',
                      text: 'This is a quote. Invoice charges may vary and are based on actual work required.',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    borderBottom: '1px solid #444444',
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: ' ',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

export const returnProjectBasePricingLineItems = (
  projectBaseFinances: TsInterface_UnspecifiedObject,
  taskKey: string,
  projectJobCode: string,
): TsInterface_UnspecifiedObject[] => {
  // Generate List of Line Items
  let lineItems: TsInterface_UnspecifiedObject[] = []
  // TODO: Leave Permitting Fees or in or make it a true adder - only in detailed invoice template so not relevant here right now
  if (getProp(projectBaseFinances, 'billing_type', null) === 'percent') {
    // Percent to Invoice
    if (
      projectBaseFinances != null &&
      projectBaseFinances['billing_times'] != null &&
      projectBaseFinances['billing_times'][taskKey] != null &&
      projectBaseFinances['billing_times'][taskKey]['invoice_amount_percent'] != null
    ) {
      let percent = parseFloat(projectBaseFinances['billing_times'][taskKey]['invoice_amount_percent'])
      let taskName = getProp(projectBaseFinances['billing_times'][taskKey], 'associated_task_name', null)
      if (taskName != null) {
        taskName = 'Completed ' + taskName + ' - ' + percent + '% due'
      }
      if (percent > 0) {
        // Base Pricing
        for (let loopBaseKey in getProp(projectBaseFinances, 'base_pricing', [])) {
          let baseLineItem = getProp(projectBaseFinances, 'base_pricing', [])[loopBaseKey]
          if (baseLineItem != null && baseLineItem.calculated_price != null) {
            let amount = parseFloat(baseLineItem.calculated_price) * (percent / 100)
            if (amount > 0) {
              lineItems.push({
                job_code: projectJobCode,
                description: baseLineItem.name + ' (' + percent + '%)',
                amount: amount,
                // rate
                // adders
                notes: taskName,
              })
            }
          }
        }
        // Adders
        for (let loopAdderKey in getProp(projectBaseFinances, 'adder_pricing', [])) {
          let adderLineItem = getProp(projectBaseFinances, 'adder_pricing', [])[loopAdderKey]
          if (adderLineItem != null && adderLineItem.calculated_price != null) {
            let amount = parseFloat(adderLineItem.calculated_price) * (percent / 100)
            if (amount > 0) {
              lineItems.push({
                job_code: projectJobCode,
                description: adderLineItem.name + ' (' + percent + '%)',
                amount: amount,
                // rate
                // adders
              })
            }
          }
        }
      }
    }
  } else if (getProp(projectBaseFinances, 'billing_type', null) === 'deposit') {
    let billType = null
    let billAmount = null
    let taskName = null
    if (projectBaseFinances != null && projectBaseFinances['billing_times'] != null && projectBaseFinances['billing_times'][taskKey] != null) {
      billType = getProp(projectBaseFinances['billing_times'][taskKey], 'invoice_amount_type', null)
      // billAmount = getProp(projectBaseFinances['billing_times'][taskKey], 'invoice_deposit_amount', null)
      taskName = 'Completed ' + getProp(projectBaseFinances['billing_times'][taskKey], 'associated_task_name', null)
    }
    if (billType === 'deposit') {
      billAmount = getProp(projectBaseFinances['billing_times'][taskKey], 'invoice_deposit_amount', null)
      // Deposit
      lineItems.push({
        job_code: projectJobCode,
        // description: 'Deposit due for ' + taskName,
        description: 'Deposit due',
        amount: billAmount,
        // rate
        // adders
        notes: taskName,
      })
    } else if (billType === 'remainder') {
      let totalDeposits = 0
      for (let loopBillingTaskKey in getProp(projectBaseFinances, 'billing_times', {})) {
        let billingTask = getProp(projectBaseFinances, 'billing_times', {})[loopBillingTaskKey]
        if (billingTask != null && billingTask.invoice_amount_type === 'deposit' && billingTask.invoice_deposit_amount != null) {
          totalDeposits += billingTask.invoice_deposit_amount
        }
      }
      // Create Remainder Line Items
      // Base Pricing
      for (let loopBaseKey in getProp(projectBaseFinances, 'base_pricing', [])) {
        let baseLineItem = getProp(projectBaseFinances, 'base_pricing', [])[loopBaseKey]
        if (baseLineItem != null && baseLineItem.calculated_price != null) {
          let amount = parseFloat(baseLineItem.calculated_price)
          if (amount > 0) {
            lineItems.push({
              job_code: projectJobCode,
              description: baseLineItem.name,
              amount: amount,
              // rate
              // adders
              notes: taskName,
            })
          }
        }
      }
      // Adders
      for (let loopAdderKey in getProp(projectBaseFinances, 'adder_pricing', [])) {
        let adderLineItem = getProp(projectBaseFinances, 'adder_pricing', [])[loopAdderKey]
        if (adderLineItem != null && adderLineItem.calculated_price != null) {
          let amount = parseFloat(adderLineItem.calculated_price)
          if (amount > 0) {
            lineItems.push({
              job_code: projectJobCode,
              description: adderLineItem.name,
              amount: amount,
              // rate
              // adders
            })
          }
        }
      }
      // Deduct for Deposits
      lineItems.push({
        job_code: projectJobCode,
        description: 'Previous Deposits',
        amount: -1 * totalDeposits,
        // rate
        // adders
      })
    }
  }
  return lineItems
}

export const downloadBasePricingPDF = (
  clientKey: string,
  projectKey: string,
  taskKey: string,
  action: 'download' | 'base64',
  lineItemSource: 'project' | 'invoice',
) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && taskKey != null) {
      let promiseArray1: Promise<any>[] = []
      let promiseArray2: Promise<any>[] = []
      let project: TsInterface_UnspecifiedObject = {}
      let projectBaseFinances: TsInterface_UnspecifiedObject = {}
      let task: TsInterface_UnspecifiedObject = {}
      let actualInvoiceData: TsInterface_UnspecifiedObject = {}
      // Get Project Data
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
          .then((res_DGD) => {
            project = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_ProjectFinance_Document(clientKey, projectKey, 'base'))
          .then((res_DGD) => {
            projectBaseFinances = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_Task_Document(clientKey, projectKey + '_' + taskKey))
          .then((res_DGD) => {
            task = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      // After Data Loaded
      Promise.all(promiseArray1).finally(() => {
        // Basic Data
        let projectJobCode = getProp(project, 'id_number', null)
        // Get Address For Project
        let projectAddress = getProp(project, 'location_address', null)
        let projectCity = getProp(project, 'location_city', null)
        let projectState = getProp(project, 'location_state', null)
        let projectZip = getProp(project, 'location_zip', null)
        let projectBillTo = ''
        // TODO: If new options added to invoiceBillToOptions, need to add them here
        if (getProp(project, 'invoice_bill_to', null) === 'sales_partner') {
          projectBillTo = getProp(project, 'associated_sales_partner_name', null)
          if (getProp(project, 'associated_sales_partner_key', null) != null) {
            promiseArray2.push(
              DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, getProp(project, 'associated_sales_partner_key', null)))
                .then((res_DGD) => {
                  let salesPartner = res_DGD.data
                  if (
                    salesPartner.location_address != null &&
                    salesPartner.location_city != null &&
                    salesPartner.location_state != null &&
                    salesPartner.location_zip != null
                  ) {
                    projectAddress = salesPartner.location_address
                    projectCity = salesPartner.location_city
                    projectState = salesPartner.location_state
                    projectZip = salesPartner.location_zip
                  }
                })
                .catch((rej_DGD) => {
                  console.error(rej_DGD)
                }),
            )
          }
        } else if (getProp(project, 'invoice_bill_to', null) === 'customer') {
          projectBillTo = getProp(project, 'associated_customer_name', null)
        }
        // Get Actual Invoice Data
        if (lineItemSource === 'invoice') {
          promiseArray2.push(
            DatabaseGetDocument(DatabaseRef_ProjectBaseInvoice_Document(clientKey, projectKey + '_' + task.associated_task_key))
              .then((res_DGD) => {
                actualInvoiceData = res_DGD.data
              })
              .catch((rej_DGD) => {
                console.error(rej_DGD)
              }),
          )
        }
        // After Data Loaded
        Promise.all(promiseArray2).finally(() => {
          // Generate List of Line Items
          let lineItems: TsInterface_UnspecifiedObject[] = []
          let invoiceIDNumber = projectJobCode
          if (lineItemSource === 'project') {
            // Get Line Items
            lineItems = returnProjectBasePricingLineItems(projectBaseFinances, taskKey, projectJobCode)
            // Add Invoice Suffix
            if (
              projectBaseFinances != null &&
              projectBaseFinances['billing_times'] != null &&
              projectBaseFinances['billing_times'][taskKey] != null &&
              projectBaseFinances['billing_times'][taskKey]['invoice_suffix'] != null
            ) {
              invoiceIDNumber += '-' + projectBaseFinances['billing_times'][taskKey]['invoice_suffix']
            }
          } else if (lineItemSource === 'invoice') {
            // Get Line Items
            lineItems = getProp(actualInvoiceData, 'line_items', [])
            // Add Invoice Suffix
            if (actualInvoiceData != null && actualInvoiceData['invoice_suffix'] != null) {
              invoiceIDNumber += '-' + actualInvoiceData['invoice_suffix']
            }
          }
          let formattedData: TsInterface_UnspecifiedObject = {
            download_type: 'invoice',
            invoice_number: invoiceIDNumber,
            permitting_fees: null,
            // permitting_fees: invoiceData[0].permitting_fees,
            invoice_date: returnFormattedDate(getProp(task, 'timestamp_completed', new Date()), 'MM/DD/YYYY'),
            client_name: projectBillTo,
            location_address: projectAddress,
            location_city: projectCity,
            location_state: projectState,
            location_zip: projectZip,
            location_city_plus_state: projectCity + ', ' + projectState,
            job_code: projectJobCode,
            line_items: [],
            line_item_total: 0,
            line_item_notes: '',
          }
          // Loop through to generate line items
          for (let lineItemKey in lineItems) {
            let lineItem = lineItems[lineItemKey]
            // Add first permitting fee found
            if (
              lineItem.permitting_fees != null &&
              !isNaN(parseFloat(lineItem.permitting_fees)) &&
              lineItem.permitting_fees > 0 &&
              formattedData.permitting_fees == null
            ) {
              formattedData.permitting_fees = formatCurrency(parseFloat(lineItem.permitting_fees))
              formattedData.line_item_total += parseFloat(lineItem.permitting_fees)
            }
            formattedData.line_items.push({
              item_job_code: lineItem.job_code,
              item_description: lineItem.description,
              item_total: formatCurrency(lineItem.amount),
              // NEW
              item_rate: formatCurrency(lineItem.rate),
              item_adders: formatCurrency(lineItem.adders),
            })
            if (!isNaN(parseFloat(lineItem.amount))) {
              formattedData.line_item_total += parseFloat(lineItem.amount)
            }
            if (lineItem.notes) {
              formattedData.line_item_notes += lineItem.notes + '\n'
            }
          }
          formattedData.line_item_total = formatCurrency(formattedData.line_item_total)
          // TODO: Rename File?
          if (action === 'download') {
            downloadPdfFromData({ name: 'Invoice ' + invoiceIDNumber + '.pdf' }, [{ pdf_template: invoicePdfTemplate }], { data: formattedData })
            resolve({ success: true })
          } else if (action === 'base64') {
            // TODO: Implement
            returnPdfBlobFromData({ name: 'Invoice ' + invoiceIDNumber + '.pdf' }, [{ pdf_template: invoicePdfTemplate }], { data: formattedData }).then(
              (res_RPBD) => {
                convertBlobToBase64(getProp(res_RPBD, 'blob', null)).then((res_CBTB) => {
                  resolve({ success: true, data: res_CBTB, file_name: 'Invoice ' + invoiceIDNumber + '.pdf', invoice_id_number: invoiceIDNumber })
                })
              },
            )
          }
        })
      })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to download PDF'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-D-PDF-DPD-01',
        },
      })
    }
  })
}

export const downloadAdditionalWorkQuotePDF = (
  clientKey: string,
  projectKey: string,
  billTo: string,
  billableIdNumber: string,
  lineItems: TsInterface_UnspecifiedObject[],
  action: 'download' | 'base64',
) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && billTo != null && lineItems != null) {
      let promiseArray1: Promise<any>[] = []
      let promiseArray2: Promise<any>[] = []
      let project: TsInterface_UnspecifiedObject = {}
      // Get Project Data
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
          .then((res_DGD) => {
            project = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      // After Data Loaded
      Promise.all(promiseArray1).finally(() => {
        // Basic Data
        let projectJobCode = getProp(project, 'id_number', null)
        // Get Address For Project
        let projectAddress = getProp(project, 'location_address', null)
        let projectCity = getProp(project, 'location_city', null)
        let projectState = getProp(project, 'location_state', null)
        let projectZip = getProp(project, 'location_zip', null)
        let projectBillTo = ''
        // TODO: If new options added to invoiceBillToOptions, need to add them here
        if (billTo === 'sales_partner') {
          projectBillTo = getProp(project, 'associated_sales_partner_name', null)
          if (getProp(project, 'associated_sales_partner_key', null) != null) {
            promiseArray2.push(
              DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, getProp(project, 'associated_sales_partner_key', null)))
                .then((res_DGD) => {
                  let salesPartner = res_DGD.data
                  if (
                    salesPartner.location_address != null &&
                    salesPartner.location_city != null &&
                    salesPartner.location_state != null &&
                    salesPartner.location_zip != null
                  ) {
                    projectAddress = salesPartner.location_address
                    projectCity = salesPartner.location_city
                    projectState = salesPartner.location_state
                    projectZip = salesPartner.location_zip
                  }
                })
                .catch((rej_DGD) => {
                  console.error(rej_DGD)
                }),
            )
          }
        } else if (billTo === 'customer') {
          projectBillTo = getProp(project, 'associated_customer_name', null)
        }
        Promise.all(promiseArray2).finally(() => {
          let formattedData: TsInterface_UnspecifiedObject = {
            download_type: 'invoice',
            invoice_number: billableIdNumber,
            permitting_fees: null,
            // permitting_fees: invoiceData[0].permitting_fees,
            invoice_date: returnFormattedDate(new Date(), 'MM/DD/YYYY'),
            client_name: projectBillTo,
            location_address: projectAddress,
            location_city: projectCity,
            location_state: projectState,
            location_zip: projectZip,
            location_city_plus_state: projectCity + ', ' + projectState,
            job_code: projectJobCode,
            line_items: [],
            line_item_total: 0,
            line_item_notes: '',
          }
          // Loop through to generate line items
          for (let lineItemKey in lineItems) {
            let lineItem = lineItems[lineItemKey]
            // Add first permitting fee found
            if (
              lineItem.permitting_fees != null &&
              !isNaN(parseFloat(lineItem.permitting_fees)) &&
              lineItem.permitting_fees > 0 &&
              formattedData.permitting_fees == null
            ) {
              formattedData.permitting_fees = formatCurrency(parseFloat(lineItem.permitting_fees))
              formattedData.line_item_total += parseFloat(lineItem.permitting_fees)
            }
            formattedData.line_items.push({
              item_job_code: lineItem.job_code,
              item_description: lineItem.description,
              item_total: formatCurrency(lineItem.amount),
              // NEW
              item_rate: formatCurrency(lineItem.rate),
              item_adders: formatCurrency(lineItem.adders),
            })
            if (!isNaN(parseFloat(lineItem.amount))) {
              formattedData.line_item_total += parseFloat(lineItem.amount)
            }
            if (lineItem.notes) {
              formattedData.line_item_notes += lineItem.notes + '\n'
            }
          }
          formattedData.line_item_total = formatCurrency(formattedData.line_item_total)
          // TODO: Rename File?
          if (action === 'download') {
            downloadPdfFromData({ name: 'Invoice ' + billableIdNumber + '.pdf' }, [{ pdf_template: invoicePdfTemplate }], { data: formattedData })
            resolve({ success: true })
          } else if (action === 'base64') {
            // TODO: Implement
            returnPdfBlobFromData({ name: 'Invoice ' + billableIdNumber + '.pdf' }, [{ pdf_template: invoicePdfTemplate }], { data: formattedData }).then(
              (res_RPBD) => {
                convertBlobToBase64(getProp(res_RPBD, 'blob', null)).then((res_CBTB) => {
                  resolve({ success: true, data: res_CBTB, file_name: 'Invoice ' + billableIdNumber + '.pdf', invoice_id_number: billableIdNumber })
                })
              },
            )
          }
        })
      })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to download PDF'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-D-PDF-DPD-01',
        },
      })
    }
  })
}
