///////////////////////////////
// Imports
///////////////////////////////

import { themeVariables } from 'rfbp_aux/config/app_theme'
import { objectToArray } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const roundNumber = (num: number): number => {
  if (num > 0 && num < 1) return 1 // If the number is greater than 0 but less than 1, round it up to 1.
  return Math.round(num) // Otherwise, round the number to the nearest integer.
}

///////////////////////////////
// Exports
///////////////////////////////

// TEMP

export const processVehicleMilesData = (rawVehicleData: any, mondaysInDateRange: any): { summary_rows: any[]; data_rows: any[] } => {
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Normalize today's date

  const formattedData: any = {
    summary_rows: [],
    data_rows: [],
  }

  const dateHeaders: any[] = [
    {
      cellValue: 'Vehicle Usage',
      conditionalFormatting: { fontWeight: 'bold', width: '200px' },
    },
  ]

  // Step 1: Create driverDataMap with associated_user_key as key
  const driverDataMap: any = {}

  // Populate driverDataMap to include all drivers (by key) in the specified date range
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    Object.keys(rawVehicleData).forEach((week) => {
      const drivers = rawVehicleData[week].data
      Object.keys(drivers).forEach((userKey) => {
        if (drivers[userKey].associated_user_name === 'Unknown Driver') return // Skip "Unknown Driver"

        if (!driverDataMap[userKey]) {
          driverDataMap[userKey] = {
            name: drivers[userKey].associated_user_name, // Store name for display
            data: Array(objectToArray(mondaysInDateRange).length).fill('-'), // Initialize data with placeholders
          }
        }
      })
    })
  })

  // Step 2: Populate Weekly Data based on associated_user_key
  objectToArray(mondaysInDateRange).forEach((monday: string, weekIndex: number) => {
    Object.entries(rawVehicleData).forEach(([weekDate, weekData]: [string, any]) => {
      if (weekDate !== monday) return // Process only the current week data

      const weekDrivers = weekData.data
      Object.entries(weekDrivers).forEach(([userKey, driverData]: [string, any]) => {
        if (driverData.associated_user_name === 'Unknown Driver') return // Skip "Unknown Driver"

        const additionalMiles = driverData.additional_miles || 0
        let totalMilesDriven = 0
        let totalExpectedMilesWithAdditional = 0

        // Sum totals across days in the week for this driver
        Object.keys(driverData).forEach((date) => {
          const dayData = driverData[date]
          if (dayData && dayData.miles_driven !== undefined && dayData.expected_miles !== undefined) {
            totalMilesDriven += roundNumber(dayData.miles_driven)
            if (dayData.expected_miles > 0) {
              totalExpectedMilesWithAdditional += roundNumber(dayData.expected_miles + additionalMiles)
            }
          }
        })

        const difference = roundNumber(totalMilesDriven) - roundNumber(totalExpectedMilesWithAdditional)
        driverDataMap[userKey].data[weekIndex] = difference !== 0 ? difference : '-'
      })
    })
  })

  // Step 3: Build Date Headers
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    const date = new Date(monday)
    date.setDate(date.getDate() + 1) // Adjust for correct display
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
    const isFuture = date > today

    dateHeaders.push({
      cellValue: formattedDate,
      conditionalFormatting: {
        fontWeight: 'bold',
        width: '120px',
        opacity: isFuture ? 0.5 : 1,
      },
    })
  })

  // Step 4: Convert `driverDataMap` to final `data_rows`
  const dataRows = Object.keys(driverDataMap)
    .sort((a, b) => {
      const nameA = driverDataMap[a]?.name || ''
      const nameB = driverDataMap[b]?.name || ''
      return nameA.localeCompare(nameB)
    })
    .map((userKey) => {
      const driver = driverDataMap[userKey]
      return [
        {
          cellValue: driver.name, // Display name in the table
          userKey, // Add the userKey here
          conditionalFormatting: { fontWeight: 'bold', width: '200px' },
        },
        ...driver.data.map((value: any) => ({
          cellValue: value,
          conditionalFormatting: {
            backgroundColor: value === '-' ? 'inherit' : value < 0 ? themeVariables.success_main : themeVariables.error_main,
            color: value === '-' ? 'inherit' : 'white',
            width: '120px',
          },
        })),
      ]
    })

  // Step 5: Calculate Summary Row Totals
  const totalsRow: any[] = [{ cellValue: 'Total', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
  objectToArray(mondaysInDateRange).forEach((_, index: number) => {
    let totalDifference = 0

    // Sum positive values for the week
    dataRows.forEach((row: any) => {
      const cellValue = row[index + 1]?.cellValue
      const difference = typeof cellValue === 'number' && cellValue > 0 ? cellValue : 0
      totalDifference += difference
    })

    totalsRow.push({
      cellValue: totalDifference !== 0 ? totalDifference : '-',
      conditionalFormatting: {
        fontWeight: 'normal',
        width: '120px',
        backgroundColor: totalDifference > 0 ? themeVariables.error_main : totalDifference < 0 ? themeVariables.success_main : 'inherit',
        color: totalDifference !== 0 ? 'white' : 'inherit',
      },
    })
  })

  // Compile final formatted data
  formattedData.summary_rows.push(dateHeaders)
  formattedData.data_rows = dataRows
  formattedData.summary_rows.push(totalsRow)

  return formattedData
}
