///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'
import { onClickNewWindowAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { BaseInvoiceViewDialog } from '../dialogs/base_invoice_view'
import { openApproveBaseInvoiceDialog, openSendProjectBaseInvoiceEmailDialog } from '../services/invoice_progress_functions'

///////////////////////////////
// Variables
///////////////////////////////

// Cells
const invoiceJobCodeCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (rowData.associated_project_job_code != null && rowData.associated_project_key != null) {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  onClickNewWindowAppNavigation(ApplicationPages.AdminActiveProjectViewPage.url(rowData.associated_project_key as string))
                }
              }}
            >
              {rowData.associated_project_job_code}
            </Box>
          </>
        )
      } else if (rowData.associated_project_key != null) {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.warning_main, '&:hover': { background: themeVariables.warning_dark } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  onClickNewWindowAppNavigation(ApplicationPages.AdminActiveProjectViewPage.url(rowData.associated_project_key as string))
                }
              }}
            >
              {rLIB('Missing')}
            </Box>
          </>
        )
      } else {
        cellJSX = (
          <>
            <Box className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block">{rLIB('Missing')}</Box>
          </>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Job Code')
    },
    header_sort_by: null,
  },
}

const taskNameCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (rowData.associated_task_name != null) {
        let taskStatus = getProp(rowData, 'associated_task_status', null)
        let taskStatusIconJSX = <></>
        if (taskStatus === 'deleted') {
          taskStatusIconJSX = (
            <Icon
              icon="circle-exclamation"
              sx={{ color: themeVariables.error_main }}
              tooltip={rLIB('Task Deleted')}
              tooltipPlacement="right"
              className="tw-ml-2"
            />
          )
        } else if (taskStatus === 'completed') {
          taskStatusIconJSX = (
            <Icon
              icon="circle-check"
              sx={{ color: themeVariables.success_main }}
              tooltip={rLIB('Task Completed')}
              tooltipPlacement="right"
              className="tw-ml-2"
            />
          )
        } else {
          taskStatusIconJSX = (
            <Icon
              icon="circle-info"
              sx={{ color: themeVariables.info_main }}
              tooltip={taskStatus}
              tooltipPlacement="right"
              className="tw-ml-2"
            />
          )
        }
        cellJSX = (
          <>
            <Box>
              {rowData.associated_task_name}
              {taskStatusIconJSX}
            </Box>
          </>
        )
      } else {
        cellJSX = (
          <>
            <Box>{rLIB('Missing Task Name')}</Box>
          </>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Task Name')
    },
    header_sort_by: null,
  },
}

const viewProjectIdNumberCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (rowData.id_number != null) {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  onClickNewWindowAppNavigation(ApplicationPages.AdminActiveProjectViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.id_number}
            </Box>
          </>
        )
      } else {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.warning_main, '&:hover': { background: themeVariables.warning_dark } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  onClickNewWindowAppNavigation(ApplicationPages.AdminActiveProjectViewPage.url(rowData.key as string))
                }
              }}
            >
              {rLIB('Missing')}
            </Box>
          </>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Job Code')
    },
    header_sort_by: null,
  },
}

const statusCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      const rJSX_TaskStatusIcon = (taskStatus: string): JSX.Element => {
        let iconJSX = <></>
        switch (taskStatus) {
          case 'unapproved':
            iconJSX = (
              <Icon
                icon="circle-pause"
                className="tw-mr-2"
                tooltip={rLIB('Pending Approval')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.warning_main }}
              />
            )
            break
          case 'approved':
            iconJSX = (
              <Icon
                icon="circle-play"
                className="tw-mr-2"
                tooltip={rLIB('Approved')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.success_main }}
              />
            )
            break
          case 'completed':
            iconJSX = (
              <Icon
                icon="circle-check"
                className="tw-mr-2"
                tooltip={rLIB('Task Completed but not yet billed')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.primary_main }}
              />
            )
            break
          case 'billed':
            iconJSX = (
              <Icon
                icon="circle-envelope"
                className="tw-mr-2"
                tooltip={rLIB('Billed')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.info_main }}
              />
            )
            break
          case 'paid':
            iconJSX = (
              <Icon
                icon="circle-dollar"
                className="tw-mr-2"
                tooltip={rLIB('Paid')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.success_main }}
              />
            )
            break
          case 'cancelled':
            iconJSX = (
              <Icon
                icon="circle-trash"
                className="tw-mr-2"
                tooltip={rLIB('Cancelled')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.error_main }}
              />
            )
            break
          default:
            iconJSX = (
              <Icon
                icon="circle-question"
                className="tw-mr-2"
                tooltip={rLIB('Unknown Status')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.gray_500 }}
              />
            )
            break
        }
        return iconJSX
      }
      let cellJSX = <Box>{rJSX_TaskStatusIcon(rowData.status as string)}</Box>
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <></>
    },
    header_sort_by: null,
  },
}

const hardCodePendingStatusCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = (
        <Box>
          <Icon
            icon="circle-pause"
            className="tw-mr-2"
            tooltip={rLIB('Pending Approval')}
            tooltipPlacement="right"
            sx={{ fontSize: '20px', color: themeVariables.warning_main }}
          />
        </Box>
      )
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <></>
    },
    header_sort_by: null,
  },
}

// Manage Actions
const viewProject = {
  icon: (
    <Icon
      type="solid"
      icon="magnifying-glass"
    />
  ),
  label: <>{rLIB('View Project')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      onClickNewWindowAppNavigation(ApplicationPages.AdminActiveProjectViewPage.url(rowData.key as string))
    }
  },
}

const approveBaseProjectQuote = {
  icon: (
    <Icon
      type="solid"
      icon="badge-check"
    />
  ),
  label: <>{rLIB('Approve Base Project Quote')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          openApproveBaseInvoiceDialog(res_GCK.clientKey, rowData.key as string, tableHooks.uc_setUserInterface_CustomDialogDisplay)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          tableHooks.uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: rej_GCK.error,
          })
        })
    }
  },
}

const viewBaseProjectInvoice = {
  icon: (
    <Icon
      type="solid"
      icon="magnifying-glass"
    />
  ),
  label: <>{rLIB('View Invoice')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          tableHooks.uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: (
                <BaseInvoiceViewDialog
                  clientKey={res_GCK.clientKey}
                  invoiceKey={rowData.key as string}
                />
              ),
            },
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          tableHooks.uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: rej_GCK.error,
          })
        })
    }
  },
}

const billCompletedBaseInvoice = {
  icon: (
    <Icon
      type="solid"
      icon="paper-plane"
    />
  ),
  label: <>{rLIB('Email Invoice')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          openSendProjectBaseInvoiceEmailDialog(
            res_GCK.clientKey,
            rowData.associated_project_key as string,
            rowData.associated_task_key as string,
            rowData.key as string,
            rowData,
            tableHooks.uc_setUserInterface_CustomDialogDisplay,
            tableHooks.uc_setUserInterface_ErrorDialogDisplay,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          tableHooks.uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: rej_GCK.error,
          })
        })
    }
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_ProjectsPendingInvoiceApproval: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewProject,
    approve: approveBaseProjectQuote,
  }),
  status: hardCodePendingStatusCell,
  id_number: viewProjectIdNumberCell,
  timestamp_invoice_generated: TableCellTimestamp(
    'timestamp_invoice_generated',
    rLIB('Invoice Generated'),
    'timestamp_invoice_generated',
    'D MMM YYYY h:mm a',
    false,
  ),
  associated_customer_name: TableCellBasic('associated_customer_name', rLIB('Customer Name'), 'associated_customer_name'),
  associated_sales_partner_name: TableCellBasic('associated_sales_partner_name', rLIB('Sales Partner'), 'associated_sales_partner_name'),
  invoice_status: TableCellBasic('invoice_status', rLIB('Invoice Status'), 'invoice_status'),
}

export const tableColumns_ProjectsMissingInvoiceApproval: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewProject,
  }),
  status: hardCodePendingStatusCell,
  id_number: viewProjectIdNumberCell,
  associated_customer_name: TableCellBasic('associated_customer_name', rLIB('Customer Name'), 'associated_customer_name'),
  associated_sales_partner_name: TableCellBasic('associated_sales_partner_name', rLIB('Sales Partner'), 'associated_sales_partner_name'),
  invoice_status: TableCellBasic('invoice_status', rLIB('Invoice Status'), 'invoice_status'),
}

export const tableColumns_ApprovedInvoices: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewBaseProjectInvoice,
  }),
  status: statusCell,
  associated_project_job_code: invoiceJobCodeCell,
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Invoice ID Number'), 'invoice_id_number'),
  associated_task_name: taskNameCell,
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Invoice Generated'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
  total_price: TableCellCurrency('total_price', rLIB('Total Price'), 'total_price'),
}

export const tableColumns_CompletedInvoices: TsInterface_TableColumns = {
  manage: TableCellManage({
    bill: billCompletedBaseInvoice,
    view: viewBaseProjectInvoice,
  }),
  status: statusCell,
  associated_project_job_code: invoiceJobCodeCell,
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Invoice ID Number'), 'invoice_id_number'),
  associated_task_name: taskNameCell,
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Invoice Generated'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
  timestamp_task_completed: TableCellTimestamp('timestamp_task_completed', rLIB('Task Completed'), 'timestamp_task_completed', 'D MMM YYYY h:mm a', false),
  total_price: TableCellCurrency('total_price', rLIB('Total Price'), 'total_price'),
}

export const tableColumns_BilledInvoices: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewBaseProjectInvoice,
  }),
  status: statusCell,
  associated_project_job_code: invoiceJobCodeCell,
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Invoice ID Number'), 'invoice_id_number'),
  associated_task_name: taskNameCell,
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Invoice Generated'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
  timestamp_task_completed: TableCellTimestamp('timestamp_task_completed', rLIB('Task Completed'), 'timestamp_task_completed', 'D MMM YYYY h:mm a', false),
  timestamp_invoice_billed: TableCellTimestamp('timestamp_invoice_billed', rLIB('Invoice Emailed'), 'timestamp_invoice_billed', 'D MMM YYYY h:mm a', false),
  total_price: TableCellCurrency('total_price', rLIB('Total Price'), 'total_price'),
}

export const tableColumns_PaidInvoices: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewBaseProjectInvoice,
  }),
  status: statusCell,
  associated_project_job_code: invoiceJobCodeCell,
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Invoice ID Number'), 'invoice_id_number'),
  associated_task_name: taskNameCell,
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Invoice Generated'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
  timestamp_task_completed: TableCellTimestamp('timestamp_task_completed', rLIB('Task Completed'), 'timestamp_task_completed', 'D MMM YYYY h:mm a', false),
  timestamp_invoice_billed: TableCellTimestamp('timestamp_invoice_billed', rLIB('Invoice Emailed'), 'timestamp_invoice_billed', 'D MMM YYYY h:mm a', false),
  timestamp_invoice_paid: TableCellTimestamp('timestamp_invoice_paid', rLIB('Invoice Paid'), 'timestamp_invoice_paid', 'D MMM YYYY h:mm a', false),
  total_price: TableCellCurrency('total_price', rLIB('Total Price'), 'total_price'),
}

export const tableColumns_CancelledInvoices: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewBaseProjectInvoice,
  }),
  status: statusCell,
  associated_project_job_code: invoiceJobCodeCell,
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Invoice ID Number'), 'invoice_id_number'),
  associated_task_name: taskNameCell,
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Invoice Generated'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
  timestamp_cancelled: TableCellTimestamp('timestamp_cancelled', rLIB('Invoice Cancelled'), 'timestamp_cancelled', 'D MMM YYYY h:mm a', false),
  total_price: TableCellCurrency('total_price', rLIB('Total Price'), 'total_price'),
}

export const tableSettings_InvoiceProjects: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'id_number',
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  no_data_message: rLIB('No projects') as JSX.Element,
  searchable: false,
  hide_pagination: false,
}

export const tableSettings_BaseInvoices: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'timestamp_created',
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  no_data_message: rLIB('No invoices') as JSX.Element,
  searchable: false,
  hide_pagination: false,
}
