///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Autocomplete,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format } from 'date-fns'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
  DatabaseRef_DeductionsByDate_Query,
  DatabaseRef_Deductions_Collection,
  DatabaseRef_Deductions_Document,
  DatabaseRef_Deduction_Lookup_Collection,
  DatabaseRef_Deduction_Lookup_Document,
} from 'rfbp_aux/services/database_endpoints/operations/performance_management'
import { DatabaseRef_ActiveTeamsByType_Query } from 'rfbp_aux/services/database_endpoints/operations/schedules'
import { DatabaseRef_ScheduledTasksForTeamOnDate_Query, DatabaseRef_TaskForSpecificProject_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseDeleteDocument,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  StorageUploadFile,
} from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { dynamicSort, getProp, objectToArray } from '../../../rfbp_core/services/helper_functions/index'

import { sendAppPushNotification } from 'app/services/external_requests/external_requests'
import { StorageRef_PerformanceManagementImages } from 'rfbp_aux/services/storage_endpoints/performance_management'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['PerformanceManagementPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

const currentDate = new Date()
const firstDayOfSixMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1, 0, 0, 0, 0)

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  const [us_selectedStartDate, us_setSelectedStartDate] = useState<Date>(firstDayOfSixMonthsAgo)
  const [us_selectedEndDate, us_setSelectedEndDate] = useState<Date>(currentDate)
  const [us_evaluatorName, us_setEvaluatorName] = useState() // Assuming "John Doe" as the logged-in evaluator
  const [us_evaluatorKey, us_setEvaluatorKey] = useState() // Assuming "John Doe" as the logged-in evaluator
  const [us_dateOfObservation, us_setDateOfObservation] = useState(() => {
    const currentDate = new Date()
    return format(currentDate, 'yyyy-MM-dd')
  })
  const [us_selectedCrew, us_setSelectedCrew] = useState<string | null>(null)
  const [us_selectedForeman, us_setSelectedForeman] = useState<string>('')
  const [us_deductionReason, us_setDeductionReason] = useState<string>('')
  const [us_notes, us_setNotes] = useState<string>('')
  const [us_selectedTeamKey, us_setSelectedTeamKey] = useState<string>('')
  const [us_selectedTeamRegion, us_setSelectedTeamRegion] = useState<string>('')
  const [us_selectedTeamRegionName, us_setSelectedTeamRegionName] = useState<string>('')
  const [us_selectedProjectKey, us_setSelectedProjectKey] = useState<string | null>(null)
  const [us_activeCrews, us_setActiveCrews] = useState<TsInterface_UnspecifiedObject>({})
  const [us_processedActiveCrews, us_setprocessedActiveCrews] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedCrewsProjects, us_setSelectedCrewsProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_deductionLookup, us_setDeductionLookup] = useState<TsInterface_UnspecifiedObject>({})
  const [us_deductions, us_setDeductions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_deductionPanels, us_setDeductionPanels] = useState<number>()
  const [us_deductionReasonName, us_setDeductionReasonName] = useState<string>('')
  const [us_selectedRegion, us_setSelectedRegion] = useState<string>('ALL_REGIONS')
  const [us_dialogOpen, us_setDialogOpen] = useState(false)
  const [us_selectedDeduction, us_setSelectedDeduction] = useState<any>(null)
  const [us_errorMessage, us_setErrorMessage] = useState<string | null>(null)
  const [us_uploadedUrls, us_setUploadedUrls] = useState<string[]>([])
  const [us_datePickerOpen, us_setDatePickerOpen] = useState<boolean>(false)
  const [us_regionsLists, us_setRegionsLists] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedTasks, us_setSelectedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredTasks, us_setFilteredTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedTaskKey, us_setSelectedTaskKey] = useState<string | null>()
  const [us_selectedImage, us_setSelectedImage] = useState<string | null>(null)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_selectedCrewMembers, us_setSelectedCrewMembers] = useState<TsInterface_UnspecifiedObject>({})

  console.log('uc_RootData_ClientUser:', uc_RootData_ClientUser)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Performance Management', false) as string
  }, [])

  //Get Regions
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionsLists(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    us_setEvaluatorName(getProp(uc_RootData_ClientUser, 'name', ''))
    us_setEvaluatorKey(getProp(uc_RootData_ClientUser, 'key', ''))
  }, [uc_RootData_ClientUser])

  // Use useEffect to auto-fill the first project when the crew is selected
  useEffect(() => {
    if (us_selectedCrew && us_selectedCrewsProjects) {
      const firstProject = Object.values(us_selectedCrewsProjects).find((project: TsInterface_UnspecifiedObject) => project.associated_project_key)

      if (firstProject && firstProject.associated_project_key) {
        us_setSelectedProjectKey(firstProject.associated_project_key) // Ensure we're setting associated_project_key
      }
    }
  }, [us_selectedCrew, us_selectedCrewsProjects])

  // Get Active Crews
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // Convert the object to an array, sort it alphabetically by crew name, and then convert it back to an object
      const sortedData = Object.fromEntries(
        Object.entries(newData).sort(([keyA, valueA], [keyB, valueB]) => {
          const nameA = valueA.name.toLowerCase()
          const nameB = valueB.name.toLowerCase()
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
        }),
      )

      us_setActiveCrews(sortedData) // Assuming you have a state to store this data
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTeamsByType_Query(res_GCK.clientKey, 'install_crew'), updateLiveData)
      })
      .catch((rej_GCK) => {})

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey])

  // Process crews
  useEffect(() => {
    if (us_activeCrews && Object.keys(us_activeCrews).length > 0) {
      const processedData = Object.values(us_activeCrews).map((crew: any) => {
        // Find all foremen with a valid name (both sow_foreman and install_foreman)
        const foremen = Object.keys(crew.associated_member_roles)
          .filter(
            (key) =>
              (crew.associated_member_roles[key] === 'sow_foreman' || crew.associated_member_roles[key] === 'install_foreman') &&
              crew.associated_member_names[key],
          )
          .map((key) => ({
            foremanKey: key,
            foremanName: crew.associated_member_names[key],
          }))

        return {
          name: crew.name,
          key: crew.key,
          associated_region_key: crew.associated_region_key,
          foremen, // Array of foremen with their keys and names
        }
      })

      us_setprocessedActiveCrews(processedData)
    }
  }, [us_activeCrews])

  //Get Deduction Values
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // Convert the object to an array, sort it alphabetically by deduction name, and then convert it back to an object
      const sortedData = Object.fromEntries(
        Object.entries(newData).sort(([keyA, valueA], [keyB, valueB]) => {
          const nameA = valueA.deduction_name.toLowerCase()
          const nameB = valueB.deduction_name.toLowerCase()
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
        }),
      )

      us_setDeductionLookup(sortedData) // Assuming you have a state to store this data
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Deduction_Lookup_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {})

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey])

  // Get Deductions
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // Filter the data to only include records with status = 'active' and the selected region
      const activeDeductions = Object.fromEntries(
        Object.entries(newData).filter(([key, value]: [string, any]) => {
          const isActive = value.status === 'active'
          const isInRegion = us_selectedRegion === 'ALL_REGIONS' || value.associated_region_key === us_selectedRegion
          return isActive && isInRegion
        }),
      )

      us_setDeductions(activeDeductions) // Assuming you have a state to store this data
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_DeductionsByDate_Query(res_GCK.clientKey, us_selectedStartDate, us_selectedEndDate),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {})

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, us_selectedRegion])

  // Get projects for selected crew
  useEffect(() => {
    if (!us_selectedTeamKey) return // Ensure team key is available

    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedCrewsProjects(newData) // Assuming you have a state to store this data
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_ScheduledTasksForTeamOnDate_Query(res_GCK.clientKey, us_dateOfObservation, us_selectedTeamKey),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {})

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedTeamKey, us_dateOfObservation])

  // UseEffect to fetch tasks after a project is selected
  useEffect(() => {
    if (!us_selectedProjectKey) return

    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveTasksData = (newTasksData: TsInterface_UnspecifiedObject) => {
      us_setSelectedTasks(newTasksData) // Assuming newTasksData is an array of tasks
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_TaskForSpecificProject_Query(res_GCK.clientKey, us_selectedProjectKey), // Adjust your query function for tasks
          updateLiveTasksData,
        )
      })
      .catch((error) => {
        console.error('Error fetching tasks:', error)
        us_setSelectedTasks([]) // Clear tasks if there's an error
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedProjectKey])

  useEffect(() => {
    if (!us_selectedTasks || !us_dateOfObservation) return

    const filteredTasks = objectToArray(us_selectedTasks).filter((task: TsInterface_UnspecifiedObject) => {
      return task.task_completion_scheduled_dates?.some(
        (date: string) => date === us_dateOfObservation, // Check if the task contains the selected date
      )
    })

    us_setFilteredTasks(filteredTasks) // Update state with filtered tasks
  }, [us_selectedTasks, us_dateOfObservation])

  // Auto-select the first task when a project is selected and filtered tasks are available
  useEffect(() => {
    if (us_selectedProjectKey && us_filteredTasks.length > 0) {
      // Auto-select the first task
      const firstTask = us_filteredTasks[0]
      if (firstTask) {
        us_setSelectedTaskKey(firstTask.associated_task_key)
      }
    }
  }, [us_selectedProjectKey, us_filteredTasks])

  //set the crew members for the selected task
  useEffect(() => {
    if (!us_selectedTaskKey || !us_filteredTasks) return

    // Find the selected task from the filtered tasks
    const selectedTask = us_filteredTasks.find((task: any) => task.associated_task_key === us_selectedTaskKey)

    if (selectedTask) {
      // Assuming crew members are part of the task data as an array
      const taskCompletionTeam = selectedTask.task_completion_scheduled_team_names[us_dateOfObservation] || {}
      const teamMembers = Object.keys(taskCompletionTeam).map((memberKey) => ({
        key: memberKey,
        name: taskCompletionTeam[memberKey],
      }))

      // Set the crew members in a state variable to display them
      us_setSelectedCrewMembers(teamMembers)
    }
  }, [us_selectedTaskKey, us_filteredTasks, us_dateOfObservation]) // Add dependencies

  const changeCalendarStartDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedStartDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const changeCalendarEndDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedEndDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const rJSX_StartDatePicker = (disabled: boolean): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={us_selectedStartDate}
            onChange={(newDate) => changeCalendarStartDate(newDate as any)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={rLIB('Start Date')}
                fullWidth
                sx={{ width: '170px' }} // Adjust width here
                InputLabelProps={{
                  shrink: true, // Ensure the label stays above the input
                  required: false, // Remove the asterisk
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { cursor: disabled ? 'default' : 'pointer', height: '36px' }, // Adjust height here
                }}
                disabled={disabled}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const rJSX_EndDatePicker = (disabled: boolean): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={us_selectedEndDate}
            onChange={(newDate) => changeCalendarEndDate(newDate as any)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={rLIB('End Date')}
                fullWidth
                sx={{ width: '170px' }} // Adjust width here
                InputLabelProps={{
                  shrink: true, // Ensure the label stays above the input
                  required: false, // Remove the asterisk
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { cursor: disabled ? 'default' : 'pointer', height: '36px' }, // Adjust height here
                }}
                disabled={disabled}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const handleRemoveCrewMember = (memberKey: string) => {
    // Filter out the member by their key
    const updatedCrewMembers = us_selectedCrewMembers.filter((member: any) => member.key !== memberKey)

    // Update the state to trigger re-render
    us_setSelectedCrewMembers(updatedCrewMembers)
  }

  const formInputs_NewDeduction: TsInterface_FormInputs = {
    deduction_name: {
      data_type: 'string',
      input_type: 'text_basic',
      key: 'deduction_name',
      label: rLIB('Name'),
      required: true,
    },
    deduction_amount: {
      data_type: 'number',
      input_type: 'text_basic',
      key: 'deduction_amount',
      label: rLIB('Amount'),
      required: true,
    },
  }

  const rJSX_NewDeductionButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createDeduction(uc_setUserInterface_FormDialogDisplay)
        }}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('Coaching Category')}
      </Button>
    )
  }

  const createDeduction = (uc_setUserInterface_FormDialogDisplay: any): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewDeduction,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              try {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    const clientKey = res_GCK.clientKey

                    DatabaseAddDocument(DatabaseRef_Deduction_Lookup_Collection(clientKey), formSubmittedData, true)
                      .then((res_DSMD) => {
                        resolve({ success: true })

                        // Additional logic upon successful creation can be added here
                      })
                      .catch((rej_DSMD) => {
                        console.error('Error creating deduction:', rej_DSMD)
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error('Error retrieving client key:', rej_GCK)
                    reject(rej_GCK)
                  })
              } catch (error) {
                console.error('Unexpected error during form submission:', error)
                reject(error)
              }
            }).catch((error) => {
              console.error('Error in form submission:', error)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Create Coaching Category')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const rJSX_recordDeduction = (): JSX.Element => {
    let deductionLogKey = uuidv4()

    const resetForm = () => {
      us_setDateOfObservation(format(new Date(), 'yyyy-MM-dd'))
      us_setSelectedCrew('')
      us_setSelectedTeamKey('')
      us_setSelectedForeman('')

      us_setSelectedProjectKey(null)
      us_setSelectedTaskKey(null)
      us_setFilteredTasks([])
      us_setDeductionReason('')
      us_setDeductionReasonName('')
      us_setDeductionPanels(0)
      us_setNotes('')
      us_setUploadedUrls([]) // Reset the uploaded URLs
      us_setErrorMessage(null)
      us_setSelectedTasks([]) // Reset the tasks
      us_setSelectedTaskKey(null) // Reset selected task
      us_setSelectedCrewMembers({}) // Reset crew members
    }

    const handleCrewChange = (event: React.SyntheticEvent, newValue: string | null) => {
      us_setSelectedCrew(newValue)

      const crewData = objectToArray(us_processedActiveCrews).find((crew: any) => crew.name === newValue)

      if (crewData) {
        us_setSelectedTeamKey(crewData.key)
        us_setSelectedTeamRegion(crewData.associated_region_key)

        const regionData = us_regionsLists[crewData.associated_region_key]
        us_setSelectedTeamRegionName(regionData?.name || '')

        if (crewData.foremen.length === 1) {
          us_setSelectedForeman(crewData.foremen[0].foremanName)
        } else if (crewData.foremen.length > 1) {
          us_setSelectedForeman(crewData.foremen[0].foremanName)
        }
      }
    }

    const handleJobCodeChange = (event: SelectChangeEvent<string>) => {
      const selectedAssociatedProjectKey = event.target.value
      us_setSelectedProjectKey(selectedAssociatedProjectKey)
    }

    const handleTaskChange = (event: SelectChangeEvent<string>) => {
      const selectedTaskKey = event.target.value
      us_setSelectedTaskKey(selectedTaskKey) // Only set the selected task key
    }

    const handleDeductionChange = (event: SelectChangeEvent<string>) => {
      const selectedDeductionKey = event.target.value
      us_setDeductionReason(selectedDeductionKey)

      const us_selectedDeduction = us_deductionLookup[selectedDeductionKey]

      if (us_selectedDeduction) {
        us_setDeductionReasonName(us_selectedDeduction.deduction_name || '')
        us_setDeductionPanels(us_selectedDeduction.deduction_amount || 0)
      } else {
        us_setDeductionReasonName('')
        us_setDeductionPanels(0)
      }
    }

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): Promise<any> => {
      return new Promise((resolve, reject) => {
        if (event.target.files) {
          uploadFiles(event, additionalFileUploadParams)
            .then((urls) => {
              us_setUploadedUrls((prevUrls) => [...prevUrls, ...urls]) // Save the uploaded URLs to state
              resolve(urls) // Resolve the promise with the uploaded URLs
            })
            .catch((error) => {
              console.error('Error uploading files:', error)
              reject(error) // Reject the promise with the error
            })
        } else {
          reject('No files selected') // Reject the promise if no files are selected
        }
      })
    }

    const removeImage = (url: string) => {
      us_setUploadedUrls((prevUrls) => prevUrls.filter((prevUrl) => prevUrl !== url))
    }
    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault()
      us_setErrorMessage(null)

      // Ensure us_selectedProjectKey is not null
      if (!us_selectedProjectKey) {
        us_setErrorMessage('Please select a valid project.')
        console.error('Error: No project key selected')
        return
      }

      // Find the selected project by matching the associated_project_key
      const selectedProject = Object.values(us_selectedCrewsProjects).find((project: any) => project.associated_project_key === us_selectedProjectKey)

      if (!selectedProject) {
        us_setErrorMessage('Please select a valid project.')
        console.error('Error: No project found for selected key')
        return
      }

      const selectedTask = us_filteredTasks.find((task: any) => task.associated_task_key === us_selectedTaskKey)

      if (!selectedTask) {
        us_setErrorMessage('Please select a valid task.')
        console.error('Error: No task found for selected key')
        return
      }

      // Use the updated crew members from the state
      const teamMembers = us_selectedCrewMembers.map((member: any) => ({
        key: member.key,
        name: member.name,
      }))

      // Prepare deduction data, using the updated team members
      const deductionData = {
        associated_evaluator_name: us_evaluatorName,
        associated_evaluator_key: us_evaluatorKey,
        deduction_date: us_dateOfObservation,
        associated_team_name: us_selectedCrew,
        associated_team_key: us_selectedTeamKey,
        associated_foreman_name: us_selectedForeman,
        associated_project_key: selectedProject.associated_project_key,
        associated_project_id_number: selectedProject.associated_project_id_number,
        associated_deduction_name_key: us_deductionReason,
        associated_deduction_name: us_deductionReasonName,
        associated_deduction_amount: us_deductionPanels,
        associated_region_key: us_selectedTeamRegion,
        associated_region_name: us_selectedTeamRegionName,
        associated_task_key: us_selectedTaskKey,
        associated_task_name: selectedTask.name,
        associated_task_crew_members: teamMembers, // Use updated crew members here
        notes: us_notes,
        status: 'active',
        urls: us_uploadedUrls,
      }

      // Save the deduction data
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          const clientKey = res_GCK.clientKey
          DatabaseAddDocument(DatabaseRef_Deductions_Collection(clientKey), deductionData, true)
            .then((res_DSMD) => {
              resetForm() // Reset the form after submission

              //send notification to crew members in app when deduction is recorded
              const crewMemberKeys = us_selectedCrewMembers.map((member: any) => member.key)
              sendAppPushNotification(
                crewMemberKeys,
                'feedback',
                'New Feedback Recorded',
                `New feedback has been recorded for the project ${selectedProject.associated_project_id_number}`,
                getProp(uc_RootData_ClientUser, 'key', null),
                us_selectedProjectKey ?? null,
                us_selectedTaskKey ?? null,
                {},
                false,
              )
            })
            .catch((error) => {
              console.error('Error saving deduction:', error)
            })
        })
        .catch((error) => {
          console.error('Error getting client key:', error)
        })
    }

    const selectedCrewData = objectToArray(us_processedActiveCrews).find((crew: any) => crew.name === us_selectedCrew)

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Card className="tw-p-4 tw-mt-4">
          <Box>
            <form onSubmit={handleSubmit}>
              <Box mb={2}>
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Typography
                    variant="h6"
                    component="span"
                    style={{ marginRight: '8px' }}
                  >
                    {rLIB('Evaluator Name: ')}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="span"
                  >
                    {us_evaluatorName}
                  </Typography>
                </Box>
              </Box>
              <Box mb={2}>
                <DatePicker
                  label="Date of Observation"
                  value={new Date(us_dateOfObservation + 'T00:00:00')}
                  onChange={(newDate: any) => us_setDateOfObservation(format(newDate, 'yyyy-MM-dd'))}
                  onOpen={() => us_setDatePickerOpen(true)}
                  onClose={() => us_setDatePickerOpen(false)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => us_setDatePickerOpen(true)} // Opens the date picker when clicking on the text field
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true, // This prevents any text input
                        style: { cursor: 'pointer' }, // Make the entire field clickable without text selection
                      }}
                    />
                  )}
                  open={us_datePickerOpen}
                />
              </Box>

              <Box mb={2}>
                <FormControl
                  fullWidth
                  required
                >
                  <Autocomplete
                    value={us_selectedCrew}
                    onChange={handleCrewChange}
                    options={objectToArray(us_processedActiveCrews).map((crew: any) => crew.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Crew Name"
                        required
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl
                  fullWidth
                  required
                >
                  <InputLabel>{rLIB('Foreman Name')}</InputLabel>
                  <Select
                    value={us_selectedForeman}
                    onChange={(e) => us_setSelectedForeman(e.target.value)}
                    disabled={!us_selectedCrew || selectedCrewData?.foremen.length === 0}
                    label="Foreman Name"
                  >
                    {selectedCrewData?.foremen.map((foreman: any) => (
                      <MenuItem
                        key={foreman.foremanKey}
                        value={foreman.foremanName}
                      >
                        {foreman.foremanName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl
                  fullWidth
                  required
                >
                  <InputLabel>{rLIB('Job Code')}</InputLabel>
                  <Select
                    value={us_selectedProjectKey || ''}
                    onChange={handleJobCodeChange}
                    disabled={!us_selectedCrew || !Object.keys(us_selectedCrewsProjects).length}
                    label="Job Code"
                  >
                    {Object.values(us_selectedCrewsProjects).map((project: any) => (
                      <MenuItem
                        key={project.associated_project_key}
                        value={project.associated_project_key}
                      >
                        {project.associated_project_id_number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl
                  fullWidth
                  required
                >
                  <InputLabel>{rLIB('Task')}</InputLabel>
                  <Select
                    value={us_selectedTaskKey || ''}
                    onChange={handleTaskChange}
                    disabled={!us_selectedProjectKey || Object.keys(us_filteredTasks).length === 0}
                    label="Task"
                  >
                    {Object.values(us_filteredTasks).map((task: any) => (
                      <MenuItem
                        key={task.associated_task_key}
                        value={task.associated_task_key}
                      >
                        {task.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box mb={2}>
                <TextField
                  label="Crew Members"
                  disabled={!us_selectedProjectKey || Object.keys(us_filteredTasks).length === 0}
                  fullWidth
                  InputProps={{
                    readOnly: true, // Non-editable input
                    startAdornment: (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap', // Ensure chips wrap only when necessary
                          gap: 1,
                          width: '100%', // Take full width of the TextField
                        }}
                      >
                        {objectToArray(us_selectedCrewMembers).map((member: any) => (
                          <Chip
                            key={member.key}
                            label={member.name}
                            onDelete={() => handleRemoveCrewMember(member.key)} // Add remove functionality
                            sx={{
                              'backgroundColor': '#333',
                              'color': 'white',
                              '& .MuiChip-deleteIcon': {
                                color: 'white', // Make delete icon white
                              },
                            }}
                          />
                        ))}
                      </Box>
                    ),
                  }}
                  sx={{
                    '.MuiInputBase-root': {
                      paddingTop: '12px', // Ensure the padding is large enough for chips
                      paddingBottom: '12px',
                      paddingRight: '12px',
                    },
                  }}
                />
              </Box>

              <Box mb={2}>
                <FormControl
                  fullWidth
                  required
                >
                  <InputLabel>{rLIB('Category')}</InputLabel>
                  <Select
                    value={us_deductionReason}
                    onChange={handleDeductionChange}
                    label="Category"
                  >
                    {objectToArray(us_deductionLookup).map((deduction: any) => (
                      <MenuItem
                        key={deduction.key}
                        value={deduction.key}
                      >
                        {deduction.deduction_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <TextField
                  label="Amount"
                  value={us_deductionPanels || 0}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label="Notes"
                  value={us_notes}
                  onChange={(e) => us_setNotes(e.target.value)}
                  multiline
                  rows={1}
                  fullWidth
                />
              </Box>
              {us_errorMessage && (
                <Box
                  mb={2}
                  display="flex"
                  alignItems="center"
                >
                  <Typography color={themeVariables.error_main}>{us_errorMessage}</Typography>
                </Box>
              )}
              <Box mb={2}>
                <FileUploadButton
                  multiple={true}
                  button={{
                    text: <Typography>{rLIB('Upload Photos')}</Typography>,
                    icon: (
                      <Icon
                        icon="cloud-arrow-up"
                        sx={{ fontSize: '20px' }}
                      />
                    ),
                    color: 'info',
                    className: 'tw-mr-2 bp_icon_only_button',
                    variant: 'contained',
                    disabled: false,
                  }}
                  accept="*"
                  onChange={handleFileUpload}
                  additionalFileUploadParams={{
                    deductionLogKey: deductionLogKey,
                  }}
                />
              </Box>
              {us_uploadedUrls.length > 0 && (
                <Box mb={2}>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={2}
                    mt={1}
                  >
                    {us_uploadedUrls.map((url, index) => (
                      <Box
                        key={index}
                        sx={{
                          position: 'relative',
                          width: '100px',
                          height: '100px',
                          cursor: 'default',
                        }}
                      >
                        <Box
                          component="img"
                          src={url}
                          alt={`Preview ${index + 1}`}
                          sx={{
                            'width': '100%',
                            'height': '100%',
                            'objectFit': 'cover',
                            'borderRadius': '4px',
                            '&:hover': {
                              opacity: 0.8,
                            },
                          }}
                        />
                        <IconButton
                          size="small"
                          onClick={() => removeImage(url)}
                          sx={{
                            'position': 'absolute',
                            'top': '4px',
                            'right': '4px',
                            'backgroundColor': 'rgba(0, 0, 0, 0.5)',
                            'color': 'white',
                            'zIndex': 2, // Ensure the delete button is above the image
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                          }}
                        >
                          <Icon
                            icon="times"
                            sx={{ fontSize: '16px' }}
                          />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
              <Box>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                >
                  {rLIB('Submit')}
                </Button>
              </Box>
            </form>
          </Box>
        </Card>
      </LocalizationProvider>
    )
  }

  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(file)
    })
  }

  const uploadFiles = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: Promise<any>[] = []
        let promiseArray2: Promise<any>[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (file != null && typeof file === 'object') {
            promiseArray.push(
              readAsDataURL(file)
                .then((res_RADURL) => {
                  readFiles[fileIndex] = {
                    file_name: file.name,
                    file: file,
                    data_url: res_RADURL,
                  }
                })
                .catch((rej_RADURL) => {
                  // Handle error if necessary
                }),
            )
          }
        }

        Promise.all(promiseArray)
          .then(() => {
            let us_uploadedUrls: string[] = []
            for (let loopFileKey in readFiles) {
              let loopFile = readFiles[loopFileKey]
              promiseArray2.push(
                StorageUploadFile(
                  StorageRef_PerformanceManagementImages(
                    uc_RootData_ClientKey as any,
                    additionalFileUploadParams.deductionLogKey as string,
                    loopFile.file_name,
                  ),
                  loopFile.file,
                  {
                    clientKey: uc_RootData_ClientKey,
                    deductionLogKey: additionalFileUploadParams.deductionLogKey,
                  },
                )
                  .then((res_SUF: any) => {
                    const url = getProp(res_SUF, 'url', null)
                    if (url != null) {
                      us_uploadedUrls.push(url)
                    }
                  })
                  .catch((rej_SUF) => {
                    console.error(rej_SUF)
                    reject(rej_SUF)
                  }),
              )
            }

            Promise.all(promiseArray2)
              .then(() => {
                resolve(us_uploadedUrls) // Resolve the outer promise with the array of uploaded URLs
              })
              .catch((err) => {
                console.error('Error uploading files:', err)
                reject(err) // Reject the outer promise if an error occurs during file uploads
              })
          })
          .catch((err) => {
            console.error('Error reading files:', err)
            reject(err) // Reject the outer promise if an error occurs while reading files
          })
      } else {
        reject({
          success: false,
          error: {
            message: 'Failed to upload files',
            details: 'invalid file selection',
            code: 'ER-D-TFS-FOS-02',
          },
        })
      }
    })
  }

  const resetForm = () => {
    us_setDateOfObservation(format(new Date(), 'yyyy-MM-dd'))
    us_setSelectedCrew('')
    us_setSelectedTeamKey('')
    us_setSelectedForeman('')

    us_setSelectedProjectKey(null)
    us_setSelectedTaskKey(null)
    us_setFilteredTasks([])
    us_setDeductionReason('')
    us_setDeductionReasonName('')
    us_setDeductionPanels(0)
    us_setNotes('')
    us_setUploadedUrls([]) // Reset the uploaded URLs
    us_setErrorMessage(null)
    us_setSelectedTasks([]) // Reset the tasks
    us_setSelectedTaskKey(null) // Reset selected task
    us_setSelectedCrewMembers({}) // Reset crew members
  }
  const rJSX_resetDeductionButton = (): JSX.Element => {
    let rJSX_button = (
      <Button
        variant="outlined"
        color="secondary"
        onClick={resetForm}
        style={{ marginLeft: '10px' }}
      >
        Reset
      </Button>
    )

    return rJSX_button
  }

  const deletePastDeduction = (rowData: any, tableHooks: TsInterface_TableHooks) => {
    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Feedback Record'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure you want to delete this deduction?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                const clientKey = res_GCK.clientKey

                // Update the status of the deduction to 'deleted'
                const updatedData = { status: 'deleted' }

                DatabaseUpdateDocument(DatabaseRef_Deductions_Document(clientKey, rowData.key as string), updatedData)
                  .then((res_DSDU) => {
                    resolve({ success: true })
                  })
                  .catch((rej_DSDU) => {
                    reject(rej_DSDU)
                  })
              })
              .catch((rej_GCK) => {
                console.error('Error retrieving client key:', rej_GCK)
                reject(rej_GCK)
              })
          }).catch((error) => {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
          })
        },
      },
    })
  }

  const tableSettings_Past_Deductions: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'desc', // Default sorting direction
    sort_property: 'deduction_date', // Default sorting property
    use_live_data: true,
  }

  const tableColumns_Past_Deductions: TsInterface_TableColumns = {
    manage: TableCellManage({
      view: {
        icon: (
          <Icon
            icon="eye"
            type="solid"
          />
        ),
        label: <>{rLIB('View')}</>,
        onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          if (rowData != null) {
            viewPastDeduction(rowData)
          }
        },
      },
      delete: {
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        label: <>{rLIB('Delete Feedback Record')}</>,
        onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
            deletePastDeduction(rowData, tableHooks)
          }
        },
      },
    }),
    associated_evaluator_name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Evaluator Name')
        },
        header_sort_by: 'associated_evaluator_name',
      },
      cell: {
        cell_jsx: (rowData: any) => <>{rowData ? rowData.associated_evaluator_name : 'N/A'}</>,
      },
    },
    deduction_date: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Feedback Date')
        },
        header_sort_by: 'deduction_date',
      },
      cell: {
        cell_jsx: (rowData: any) => <>{rowData ? rowData.deduction_date : 'N/A'}</>,
      },
    },
    associated_team_name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Team Name')
        },
        header_sort_by: 'associated_team_name',
      },
      cell: {
        cell_jsx: (rowData: any) => <>{rowData ? rowData.associated_team_name : 'N/A'}</>,
      },
    },
    associated_task_crew_members: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Crew Members')
        },
        header_sort_by: 'associated_task_crew_members',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          if (!rowData || !rowData.associated_task_crew_members) {
            return 'N/A'
          }

          // Sort the crew members by name in alphabetical order
          const sortedCrewMembers = rowData.associated_task_crew_members.sort((a: any, b: any) => a.name.localeCompare(b.name))

          // Display crew members as a comma-separated list
          return sortedCrewMembers.map((member: any) => member.name).join(', ')
        },
      },
    },

    associated_deduction_name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Coaching Category')
        },
        header_sort_by: 'associated_deduction_name',
      },
      cell: {
        cell_jsx: (rowData: any) => <>{rowData ? rowData.associated_deduction_name : 'N/A'}</>,
      },
    },
    associated_deduction_amount: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Amount')
        },
        header_sort_by: 'associated_deduction_amount',
      },
      cell: {
        cell_jsx: (rowData: any) => <>{rowData ? rowData.associated_deduction_amount : 'N/A'}</>,
      },
    },
    associated_project_id_number: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Job Code')
        },
        header_sort_by: 'associated_project_id_number',
      },
      cell: {
        cell_jsx: (rowData: any) => <>{rowData ? rowData.associated_project_id_number : 'N/A'}</>,
      },
    },
    associated_task_name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Name')
        },
        header_sort_by: 'associated_task_name',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          return rowData?.associated_task_name ? rowData.associated_task_name : 'N/A'
        },
      },
    },

    associated_region_name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Region')
        },
        header_sort_by: 'associated_region_name',
      },
      cell: {
        cell_jsx: (rowData: any) => <>{rowData ? rowData.associated_region_name : 'N/A'}</>,
      },
    },
  }

  const viewPastDeduction = (rowData: any) => {
    us_setSelectedDeduction(rowData) // Set the selected deduction data to the state
    us_setDialogOpen(true) // Open the dialog to view the details
  }

  // JSX for the Dialog component
  const rJSX_DeductionDialog = () => (
    <Dialog
      open={us_dialogOpen}
      onClose={() => us_setDialogOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {us_selectedDeduction ? `${us_selectedDeduction.associated_team_name} - ${us_selectedDeduction.deduction_date}` : rLIB('View Deduction')}
      </DialogTitle>
      <DialogContent>
        <Card>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{rLIB('Evaluator Name')}</TableCell>
                <TableCell>{us_selectedDeduction?.associated_evaluator_name || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{rLIB('Feedback Date')}</TableCell>
                <TableCell>{us_selectedDeduction?.deduction_date || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{rLIB('Team Name')}</TableCell>
                <TableCell>{us_selectedDeduction?.associated_team_name || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{rLIB('Crew Members')}</TableCell>
                <TableCell>
                  {us_selectedDeduction?.associated_task_crew_members
                    ? us_selectedDeduction.associated_task_crew_members
                        .sort((a: any, b: any) => a.name.localeCompare(b.name)) // Sort alphabetically
                        .map((member: any) => member.name)
                        .join(', ')
                    : 'N/A'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{rLIB('Coaching Category')}</TableCell>
                <TableCell>{us_selectedDeduction?.associated_deduction_name || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{rLIB('Amount')}</TableCell>
                <TableCell>{us_selectedDeduction?.associated_deduction_amount || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{rLIB('Job Code')}</TableCell>
                <TableCell>{us_selectedDeduction?.associated_project_id_number || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{rLIB('Task Name')}</TableCell>
                <TableCell>{us_selectedDeduction?.associated_task_name || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{rLIB('Region')}</TableCell>
                <TableCell>{us_selectedDeduction?.associated_region_name || 'N/A'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{rLIB('Notes')}</TableCell>
                <TableCell>{us_selectedDeduction?.notes || ''}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
        <Box mt={2}>
          {us_selectedDeduction?.urls?.length > 0 ? (
            <>
              <Typography variant="h6">{rLIB('Uploaded Images')}</Typography>
              <Box
                display="flex"
                flexWrap="wrap"
                gap={2}
                mt={1}
              >
                {us_selectedDeduction.urls.map((url: string, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      'position': 'relative',
                      'width': '100px',
                      'height': '100px',
                      'cursor': 'pointer',
                      'overflow': 'hidden',
                      'borderRadius': '4px',
                      '&:hover .overlay': {
                        opacity: 1,
                      },
                    }}
                    onClick={() => handleImageClick(url)}
                  >
                    <Box
                      component="img"
                      src={url}
                      alt={`Image ${index + 1}`}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <Box
                      className="overlay"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        opacity: 0,
                        transition: 'opacity 0.3s',
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="white"
                        align="center"
                      >
                        {rLIB('View')}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <Typography>{rLIB('No images uploaded')}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => us_setDialogOpen(false)}
          color="primary"
        >
          {rLIB('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  )

  const rJSX_RegionDropdown = (shrinkButton: boolean): JSX.Element => {
    // JSX
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <InputLabel id={'region_filter'}>{rLIB('Region')}</InputLabel>
          <Select
            id={'region_filter'}
            labelId={'region_filter'}
            color="primary"
            value={us_selectedRegion}
            label={rLIB('Region')}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedRegion(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_REGIONS'}>{rLIB('All Regions')}</MenuItem>
            {objectToArray(us_regionsLists)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  // Past us_deductions
  const rJSX_pastDeductions = (): JSX.Element => {
    return (
      <Card className=" tw-p-4 tw-mt-4">
        <Box className="tw-mt-4">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_Past_Deductions}
            tableData={objectToArray(us_deductions)}
            tableSettings={tableSettings_Past_Deductions}
          />
        </Box>

        {/* Render the deduction dialog */}
        {rJSX_DeductionDialog()}
        {rJSX_ImageDialog()}
      </Card>
    )
  }

  const handleImageClick = (url: string) => {
    us_setSelectedImage(url)
  }

  const handleClosePreviewDialog = () => {
    us_setSelectedImage(null)
  }
  // Full-size image dialog
  const rJSX_ImageDialog = () => (
    <Dialog
      open={!!us_selectedImage}
      onClose={handleClosePreviewDialog}
      maxWidth="md"
    >
      <DialogContent>
        {us_selectedImage && (
          <Box
            component="img"
            src={us_selectedImage} // Ensure this is a string, not null
            alt="Selected Image"
            sx={{
              width: '100%',
              height: 'auto',
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClosePreviewDialog}
          color="primary"
        >
          {rLIB('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  )

  const editDeduction = (rowData: any, tableHooks: TsInterface_TableHooks) => {
    tableHooks.uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: rowData, // Prefill the form with current data
          formInputs: formInputs_NewDeduction, // Use the same form inputs for editing
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  const clientKey = res_GCK.clientKey

                  DatabaseSetMergeDocument(DatabaseRef_Deduction_Lookup_Document(clientKey, rowData.key as string), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            }).catch((error) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Coaching Category')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableSettings_Performance_Management: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'desc',
    sort_property: 'timestamp_created',
    use_live_data: true,
  }

  const tableColumns_Deductions: TsInterface_TableColumns = {
    manage: TableCellManage({
      view: {
        icon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
        label: <>{rLIB('Edit')}</>,
        onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          if (rowData.key != null) {
            editDeduction(rowData, tableHooks)
          } else {
            console.warn('No key found for rowData:', rowData)
          }
        },
      },
      delete: {
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        label: <>{rLIB('Delete')}</>,
        onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
            tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
              display: true,
              confirm: {
                color: 'error',
                header: rLIB('Delete Category'),
                icon: (
                  <Icon
                    icon="trash"
                    type="solid"
                  />
                ),
                submit_text: rLIB('Delete'),
                text: rLIB('Are you sure that you want to delete this category?'),
                submit_callback: () => {
                  return new Promise((resolve, reject) => {
                    DatabaseDeleteDocument(DatabaseRef_Deduction_Lookup_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string))
                      .then((res_DSMD) => {
                        resolve({ success: true })
                      })
                      .catch((rej_DSMD) => {
                        console.error('Error deleting deduction:', rej_DSMD)
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                },
              },
            })
          } else {
            console.warn('Cannot delete. Missing key or clientKey:', rowData, tableHooks.uc_RootData_ClientKey)
          }
        },
      },
    }),
    deduction_name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Name')
        },
        header_sort_by: 'deduction_name',
      },
      cell: {
        cell_jsx: (rowData: any) => <>{rowData ? rowData.deduction_name : 'N/A'}</>,
      },
    },
    deduction_amount: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Amount')
        },
        header_sort_by: 'deduction_amount',
      },
      cell: {
        cell_jsx: (rowData: any) => <>{rowData ? rowData.deduction_amount : 'N/A'}</>,
      },
    },
  }

  // Deduction values
  const rJSX_deductionValues = (): JSX.Element => {
    return (
      <Card className="tw-p-4 tw-mt-4">
        <Box>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_Deductions}
            tableData={objectToArray(us_deductionLookup)}
            tableSettings={tableSettings_Performance_Management}
          />
        </Box>
      </Card>
    )
  }

  // Tabs
  const rJSX_PerformanceManagementTabs = (): JSX.Element => {
    return (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'Record_Deduction',
            tabHeader: rLIB('New Feedback'),
            tabContent: rJSX_recordDeduction(),
            tabButtons: [{ fullJSX: rJSX_resetDeductionButton(), minJSX: rJSX_resetDeductionButton(), sizeCutoff: 0 }],
          },
          {
            tabUrlKey: 'Past_Deductions',
            tabHeader: rLIB('Feedback Record'),
            tabContent: rJSX_pastDeductions(),
            tabButtons: [
              { fullJSX: rJSX_StartDatePicker(false), minJSX: rJSX_StartDatePicker(false), sizeCutoff: 0 },
              { fullJSX: rJSX_EndDatePicker(false), minJSX: rJSX_EndDatePicker(false), sizeCutoff: 0 },
              { fullJSX: rJSX_RegionDropdown(false), minJSX: rJSX_RegionDropdown(false), sizeCutoff: 0 },
            ],
          },
          {
            tabUrlKey: 'Coaching Type',
            tabHeader: rLIB('Coaching Type'),
            tabContent: rJSX_deductionValues(),
            tabButtons: [{ fullJSX: rJSX_NewDeductionButton(), minJSX: rJSX_NewDeductionButton(), sizeCutoff: 0 }],
          },
        ]}
        tabsSettings={{
          baseUrl: ApplicationPages.PerformanceManagementPage.url(),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: 'Performance Management',
        }}
      />
    )
  }

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    return (
      <Box>
        <AuthenticatedContainer
          pageHeader={rLIB('Performance Management')}
          pageKey={pageKey}
          content={rJSX_PerformanceManagementTabs()}
        />
        {/* <Json data={us_filteredTasks}></Json>
        <Json data={us_selectedCrewsProjects}></Json> */}
      </Box>
    )
  }

  // Render
  return <>{rJSX_Page()}</>
}
