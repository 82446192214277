///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'
import {
  generateDatabaseArrayContainsAnyQuery,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { formatDateToYYYYMMDD } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Exports
///////////////////////////////

// Main
export const DatabaseRef_CancelledScheduledTasks_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'cancelled_scheduled_tasks', 'main')
}

export const DatabaseRef_CancelledScheduledTask_Document = (clientKey: string, taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'cancelled_scheduled_tasks', 'main', taskKey)
}

// Queries
export const DatabaseRef_CancelledScheduledTasksInDateRange_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  // let dateKeyArray: string[] = []
  // for (let loopTime = startDate.getTime(); loopTime <= endDate.getTime() + 3600000; loopTime += 86400000) {
  //   let loopDate = new Date(loopTime)
  //   let loopDateKey = formatDateToYYYYMMDD(loopDate)
  //   dateKeyArray.push(loopDateKey)
  // }
  let dateKeyArray: string[] = []
  let daylightSavingsDetected = false
  let nextDateKey = ''
  for (let loopTime = startDate.getTime(); loopTime <= endDate.getTime() + 3600000; loopTime += 86400000) {
    let loopDate = new Date(loopTime)
    let loopDateKey = formatDateToYYYYMMDD(loopDate)
    // Check and see if the date is in the array already for daylight savings
    if (!dateKeyArray.includes(loopDateKey)) {
      daylightSavingsDetected = true
      dateKeyArray.push(loopDateKey)
    }
    nextDateKey = formatDateToYYYYMMDD(new Date(loopTime + 86400000))
  }
  // If daylight savings was detected, increment and add one more date key
  if (daylightSavingsDetected) {
    dateKeyArray.push(nextDateKey)
  }
  return generateDatabaseArrayContainsAnyQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'cancelled_scheduled_tasks', 'main'),
    'task_completion_scheduled_dates',
    dateKeyArray,
  )
}

export const DatabaseRef_CancelledScheduledTasksInDateRangeArray_Query = (clientKey: string, dateKeyArray: string[]) => {
  return generateDatabaseArrayContainsAnyQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'cancelled_scheduled_tasks', 'main'),
    'task_completion_scheduled_dates',
    dateKeyArray,
  )
}

export const DatabaseRef_CancelledScheduledTasks_LastUpdated_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_last_updated', comparator: '>=', value: startDate },
    { prop: 'timestamp_last_updated', comparator: '<=', value: endDate },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_last_updated', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'cancelled_scheduled_tasks', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_CancelledScheduledTasksOnDateForSpecificUser_Query = (clientKey: string, dateKey: string, userKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'task_completion_scheduled_team_keys.' + dateKey + '.' + userKey, comparator: '==', value: true },
  ]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'cancelled_scheduled_tasks', 'main'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}

export const DatabaseRef_AllCancelledScheduledTasksForSpecificTask_Query = (clientKey: string, taskKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_task_key', comparator: '==', value: taskKey }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'cancelled_scheduled_tasks', 'main'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}

export const DatabaseRef_AllCancelledScheduledTasksForSpecificTaskAndProject_Query = (clientKey: string, taskKey: string, projectKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_task_key', comparator: '==', value: taskKey },
    { prop: 'associated_project_key', comparator: '==', value: projectKey },
  ]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'cancelled_scheduled_tasks', 'main'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}
