/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:
      - Phone number to get in
      - Saving local storage "auth"
      - Create Analytics Session
      - Save Data to Analytics Session

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  LinearProgress,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Grid2 from '@mui/material/Unstable_Grid2'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_UnprotectedOpportunityUpdateTime_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic, TsInterface_MapMarker, TsInterface_MapMarkers, TsInterface_MapSettings } from 'rfbp_core/components/map'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import validator from 'validator'
import { formatNumberToDollar } from './components/helper'
import { TsInterface_LeadData, TsInterface_UnauthenticatedShownSections, TsInterface_UtilityCompanyMap } from './interfaces/i_sales_tools'

import CookieConsent from 'react-cookie-consent'
// @ts-expect-error - TODO: reason for error
import logoSrc from '../../images/logos/logo_temp.png'
import { EagleviewProposePanelsVisualization, TsInterface_EagleviewVisualizationCallbackData } from './components/eagleview_propose_panels_visualization'

///////////////////////////////
// Typescript
///////////////////////////////
interface ContainerProps {
  isAuthenticatedUser: boolean
}
///////////////////////////////
// Variables
///////////////////////////////
const DEFAULT_EPC = 2.5
const PANEL_WATT_CAPACITY = 405 // note that this matches the value used to query eagleview on the server side
const FEDERAL_INCENTIVE_RATE = 0.3
const KWH_RATE = 0.09
const PHONE_NUMBER = '(312) 728-3403'

const sectionMap: TsInterface_UnspecifiedObject = {
  ['System Size']: 'system_size',
  ['Battery']: 'battery',
  ['Roof Replacement']: 'roof_replacement',
  ['Electrical Upgrades']: 'electrical_upgrades',
  ['Remove Trees']: 'remove_trees',
  ['Ground Mount']: 'ground_mount',
}

const illinoisUtilityCompanies = {
  'Ameren Illinois': 'A',
  'Commonwealth Edison Co': 'B',
  'Tri-County Electric Coop, Inc': 'A',
  'Village of Chatham': 'A',
  'City Water Light & Power': 'A',
  'Rural Electric Conven Coop': 'A',
  'City of Highland': 'A',
  'Menard Electric Coop': 'A',
  'Wayne-White Counties Elec Coop': 'A',
  'Corn Belt Energy Corporation': 'A',
  'Southwestern Electric Coop Inc': 'A',
  'Southern Illinois Elec Coop': 'A',
  'Rock Energy Cooperative': 'B',
  'Shelby Electric Coop, Inc': 'A',
  'City of Batavia': 'B',
}

const utilityCompanyMap: TsInterface_UtilityCompanyMap = {
  IL: illinoisUtilityCompanies,
}

const black = '#000000'
const white = '#ffffff'
const teal = '#1E83A1'
const lightTeal = '#33b3d9'
const darkTeal = '#166076'
const lightGray = '#F4F7F9'
const lightGray2 = '#dbe5eb'
const darkGray = '#9C9C9E'
const green = '#1ea13c'
const lightGreen = '#33d95e'
const yellow = '#f7a830'

let pageCSS: string = `
		body {
			background: ${lightGray};
		}
	`

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: teal,
      light: lightTeal,
      dark: darkTeal,
      contrastText: white,
    },
  },
})

///////////////////////////////
// Functions
///////////////////////////////
const orderEagleViewProposeData = async (clientKey: string, opportunityKey: string, formSubmittedData: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    cloudFunctionUnauthenticatedRequests({
      function: 'generateNewOpportunityData',
      client_key: clientKey,
      opportunity_key: opportunityKey,
      formSubmittedData: formSubmittedData,
      generateFlags: {
        google: false,
        ev_prospect: false,
        ev_propose: true,
      },
    })
      .then((res_CFUR) => {
        resolve(res_CFUR)
      })
      .catch((rej_CFUR) => {
        reject(rej_CFUR)
      })
  })
}

const saveSessionData = (
  clientKey: string,
  opportunityKey: string,
  analyticsSessionKey: string,
  sessionData: TsInterface_UnspecifiedObject,
  saveAnalyticsSessionData: boolean,
) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && opportunityKey != null && analyticsSessionKey != null && saveAnalyticsSessionData === true) {
      cloudFunctionUnauthenticatedRequests({
        function: 'saveSalesOpportunitySessionData',
        client_key: clientKey,
        opportunity_key: opportunityKey,
        session_key: analyticsSessionKey,
        session_data: sessionData,
      })
        .then((res_CFUR) => {
          resolve(res_CFUR)
        })
        .catch((rej_CFUR) => {
          reject(rej_CFUR)
        })
    } else {
      resolve({ success: false })
    }
  })
}

const returnStandardizedUSPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber != null) {
    // Remove all non-numeric characters from the input
    const numericOnly = phoneNumber.replace(/\D/g, '')
    // Check if the number is 10 digits long (without country code)
    // or 11 digits long (with country code)
    if (numericOnly.length === 10) {
      // If it's 10 digits, assume it's a US number without the country code
      return `${numericOnly}`
    } else if (numericOnly.length === 11 && numericOnly.startsWith('1')) {
      // If it's 11 digits and starts with '1', remove the '1' and assume it's a US number with the country code
      return `${numericOnly.substring(1)}`
    } else {
      // If the number does not conform to expected formats, return an error message
      return 'Invalid phone number'
    }
  } else {
    return 'Invalid phone number'
  }
}

function shuffleArray(array: any[]) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

function formatTime(seconds: number) {
  const minutes = Math.floor(seconds / 60) // Get whole minutes
  const remainingSeconds = seconds % 60 // Get remaining seconds
  const paddedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds // Pad seconds with zero if less than 10

  return `${minutes}:${paddedSeconds}` // Format in m:ss
}

const RotatingLoadingMessages = () => {
  const [us_currentMessageIndex, us_setCurrentMessageIndex] = useState<number>(0)
  const [us_messagesArray, us_setMessagesArray] = useState<string[]>([])

  const secondsBetweenMessages = 5

  useEffect(() => {
    // Shuffle Messages
    const messages = [
      'Harness the power of the sun!',
      'Save on your electricity bills',
      'Reduce your carbon footprint',
      'Go green with solar energy',
      'Customize your own solar solution',
      'Solar panels for a sustainable future',
      'Energy independence is just a step away',
      'Invest in renewable energy',
      'Solar power: Clean, green, and efficient',
      'Discover the benefits of solar energy',
      'The future is solar-powered',
      'Start saving with solar today',
      'A bright idea: Solar energy!',
      'Solar panels: The eco-friendly choice',
      'Design your dream solar system',
      'Solar energy: Reliable and renewable',
      'Enjoy the freedom of solar power',
      'Solar panels pay for themselves',
      'Experience the power of the sun',
      'Join the solar revolution!',
    ]
    us_setMessagesArray(shuffleArray(messages))
    return () => {}
  }, [])

  useEffect(() => {
    // Interval to rotate messages
    const intervalId = setInterval(() => {
      us_setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % us_messagesArray.length)
    }, secondsBetweenMessages * 1000)
    // Clear Interval
    return () => {
      clearInterval(intervalId)
    }
  }, [us_messagesArray])

  return <Box>{us_messagesArray[us_currentMessageIndex]}</Box>
}

///////////////////////////////
// Container
///////////////////////////////
export const Container: React.FC<ContainerProps> = ({ isAuthenticatedUser }): JSX.Element => {
  // Hooks
  const params = useParams()
  const clientKey: string = 'etw_energy'
  const opportunityKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_hideEditPanelLayoutButton, us_setHideEditPanelLayoutButton] = useState<boolean>(false)
  const [us_hideEditUtilityButton, us_setHideEditUtilityButton] = useState<boolean>(false)
  const [us_hideOffsetSlider, us_setHideOffsetSlider] = useState<boolean>(false)
  const [us_selectedFinancingType, us_setSelectedFinancingType] = useState<string>('cash')
  const [us_editModeIsActive, us_setEditModeIsActive] = useState<boolean>(false)
  const [us_tcpaAccepted, us_setTcpaAccepted] = useState<boolean>(false)
  const [us_submitted, us_setSubmitted] = useState<boolean>(false)
  const [us_submittedDate, us_setSubmittedDate] = useState<Date | null>(null)
  const [us_submittedSize, us_setSubmittedSize] = useState<number>(0)
  const [us_submittedProduction, us_setSubmittedProduction] = useState<number>(0)
  const [us_submittedNumPanels, us_setSubmittedNumPanels] = useState<number>(0)
  const [us_submittedFinancingType, us_setSubmittedFinancingType] = useState<string>('')
  const [us_submittedPrice, us_setSubmittedPrice] = useState<number>(0)
  const [us_showFinalSubmissionModal, us_setShowFinalSubmissionModal] = useState<boolean>(false)
  const [us_showHouseConfirmationModal, us_setShowHouseConfirmationModal] = useState<boolean>(false)
  const [us_leadData, us_setLeadData] = useState<TsInterface_LeadData | undefined>(undefined)
  const [us_federalIncentive, us_setFederalIncentive] = useState<number>(0)
  const [us_srecIncentive, us_setSrecIncentive] = useState<number>(0)
  const [us_showUtilityCompanyConfirmationModal, us_setShowUtilityCompanyConfirmationModal] = useState<boolean>(true)
  const [us_showUtilityBillConfirmationModal, us_setShowUtilityBillConfirmationModal] = useState<boolean>(false)
  const [us_analyticsSessionKey] = useState<string>(uuidv4())
  const [us_propertyCoordinates, us_setPropertyCoordinates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_savingInitialActivePanels, us_setSavingInitialActivePanels] = useState<boolean>(false)
  const [us_activeProposePanels, us_setActiveProposePanels] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_updatingPricingNumbers, us_setUpdatingPricingNumbers] = useState<boolean>(false)
  const [us_leasePricingError, us_setLeasePricingError] = useState<boolean>(false)
  const [us_customerNameChanged, us_setCustomerNameChanged] = useState<boolean>(false)
  const [us_customerEmailChanged, us_setCustomerEmailChanged] = useState<boolean>(false)
  const [us_customerPhoneChanged, us_setCustomerPhoneChanged] = useState<boolean>(false)
  const [us_electricityBillChanged, us_setElectricityBillChanged] = useState<boolean>(false)
  const [us_confirmedPhoneNumber, us_setConfirmedPhoneNumber] = useState<boolean>(false)
  const [us_dataLoadedStatus, us_setDataLoadedStatus] = useState<string>('loading')
  const [us_initialPageLoadTime] = useState<Date>(new Date())
  const [us_initialSessionSaved, us_setInitialSessionSaved] = useState<boolean>(false)
  const [us_userPhoneNumber, us_setUserPhoneNumber] = useState<string>('')
  const [us_utilityCompanies, us_setUtilityCompanies] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_systemRegressionSliderData, us_setSystemRegressionSliderData] = useState<TsInterface_UnspecifiedObject[] | []>([])
  const [us_selectedSystemSizeInWatts, us_setSelectedSystemSizeInWatts] = useState<number>(0)
  const [us_selectedSystemAnnualProductionInKWh, us_setSelectedSystemAnnualProductionInKwh] = useState<number>(0)
  const [us_customerName, us_setCustomerName] = useState<string>('')
  const [us_customerEmail, us_setCustomerEmail] = useState<string>('')
  const [us_customerPhone, us_setCustomerPhone] = useState<string>('')
  const [us_electricityBill, us_setElectricityBill] = useState<number>(200)
  const [us_numPanels, us_setNumPanels] = useState(0)
  const [us_numGroundMounts, us_setNumGroundMounts] = useState<number>(0)
  const [us_numBatteries, us_setNumBatteries] = useState<number>(0)
  const [us_baseCashCost, us_setBaseCashCost] = useState<number>(0)
  const [us_currentCashCost, us_setCurrentCashCost] = useState<number>(0)
  const [us_cashSavings, us_setCashSavings] = useState<number>(0)
  const [us_leaseMonthlySavings, us_setLeaseMonthlySavings] = useState<number>(0)
  const [us_leaseCumulativeSavings, us_setLeaseCumulativeSavings] = useState<number>(0)
  const [us_roofCost, us_setRoofCost] = useState<number>(0)
  const [us_batteryCost, us_setBatteryCost] = useState<number>(0)
  const [us_leaseCost, us_setLeaseCost] = useState<number>(0)
  const [us_loadingEagleviewProposeDataStartTime, us_setLoadingEagleviewProposeDataStartTime] = useState<number>(0)
  const [us_loadingEagleviewProposeDataSecondsSinceStart, us_setLoadingEagleviewProposeDataSecondsSinceStart] = useState<number>(0)
  const [us_utilityCompanyName, us_setUtilityCompanyName] = useState<string>('Commonwealth Edison Co') // TODO: change so that this is based on zip code
  const [us_roofSizeInFeet, us_setRoofSizeInFeet] = useState(0)
  const [us_EPC, us_setEPC] = useState<number>(DEFAULT_EPC)
  // const [us_lightreachTable, us_setLightreachTable] = useState<TsInterface_UnspecifiedObject>(null)
  const [us_leasePricingErrorCount, us_setLeasePricingErrorCount] = useState(0)
  const [us_offset, us_setOffset] = useState<number>(100)
  const [us_showEditUtilityModal, us_setShowEditUtilityModal] = useState<boolean>(false)
  const [us_showContactInfoModal, us_setShowContactInfoModal] = useState<boolean>(false)
  const [us_alreadySentEmail, us_setAlreadySentEmail] = useState<boolean>(false)
  const [us_adders, us_setAdders] = useState({
    batteries: false,
    ground_mount: false,
    roof_replacement: false,
    electrical_upgrades: false,
    remove_trees: false,
  })
  const [us_shownSections, us_setShownSections] = useState<TsInterface_UnauthenticatedShownSections>({
    system_size: true,
    battery: true,
    roof_replacement: true,
    electrical_upgrades: true,
    remove_trees: true,
    ground_mount: true,
  })
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ul_queryParams = new URLSearchParams(useLocation().search)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)

  // Hooks - useCallback
  const loadPageData = useCallback((clientKey: string, opportunityKey: string) => {
    cloudFunctionUnauthenticatedRequests({
      function: 'getSalesOpportunityData',
      client_key: clientKey,
      opportunity_key: opportunityKey,
    })
      .then((res_CFUR: any) => {
        console.log(res_CFUR)

        if (res_CFUR != null && res_CFUR.data != null) {
          us_setLeadData(res_CFUR.data) // TsInterface_LeadData
          // Load Prospect Data if it's missing
          if (
            (!res_CFUR.data.eagleviewProspectData || Object.keys(res_CFUR.data.eagleviewProspectData).length === 0) &&
            (res_CFUR.data.opportunityData.first_page_data_source === 'eagleview_prospect' || us_dataLoadedStatus === 'loading_eagleview_propose_data')
          ) {
            cloudFunctionUnauthenticatedRequests({
              function: 'generateNewOpportunityData',
              client_key: clientKey,
              opportunity_key: opportunityKey,
              formSubmittedData: res_CFUR.data.opportunityData,
              generateFlags: {
                google: false,
                ev_prospect: true,
                ev_propose: false,
              },
            })
            return // wait until we have the prospect data loaded
          }
          // Eagleview Data
          if (
            res_CFUR !== null &&
            res_CFUR.data !== null &&
            res_CFUR.data.eagleviewProspectData != null &&
            res_CFUR.data.eagleviewProspectData.ev_slider_data != null &&
            res_CFUR.data.opportunityData !== null &&
            (res_CFUR.data.opportunityData.first_page_data_source === 'eagleview_prospect' || us_dataLoadedStatus === 'loading_eagleview_propose_data')
          ) {
            let evRegressionData: TsInterface_UnspecifiedObject[] = []
            // use propose data if we have it for the regression
            if (!res_CFUR.data.eagleviewProposeData) {
              evRegressionData = getEagleViewSliderData(getEagleViewSystemSizesAndProductionRatios(res_CFUR.data.eagleviewProspectData.ev_slider_data))
              us_setSystemRegressionSliderData(evRegressionData)
            } else {
              evRegressionData = getEagleViewProposeSliderData(res_CFUR.data.eagleviewProposeData.dataOutput.energyTable)
              us_setSystemRegressionSliderData(evRegressionData)
            }
          } else if (
            res_CFUR !== null &&
            res_CFUR.data !== null &&
            res_CFUR.data.googleSolarData != null &&
            res_CFUR.data.googleSolarData.solarPotential != null
          ) {
            us_setSystemRegressionSliderData(getGoogleRegressionSliderData(res_CFUR.data.googleSolarData))
          }
          // Opportunity Data
          // we check to see if it is loading propose data because if it is, then the state will be set above in the eagleview data section
          if (res_CFUR.data.opportunityData != null) {
            res_CFUR.data.opportunityData.confirmed ? us_setShowUtilityCompanyConfirmationModal(false) : us_setShowUtilityCompanyConfirmationModal(true)
            us_setShownSections(res_CFUR.data.opportunityData.shown_sections ? res_CFUR.data.opportunityData.shown_sections : us_shownSections)
            us_setUtilityCompanyName(
              res_CFUR.data.opportunityData.associated_utility_company_name
                ? res_CFUR.data.opportunityData.associated_utility_company_name
                : us_utilityCompanyName,
            )
            us_setElectricityBill(
              res_CFUR.data.opportunityData.system_monthly_electricity_bill
                ? parseInt(res_CFUR.data.opportunityData.system_monthly_electricity_bill)
                : us_electricityBill,
            )
            // these edit the page structure based on the admin's settings
            us_setHideEditPanelLayoutButton(
              res_CFUR.data.opportunityData.hide_edit_panel_layout_button ? res_CFUR.data.opportunityData.hide_edit_panel_layout_button : false,
            )
            us_setHideEditUtilityButton(res_CFUR.data.opportunityData.hide_edit_utility_button ? res_CFUR.data.opportunityData.hide_edit_utility_button : false)
            us_setHideOffsetSlider(res_CFUR.data.opportunityData.hide_offset_slider ? res_CFUR.data.opportunityData.hide_offset_slider : false)

            // this is static customer data that should only change when the customer inputs it
            us_setCustomerName(res_CFUR.data.opportunityData.name ? res_CFUR.data.opportunityData.name : us_customerName)
            us_setCustomerEmail(res_CFUR.data.opportunityData.email ? res_CFUR.data.opportunityData.email : us_customerEmail)
            us_setCustomerPhone(res_CFUR.data.opportunityData.phone ? res_CFUR.data.opportunityData.phone : us_customerPhone)
            us_setCurrentCashCost(res_CFUR.data.opportunityData.current_cash_cost ? res_CFUR.data.opportunityData.current_cash_cost : us_currentCashCost)

            // this data is used for price calculations
            us_setOffset(res_CFUR.data.opportunityData.offset ? res_CFUR.data.opportunityData.offset : us_offset)
            us_setBaseCashCost(res_CFUR.data.opportunityData.base_cash_cost ? res_CFUR.data.opportunityData.base_cash_cost : us_baseCashCost)
            us_setLeaseCost(res_CFUR.data.opportunityData.lease_cost ? res_CFUR.data.opportunityData.lease_cost : us_leaseCost)
            us_setCashSavings(res_CFUR.data.opportunityData.cash_savings ? res_CFUR.data.opportunityData.cash_savings : us_cashSavings)
            us_setLeaseMonthlySavings(
              res_CFUR.data.opportunityData.lease_monthly_savings ? res_CFUR.data.opportunityData.lease_monthly_savings : us_leaseMonthlySavings,
            )
            us_setLeaseCumulativeSavings(
              res_CFUR.data.opportunityData.lease_cumulative_savings ? res_CFUR.data.opportunityData.lease_cumulative_savings : us_leaseCumulativeSavings,
            )
            us_setNumPanels(res_CFUR.data.opportunityData.num_panels ? res_CFUR.data.opportunityData.num_panels : us_numPanels)
            us_setSelectedSystemSizeInWatts(
              res_CFUR.data.opportunityData.selected_system_size ? res_CFUR.data.opportunityData.selected_system_size : us_selectedSystemSizeInWatts,
            )
            us_setSelectedSystemAnnualProductionInKwh(
              res_CFUR.data.opportunityData.selected_annual_production
                ? res_CFUR.data.opportunityData.selected_annual_production
                : us_selectedSystemAnnualProductionInKWh,
            )
            us_setTcpaAccepted(res_CFUR.data.opportunityData.tcpa_accepted ? res_CFUR.data.opportunityData.tcpa_accepted : us_tcpaAccepted)
          }
          // Google Solar Data
          // TODO: This may not work with all addresses
          if (res_CFUR.data.googleSolarData != null && res_CFUR.data.googleSolarData.solarPotential != null) {
            const roofSizeInFeet = res_CFUR.data.googleSolarData.solarPotential.buildingStats.areaMeters2 * 10.7639
            us_setRoofSizeInFeet(roofSizeInFeet)
            if (
              res_CFUR.data.googleSolarData.center != null &&
              res_CFUR.data.googleSolarData.center.latitude != null &&
              res_CFUR.data.googleSolarData.center.longitude != null
            ) {
              us_setPropertyCoordinates(res_CFUR.data.googleSolarData.center)
            }
          }
          if (res_CFUR.data.opportunityData.submitted) {
            us_setSubmittedSize(res_CFUR.data.opportunityData.submitted_size ? res_CFUR.data.opportunityData.submitted_size : us_selectedSystemSizeInWatts)
            us_setSubmittedProduction(
              res_CFUR.data.opportunityData.submitted_production ? res_CFUR.data.opportunityData.submitted_production : us_selectedSystemAnnualProductionInKWh,
            )
            us_setSubmittedNumPanels(res_CFUR.data.opportunityData.submitted_num_panels ? res_CFUR.data.opportunityData.submitted_num_panels : us_numPanels)
            us_setSubmittedFinancingType(
              res_CFUR.data.opportunityData.submitted_financing_type ? res_CFUR.data.opportunityData.submitted_financing_type : us_selectedFinancingType,
            )
            us_setSubmittedPrice(res_CFUR.data.opportunityData.submitted_price ? res_CFUR.data.opportunityData.submitted_price : us_currentCashCost)
          }
          us_setSubmitted(res_CFUR.data.opportunityData.submitted)
          const firestoreDate = res_CFUR.data.opportunityData.submission_date
          if (firestoreDate) {
            us_setSubmittedDate(new Date(firestoreDate))
          }
        }
        // if we are loading the propose data, we won't set the data loaded status to success until it is loaded
        if (
          us_dataLoadedStatus === 'loading_eagleview_propose_data' ||
          (res_CFUR.data.opportunityData.first_page_data_source && res_CFUR.data.opportunityData.first_page_data_source === 'eagleview_prospect')
        ) {
          if (!res_CFUR.data.eagleviewProspectData || !res_CFUR.data.eagleviewProposeData) {
            return
          } else {
            // handleUtilityBillChange(us_electricityBill) // this will recalculate all the numbers
            // const evRegressionData = getEagleViewSliderData(getEagleViewSystemSizesAndProductionRatios(res_CFUR.data.eagleviewProspectData.ev_slider_data))
            let evRegressionData: TsInterface_UnspecifiedObject[] = []
            // use propose data if we have it for the regression
            if (!res_CFUR.data.eagleviewProposeData) {
              evRegressionData = getEagleViewSliderData(getEagleViewSystemSizesAndProductionRatios(res_CFUR.data.eagleviewProspectData.ev_slider_data))
            } else {
              evRegressionData = getEagleViewProposeSliderData(res_CFUR.data.eagleviewProposeData.dataOutput.energyTable)
            }

            handleDataSourceChangeToEagleview(
              evRegressionData,
              res_CFUR.data,
              res_CFUR.data.opportunityData.contact_utility_provider ? res_CFUR.data.opportunityData.contact_utility_provider : us_utilityCompanyName,
            )
            us_setDataLoadedStatus('success')
          }
        } else {
          us_setDataLoadedStatus('success')
        }
      })
      .catch((rej_CFUR) => {
        console.error('<><>')
        console.error(rej_CFUR)
        us_setDataLoadedStatus('error')
      })
  }, []) //try catch?

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Go Solar with ETW Energy', false) as string
  }, [])

  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage('go_solar_' + opportunityKey)
    if (getProp(pageLocalStorageData, 'confirmed_phone_number', null) == true) {
      us_setConfirmedPhoneNumber(true)
    }
  }, [opportunityKey])

  const requestProposeDataset = () => {
    if (us_leadData) {
      updateOpportunity({ first_page_data_source: 'eagleview_prospect' }, true)
      us_setLoadingEagleviewProposeDataStartTime(new Date().getTime())
      us_setDataLoadedStatus('loading_eagleview_propose_data')
      orderEagleViewProposeData(clientKey, opportunityKey, getProp(us_leadData, 'opportunityData', {}))
        .then((res_OEVPD) => {
          // Nothing
        })
        .catch((rej_OEVPD) => {
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              message: 'There was an error getting the customization dataset.',
              code: '',
              details: '',
            },
          })
        })
    } else {
      uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: {
          message: 'No lead data found.',
          code: '',
          details: '',
        },
      })
    }
  }

  // load all lead, google, and eagleview data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      loadPageData(clientKey, opportunityKey)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_UnprotectedOpportunityUpdateTime_Document(opportunityKey), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [opportunityKey, loadPageData, ur_forceRerender])

  // create a web socket in order to live update the displayed lease price
  // useEffect(() => {
  //   let unsubscribeLiveData: TsType_VoidFunction
  //   const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //     us_setEPC(getEPCFromLightreachData(newData, KWH_RATE, 0.0299))
  //     ur_forceRerender()
  //   }

  //   let production = us_selectedSystemAnnualProductionInKWh
  //   let size = us_selectedSystemSizeInWatts
  //   let pr = 0

  //   if (size === 0) {
  //     return
  //   }

  //   pr = getProductionRatioBucket(production / (size / 1000))

  //   unsubscribeLiveData = DatabaseGetLiveDocument(
  //     DatabaseRef_LightreachTable_Document(clientKey, us_leadData?.opportunityData?.state, pr.toString()),
  //     updateLiveData,
  //   )
  //   return () => {
  //     if (typeof unsubscribeLiveData === 'function') {
  //       unsubscribeLiveData()
  //     }
  //   }
  // }, [opportunityKey])

  useEffect(() => {
    // Check if the user has confirmed their phone number
    if (
      us_leadData != null &&
      us_leadData.opportunityData != null &&
      us_leadData.opportunityData.phone != null &&
      returnStandardizedUSPhoneNumber(us_leadData.opportunityData.phone) !== 'Invalid phone number' &&
      us_userPhoneNumber !== '' &&
      returnStandardizedUSPhoneNumber(us_leadData.opportunityData.phone) === returnStandardizedUSPhoneNumber(us_userPhoneNumber) &&
      us_confirmedPhoneNumber !== true
    ) {
      us_setConfirmedPhoneNumber(true)
      setPageLocalStorage('go_solar', 'confirmed_phone_number', true)
      saveSessionData(
        clientKey,
        opportunityKey,
        us_analyticsSessionKey,
        {
          actions_taken: {
            clicked_test_button: true,
          },
        },
        !(ul_queryParams.get('tracking') == 'false'),
      )
    }
  }, [opportunityKey, ul_queryParams, us_analyticsSessionKey, us_confirmedPhoneNumber, us_leadData, us_userPhoneNumber])

  useEffect(() => {
    // Save Initial Analytics Session
    if (us_initialSessionSaved === false) {
      let sessionMetadata: TsInterface_UnspecifiedObject = {
        metadata_version: '1.0.0',
        cookies_enabled: getProp(navigator, 'cookieEnabled', null),
        device_memory: getProp(navigator, 'deviceMemory', null),
        language: getProp(navigator, 'language', null),
        platform: getProp(navigator, 'platform', null),
        referrer: document.referrer,
        screen_available_height: getProp(screen, 'availHeight', null),
        screen_available_width: getProp(screen, 'availWidth', null),
        screen_height: getProp(screen, 'height', null),
        screen_width: getProp(screen, 'width', null),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        user_agent: getProp(navigator, 'userAgent', null),
      }
      // Get IP Address
      let promiseArray: Promise<unknown>[] = []
      promiseArray.push(
        fetch('https://api.ipify.org?format=json')
          .then((response) => response.json())
          .then((data) => {
            sessionMetadata['ip_address'] = getProp(data, 'ip', null)
          }),
      )
      // After IP Address Loaded
      Promise.all(promiseArray).finally(() => {
        saveSessionData(
          clientKey,
          opportunityKey,
          us_analyticsSessionKey,
          {
            key: us_analyticsSessionKey,
            associated_opportunity_key: opportunityKey,
            associated_client_key: clientKey,
            timestamp_session_started: us_initialPageLoadTime,
            initialPageLoadURL: window.location.href,
            metadata: sessionMetadata,
          },
          !(ul_queryParams.get('tracking') == 'false'),
        )
        us_setInitialSessionSaved(true)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityKey, us_analyticsSessionKey, us_initialPageLoadTime, us_initialSessionSaved])

  useEffect(() => {
    // Interval Update Session Length
    let currentLoop = 1
    let intervalId = setInterval(() => {
      let sessionLength = currentLoop * 5
      if (sessionLength <= 60) {
        // 1 minute
        // Save Data to Analytics Session
        saveSessionData(
          clientKey,
          opportunityKey,
          us_analyticsSessionKey,
          {
            timestamp_session_last_updated: new Date(),
            session_length_seconds: sessionLength,
          },
          !(ul_queryParams.get('tracking') == 'false'),
        )
      } else if (sessionLength <= 180) {
        // 3 minutes
        // Save Data to Analytics Session on 10 second intervals
        if (sessionLength % 10 === 0) {
          saveSessionData(
            clientKey,
            opportunityKey,
            us_analyticsSessionKey,
            {
              timestamp_session_last_updated: new Date(),
              session_length_seconds: sessionLength,
            },
            !(ul_queryParams.get('tracking') == 'false'),
          )
        }
      } else if (sessionLength <= 600) {
        // 10 minutes
        // Save Data to Analytics Session on 30 second intervals
        if (sessionLength % 30 === 0) {
          saveSessionData(
            clientKey,
            opportunityKey,
            us_analyticsSessionKey,
            {
              timestamp_session_last_updated: new Date(),
              session_length_seconds: sessionLength,
            },
            !(ul_queryParams.get('tracking') == 'false'),
          )
        }
      }
      currentLoop++
      return () => {
        if (intervalId != null) {
          clearInterval(intervalId)
        }
      }
    }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // load the correct utility provider names in the autocomplete
    // const jsonString = JSON.stringify(utilityCompanyJson)
    // let jsonData: TsInterface_LSEDetails[] = JSON.parse(jsonString)
    // let utilityCompanyListObject: TsInterface_UnspecifiedObject = {}
    // for (let loopCompanyKey in jsonData) {
    //   let loopCompany = jsonData[loopCompanyKey]
    //   if (loopCompany != null && loopCompany.name != null && loopCompany.lseId != null) {
    //     utilityCompanyListObject[loopCompany.name] = {
    //       label: loopCompany.name,
    //       lseId: loopCompany.lseId,
    //       key: loopCompany.name,
    //     }
    //   }
    // }
    // TODO: temporary for illinois, may need to find way to programmatically segment companies by state
    if (us_leadData) {
      let utilityCompanyList = []
      for (let loopCompanyKey of Object.keys(utilityCompanyMap[us_leadData?.opportunityData?.state])) {
        utilityCompanyList.push({
          label: loopCompanyKey,
          key: loopCompanyKey,
        })
      }
      us_setUtilityCompanies(objectToArray(utilityCompanyList).sort(dynamicSort('label', 'asc')))
    }
  }, [us_leadData])

  useEffect(() => {
    let intervalId: any = null
    if (us_dataLoadedStatus === 'loading_eagleview_propose_data' && us_loadingEagleviewProposeDataStartTime > 0) {
      intervalId = setInterval(() => {
        let currentTime = new Date().getTime()
        // Difference in seconds
        let differenceInSeconds = (currentTime - us_loadingEagleviewProposeDataStartTime) / 1000
        us_setLoadingEagleviewProposeDataSecondsSinceStart(differenceInSeconds)
      }, 100)
    }
    return () => {
      if (intervalId != null) {
        clearInterval(intervalId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [us_dataLoadedStatus])

  useEffect(() => {
    if (
      us_selectedSystemAnnualProductionInKWh > 0 &&
      us_savingInitialActivePanels === false &&
      us_dataLoadedStatus === 'success' &&
      us_leadData != null &&
      us_leadData.eagleviewProposeData != null &&
      objectToArray(us_leadData.eagleviewProposeData).length > 0 &&
      us_leadData.opportunityData != null &&
      us_leadData.opportunityData['active_propose_panels'] == null
    ) {
      us_setSavingInitialActivePanels(true)
      // Set Initial Panels - One Time Only based on System Size
      let activePanelSelection: TsInterface_UnspecifiedObject = {}
      let systemSize = us_selectedSystemAnnualProductionInKWh
      let passedSystemSize = false
      for (let loopPanelIndex in getProp(us_leadData.eagleviewProposeData, 'dataOutput', {})['energyTable']) {
        let loopPanel = getProp(us_leadData.eagleviewProposeData, 'dataOutput', {})['energyTable'][loopPanelIndex]
        if (loopPanel != null) {
          activePanelSelection[loopPanelIndex] = true
          if (passedSystemSize === false) {
            activePanelSelection[loopPanelIndex] = true
          } else {
            activePanelSelection[loopPanelIndex] = false
          }
          if (loopPanel['kWh/yr(Cumulative)'] >= systemSize) {
            passedSystemSize = true
          }
        }
      }
      // updateOpportunity({ active_propose_panels: activePanelSelection })
      us_setActiveProposePanels(activePanelSelection)
      ur_forceRerender()
    } else if (us_leadData != null && us_leadData.opportunityData != null && us_leadData.opportunityData['active_propose_panels'] != null) {
      us_setActiveProposePanels(us_leadData.opportunityData['active_propose_panels'])
      ur_forceRerender()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [us_dataLoadedStatus, us_leadData, us_savingInitialActivePanels, ur_forceRerender, us_selectedSystemAnnualProductionInKWh])

  useEffect(() => {
    if (!isAuthenticatedUser && us_alreadySentEmail === false && us_leadData != null) {
      console.log('PROCEED')
      cloudFunctionUnauthenticatedRequests({
        function: 'sendSalesLeadEmail',
        to_array: ['leads.curiosity@etwenergy.com'], // this is set on the server so either way it will only send to this email
        cc_array: ['brady.richardson@etwenergy.com', 'raphael@etwenergy.com', 'john.morrell@etwenergy.com'],
        bcc_array: [''],
        subject: `Customer Page Opened - ${us_leadData?.opportunityData.address1}`,
        body: `A customer has opened the page for the property at:\n\n${us_leadData?.opportunityData.address1}`,
      })
        .then((res_CFUR) => {
          console.log(res_CFUR)
        })
        .catch((rej_CFUR) => {
          console.error(rej_CFUR)
        })
      us_setAlreadySentEmail(true)
    }
  }, [isAuthenticatedUser, us_leadData, us_alreadySentEmail])

  // TODO: add clarity stuff here!!!

  const setActivePanelsForVisualization = (productionInKwhPerYear: number) => {
    if (!us_leadData || (us_leadData.eagleviewProposeData === null && objectToArray(us_leadData.eagleviewProposeData).length === 0)) {
      return
    }

    let activePanelSelection: TsInterface_UnspecifiedObject = {}
    let passedSystemSize = false
    for (let loopPanelIndex in getProp(us_leadData.eagleviewProposeData, 'dataOutput', {})['energyTable']) {
      let loopPanel = getProp(us_leadData.eagleviewProposeData, 'dataOutput', {})['energyTable'][loopPanelIndex]
      if (loopPanel != null) {
        activePanelSelection[loopPanelIndex] = true
        if (passedSystemSize === false) {
          activePanelSelection[loopPanelIndex] = true
        } else {
          activePanelSelection[loopPanelIndex] = false
        }
        if (loopPanel['kWh/yr(Cumulative)'] >= productionInKwhPerYear) {
          passedSystemSize = true
        }
      }
    }
    // updateOpportunity({ active_propose_panels: activePanelSelection })
    us_setActiveProposePanels(activePanelSelection)
    ur_forceRerender()
  }

  const getGoogleRegressionSliderData = (googleBuildingInsights: TsInterface_UnspecifiedObject) => {
    const minPanelCount = googleBuildingInsights.solarPotential.solarPanelConfigs[0]['panelsCount']
    // const maxPanelCount = googleBuildingInsights.solarPotential.maxArrayPanelsCount
    const solarPanelConfigs = googleBuildingInsights['solarPotential']['solarPanelConfigs']
    const defaultPanelCapacityWatts = googleBuildingInsights['solarPotential']['panelCapacityWatts']
    const panelCapacityRatio = PANEL_WATT_CAPACITY / defaultPanelCapacityWatts
    // const maxAnnualEnergyOutput = Math.round(solarPanelConfigs[solarPanelConfigs.length - 1]['yearlyEnergyDcKwh'] * panelCapacityRatio)

    let regressionSliderData: TsInterface_UnspecifiedObject[] = []

    // google starts at 4 panels, so regressively determine the first 3 panel production values
    for (let i = 0; i < minPanelCount; i++) {
      const productionIncrement = (solarPanelConfigs[0]['yearlyEnergyDcKwh'] * panelCapacityRatio) / minPanelCount

      regressionSliderData.push({
        size: i * PANEL_WATT_CAPACITY,
        production: productionIncrement * i,
      })
    }

    // start at 4 panels using the google solar panel config data, filling in regression gaps where necessary
    for (let i = 0; i < solarPanelConfigs.length; i++) {
      if (i !== solarPanelConfigs.length - 1 && solarPanelConfigs[i + 1]['panelsCount'] - solarPanelConfigs[i]['panelsCount'] > 1) {
        regressionSliderData = regressionSliderData.concat(
          fillGoogleRegressionGaps(
            solarPanelConfigs[i]['panelsCount'],
            solarPanelConfigs[i + 1]['panelsCount'],
            Math.round(solarPanelConfigs[i]['yearlyEnergyDcKwh'] * panelCapacityRatio),
            Math.round(solarPanelConfigs[i + 1]['yearlyEnergyDcKwh'] * panelCapacityRatio),
          ),
        )
      } else {
        regressionSliderData.push({
          size: solarPanelConfigs[i]['panelsCount'] * PANEL_WATT_CAPACITY,
          production: Math.round(solarPanelConfigs[i]['yearlyEnergyDcKwh'] * panelCapacityRatio),
        })
      }
    }

    return regressionSliderData
  }

  const fillGoogleRegressionGaps = (currentNumPanels: number, nextNumPanels: number, currentProduction: number, nextProduction: number) => {
    let regressionSliderData: TsInterface_UnspecifiedObject = []

    const sizeDiff = nextNumPanels - currentNumPanels
    const productionDiff = nextProduction - currentProduction
    const productionIncrement = productionDiff / sizeDiff

    for (let i = 0; i < sizeDiff; i++) {
      regressionSliderData.push({
        size: (currentNumPanels + i) * PANEL_WATT_CAPACITY,
        production: currentProduction + productionIncrement * i,
      })
    }

    return regressionSliderData
  }

  // const calculateGoogleAnnualEnergyOutput = (numPanels: number, solarPanelConfigs: TsInterface_UnspecifiedObject[]) => {
  //   if (numPanels === 0) {
  //     return 0
  //   }

  //   let selectedConfig: TsInterface_UnspecifiedObject = {}

  //   for (let config of Object.entries(solarPanelConfigs)) {
  //     const configBody = config[1]

  //     // the reason we are not just searching for the exact number of panels is because the configs do not always increase panel count by 1
  //     if (numPanels >= configBody['panelsCount']) {
  //       selectedConfig = config[1]
  //     }
  //   }

  //   if (Object.keys(selectedConfig).length === 0) {
  //     selectedConfig = solarPanelConfigs[0]
  //   }

  //   return selectedConfig['yearlyEnergyDcKwh']
  // }

  const getEPCFromLightreachData = (lightreachTable: TsInterface_UnspecifiedObject, kwhRate: number, escalationRate: number) => {
    if (lightreachTable && lightreachTable[kwhRate] && lightreachTable[kwhRate][escalationRate]) {
      return lightreachTable[kwhRate][escalationRate].ppwRate
    } else {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: { message: 'Error getting EPC from Lightreach data', code: '', details: '' } })
    }
  }

  const getProductionRatioBucket = (rawProductionRatio: number) => {
    if (rawProductionRatio > 1580) {
      return 1580
    }

    const lowPR = 780 // offset increments of 50 because that is what the lightreach data is in
    const highPR = 1580
    const step = 50

    for (let i = lowPR; i <= highPR; i += step) {
      if (rawProductionRatio >= i && rawProductionRatio <= i + step) {
        return i
      }
    }

    return 0
  }

  const getEagleViewProposeSliderData = (proposeEnergyTable: TsInterface_UnspecifiedObject[]) => {
    let regressionSliderData: TsInterface_UnspecifiedObject[] = []
    let currentProduction = 0
    let currentSize = 0

    for (const panel of proposeEnergyTable) {
      currentProduction += panel['kWh/yr']
      currentSize += panel['kW size'] * 1000

      regressionSliderData.push({
        size: currentSize,
        production: currentProduction,
      })
    }

    return regressionSliderData
  }

  const getEagleViewSliderData = (systemSizes: TsInterface_UnspecifiedObject) => {
    let systemSizesArray = Object.keys(systemSizes)
      .map((systemSize) => parseFloat(systemSize))
      .sort((a, b) => a - b) // sorts the array in ascending order

    let annualProductionArray = Object.values(systemSizes)
      .map((systemSize) => systemSize.annual_production)
      .sort((a, b) => a - b) // sorts the array in ascending order

    let currentSystemSize = 0
    let currentSystemProduction = 0

    let regressionSliderData: TsInterface_UnspecifiedObject[] = []

    // use outer for-loop to calculate the needed production increment estimate per panel for the gap in production data
    for (let i = 1; i < annualProductionArray.length; i++) {
      const sizeDiff = systemSizesArray[i] - systemSizesArray[i - 1]
      const productionDiff = annualProductionArray[i] - annualProductionArray[i - 1]
      const intermediateNumPanels = Math.round((sizeDiff * 1000) / PANEL_WATT_CAPACITY)
      const productionIncrement = productionDiff / intermediateNumPanels

      // use inner for-loop to populate the data array that will be used for the slider
      for (let j = 0; j < intermediateNumPanels; j++) {
        regressionSliderData.push({
          size: currentSystemSize,
          production: currentSystemProduction,
        })
        currentSystemProduction = currentSystemProduction + productionIncrement
        currentSystemSize = currentSystemSize + 405
      }
    }

    // add the max values to the end of the array
    regressionSliderData.push({
      size: systemSizesArray[systemSizesArray.length - 1] * 1000,
      production: annualProductionArray[annualProductionArray.length - 1],
    })

    return regressionSliderData
  }

  // Functions
  // const handleRoofReplacementChange = (event: any) => {
  //   us_setAdders({ ...us_adders, [event.target.name]: event.target.checked })
  //   const roofCost = event.target.checked ? calculateRoofCost() : 0
  //   us_setRoofCost(roofCost)
  //   // updateOpportunity({ current_cash_cost: applyIncentives(us_baseCashCost + roofCost + us_batteryCost) })
  //   us_setCurrentCashCost(applyIncentives(us_baseCashCost + roofCost + us_batteryCost))
  // }

  const getNumPanelsFromProduction = (production: number, newRegression: TsInterface_UnspecifiedObject[] | null = null): number => {
    const regressionData = newRegression ? newRegression : us_systemRegressionSliderData

    if (regressionData.length === 0) {
      return 0
    }

    let currentSize = 0

    for (let i = 0; i < regressionData.length; i++) {
      if (regressionData[i].production <= production) {
        currentSize = regressionData[i].size
      }
    }

    return Math.ceil(currentSize / PANEL_WATT_CAPACITY)
  }

  const getProductionFromNumPanels = (numPanels: number, newRegression: TsInterface_UnspecifiedObject[] | null = null): number => {
    const regressionData = newRegression ? newRegression : us_systemRegressionSliderData

    if (regressionData.length === 0) {
      return 0
    }

    if (numPanels > regressionData.length) {
      numPanels = regressionData.length
    }

    if (newRegression) {
      return regressionData[numPanels - 1].production
    }

    let currentProduction = 0

    for (let i = 0; i < regressionData.length; i++) {
      if (regressionData[i].size <= numPanels * PANEL_WATT_CAPACITY) {
        currentProduction = regressionData[i].production
      }
    }

    return currentProduction
  }

  const handleSystemSizeSliderChange = (event: any, newValue: number) => {
    let panelCount = newValue
    let productionAmount = us_systemRegressionSliderData[newValue].production
    let systemSize = us_systemRegressionSliderData[newValue].size
    submitSystemSizeChange(panelCount, productionAmount, systemSize)
  }

  const submitOffsetChange = (offset: number, triggerRefresh: boolean = false) => {
    // calculate and set the pricing state variables
    const [baseCashCost, currentCashCost, leaseCost, hypotheticalSize, hypotheticalProduction] = recalculateCostsOnOffsetChange(offset)

    // calculate and set the savings state variables
    const [cashSavings, leaseMonthlySavings, leaseCumulativeSavings] = calculateSavings(us_electricityBill, currentCashCost, leaseCost, hypotheticalProduction)

    // updateOpportunity(
    //   {
    //     lease_cost: leaseCost,
    //     base_cash_cost: baseCashCost,
    //     current_cash_cost: currentCashCost,
    //     num_panels: hypotheticalSize / PANEL_WATT_CAPACITY,
    //     selected_system_size: hypotheticalSize,
    //     selected_annual_production: hypotheticalProduction,
    //     cash_savings: cashSavings,
    //     lease_monthly_savings: leaseMonthlySavings,
    //     lease_cumulative_savings: leaseCumulativeSavings,
    //     contact_monthly_electric_bill: us_electricityBill,
    //     offset: offset,
    //     confirmed: true,
    //   },
    //   triggerRefresh,
    // )

    us_setNumPanels(hypotheticalSize / PANEL_WATT_CAPACITY)
    us_setBaseCashCost(baseCashCost)
    us_setCurrentCashCost(currentCashCost)
    us_setLeaseCost(leaseCost)
    us_setSelectedSystemSizeInWatts(hypotheticalSize)
    us_setSelectedSystemAnnualProductionInKwh(hypotheticalProduction)
    us_setCashSavings(cashSavings)
    us_setLeaseMonthlySavings(leaseMonthlySavings)
    us_setLeaseCumulativeSavings(leaseCumulativeSavings)
    us_setOffset(offset)
  }

  const submitSystemSizeChange = (panelCount: number, productionAmount: number, systemSize: number) => {
    // set the state variables corresponding to system size, annual production, and number of panels
    us_setSelectedSystemSizeInWatts(systemSize)
    us_setSelectedSystemAnnualProductionInKwh(productionAmount)
    us_setNumPanels(panelCount)

    // calculate and set the pricing state variables
    const [baseCashCost, currentCashCost, leaseCost] = recalculateCostsOnSystemSizeChange(productionAmount, systemSize)
    us_setBaseCashCost(baseCashCost)
    us_setCurrentCashCost(currentCashCost)
    us_setLeaseCost(leaseCost)

    // calculate and set the savings state variables
    const [cashSavings, leaseMonthlySavings, leaseCumulativeSavings] = calculateSavings(us_electricityBill, currentCashCost, leaseCost, productionAmount)
    us_setCashSavings(cashSavings)
    us_setLeaseMonthlySavings(leaseMonthlySavings)
    us_setLeaseCumulativeSavings(leaseCumulativeSavings)

    updateOpportunity(
      {
        num_panels: panelCount,
        selected_system_size: systemSize,
        selected_annual_production: productionAmount,
        base_cash_cost: baseCashCost,
        current_cash_cost: currentCashCost,
        lease_cost: leaseCost,
        cash_savings: cashSavings,
        lease_monthly_savings: leaseMonthlySavings,
        lease_cumulative_savings: leaseCumulativeSavings,
      },
      false,
    )
  }

  // const calculateRoofCost = () => {
  //   const numSquares = Math.ceil(us_roofSizeInFeet / 100)
  //   const cost = numSquares * 650
  //   return cost
  // }

  // const handleBatterySliderChange = (event: any, newValue: number) => {
  //   let battery = false
  //   if (newValue == 0) {
  //     us_setAdders({ ...us_adders, batteries: false })
  //   } else {
  //     battery = true
  //     us_setAdders({ ...us_adders, batteries: true })
  //   }
  //   us_setBatteryCost(calculateBatteryAdder(newValue))
  //   us_setCurrentCashCost(applyIncentives(us_baseCashCost + us_roofCost + calculateBatteryAdder(newValue)))
  //   // updateOpportunity({ current_cash_cost: applyIncentives(us_baseCashCost + us_roofCost + calculateBatteryAdder(newValue)) })
  // }

  // const calculateCostsWithAdders = (adders: TsInterface_UnspecifiedObject) => {
  //   let cashCost = us_baseCashCost
  //   let leaseCost = us_leaseCost
  //   if (adders.battery) {
  //     cashCost += calculateBatteryAdder(us_numBatteries)
  //   }
  //   if (adders.replace_roof) {
  //     cashCost += 10000
  //   }
  //   return [cashCost, leaseCost]
  // }

  const getSRECGroup = (leadData: TsInterface_LeadData | null = null, companyName: string = '') => {
    const state = leadData && leadData.opportunityData ? leadData.opportunityData.state : us_leadData?.opportunityData.state
    const utilityCompanyName = companyName === '' ? us_utilityCompanyName : companyName

    if (state && utilityCompanyName) {
      // const state = us_leadData.opportunityData.state
      const relevantUtilityCompanies = getProp(utilityCompanyMap, state, null)

      if (relevantUtilityCompanies && relevantUtilityCompanies[utilityCompanyName as string]) {
        return relevantUtilityCompanies[utilityCompanyName as string]
      } else {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: { message: 'Utility company not found.', code: '', details: '' } })
      }
    } else {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: { message: 'No lead data available.', code: '', details: '' } })
    }
  }

  const getSRECSizeBracket = (size: number) => {
    if (size <= 10000) {
      return '<=10'
    } else if (size <= 25000) {
      return '10-25'
    } else {
      return '>25'
    }
  }

  // these values correspond directly to the Zero-Cost Solar lookup values in the google sheet
  const getSRECRate = (srecGroup: string, srecSizeBracket: string) => {
    if (srecGroup === 'A') {
      if (srecSizeBracket === '<=10') {
        return 75.21
      } else if (srecSizeBracket === '10-25') {
        return 64.71
      } else {
        return 59.28
      }
    } else {
      if (srecSizeBracket === '<=10') {
        return 83.75
      } else if (srecSizeBracket === '10-25') {
        return 76.27
      } else {
        return 69.47
      }
    }
  }

  const calculateSRECRate = (size: number, leadData: TsInterface_LeadData | null = null, utilityCompanyName: string = '') => {
    const srecGroup = getSRECGroup(leadData, utilityCompanyName)
    const srecSizeBracket = getSRECSizeBracket(size)
    const srecRate = getSRECRate(srecGroup, srecSizeBracket)
    return srecRate
  }

  // =ROUNDDOWN(B7*((1-(1-B27)^15)/0.005)/1000,0) --- forecast the production over 15 years in Mwh
  const calculateFifteenYearProductionRounded = (production: number, degradation: number): number => {
    const result = (production * ((1 - Math.pow(1 - degradation, 15)) / 0.005)) / 1000
    return Math.floor(result)
  }

  const applyIncentives = (
    cost: number,
    production: number,
    size: number,
    federalRate: number = FEDERAL_INCENTIVE_RATE,
    leadData: TsInterface_LeadData | null = null,
    utilityCompanyName: string = '',
  ): number => {
    const degradation = 0.005

    const fifteenYearProductionInMWh = calculateFifteenYearProductionRounded(production, degradation)
    const costAfterFederalIncentive = cost * (1 - federalRate)

    let costAfterSRECIncentive = costAfterFederalIncentive

    // Check if the state is Illinois before applying SREC incentive
    if (leadData?.opportunityData?.state === 'IL') {
      const srecRate = calculateSRECRate(size, leadData, utilityCompanyName)
      costAfterSRECIncentive = costAfterFederalIncentive - srecRate * fifteenYearProductionInMWh * 0.8
      us_setSrecIncentive(costAfterFederalIncentive - costAfterSRECIncentive)
    } else {
      us_setSrecIncentive(0)
    }

    us_setFederalIncentive(cost - costAfterFederalIncentive)

    return costAfterSRECIncentive
  }

  // const getSystemFromRequiredProduction = (requiredProduction: number) => {
  //   let production = 0
  //   let size = 0

  //   // get the largest system that is less than or equal to the hypothetical pr
  //   if (us_systemRegressionSliderData !== null) {
  //     for (const system of us_systemRegressionSliderData) {
  //       if (system.production <= requiredProduction) {
  //         production = system.production
  //         size = system.size
  //       }
  //     }
  //   }

  //   return [production, size]
  // }

  const recalculateCostsOnOffsetChange = (offset: number) => {
    let baseCashCost = 0
    let currentCashCost = 0
    let leaseCost = 0

    const hypotheticalProductionRatio = 1.1
    const productionBasedOnBill = ((offset / 100) * us_electricityBill * 12) / 0.15
    // TODO: use below code to use the google regression data
    // const numPanelsBasedOnBill = getNumPanelsFromProduction(productionBasedOnBill)
    // const sizeBasedOnBill = numPanelsBasedOnBill * PANEL_WATT_CAPACITY
    // const preciseProduction = getProductionFromNumPanels(numPanelsBasedOnBill)

    // TODO: use below code to use the hypothetical production ratio
    const sizeBasedOnBill = productionBasedOnBill / hypotheticalProductionRatio
    const preciseProduction = hypotheticalProductionRatio * sizeBasedOnBill

    if (preciseProduction && sizeBasedOnBill) {
      // TODO: eventually we will need to incorporate a dynamic EPC when we introduce adders
      baseCashCost = DEFAULT_EPC * (sizeBasedOnBill / 405) * PANEL_WATT_CAPACITY
      currentCashCost = applyIncentives(baseCashCost, preciseProduction, sizeBasedOnBill)
      leaseCost = calculateLeaseCost(preciseProduction)
    }

    return [baseCashCost, currentCashCost, leaseCost, sizeBasedOnBill, preciseProduction]
  }

  const recalculateCostsOnSystemSizeChange = (
    production: number = 0,
    systemSize: number = 0,
    leadData: TsInterface_LeadData | null = null,
    utilityCompanyName: string = '',
  ) => {
    let baseCashCost = 0
    let currentCashCost = 0
    let leaseCost = 0

    if (production && systemSize) {
      // TODO: eventually we will need to incorporate a dynamic EPC when we introduce adders
      baseCashCost = DEFAULT_EPC * (systemSize / 405) * PANEL_WATT_CAPACITY
      currentCashCost = applyIncentives(baseCashCost, production, systemSize, FEDERAL_INCENTIVE_RATE, leadData, utilityCompanyName)
      leaseCost = calculateLeaseCost(production)
    }

    return [baseCashCost, currentCashCost, leaseCost]
  }

  const calculateSavings = (electricityBill: number, currentCashCost: number, leaseCost: number, productionInWatts: number): number[] => {
    if (currentCashCost === 0 || leaseCost === 0) {
      return [0, 0, 0]
    }
    const offset = calculateOffset(electricityBill, productionInWatts) / 100
    let cashSavings
    let leaseMonthlySavings
    let leaseCumulativeSavings

    // here we multiply the electricity bill by the offset to account for the rest of the bill that is not covered by the offset if not fully covered
    if (offset < 1) {
      cashSavings = offset * electricityBill * 12 * 25 - currentCashCost // 12 mo * 25 years
      leaseMonthlySavings = offset * electricityBill - leaseCost
      leaseCumulativeSavings = offset * electricityBill * 12 * 25 - leaseCost * 12 * 25 // 12 mo * 25 years
    } else {
      cashSavings = electricityBill * 12 * 25 - currentCashCost // 12 mo * 25 years
      leaseMonthlySavings = electricityBill - leaseCost
      leaseCumulativeSavings = electricityBill * 12 * 25 - leaseCost * 12 * 25 // 12 mo * 25 years
    }

    return [cashSavings, leaseMonthlySavings, leaseCumulativeSavings]
  }

  const calculateLeaseCost = (production: number) => {
    return (production / 12) * KWH_RATE
  }

  // const calculateBatteryAdder = (numBatteries: number) => {
  //   let adderSum = 0
  //   for (let i = 0; i < numBatteries; i++) {
  //     if (i == 0) {
  //       adderSum += 13200
  //     } else {
  //       adderSum += 9700
  //     }
  //   }
  //   return adderSum
  // }

  // const calculateGroundMountAdder = (numMounts: number) => {
  //   if (numMounts === 0) {
  //     return 0
  //   }
  //   if (numMounts <= 30) {
  //     return 9000
  //   }
  //   let adderSum = 9000
  //   for (let i = 30; i < numMounts; i += 10) {
  //     adderSum += 3000
  //   }
  //   return adderSum
  // }

  const getEagleViewSystemSizesAndProductionRatios = (systemSliderData: any) => {
    let possibleSystems: TsInterface_UnspecifiedObject = {}

    for (const sliderData of systemSliderData) {
      const systemSize = sliderData.data.dataOutput.roof['Max kW system']
      const pr = sliderData.production_ratio as number

      if (!possibleSystems[systemSize]) {
        possibleSystems[systemSize] = { production_ratio: sliderData.production_ratio, annual_production: sliderData.data.dataOutput.roof['Max kWh/yr system'] }
      } else {
        if (possibleSystems[systemSize] > pr) {
          possibleSystems[systemSize] = {
            production_ratio: sliderData.production_ratio,
            annual_production: sliderData.data.dataOutput.roof['Max kWh/yr system'],
          }
        }
      }
    }

    return possibleSystems
  }

  const calculateOffset = (monthlyBill: string | number, selectedSystemProduction: number) => {
    let monthlyBillTemp

    if (typeof monthlyBill === 'string') {
      // Remove the dollar sign and parse the number
      monthlyBillTemp = parseInt(monthlyBill.replace(/^\$/, ''))
    } else {
      monthlyBillTemp = monthlyBill
    }

    const annualBill = monthlyBillTemp * 12
    const estimatedProduction = annualBill / 0.15

    return (selectedSystemProduction / estimatedProduction) * 100
  }

  // const getLightreachData = (systemSize: number, production: number) => {
  //   return new Promise((resolve, reject) => {
  //     if (clientKey && opportunityKey && us_leadData) {
  //       const lightreachParams: TsInterface_LightreachRequestParams = {
  //         utilityCompany: us_utilityCompanyName,
  //         state: us_leadData.opportunityData.state,
  //         systemSize: systemSize / 1000,
  //         production: production,
  //         batteryIncluded: false, // TODO: for now, going to calculate battery adders separately
  //         electricalUpgradeIncluded: false,
  //       }
  //       cloudFunctionUnauthenticatedRequests({
  //         function: 'getLightreachData',
  //         client_key: clientKey,
  //         opportunity_key: opportunityKey,
  //         lightreachParams: lightreachParams,
  //       })
  //         .then((res_CFUR) => {
  //           resolve(res_CFUR)
  //         })
  //         .catch((rej_CFUR) => {
  //           reject(rej_CFUR)
  //         })
  //     } else {
  //       resolve({ success: false })
  //     }
  //   })
  // }

  const openCustomTooltipDialog = (header: string | JSX.Element, textToDisplay: string | JSX.Element) => {
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Dialog
            className="bp_dialog_md_width"
            keepMounted
            onClose={() => {
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
            }}
            open={true}
          >
            <DialogContent sx={{ padding: '0px', background: white }}>
              <Box className="tw-p-4">
                <Typography
                  variant="h6"
                  sx={{ color: teal, fontWeight: 'bold' }}
                >
                  {header}
                </Typography>
                {typeof textToDisplay === 'string' && (
                  <Typography
                    variant="body1"
                    sx={{ color: darkGray }}
                  >
                    {textToDisplay}
                  </Typography>
                )}
                {typeof textToDisplay !== 'string' && <Box sx={{ color: darkGray }}>{textToDisplay}</Box>}
              </Box>
            </DialogContent>
          </Dialog>
        ),
        settings: {
          max_width: 'lg',
        },
      },
    })
  }

  // updates the opportunity in the database
  const updateOpportunity = (updatedFields: TsInterface_UnspecifiedObject, triggerPageRefresh: boolean = true) => {
    return new Promise((resolve, reject) => {
      if (us_leadData) {
        let updatedOpportunity = {}
        for (const entry of Object.entries(updatedFields)) {
          updatedOpportunity = { ...updatedOpportunity, [entry[0]]: entry[1] }
        }
        cloudFunctionUnauthenticatedRequests({
          function: 'updateOpportunity',
          client_key: clientKey,
          opportunity_key: opportunityKey,
          opportunity_data: { ...updatedOpportunity },
          trigger_page_refresh: triggerPageRefresh,
        })
          .then((res_CFUR) => {
            resolve(res_CFUR)
          })
          .catch((rej_CFUR) => {
            reject(rej_CFUR)
          })
      } else {
        resolve({ success: false })
      }
    })
  }

  const handleEditUtilityBill = () => {
    us_setShowEditUtilityModal(true)
  }

  const checkIfContactInfoIsIncomplete = () => {
    if (!us_customerName || !us_customerEmail || !us_customerPhone) {
      return true
    }

    if (!validator.isEmail(us_customerEmail)) {
      return true
    }

    if (!validator.isMobilePhone(us_customerPhone) || !validator.isLength(us_customerPhone, { min: 10 })) {
      return true
    }

    return false
  }

  const handleDataSourceChangeToEagleview = (newRegression: TsInterface_UnspecifiedObject[], leadData: TsInterface_LeadData, utilityCompanyName: string) => {
    const approxProduction = (us_electricityBill * 12) / 0.15
    let numPanels = getNumPanelsFromProduction(approxProduction, newRegression)
    const preciseProduction = getProductionFromNumPanels(numPanels, newRegression)
    if (numPanels > newRegression.length) {
      numPanels = newRegression.length
    }
    const costsResult = recalculateCostsOnSystemSizeChange(preciseProduction, newRegression[numPanels - 1].size, leadData, utilityCompanyName)
    const baseCashCost = costsResult[0]
    const currentCashCost = costsResult[1]
    const leaseCost = costsResult[2]
    const savings = calculateSavings(us_electricityBill, currentCashCost, leaseCost, preciseProduction)
    const cashSavings = savings[0]
    const leaseMonthlySavings = savings[1]
    const leaseCumulativeSavings = savings[2]

    us_setElectricityBill(us_electricityBill)
    us_setLeaseCost(leaseCost)
    us_setBaseCashCost(baseCashCost)
    us_setCurrentCashCost(currentCashCost)
    us_setNumPanels(numPanels)
    us_setSelectedSystemSizeInWatts(newRegression[numPanels - 1].size)
    us_setSelectedSystemAnnualProductionInKwh(preciseProduction)
    us_setCashSavings(cashSavings)
    us_setLeaseMonthlySavings(leaseMonthlySavings)
    us_setLeaseCumulativeSavings(leaseCumulativeSavings)
  }

  const handleUtilityBillChange = (value: number, triggerRefresh: boolean = false) => {
    let numPanels = 0
    let preciseProduction = 0
    let costsResult = []
    let savings = []
    let baseCashCost = 0
    let currentCashCost = 0
    let leaseCost = 0
    let cashSavings = 0
    let leaseMonthlySavings = 0
    let leaseCumulativeSavings = 0

    // if we are not using the eagleview data, we need to estimate the prices/savings using the offset because the google data is too far off
    if (
      us_leadData &&
      (us_leadData.opportunityData.first_page_data_source === 'eagleview_prospect' ||
        (us_dataLoadedStatus === 'loading_eagleview_propose_data' && us_systemRegressionSliderData.length > 0))
    ) {
      let approxProduction = (value * 12) / 0.15
      // preciseProduction = us_systemRegressionSliderData[us_numPanels - 1].production
      // numPanels = us_numPanels
      numPanels = getNumPanelsFromProduction(approxProduction, us_systemRegressionSliderData)
      preciseProduction = getProductionFromNumPanels(numPanels, us_systemRegressionSliderData)
      if (numPanels > us_systemRegressionSliderData.length) {
        numPanels = us_systemRegressionSliderData.length
      }
      costsResult = recalculateCostsOnSystemSizeChange(preciseProduction, us_systemRegressionSliderData[numPanels - 1].size)
      baseCashCost = costsResult[0]
      currentCashCost = costsResult[1]
      leaseCost = costsResult[2]
      savings = calculateSavings(us_electricityBill, currentCashCost, leaseCost, preciseProduction)
      cashSavings = savings[0]
      leaseMonthlySavings = savings[1]
      leaseCumulativeSavings = savings[2]
    } else {
      costsResult = recalculateCostsOnOffsetChange(us_offset ? us_offset : 100)

      baseCashCost = costsResult[0]
      currentCashCost = costsResult[1]
      leaseCost = costsResult[2]
      let size = costsResult[3]
      numPanels = Math.floor(size / PANEL_WATT_CAPACITY)
      preciseProduction = costsResult[4]

      savings = calculateSavings(value as number, currentCashCost, leaseCost, preciseProduction)

      cashSavings = savings[0]
      leaseMonthlySavings = savings[1]
      leaseCumulativeSavings = savings[2]
    }

    // if the change is committed (slider is released), update the opportunity in the database which will then update the state in the loadpagedata function
    // updateOpportunity(
    //   {
    //     lease_cost: leaseCost,
    //     base_cash_cost: baseCashCost,
    //     current_cash_cost: currentCashCost,
    //     num_panels: numPanels,
    //     selected_system_size: numPanels * PANEL_WATT_CAPACITY,
    //     selected_annual_production: preciseProduction,
    //     cash_savings: cashSavings,
    //     lease_monthly_savings: leaseMonthlySavings,
    //     lease_cumulative_savings: leaseCumulativeSavings,
    //     contact_monthly_electric_bill: value,
    //     offset: us_offset,
    //     confirmed: true,
    //   },
    //   triggerRefresh,
    // )

    us_setElectricityBill(value as number)
    us_setLeaseCost(leaseCost)
    us_setBaseCashCost(baseCashCost)
    us_setCurrentCashCost(currentCashCost)
    us_setNumPanels(numPanels)
    us_setSelectedSystemSizeInWatts(numPanels * PANEL_WATT_CAPACITY)
    us_setSelectedSystemAnnualProductionInKwh(preciseProduction)
    us_setCashSavings(cashSavings)
    us_setLeaseMonthlySavings(leaseMonthlySavings)
    us_setLeaseCumulativeSavings(leaseCumulativeSavings)
    us_setShowUtilityBillConfirmationModal(false)

    // set the active panels for the visualization
    setActivePanelsForVisualization(preciseProduction)
  }

  const handleDesignSubmissionClick = () => {
    us_setShowFinalSubmissionModal(true)
  }

  // JSX Generation
  // const rJSX_TempAnalyticsButton = (): JSX.Element => {
  //   let buttonJSX = <></>
  //   buttonJSX = (
  //     <Button
  //       variant="contained"
  //       color="primary"
  //       startIcon={<Icon icon="analytics" />}
  //       className="tw-m-2"
  //       onClick={() => {
  //         saveSessionData(
  //           clientKey,
  //           opportunityKey,
  //           us_analyticsSessionKey,
  //           {
  //             actions_taken: {
  //               clicked_test_button: true,
  //             },
  //           },
  //           !(ul_queryParams.get('tracking') == 'false'),
  //         )
  //       }}
  //     >
  //       Test Save Analytics Session Action Data
  //     </Button>
  //   )
  //   return buttonJSX
  // }
  const rJSX_ContactInfoModalContent = (): JSX.Element => {
    if (!us_leadData) {
      return <></>
    }

    const textJSX = (
      <Box>
        <Typography
          variant="h5"
          sx={{ color: teal, marginTop: '-8px', marginBottom: '6px', fontWeight: 'bold' }}
        >
          Save System
        </Typography>
        <Typography sx={{ fontSize: '14px', color: black }}>
          {rLIB(
            'Please add your information so we can save your system. This will also allow you to come back and pick up where you left off. We will not sell or share your information. You will not get a sales call from us (unless you reach out to us and/or request a call).',
          )}
        </Typography>
      </Box>
    )
    let modalContentJSX = <></>

    modalContentJSX = (
      <Box sx={{ px: 2, maxWidth: '300px' }}>
        {/* <Card sx={{ p: 2 }}> */}
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', color: 'black', fontWeight: 'bold' }}
          >
            {textJSX}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ textAlign: 'center' }}>
          <FormControl>
            <Typography
              variant="h6"
              sx={{ marginBottom: '15px' }}
            >
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'teal', display: 'flex', alignItems: 'center' }}
              >
                <Icon
                  icon="user"
                  sx={{ mr: 2 }}
                />
                {rLIB('Your Name')}:
              </Box>
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'darkGray' }}
              >
                <TextField
                  onChange={(event: any) => us_setCustomerName(event.target.value as string)}
                  value={us_customerName}
                ></TextField>
              </Box>
            </Typography>
            <Typography
              variant="h6"
              sx={{ marginBottom: '15px' }}
            >
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'teal', display: 'flex', alignItems: 'center' }}
              >
                <Icon
                  icon="envelope"
                  sx={{ mr: 2 }}
                />
                {rLIB('Your Email')}:
              </Box>
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'darkGray' }}
              >
                <TextField
                  onChange={(event: any) => {
                    us_setCustomerEmail(event.target.value as string)
                  }}
                  value={us_customerEmail}
                ></TextField>
              </Box>
            </Typography>
            <Typography variant="h6">
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'teal', display: 'flex', alignItems: 'center' }}
              >
                <Icon
                  icon="phone"
                  sx={{ mr: 2 }}
                />
                {rLIB('Your Phone')}:
              </Box>
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'darkGray' }}
              >
                <TextField
                  onChange={(event: any) => us_setCustomerPhone(event.target.value as string)}
                  value={us_customerPhone}
                ></TextField>
              </Box>
            </Typography>
          </FormControl>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mx: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
          <Typography
            variant="caption"
            sx={{ color: darkGray }}
          >
            I authorize ETW Energy, LLC ("ETW") to call me and send pre-recorded messages and text messages to me about ETW products and services via the
            contact information I provide, even if I am on a national or state “Do Not Call” list. Message and data rates may apply. Maximum 10 texts per month.
            Consent for calls & texts is optional. I can opt out anytime. I also agree to ETW's Terms of Service.
          </Typography>
          <Checkbox
            checked={us_tcpaAccepted}
            onChange={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_tcpa_checkbox: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              us_setTcpaAccepted(!us_tcpaAccepted)
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_contact_info_form_submission_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              updateOpportunity({ tcpa_accepted: true, phone: us_customerPhone, email: us_customerEmail }, false)
              requestProposeDataset()
            }}
            disabled={!us_tcpaAccepted || checkIfContactInfoIsIncomplete()}
          >
            <Typography>{rLIB('Save and Proceed')}</Typography>
          </Button>
          <Button
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_contact_info_form_toggle_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              us_setShowContactInfoModal(false)
            }}
          >
            <Typography sx={{ marginTop: '8px' }}>Cancel</Typography>
          </Button>
        </Box>
        {/* </Card> */}
      </Box>
    )
    return modalContentJSX
  }

  const rJSX_EditUtilityModalContent = (): JSX.Element => {
    if (!us_leadData) {
      return <></>
    }

    let modalContentJSX = <></>
    // let newLocal = us_utilityCompanyName ? us_utilityCompanyName : 'Your company here...'

    modalContentJSX = (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px' }}>
        <Typography
          variant="h6"
          sx={{ marginBottom: '10px' }}
        >
          Edit Utility Settings
        </Typography>
        <Autocomplete
          fullWidth
          value={us_utilityCompanyName ? { label: us_utilityCompanyName } : {}}
          onChange={(event, newValue) => {
            if (newValue != null && newValue.label != null) {
              us_setUtilityCompanyName(newValue ? newValue.label : '')
              updateOpportunity({ contact_utility_provider: newValue ? newValue.label : '' }, false)
            }
          }}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          options={us_utilityCompanies}
          getOptionLabel={(option) => {
            if (option != null && option.label != null) {
              return option.label
            } else {
              return ''
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={us_utilityCompanyName}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Box sx={{ marginTop: '10px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h5">{us_electricityBill ? formatNumberToDollar(us_electricityBill) : formatNumberToDollar(200)}</Typography>
          <Slider
            sx={{ height: 10, width: '100%', marginX: 'auto' }}
            value={us_electricityBill ? us_electricityBill : 200}
            valueLabelDisplay="auto"
            // marks
            step={5}
            min={50}
            max={300}
            onChange={(event, value) => {
              handleUtilityBillChange(value as number)
            }}
            onChangeCommitted={(event, value) => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    committed_utility_slider_change: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              handleUtilityBillChange(value as number, true)
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_show_edit_utility_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              us_setShowEditUtilityModal(false)
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    )
    return modalContentJSX
  }

  // TODO: undo changes, testing to see how far users will go
  const rJSX_FinalSubmissionModalContent = () => {
    if (!us_leadData) {
      return <></>
    }

    let modalContentJSX = <></>

    modalContentJSX = (
      <Box sx={{ px: 2, maxWidth: '300px' }}>
        <Box sx={{ mt: 2 }}>
          {/* Summary of order: system size, num panels, system production, estimated price */}
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold', color: teal, marginTop: '-8px', marginBottom: '6px', textAlign: 'center' }}
          >
            Order Summary
          </Typography>
          <Box>
            <Typography>
              <b>System Size</b>: {(us_selectedSystemSizeInWatts / 1000).toFixed(1)} Watts
            </Typography>
            <Typography>
              <b>System Production</b>: {us_selectedSystemAnnualProductionInKWh.toFixed(0)} kWh
            </Typography>
            <Typography>
              <b>Number of Panels</b>: {us_numPanels.toFixed(0)}
            </Typography>
            <Typography>
              <b>Estimated Price</b>: {formatNumberToDollar(us_selectedFinancingType === 'cash' ? us_currentCashCost : us_leaseCost)}
              {us_selectedFinancingType === 'cash' ? ' (with incentives)' : '/mo'}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography sx={{ color: darkGray, fontSize: '14px' }}>
              Note: This is <b>not</b> a commitment to purchase. Submitting an order will alert one of our team members to review your design and get in contact
              with you.
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />

        {/* <Box sx={{ px: 2, maxWidth: '300px' }}> */}
        {/* <Card sx={{ p: 2 }}> */}
        {/* <Box sx={{ mt: 2 }}> */}
        {/* <Typography
            variant="h6"
            sx={{ textAlign: 'center', color: 'black', fontWeight: 'bold' }}
          >
            Test
          </Typography> */}
        {/* </Box> */}
        {/* <Divider sx={{ my: 2 }} /> */}
        <Box sx={{ textAlign: 'center' }}>
          <FormControl>
            <Typography
              variant="h6"
              sx={{ marginBottom: '15px' }}
            >
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'teal', display: 'flex', alignItems: 'center' }}
              >
                <Icon
                  icon="user"
                  sx={{ mr: 2 }}
                />
                {rLIB('Your Name')}:
              </Box>
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'darkGray' }}
              >
                <TextField
                  onChange={(event: any) => us_setCustomerName(event.target.value as string)}
                  value={us_customerName}
                ></TextField>
              </Box>
            </Typography>
            <Typography
              variant="h6"
              sx={{ marginBottom: '15px' }}
            >
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'teal', display: 'flex', alignItems: 'center' }}
              >
                <Icon
                  icon="envelope"
                  sx={{ mr: 2 }}
                />
                {rLIB('Your Email')}:
              </Box>
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'darkGray' }}
              >
                <TextField
                  onChange={(event: any) => {
                    us_setCustomerEmail(event.target.value as string)
                  }}
                  value={us_customerEmail}
                ></TextField>
              </Box>
            </Typography>
            <Typography variant="h6">
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'teal', display: 'flex', alignItems: 'center' }}
              >
                <Icon
                  icon="phone"
                  sx={{ mr: 2 }}
                />
                {rLIB('Your Phone')}:
              </Box>
              <Box
                component={'span'}
                sx={{ mr: 2, color: 'darkGray' }}
              >
                <TextField
                  onChange={(event: any) => us_setCustomerPhone(event.target.value as string)}
                  value={us_customerPhone}
                ></TextField>
              </Box>
            </Typography>
          </FormControl>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ mx: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
          <Typography
            variant="caption"
            sx={{ color: darkGray }}
          >
            I authorize ETW Energy, LLC ("ETW") to call me and send pre-recorded messages and text messages to me about ETW products and services via the
            contact information I provide, even if I am on a national or state “Do Not Call” list. Message and data rates may apply. Maximum 10 texts per month.
            Consent for calls & texts is optional. I can opt out anytime. I also agree to ETW's Terms of Service.
          </Typography>
          <Checkbox
            checked={us_tcpaAccepted}
            onChange={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_tcpa_checkbox: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              us_setTcpaAccepted(!us_tcpaAccepted)
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            variant="contained"
            disabled={checkIfContactInfoIsIncomplete() || !us_tcpaAccepted}
            onClick={() => {
              const submissionDate = new Date()
              updateOpportunity(
                {
                  tcpa_accepted: true,
                  phone: us_customerPhone,
                  email: us_customerEmail,
                  name: us_customerName,
                  submitted: true,
                  submission_date: submissionDate,
                  lease_cost: us_leaseCost,
                  base_cash_cost: us_baseCashCost,
                  current_cash_cost: us_currentCashCost,
                  num_panels: us_numPanels,
                  selected_system_size: us_selectedSystemSizeInWatts,
                  selected_annual_production: us_selectedSystemAnnualProductionInKWh,
                  cash_savings: us_cashSavings,
                  lease_monthly_savings: us_leaseMonthlySavings,
                  lease_cumulative_savings: us_leaseCumulativeSavings,
                  contact_monthly_electric_bill: us_electricityBill,
                  offset: us_offset,
                  active_propose_panels: us_activeProposePanels,
                  submitted_size: us_selectedSystemSizeInWatts / 1000,
                  submitted_production: us_selectedSystemAnnualProductionInKWh,
                  submitted_num_panels: us_numPanels,
                  submitted_financing_type: us_selectedFinancingType.toUpperCase(),
                  submitted_price: us_selectedFinancingType === 'cash' ? us_currentCashCost : us_leaseCost,
                },
                true,
              )
              us_setSubmitted(true)
              us_setSubmittedDate(submissionDate)
              us_setSubmittedFinancingType(us_selectedFinancingType.toUpperCase())
              us_setSubmittedPrice(us_selectedFinancingType === 'cash' ? us_currentCashCost : us_leaseCost)
              us_setSubmittedNumPanels(us_numPanels)
              us_setSubmittedSize(us_selectedSystemSizeInWatts / 1000)
              us_setSubmittedProduction(us_selectedSystemAnnualProductionInKWh)
              // send email to etw with summary order data
              cloudFunctionUnauthenticatedRequests({
                function: 'sendSalesLeadEmail',
                to_array: ['leads.curiosity@etwenergy.com'], // this is set on the server so either way it will only send to this email
                cc_array: ['brady.richardson@etwenergy.com', 'raphael@etwenergy.com', 'john.morrell@etwenergy.com'],
                bcc_array: [''],
                subject: `New Order Submitted: ${us_customerName}, ${us_leadData?.opportunityData.address1}`,
                body: `A new order has been submitted by ${us_customerName} for the property at:\n\n${us_leadData?.opportunityData.address1}\n\nCustomer phone: ${us_customerPhone}\nCustomer email: ${us_customerEmail}\n\nThe order summary is as follows:\nSystem Size: ${(us_selectedSystemSizeInWatts / 1000).toFixed(1)} kW\nSystem Production: ${us_selectedSystemAnnualProductionInKWh.toFixed(0)} kWh/yr\nNumber of Panels: ${us_numPanels.toFixed(0)}\nEstimated Price (${us_selectedFinancingType === 'cash' ? 'Cash' : 'Lease'}): ${formatNumberToDollar(us_selectedFinancingType === 'cash' ? us_currentCashCost : us_leaseCost)}${us_selectedFinancingType === 'cash' ? ' (with incentives)' : '/mo'}\n\nPlease follow up with the customer.`,
              })
              us_setShowFinalSubmissionModal(false)
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_final_submission_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
            }}
          >
            <Typography>Submit</Typography>
          </Button>
          <Button
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_show_final_submission_modal_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              us_setShowFinalSubmissionModal(false)
            }}
          >
            <Typography sx={{ marginTop: '8px' }}>Cancel</Typography>
          </Button>
        </Box>
      </Box>
    )
    return modalContentJSX
  }

  // House Confirmation Modal Content
  const rJSX_HouseConfirmationModalContent = (): JSX.Element => {
    if (!us_leadData) {
      return <></>
    }

    const mapSettings: TsInterface_MapSettings = {
      center_lat: getProp(us_leadData?.googleSolarData['center'], 'latitude', 0),
      center_lng: getProp(us_leadData?.googleSolarData['center'], 'longitude', 0),
      map_type_id: 'satellite',
      height: '500px',
      width: '300px',
      zoom: 20,
      tilt: 0,
    }
    const houseMarker: TsInterface_MapMarker = {
      label: '',
      position: {
        lat: mapSettings.center_lat,
        lng: mapSettings.center_lng,
      },
    }
    let mapMarkers: TsInterface_MapMarkers = { houseMarker }
    let modalContentJSX = <></>

    modalContentJSX = (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          variant="h6"
          sx={{ color: teal }}
        >
          {rLIB('Is this your house?')}
        </Typography>
        <MapBasic
          mapMarkers={mapMarkers}
          mapSettings={mapSettings}
        ></MapBasic>
        <Box sx={{ marginTop: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_show_confirm_house_modal_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              us_setShowHouseConfirmationModal(false)
              us_setShowUtilityCompanyConfirmationModal(true)
            }}
            sx={{ marginRight: '20px' }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => console.log('No clicked')}
          >
            No
          </Button>
        </Box>
      </Box>
    )
    return modalContentJSX
  }

  // Utility Company Confirmation Modal Content
  const rJSX_UtilityCompanyConfirmationModalContent = (): JSX.Element => {
    if (!us_leadData) {
      return <></>
    }

    // this will notify the company that the user has opened the page

    let modalContentJSX = <></>

    modalContentJSX = (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          variant="h6"
          sx={{ color: teal, textAlign: 'center', marginBottom: '5px' }}
        >
          {rLIB('Please select your utility company. If you are unsure, you can leave it as is and come back to it later.')}
        </Typography>
        {/* <Typography
          variant="h5"
          sx={{ marginBottom: '10px' }}
        >
          {us_utilityCompanyName ? us_utilityCompanyName : 'Your utility company here...'}
        </Typography> */}
        <Autocomplete
          fullWidth
          value={us_utilityCompanyName ? { label: us_utilityCompanyName } : {}}
          onChange={(event, newValue) => {
            if (newValue != null && newValue.label != null) {
              us_setUtilityCompanyName(newValue ? newValue.label : '')
              updateOpportunity({ contact_utility_provider: newValue ? newValue.label : '' }, false)
            }
          }}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          options={us_utilityCompanies}
          getOptionLabel={(option) => {
            if (option != null && option.label != null) {
              return option.label
            } else {
              return ''
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={us_utilityCompanyName}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_confirm_utility_company_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              us_setShowUtilityCompanyConfirmationModal(false)
              us_setShowUtilityBillConfirmationModal(true)
            }}
            sx={{ marginRight: '20px' }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    )
    return modalContentJSX
  }

  // Utility Bill Confirm Modal Content
  const rJSX_UtilityBillConfirmationModalContent = (): JSX.Element => {
    if (!us_leadData) {
      return <></>
    }

    let modalContentJSX = <></>

    modalContentJSX = (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          variant="h6"
          sx={{ color: teal }}
        >
          {rLIB('What is your average utility bill each month?')}
        </Typography>
        <Typography variant="h5">{us_electricityBill ? formatNumberToDollar(us_electricityBill) : formatNumberToDollar(200)}</Typography>
        <Slider
          sx={{ height: 10, width: '100%', marginX: 'auto' }}
          value={us_electricityBill ? us_electricityBill : 200}
          valueLabelDisplay="auto"
          // marks
          step={5}
          min={0}
          max={500}
          onChange={(event, value) => us_setElectricityBill(value as number)}
          onChangeCommitted={(event, value) => {
            saveSessionData(
              clientKey,
              opportunityKey,
              us_analyticsSessionKey,
              {
                actions_taken: {
                  committed_utility_confirmation_slider_change: true,
                },
              },
              !(ul_queryParams.get('tracking') == 'false'),
            )
            us_setElectricityBill(value as number)
          }}
        />
        <Box sx={{ marginTop: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_confirm_utility_bill_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              handleUtilityBillChange(us_electricityBill ? us_electricityBill : 200, true)
            }}
            sx={{ marginRight: '20px' }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    )
    return modalContentJSX
  }

  // Confirmation Modal
  const rJSX_Modal = (isOpen: boolean, content: JSX.Element): JSX.Element => {
    let modalJSX = <></>
    modalJSX = (
      <Dialog
        open={isOpen}
        // onClose={onClose}
      >
        <DialogContent>{content}</DialogContent>
      </Dialog>
    )
    return modalJSX
  }

  // Error View
  const rJSX_ErrorDisplay = (): JSX.Element => {
    let errorsJSX = <></>
    errorsJSX = (
      <Box
        className="tw-text-center tw-m-auto tw-rounded-lg tw-p-4"
        sx={{ maxWidth: '600px', border: '2px solid ' + themeVariables.error_main }}
      >
        <Typography
          variant="h5"
          sx={{ color: themeVariables.error_main }}
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2"
          />
          {rLIB('Failed to load property data')}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: themeVariables.black }}
          className="tw-mt-2"
        >
          {rLIB('If this error persists please contact ETW Energy for support')}
        </Typography>
      </Box>
    )
    return errorsJSX
  }

  // Customer Info Tab Content
  const rJSX_ContactInfoTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    if (
      us_utilityCompanyName == null ||
      us_utilityCompanyName === '' ||
      us_customerName == null ||
      us_customerName === '' ||
      us_customerEmail == null ||
      us_customerEmail === '' ||
      us_customerPhone == null ||
      us_customerPhone === '' ||
      !us_electricityBill
    ) {
      headerJSX = (
        <Box>
          <Icon
            icon="circle-exclamation"
            className="tw-mr-1"
            sx={{ color: themeVariables.warning_main }}
          />
          {rLIB('Contact Info')}
        </Box>
      )
    } else {
      headerJSX = (
        <Box>
          <Icon
            icon="circle-check"
            className="tw-mr-1"
            sx={{ color: themeVariables.success_main }}
          />
          {rLIB('Contact Info')}
        </Box>
      )
    }
    return headerJSX
  }

  const rJSX_UtilityCompanyAutocomplete = (): JSX.Element => {
    // const newLocal = 'Your Utility Company'
    const newLocal = 'Commonwealth Edison Co'
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Box className="">
          <Typography
            variant="h6"
            className="tw-font-bold"
            sx={{ color: teal }}
          >
            <Icon
              icon="utility-pole-double"
              className="tw-mr-2"
            />
            {rLIB('Utility Company')}
            <Icon
              icon="info-circle"
              className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB(
                "Your utility company is the company that provides your electricity. If you're not sure, check your electricity bill. This information helps us calculate your utility rate and the best system size for your home.",
              )}
              onClick={() => {
                saveSessionData(
                  clientKey,
                  opportunityKey,
                  us_analyticsSessionKey,
                  {
                    actions_taken: {
                      clicked_utility_company_info_button: true,
                    },
                  },
                  !(ul_queryParams.get('tracking') == 'false'),
                )
                openCustomTooltipDialog(
                  rLIB('Utility Company'),
                  rLIB(
                    "Your utility company is the company that provides your electricity. If you're not sure, check your electricity bill. This information helps us calculate your utility rate and the best system size for your home.",
                  ),
                )
              }}
            />
          </Typography>
          <FormControl
            className="tw-my-2"
            fullWidth
          >
            <Autocomplete
              value={us_utilityCompanyName ? { label: us_utilityCompanyName } : { label: 'Commonwealth Edison Co' }}
              onChange={(event, newValue) => {
                if (newValue != null && newValue.label != null) {
                  // console.log('UPDATE')
                  // updateOpportunity({ contact_utility_provider: newValue ? newValue.label : '' })
                  us_setUtilityCompanyName(newValue ? newValue.label : '')
                }
              }}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              options={us_utilityCompanies}
              getOptionLabel={(option) => {
                if (option != null && option.label != null) {
                  return option.label
                } else {
                  return ''
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={rLIB(newLocal)}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Box>
      </Card>
    )
  }

  const rJSX_ContactInfoTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-mx-2">
        <Card className="tw-p-2 tw-mb-2">
          <Box className="tw-mt-0">
            <Typography
              variant="h6"
              className="tw-font-bold"
              sx={{ color: teal }}
            >
              <Icon
                icon="address-card"
                className="tw-mr-2"
              />
              {rLIB('Contact Info')}
            </Typography>

            <FormControl
              className="tw-my-2"
              fullWidth
            >
              <TextField
                label={rLIB('Your Name')}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  us_setCustomerName(event.target.value || '')
                  us_setCustomerNameChanged(true)
                }}
                onBlur={(event) => {
                  if (us_customerNameChanged === true) {
                    // updateOpportunity({ name: event.target.value })
                    us_setCustomerNameChanged(false)
                  }
                }}
                value={us_customerName}
              />
            </FormControl>
            <FormControl
              className="tw-my-2"
              fullWidth
            >
              <TextField
                label={rLIB('Your Email')}
                variant="outlined"
                fullWidth
                value={us_customerEmail || ''}
                onChange={(event) => {
                  us_setCustomerEmail(event.target.value)
                  us_setCustomerEmailChanged(true)
                }}
                onBlur={(event) => {
                  if (us_customerEmailChanged === true) {
                    // updateOpportunity({ email: event.target.value })
                    us_setCustomerEmailChanged(false)
                  }
                }}
              />
            </FormControl>
            <FormControl
              className="tw-my-2"
              fullWidth
            >
              <TextField
                label={rLIB('Your Phone')}
                variant="outlined"
                fullWidth
                value={us_customerPhone || ''}
                onChange={(event) => {
                  us_setCustomerPhone(event.target.value)
                  us_setCustomerPhoneChanged(true)
                }}
                onBlur={(event) => {
                  if (us_customerPhoneChanged === true) {
                    // updateOpportunity({ phone: event.target.value })
                    us_setCustomerPhoneChanged(false)
                  }
                }}
              />
            </FormControl>
          </Box>
        </Card>
        {rJSX_UtilityCompanyAutocomplete()}
      </Box>
    )
    return tabJSX
  }

  // System Tab Content
  const rJSX_SystemTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    headerJSX = (
      <Box>
        <Icon
          icon="solar-panel"
          className="tw-mr-1"
          sx={{ color: teal }}
        />
        {rLIB('System')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_LoadingPriceIcon = (): JSX.Element => {
    let iconJSX = <></>
    if (us_updatingPricingNumbers === true) {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-ml-2"
        />
      )
    }
    return iconJSX
  }

  const rJSX_SystemSizeVisualizationSegment = (percent: number, offset: number): JSX.Element => {
    let segmentJSX = <></>
    if (percent <= offset) {
      segmentJSX = (
        <Box sx={{ paddingLeft: '1px', paddingRight: '1px', width: '5%' }}>
          <Box
            className="tw-rounded"
            sx={{ backgroundColor: yellow, height: '20px', width: '100%' }}
          ></Box>
        </Box>
      )
    } else {
      segmentJSX = (
        <Box sx={{ paddingLeft: '1px', paddingRight: '1px', width: '5%' }}>
          <Box
            className="tw-rounded"
            sx={{ border: '2px solid ' + yellow, height: '20px', width: '100%' }}
          ></Box>
        </Box>
      )
    }
    return segmentJSX
  }

  const rJSX_SystemSizeVisualizationFull = (): JSX.Element => {
    let visualizationJSX = <></>
    let systemOffsetPercent = calculateOffset(us_electricityBill, us_selectedSystemAnnualProductionInKWh)
    visualizationJSX = (
      <Stack
        direction="row"
        spacing="0"
      >
        {rJSX_SystemSizeVisualizationSegment(5, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(10, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(15, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(20, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(25, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(30, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(35, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(40, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(45, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(50, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(55, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(60, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(65, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(70, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(75, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(80, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(85, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(90, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(95, systemOffsetPercent)}
        {rJSX_SystemSizeVisualizationSegment(100, systemOffsetPercent)}
      </Stack>
    )
    return visualizationJSX
  }

  const rJSX_UtilityBillSection = (): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
          sx={{ color: teal }}
        >
          <Icon
            icon="bolt"
            className="tw-mr-2"
          />
          {rLIB('Utility Bill')}
          <Icon
            icon="info-circle"
            className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
            tooltip={rLIB(
              'We use your utility bill to estimate your electricity usage and calculate the size of the solar system you need to offset your electricity bill',
            )}
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_utility_bill_info_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              openCustomTooltipDialog(
                rLIB('Utility Bill'),
                rLIB(
                  'We use your utility bill to estimate your electricity usage and calculate the size of the solar system you need to offset your electricity bill',
                ),
              )
            }}
          />
        </Typography>
        <Typography variant="h6">{formatNumberToDollar(us_electricityBill)}</Typography>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_SystemSizeSection = (): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold tw-inline-block"
            sx={{ color: teal }}
          >
            <Icon
              icon="solar-panel"
              className="tw-mr-2"
            />
            {rLIB('System Size')}
            <Box
              component={'span'}
              className="tw-inline-block tw-ml-1"
              sx={{ color: teal }}
            >
              ({(us_selectedSystemSizeInWatts / 1000).toFixed(1)} {rLIB('kW')})
            </Box>
            <Icon
              icon="info-circle"
              className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB(
                'Your system is composed of solar panels that convert sunlight into electricity. The more panels you have, the more electricity you can produce.',
              )}
              onClick={() => {
                saveSessionData(
                  clientKey,
                  opportunityKey,
                  us_analyticsSessionKey,
                  {
                    actions_taken: {
                      clicked_system_size_info_button: true,
                    },
                  },
                  !(ul_queryParams.get('tracking') == 'false'),
                )
                openCustomTooltipDialog(
                  rLIB('System Size'),
                  rLIB(
                    'Your system is composed of solar panels that convert sunlight into electricity. The more panels you have, the more electricity you can produce.',
                  ),
                )
              }}
            />
          </Typography>
        </Box>
        <Grid2
          container
          sx={{ flexDirection: 'row' }}
        >
          <Grid2
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
          >
            <Slider
              sx={{ height: 10, width: '100%', marginX: 'auto' }}
              value={us_numPanels}
              valueLabelDisplay="auto"
              // marks
              step={1}
              min={0}
              max={us_systemRegressionSliderData.length - 1}
              onChange={(event, value) => handleSystemSizeSliderChange(event, value as number)}
              onChangeCommitted={(event, value) => handleSystemSizeSliderChange(event, value as number)} // TODO: replace with database update function
              disabled={us_updatingPricingNumbers === true}
            />
          </Grid2>
          <Grid2
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className="tw-text-center"
          >
            <Typography
              variant="h5"
              sx={{ color: teal, fontWeight: 'bold', marginBottom: '-5px' }}
            >
              {us_numPanels}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: teal }}
            >
              {rLIB('Panels')}
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    )

    return sectionJSX
  }

  const rJSX_ProductionSection = (): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Icon
              sx={{ color: darkGray }}
              icon="info-circle"
              className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB(
                'Offset is the percentage of your electricity bill that will be covered by solar production. The more panels in your system, the greater the offset.',
              )}
              size={'lg'}
              onClick={() => {
                saveSessionData(
                  clientKey,
                  opportunityKey,
                  us_analyticsSessionKey,
                  {
                    actions_taken: {
                      clicked_production_info_button: true,
                    },
                  },
                  !(ul_queryParams.get('tracking') == 'false'),
                )
                openCustomTooltipDialog(
                  rLIB('Production'),
                  rLIB(
                    'Offset is the percentage of your electricity bill that will be covered by solar production. The more panels in your system, the greater the offset.',
                  ),
                )
              }}
            />
          </Box>
          <Typography
            variant="h6"
            className="tw-font-bold tw-inline-block"
            sx={{ color: teal }}
          >
            <Icon
              icon="battery-bolt"
              className="tw-mr-2"
            />
            {rLIB('Production')} ({us_selectedSystemAnnualProductionInKWh.toFixed(0)} {rLIB('kWh/year')})
          </Typography>
        </Box>
        <Grid2
          container
          sx={{ flexDirection: 'row' }}
        >
          <Grid2
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
          >
            <Box className="tw-mt-3">{rJSX_SystemSizeVisualizationFull()}</Box>
          </Grid2>
          <Grid2
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className="tw-text-center"
          >
            <Typography
              variant="h5"
              sx={{ color: yellow, fontWeight: 'bold', marginBottom: '-5px' }}
            >
              {calculateOffset(us_electricityBill, us_selectedSystemAnnualProductionInKWh).toFixed(0)}%
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: yellow }}
            >
              {rLIB('Offset')}
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_LeasePricingLabel = (): JSX.Element => {
    let labelJSX = <></>
    if (us_updatingPricingNumbers) {
      labelJSX = <>{rLIB('Calculating')}</>
    } else if (us_leasePricingError || us_leaseCost === 0) {
      labelJSX = <>{rLIB('Unknown')}</>
    } else {
      labelJSX = (
        <>
          {formatNumberToDollar(us_leaseCost)} / {rLIB('mo')}
        </>
      )
    }
    return labelJSX
  }

  const rJSX_CashPricingLabel = (): JSX.Element => {
    let labelJSX = <></>
    if (us_updatingPricingNumbers) {
      labelJSX = <>{rLIB('Calculating')}</>
    } else {
      labelJSX = <>{formatNumberToDollar(us_currentCashCost)}</>
    }
    return labelJSX
  }

  const rJSX_CashSavingsLabel = (): JSX.Element => {
    let labelJSX = <></>
    if (us_updatingPricingNumbers) {
      labelJSX = <>{rLIB('Calculating')}</>
    } else {
      labelJSX = <>{formatNumberToDollar(us_cashSavings)}</>
    }
    return labelJSX
  }

  const rJSX_LeaseSavingsLabel = (): JSX.Element => {
    let labelJSX = <></>
    if (us_updatingPricingNumbers) {
      labelJSX = <>{rLIB('Calculating')}</>
    } else if (us_leasePricingError || us_leaseCost === 0) {
      labelJSX = <>{rLIB('Unknown')}</>
    } else {
      labelJSX = (
        <>
          {formatNumberToDollar(us_leaseMonthlySavings)} / {rLIB('month')}
        </>
      )
    }
    return labelJSX
  }

  const rJSX_PricesSection = (): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
          sx={{ color: teal }}
        >
          <Icon
            icon="circle-dollar"
            className="tw-mr-2"
          />
          {rLIB('Estimated Prices')}
          <Icon
            icon="info-circle"
            className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
            tooltip={rLIB(
              'The estimated prices are based on the system size and production ratio. The final price may vary based on additional adders and incentives.',
            )}
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_prices_info_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              openCustomTooltipDialog(
                rLIB('Estimated Prices'),
                rLIB(
                  'The estimated prices are based on the system size and production ratio. The final price may vary based on additional adders and incentives.',
                ),
              )
            }}
          />
          {rJSX_LoadingPriceIcon()}
        </Typography>
        <Grid2
          container
          sx={{ flexDirection: 'row' }}
        >
          <Grid2
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="tw-text-center tw-px-2"
          >
            <Box
              className="tw-inline-block tw-rounded-full tw-w-full tw-py-1 tw-px-2 tw-truncate"
              sx={{ background: us_updatingPricingNumbers ? darkGray : green, fontSize: '1.2rem', color: white, height: '36px' }}
            >
              {/* <Box>{formatNumberToDollar(us_baseCashCost)}</Box> */}
              {/* <Box>{formatNumberToDollar(us_currentCashCost)}</Box> */}
              {rJSX_CashPricingLabel()}
            </Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
              sx={{ color: us_updatingPricingNumbers ? darkGray : green, verticalAlign: 'top', marginTop: '-4px' }}
            >
              {rLIB('Cash')}*
              <Icon
                icon="info-circle"
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
                tooltip={
                  <>
                    {rLIB(
                      'Own your system outright with a single upfront payment. The displayed price includes all incentives. Without incentives the price is ',
                    )}
                    {formatNumberToDollar(us_baseCashCost)}
                  </>
                }
                onClick={() => {
                  saveSessionData(
                    clientKey,
                    opportunityKey,
                    us_analyticsSessionKey,
                    {
                      actions_taken: {
                        clicked_cash_info_button: true,
                      },
                    },
                    !(ul_queryParams.get('tracking') == 'false'),
                  )
                  openCustomTooltipDialog(
                    rLIB('Cash'),
                    <>
                      {rLIB(
                        'Own your system outright with a single upfront payment. The displayed price includes all incentives. Without incentives the price is ',
                      )}{' '}
                      {formatNumberToDollar(us_baseCashCost)}
                    </>,
                  )
                }}
              />
            </Typography>
          </Grid2>
          <Grid2
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="tw-text-center tw-px-2"
          >
            <Box
              className="tw-inline-block tw-rounded-full tw-w-full tw-py-1 tw-px-2 tw-truncate"
              sx={{
                background: us_updatingPricingNumbers || us_leasePricingError || us_leaseCost === 0 ? darkGray : green,
                fontSize: '1.2rem',
                color: white,
                height: '36px',
              }}
            >
              {rJSX_LeasePricingLabel()}
            </Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
              sx={{
                color: us_updatingPricingNumbers || us_leasePricingError || us_leaseCost === 0 ? darkGray : green,
                verticalAlign: 'top',
                marginTop: '-4px',
              }}
            >
              {rLIB('Financed')}
              <Icon
                icon="info-circle"
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
                tooltip={rLIB('No upfront cost. Make monthly payments that replace part or all of your utility bill.')}
                onClick={() => {
                  saveSessionData(
                    clientKey,
                    opportunityKey,
                    us_analyticsSessionKey,
                    {
                      actions_taken: {
                        clicked_financed_info_button: true,
                      },
                    },
                    !(ul_queryParams.get('tracking') == 'false'),
                  )
                  openCustomTooltipDialog(rLIB('Financed'), rLIB('No upfront cost. Make monthly payments that replace part or all of your utility bill.'))
                }}
              />
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_SimplePricesSection = (isEditMode: boolean = false): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Grid2
          container
          sx={{ flexDirection: 'row' }}
        >
          <Grid2
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          ></Grid2>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
            <Typography sx={{ color: darkGray, marginBottom: '6px', marginX: 'auto' }}>Select a financing option below:</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
              <Grid2
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                className="tw-text-center tw-px-2"
              >
                <Card sx={{ padding: '10px', backgroundColor: us_selectedFinancingType === 'cash' ? darkGray : 'white' }}>
                  <Box
                    sx={{ height: '100%', width: '100%' }}
                    onClick={() => {
                      saveSessionData(
                        clientKey,
                        opportunityKey,
                        us_analyticsSessionKey,
                        {
                          actions_taken: {
                            selected_cash_financing_type_card: true,
                          },
                        },
                        !(ul_queryParams.get('tracking') == 'false'),
                      )
                      us_setSelectedFinancingType('cash')
                    }}
                  >
                    <Box
                      className="tw-inline-block tw-rounded-full tw-w-full tw-py-1 tw-px-2 tw-truncate"
                      sx={{
                        borderColor: us_updatingPricingNumbers ? darkGray : teal,
                        color: us_updatingPricingNumbers ? darkGray : black,
                        borderWidth: '2px',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        // color: white,
                        height: '36px',
                      }}
                    >
                      {rJSX_CashPricingLabel()}
                    </Box>
                    <Box sx={{ marginTop: '4px' }}>
                      <Typography
                        variant="h6"
                        className="tw-font-bold"
                        sx={{
                          color: us_updatingPricingNumbers ? darkGray : teal,
                          verticalAlign: 'top',
                          fontSize: '19px',
                          display: 'inline-flex',
                        }}
                      >
                        {rLIB('Cash*')}
                      </Typography>
                      <Icon
                        icon="info-circle"
                        size={'xl'}
                        className="tw-mx-2 tw-opacity-30 hover:tw-opacity-100"
                        sx={{ marginLeft: '2px', cursor: 'pointer', color: us_selectedFinancingType === 'cash' ? lightGray : darkGray }}
                        tooltip={<>{rLIB('See a breakdown of the pricing estimates with associated incentives.')}</>}
                        onClick={() => {
                          saveSessionData(
                            clientKey,
                            opportunityKey,
                            us_analyticsSessionKey,
                            {
                              actions_taken: {
                                clicked_cash_info_button: true,
                              },
                            },
                            !(ul_queryParams.get('tracking') == 'false'),
                          )
                          openCustomTooltipDialog(
                            <>{rLIB('Pricing Estimate Details')}</>,
                            <Box>
                              <Typography sx={{ color: darkGray }}>
                                {rLIB(
                                  'Own your system outright with a single upfront payment or lease it over a 25-year period. The displayed price estimates automatically factor in all available incentives available in your area. Without incentives, the cash price is ',
                                )}
                              </Typography>
                              <Box>
                                <Typography
                                  sx={{ color: 'red' }}
                                  display="inline"
                                >
                                  {formatNumberToDollar(us_baseCashCost)}.
                                </Typography>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '20px', color: teal, marginTop: '5px' }}>
                                  {rLIB('Incentive Breakdown')}
                                </Typography>
                                <hr />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Typography sx={{ color: black }}>{rLIB('Cost Without Incentives: ')}</Typography>
                                  <Typography sx={{ color: 'red' }}>{formatNumberToDollar(us_baseCashCost)}</Typography>
                                </Box>
                                <hr />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Typography sx={{ color: black }}>{rLIB('Federal Tax Credit: ')}</Typography>
                                  <Typography sx={{ color: green }}>{formatNumberToDollar(us_federalIncentive)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Typography sx={{ color: black }}>{rLIB('SREC Incentive: ')}</Typography>
                                  <Typography sx={{ color: green }}>{formatNumberToDollar(us_srecIncentive)}</Typography>
                                </Box>
                                <hr />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Typography sx={{ color: black }}>{rLIB('Net Cash Price: ')}</Typography>
                                  <Typography sx={{ color: black }}>{formatNumberToDollar(us_currentCashCost)}</Typography>
                                </Box>
                              </Box>
                            </Box>,
                          )
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography sx={{ color: us_selectedFinancingType === 'cash' ? lightGray : darkGray, fontSize: '13px' }}>{'* After incentives'}</Typography>
                </Card>
              </Grid2>
              <Grid2
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                className="tw-text-center tw-px-2"
              >
                <Card sx={{ padding: '10px', backgroundColor: us_selectedFinancingType === 'lease' ? darkGray : 'white' }}>
                  <Box
                    sx={{ height: '100%', width: '100%' }}
                    onClick={() => {
                      saveSessionData(
                        clientKey,
                        opportunityKey,
                        us_analyticsSessionKey,
                        {
                          actions_taken: {
                            clicked_lease_financing_type_card: true,
                          },
                        },
                        !(ul_queryParams.get('tracking') == 'false'),
                      )
                      us_setSelectedFinancingType('lease')
                    }}
                  >
                    <Box
                      className="tw-inline-block tw-rounded-full tw-w-full tw-py-1 tw-px-2 tw-truncate"
                      sx={{
                        borderColor: us_updatingPricingNumbers ? darkGray : teal,
                        color: us_updatingPricingNumbers ? darkGray : black,
                        fontWeight: 'bold',
                        borderWidth: '2px',
                        fontSize: '1.2rem',
                        // color: white,
                        height: '36px',
                      }}
                    >
                      {rJSX_LeasePricingLabel()}
                    </Box>
                    <Typography
                      variant="h6"
                      className="tw-font-bold tw-mt-1"
                      sx={{
                        color: us_updatingPricingNumbers || us_leasePricingError || us_leaseCost === 0 ? darkGray : teal,
                        verticalAlign: 'top',
                        marginTop: '-4px',
                        fontSize: '19px',
                      }}
                    >
                      {rLIB('Solar Lease')}
                      <Icon
                        icon="info-circle"
                        size={'lg'}
                        className="tw-mx-2 tw-opacity-30 hover:tw-opacity-100"
                        sx={{ marginLeft: '2px', cursor: 'pointer', color: us_selectedFinancingType === 'lease' ? lightGray : darkGray }}
                        tooltip={<>{rLIB('See a breakdown of the pricing estimates with associated incentives.')}</>}
                        onClick={() => {
                          saveSessionData(
                            clientKey,
                            opportunityKey,
                            us_analyticsSessionKey,
                            {
                              actions_taken: {
                                clicked_lease_info_button: true,
                              },
                            },
                            !(ul_queryParams.get('tracking') == 'false'),
                          )
                          openCustomTooltipDialog(
                            <>{rLIB('Pricing Estimate Details')}</>,
                            <Box>
                              <Typography>
                                {rLIB(
                                  'A 25-year lease with monthly installments that replace part or all of your utility bill. Monthly savings are estimated to be ',
                                )}
                              </Typography>
                              <Typography sx={{ color: 'green' }}>{formatNumberToDollar(us_leaseMonthlySavings)}/mo,</Typography>
                              <Typography>{rLIB('and the cumulative savings over 25 years are estimated to be ')}</Typography>
                              <Typography sx={{ color: 'green' }}>{formatNumberToDollar(us_leaseCumulativeSavings)}.</Typography>
                            </Box>,
                          )
                        }}
                      />
                      <Typography sx={{ color: us_selectedFinancingType === 'lease' ? lightGray : darkGray, fontSize: '13px' }}>
                        {rLIB('$0 down, 25 year lease')}
                      </Typography>
                    </Typography>
                  </Box>
                </Card>
              </Grid2>
            </Box>
          </Box>
        </Grid2>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_SavingsSection = () => {
    let sectionJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
          sx={{ color: teal }}
        >
          <Icon
            icon="piggy-bank"
            className="tw-mr-2"
          />
          {rLIB('Estimated Savings')}
          <Icon
            icon="info-circle"
            className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
            tooltip={rLIB('Estimated savings are based on your current utility bill.')}
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_estimated_savings_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              openCustomTooltipDialog(rLIB('Estimated Savings'), rLIB('Estimated savings are based on your current utility bill.'))
            }}
          />
          {rJSX_LoadingPriceIcon()}
        </Typography>
        <Grid2
          container
          sx={{ flexDirection: 'row' }}
        >
          <Grid2
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="tw-text-center tw-px-2"
          >
            <Box
              className="tw-inline-block tw-rounded-full tw-w-full tw-py-1 tw-px-2 tw-truncate"
              sx={{ background: us_updatingPricingNumbers ? darkGray : lightGreen, fontSize: '1.2rem', color: white, height: '36px' }}
            >
              {/* <Box>{formatNumberToDollar(us_baseCashCost)}</Box> */}
              {/* <Box>{formatNumberToDollar(us_currentCashCost)}</Box> */}
              {rJSX_CashSavingsLabel()}
            </Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
              sx={{ color: us_updatingPricingNumbers ? darkGray : lightGreen, verticalAlign: 'top', marginTop: '-4px' }}
            >
              {rLIB('Cash Savings')}*
              <Icon
                icon="info-circle"
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
                tooltip={rLIB('Your cumulative estimated savings over the course of 25 years if you were to pay cash.')}
                onClick={() => {
                  saveSessionData(
                    clientKey,
                    opportunityKey,
                    us_analyticsSessionKey,
                    {
                      actions_taken: {
                        clicked_cash_savings_button_button: true,
                      },
                    },
                    !(ul_queryParams.get('tracking') == 'false'),
                  )
                  openCustomTooltipDialog(
                    rLIB('Total Cash Savings'),
                    rLIB('Your cumulative estimated savings over the course of 25 years if you were to pay cash.'),
                  )
                }}
              />
            </Typography>
          </Grid2>
          <Grid2
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="tw-text-center tw-px-2"
          >
            <Box
              className="tw-inline-block tw-rounded-full tw-w-full tw-py-1 tw-px-2 tw-truncate"
              sx={{
                background: us_updatingPricingNumbers || us_leasePricingError || us_leaseCost === 0 ? darkGray : lightGreen,
                fontSize: '1.2rem',
                color: white,
                height: '36px',
              }}
            >
              {rJSX_LeaseSavingsLabel()}
            </Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
              sx={{
                color: us_updatingPricingNumbers || us_leasePricingError || us_leaseCost === 0 ? darkGray : lightGreen,
                verticalAlign: 'top',
                marginTop: '-4px',
              }}
            >
              {rLIB('Monthly Savings')}*
              <Icon
                icon="info-circle"
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
                tooltip={
                  <>
                    {rLIB(
                      'Monthly savings are based on your current utility bill and the cost of the lease. The cumulative savings over a 25-year period would be ',
                    )}
                    {formatNumberToDollar(us_leaseCumulativeSavings)}
                  </>
                }
                onClick={() => {
                  saveSessionData(
                    clientKey,
                    opportunityKey,
                    us_analyticsSessionKey,
                    {
                      actions_taken: {
                        clicked_lease_savings_button: true,
                      },
                    },
                    !(ul_queryParams.get('tracking') == 'false'),
                  )
                  openCustomTooltipDialog(
                    rLIB('Financed'),
                    <>
                      {rLIB(
                        'Monthly savings are based on your current utility bill and the cost of the lease. The cumulative savings over a 25-year period would be ',
                      )}
                      {formatNumberToDollar(us_leaseCumulativeSavings)}
                    </>,
                  )
                }}
              />
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
    )

    return sectionJSX
  }

  // const rJSX_AddersSection = (): JSX.Element => {
  //   // TODO: Batteries
  //   let sectionJSX = <></>
  //   return sectionJSX
  // }

  const rJSX_SystemDetailsTab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-mx-2">
        <Card className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_PricesSection()}</Card>
        <Card className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_SavingsSection()}</Card>
        <Card className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_UtilityBillSection()}</Card>
        <Card className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_ProductionSection()}</Card>
        <Card className="tw-pl-4 tw-pr-2 tw-pt-2 tw-mb-2">{rJSX_SystemSizeSection()}</Card>
        {/* <Card className="tw-p-2 tw-mb-2">{rJSX_AddersSection()}</Card> */}
      </Box>
    )
    return tabJSX
  }

  // Customize Tab Content
  const rJSX_CustomizeTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    headerJSX = (
      <Box>
        <Icon
          icon="wrench"
          className="tw-mr-1"
          sx={{ color: teal }}
        />
        {rLIB('Customize')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_CustomizeTab = (): JSX.Element => {
    let tabJSX = <></>
    let textJSX = <></>
    let disableButton = true
    if (
      us_utilityCompanyName == null ||
      us_utilityCompanyName === '' ||
      us_customerName == null ||
      us_customerName === '' ||
      us_customerEmail == null ||
      us_customerEmail === '' ||
      us_customerPhone == null ||
      us_customerPhone === '' ||
      !us_electricityBill
    ) {
      textJSX = <>{rLIB('If you want to see the AI-generated panel layout on your roof and customize it, confirm your contact information.')}</>
    } else {
      textJSX = <>{rLIB('If you want to see the AI-generated panel layout on your roof and customize it, confirm your contact information.')}</>
      disableButton = false
    }
    tabJSX = (
      <Box className="tw-px-2">
        <Card className="tw-p-2">
          <Box className="tw-mt-2">
            <Typography
              variant="h6"
              className="tw-text-center"
              sx={{ color: black, fontWeight: 'bold' }}
            >
              {textJSX}
            </Typography>
          </Box>
          <Divider className="tw-my-2" />
          <Box className="tw-text-left">
            <Typography variant="h6">
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: teal }}
              >
                <Icon
                  icon="user"
                  className="tw-mr-2"
                />
                {rLIB('Your Name')}:
              </Box>
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: darkGray }}
              >
                {us_customerName}
              </Box>
            </Typography>
            <Typography variant="h6">
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: teal }}
              >
                <Icon
                  icon="envelope"
                  className="tw-mr-2"
                />
                {rLIB('Your Email')}:
              </Box>
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: darkGray }}
              >
                {us_customerEmail}
              </Box>
            </Typography>
            <Typography variant="h6">
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: teal }}
              >
                <Icon
                  icon="phone"
                  className="tw-mr-2"
                />
                {rLIB('Your Phone')}:
              </Box>
              <Box
                component={'span'}
                className="tw-mr-2"
                sx={{ color: darkGray }}
              >
                {us_customerPhone}
              </Box>
            </Typography>
          </Box>
          <Divider className="tw-my-2" />
          <Box className="tw-m-auto tw-text-center tw-mt-4">
            <Button
              variant="contained"
              onClick={() => {
                saveSessionData(
                  clientKey,
                  opportunityKey,
                  us_analyticsSessionKey,
                  {
                    actions_taken: {
                      clicked_contact_info_form_submission_button: true,
                    },
                  },
                  !(ul_queryParams.get('tracking') == 'false'),
                )
                requestProposeDataset()
              }}
              disabled={disableButton}
            >
              <Typography>Customize System</Typography>
            </Button>
          </Box>
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Shared Content
  const rJSX_AddressBanner = (): JSX.Element => {
    let bannerJSX = (
      <Box
        className="tw-p-2"
        sx={{ background: teal }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px', paddingX: '5px' }}>
          <Box
            className="tw-mb-2"
            component="img"
            sx={{ width: 189 * 0.7, height: 54 * 0.7, background: 'white', borderRadius: '5px' }}
            alt="logo"
            src={logoSrc}
          />
          <Box sx={{ marginTop: '6px', display: 'inline', marginRight: '20px' }}>
            <Icon icon="phone"></Icon>
            <Typography
              variant="h6"
              sx={{ marginTop: '6px', display: 'inline', marginRight: '6px' }}
            >
              {' '}
              {PHONE_NUMBER}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="h6"
          sx={{ color: white, fontWeight: 'bold' }}
          className="tw-truncate"
        >
          <Icon
            icon="house"
            className="tw-mr-2"
          />
          {us_leadData?.opportunityData.address1} {us_leadData?.opportunityData.city} {us_leadData?.opportunityData.state}{' '}
          {us_leadData?.opportunityData.postal_code}
        </Typography>
      </Box>
    )

    return bannerJSX
  }

  const rJSX_EditModeBanner = (): JSX.Element => {
    let bannerJSX = (
      <Box
        className="tw-p-2"
        sx={{ background: 'orange' }}
      >
        <Typography
          variant="h6"
          sx={{ color: white, fontWeight: 'bold' }}
          className="tw-truncate tw-text-center"
        >
          <Icon
            icon="gear"
            className="tw-mr-2"
          />
          {rLIB('Edit Mode')}
        </Typography>
      </Box>
    )

    return bannerJSX
  }

  const rJSX_MapImageBanner = (): JSX.Element => {
    let bannerJSX = <></>
    if (us_leadData != null && us_leadData.eagleviewProspectData != null && us_leadData.eagleviewProspectData.ev_image_data != null) {
      bannerJSX = (
        <Box
          sx={{
            backgroundImage: `url(${us_leadData.eagleviewProspectData.ev_image_data ? `data:image/jpeg;base64,${us_leadData.eagleviewProspectData.ev_image_data}` : ''})`,
            width: '100%',
            height: '280px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></Box>
      )
    } else {
      bannerJSX = (
        <MapBasic
          mapMarkers={{}}
          mapSettings={{
            center_lat: getProp(us_propertyCoordinates, 'latitude', 0),
            center_lng: getProp(us_propertyCoordinates, 'longitude', 0),
            height: '280px',
            render_clusters: false,
            render_heatmap: false,
            render_markers: true,
            width: '100%',
            zoom: 20,
            map_type_id: 'satellite',
            additional_map_options: {
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            },
          }}
        />
      )
    }
    return bannerJSX
  }

  const rJSX_SimpleSavingsPage = (isAuthenticatedUser: boolean): JSX.Element => {
    return (
      <Box className="tw-text-center tw-p-4">
        <Box>
          {rJSX_Modal(
            us_showEditUtilityModal, // toggle show
            rJSX_EditUtilityModalContent(), // content
          )}
        </Box>
        {/* <Box>{rJSX_Modal(us_showContactInfoModal, rJSX_ContactInfoModalContent())}</Box> */}
        <Typography
          variant="h6"
          sx={{ color: darkGray, lineHeight: '1.3', marginBottom: '12px' }}
        >
          For a{' '}
          <Box
            component="span"
            sx={{ color: yellow, fontWeight: '900' }}
          >
            {formatNumberToDollar(us_electricityBill)}
          </Box>{' '}
          utility bill, we estimate{' '}
          <Box
            component="span"
            sx={{ color: green, fontWeight: '900' }}
          >
            {us_selectedFinancingType === 'cash'
              ? `${formatNumberToDollar(us_cashSavings)} in total savings!`
              : `${formatNumberToDollar(us_leaseMonthlySavings)} in monthly savings!`}
          </Box>
        </Typography>
        <Box>
          {!us_hideEditUtilityButton && (
            <Button
              variant="outlined"
              className="tw-mx-1 tw-mb-2"
              startIcon={<Icon icon="pen-to-square"></Icon>}
              onClick={() => {
                saveSessionData(
                  clientKey,
                  opportunityKey,
                  us_analyticsSessionKey,
                  {
                    actions_taken: {
                      clicked_show_edit_utility_bill_modal_button: true,
                    },
                  },
                  !(ul_queryParams.get('tracking') == 'false'),
                )
                handleEditUtilityBill()
              }}
            >
              Edit Utility Bill
            </Button>
          )}
          {isAuthenticatedUser && (
            <Button
              className="tw-bg-error_main tw-text-white"
              onClick={() => {
                us_setHideEditUtilityButton(!us_hideEditUtilityButton)
                // updateOpportunity({ hide_edit_utility_button: !us_hideEditUtilityButton }, true)
              }}
            >
              {us_hideEditUtilityButton ? 'Show ' : 'Hide '} Edit Utility Button
            </Button>
          )}
        </Box>
        <Divider className="tw-mb-4 tw-mt-2" />
        {rJSX_SimplePricesSection()}
        <Divider className="tw-my-4" />
        <Box>
          <Typography
            variant="h6"
            sx={{ color: darkGray, lineHeight: '1.3' }}
          >
            This estimate is for a solar system that covers{' '}
            <Box
              component="span"
              sx={{ color: black, fontWeight: '900' }}
            >
              {us_offset ? us_offset : 100}%
            </Box>{' '}
            of your utility bill. {!us_hideOffsetSlider && 'Use the slider to explore other system sizes.'}
          </Typography>
          {!us_hideOffsetSlider && (
            <Slider
              sx={{ height: 10, width: '100%', marginX: 'auto' }}
              value={us_offset ? us_offset : 100}
              valueLabelDisplay="auto"
              // marks
              step={1}
              min={80}
              max={120}
              onChange={(event, value) => {
                submitOffsetChange(value as number)
              }}
              onChangeCommitted={(event, value) => {
                submitOffsetChange(value as number, true)
              }}
            />
          )}
          {isAuthenticatedUser && (
            <Button
              className="tw-bg-error_main tw-text-white tw-mb-2"
              onClick={() => {
                us_setHideOffsetSlider(!us_hideOffsetSlider)
                // updateOpportunity({ hide_offset_slider: !us_hideOffsetSlider }, true)
              }}
            >
              {us_hideOffsetSlider ? 'Show ' : 'Hide '} Offset Slider
            </Button>
          )}
        </Box>
        <Divider className="tw-mb-4" />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: darkGray, marginBottom: '10px' }}>
          <Typography sx={{ fontSize: '13px' }}>System Size: {(us_selectedSystemSizeInWatts / 1000).toFixed(1)} kW</Typography>
          <Typography sx={{ fontSize: '13px' }}>Number of Panels: {us_numPanels.toFixed(0)}</Typography>
          <Typography sx={{ fontSize: '13px' }}>Est. Annual Production: {us_selectedSystemAnnualProductionInKWh.toFixed(0)} kWh</Typography>
        </Box>
        <Divider className="tw-mb-4" />

        <Box>
          <Button
            variant="contained"
            sx={{ backgroundColor: green }}
            startIcon={<Icon icon="lightbulb-on"></Icon>}
            onClick={() => {
              saveSessionData(
                clientKey,
                opportunityKey,
                us_analyticsSessionKey,
                {
                  actions_taken: {
                    clicked_show_customize_system_button: true,
                  },
                },
                !(ul_queryParams.get('tracking') == 'false'),
              )
              // us_setShowContactInfoModal(true)
              requestProposeDataset()
            }}
          >
            <Typography sx={{ fontSize: '1.3rem' }}>{rLIB('See Panels On Your Home')}</Typography>
          </Button>
        </Box>
      </Box>
    )
  }

  const rJSX_ProspectDataView = (isAuthenticatedUser: boolean): JSX.Element => {
    if (us_leadData?.opportunityData.first_page_source_data === 'eagleview_prospect') {
      return (
        <Box>
          {/* {rJSX_Modal(
          us_showHouseConfirmationModal, // toggle show
          rJSX_HouseConfirmationModalContent(), // content
        )} */}
          {rJSX_Modal(us_showUtilityCompanyConfirmationModal, rJSX_UtilityCompanyConfirmationModalContent())}
          {rJSX_Modal(us_showUtilityBillConfirmationModal, rJSX_UtilityBillConfirmationModalContent())}
          {rJSX_AddressBanner()}
          {rJSX_MapImageBanner()}
          <Box sx={{ maxWidth: '800px', width: '100%', margin: 'auto' }}>
            <TabsBasic
              tabs={[
                {
                  tabHeader: rJSX_SystemTabHeader(),
                  tabContent: <Box>{rJSX_SystemDetailsTab()}</Box>,
                },
                {
                  tabHeader: rJSX_ContactInfoTabHeader(),
                  tabContent: <Box>{rJSX_ContactInfoTab()}</Box>,
                },
                {
                  tabHeader: rJSX_CustomizeTabHeader(),
                  tabContent: <Box>{rJSX_CustomizeTab()}</Box>,
                },
              ]}
              tabsSettings={{ thin: true }}
            />
          </Box>
        </Box>
      )
    } else {
      return (
        <Box>
          {/* {rJSX_Modal(
          us_showHouseConfirmationModal, // toggle show
          rJSX_HouseConfirmationModalContent(), // content
        )} */}
          {rJSX_Modal(us_showUtilityCompanyConfirmationModal, rJSX_UtilityCompanyConfirmationModalContent())}
          {rJSX_Modal(us_showUtilityBillConfirmationModal, rJSX_UtilityBillConfirmationModalContent())}
          {rJSX_AddressBanner()}
          {rJSX_MapImageBanner()}
          {rJSX_SimpleSavingsPage(isAuthenticatedUser)}
        </Box>
      )
    }
  }

  // Phone Number Confirmation View
  const rJSX_SaveMoneyBanner = () => {
    let bannerJSX = <></>
    bannerJSX = (
      <Box
        className="tw-p-4"
        sx={{ backgroundColor: teal, width: '100%' }}
      >
        <Typography
          variant="h6"
          className="tw-text-center"
          sx={{ color: white, fontWeight: 'bold', width: '100%' }}
        >
          <Icon
            icon="solar-panel"
            type="solid"
            className="tw-mr-2"
          />
          {rLIB('Save BIG by going solar!')}
          <Icon
            icon="face-party"
            type="solid"
            className="tw-ml-2"
          />
        </Typography>
      </Box>
    )
    return bannerJSX
  }

  const rJSX_PhoneIntroText = (): JSX.Element => {
    let textJSX = <></>
    if (us_leadData != null && us_leadData.opportunityData != null && us_leadData.opportunityData.address1 != null) {
      // Remove substring before first space from address
      let formattedAddress = us_leadData.opportunityData.address1.split(' ').slice(1).join(' ')
      textJSX = (
        <Box className="tw-my-6">
          <Typography
            variant="h5"
            className="tw-text-center"
            sx={{ color: darkGray, fontWeight: 'bold' }}
          >
            <Box className="">
              {rLIB("We've analyzed your home")} {rLIB('at')}{' '}
            </Box>
            <Box sx={{ color: 'black' }}>{formattedAddress}</Box>
            <Box className="">{rLIB('and designed a great custom solar solution for you!')}</Box>
          </Typography>
        </Box>
      )
    } else {
      textJSX = (
        <Box className="tw-my-6">
          <Typography
            variant="h5"
            className="tw-text-center"
            sx={{ color: darkGray, fontWeight: 'bold' }}
          >
            <Box className="tw-inline-block">
              {rLIB("We've analyzed your home")} {rLIB('and designed a great custom solar solution for you!')}
            </Box>
          </Typography>
        </Box>
      )
    }
    return textJSX
  }

  const rJSX_ConfirmPhoneNumberView = (phone: string): JSX.Element => {
    let formattedPhoneNumber = returnStandardizedUSPhoneNumber(phone)
    let confirmPhoneNumberJSX = (
      <Box>
        {rJSX_SaveMoneyBanner()}
        <Box
          sx={{ maxWidth: '600px', margin: 'auto' }}
          className="tw-p-2"
        >
          {rJSX_PhoneIntroText()}
          <Box
            className="tw-p-4 tw-mt-4 tw-rounded-xl"
            sx={{ width: '100%', border: '1px solid ' + lightGray2, backgroundColor: white }}
          >
            <Typography
              variant="h5"
              className="tw-text-center"
              sx={{ color: teal, fontWeight: 'bold' }}
            >
              {rLIB('Confirm your phone number')}
            </Typography>
            <Typography
              variant="body1"
              className="tw-text-center"
              sx={{ color: darkGray, fontWeight: 'regular' }}
            >
              {rLIB('In order to view your custom solar layout, please confirm your phone number')}
            </Typography>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-8 tw-text-center">
              <Typography
                variant="h4"
                className="tw-text-center tw-mt-2"
                sx={{ color: darkGray }}
              >
                XXX-XXX-{formattedPhoneNumber.slice(-4)}
              </Typography>
              <FormControl
                className="bp_thin_text_input"
                sx={{ minWidth: '300px', marginRight: '12px', marginBottom: '8px' }}
              >
                <TextField
                  onChange={(event) => {
                    us_setUserPhoneNumber(event.target.value)
                  }}
                  value={us_userPhoneNumber}
                  variant="outlined"
                  className="tw-mt-4"
                  sx={{
                    width: '300px',
                    input: { fontSize: 24 },
                  }}
                />
                <Button
                  sx={{ fontSize: 18 }}
                  className="tw-mt-4"
                  variant="contained"
                  color="primary"
                  startIcon={
                    <Icon
                      icon="badge-check"
                      type="solid"
                    />
                  }
                  // Disable if there are not at least 10 numeric digits
                  disabled={
                    returnStandardizedUSPhoneNumber(us_userPhoneNumber).length < 10 ||
                    returnStandardizedUSPhoneNumber(us_userPhoneNumber) === 'Invalid phone number'
                  }
                  onClick={() => {
                    // Error Message
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: {
                        message: rLIB('Failed to confirm phone number'),
                        details: rLIB("The number we have on file doesn't match the number you entered. Please try again."),
                        code: 'ER-MATCH',
                      },
                    })
                  }}
                >
                  {rLIB('Confirm Phone')}
                </Button>
                {returnStandardizedUSPhoneNumber(us_userPhoneNumber)}
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          className="tw-mx-auto tw-mb-4"
          component="img"
          sx={{ width: 126, height: 36, position: 'fixed', bottom: '5px', left: 'calc(50% - 53px)' }}
          alt="logo"
          src={logoSrc}
        />
      </Box>
    )
    return confirmPhoneNumberJSX
  }

  // Loading Data View
  const rJSX_LoadingDataView = (): JSX.Element => {
    return (
      <Box
        className="tw-text-center tw-mt-2"
        sx={{ paddingTop: 'calc(50vh - 100px)' }}
      >
        <Box
          className="tw-mx-auto tw-mb-4"
          component="img"
          sx={{ width: 189, height: 54 }}
          alt="logo"
          src={logoSrc}
        />
        <CircularProgress
          sx={{ color: teal }}
          size="64px"
        />
      </Box>
    )
  }

  // Eagleview Propose Data View

  // Eagleview Load Prospect Data
  const rJSX_RotatingBanner = () => {
    let bannerJSX = <></>
    bannerJSX = (
      <Box
        className="tw-p-4"
        sx={{ backgroundColor: teal, width: '100%' }}
      >
        <Typography
          variant="h6"
          className="tw-text-center"
          sx={{ color: white, fontWeight: 'bold', width: '100%', fontSize: '18px' }}
        >
          <RotatingLoadingMessages />
        </Typography>
      </Box>
    )
    return bannerJSX
  }

  const rJSX_LoadingSectionVisualization = (
    stepNumber: number,
    activeStartTime: number,
    activeEndTime: number,
    text: string | JSX.Element,
    progressType: 'determinate' | 'indeterminate',
  ): JSX.Element => {
    let sectionJSX = <></>
    if (activeEndTime <= us_loadingEagleviewProposeDataSecondsSinceStart) {
      // Completed
      sectionJSX = (
        <Box>
          <Stack
            direction="row"
            spacing="2"
          >
            <Box
              sx={{ maxWidth: '30px' }}
              className="tw-my-2 tw-ml-2 tw-mr-4"
            >
              <Icon
                icon="circle-check"
                sx={{ color: green, fontSize: '30px' }}
              />
            </Box>
            <Box
              className="tw-text-left"
              sx={{ maxWidth: 'calc(100% - 75px)' }}
            >
              <Typography
                variant="h6"
                sx={{ color: darkGray }}
                className="tw-mt-1.5"
              >
                {text}
              </Typography>
            </Box>
          </Stack>
        </Box>
      )
    } else if (activeStartTime <= us_loadingEagleviewProposeDataSecondsSinceStart) {
      // In Progress
      sectionJSX = (
        <Box>
          <Stack
            direction="row"
            spacing="2"
          >
            <Box
              sx={{ maxWidth: '30px' }}
              className="tw-my-1.5 tw-ml-2 tw-mr-4"
            >
              <CircularProgress
                size="30px"
                variant={progressType}
                value={((us_loadingEagleviewProposeDataSecondsSinceStart - activeStartTime) / (activeEndTime - activeStartTime)) * 100}
                color="warning"
                className="tw-m-auto"
              />
            </Box>
            <Box
              className="tw-text-left"
              sx={{ maxWidth: 'calc(100% - 75px)' }}
            >
              <Typography
                variant="h6"
                sx={{ color: darkGray }}
                className="tw-mt-1.5"
              >
                {text}
              </Typography>
            </Box>
          </Stack>
        </Box>
      )
    } else {
      // Waiting
      sectionJSX = (
        <Box>
          <Stack
            direction="row"
            spacing="2"
          >
            <Box
              sx={{ maxWidth: '30px' }}
              className="tw-my-2 tw-ml-2 tw-mr-4"
            >
              <Icon
                icon={'circle-' + stepNumber.toString()}
                sx={{ color: teal, fontSize: '30px' }}
              />
            </Box>
            <Box
              className="tw-text-left"
              sx={{ maxWidth: 'calc(100% - 75px)' }}
            >
              <Typography
                variant="h6"
                sx={{ color: darkGray }}
                className="tw-mt-1.5"
              >
                {text}
              </Typography>
            </Box>
          </Stack>
        </Box>
      )
    }
    return sectionJSX
  }

  const rJSX_LoadEagleviewDataViewV2 = (titleText: JSX.Element | string): JSX.Element => {
    let viewJSX = <></>
    viewJSX = (
      <Box>
        <Box className="tw-truncate">
          {rJSX_RotatingBanner()}
          <LinearProgress />
        </Box>
        <Box sx={{ maxWidth: '600px', margin: 'auto' }}>
          <Box className="tw-mt-6">
            <Typography
              variant="h5"
              className="tw-text-center"
              sx={{ color: black, fontWeight: 'bold' }}
            >
              {titleText}
            </Typography>
            <Typography
              variant="body1"
              className="tw-text-center"
              sx={{ color: darkGray, fontWeight: 'regular' }}
            >
              {rLIB('This should take about a minute')}
              <Box
                component={'span'}
                sx={{ color: 'darkGray' }}
                className="tw-ml-1"
              >
                ({formatTime(parseInt(us_loadingEagleviewProposeDataSecondsSinceStart.toString()))})
              </Box>
            </Typography>
          </Box>
          <Divider className="tw-my-4" />
          {rJSX_LoadingSectionVisualization(1, 1, 9, 'Analyzing Roof Dimensions', 'determinate')}
          {rJSX_LoadingSectionVisualization(2, 9, 18, 'Assessing Roof Angles', 'determinate')}
          {rJSX_LoadingSectionVisualization(3, 18, 25, 'Checking for Obstructions', 'determinate')}
          {rJSX_LoadingSectionVisualization(4, 25, 37, 'Calculating Sunlight Exposure', 'determinate')}
          {rJSX_LoadingSectionVisualization(5, 37, 42, 'Evaluating Shade Patterns', 'determinate')}
          {rJSX_LoadingSectionVisualization(6, 42, 48, 'Analyzing Historical Weather Data', 'determinate')}
          {rJSX_LoadingSectionVisualization(7, 48, 55, 'Mapping Optimal Panel Layout', 'determinate')}
          {rJSX_LoadingSectionVisualization(8, 55, 61, 'Calculating Expected Energy Output', 'determinate')}
          {rJSX_LoadingSectionVisualization(9, 61, 300, 'Finalizing Recommendations', 'indeterminate')}
        </Box>
        <Box
          className="tw-mx-auto tw-mb-4"
          component="img"
          sx={{ width: 126, height: 36, bottom: '5px', left: 'calc(50% - 53px)' }}
          alt="logo"
          src={logoSrc}
        />
      </Box>
    )
    return viewJSX
  }

  // Propose
  const rJSX_CustomSystemDialog = (): JSX.Element => {
    return (
      <Dialog
        className="bp_dialog_md_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <DialogContent sx={{ padding: '0px', background: white }}>
          <Box className="tw-p-4">
            <Typography
              variant="h6"
              className="tw-text-center"
              sx={{ color: teal, fontWeight: 'bold' }}
            >
              {rLIB('Customizing your solar layout.')}
            </Typography>
            <Divider className="tw-my-2" />
            <Typography
              variant="body1"
              sx={{ color: darkGray }}
            >
              1.{' '}
              {rLIB(
                "Click on the solar panels on the map or the list to the left to add or remove them from your custom solar layout. We'll update your production and cost in real time.",
              )}
            </Typography>
            <Typography
              variant="body1"
              className="tw-mt-2"
              sx={{ color: darkGray }}
            >
              2. {rLIB('The most cost efficient layouts use the fewest number of panels to reach your desired production. (Usually 100% offset).')}
            </Typography>
            <Divider className="tw-my-2" />
            <Typography
              variant="h6"
              className="tw-text-center"
              sx={{ color: teal, fontWeight: 'bold' }}
            >
              {rLIB('Notes')}
            </Typography>
            <Divider className="tw-my-2" />
            <Typography
              variant="body1"
              sx={{ color: darkGray }}
            >
              1.{' '}
              {rLIB(
                'Our panel overlay may not be aligned with your roof, you can use the yellow arrows to adjust the overlay. (This does not affect the price estimates or final layout).',
              )}
            </Typography>
            <Typography
              variant="body1"
              className="tw-mt-2"
              sx={{ color: darkGray }}
            >
              2. {rLIB("We still require a human to verify the layout before installation, but what's shown is usually very close to the final layout.")}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  const rJSX_SubmitTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    headerJSX = (
      <Box>
        <Icon
          icon="paper-plane"
          className="tw-mr-1"
          sx={{ color: teal }}
        />
        {rLIB('Submit Design')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_UtilityTabHeader = (): JSX.Element => {
    let headerJSX = <></>
    headerJSX = (
      <Box>
        <Icon
          icon="utility-pole-double"
          className="tw-mr-1"
          sx={{ color: teal }}
        />
        {rLIB('Utility')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_EagleViewVisualization = (eagleviewProposeData: TsInterface_UnspecifiedObject, editModeIsActive: boolean): JSX.Element => {
    let visualizationJSX = <></>
    const mapHeight = editModeIsActive ? 'calc( 100vh - 350px )' : 'calc( 100vh - 400px )'
    if (us_activeProposePanels != null) {
      visualizationJSX = (
        <EagleviewProposePanelsVisualization
          editModeActive={editModeIsActive}
          activePanelSelection={us_activeProposePanels}
          mapHeight={mapHeight}
          mapWidth="100%"
          disablePanelSelection={us_updatingPricingNumbers}
          // panelsLatOffset={0.0000175}
          panelsLatOffset={0}
          // panelsLngOffset={-0.00001}
          panelsLngOffset={0}
          panelData={getProp(eagleviewProposeData, 'dataOutput', {})}
          onChange={(panelData: TsInterface_EagleviewVisualizationCallbackData) => {
            // console.log(panelData)
            if (panelData != null && panelData.active_panels != null) {
              // updateOpportunity({ active_propose_panels: panelData.active_panels })
              us_setActiveProposePanels(panelData.active_panels)
            }

            // Example of how to use the callback data
            let totalSystemSizeInKw = 0
            let totalAnnualSystemSize = 0
            let panelCount = 0
            if (panelData != null && panelData['active_panels'] != null && panelData['panel_data'] != null && panelData['panel_data']['energyTable'] != null) {
              for (let loopPanelIndex in panelData['panel_data']['energyTable']) {
                let loopPanel = panelData['panel_data']['energyTable'][loopPanelIndex]

                if (
                  loopPanel != null &&
                  loopPanel['kW size'] != null &&
                  loopPanel['kWh/yr'] != null &&
                  getProp(panelData['active_panels'], loopPanelIndex, true) === true
                ) {
                  panelCount++
                  totalSystemSizeInKw += loopPanel['kW size']
                  totalAnnualSystemSize += loopPanel['kWh/yr']
                }
              }
            }
            if (panelData['event_type'] === 'panel_toggle') {
              submitSystemSizeChange(panelCount, totalAnnualSystemSize, totalSystemSizeInKw * 1000)
            }
          }}
        />
      )
    } else {
      visualizationJSX = <Box sx={{ background: themeVariables.background_paper, width: '100%', height: 'calc( 100vh - 400px )' }}></Box>
    }
    return visualizationJSX
  }

  const rJSX_ProposeDataView = (isAuthenticatedUser: boolean, eagleviewProposeData: TsInterface_UnspecifiedObject): JSX.Element => {
    let viewJSX = <></>
    const infoBackgroundColor = !us_editModeIsActive ? lightGray : lightGray // TODO: change
    viewJSX = (
      <Box sx={{ backgroundColor: infoBackgroundColor }}>
        {rJSX_Modal(us_showEditUtilityModal, rJSX_EditUtilityModalContent())}
        {rJSX_Modal(us_showFinalSubmissionModal, rJSX_FinalSubmissionModalContent())}
        {us_editModeIsActive && rJSX_EditModeBanner()}
        {!us_editModeIsActive && rJSX_AddressBanner()}
        {us_activeProposePanels && rJSX_EagleViewVisualization(eagleviewProposeData, us_editModeIsActive)}
        <Box>
          <Box className="tw-mx-2 tw-mt-2 tw-text-center">
            <Box className="tw-text-center tw-pt-4 tw-px-4">
              {!us_editModeIsActive && (
                <Typography
                  variant="h6"
                  sx={{ color: darkGray, lineHeight: '1.3', marginBottom: '12px' }}
                >
                  For a{' '}
                  <Box
                    component="span"
                    sx={{ color: yellow, fontWeight: '900' }}
                  >
                    {formatNumberToDollar(us_electricityBill)}
                  </Box>{' '}
                  utility bill, we estimate{' '}
                  <Box
                    component="span"
                    sx={{ color: green, fontWeight: '900' }}
                  >
                    {us_selectedFinancingType === 'cash'
                      ? `${formatNumberToDollar(us_cashSavings)} in total savings!`
                      : `${formatNumberToDollar(us_leaseMonthlySavings)} in monthly savings!`}
                  </Box>
                </Typography>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                {!us_editModeIsActive && !us_hideEditUtilityButton && (
                  <Button
                    variant="outlined"
                    className="tw-mx-1 tw-mb-2"
                    startIcon={<Icon icon="pen-to-square"></Icon>}
                    onClick={() => {
                      saveSessionData(
                        clientKey,
                        opportunityKey,
                        us_analyticsSessionKey,
                        {
                          actions_taken: {
                            clicked_show_edit_utility_bill_modal_button: true,
                          },
                        },
                        !(ul_queryParams.get('tracking') == 'false'),
                      )
                      handleEditUtilityBill()
                    }}
                  >
                    Edit Utility Bill
                  </Button>
                )}
              </Box>
              {isAuthenticatedUser && (
                <Button
                  className="tw-bg-error_main tw-text-white"
                  onClick={() => {
                    us_setHideEditUtilityButton(!us_hideEditUtilityButton)
                    // updateOpportunity({ hide_edit_utility_button: !us_hideEditUtilityButton }, true)
                  }}
                >
                  {us_hideEditUtilityButton ? 'Show' : 'Hide'} Edit Utility Button
                </Button>
              )}
            </Box>
            {us_editModeIsActive && (
              <Box>
                <Box className="tw-px-2 tw-pt-2 tw-mb-2">{rJSX_ProductionSection()}</Box>
              </Box>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {!us_editModeIsActive && !us_hideEditPanelLayoutButton && (
                <Button
                  variant="outlined"
                  className="tw-mx-1 tw-mt-2"
                  startIcon={!us_editModeIsActive && <Icon icon="gear"></Icon>}
                  onClick={() => {
                    saveSessionData(
                      clientKey,
                      opportunityKey,
                      us_analyticsSessionKey,
                      {
                        actions_taken: {
                          clicked_edit_panel_layout_button: true,
                        },
                      },
                      !(ul_queryParams.get('tracking') == 'false'),
                    )
                    if (!us_editModeIsActive) {
                      uc_setUserInterface_CustomDialogDisplay({
                        display: true,
                        dialog: {
                          dialog_jsx: rJSX_CustomSystemDialog(),
                          settings: {
                            max_width: 'lg',
                          },
                        },
                      })
                    }
                    us_setEditModeIsActive(!us_editModeIsActive)
                  }}
                >
                  {rLIB('Edit Panel Layout')}
                </Button>
              )}
              {isAuthenticatedUser && (
                <Button
                  className="tw-bg-error_main tw-text-white tw-mt-2"
                  onClick={() => {
                    us_setHideEditPanelLayoutButton(!us_hideEditPanelLayoutButton)
                    // updateOpportunity({ hide_edit_panel_layout_button: !us_hideEditPanelLayoutButton }, true)
                  }}
                >
                  {us_hideEditPanelLayoutButton ? 'Show ' : 'Hide '} Edit Panel Layout
                </Button>
              )}
              <Box className="tw-mt-3 tw-w-full">{rJSX_SimplePricesSection(us_editModeIsActive)}</Box>
              {us_editModeIsActive && (
                <Button
                  variant="contained"
                  className="tw-mx-1 tw-mt-2 tw-mb-4"
                  startIcon={!us_editModeIsActive && <Icon icon="gear"></Icon>}
                  onClick={() => {
                    saveSessionData(
                      clientKey,
                      opportunityKey,
                      us_analyticsSessionKey,
                      {
                        actions_taken: {
                          clicked_done_editing_button: true,
                        },
                      },
                      !(ul_queryParams.get('tracking') == 'false'),
                    )
                    if (!us_editModeIsActive) {
                      uc_setUserInterface_CustomDialogDisplay({
                        display: true,
                        dialog: {
                          dialog_jsx: rJSX_CustomSystemDialog(),
                          settings: {
                            max_width: 'lg',
                          },
                        },
                      })
                    }
                    us_setEditModeIsActive(!us_editModeIsActive)
                  }}
                >
                  {rLIB('Done Editing')}
                </Button>
              )}
              {!us_editModeIsActive && (
                <Box>
                  <Divider className="tw-my-4" />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', color: darkGray, marginBottom: '10px' }}>
                    <Typography sx={{ fontSize: '13px' }}>System Size: {(us_selectedSystemSizeInWatts / 1000).toFixed(1)} kW</Typography>
                    <Typography sx={{ fontSize: '13px' }}>Number of Panels: {us_numPanels.toFixed(0)}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>Est. Annual Production: {us_selectedSystemAnnualProductionInKWh.toFixed(0)} kWh</Typography>
                  </Box>
                  <Divider className="tw-mt-4" />
                  <Button
                    variant="contained"
                    className="tw-my-4"
                    startIcon={<Icon icon="paper-plane" />}
                    onClick={() => {
                      saveSessionData(
                        clientKey,
                        opportunityKey,
                        us_analyticsSessionKey,
                        {
                          actions_taken: {
                            clicked_submit_design_button: true,
                          },
                        },
                        !(ul_queryParams.get('tracking') == 'false'),
                      )
                      handleDesignSubmissionClick()
                    }}
                    disabled={us_submitted}
                  >
                    {rLIB('Submit Design for Review')}
                  </Button>
                  {us_submittedDate !== null && (
                    <Box sx={{ marginBottom: '10px' }}>
                      <Typography sx={{ color: darkGray, marginBottom: '2px' }}>Submitted: {us_submittedDate.toDateString()}</Typography>
                      <Typography sx={{ color: darkGray, marginBottom: '2px' }}>Size: {us_submittedSize.toFixed(1)} kW</Typography>
                      <Typography sx={{ color: darkGray, marginBottom: '2px' }}>Panels: {us_submittedNumPanels.toFixed(0)}</Typography>
                      <Typography sx={{ color: darkGray, marginBottom: '2px' }}>Est. Production: {us_submittedProduction.toFixed(0)} kWh/yr</Typography>
                      <Typography sx={{ color: darkGray, marginBottom: '2px' }}>Financing Type: {us_submittedFinancingType}</Typography>
                      <Typography sx={{ color: darkGray, marginBottom: '2px' }}>Est. Price: {formatNumberToDollar(us_submittedPrice)}</Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {/* <Box className="tw-mx-2 tw-mt-2">
            <Card className="tw-px-2 tw-pt-0 tw-mb-2">{rJSX_UtilityBillSection()}</Card>
            {rJSX_UtilityCompanyAutocomplete()}
          </Box>
          <Box sx={{ position: 'fixed', bottom: 8, right: 8 }}>
            <Box className="tw-text-center tw-mt-2 tw-mx-2">
              <Card className="tw-p-2">
                <Typography
                  variant="h6"
                  sx={{ color: black }}
                >
                  {rLIB(
                    'Submitting your design for review will alert our team to review your custom solar layout and reach out for further details. This is not a commitment to purchase',
                  )}
                </Typography>
                <Button
                  className="tw-mt-4"
                  variant="contained"
                  startIcon={<Icon icon="paper-plane" />}
                >
                  {rLIB('Submit Design for Review')}
                </Button>
              </Card>
            </Box> */}
          {/* </Box> */}

          {/* <Fab
            color="warning"
            onClick={() => {
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: rJSX_CustomSystemDialog(),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          >
            <Icon
              icon="question"
              type="regular"
              sx={{ fontSize: '34px' }}
            />
          </Fab> */}
        </Box>
      </Box>
    )
    return viewJSX
  }

  // Page Content
  const rJSX_PageContent = (isAuthenticatedUser: boolean): JSX.Element => {
    let contentJSX = <></>
    // if (us_dataLoadedStatus === 'loading_eagleview_propose_data' && us_leadData?.opportunityData.first_page_source_data === 'eagleview_prospect') {
    if (us_dataLoadedStatus === 'loading_eagleview_propose_data') {
      contentJSX = rJSX_LoadEagleviewDataViewV2(rLIB('Our AI system is finalizing your custom solar layout'))
    } else if (us_dataLoadedStatus === 'loading') {
      contentJSX = rJSX_LoadingDataView()
    } else if (us_dataLoadedStatus === 'success') {
      // if (
      //   us_confirmedPhoneNumber !== true &&
      //   us_leadData != null &&
      //   us_leadData.opportunityData != null &&
      //   us_leadData.opportunityData.phone != null &&
      //   returnStandardizedUSPhoneNumber(us_leadData.opportunityData.phone) !== 'Invalid phone number'
      // ) {
      //   // contentJSX = rJSX_ConfirmPhoneNumberView(us_leadData.opportunityData.phone)
      // } else
      if (us_leadData != null && us_leadData.eagleviewProposeData != null && objectToArray(us_leadData.eagleviewProposeData).length > 0) {
        contentJSX = rJSX_ProposeDataView(isAuthenticatedUser, us_leadData.eagleviewProposeData)
      } else if (
        us_leadData != null &&
        (us_leadData.eagleviewProspectData == null || objectToArray(us_leadData.eagleviewProspectData).length === 0) &&
        us_leadData?.opportunityData.first_page_source_data === 'eagleview_prospect'
      ) {
        contentJSX = rJSX_LoadEagleviewDataViewV2(rLIB('Our AI system is designing your custom solar layout'))
      } else {
        contentJSX = rJSX_ProspectDataView(isAuthenticatedUser)
      }
    } else if (us_dataLoadedStatus === 'error') {
      contentJSX = rJSX_ErrorDisplay()
    }
    return contentJSX
  }

  const rJSX_Page = (isAuthenticatedUser: boolean): JSX.Element => {
    let pageJSX = (
      <Box>
        <ThemeProvider theme={lightTheme}>
          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
          {rJSX_PageContent(isAuthenticatedUser)}
          <style>{pageCSS}</style>
        </ThemeProvider>
      </Box>
    )
    return pageJSX
  }

  return <>{rJSX_Page(isAuthenticatedUser)}</>
}
