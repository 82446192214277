///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:
			1 @ts-expect-error - really annoying casting problem on permission overrides

	*/

///////////////////////////////
// Imports
///////////////////////////////

import Box from '@mui/material/Box'
import { createCancelledCalendarEvent } from 'app/models/scheduling/scheduling_teams'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { schedulingTeamTypes } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_FinalizedPayroll_Document,
  DatabaseRef_FinalizedPayroll_UnitPayTasks_Document,
} from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import { DatabaseRef_CancelledScheduledTask_Document } from 'rfbp_aux/services/database_endpoints/operations/cancelled_scheduled_tasks'
import { DatabaseRef_ProjectLogs_Document, DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  DatabaseRef_AllProjectTasksFromSameWorkflowTask_Query,
  DatabaseRef_ProjectTasksForPrerequisiteTask_Query,
  DatabaseRef_Task_Document,
  DatabaseRef_UpstreamDispatcherTask_Query,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { TsInterface_FormInputs, TsInterface_FormSettings, TsType_FormOnChange, TsType_FormSubmission } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import {
  cloneObjectWithoutReference,
  generateRandomString,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { getStartAndEndOfWeek } from '../../pages/payroll/data/payroll_calculations'
import { adHocTaskAssignmentTypeInputOptions, checkIfTaskIsUpstream } from './task_workflow_services'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_REDO_REASON: JSX.Element = 																<Trans>Redo Reason</Trans>
const s_CUSTOMER_REQUESTED_NO_SHOW: JSX.Element = <Trans>Customer Requested / No-Show</Trans>
const s_DESIGN_ISSUES: JSX.Element = <Trans>Design Issues</Trans>
const s_FAILED_TO_CREATE_REDO_TASK: JSX.Element = <Trans>Failed to create redo task</Trans>
const s_FAILED_TO_CREATE_RETRY_TASK: JSX.Element = <Trans>Failed to create retry task</Trans>
const s_FAILED_TO_CREATE_TASK: JSX.Element = <Trans>Failed to create task</Trans>
const s_GREEN_STATUS_CUTOFF_DAYS: JSX.Element = <Trans>Green Status Cutoff Days</Trans>
const s_MATERIAL_SUPPLY_SHORTAGE: JSX.Element = <Trans>Material/Supply shortage</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing required parameters</Trans>
const s_REDO_NOTES: JSX.Element = <Trans>Redo Notes</Trans>
const s_REDO_REASON: JSX.Element = <Trans>Redo Reason</Trans>
const s_REDO_REASON_NOTES: JSX.Element = <Trans>Redo Reason Notes</Trans>
const s_SCHEDULED_TEAM_TYPE: JSX.Element = <Trans>Scheduled Team Type</Trans>
const s_SITE_INACCESSIBLE: JSX.Element = <Trans>Site inaccessible</Trans>
const s_TASKS_THAT_CANNOT_START_UNTIL_THE_REDO_TASK_IS_DONE: JSX.Element = <Trans>Tasks that cannot start until the redo task is done</Trans>
const s_TASKS_THAT_CANNOT_START_UNTIL_THIS_TASK_IS_DONE: JSX.Element = <Trans>Tasks that cannot start until this task is done</Trans>
const s_TASKS_THAT_MUST_BE_COMPLETED_BEFORE_STARTING_THIS_TASK: JSX.Element = <Trans>Tasks that must be completed before starting this task</Trans>
const s_TASK_DISPATCHER_TYPE: JSX.Element = <Trans>Task Dispatcher Type</Trans>
const s_TASK_FORM: JSX.Element = <Trans>Task Form</Trans>
const s_TASK_GROUP: JSX.Element = <Trans>Task Group</Trans>
const s_TASK_NAME: JSX.Element = <Trans>Task Name</Trans>
const s_TASK_OWNER_TYPE: JSX.Element = <Trans>Task Owner Type</Trans>
const s_THIS_TASK_HAS_ALREADY_BEEN_PROCESSED_BY_PAYROLL_AND_CANNOT_BE_REOPENED: JSX.Element = (
  <Trans>This task has already been processed by payroll and cannot be reopened</Trans>
)
const s_UNINSTALLABLE_ROOF: JSX.Element = <Trans>Uninstallable Roof</Trans>
const s_WAITING_FOR_ADDITIONAL_WORK: JSX.Element = <Trans>Waiting for additional work</Trans>
const s_WEATHER: JSX.Element = <Trans>Weather</Trans>
const s_YELLOW_STATUS_CUTOFF_DAYS: JSX.Element = <Trans>Yellow Status Cutoff Days</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

// Edit
export const formOnChange_EditTask: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
  // Instantiate Variables
  let allTasks = getProp(formAdditionalData, 'projectTasks', {})
  let task = getProp(formAdditionalData, 'task', {})
  let recursiveTasks = getProp(formAdditionalData, 'recursiveTasks', {})
  let taskKey = getProp(task, 'key', null)
  let currentUpstreamTasks = getProp(formData, 'upstream_tasks', {})
  let currentDownstreamTasks = getProp(formData, 'downstream_tasks', {})
  // Prevent Task that is being edited from being used in upstream or downstream
  if (taskKey != null) {
    if (currentUpstreamTasks[taskKey] === true) {
      // @ts-expect-error - TODO: reason for error
      formData['upstream_tasks'][taskKey] = false
    }
    if (currentDownstreamTasks[taskKey] === true) {
      // @ts-expect-error - TODO: reason for error
      formData['downstream_tasks'][taskKey] = false
    }
  }
  // Loop through downstream tasks
  for (let loopDownstreamTaskKey in currentDownstreamTasks) {
    let hasAnUpstreamTask = false
    let loopDownstreamTask = allTasks[loopDownstreamTaskKey]
    // If the same task is in both upstream and downstream form inputs
    if (currentUpstreamTasks[loopDownstreamTaskKey] === true) {
      hasAnUpstreamTask = true
    }
    // Loop through upstream tasks
    for (let loopUpstreamTaskKey in currentUpstreamTasks) {
      let loopUpstreamTask = allTasks[loopUpstreamTaskKey]
      if (currentUpstreamTasks[loopUpstreamTaskKey] === true) {
        if (checkIfTaskIsUpstream(allTasks, loopDownstreamTask, loopUpstreamTask, recursiveTasks, ['formOnChange_EditTask']) === true) {
          hasAnUpstreamTask = true
        }
      }
    }
    if (hasAnUpstreamTask === true) {
      // @ts-expect-error - TODO: reason for error
      formData['downstream_tasks'][loopDownstreamTaskKey] = false
    }
  }
}

export const formSubmission_EditTask: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    if (
      formAdditionalData != null &&
      formAdditionalData.task != null &&
      // @ts-expect-error - TODO: reason for error
      formAdditionalData.task.key != null
    ) {
      // @ts-expect-error - TODO: reason for error
      let taskKey = formAdditionalData.task.key
      let taskUpdateObject: TsInterface_UnspecifiedObject = {
        status_green_days_cutoff: getProp(formSubmittedData, 'status_green_days_cutoff', null),
        status_yellow_days_cutoff: getProp(formSubmittedData, 'status_yellow_days_cutoff', null),
        prerequisite_tasks: {},
        prerequisite_tasks_completion: {},
        timestamp_last_updated: new Date(),
      }
      if (getProp(formSubmittedData, 'associated_task_form_key', null) != null) {
        taskUpdateObject['associated_task_form_key'] = getProp(formSubmittedData, 'associated_task_form_key', null)
      }
      if (getProp(formSubmittedData, 'associated_team_type', null) != null) {
        taskUpdateObject['associated_team_type'] = getProp(formSubmittedData, 'associated_team_type', null)
      }
      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // Get Upstream Tasks
          let upstreamTasks: TsInterface_UnspecifiedObject = {}
          let databasePromiseArray: TsType_UnknownPromise[] = []
          if (formSubmittedData.upstream_tasks != null) {
            for (let loopTaskKey in formSubmittedData.upstream_tasks) {
              if (formSubmittedData.upstream_tasks[loopTaskKey] === true) {
                databasePromiseArray.push(
                  DatabaseGetDocument(DatabaseRef_Task_Document(res_GCK.clientKey, loopTaskKey as string))
                    .then((res_DGD) => {
                      upstreamTasks[loopTaskKey] = res_DGD.data
                    })
                    .catch((rej_DGD) => {
                      console.error(rej_DGD)
                    }),
                )
              }
            }
          }
          // Get Downstream Tasks
          let previousDownstreamTasks: TsInterface_UnspecifiedObject = {}
          databasePromiseArray.push(
            DatabaseGetCollection(DatabaseRef_ProjectTasksForPrerequisiteTask_Query(res_GCK.clientKey, taskKey as string))
              .then((res_DGC) => {
                previousDownstreamTasks = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          // After All Data Loaded
          Promise.all(databasePromiseArray).finally(() => {
            // Instantiate Variables
            let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
            let baseTask: TsInterface_UnspecifiedObject = getProp(formAdditionalData, 'task', {})
            // Upstream Tasks
            let readyToStart = true
            for (let loopTaskKey in formSubmittedData.upstream_tasks) {
              if (formSubmittedData.upstream_tasks[loopTaskKey] === true) {
                taskUpdateObject['prerequisite_tasks'][loopTaskKey] = loopTaskKey
                let fullUpstreamTask = getProp(upstreamTasks, loopTaskKey, {})
                if (fullUpstreamTask != null && fullUpstreamTask.status_complete === false) {
                  readyToStart = false
                } else if (fullUpstreamTask != null && fullUpstreamTask.status_complete === true && fullUpstreamTask.timestamp_completed != null) {
                  taskUpdateObject['prerequisite_tasks_completion'][loopTaskKey] = fullUpstreamTask.timestamp_completed
                }
              }
            }
            taskUpdateObject['ready_to_start'] = readyToStart
            // Previous Downstream Tasks
            for (let loopPreviousDownstreamTaskKey in previousDownstreamTasks) {
              let loopPreviousDownstreamTask = previousDownstreamTasks[loopPreviousDownstreamTaskKey]
              let prerequisiteTasks = getProp(loopPreviousDownstreamTask, 'prerequisite_tasks', {})
              let prerequisiteTaskCompletions = getProp(loopPreviousDownstreamTask, 'prerequisite_tasks_completion', {})
              // Wipe if no longer listed
              if (
                loopPreviousDownstreamTaskKey != null &&
                (formSubmittedData == null ||
                  formSubmittedData['downstream_tasks'] == null ||
                  formSubmittedData['downstream_tasks'][loopPreviousDownstreamTaskKey] == null)
              ) {
                delete prerequisiteTasks[loopPreviousDownstreamTaskKey]
                delete prerequisiteTaskCompletions[loopPreviousDownstreamTaskKey]
                updateArray.push({
                  type: 'update',
                  ref: DatabaseRef_Task_Document(res_GCK.clientKey, loopPreviousDownstreamTaskKey),
                  data: {
                    prerequisite_tasks: prerequisiteTasks,
                    prerequisite_tasks_completion: prerequisiteTaskCompletions,
                    timestamp_last_updated: new Date(),
                  },
                })
              }
            }
            // New Downstream Tasks
            if (formSubmittedData != null && formSubmittedData['downstream_tasks'] != null) {
              for (let loopNewDownstreamTaskKey in formSubmittedData['downstream_tasks']) {
                let loopNewDownstreamTask = formSubmittedData['downstream_tasks'][loopNewDownstreamTaskKey]
                let prerequisiteTasks = getProp(loopNewDownstreamTask, 'prerequisite_tasks', {})
                let prerequisiteTaskCompletions = getProp(loopNewDownstreamTask, 'prerequisite_tasks_completion', {})
                if (baseTask != null && baseTask['status_complete'] === true && baseTask['timestamp_completed'] != null) {
                  if (formSubmittedData['downstream_tasks'][loopNewDownstreamTaskKey] === true) {
                    prerequisiteTasks[taskKey] = taskKey
                    prerequisiteTaskCompletions[taskKey] = returnDateFromUnknownDateFormat(baseTask['timestamp_completed'])
                  }
                  updateArray.push({
                    type: 'update',
                    ref: DatabaseRef_Task_Document(res_GCK.clientKey, loopNewDownstreamTaskKey),
                    data: {
                      prerequisite_tasks: prerequisiteTasks,
                      prerequisite_tasks_completion: prerequisiteTaskCompletions,
                      timestamp_last_updated: new Date(),
                    },
                  })
                } else {
                  if (formSubmittedData['downstream_tasks'][loopNewDownstreamTaskKey] === true) {
                    prerequisiteTasks[taskKey] = taskKey
                  }
                  updateArray.push({
                    type: 'update',
                    ref: DatabaseRef_Task_Document(res_GCK.clientKey, loopNewDownstreamTaskKey),
                    data: {
                      prerequisite_tasks: prerequisiteTasks,
                      timestamp_last_updated: new Date(),
                    },
                  })
                }
              }
            }
            // Other Update Objects
            updateArray.push({ type: 'update', ref: DatabaseRef_Task_Document(res_GCK.clientKey, taskKey), data: taskUpdateObject })
            // TODO - log
            // updateArray.push( { type: "setMerge", ref: DatabaseRef_ProjectLogs_Document( res_GCK.clientKey, formAdditionalData.associated_project_key as string, logKey ), data: logUpdateObject })
            // Batch Update
            DatabaseBatchUpdate(updateArray)
              .then((res_DBU) => {
                cloudFunctionManageRequest('manageTasks', {
                  function: 'refreshProjectTaskProgressBar',
                  client_key: res_GCK.clientKey,
                  project_key: getProp(formAdditionalData.task, 'associated_project_key', null),
                })
                resolve(res_DBU)
              })
              .catch((rej_DBU) => {
                reject(rej_DBU)
              })
          })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_TASK,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TF-FSET-01',
        },
      })
    }
  })
}

export const formSettings_EditTask: TsInterface_FormSettings = {
  // highlight_missing: true,
  // submit_button_alignment: "right",
  // submit_button_hide: false,
  // submit_button_icon: <SaveIcon/>,
  // submit_button_saving_icon: true,
  // submit_button_text: <>{s_SAVE}</>,
}

export const formInputs_EditTask: TsInterface_FormInputs = {
  upstream_tasks: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'upstream_tasks',
    label: s_TASKS_THAT_MUST_BE_COMPLETED_BEFORE_STARTING_THIS_TASK,
    required: false,
    options: [], // Dynamically populated when form used
  },
  downstream_tasks: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'downstream_tasks',
    label: s_TASKS_THAT_CANNOT_START_UNTIL_THIS_TASK_IS_DONE,
    required: false,
    options: [], // Dynamically populated when form used
  },
  associated_task_form_key: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_task_form_key',
    label: s_TASK_FORM,
    required: true,
    options: [], // Dynamically populated when form used
  },
  status_green_days_cutoff: {
    key: 'status_green_days_cutoff',
    label: s_GREEN_STATUS_CUTOFF_DAYS,
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    startAdornment: (
      <Box sx={{ color: themeVariables.success_main }}>
        <Icon icon="circle-play" />
      </Box>
    ),
  },
  status_yellow_days_cutoff: {
    key: 'status_yellow_days_cutoff',
    label: s_YELLOW_STATUS_CUTOFF_DAYS,
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    startAdornment: (
      <Box sx={{ color: themeVariables.warning_main }}>
        <Icon icon="triangle-exclamation" />
      </Box>
    ),
  },
}

export const formInputs_EditTask_Direct: TsInterface_FormInputs = {
  upstream_tasks: formInputs_EditTask['upstream_tasks'],
  downstream_tasks: formInputs_EditTask['downstream_tasks'],
  associated_task_form_key: formInputs_EditTask['associated_task_form_key'],
  status_green_days_cutoff: formInputs_EditTask['status_green_days_cutoff'],
  status_yellow_days_cutoff: formInputs_EditTask['status_yellow_days_cutoff'],
}

export const formInputs_EditTask_Dispatcher: TsInterface_FormInputs = {
  upstream_tasks: formInputs_EditTask['upstream_tasks'],
  downstream_tasks: formInputs_EditTask['downstream_tasks'],
  status_green_days_cutoff: formInputs_EditTask['status_green_days_cutoff'],
  status_yellow_days_cutoff: formInputs_EditTask['status_yellow_days_cutoff'],
}

export const formInputs_EditTask_Scheduled: TsInterface_FormInputs = {
  upstream_tasks: formInputs_EditTask['upstream_tasks'],
  downstream_tasks: formInputs_EditTask['downstream_tasks'],
  associated_team_type: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_team_type',
    label: s_SCHEDULED_TEAM_TYPE,
    required: false,
    options: objectToArray(schedulingTeamTypes),
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'task_completion_type',
        comparator: '==',
        value: 'scheduled',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'task_completion_type',
        comparator: '==',
        value: 'scheduled',
        conditions: [],
      },
    },
  },
  associated_task_form_key: formInputs_EditTask['associated_task_form_key'],
  status_green_days_cutoff: formInputs_EditTask['status_green_days_cutoff'],
  status_yellow_days_cutoff: formInputs_EditTask['status_yellow_days_cutoff'],
}

// Edit Team
export const formOnChange_EditTaskTeam: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
  // Nothing
}

export const formSubmission_EditTaskTeam: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    if (
      formAdditionalData != null &&
      formAdditionalData.clientKey != null &&
      formAdditionalData.task != null &&
      getProp(formAdditionalData.task, 'key', null) != null &&
      getProp(formAdditionalData.task, 'associated_project_key', null) != null
    ) {
      DatabaseGetDocument(
        DatabaseRef_Project_Document(formAdditionalData.clientKey as string, getProp(formAdditionalData.task, 'associated_project_key', null) as string),
      )
        .then((res_DGD) => {
          let projectData = res_DGD.data
          let updateObject: TsInterface_UnspecifiedObject = {
            associated_owner_type: getProp(formSubmittedData, 'associated_owner_type', null),
            associated_owner_name: null,
            associated_owner_key: null,
          }
          if (projectData != null && projectData['associated_' + getProp(formSubmittedData, 'associated_owner_type', '_MISSING') + '_key'] != null) {
            updateObject['associated_owner_key'] = projectData['associated_' + getProp(formSubmittedData, 'associated_owner_type', '_MISSING') + '_key']
          }
          if (projectData != null && projectData['associated_' + getProp(formSubmittedData, 'associated_owner_type', '_MISSING') + '_name'] != null) {
            updateObject['associated_owner_name'] = projectData['associated_' + getProp(formSubmittedData, 'associated_owner_type', '_MISSING') + '_name']
          }
          DatabaseSetMergeDocument(
            DatabaseRef_Task_Document(formAdditionalData.clientKey as string, getProp(formAdditionalData.task, 'key', null) as string),
            updateObject,
          )
            .then((res_DSM) => {
              resolve(res_DSM)
            })
            .catch((rej_DSM) => {
              reject(rej_DSM)
            })
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
          reject(rej_DGD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to update task team'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-TF-FSETT-01',
        },
      })
    }
  })
}

export const formSettings_EditTaskTeam: TsInterface_FormSettings = {}

export const formInputs_EditTaskTeam: TsInterface_FormInputs = {
  associated_owner_type: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_owner_type',
    label: rLIB('Team Type'),
    required: true,
    options: [], // Dynamically populated when form used
  },
}

// AdHoc
export const formOnChange_AdHocTask: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
  // Instantiate Variables
  let allTasks = getProp(formAdditionalData, 'projectTasks', {})
  let recursiveTasks = getProp(formAdditionalData, 'recursiveTasks', {})
  let currentUpstreamTasks = getProp(formData, 'upstream_tasks', {})
  let currentDownstreamTasks = getProp(formData, 'downstream_tasks', {})
  // Loop through downstream tasks
  for (let loopDownstreamTaskKey in currentDownstreamTasks) {
    let hasAnUpstreamTask = false
    let loopDownstreamTask = allTasks[loopDownstreamTaskKey]
    // If the same task is in both upstream and downstream form inputs
    if (currentUpstreamTasks[loopDownstreamTaskKey] === true) {
      hasAnUpstreamTask = true
    }
    // Loop through upstream tasks
    for (let loopUpstreamTaskKey in currentUpstreamTasks) {
      let loopUpstreamTask = allTasks[loopUpstreamTaskKey]
      if (currentUpstreamTasks[loopUpstreamTaskKey] === true) {
        if (checkIfTaskIsUpstream(allTasks, loopDownstreamTask, loopUpstreamTask, recursiveTasks, ['formOnChange_AdHocTask']) === true) {
          hasAnUpstreamTask = true
        }
      }
    }
    if (hasAnUpstreamTask === true) {
      // @ts-expect-error - TODO: reason for error
      formData['downstream_tasks'][loopDownstreamTaskKey] = false
    }
  }
}

export const formSubmission_AdHocTaskCreate: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    try {
      if (
        formAdditionalData.associated_project_key != null &&
        (formAdditionalData.task_type === 'ad_hoc' || (formAdditionalData.task_type === 'sow' && formAdditionalData.sow_item_key != null))
      ) {
        let createTimestamp = new Date()
        let mainAdHocAbbreviation = ''
        let dispatchAdHocAbbreviation = ''
        let newTaskKey = ''
        let logCode = ''
        if (formAdditionalData.task_type === 'ad_hoc') {
          mainAdHocAbbreviation = 'AHCC'
          dispatchAdHocAbbreviation = 'AHCA'
          newTaskKey = formAdditionalData.associated_project_key + '_adhoc_' + createTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
          logCode = 'create_ad_hoc_task'
        } else if (formAdditionalData.task_type === 'sow') {
          mainAdHocAbbreviation = 'SOWC'
          dispatchAdHocAbbreviation = 'SOWA'
          newTaskKey = formAdditionalData.associated_project_key + '_sow_' + formAdditionalData.sow_item_key
          logCode = 'create_sow_task'
        }
        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            // Get Upstream Tasks
            let upstreamTasks: TsInterface_UnspecifiedObject = {}
            let databasePromiseArray: TsType_UnknownPromise[] = []
            if (formSubmittedData.upstream_tasks != null) {
              for (let loopTaskKey in formSubmittedData.upstream_tasks) {
                if (formSubmittedData.upstream_tasks[loopTaskKey] === true) {
                  databasePromiseArray.push(
                    DatabaseGetDocument(DatabaseRef_Task_Document(res_GCK.clientKey, loopTaskKey as string))
                      .then((res_DGD) => {
                        upstreamTasks[loopTaskKey] = res_DGD.data
                      })
                      .catch((rej_DGD) => {
                        console.error(rej_DGD)
                      }),
                  )
                }
              }
            }
            Promise.all(databasePromiseArray).finally(() => {
              // Get Project
              DatabaseGetDocument(DatabaseRef_Project_Document(res_GCK.clientKey, formAdditionalData.associated_project_key as string))
                .then((res_DGD) => {
                  // Instantiate Variables
                  let project = res_DGD.data
                  let logKey = createTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
                  let taskGroup: TsInterface_UnspecifiedObject = {}
                  if (
                    formSubmittedData != null &&
                    formSubmittedData['associated_task_group_key'] != null &&
                    formAdditionalData != null &&
                    formAdditionalData['task_groups'] != null
                  ) {
                    taskGroup = getProp(formAdditionalData['task_groups'], formSubmittedData['associated_task_group_key'], {})
                  }
                  let delayReasons: TsInterface_UnspecifiedObject = {}
                  let defaultDelayReason: string | null = null
                  if (
                    formAdditionalData != null &&
                    formAdditionalData.originalFormData != null &&
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData.originalFormData.delay_reasons != null
                  ) {
                    // @ts-expect-error - TODO: reason for error
                    delayReasons = formAdditionalData.originalFormData.delay_reasons
                  }
                  if (
                    formAdditionalData != null &&
                    formAdditionalData.originalFormData != null &&
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData.originalFormData.default_delay_reason != null
                  ) {
                    // @ts-expect-error - TODO: reason for error
                    defaultDelayReason = formAdditionalData.originalFormData.default_delay_reason
                  }
                  // Initial Update Objects
                  let projectUpdateObject: TsInterface_UnspecifiedObject = {}
                  let taskUpdateObject: TsInterface_UnspecifiedObject = {
                    abbreviation: mainAdHocAbbreviation,
                    associated_owner_key: null,
                    associated_owner_name: null,
                    associated_owner_type: formSubmittedData.associated_owner_type,
                    associated_project_id_number: project.id_number,
                    associated_project_key: formAdditionalData.associated_project_key,
                    associated_task_form_key: getProp(formSubmittedData, 'associated_task_form_key', null),
                    associated_task_group_name: getProp(taskGroup, 'name', null),
                    associated_task_group_key: getProp(formSubmittedData, 'associated_task_group_key', null),
                    associated_task_key: newTaskKey,
                    associated_task_origin: formAdditionalData.task_type,
                    associated_team_type: getProp(formSubmittedData, 'associated_team_type', null),
                    default_delay_reason: defaultDelayReason,
                    delay_reasons: delayReasons,
                    id_number: null,
                    key: newTaskKey,
                    name: formSubmittedData.name,
                    prerequisite_tasks: {},
                    prerequisite_tasks_completion: {},
                    ready_to_start: true,
                    status: null,
                    status_complete: false,
                    status_green_days_cutoff: formSubmittedData.status_green_days_cutoff,
                    status_yellow_days_cutoff: formSubmittedData.status_yellow_days_cutoff,
                    task_completion_type: formSubmittedData.task_completion_type,
                    timestamp_assigned: null,
                    timestamp_created: createTimestamp,
                    timestamp_last_updated: new Date(),
                  }
                  let logUpdateObject: TsInterface_UnspecifiedObject = {
                    timestamp: createTimestamp,
                    associated_user_key: getProp(formHooks.uc_RootData_ClientUser, 'key', null),
                    associated_user_name: getProp(formHooks.uc_RootData_ClientUser, 'name', null),
                    event: logCode,
                    additional_data: {
                      associated_task_name: formSubmittedData.name,
                    },
                  }
                  // Dynamic Update Object Changes
                  let addHocTaskNumber = 0
                  if (project['count_ad_hoc_tasks'] == null) {
                    addHocTaskNumber = 1
                    projectUpdateObject['count_ad_hoc_tasks'] = 1
                  } else {
                    addHocTaskNumber = project['count_ad_hoc_tasks'] + 1
                    projectUpdateObject['count_ad_hoc_tasks'] = addHocTaskNumber + 1
                  }
                  let taskIdString = addHocTaskNumber.toString()
                  if (taskIdString.length === 0) {
                    taskIdString = '000'
                  } else if (taskIdString.length === 1) {
                    taskIdString = '00' + taskIdString
                  } else if (taskIdString.length === 2) {
                    taskIdString = '0' + taskIdString
                  } else if (taskIdString.length === 3) {
                    // Nothing
                  } else if (taskIdString.length > 3) {
                    taskIdString = taskIdString.substring(taskIdString.length - 3, taskIdString.length)
                  }
                  taskUpdateObject['id_number'] = project['id_number'] + '-' + mainAdHocAbbreviation + taskIdString
                  // Task Completion Order
                  let readyToStart = true
                  for (let loopTaskKey in formSubmittedData.upstream_tasks) {
                    if (formSubmittedData.upstream_tasks[loopTaskKey] === true) {
                      taskUpdateObject['prerequisite_tasks'][loopTaskKey] = loopTaskKey
                      let fullUpstreamTask = getProp(upstreamTasks, loopTaskKey, {})
                      if (fullUpstreamTask != null && fullUpstreamTask.status_complete === false) {
                        readyToStart = false
                      } else if (fullUpstreamTask != null && fullUpstreamTask.status_complete === true && fullUpstreamTask.timestamp_completed != null) {
                        taskUpdateObject['prerequisite_tasks_completion'][loopTaskKey] = fullUpstreamTask.timestamp_completed
                      }
                    }
                  }
                  taskUpdateObject['ready_to_start'] = readyToStart
                  // Task Ownership
                  if (
                    formSubmittedData.associated_owner_type != null &&
                    project != null &&
                    project['associated_' + formSubmittedData.associated_owner_type + '_key'] != null &&
                    project['associated_' + formSubmittedData.associated_owner_type + '_name'] != null
                  ) {
                    taskUpdateObject['status'] = 'active'
                    taskUpdateObject['timestamp_assigned'] = createTimestamp
                    taskUpdateObject['associated_owner_key'] = project['associated_' + formSubmittedData.associated_owner_type + '_key']
                    taskUpdateObject['associated_owner_name'] = project['associated_' + formSubmittedData.associated_owner_type + '_name']
                  } else {
                    taskUpdateObject['status'] = 'unassigned'
                  }
                  // If Scheduled Task, Generate Upstream Task
                  let hasDispatchTask = false
                  let dispatchTaskUpdateObject: TsInterface_UnspecifiedObject = {
                    timestamp_last_updated: new Date(),
                  }
                  if (formSubmittedData.task_completion_type === 'scheduled') {
                    hasDispatchTask = true
                    dispatchTaskUpdateObject = cloneObjectWithoutReference(taskUpdateObject)
                    dispatchTaskUpdateObject['associated_dispatched_task_key'] = newTaskKey
                    dispatchTaskUpdateObject['abbreviation'] = dispatchAdHocAbbreviation
                    dispatchTaskUpdateObject['associated_owner_type'] = formSubmittedData.associated_dispatcher_type
                    dispatchTaskUpdateObject['associated_task_form_key'] = null
                    dispatchTaskUpdateObject['id_number'] = project['id_number'] + '-' + dispatchAdHocAbbreviation + taskIdString

                    dispatchTaskUpdateObject['key'] = dispatchTaskUpdateObject['key'] + '_dispatch'
                    dispatchTaskUpdateObject['name'] = 'Schedule ' + dispatchTaskUpdateObject['name']
                    // dispatchTaskUpdateObject["name"] = "Assign " + dispatchTaskUpdateObject["name"]
                    dispatchTaskUpdateObject['task_completion_type'] = 'dispatcher'
                    dispatchTaskUpdateObject['timestamp_created'] = createTimestamp
                    if (
                      formSubmittedData.associated_dispatcher_type != null &&
                      project != null &&
                      project['associated_' + formSubmittedData.associated_dispatcher_type + '_key'] != null &&
                      project['associated_' + formSubmittedData.associated_dispatcher_type + '_name'] != null
                    ) {
                      dispatchTaskUpdateObject['status'] = 'active'
                      dispatchTaskUpdateObject['timestamp_assigned'] = createTimestamp
                      dispatchTaskUpdateObject['associated_owner_key'] = project['associated_' + formSubmittedData.associated_dispatcher_type + '_key']
                      dispatchTaskUpdateObject['associated_owner_name'] = project['associated_' + formSubmittedData.associated_dispatcher_type + '_name']
                    } else {
                      dispatchTaskUpdateObject['status'] = 'unassigned'
                    }
                    taskUpdateObject['prerequisite_tasks'][dispatchTaskUpdateObject['key']] = dispatchTaskUpdateObject['key']
                    taskUpdateObject['ready_to_start'] = false
                  }
                  // Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                    { type: 'setMerge', ref: DatabaseRef_Task_Document(res_GCK.clientKey, taskUpdateObject.key as string), data: taskUpdateObject },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_Project_Document(res_GCK.clientKey, formAdditionalData.associated_project_key as string),
                      data: projectUpdateObject,
                    },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_ProjectLogs_Document(res_GCK.clientKey, formAdditionalData.associated_project_key as string, logKey),
                      data: logUpdateObject,
                    },
                  ]
                  if (hasDispatchTask === true) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_Task_Document(res_GCK.clientKey, dispatchTaskUpdateObject.key),
                      data: dispatchTaskUpdateObject,
                    })
                  }
                  // Downstream Tasks
                  let downstreamTaskUpdateObject: TsInterface_UnspecifiedObject = {
                    prerequisite_tasks: {},
                    timestamp_last_updated: new Date(),
                  }
                  downstreamTaskUpdateObject['prerequisite_tasks'][newTaskKey] = newTaskKey
                  for (let loopTaskKey in formSubmittedData.downstream_tasks) {
                    if (formSubmittedData.downstream_tasks[loopTaskKey] === true) {
                      updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(res_GCK.clientKey, loopTaskKey), data: downstreamTaskUpdateObject })
                    }
                  }
                  // Batch Update
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      cloudFunctionManageRequest('manageTasks', {
                        function: 'refreshProjectTaskProgressBar',
                        client_key: res_GCK.clientKey,
                        project_key: formAdditionalData.associated_project_key,
                      })
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
                .catch((rej_DGD) => {
                  reject(rej_DGD)
                })
            })
          })
          .catch((rej_GCK) => {
            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            reject(rej_GCK)
          })
      } else {
        reject({
          success: false,
          error: {
            message: s_FAILED_TO_CREATE_TASK,
            details: s_MISSING_REQUIRED_PARAMETERS,
            code: 'ER-D-TF-FSAHTC-01',
          },
        })
      }
    } catch (rej_T) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_TASK,
          details: rej_T,
          code: 'ER-D-TF-FSAHTC-02',
        },
      })
    }
  })
}

export const formSettings_AdHocTask: TsInterface_FormSettings = {
  // highlight_missing: true,
  // submit_button_alignment: "right",
  // submit_button_hide: false,
  // submit_button_icon: <SaveIcon/>,
  // submit_button_saving_icon: true,
  // submit_button_text: <>{s_SAVE}</>,
}

export const formInputs_AdHocTaskNew: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: s_TASK_NAME,
    required: true,
    conditional_disable: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formAdditionalData',
        prop: 'task_type',
        comparator: '==',
        value: 'sow',
        conditions: [],
      },
    },
  },
  associated_owner_type: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_owner_type',
    label: s_TASK_OWNER_TYPE,
    required: true,
    options: [], // Dynamically populated when form used
  },
  task_completion_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'task_completion_type',
    label: rLIB('Task Completion Type'),
    required: true,
    options: objectToArray(adHocTaskAssignmentTypeInputOptions),
  },
  associated_dispatcher_type: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_dispatcher_type',
    label: s_TASK_DISPATCHER_TYPE,
    required: false,
    options: [], // Dynamically populated when form used
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'task_completion_type',
        comparator: '==',
        value: 'scheduled',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'task_completion_type',
        comparator: '==',
        value: 'scheduled',
        conditions: [],
      },
    },
  },
  associated_team_type: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_team_type',
    label: s_SCHEDULED_TEAM_TYPE,
    required: false,
    options: objectToArray(schedulingTeamTypes),
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'task_completion_type',
        comparator: '==',
        value: 'scheduled',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'task_completion_type',
        comparator: '==',
        value: 'scheduled',
        conditions: [],
      },
    },
  },
  associated_task_form_key: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_task_form_key',
    label: s_TASK_FORM,
    required: true,
    options: [], // Dynamically populated when form used
  },
  associated_task_group_key: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_task_group_key',
    label: s_TASK_GROUP,
    required: true,
    options: [],
  },
  upstream_tasks: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'upstream_tasks',
    label: s_TASKS_THAT_MUST_BE_COMPLETED_BEFORE_STARTING_THIS_TASK,
    required: false,
    options: [], // Dynamically populated when form used
  },
  downstream_tasks: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'downstream_tasks',
    label: s_TASKS_THAT_CANNOT_START_UNTIL_THIS_TASK_IS_DONE,
    required: false,
    options: [], // Dynamically populated when form used
  },
  status_green_days_cutoff: {
    key: 'status_green_days_cutoff',
    label: s_GREEN_STATUS_CUTOFF_DAYS,
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    startAdornment: (
      <Box sx={{ color: themeVariables.success_main }}>
        <Icon icon="circle-play" />
      </Box>
    ),
  },
  status_yellow_days_cutoff: {
    key: 'status_yellow_days_cutoff',
    label: s_YELLOW_STATUS_CUTOFF_DAYS,
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    startAdornment: (
      <Box sx={{ color: themeVariables.warning_main }}>
        <Icon icon="triangle-exclamation" />
      </Box>
    ),
  },
}

// Redo and Retry
export const formOnChange_RedoTask: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
  // Instantiate Variables
  let allTasks = getProp(formAdditionalData, 'projectTasks', {})
  let task = getProp(formAdditionalData, 'task', {})
  let recursiveTasks = getProp(formAdditionalData, 'recursiveTasks', {})
  let taskKey = getProp(task, 'key', null)
  let currentUpstreamTasks = getProp(task, 'prerequisite_tasks', {})
  let currentDownstreamTasks = getProp(formData, 'downstream_tasks', {})
  // Prevent Task that is being edited from being used in upstream or downstream
  if (taskKey != null) {
    if (currentUpstreamTasks[taskKey] === true) {
      // @ts-expect-error - TODO: reason for error
      formData['upstream_tasks'][taskKey] = false
    }
    if (currentDownstreamTasks[taskKey] === true) {
      // @ts-expect-error - TODO: reason for error
      formData['downstream_tasks'][taskKey] = false
    }
  }
  // Loop through downstream tasks
  for (let loopDownstreamTaskKey in currentDownstreamTasks) {
    let hasAnUpstreamTask = false
    let loopDownstreamTask = allTasks[loopDownstreamTaskKey]
    // If the same task is in both upstream and downstream form inputs
    if (currentUpstreamTasks[loopDownstreamTaskKey] != null) {
      hasAnUpstreamTask = true
    }
    // Loop through upstream tasks
    for (let loopUpstreamTaskKey in currentUpstreamTasks) {
      let loopUpstreamTask = allTasks[loopUpstreamTaskKey]
      if (currentUpstreamTasks[loopUpstreamTaskKey] != null) {
        if (checkIfTaskIsUpstream(allTasks, loopDownstreamTask, loopUpstreamTask, recursiveTasks, ['formOnChange_RedoTask']) === true) {
          hasAnUpstreamTask = true
        }
      }
    }
    if (hasAnUpstreamTask === true) {
      // @ts-expect-error - TODO: reason for error
      formData['downstream_tasks'][loopDownstreamTaskKey] = false
    }
  }
}

// task_completion_type === direct
export const formSubmission_RedoTask: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    if (formAdditionalData.task_key != null) {
      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_Task_Document(res_GCK.clientKey, formAdditionalData.task_key as string))
            .then((res_DGD) => {
              let taskToRedo = cloneObjectWithoutReference(res_DGD.data)
              let databasePromiseArray: TsType_UnknownPromise[] = []
              let originalAndPreviousRedoTasks: TsInterface_UnspecifiedObject = {}
              if (taskToRedo != null && taskToRedo.associated_task_key != null && taskToRedo.associated_project_key != null) {
                databasePromiseArray.push(
                  DatabaseGetCollection(
                    DatabaseRef_AllProjectTasksFromSameWorkflowTask_Query(res_GCK.clientKey, taskToRedo.associated_project_key, taskToRedo.associated_task_key),
                  )
                    .then((res_DGC) => {
                      originalAndPreviousRedoTasks = res_DGC.data
                    })
                    .catch((rej_DGC) => {
                      console.error(rej_DGC)
                    }),
                )
              }
              Promise.all(databasePromiseArray).finally(() => {
                let redoTimestamp = new Date()
                let logKey = redoTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
                let redoUniqueKey = redoTimestamp.getTime().toString()
                let redoCount = objectToArray(originalAndPreviousRedoTasks).length.toString()
                // taskToRedo["associated_task_origin"] = "redo" // Messes up workflow unassignment queries
                taskToRedo['redo_and_retry_attempts'] = {}
                taskToRedo['redo_and_retry_attempts'][redoTimestamp.getTime().toString()] = {
                  type: 'redo',
                  timestamp: redoTimestamp,
                  redo_notes: formSubmittedData.redo_notes,
                  // redo_reason: formSubmittedData.redo_reason,
                  associated_user_key: getProp(formHooks.uc_RootData_ClientUser, 'key', null),
                  associated_user_name: getProp(formHooks.uc_RootData_ClientUser, 'name', null),
                }
                taskToRedo['id_number'] = taskToRedo['id_number'] + '-REDO' + redoCount
                taskToRedo['key'] = taskToRedo['key'] + '_redo_' + redoUniqueKey
                taskToRedo['status'] = 'active'
                taskToRedo['name'] = taskToRedo['name'] + ' (REDO ' + redoCount + ')'
                taskToRedo['status_complete'] = false
                taskToRedo['timestamp_redo'] = redoTimestamp
                taskToRedo['timestamp_assigned'] = redoTimestamp
                taskToRedo['timestamp_created'] = redoTimestamp
                taskToRedo['timestamp_completed'] = null
                taskToRedo['timestamp_completed_by_key'] = null
                taskToRedo['timestamp_completed_by_name'] = null
                taskToRedo['timestamp_last_updated'] = new Date()
                let logUpdateObject: TsInterface_UnspecifiedObject = {
                  timestamp: redoTimestamp,
                  associated_user_key: getProp(formHooks.uc_RootData_ClientUser, 'key', null),
                  associated_user_name: getProp(formHooks.uc_RootData_ClientUser, 'name', null),
                  event: 'create_redo_task',
                  additional_data: {
                    associated_task_key: formAdditionalData.task_key,
                    associated_task_name: taskToRedo.name,
                  },
                }
                // Update Array
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  { type: 'setMerge', ref: DatabaseRef_Task_Document(res_GCK.clientKey, taskToRedo['key']), data: taskToRedo },
                  {
                    type: 'setMerge',
                    ref: DatabaseRef_ProjectLogs_Document(res_GCK.clientKey, taskToRedo.associated_project_key as string, logKey),
                    data: logUpdateObject,
                  },
                ]
                // Downstream Tasks
                let downstreamTaskUpdateObject: TsInterface_UnspecifiedObject = {
                  prerequisite_tasks: {},
                  timestamp_last_updated: new Date(),
                }
                downstreamTaskUpdateObject['prerequisite_tasks'][taskToRedo['key']] = taskToRedo['key']
                for (let loopTaskKey in formSubmittedData.downstream_tasks) {
                  if (formSubmittedData.downstream_tasks[loopTaskKey] === true) {
                    updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(res_GCK.clientKey, loopTaskKey), data: downstreamTaskUpdateObject })
                  }
                }
                // Batch Update
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    cloudFunctionManageRequest('manageTasks', {
                      function: 'refreshProjectTaskProgressBar',
                      client_key: res_GCK.clientKey,
                      project_key: taskToRedo.associated_project_key,
                    })
                    resolve(res_DBU)
                  })
                  .catch((rej_DBU) => {
                    reject(rej_DBU)
                  })
              })
            })
            .catch((rej_DGD) => {
              reject(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_REDO_TASK,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TF-FSRT-01',
        },
      })
    }
  })
}

export const formSettings_RedoTask: TsInterface_FormSettings = {
  // highlight_missing: true,
  // submit_button_alignment: "right",
  // submit_button_hide: false,
  // submit_button_icon: <SaveIcon/>,
  // submit_button_saving_icon: true,
  // submit_button_text: <>{s_SAVE}</>,
}

export const formInputs_RedoTask: TsInterface_FormInputs = {
  // redo_reason: {
  // data_type: "string",
  // input_type: "multiple_choice_radio",
  // key: "redo_reason",
  // label: s_REDO_REASON,
  // required: true,
  // options: []
  // },
  redo_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'redo_notes',
    label: s_REDO_REASON_NOTES,
    required: true,
  },
  downstream_tasks: {
    data_type: 'string',
    input_type: 'multiple_select_checklist',
    key: 'downstream_tasks',
    label: s_TASKS_THAT_CANNOT_START_UNTIL_THE_REDO_TASK_IS_DONE,
    required: false,
    options: [], // Dynamically populated when form used
  },
}

export const formOnChange_RetryTask: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
  // Nothing
}

// task_completion_type === scheduled | dispatcher
export const formSubmission_RetryTask: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    if (formAdditionalData.task_key != null) {
      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_Task_Document(res_GCK.clientKey, formAdditionalData.task_key as string))
            .then((res_DGD) => {
              let taskToRetry = cloneObjectWithoutReference(res_DGD.data)
              DatabaseGetCollection(
                DatabaseRef_UpstreamDispatcherTask_Query(res_GCK.clientKey, taskToRetry.associated_project_key, formAdditionalData.task_key as string),
              )
                .then((res_DGC) => {
                  if (res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                    let promiseArray: TsType_UnknownPromise[] = []
                    let previousCompletionTimestamp: null | Date = null
                    let previousCompletionTimestampDateKey: null | string = null
                    if (taskToRetry != null && taskToRetry.timestamp_completed != null) {
                      previousCompletionTimestamp = returnDateFromUnknownDateFormat(taskToRetry.timestamp_completed)
                      let weekBoundingDates = getStartAndEndOfWeek(previousCompletionTimestamp)
                      previousCompletionTimestampDateKey = returnFormattedDateKey(weekBoundingDates.startOfWeek)
                    }
                    let finalizedPayrollObject: null | TsInterface_UnspecifiedObject = null
                    let unitPayTotals: null | TsInterface_UnspecifiedObject = null
                    if (previousCompletionTimestampDateKey != null) {
                      promiseArray.push(
                        DatabaseGetDocument(DatabaseRef_FinalizedPayroll_Document(res_GCK.clientKey, previousCompletionTimestampDateKey as string))
                          .then((res_DGD) => {
                            if (res_DGD != null && res_DGD.data != null) {
                              finalizedPayrollObject = res_DGD.data
                            }
                          })
                          .catch((rej_DGD) => {
                            console.error(rej_DGD)
                          }),
                      )
                      promiseArray.push(
                        DatabaseGetDocument(
                          DatabaseRef_FinalizedPayroll_UnitPayTasks_Document(
                            res_GCK.clientKey,
                            previousCompletionTimestampDateKey,
                            formAdditionalData.task_key as string,
                          ),
                        )
                          .then((res_DGD) => {
                            if (res_DGD != null && res_DGD.data != null && res_DGD.data['unit_pay_totals'] != null) {
                              unitPayTotals = res_DGD.data['unit_pay_totals']
                            }
                          })
                          .catch((rej_DGD) => {
                            console.error(rej_DGD)
                          }),
                      )
                    }
                    // After data is loaded
                    Promise.all(promiseArray).finally(() => {
                      let lockedPayroll = false
                      if (finalizedPayrollObject != null && finalizedPayrollObject['locked'] === true && unitPayTotals != null) {
                        lockedPayroll = true
                      }
                      // IF LOCKED AND PAYROLL EXISTS - PREVENT REDO
                      if (lockedPayroll === false) {
                        let retryTimestamp = new Date()
                        let dispatcherTask = objectToArray(res_DGC.data)[0]
                        let logKey = retryTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
                        let retryAttemptCount = 1
                        if (taskToRetry != null && taskToRetry['redo_and_retry_attempts'] != null) {
                          let retryAttempts = getProp(taskToRetry, 'redo_and_retry_attempts', {})
                          retryAttemptCount = objectToArray(retryAttempts).length + 1
                        }
                        retryAttemptCount++
                        let logUpdateObject: TsInterface_UnspecifiedObject = {
                          timestamp: retryTimestamp,
                          associated_user_key: getProp(formHooks.uc_RootData_ClientUser, 'key', null),
                          associated_user_name: getProp(formHooks.uc_RootData_ClientUser, 'name', null),
                          event: 'retry_task',
                          additional_data: {
                            associated_task_key: getProp(taskToRetry, 'key', null),
                            associated_task_name: getProp(taskToRetry, 'name', null),
                          },
                        }
                        let mainTaskUpdateObject: TsInterface_UnspecifiedObject = {
                          status: 'active',
                          status_complete: false,
                          timestamp_completed: null,
                          timestamp_completed_by_key: null,
                          timestamp_completed_by_name: null,
                          redo_and_retry_attempts: {},
                          prerequisite_tasks_completion: {},
                          timestamp_last_updated: new Date(),
                          // Copied from - "unassignDispatchedTask"
                          // ready_to_start: false,
                          associated_team_key: null,
                          associated_team_name: null,
                          associated_team_type: null,
                          timestamp_scheduled: null,
                          associated_scheduled_time_slot: null,
                          task_completion_scheduled_dates: null,
                          task_completion_scheduled_start_times: null,
                          task_completion_scheduled_end_times: null,
                          task_completion_scheduled_team_keys: null,
                          task_completion_scheduled_team_names: null,
                          task_completion_scheduled_team_roles: null,
                          // End Copy
                        }
                        // TODO - copy task info so it's not totally removed from the calendar on a redo?
                        mainTaskUpdateObject['prerequisite_tasks_completion'][dispatcherTask.key] = null
                        mainTaskUpdateObject['redo_and_retry_attempts'][retryTimestamp.getTime().toString()] = {
                          type: 'retry',
                          timestamp: retryTimestamp,
                          redo_notes: formSubmittedData.redo_notes,
                          redo_reason: formSubmittedData.redo_reason,
                          associated_user_key: getProp(formHooks.uc_RootData_ClientUser, 'key', null),
                          associated_user_name: getProp(formHooks.uc_RootData_ClientUser, 'name', null),
                        }
                        let dispatcherTaskUpdateObject: TsInterface_UnspecifiedObject = {
                          status: 'active',
                          status_complete: false,
                          timestamp_completed: null,
                          timestamp_completed_by_key: null,
                          timestamp_completed_by_name: null,
                          timestamp_last_updated: new Date(),
                        }
                        if (taskToRetry.name_original == null) {
                          mainTaskUpdateObject['name_original'] = taskToRetry.name
                          mainTaskUpdateObject['name'] = taskToRetry.name + ' (ATTEMPT ' + retryAttemptCount + ')'
                        } else {
                          mainTaskUpdateObject['name'] = taskToRetry.name_original + ' (ATTEMPT ' + retryAttemptCount + ')'
                        }
                        if (dispatcherTask.name_original == null) {
                          dispatcherTaskUpdateObject['name_original'] = dispatcherTask.name
                          dispatcherTaskUpdateObject['name'] = dispatcherTask.name + ' (ATTEMPT ' + retryAttemptCount + ')'
                        } else {
                          dispatcherTaskUpdateObject['name'] = dispatcherTask.name_original + ' (ATTEMPT ' + retryAttemptCount + ')'
                        }
                        // Update Array
                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                          { type: 'setMerge', ref: DatabaseRef_Task_Document(res_GCK.clientKey, taskToRetry['key']), data: mainTaskUpdateObject },
                          { type: 'setMerge', ref: DatabaseRef_Task_Document(res_GCK.clientKey, dispatcherTask['key']), data: dispatcherTaskUpdateObject },
                          {
                            type: 'setMerge',
                            ref: DatabaseRef_ProjectLogs_Document(res_GCK.clientKey, taskToRetry.associated_project_key as string, logKey),
                            data: logUpdateObject,
                          },
                        ]
                        createCancelledCalendarEvent(
                          res_GCK.clientKey,
                          taskToRetry,
                          'return_update_object',
                          getProp(formSubmittedData, 'redo_notes', null),
                          getProp(formSubmittedData, 'redo_reason', null),
                          previousCompletionTimestamp,
                        )
                          .then((res_CCCE) => {
                            updateArray.push({
                              type: 'setMerge',
                              // @ts-expect-error - TODO: reason for error
                              ref: DatabaseRef_CancelledScheduledTask_Document(res_GCK.clientKey, res_CCCE.key),
                              // @ts-expect-error - TODO: reason for error
                              data: res_CCCE.data,
                            })
                            // Batch Update
                            DatabaseBatchUpdate(updateArray)
                              .then((res_DBU) => {
                                cloudFunctionManageRequest('manageTasks', {
                                  function: 'refreshProjectTaskProgressBar',
                                  client_key: res_GCK.clientKey,
                                  project_key: taskToRetry.associated_project_key,
                                })
                                resolve(res_DBU)
                              })
                              .catch((rej_DBU) => {
                                reject(rej_DBU)
                              })
                          })
                          .catch((rej_CCCE) => {
                            reject(rej_CCCE)
                          })
                      } else {
                        let error = {
                          message: s_FAILED_TO_CREATE_RETRY_TASK,
                          details: s_THIS_TASK_HAS_ALREADY_BEEN_PROCESSED_BY_PAYROLL_AND_CANNOT_BE_REOPENED,
                          code: 'ER-D-TF-FSRT-01',
                        }
                        reject({
                          success: false,
                          error: error,
                        })
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                      }
                    })
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: s_FAILED_TO_CREATE_RETRY_TASK,
                        details: s_MISSING_REQUIRED_PARAMETERS,
                        code: 'ER-D-TF-FSRT-02',
                      },
                    })
                  }
                })
                .catch((rej_DGC) => {
                  reject(rej_DGC)
                })
            })
            .catch((rej_DGD) => {
              reject(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_CREATE_RETRY_TASK,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TF-FSRT-02',
        },
      })
    }
  })
}

export const formSettings_RetryTask: TsInterface_FormSettings = {
  // highlight_missing: true,
  // submit_button_alignment: "right",
  // submit_button_hide: false,
  // submit_button_icon: <SaveIcon/>,
  // submit_button_saving_icon: true,
  // submit_button_text: <>{s_SAVE}</>,
}

export const onSiteTaskRedoTasks: TsInterface_UnspecifiedObject = {
  weather: { key: 'weather', value: s_WEATHER },
  customer_requested: { key: 'customer_requested', value: s_CUSTOMER_REQUESTED_NO_SHOW },
  uninstallable_roof: { key: 'uninstallable_roof', value: s_UNINSTALLABLE_ROOF },
  site_inaccessible: { key: 'site_inaccessible', value: s_SITE_INACCESSIBLE },
  additional_unanticipated_work: { key: 'additional_unanticipated_work', value: rLIB('Additional unanticipated work') },
  waiting_for_additional_work: { key: 'waiting_for_additional_work', value: s_WAITING_FOR_ADDITIONAL_WORK },
  design_issues: { key: 'design_issues', value: s_DESIGN_ISSUES },
  material_supply_shortage: { key: 'material_supply_shortage', value: s_MATERIAL_SUPPLY_SHORTAGE },
}

export const formInputs_RetryTask: TsInterface_FormInputs = {
  redo_reason: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'redo_reason',
    label: s_REDO_REASON,
    required: true,
    options: objectToArray(onSiteTaskRedoTasks),
  },
  redo_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'redo_notes',
    label: s_REDO_NOTES,
    required: true,
  },
}
