///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			MAYBE Write cloud function to read only prod data at path and then have them select the data they want to copy to staging
				Feel like I built this utility before somewhere...


			// TODO - get collections on server and return and then pull list of documents in collection


			const admin = require('firebase-admin');

			// Initialize Firebase Admin SDK
			admin.initializeApp({
			credential: admin.credential.applicationDefault(),
			databaseURL: 'YOUR_DATABASE_URL'
			});

			async function getSubcollectionIds(path) {
			try {
				const collections = await admin.firestore().doc(path).listCollections();
				const collectionIds = collections.map(collection => collection.id);
				return collectionIds;
			} catch (error) {
				console.error('Error getting subcollection IDs:', error);
				return [];
			}
			}

			// Example usage:
			const path = 'YOUR_DOCUMENT_PATH'; // Replace this with your document path
			getSubcollectionIds(path)
			.then(subcollectionIds => {
				console.log('Subcollection IDs:', subcollectionIds);
			})
			.catch(error => {
				console.error('Error:', error);
			});




	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Divider, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material/'
import { collection, getFirestore } from 'firebase/firestore'

import React, { useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_RootClient_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Json } from 'rfbp_core/components/code_display'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  generateDatabaseArrayContainsAnyQuery,
} from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { dynamicSort, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SuperTestLabPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

// const moveProjectDataBetweenClients = (
// originClientKey: string,
// originProjectKey: string,
// destinationClientKey: string,
// destinationProjectKey: string,
// ) => {
// // Instantiate Variables
// let promiseArray1: TsType_UnknownPromise[] = []
// let promiseArray2: TsType_UnknownPromise[] = []
// let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
// let contactLogs: TsInterface_UnspecifiedObject | null = null
// let documentsAgreements: TsInterface_UnspecifiedObject | null = null
// let documentsDesign: TsInterface_UnspecifiedObject | null = null
// let documentsPermits: TsInterface_UnspecifiedObject | null = null
// let designPreferences: TsInterface_UnspecifiedObject | null = null
// let projectNotes: TsInterface_UnspecifiedObject | null = null
// let projectNotesSubdata: TsInterface_UnspecifiedObject = {}
// let taskFormData: TsInterface_UnspecifiedObject | null = null
// // Get Data
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectContactLogs_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ contactLogs = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "agreements" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsAgreements = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "design" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsDesign = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "permits" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsPermits = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetDocument( DatabaseRef_ProjectDesignPreferences_Document( originClientKey, originProjectKey )).then((res_DGD) => {
// 	if(res_DGD != null && res_DGD.data != null){ designPreferences = res_DGD.data }
// }).catch((rej_DGD) => {
// 	console.error(rej_DGD)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectNoteThreads_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ projectNotes = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectTaskFormData_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ taskFormData = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// // Initial Data Loaded
// Promise.all( promiseArray1 ).finally(() => {
// 	for( let loopKey in projectNotes ){
// 		promiseArray2.push( DatabaseGetCollection( DatabaseRef_ProjectNoteThreadNotes_Collection( originClientKey, originProjectKey, loopKey )).then((res_DGC) => {
// 			if(res_DGC != null && res_DGC.data != null){ projectNotesSubdata[ loopKey ] = res_DGC.data }
// 		}).catch((rej_DGC) => {
// 			console.error(rej_DGC)
// 		}) )
// 	}
// 	Promise.all( promiseArray2 ).finally(() => {
// 		// Subsequent Data Loaded
// 		if( contactLogs != null ){
// 			for( let loopKey in contactLogs ){
// 				let loopData = contactLogs[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectContactLogs_Document( destinationClientKey, destinationProjectKey, loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsAgreements != null ){
// 			for( let loopKey in documentsAgreements ){
// 				let loopData = documentsAgreements[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "agreements", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsDesign != null ){
// 			for( let loopKey in documentsDesign ){
// 				let loopData = documentsDesign[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "design", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsPermits != null ){
// 			for( let loopKey in documentsPermits ){
// 				let loopData = documentsPermits[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "permits", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( designPreferences != null ){
// 			updateArray.push({
// 				type: "setMerge",
// 				ref: DatabaseRef_ProjectDesignPreferences_Document( destinationClientKey, destinationProjectKey ),
// 				data: designPreferences
// 			})
// 		}
// 		if( taskFormData != null ){
// 			for( let loopKey in taskFormData ){
// 				let loopData = taskFormData[ loopKey ]
// 				// TODO - change key
// 				let newLoopKey = loopKey.replace(originProjectKey, destinationProjectKey)
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectTaskFormData_Document( destinationClientKey, destinationProjectKey, newLoopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( projectNotes != null ){
// 			for( let loopKey in projectNotes ){
// 				let loopData = projectNotes[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectNoteThreads_Document( destinationClientKey, destinationProjectKey, loopKey ),
// 					data: loopData
// 				})
// 				if( projectNotesSubdata != null && projectNotesSubdata[loopKey] != null ){
// 					for( let subLoopKey in projectNotesSubdata[ loopKey ] ){
// 						let subLoopData = projectNotesSubdata[ loopKey ][ subLoopKey ]
// 						updateArray.push({
// 							type: "setMerge",
// 							ref: DatabaseRef_ProjectNoteThreadNotes_Document( destinationClientKey, destinationProjectKey, loopKey, subLoopKey ),
// 							data: subLoopData
// 						})
// 					}
// 				}
// 			}
// 		}
// 		console.log( "<><><><><><><><>" )
// 		console.log( updateArray )
// 		DatabaseBatchUpdate( updateArray ).then((res_DBU) => {
// 			console.log( res_DBU )
// 		}).catch((rej_DBU) => {
// 			console.error( rej_DBU )
// 		})
// 	})
// })
// }

// Database Management Utility
let databaseStructureOptions: TsInterface_UnspecifiedObject = {
  directory: {
    type: 'collection',
    key: 'directory',
    docs: {
      equipment: {
        type: 'doc',

        key: 'equipment',
        collections: {
          groups: {
            type: 'collection',
            key: 'groups',
            dynamic_doc_keys: true,
            docs: {
              $dynamicKey: {
                type: 'doc',
              },
            },
          },
          kits: {
            type: 'collection',
            key: 'kits',
          },
          main: {
            type: 'collection',
            key: 'main',
          },
          manufacturers: {
            type: 'collection',
            key: 'manufacturers',
          },
          types: {
            type: 'collection',
            key: 'types',
          },
        },
      },
      regions: {
        key: 'regions',
      },
      sales_partners: {
        key: 'sales_partners',
      },
      task_blueprints: {
        key: 'task_blueprints',
      },
    },
  },
  finances: {
    type: 'collection',
    key: 'finances',
  },
  integrations: {
    type: 'collection',
    key: 'integrations',
  },
  machine_learning: {
    type: 'collection',
    key: 'machine_learning',
  },
  materials: {
    type: 'collection',
    key: 'materials',
  },
  operations: {
    type: 'collection',
    key: 'operations',
  },
  settings: {
    type: 'collection',
    key: 'settings',
  },
  timesheets: {
    type: 'collection',
    key: 'timesheets',
  },
  webstore: {
    type: 'collection',
    key: 'webstore',
  },
}

// us_panelsInstalledFormattedData
const TEMP_GMDashboardData_PanelsInstalled = {
  summary_rows: [
    [
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: 'Panels Installed',
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 05',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 12',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 19',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 26',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: 'Total',
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '627 / 800',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
        },
        additionalCellData: {
          totalPanels: 627,
          goalPanels: 800,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 1,
          },
        },
      },
      {
        cellValue: '955 / 1100',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
        },
        additionalCellData: {
          totalPanels: 955,
          goalPanels: 1100,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 1,
          },
        },
      },
      {
        cellValue: '478 / 800',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
        },
        additionalCellData: {
          totalPanels: 478,
          goalPanels: 800,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 0.5,
          },
        },
      },
      {
        cellValue: '170 / 200',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
        },
        additionalCellData: {
          totalPanels: 170,
          goalPanels: 200,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 0.5,
          },
        },
      },
    ],
  ],
  data_rows: [
    [
      {
        cellValue: 'Dream Team (712)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 63,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 20,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Illuminatis',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 102,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 77,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 39,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Slay That Pitch',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 24,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Solar Bears',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 73,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 80,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 10,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Solar Energy',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 79,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 94,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 74,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Solar Expanse',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 81,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 90,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 43,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Star Troopers (712)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 161,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 60,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Sun Seekers (Peoria Local)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 108,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 45,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 97,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'The Alphas (711)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 71,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 76,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Watt Wizards',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 54,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 122,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 83,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Wolf Pack',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 59,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 123,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 91,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 131,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
    ],
  ],
}

// us_formattedCrewHours
const TEMP_GMDashboardData_CrewHours = {
  summary_rows: [
    [
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: 'Crew Hours',
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '200px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 05',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 12',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 19',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '120px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 26',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Total',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-88.54',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '#28a56c',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: '198.59',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '#c82424',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '',
          opacity: 1,
          width: '120px',
        },
      },
    ],
  ],
  data_rows: [
    [
      {
        cellValue: 'Abdiel Mata',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '10.46',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '14.91',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Aby Cabrera',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '7.03',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '8.59',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Adam Toddington',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '8.74',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Anderson Hohn',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '5.10',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-6.84',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Antonio Arciga',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '8.16',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-1.64',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Antonio Carpenterio',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Austin Johnson',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '5.53',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Ben Woodward',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-30.01',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Bryce Jones',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '5.42',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-15.61',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Carlos Castaneda',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Carlos Castro',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '6.06',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '6.57',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Damyan Melton',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-18.26',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '26.57',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Daniel Garcia Jr',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Edgar Porras',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Felix Medina',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-24.67',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-13.64',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Francisco Gomez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '16.24',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '20.68',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Frederick Burks',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '4.13',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '14.78',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gabriel Aguirre',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-14.79',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.19',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gabriel Gonzales',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-10.63',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.48',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gabriel Halcon',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '17.54',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'George Blanco II',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-25.38',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-13.42',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gerardo Castillo',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '12.78',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '5.35',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gerardo Soriano',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-19.87',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '14.14',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gilbert Rodriguez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Haroll Gonzalez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jeremy Gonzales',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-7.71',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.72',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jon-Angel Cantu',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '2.05',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '9.07',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jonathan Martinez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jose Cabrera',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '11.11',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '15.06',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jose Murillo',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Joshua Jenkins',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '7.23',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.70',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Juan Santos',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-21.62',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-9.97',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Kelan Williams',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '45.44',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Landan Crowder',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '2.87',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-19.05',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Manuel Vallejo',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '18.00',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '4.82',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Marquis Washington',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-11.24',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '7.72',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'No Sit Inspection',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Phillip Rodriguez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-2.51',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '13.15',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Pierre Brim',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '27.29',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Samuel Alvarado',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '8.03',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '12.93',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Santiago Alaniz',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-21.89',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Sergio Cuevas',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '7.28',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '6.50',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Ulysses Davis',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-17.44',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '0.99',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'null',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
  ],
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clientsList, us_setClientsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_destinationClientKey, us_setDestinationClientKey] = useState<string>('')
  const [us_doc10, us_setDoc10] = useState<string>('')
  const [us_doc2, us_setDoc2] = useState<string>('')
  const [us_doc4, us_setDoc4] = useState<string>('')
  const [us_doc6, us_setDoc6] = useState<string>('')
  const [us_doc8, us_setDoc8] = useState<string>('')
  const [us_originClientKey, us_setOriginClientKey] = useState<string>('')
  const [us_originDatabase, us_setOriginDatabase] = useState<string>('staging')
  const [us_sub1, us_setSub1] = useState<string>('')
  const [us_sub3, us_setSub3] = useState<string>('')
  const [us_sub5, us_setSub5] = useState<string>('')
  const [us_sub7, us_setSub7] = useState<string>('')
  const [us_sub9, us_setSub9] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Test Lab', false) as string
  }, [])

  useEffect(() => {
    // moveProjectFieldFromSubcollectionToMainDocument( "jmorrell1", "0", 500 )
    // moveProjectFieldFromSubcollectionToMainDocument( "etw_energy", "Z377841510", 500 )
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientsList(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_RootClient_Collection(), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender])

  // Variables

  // Functions

  // JSX Generation
  const rJSX_OriginDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setOriginDatabase(event.target.value)
            }
          }}
          value={us_originDatabase}
        >
          <MenuItem value={'staging'}>{rLIB('Staging')}</MenuItem>
          <MenuItem value={'prod'}>{rLIB('Production')}</MenuItem>
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_OriginClientDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setOriginClientKey(event.target.value)
            }
          }}
          value={us_originClientKey || ''}
        >
          {objectToArray(us_clientsList)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['key']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DestinationClientDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setDestinationClientKey(event.target.value)
            }
          }}
          value={us_destinationClientKey || ''}
        >
          {objectToArray(us_clientsList)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['key']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DropdownS1 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      us_setSub1(value)
      us_setDoc2('')
      us_setSub3('')
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '') {
      // Dropdown Options
      let dropdownOptions: TsInterface_UnspecifiedObject = {}
      if (databaseStructureOptions != null) {
        dropdownOptions = databaseStructureOptions
      }
      // Dropdown JSX
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateState(event.target.value)
              }
            }}
            value={us_sub1 || ''}
          >
            {objectToArray(dropdownOptions)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['key']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_DropdownD2 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      us_setDoc2(value)
      us_setSub3('')
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '') {
      if (databaseStructureOptions != null && databaseStructureOptions[us_sub1] != null && databaseStructureOptions[us_sub1]['dynamic_doc_keys'] === true) {
        dropdownJSX = (
          <FormControl
            className="bp_thin_text_input"
            sx={{ width: '130px', marginTop: '0px !important', marginBottom: '0px !important' }}
          >
            <TextField
              className="bp_thin_text_input"
              value={us_doc2 || ''}
              margin="normal"
              type="text"
              sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
              onChange={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              onBlur={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )
      } else {
        // Dropdown Options
        let dropdownOptions: TsInterface_UnspecifiedObject = {}
        if (databaseStructureOptions != null && databaseStructureOptions[us_sub1] != null && databaseStructureOptions[us_sub1]['docs'] != null) {
          dropdownOptions = databaseStructureOptions[us_sub1]['docs']
        }
        // Dropdown JSX
        dropdownJSX = (
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
          >
            <Select
              onChange={(event, value) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              value={us_doc2 || ''}
            >
              {objectToArray(dropdownOptions)
                .sort(dynamicSort('key', null))
                .map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={index}
                    value={option['key']}
                  >
                    {option['key']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )
      }
    }
    return dropdownJSX
  }

  const rJSX_DropdownS3 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      // us_setDoc2( value )
      us_setSub3(value)
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '' && us_doc2 !== '') {
      // Dropdown Options
      let dropdownOptions: TsInterface_UnspecifiedObject = {}
      if (
        databaseStructureOptions != null &&
        databaseStructureOptions[us_sub1] != null &&
        databaseStructureOptions[us_sub1]['docs'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null
      ) {
        dropdownOptions = databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections']
        // } else if(
        // 	databaseStructureOptions != null &&
        // 	databaseStructureOptions[ us_sub1 ] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"] != null
        // ){
        // 	dropdownOptions = databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"]
      }
      // Dropdown JSX
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateState(event.target.value)
              }
            }}
            value={us_sub3 || ''}
          >
            {objectToArray(dropdownOptions)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['key']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_DropdownD4 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      // us_setDoc2( value )
      // us_setSub3( value )
      us_setDoc4(value)
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '' && us_doc2 !== '' && us_sub3 !== '') {
      if (
        databaseStructureOptions != null &&
        databaseStructureOptions[us_sub1] != null &&
        databaseStructureOptions[us_sub1]['docs'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['dynamic_doc_keys'] === true
      ) {
        dropdownJSX = (
          <FormControl
            className="bp_thin_text_input"
            sx={{ width: '130px', marginTop: '0px !important', marginBottom: '0px !important' }}
          >
            <TextField
              className="bp_thin_text_input"
              value={us_doc4 || ''}
              margin="normal"
              type="text"
              sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
              onChange={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              onBlur={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )
      } else {
        // Dropdown Options
        let dropdownOptions: TsInterface_UnspecifiedObject = {}
        if (
          databaseStructureOptions != null &&
          databaseStructureOptions[us_sub1] != null &&
          databaseStructureOptions[us_sub1]['docs'] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['docs'] != null
        ) {
          dropdownOptions = databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['docs']
          // } else if(
          // 	databaseStructureOptions != null &&
          // 	databaseStructureOptions[ us_sub1 ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"][ us_sub3 ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"][ us_sub3 ]["docs"] != null
          // ){
          // 	dropdownOptions = databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"]
        }
        // D

        // Dropdown JSX
        dropdownJSX = (
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
          >
            <Select
              onChange={(event, value) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              value={us_doc4 || ''}
            >
              {objectToArray(dropdownOptions)
                .sort(dynamicSort('key', null))
                .map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={index}
                    value={option['key']}
                  >
                    {option['key']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )
      }
    }
    return dropdownJSX
  }

  const rJSX_DropdownS5 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD6 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownS7 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD8 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownS9 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD10 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  // const rJSX_ClientKeyDropdown = (): JSX.Element => {
  // let dropdownJSX =
  // <FormControl className="bp_thin_select_input bp_thin_select_multiple_input" sx={{ minWidth: "130px", marginRight: "12px", marginBottom: "8px" }}>
  // 	<Select
  // 		onChange={ ( event, value ) => {

  // 		} }
  // 		value={ conditionLine.variable_2 || "" }
  // 	>
  // 		{ objectToArray( calculationData.variables ).sort( dynamicSort( "name", null )).map(( option: TsInterface_UnspecifiedObject ) => (
  // 			<MenuItem key={ option["key"] } value={ option["key"] } disabled={ option["status"] === "inactive" }>
  // 				{ option["name"] }{ variableTrace2 }
  // 			</MenuItem>
  // 		)) }
  // 	</Select>
  // </FormControl>
  // return dropdownJSX
  // }

  // Templates for different Task Keys
  const generateInstallTemplate = (customerName: string, taskName: string, scheduledDateTime: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>Install</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateSiteAuditTemplate = (customerName: string, taskName: string, scheduledDateTime: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>Site Audit</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateElectricalTemplate = (customerName: string, taskName: string, scheduledDateTime: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>Electrical</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateRepairTemplate = (customerName: string, taskName: string, scheduledDateTime: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>Repair</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateDefaultTemplate = (customerName: string, taskName: string, scheduledDateTime: string, taskGroupKey: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>${taskGroupKey}</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateEmailTemplate = (customerName: string, taskName: string, scheduledDateTime: string, taskGroupKey: string, address: string): string => {
    switch (taskGroupKey) {
      case 'install':
        return generateInstallTemplate(customerName, taskName, scheduledDateTime, address)
      case 'site_audit':
        return generateSiteAuditTemplate(customerName, taskName, scheduledDateTime, address)
      case 'electrical':
        return generateElectricalTemplate(customerName, taskName, scheduledDateTime, address)
      case 'repair':
        return generateRepairTemplate(customerName, taskName, scheduledDateTime, address)
      default:
        return generateDefaultTemplate(customerName, taskName, scheduledDateTime, taskGroupKey, address)
    }
  }

  const DatabaseRef_ScheduledTasksOnSpecificDate_Query = (clientKey: string, dateKey: string) => {
    let dateKeyArray = [dateKey]
    console.log(`Querying tasks for dateKey: ${dateKey}`) // Log the dateKey being queried
    return generateDatabaseArrayContainsAnyQuery(
      collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'),
      'task_completion_scheduled_dates',
      dateKeyArray,
    )
  }

  function getTomorrowDate(dateStr: string): string {
    const [year, month, day] = dateStr.split('-').map(Number)
    const formatDate = (date: Date): string => {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    const tomorrow = new Date(year, month - 1, day + 1)
    const tomorrowStr = formatDate(tomorrow)

    console.log(`Tomorrow's date is: ${tomorrowStr}`) // Log the calculated tomorrow's date
    return tomorrowStr
  }

  const getTaskStartTime = (scheduledDate: string, startTimesMap: { [key: string]: string }): string => {
    // Check if the date key exists in the task's start times map and return the time
    return startTimesMap[scheduledDate] || 'Time not available' // Return a fallback if no time is available
  }

  const getTasksForTomorrow = async (clientKey: string, dateKey: string) => {
    return new Promise((resolve, reject) => {
      console.log(`Fetching scheduled tasks for date: ${dateKey}`)
      DatabaseGetCollection(DatabaseRef_ScheduledTasksOnSpecificDate_Query(clientKey, dateKey))
        .then((res_DGC) => {
          let scheduledTasks = res_DGC.data
          console.log('Raw scheduled tasks:', scheduledTasks)

          if (!scheduledTasks || Object.keys(scheduledTasks).length === 0) {
            console.warn('No tasks found for the given date.') // Warn if no tasks are found
          }

          let promiseArray: TsType_UnknownPromise[] = []

          // Loop through tasks and fetch project details if needed
          for (let taskKey in scheduledTasks) {
            let task = scheduledTasks[taskKey]
            console.log(`Processing task with key: ${taskKey}`) // Log each task being processed

            if (task.associated_project_key != null) {
              console.log(`Fetching project data for task with project key: ${task.associated_project_key}`)
              promiseArray.push(
                DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, task.associated_project_key))
                  .then((res_DGD) => {
                    scheduledTasks[taskKey].TEMP_project = res_DGD.data
                    console.log(`Fetched project data for task ${taskKey}:`, res_DGD.data)
                  })
                  .catch((rej_DGD) => {
                    console.error(`Error fetching project details for task ${taskKey}:`, rej_DGD)
                  }),
              )
            } else {
              scheduledTasks[taskKey].TEMP_project = null
              console.log(`No project associated with task ${taskKey}`)
            }
          }

          // Resolve after all project data has been fetched
          Promise.all(promiseArray)
            .then(() => {
              console.log('Final scheduled tasks with project data:', scheduledTasks)
              resolve(scheduledTasks)
            })
            .catch((error) => {
              console.error('Error fetching project data for tasks:', error)
              reject(error)
            })
        })
        .catch((error) => {
          console.error('Error fetching scheduled tasks:', error)
          reject(error)
        })
    })
  }

  const rJSX_TaskReminderEmailTab = (): JSX.Element => {
    const triggerTaskReminderEmail = () => {
      console.log('Triggering sendTaskReminderEmail from the server...')

      // Call the manageEmails function, which triggers the sendTaskReminderEmail function on the server
      cloudFunctionManageRequest('manageEmails', {
        function: 'sendTaskReminderEmail', // Specify the function to trigger
        client_key: 'etw_energy', // Required client key for fetching tasks
        email_to: ['tyler.colson@etwenergy.com'], // Email recipient for testing
      })
        .then((res) => {
          console.log('Task reminder email triggered successfully:', res)
        })
        .catch((error) => {
          console.error('Error triggering task reminder email:', error)
        })
    }

    return (
      <Box>
        <Button
          onClick={() => {
            triggerTaskReminderEmail()
          }}
        >
          SEND TASK REMINDER EMAIL
        </Button>
      </Box>
    )
  }

  const rJSX_DatabaseManagementTab = (): JSX.Element => {
    return (
      <Box>
        <Card className="tw-p-4">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            {rLIB('Database Management Utility')}
          </Typography>
          <Typography
            variant="body1"
            className="tw-opacity-40 tw-mt-4"
          ></Typography>
          <Divider className="tw-my-2" />
          <Box className="tw-ml-4">
            <Typography
              variant="body1"
              className="tw-mb-2 tw-opacity-40"
            >
              {rLIB('Copy From')}:
            </Typography>
            {rJSX_OriginDropdown()}
            {rJSX_OriginClientDropdown()}

            {rJSX_DropdownS1()}
            {rJSX_DropdownD2()}
            {rJSX_DropdownS3()}
            {rJSX_DropdownD4()}
            {rJSX_DropdownS5()}
            {rJSX_DropdownD6()}
            {rJSX_DropdownS7()}
            {rJSX_DropdownD8()}
            {rJSX_DropdownS9()}
            {rJSX_DropdownD10()}
          </Box>
          <Box className="tw-ml-4">
            <Typography
              variant="body1"
              className="tw-mb-2 tw-opacity-40"
            >
              {rLIB('Copy To')}:
            </Typography>
            {rJSX_DestinationClientDropdown()}
          </Box>
        </Card>
        <Json
          data={{
            us_originDatabase: us_originDatabase,
            us_originClientKey: us_originClientKey,
            us_destinationClientKey: us_destinationClientKey,
            us_sub1: us_sub1,
            us_doc2: us_doc2,
            us_sub3: us_sub3,
            us_doc4: us_doc4,
            us_sub5: us_sub5,
            us_doc6: us_doc6,
            us_sub7: us_sub7,
            us_doc8: us_doc8,
            us_sub9: us_sub9,
            us_doc10: us_doc10,
          }}
        />
      </Box>
    )
  }

  const rJSX_EmailTab = (): JSX.Element => {
    let templateObject: TsInterface_EmailTemplateObject = {
      BODY_DIV: {
        element_type: 'div',
        style: {
          'box-sizing': 'border-box',
          'width': '100%',
          'background': '#eeeeee',
          'padding': '16px',
        },
        contents: {
          CARD_DIV: {
            element_type: 'div',
            style: {
              'max-width': '600px',
              'background': '#ffffff',
              'border-radius': '8px',
              'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
              'margin': '0 auto',
              'overflow': 'hidden',
            },
            contents: {
              HEADER_DIV: {
                element_type: 'div',
                style: {
                  'box-sizing': 'border-box',
                  'background': 'rgb(25, 30, 33)',
                  'font-size': '20px',
                  'width': '100%',
                  'min-height': '70px',
                  'padding': '8px',
                  'text-align': 'center',
                },
                contents: {
                  LOGO_IMG: {
                    element_type: 'img',
                    src_type: 'static',
                    src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                    alt: 'logo.png',
                    width: '80px',
                    height: 'auto',
                  },
                },
              },
              CONTENT_DIV: {
                element_type: 'div',
                style: {
                  padding: '16px',
                },
                contents: {
                  INVOICE1: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Invoice from ETW Energy LLC',
                    style: {
                      'font-size': '16px',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  GREETING: {
                    element_type: 'p',
                    text_type: 'dynamic',
                    data_object_key: 'invoice',
                    data_object_prop_key: 'total',
                    style: {
                      'font-size': '36px',
                      'font-weight': 'bold',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                    // prefix_text: 'Hi ',
                    // suffix_text: ',',
                  },
                  INVOICE2: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'See the attached invoice for full details.',
                    style: {
                      'font-size': '16px',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  INVOICE3: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'If you have any questions, please contact us at support@etwenergy.com.',
                    style: {
                      'font-size': '16px',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
    let dataObjects: TsInterface_UnspecifiedObject = {
      invoice: {
        total: '$100.00',
      },
    }

    return (
      <Box>
        <Box
          className="ql-scroll-html-render"
          dangerouslySetInnerHTML={{ __html: generateHtmlForEmailFromTemplateObject(templateObject, dataObjects) }}
        />

        <Button
          onClick={() => {
            const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, dataObjects)

            cloudFunctionManageRequest('manageEmails', {
              function: 'sendSendgridHtmlEmail',
              to: ['john.morrell@etwenergy.com'], // Email sent only to you for testing
              subject: 'Test Email',
              html: htmlContent,
              cc: [],
              bcc: [],
            })
              .then((res) => {
                console.log('Email sent successfully:', res)
              })
              .catch((rej) => {
                console.error('Error sending email:', rej)
              })
          }}
        >
          SEND TASK REMINDER EMAIL
        </Button>
      </Box>
    )
  }

  const rJSX_TestLeaderboardData = (): JSX.Element => {
    return (
      <Button
        onClick={() => {
          cloudFunctionManageRequest('manageData', {
            // function: 'manually_calculate_leaderboard_data',
            function: 'test_leaderboard_data_query',
            client_key: 'etw_energy',
          })
            .then((res) => {
              console.log('Leaderboard data query:', res)
            })
            .catch((rej) => {
              console.error('Error querying leaderboard data:', rej)
            })
        }}
      >
        TEST LEADERBOARD DATA
      </Button>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Test Lab')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'email',
                  tabHeader: 'HTML Email',
                  tabButtons: [],
                  tabContent: rJSX_EmailTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'tyler_email',
                  tabHeader: 'Tyler Task Reminder Testing',
                  tabButtons: [],
                  tabContent: rJSX_TaskReminderEmailTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'database_management',
                  tabHeader: 'Database Management',
                  tabButtons: [],
                  tabContent: rJSX_DatabaseManagementTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'leaderboard_data',
                  tabHeader: 'Leaderboard Data',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_TestLeaderboardData()}</Box>,
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.SuperTestLabPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Test Lab', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/*
		DB Origin (prod or staging)
		DB Origin path length
		DB Destination (only staging) - select client
	*/
