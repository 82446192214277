///////////////////////////////
// Description
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_ProjectAdditionalWorkInvoice_Document,
  DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document,
  DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Query,
} from 'rfbp_aux/services/database_endpoints/finances/project_additional_work_invoices'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, formatCurrency, generateRandomString, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { downloadAdditionalWorkQuotePDF } from '../services/invoice_pdf_templates'

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_AdditionalWorkInvoiceViewDialog {
  clientKey: string
  invoiceKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

const formInputs_AdditionalWorkInvoiceLineItem: TsInterface_FormInputs = {
  description: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'description',
    label: rLIB('Description'),
    required: true,
  },
  amount: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'amount',
    label: rLIB('Amount'),
    required: true,
  },
  notes: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'notes',
    label: rLIB('Notes'),
    required: false,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const AdditionalWorkInvoiceViewDialog: React.FC<TsInterface_AdditionalWorkInvoiceViewDialog> = ({ clientKey, invoiceKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_downloadingPDF, us_setDownloadingPDF] = useState<boolean>(false)
  const [us_project, us_setProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalWorkInvoice, us_setAdditionalWorkInvoice] = useState<TsInterface_UnspecifiedObject>({})
  const [us_invoiceLogs, us_setInvoiceLogs] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAdditionalWorkInvoice(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, clientKey, invoiceKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setInvoiceLogs(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Query(clientKey, invoiceKey), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, clientKey, invoiceKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProject(newData)
      ur_forceRerender()
    }
    if (us_additionalWorkInvoice['associated_project_key'] != null) {
      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(clientKey, us_additionalWorkInvoice['associated_project_key']), updateLiveData)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, clientKey, us_additionalWorkInvoice])

  // Functions
  const createLineItem = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_AdditionalWorkInvoiceLineItem,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {
            // Nothing
          },
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let newLineItem = {
                description: getProp(formSubmittedData, 'description', null),
                amount: getProp(formSubmittedData, 'amount', null),
                notes: getProp(formSubmittedData, 'notes', null),
                job_code: us_project['id_number'],
              }
              let newTotalPrice = us_additionalWorkInvoice['total_price'] + parseFloat(formSubmittedData['amount'])
              let newLogKey = new Date().getTime().toString() + '_' + generateRandomString(8, null)
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                {
                  type: 'setMerge',
                  ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
                  data: {
                    line_items: [...us_additionalWorkInvoice['line_items'], newLineItem],
                    total_price: newTotalPrice,
                  },
                },
                {
                  type: 'setMerge',
                  ref: DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document(clientKey, invoiceKey, newLogKey),
                  data: {
                    timestamp_created: new Date(),
                    action: 'Line Item Added -' + newLineItem['description'] + ' (' + formatCurrency(newLineItem['amount']) + ')',
                    associated_user_key: getProp(uc_RootData_GlobalUser, 'key', null),
                    associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
                  },
                },
              ]
              // Batch Update
              DatabaseBatchUpdate(updateArray)
                .then((res_DBU) => {
                  resolve(res_DBU)
                })
                .catch((rej_DBU) => {
                  reject(rej_DBU)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'info',
          formDialogHeaderText: rLIB('Create Line Item'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const updateLineItem = (lineItemIndex: number): void => {
    // Get the line item
    let lineItem = us_additionalWorkInvoice['line_items'][lineItemIndex]
    let copyOfLineItem = { ...lineItem }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: lineItem,
          formInputs: formInputs_AdditionalWorkInvoiceLineItem,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {
            // Nothing
          },
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updatedLineItem = {
                ...lineItem,
                description: getProp(formSubmittedData, 'description', null),
                amount: getProp(formSubmittedData, 'amount', null),
                notes: getProp(formSubmittedData, 'notes', null),
              }
              let newTotalPrice = 0
              for (let i = 0; i < us_additionalWorkInvoice['line_items'].length; i++) {
                if (i !== lineItemIndex) {
                  newTotalPrice += parseFloat(us_additionalWorkInvoice['line_items'][i]['amount'])
                }
              }
              newTotalPrice += parseFloat(updatedLineItem['amount'])
              let newLogKey = new Date().getTime().toString() + '_' + generateRandomString(8, null)
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                {
                  type: 'setMerge',
                  ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
                  data: {
                    line_items: [
                      ...us_additionalWorkInvoice['line_items'].map((item: TsInterface_UnspecifiedObject, index: number) =>
                        index === lineItemIndex ? updatedLineItem : item,
                      ),
                    ],
                    total_price: newTotalPrice,
                  },
                },
                {
                  type: 'setMerge',
                  ref: DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document(clientKey, invoiceKey, newLogKey),
                  data: {
                    timestamp_created: new Date(),
                    action:
                      'Line Item Updated - ' +
                      formSubmittedData['description'] +
                      ' (' +
                      formatCurrency(copyOfLineItem['amount']) +
                      ' to ' +
                      formatCurrency(formSubmittedData['amount']) +
                      ')',
                    associated_user_key: getProp(uc_RootData_GlobalUser, 'key', null),
                    associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
                  },
                },
              ]
              // Batch Update
              DatabaseBatchUpdate(updateArray)
                .then((res_DBU) => {
                  resolve(res_DBU)
                })
                .catch((rej_DBU) => {
                  reject(rej_DBU)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'info',
          formDialogHeaderText: rLIB('Edit Line Item'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteLineItem = (lineItemIndex: number): void => {
    // Get the line item
    let lineItem = us_additionalWorkInvoice['line_items'][lineItemIndex]
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Line Item'),
        icon: (
          <Icon
            icon="arrow-rotate-left"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure you want to delete this line item?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            // Remove line item at index
            let updatedLineItems = us_additionalWorkInvoice['line_items'].filter(
              (item: TsInterface_UnspecifiedObject, index: number) => index !== lineItemIndex,
            )
            let newTotalPrice = 0
            updatedLineItems.forEach((item: TsInterface_UnspecifiedObject) => {
              newTotalPrice += parseFloat(item['amount'])
            })
            let newLogKey = new Date().getTime().toString() + '_' + generateRandomString(8, null)
            let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
              {
                type: 'setMerge',
                ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
                data: {
                  line_items: updatedLineItems,
                  total_price: newTotalPrice,
                },
              },
              {
                type: 'setMerge',
                ref: DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document(clientKey, invoiceKey, newLogKey),
                data: {
                  timestamp_created: new Date(),
                  action: 'Line Item Deleted - ' + lineItem['description'] + ' (' + formatCurrency(lineItem['amount']) + ')',
                  associated_user_key: getProp(uc_RootData_GlobalUser, 'key', null),
                  associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
                },
              },
            ]
            // Batch Update
            DatabaseBatchUpdate(updateArray)
              .then((res_DBU) => {
                resolve(res_DBU)
              })
              .catch((rej_DBU) => {
                reject(rej_DBU)
              })
          })
        },
      },
    })
  }

  const moveLineItemUp = (lineItemIndex: number): void => {
    // Swap line item with the one above it
    let tempLineItem = us_additionalWorkInvoice['line_items'][lineItemIndex]
    us_additionalWorkInvoice['line_items'][lineItemIndex] = us_additionalWorkInvoice['line_items'][lineItemIndex - 1]
    us_additionalWorkInvoice['line_items'][lineItemIndex - 1] = tempLineItem
    // Update Database
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
      {
        type: 'setMerge',
        ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
        data: us_additionalWorkInvoice,
      },
    ]
    DatabaseBatchUpdate(updateArray)
  }

  const moveLineItemDown = (lineItemIndex: number): void => {
    // Swap line item with the one below it
    let tempLineItem = us_additionalWorkInvoice['line_items'][lineItemIndex]
    us_additionalWorkInvoice['line_items'][lineItemIndex] = us_additionalWorkInvoice['line_items'][lineItemIndex + 1]
    us_additionalWorkInvoice['line_items'][lineItemIndex + 1] = tempLineItem
    // Update Database
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
      {
        type: 'setMerge',
        ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
        data: us_additionalWorkInvoice,
      },
    ]
    DatabaseBatchUpdate(updateArray)
  }

  const editInvoiceBillableReferenceNumber = (): void => {
    let copyOfInvoice = { ...us_additionalWorkInvoice }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: copyOfInvoice,
          formInputs: {
            invoice_id_number: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'invoice_id_number',
              label: rLIB('Billable Reference Number'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {
            // Nothing
          },
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let newLogKey = new Date().getTime().toString() + '_' + generateRandomString(8, null)
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                {
                  type: 'setMerge',
                  ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
                  data: {
                    invoice_id_number: formSubmittedData['invoice_id_number'],
                  },
                },
                {
                  type: 'setMerge',
                  ref: DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document(clientKey, invoiceKey, newLogKey),
                  data: {
                    timestamp_created: new Date(),
                    action: 'Billable Reference Number Updated from ' + copyOfInvoice['invoice_id_number'] + ' to ' + formSubmittedData['invoice_id_number'],
                    associated_user_key: getProp(uc_RootData_GlobalUser, 'key', null),
                    associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
                  },
                },
              ]
              // Batch Update
              DatabaseBatchUpdate(updateArray)
                .then((res_DBU) => {
                  resolve(res_DBU)
                })
                .catch((rej_DBU) => {
                  reject(rej_DBU)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'info',
          formDialogHeaderText: rLIB('Create Line Item'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_FinanceLineItem = (
    us_rootProject: TsInterface_UnspecifiedObject,
    icon: string,
    label: string | JSX.Element,
    color: string,
    propKey: string,
  ): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = <Typography variant="body1">{propValue}</Typography>
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_EditableFinanceLineItem = (
    us_rootProject: TsInterface_UnspecifiedObject,
    icon: string,
    label: string | JSX.Element,
    color: string,
    propKey: string,
  ): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = <Typography variant="body1">{propValue}</Typography>
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
          <Icon
            icon="pen-to-square"
            className="tw-cursor-pointer tw-ml-2 hover:tw-opacity-100 tw-opacity-50 hover:tw-text-success_main"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              if (propKey === 'invoice_id_number') {
                editInvoiceBillableReferenceNumber()
              }
            }}
          />
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_FinanceTimestampLineItem = (
    us_rootProject: TsInterface_UnspecifiedObject,
    icon: string,
    label: string | JSX.Element,
    color: string,
    propKey: string,
  ): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = <Typography variant="body1">{returnFormattedDate(propValue, 'MM/DD/YYYY h:mm a')}</Typography>
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_CustomFinanceLineItem = (
    us_rootProject: TsInterface_UnspecifiedObject,
    icon: string,
    label: string | JSX.Element,
    color: string,
    propKey: string,
    rJSX_customFormatFunction: (propValue: any) => JSX.Element,
  ): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = rJSX_customFormatFunction(propValue)
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_TaskStatusIcon = (taskStatus: string): JSX.Element => {
    let iconJSX = <></>
    switch (taskStatus) {
      case 'not_created':
        iconJSX = (
          <Icon
            icon="circle-pause"
            className="tw-mr-2"
            tooltip={rLIB('Task Not Created')}
            tooltipPlacement="left"
            sx={{ color: themeVariables.warning_main }}
          />
        )
        break
      case 'active':
        iconJSX = (
          <Icon
            icon="circle-play"
            className="tw-mr-2"
            tooltip={rLIB('Task Active')}
            tooltipPlacement="left"
            sx={{ color: themeVariables.info_main }}
          />
        )
        break
      case 'completed':
        iconJSX = (
          <Icon
            icon="circle-check"
            className="tw-mr-2"
            tooltip={rLIB('Task Completed')}
            tooltipPlacement="left"
            sx={{ color: themeVariables.success_main }}
          />
        )
        break
      case 'deleted':
        iconJSX = (
          <Icon
            icon="circle-trash"
            className="tw-mr-2"
            tooltip={rLIB('Task Deleted')}
            tooltipPlacement="left"
            sx={{ color: themeVariables.error_main }}
          />
        )
        break
      default:
        iconJSX = (
          <Icon
            icon="circle-question"
            className="tw-mr-2"
            tooltip={rLIB('Unknown Status')}
            tooltipPlacement="left"
            sx={{ color: themeVariables.gray_500 }}
          />
        )
        break
    }
    return iconJSX
  }

  const rJSX_ApprovalEvidenceFiles = (): JSX.Element => {
    let evidenceFilesJSX = <></>
    if (objectToArray(getProp(us_additionalWorkInvoice, 'approval_evidence_files', {})).length > 0) {
      evidenceFilesJSX = (
        <Box className="tw-mb-1">
          <Divider className="tw-my-2" />
          <Typography variant="body1">{rLIB('Approval Documentation')}:</Typography>
          {objectToArray(getProp(us_additionalWorkInvoice, 'approval_evidence_files', {})).map((loopFile: TsInterface_UnspecifiedObject, index: number) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              spacing={1}
              className="tw-ml-4"
            >
              <Typography variant="body1">{loopFile.name}</Typography>
              <Icon
                icon="cloud-arrow-down"
                className="tw-cursor-pointer tw-ml-2 hover:tw-opacity-100 tw-opacity-50"
                tooltip={rLIB('Download')}
                tooltipPlacement="right"
                onClick={() => {
                  // Download URL
                  window.open(loopFile.url, '_blank')
                }}
              />
            </Stack>
          ))}
        </Box>
      )
    }

    return evidenceFilesJSX
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    return (
      <Box className="tw-p-4">
        {rJSX_EditableFinanceLineItem(
          us_additionalWorkInvoice,
          'rectangle-barcode',
          rLIB('Billable Reference Number'),
          themeVariables.primary_main,
          'invoice_id_number',
        )}
        {rJSX_FinanceLineItem(us_additionalWorkInvoice, 'list', rLIB('Invoice Status'), themeVariables.primary_main, 'status')}
        {rJSX_FinanceLineItem(us_additionalWorkInvoice, 'clipboard-list', rLIB('Project Job Code'), themeVariables.primary_main, 'associated_project_job_code')}
        {rJSX_CustomFinanceLineItem(
          us_additionalWorkInvoice,
          'square-dollar',
          rLIB('Invoice Amount'),
          themeVariables.primary_main,
          'total_price',
          (propValue: string) => {
            return <Typography variant="body1">{formatCurrency(parseFloat(propValue))}</Typography>
          },
        )}
        <Divider className="tw-my-2" />
        <Typography
          variant="body1"
          className="tw-mb-1 tw-font-bold"
        >
          {rLIB('Tasks Associated with Invoice')}
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {objectToArray(getProp(us_additionalWorkInvoice, 'associated_tasks', {})).map((loopTask: TsInterface_UnspecifiedObject, index: number) => (
                <TableRow key={index}>
                  <TableCell sx={{ paddingLeft: '0px', borderBottom: '0px solid rgba(0,0,0,0)', paddingTop: '0px' }}>
                    <Typography variant="body1">
                      {rJSX_TaskStatusIcon(loopTask.status)}
                      {loopTask.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider className="tw-my-2" />
        {rJSX_FinanceTimestampLineItem(us_additionalWorkInvoice, 'clock', rLIB('Invoice Created'), themeVariables.primary_main, 'timestamp_created')}
        {/* {rJSX_FinanceTimestampLineItem(us_additionalWorkInvoice, 'clock', rLIB('Task Completed'), themeVariables.primary_main, 'timestamp_task_completed')} */}
        {rJSX_FinanceTimestampLineItem(us_additionalWorkInvoice, 'clock', rLIB('Invoice Billed'), themeVariables.primary_main, 'timestamp_invoice_billed')}
        {rJSX_FinanceTimestampLineItem(us_additionalWorkInvoice, 'clock', rLIB('Invoice Paid'), themeVariables.primary_main, 'timestamp_invoice_paid')}
        {rJSX_ApprovalEvidenceFiles()}
      </Box>
    )
  }

  const rJSX_NewLineItemButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="circle-plus" />}
        size="small"
        disabled={getProp(us_additionalWorkInvoice, 'status', null) === 'billed' || getProp(us_additionalWorkInvoice, 'status', null) === 'paid'}
        onClick={() => {
          createLineItem()
        }}
      >
        {rLIB('Add Line Item')}
      </Button>
    )
  }

  const rJSX_EditActionsCell = (index: number): JSX.Element => {
    if (getProp(us_additionalWorkInvoice, 'status', null) === 'billed' || getProp(us_additionalWorkInvoice, 'status', null) === 'paid') {
      return <TableCell className="tw-px-1 tw-text-right"></TableCell>
    } else {
      return (
        <TableCell className="tw-px-1 tw-text-right">
          {index > 0 ? (
            <Icon
              icon="up"
              className="tw-cursor-pointer hover:tw-text-info_main tw-mr-2 tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Move Line Item Up')}
              tooltipPlacement="right"
              onClick={() => {
                moveLineItemUp(index)
              }}
            />
          ) : (
            <></>
          )}
          {index < objectToArray(getProp(us_additionalWorkInvoice, 'line_items', {})).length - 1 ? (
            <Icon
              icon="down"
              className="tw-cursor-pointer hover:tw-text-info_main tw-mr-2 tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Move Line Item Down')}
              tooltipPlacement="right"
              onClick={() => {
                moveLineItemDown(index)
              }}
            />
          ) : (
            <></>
          )}
          <Icon
            icon="pen-to-square"
            className="tw-cursor-pointer hover:tw-text-success_main tw-mr-2 tw-opacity-30 hover:tw-opacity-100"
            tooltip={rLIB('Edit Line Item')}
            tooltipPlacement="right"
            onClick={() => {
              updateLineItem(index)
            }}
          />
          <Icon
            icon="trash"
            className="tw-cursor-pointer hover:tw-text-error_main tw-mr-2 tw-opacity-30 hover:tw-opacity-100"
            tooltip={rLIB('Delete Line Item')}
            tooltipPlacement="right"
            onClick={() => {
              deleteLineItem(index)
            }}
          />
        </TableCell>
      )
    }
  }

  const rJSX_LineItemsTab = (): JSX.Element => {
    return (
      <Box className="tw-px-4">
        <TableContainer sx={{ border: `1px solid ${themeVariables.gray_700}` }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell
                  className="tw-px-1 tw-font-bold"
                  sx={{ background: themeVariables.background_hover }}
                >
                  {rLIB('Job Code')}
                </TableCell>
                <TableCell
                  className="tw-px-1 tw-font-bold"
                  sx={{ background: themeVariables.background_hover }}
                >
                  {rLIB('Description')}
                </TableCell>
                <TableCell
                  className="tw-px-1 tw-font-bold"
                  sx={{ background: themeVariables.background_hover }}
                >
                  {rLIB('Amount')}
                </TableCell>
                <TableCell
                  className="tw-px-1 tw-font-bold"
                  sx={{ background: themeVariables.background_hover }}
                >
                  {rLIB('Notes')}
                </TableCell>
                <TableCell
                  className="tw-px-1 tw-font-bold"
                  sx={{ background: themeVariables.background_hover }}
                ></TableCell>
              </TableRow>
              {objectToArray(getProp(us_additionalWorkInvoice, 'line_items', {}))
                .sort(dynamicSort('name', null))
                .map((lineItem: TsInterface_UnspecifiedObject, index: number) => (
                  <TableRow key={index}>
                    <TableCell className="tw-px-1">{lineItem.job_code}</TableCell>
                    <TableCell className="tw-px-1">{lineItem.description}</TableCell>
                    <TableCell className="tw-px-1">{formatCurrency(lineItem.amount)}</TableCell>
                    <TableCell className="tw-px-1">{lineItem.notes}</TableCell>
                    {rJSX_EditActionsCell(index)}
                  </TableRow>
                ))}
              <TableRow>
                <TableCell
                  sx={{ background: themeVariables.background_hover }}
                  className="tw-px-1 tw-font-bold"
                ></TableCell>
                <TableCell
                  sx={{ background: themeVariables.background_hover }}
                  className="tw-px-1 tw-font-bold"
                >
                  {rLIB('Total')}:
                </TableCell>
                <TableCell
                  sx={{ background: themeVariables.background_hover }}
                  className="tw-px-1 tw-font-bold"
                >
                  {formatCurrency(getProp(us_additionalWorkInvoice, 'total_price', 0))}
                </TableCell>
                <TableCell
                  sx={{ background: themeVariables.background_hover }}
                  className="tw-px-1 tw-font-bold"
                ></TableCell>
                <TableCell
                  sx={{ background: themeVariables.background_hover }}
                  className="tw-px-1 tw-font-bold"
                ></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="tw-mt-2 tw-mb-4">{rJSX_NewLineItemButton()}</Box>
      </Box>
    )
  }

  const rJSX_LogsTab = (): JSX.Element => {
    return (
      <Box className="tw-px-4 tw-pb-4">
        <TableContainer sx={{ border: `1px solid ${themeVariables.gray_700}` }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell
                  className="tw-px-1 tw-font-bold"
                  sx={{ background: themeVariables.background_hover }}
                >
                  {rLIB('Date')}
                </TableCell>
                <TableCell
                  className="tw-px-1 tw-font-bold"
                  sx={{ background: themeVariables.background_hover }}
                >
                  {rLIB('Action')}
                </TableCell>
                <TableCell
                  className="tw-px-1 tw-font-bold"
                  sx={{ background: themeVariables.background_hover }}
                >
                  {rLIB('User')}
                </TableCell>
              </TableRow>
              {objectToArray(us_invoiceLogs)
                .sort(dynamicSort('name', null))
                .map((log: TsInterface_UnspecifiedObject, index: number) => (
                  <TableRow key={index}>
                    <TableCell className="tw-px-1">{returnFormattedDate(log['timestamp_created'], 'MM/DD/YYYY h:mm a')}</TableCell>
                    <TableCell className="tw-px-1">{log.action}</TableCell>
                    <TableCell className="tw-px-1">{log.associated_user_name}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  const rJSX_DownloadPdfButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="error"
        startIcon={
          us_downloadingPDF ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="file-pdf" />
          )
        }
        size="small"
        disabled={us_downloadingPDF}
        onClick={() => {
          us_setDownloadingPDF(true)
          downloadAdditionalWorkQuotePDF(
            clientKey,
            getProp(us_additionalWorkInvoice, 'associated_project_key', null),
            getProp(us_additionalWorkInvoice, 'bill_to', null),
            getProp(us_additionalWorkInvoice, 'invoice_id_number', null),
            objectToArray(getProp(us_additionalWorkInvoice, 'line_items', {})),
            'download',
          )
            .then((res_DBPP) => {
              us_setDownloadingPDF(false)
            })
            .catch((rej_DBPP) => {
              us_setDownloadingPDF(false)
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: rej_DBPP.error,
              })
            })
        }}
      >
        {rLIB('Download PDF')}
      </Button>
    )
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Dialog
        // TransitionComponent={ Transition }
        className="bp_dialog_xl_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              className="tw-w-full tw-justify-between"
            >
              <Box>
                <IconButton
                  aria-label="menu"
                  color="inherit"
                  disabled
                  edge="start"
                  size="large"
                  sx={{ mr: 1, color: '#fff !important' }}
                >
                  <Icon icon="file-invoice-dollar" />
                </IconButton>
                <Typography
                  component={'span'}
                  variant={'h6'}
                  sx={{ flexGrow: 1 }}
                >
                  <Box className="tw-inline-block">{getProp(us_additionalWorkInvoice, 'invoice_id_number', null)}</Box>
                </Typography>
              </Box>
              <Box>{rJSX_DownloadPdfButton()}</Box>
            </Stack>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <TabsBasic
            tabs={[
              {
                tabHeader: rLIB('Details'),
                tabContent: rJSX_DetailsTab(),
              },
              {
                tabHeader: rLIB('Line Items'),
                tabContent: rJSX_LineItemsTab(),
              },
              {
                tabHeader: rLIB('Logs'),
                tabContent: rJSX_LogsTab(),
              },
            ]}
            tabsSettings={{}}
          />
        </DialogContent>
      </Dialog>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
