import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_CashDisclosureForm, TsInterface_LeaseDisclosureAPIInputs, TsInterface_LeaseDisclosureForm } from '../interfaces/i_sales_tools'

//FIXME: this is a temporary function to map the lease form data keys to the ts interface lease disclosure data keys
export const mapTsInterface_LeaseDisclosureDataKeyToFormDataKey = (key: string): string => {
  const reverseFormKeys: { [key: string]: string } = {
    fee_due_1: 'when_its_due_1',
    fee_due_2: 'when_its_due_2',
    fee_due_3: 'when_its_due_3',
    fee_due_4: 'when_its_due_4',
    fee_due_5: 'when_its_due_5',
    form_name: 'disclosure_form_name',
    vendor_id: 'vendor_id_valid_non_retired',
    fee_name_1: 'fee_name_1',
    fee_name_2: 'fee_name_2',
    fee_name_3: 'fee_name_3',
    fee_name_4: 'fee_name_4',
    fee_name_5: 'fee_name_5',
    is_spanish: 'generate_pdf_in_spanish',
    own_option: 'will_the_lessor_offer_the_customer_the_option_to_own_and_retain_the_system_at_the_conclusion_of_the_term_of_the_system_lease',
    term_years: 'lease_term_years_portion',
    term_months: 'lease_term_months_portion',
    fee_amount_1: 'amount_1',
    fee_amount_2: 'amount_2',
    fee_amount_3: 'amount_3',
    fee_amount_4: 'amount_4',
    fee_amount_5: 'amount_5',
    renew_option: 'will_the_lessor_offer_the_customer_the_option_to_renew_after_the_initial_term_of_the_system_lease',
    customer_name: 'customer_name',
    customer_type: 'project_type',
    fee_payment_1: 'number_of_payments_1',
    fee_payment_2: 'number_of_payments_2',
    fee_payment_3: 'number_of_payments_3',
    fee_payment_4: 'number_of_payments_4',
    fee_payment_5: 'number_of_payments_5',
    rebate_amount: 'rebate_amount',
    remove_system: 'will_the_lessor_remove_the_project_at_the_conclusion_of_the_term_of_the_system_lease',
    repairs_party: 'who_is_responsible_for_repairs',
    repairs_years: 'for_how_many_years_are_project_repairs_included',
    abp_contingent: 'is_the_lease_contingent_upon_selection_for_the_adjustable_block_program_incentive',
    designee_email: 'designee_email',
    muni_coop_name: 'electric_utility_name_if_municipality_or_rural',
    total_payments: 'total_number_of_payments_over_the_term_of_the_lease_contract',
    escalation_rate: 'annual_escalation_rate',
    installer_known: 'is_the_project_installer_known_at_this_time',
    termination_fee: 'amount_of_fee_penalty',
    transfer_option:
      'if_the_home_or_business_where_the_solar_project_is_located_is_sold_can_the_customer_transfer_the_system_lease_obligations_to_the_purchasers_of_the_home_or_business',
    electric_utility: 'customers_electric_utility',
    final_amount_due: 'final_lease_payment_amount',
    installer_name_1: 'installer_legal_name_1',
    installer_name_2: 'installer_legal_name_2',
    installer_name_3: 'installer_legal_name_3',
    other_fee_name_1: 'other_fee_name_1',
    other_fee_name_2: 'other_fee_name_2',
    other_fee_name_3: 'other_fee_name_3',
    other_fee_name_4: 'other_fee_name_4',
    other_fee_name_5: 'other_fee_name_5',
    reference_number: 'av_reference_number',
    repairs_included: 'are_project_repairs_actions_required_to_fix_a_malfunctioning_system_included_as_part_of_the_lease',
    seller_address_1: 'project_lessor_street',
    seller_address_2: 'project_lessor_apartment_or_suite',
    who_keeps_rebate: 'who_keeps_the_rebate_payment',
    initial_amount_due: 'first_lease_payment_amount',
    inverter_warranty: 'does_the_inverter_come_with_a_manufacturers_warranty',
    maintenance_party: 'who_is_responsible_for_project_maintenance',
    maintenance_years: 'for_how_many_years_is_maintenance_included',
    mounting_location: 'mounting_location',
    project_guarantee: 'is_the_lessor_providing_a_project_guarantee_if_so_please_provide_details_below',
    purchase_required: 'is_the_customer_required_to_purchase_the_solar_project_before_selling_the_property_where_it_is_located',
    seller_legal_name: 'project_lessor_legal_name',
    will_terms_change: 'will_the_pricing_terms_change_if_the_project_is_not_selected_for_the_adjustable_block_program_incentive',
    customer_address_1: 'street',
    customer_address_2: 'apartment_or_suite',
    expected_rec_value: 'the_expected_value_of_the_rec_incentive_payments_that_will_be_received_by_the_approved_vendor_for_this_project_is',
    frequency_payments: 'frequency_of_lease_payments',
    first_payment_due: 'when_the_first_payment_is_due',
    install_start_date_days: 'the_estimated_start_date_of_project_installation_in_days_from_the_date_the_contract_is_signed',
    other_fee_amount_1: 'other_fee_amount_1',
    other_fee_amount_2: 'other_fee_amount_2',
    other_fee_amount_3: 'other_fee_amount_3',
    other_fee_amount_4: 'other_fee_amount_4',
    other_fee_amount_5: 'other_fee_amount_5',
    project_size_kw_ac: 'project_size_ac_kw',
    project_size_kw_dc: 'project_size_dc_kw',
    seller_address_zip: 'project_lessor_zip_code',
    installer_address_1: 'installer_street',
    installer_address_2: 'installer_apartment_or_suite',
    roof_warranty_party: 'who_is_responsible_for_the_warranty_against_roof_leaks_due_to_improper_installation',
    roof_warranty_years: 'for_how_many_years_is_the_warranty_against_roof_leaks_included',
    seller_address_city: 'project_lessor_city',
    termination_penalty:
      'will_the_lessor_impose_a_fee_or_penalty_for_early_termination_of_the_lease_by_the_lessee_prior_to_the_conclusion_of_the_contract_term',
    customer_address_zip: 'zip_code',
    initial_deposit_owed: 'deposit_down_payment_or_initial_upfront_amount_owed_at_lease_signing',
    installer_legal_name: 'installer_legal_name',
    loss_damage_included: 'will_the_lessor_insure_for_loss_or_damage_to_the_project',
    maintenance_included: 'is_project_maintenance_operation_upkeep_included_as_part_of_the_lease',
    mechanic_waiver_date: 'what_is_the_estimated_date_for_the_lessor_to_furnish_a_mechanics_lien_waiver',
    panel_warranty_years: 'for_how_many_years_is_the_panel_manufacturers_warranty_included',
    return_site_original: 'will_the_lessor_return_the_site_to_its_original_condition_upon_the_removal_of_the_system',
    seller_address_email: 'project_lessor_email',
    seller_address_phone: 'project_lessor_phone',
    seller_address_state: 'project_lessor_state',
    customer_address_city: 'city',
    expected_project_life: 'expected_life_of_the_project_years',
    installer_address_zip: 'installer_zip_code',
    interconnection_party: 'party_responsible_for_submitting_a_project_interconnection_application_to_the_utility',
    seller_marketing_name: 'project_lessor_name_used_for_marketing',
    transfer_requirements: 'requirements_for_transferring_lease_obligations',
    customer_address_email: 'email',
    customer_address_phone: 'phone',
    customer_address_state: 'state',
    file_uniform_statement: 'does_the_lessor_intend_to_file_a_uniform_commercial_code_1_filing_statement',
    install_warranty_party: 'who_is_responsible_for_warranties_related_to_improper_installation',
    install_warranty_years: 'for_how_many_years_is_the_install_warranty_included',
    installer_address_city: 'installer_city',
    loss_damage_exceptions: 'provide_details_of_insurance_coverage_including_limitations_of_coverage',
    other_fee_applicable_1: 'other_fee_when_applicable_1',
    other_fee_applicable_2: 'other_fee_when_applicable_2',
    other_fee_applicable_3: 'other_fee_when_applicable_3',
    other_fee_applicable_4: 'other_fee_when_applicable_4',
    other_fee_applicable_5: 'other_fee_when_applicable_5',
    roof_warranty_included: 'is_the_roof_warrantied_against_leaks_from_the_project_installation',
    auto_esign_email_option: 'auto_esign_email_option',
    explanatory_information: 'project_performance_explanation',
    install_completion_date_days: 'the_estimated_completion_date_of_project_installation_in_days_from_the_date_the_contract_is_signed',
    installer_address_email: 'installer_email',
    installer_address_phone: 'installer_phone',
    installer_address_state: 'installer_state',
    inverter_warranty_years: 'for_how_many_years_is_the_inverter_manufacturers_warranty_included',
    panel_warranty_included: 'do_the_solar_panels_come_with_a_manufacturers_warranty',
    termination_calculation: 'or_will_be_calculated_as_follows',
    installer_marketing_name: 'installer_name_used_for_marketing_if_different',
    gross_electric_production: 'estimated_gross_annual_electricity_production_in_kilowatt_hours_from_the_project_in_the_first_year',
    install_warranty_included: 'is_the_project_warrantied_against_issues_related_to_improper_installation',
    smart_inverter_rebate_submission: 'will_an_application_be_submitted_for_the_smart_inverter_dg_rebate',
    substitute_auto_esign_emailaddr: 'substitute_auto_esign_emailaddr',
    final_payment_due: 'when_the_final_payment_is_due',
    install_start_date: 'alternatively_the_estimated_start_date_will_be',
    install_completion_date: 'alternatively_the_estimated_completion_date_will_be',
  }

  return getProp(reverseFormKeys, key, '')
}

//FIXME: this is a temporary function to map the lease form data keys to the ts interface lease disclosure data keys
export const mapLeaseFormDataKeyToTsInterface_LeaseDisclosureDataKey = (key: string): string => {
  const formKeys = {
    when_its_due_1: 'fee_due_1',
    when_its_due_2: 'fee_due_2',
    when_its_due_3: 'fee_due_3',
    when_its_due_4: 'fee_due_4',
    when_its_due_5: 'fee_due_5',
    disclosure_form_name: 'form_name',
    vendor_id_valid_non_retired: 'vendor_id',
    fee_name_1: 'fee_name_1',
    fee_name_2: 'fee_name_2',
    fee_name_3: 'fee_name_3',
    fee_name_4: 'fee_name_4',
    fee_name_5: 'fee_name_5',
    generate_pdf_in_spanish: 'is_spanish',
    will_the_lessor_offer_the_customer_the_option_to_own_and_retain_the_system_at_the_conclusion_of_the_term_of_the_system_lease: 'own_option',
    lease_term_years_portion: 'term_years',
    lease_term_months_portion: 'term_months',
    amount_1: 'fee_amount_1',
    amount_2: 'fee_amount_2',
    amount_3: 'fee_amount_3',
    amount_4: 'fee_amount_4',
    amount_5: 'fee_amount_5',
    will_the_lessor_offer_the_customer_the_option_to_renew_after_the_initial_term_of_the_system_lease: 'renew_option',
    customer_name: 'customer_name',
    project_type: 'customer_type',
    number_of_payments_1: 'fee_payment_1',
    number_of_payments_2: 'fee_payment_2',
    number_of_payments_3: 'fee_payment_3',
    number_of_payments_4: 'fee_payment_4',
    number_of_payments_5: 'fee_payment_5',
    rebate_amount: 'rebate_amount',
    will_the_lessor_remove_the_project_at_the_conclusion_of_the_term_of_the_system_lease: 'remove_system',
    who_is_responsible_for_repairs: 'repairs_party',
    for_how_many_years_are_project_repairs_included: 'repairs_years',
    is_the_lease_contingent_upon_selection_for_the_adjustable_block_program_incentive: 'abp_contingent',
    designee_email: 'designee_email',
    electric_utility_name_if_municipality_or_rural: 'muni_coop_name',
    total_number_of_payments_over_the_term_of_the_lease_contract: 'total_payments',
    annual_escalation_rate: 'escalation_rate',
    is_the_project_installer_known_at_this_time: 'installer_known',
    amount_of_fee_penalty: 'termination_fee',
    if_the_home_or_business_where_the_solar_project_is_located_is_sold_can_the_customer_transfer_the_system_lease_obligations_to_the_purchasers_of_the_home_or_business:
      'transfer_option',
    customers_electric_utility: 'electric_utility',
    final_lease_payment_amount: 'final_amount_due',
    installer_legal_name_1: 'installer_name_1',
    installer_legal_name_2: 'installer_name_2',
    installer_legal_name_3: 'installer_name_3',
    other_fee_name_1: 'other_fee_name_1',
    other_fee_name_2: 'other_fee_name_2',
    other_fee_name_3: 'other_fee_name_3',
    other_fee_name_4: 'other_fee_name_4',
    other_fee_name_5: 'other_fee_name_5',
    av_reference_number: 'reference_number',
    are_project_repairs_actions_required_to_fix_a_malfunctioning_system_included_as_part_of_the_lease: 'repairs_included',
    project_lessor_street: 'seller_address_1',
    project_lessor_apartment_or_suite: 'seller_address_2',
    who_keeps_the_rebate_payment: 'who_keeps_rebate',
    first_lease_payment_amount: 'initial_amount_due',
    does_the_inverter_come_with_a_manufacturers_warranty: 'inverter_warranty',
    who_is_responsible_for_project_maintenance: 'maintenance_party',
    for_how_many_years_is_maintenance_included: 'maintenance_years',
    mounting_location: 'mounting_location',
    is_the_lessor_providing_a_project_guarantee_if_so_please_provide_details_below: 'project_guarantee',
    is_the_customer_required_to_purchase_the_solar_project_before_selling_the_property_where_it_is_located: 'purchase_required',
    project_lessor_legal_name: 'seller_legal_name',
    will_the_pricing_terms_change_if_the_project_is_not_selected_for_the_adjustable_block_program_incentive: 'will_terms_change',
    street: 'customer_address_1',
    apartment_or_suite: 'customer_address_2',
    the_expected_value_of_the_rec_incentive_payments_that_will_be_received_by_the_approved_vendor_for_this_project_is: 'expected_rec_value',
    frequency_of_lease_payments: 'frequency_payments',
    when_the_first_payment_is_due: 'first_payment_due',
    the_estimated_start_date_of_project_installation_in_days_from_the_date_the_contract_is_signed: 'install_start_date_days',
    other_fee_amount_1: 'other_fee_amount_1',
    other_fee_amount_2: 'other_fee_amount_2',
    other_fee_amount_3: 'other_fee_amount_3',
    other_fee_amount_4: 'other_fee_amount_4',
    other_fee_amount_5: 'other_fee_amount_5',
    project_size_ac_kw: 'project_size_kw_ac',
    project_size_dc_kw: 'project_size_kw_dc',
    project_lessor_zip_code: 'seller_address_zip',
    installer_street: 'installer_address_1',
    installer_apartment_or_suite: 'installer_address_2',
    who_is_responsible_for_the_warranty_against_roof_leaks_due_to_improper_installation: 'roof_warranty_party',
    for_how_many_years_is_the_warranty_against_roof_leaks_included: 'roof_warranty_years',
    project_lessor_city: 'seller_address_city',
    will_the_lessor_impose_a_fee_or_penalty_for_early_termination_of_the_lease_by_the_lessee_prior_to_the_conclusion_of_the_contract_term:
      'termination_penalty',
    zip_code: 'customer_address_zip',
    deposit_down_payment_or_initial_upfront_amount_owed_at_lease_signing: 'initial_deposit_owed',
    installer_legal_name: 'installer_legal_name',
    will_the_lessor_insure_for_loss_or_damage_to_the_project: 'loss_damage_included',
    is_project_maintenance_operation_upkeep_included_as_part_of_the_lease: 'maintenance_included',
    what_is_the_estimated_date_for_the_lessor_to_furnish_a_mechanics_lien_waiver: 'mechanic_waiver_date',
    for_how_many_years_is_the_panel_manufacturers_warranty_included: 'panel_warranty_years',
    will_the_lessor_return_the_site_to_its_original_condition_upon_the_removal_of_the_system: 'return_site_original',
    project_lessor_email: 'seller_address_email',
    project_lessor_phone: 'seller_address_phone',
    project_lessor_state: 'seller_address_state',
    city: 'customer_address_city',
    expected_life_of_the_project_years: 'expected_project_life',
    installer_zip_code: 'installer_address_zip',
    party_responsible_for_submitting_a_project_interconnection_application_to_the_utility: 'interconnection_party',
    project_lessor_name_used_for_marketing: 'seller_marketing_name',
    requirements_for_transferring_lease_obligations: 'transfer_requirements',
    email: 'customer_address_email',
    phone: 'customer_address_phone',
    state: 'customer_address_state',
    does_the_lessor_intend_to_file_a_uniform_commercial_code_1_filing_statement: 'file_uniform_statement',
    who_is_responsible_for_warranties_related_to_improper_installation: 'install_warranty_party',
    for_how_many_years_is_the_install_warranty_included: 'install_warranty_years',
    installer_city: 'installer_address_city',
    provide_details_of_insurance_coverage_including_limitations_of_coverage: 'loss_damage_exceptions',
    other_fee_when_applicable_1: 'other_fee_applicable_1',
    other_fee_when_applicable_2: 'other_fee_applicable_2',
    other_fee_when_applicable_3: 'other_fee_applicable_3',
    other_fee_when_applicable_4: 'other_fee_applicable_4',
    other_fee_when_applicable_5: 'other_fee_applicable_5',
    is_the_roof_warrantied_against_leaks_from_the_project_installation: 'roof_warranty_included',
    auto_esign_email_option: 'auto_esign_email_option',
    project_performance_explanation: 'explanatory_information',
    the_estimated_completion_date_of_project_installation_in_days_from_the_date_the_contract_is_signed: 'install_completion_date_days',
    installer_email: 'installer_address_email',
    installer_phone: 'installer_address_phone',
    installer_state: 'installer_address_state',
    for_how_many_years_is_the_inverter_manufacturers_warranty_included: 'inverter_warranty_years',
    do_the_solar_panels_come_with_a_manufacturers_warranty: 'panel_warranty_included',
    or_will_be_calculated_as_follows: 'termination_calculation',
    installer_name_used_for_marketing_if_different: 'installer_marketing_name',
    estimated_gross_annual_electricity_production_in_kilowatt_hours_from_the_project_in_the_first_year: 'gross_electric_production',
    is_the_project_warrantied_against_issues_related_to_improper_installation: 'install_warranty_included',
    will_an_application_be_submitted_for_the_smart_inverter_dg_rebate: 'smart_inverter_rebate_submission',
    substitute_auto_esign_emailaddr: 'substitute_auto_esign_emailaddr',
    when_the_final_payment_is_due: 'final_payment_due',
    alternatively_the_estimated_start_date_will_be: 'install_start_date',
    alternatively_the_estimated_completion_date_will_be: 'install_completion_date',
  }

  return getProp(formKeys, key, '')
}

//FIXME: this is a temporary function to map the lease form data to the ts interface lease disclosure data
export const mapLeaseFormDataToTsInterface_LeaseDisclosureData = (formData: TsInterface_LeaseDisclosureForm): TsInterface_LeaseDisclosureAPIInputs => {
  const data: TsInterface_LeaseDisclosureAPIInputs = {
    fee_due_1: formData.when_its_due_1.value,
    fee_due_2: formData.when_its_due_2.value,
    fee_due_3: formData.when_its_due_3.value,
    fee_due_4: formData.when_its_due_4.value,
    fee_due_5: formData.when_its_due_5.value,
    form_name: formData.disclosure_form_name.value,
    form_type: `lease_form`,
    vendor_id: formData.vendor_id_valid_non_retired.value,
    fee_name_1: formData.fee_name_1.value,
    fee_name_2: formData.fee_name_2.value,
    fee_name_3: formData.fee_name_3.value,
    fee_name_4: formData.fee_name_4.value,
    fee_name_5: formData.fee_name_5.value,
    is_spanish: formData.generate_pdf_in_spanish.value,
    own_option: formData.will_the_lessor_offer_the_customer_the_option_to_own_and_retain_the_system_at_the_conclusion_of_the_term_of_the_system_lease.value,
    term_years: formData.lease_term_years_portion.value,
    term_months: formData.lease_term_months_portion.value,
    fee_amount_1: formData.amount_1.value,
    fee_amount_2: formData.amount_2.value,
    fee_amount_3: formData.amount_3.value,
    fee_amount_4: formData.amount_4.value,
    fee_amount_5: formData.amount_5.value,
    renew_option: formData.will_the_lessor_offer_the_customer_the_option_to_renew_after_the_initial_term_of_the_system_lease.value,
    customer_name: formData.customer_name.value,
    customer_type: formData.project_type.value,
    fee_payment_1: formData.number_of_payments_1.value,
    fee_payment_2: formData.number_of_payments_2.value,
    fee_payment_3: formData.number_of_payments_3.value,
    fee_payment_4: formData.number_of_payments_4.value,
    fee_payment_5: formData.number_of_payments_5.value,
    rebate_amount: formData.rebate_amount.value,
    remove_system: formData.will_the_lessor_remove_the_project_at_the_conclusion_of_the_term_of_the_system_lease.value,
    repairs_party: formData.who_is_responsible_for_repairs.value,
    repairs_years: formData.for_how_many_years_are_project_repairs_included.value,
    abp_contingent: formData.is_the_lease_contingent_upon_selection_for_the_adjustable_block_program_incentive.value,
    designee_email: formData.designee_email.value,
    muni_coop_name: formData.electric_utility_name_if_municipality_or_rural.value,
    total_payments: formData.total_number_of_payments_over_the_term_of_the_lease_contract.value,
    escalation_rate: formData.annual_escalation_rate.value,
    installer_known: formData.is_the_project_installer_known_at_this_time.value,
    termination_fee: formData.amount_of_fee_penalty.value,
    transfer_option:
      formData
        .if_the_home_or_business_where_the_solar_project_is_located_is_sold_can_the_customer_transfer_the_system_lease_obligations_to_the_purchasers_of_the_home_or_business
        .value,
    electric_utility: formData.customers_electric_utility.value,
    final_amount_due: formData.final_lease_payment_amount.value,
    installer_name_1: formData.installer_legal_name_1.value,
    installer_name_2: formData.installer_legal_name_2.value,
    installer_name_3: formData.installer_legal_name_3.value,
    other_fee_name_1: formData.other_fee_name_1.value,
    other_fee_name_2: formData.other_fee_name_2.value,
    other_fee_name_3: formData.other_fee_name_3.value,
    other_fee_name_4: formData.other_fee_name_4.value,
    other_fee_name_5: formData.other_fee_name_5.value,
    reference_number: formData.av_reference_number.value,
    repairs_included: formData.are_project_repairs_actions_required_to_fix_a_malfunctioning_system_included_as_part_of_the_lease.value,
    seller_address_1: formData.project_lessor_street.value,
    seller_address_2: formData.project_lessor_apartment_or_suite.value,
    who_keeps_rebate: formData.who_keeps_the_rebate_payment.value,
    final_payment_due: formData.when_the_final_payment_is_due.value,
    first_payment_due: formData.when_the_first_payment_is_due.value,
    inverter_warranty: formData.does_the_inverter_come_with_a_manufacturers_warranty.value,
    maintenance_party: formData.who_is_responsible_for_project_maintenance.value,
    maintenance_years: formData.for_how_many_years_is_maintenance_included.value,
    mounting_location: formData.mounting_location.value,
    project_guarantee: formData.is_the_lessor_providing_a_project_guarantee_if_so_please_provide_details_below.value,
    purchase_required: formData.is_the_customer_required_to_purchase_the_solar_project_before_selling_the_property_where_it_is_located.value,
    seller_legal_name: formData.project_lessor_legal_name.value,
    will_terms_change: formData.will_the_pricing_terms_change_if_the_project_is_not_selected_for_the_adjustable_block_program_incentive.value,
    customer_address_1: formData.street.value,
    customer_address_2: formData.apartment_or_suite.value,
    expected_rec_value: formData.the_expected_value_of_the_rec_incentive_payments_that_will_be_received_by_the_approved_vendor_for_this_project_is.value,
    frequency_payments: formData.frequency_of_lease_payments.value,
    initial_amount_due: formData.first_lease_payment_amount.value,
    install_start_date: formData.alternatively_the_estimated_start_date_will_be.value,
    other_fee_amount_1: formData.other_fee_amount_1.value,
    other_fee_amount_2: formData.other_fee_amount_2.value,
    other_fee_amount_3: formData.other_fee_amount_3.value,
    other_fee_amount_4: formData.other_fee_amount_4.value,
    other_fee_amount_5: formData.other_fee_amount_5.value,
    project_size_kw_ac: formData.project_size_ac_kw.value,
    project_size_kw_dc: formData.project_size_dc_kw.value,
    seller_address_zip: formData.project_lessor_zip_code.value,
    installer_address_1: formData.installer_street.value,
    installer_address_2: formData.installer_apartment_or_suite.value,
    roof_warranty_party: formData.who_is_responsible_for_the_warranty_against_roof_leaks_due_to_improper_installation.value,
    roof_warranty_years: formData.for_how_many_years_is_the_warranty_against_roof_leaks_included.value,
    seller_address_city: formData.project_lessor_city.value,
    termination_penalty:
      formData.will_the_lessor_impose_a_fee_or_penalty_for_early_termination_of_the_lease_by_the_lessee_prior_to_the_conclusion_of_the_contract_term.value,
    customer_address_zip: formData.zip_code.value,
    initial_deposit_owed: formData.deposit_down_payment_or_initial_upfront_amount_owed_at_lease_signing.value,
    installer_legal_name: formData.installer_legal_name.value,
    loss_damage_included: formData.will_the_lessor_insure_for_loss_or_damage_to_the_project.value,
    maintenance_included: formData.is_project_maintenance_operation_upkeep_included_as_part_of_the_lease.value,
    mechanic_waiver_date: formData.what_is_the_estimated_date_for_the_lessor_to_furnish_a_mechanics_lien_waiver.value,
    panel_warranty_years: formData.for_how_many_years_is_the_panel_manufacturers_warranty_included.value,
    return_site_original: formData.will_the_lessor_return_the_site_to_its_original_condition_upon_the_removal_of_the_system.value,
    seller_address_email: formData.project_lessor_email.value,
    seller_address_phone: formData.project_lessor_phone.value,
    seller_address_state: formData.project_lessor_state.value,
    customer_address_city: formData.city.value,
    expected_project_life: formData.expected_life_of_the_project_years.value,
    installer_address_zip: formData.installer_zip_code.value,
    interconnection_party: formData.party_responsible_for_submitting_a_project_interconnection_application_to_the_utility.value,
    seller_marketing_name: formData.project_lessor_name_used_for_marketing.value,
    total_payments_amount: formData.total_number_of_payments_over_the_term_of_the_lease_contract.value,
    transfer_requirements: formData.requirements_for_transferring_lease_obligations.value,
    customer_address_email: formData.email.value,
    customer_address_phone: formData.phone.value,
    customer_address_state: formData.state.value,
    file_uniform_statement: formData.does_the_lessor_intend_to_file_a_uniform_commercial_code_1_filing_statement.value,
    install_warranty_party: formData.who_is_responsible_for_warranties_related_to_improper_installation.value,
    install_warranty_years: formData.for_how_many_years_is_the_install_warranty_included.value,
    installer_address_city: formData.installer_city.value,
    installer_legal_name_1: formData.installer_legal_name_1.value,
    installer_legal_name_2: formData.installer_legal_name_2.value,
    installer_legal_name_3: formData.installer_legal_name_3.value,
    loss_damage_exceptions: formData.provide_details_of_insurance_coverage_including_limitations_of_coverage.value,
    other_fee_applicable_1: formData.other_fee_when_applicable_1.value,
    other_fee_applicable_2: formData.other_fee_when_applicable_2.value,
    other_fee_applicable_3: formData.other_fee_when_applicable_3.value,
    other_fee_applicable_4: formData.other_fee_when_applicable_4.value,
    other_fee_applicable_5: formData.other_fee_when_applicable_5.value,
    roof_warranty_included: formData.is_the_roof_warrantied_against_leaks_from_the_project_installation.value,
    auto_esign_email_option: formData.auto_esign_email_option.value,
    explanatory_information: formData.project_performance_explanation.value,
    install_completion_date: formData.alternatively_the_estimated_completion_date_will_be.value,
    install_start_date_days: formData.the_estimated_start_date_of_project_installation_in_days_from_the_date_the_contract_is_signed.value,
    installer_address_email: formData.installer_email.value,
    installer_address_phone: formData.installer_phone.value,
    installer_address_state: formData.installer_state.value,
    inverter_warranty_years: formData.for_how_many_years_is_the_inverter_manufacturers_warranty_included.value,
    panel_warranty_included: formData.do_the_solar_panels_come_with_a_manufacturers_warranty.value,
    termination_calculation: formData.or_will_be_calculated_as_follows.value,
    installer_marketing_name: formData.installer_name_used_for_marketing_if_different.value,
    gross_electric_production: formData.estimated_gross_annual_electricity_production_in_kilowatt_hours_from_the_project_in_the_first_year.value,
    install_warranty_included: formData.is_the_project_warrantied_against_issues_related_to_improper_installation.value,
    install_completion_date_days: formData.the_estimated_completion_date_of_project_installation_in_days_from_the_date_the_contract_is_signed.value,
    project_performance_explanation: formData.project_performance_explanation.value,
    smart_inverter_rebate_submission: formData.will_an_application_be_submitted_for_the_smart_inverter_dg_rebate.value,
    substitute_auto_esign_emailaddr: formData.substitute_auto_esign_emailaddr.value,
  }

  return data
}

export const leaseDisclosureForm: TsInterface_LeaseDisclosureForm = {
  vendor_id_valid_non_retired: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Must match an AV ID that is associated with your user account',
    options: [],
    label: 'Vendor ID',
  },
  av_reference_number: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'AV Reference Number',
  },
  disclosure_form_name: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Name must be unique, it cannot already exist in your list of DFs',
    options: [],
    label: 'Disclosure Form Name',
  },
  project_lessor_legal_name: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'This will also be used as the preset name if you choose to save this form as a preset',
    options: [],
    label: 'Project Lessor Legal Name',
  },
  project_lessor_name_used_for_marketing: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'number',
    value: '',
    instructions: '',
    options: [],
    label: 'Project Lessor Name Used for Marketing',
  },
  project_lessor_street: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Project Lessor Street',
  },
  project_lessor_apartment_or_suite: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Project Lessor Suite',
  },
  project_lessor_city: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Project Lessor City',
  },
  project_lessor_state: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Please use a two-letter state abbreviation',
    options: [],
    label: 'Project Lessor State',
  },
  project_lessor_zip_code: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Project Lessor Zip Code',
  },
  project_lessor_phone: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '10 digits, do not include dashes or parenthesis',
    options: [],
    label: 'Project Lessor Phone',
  },
  project_lessor_email: {
    is_required: true,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Project Lessor Email',
  },
  project_type: {
    is_required: true,
    input_type: 'dropdown',
    options: ['Residential/Small Commercial', 'Large Commercial/Industrial', 'Public School'],
    value: 'Residential/Small Commercial',
    conditional_dependencies: {},
    instructions: '',
    label: 'Project Type',
  },
  customer_name: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Name',
  },
  street: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Street',
  },
  apartment_or_suite: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Apartment or Suite',
  },
  city: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer City',
  },
  state: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: 'IL',
    instructions: 'Please use a two-letter state abbreviation',
    options: [],
    label: 'Customer State',
  },
  zip_code: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Zip Code',
  },
  phone: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '10 digits, do not include dashes or parentheses',
    options: [],
    label: 'Customer Phone',
  },
  email: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Email',
  },
  customers_electric_utility: {
    is_required: true,
    input_type: 'dropdown',
    value: 'ComEd',
    conditional_dependencies: {},
    instructions: '',
    options: ['AmerenIllinois', 'ComEd', 'MidAmerican', 'Mt. Carmel', 'Municipal Utility', 'Rural Electric Cooperative'],
    label: 'Electric Utility',
  },
  electric_utility_name_if_municipality_or_rural: {
    input_type: 'text',
    value: '',
    is_required: false,
    conditional_dependencies: { customers_electric_utility: ['Municipal Utility', 'Rural Electric Cooperative'] },
    instructions: '',
    options: [],
    label: 'Electric Utility Name',
  },
  is_the_project_installer_known_at_this_time: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Project Installer Known at This Time?',
  },
  installer_apartment_or_suite: {
    input_type: 'text',
    value: '',
    is_required: false,
    conditional_dependencies: { is_the_project_installer_known_at_this_time: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Apartment or Suite',
  },
  installer_legal_name: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { is_the_project_installer_known_at_this_time: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Legal Name',
  },
  installer_name_used_for_marketing_if_different: {
    is_required: false,
    conditional_dependencies: { installer_legal_name: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Name Used for Marketing',
  },
  installer_street: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { is_the_project_installer_known_at_this_time: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Street',
  },
  installer_city: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { is_the_project_installer_known_at_this_time: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer City',
  },
  installer_state: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { is_the_project_installer_known_at_this_time: ['Yes'] },
    instructions: 'Use a two-letter state abbreviation',
    options: [],
    label: 'Installer State',
  },
  installer_zip_code: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { is_the_project_installer_known_at_this_time: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Zip Code',
  },
  installer_phone: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { is_the_project_installer_known_at_this_time: ['Yes'] },
    instructions: '10 digits, do not include dashes or parenthesis',
    options: [],
    label: 'Installer Phone',
  },
  installer_email: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { is_the_project_installer_known_at_this_time: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Email',
  },
  designee_email: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Designee Email',
  },
  installer_legal_name_1: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Installer Legal Name 1',
  },
  installer_legal_name_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Installer Legal Name 2',
  },
  installer_legal_name_3: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Installer Legal Name 3',
  },
  deposit_down_payment_or_initial_upfront_amount_owed_at_lease_signing: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Deposit, Down Payment, or Initial Upfront Amount',
  },
  first_lease_payment_amount: {
    input_type: 'number',
    value: 0,
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit $ sign and commas, can be left blank or greater than/equal to 0',
    options: [],
    label: 'First Lease Payment Amount',
  },
  final_lease_payment_amount: {
    input_type: 'number',
    value: 0,
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit $ sign and commas, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Final Lease Payment Amount',
  },
  total_amount_of_all_lease_payments_over_the_course_of_the_lease_contract_do_not_include_any_down_payment_or_other_fees: {
    input_type: 'number',
    value: 0,
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit $ sign and commas. Do not include any down payment or other fees, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Total Amount of All Lease Payments',
  },
  frequency_of_lease_payments: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description of the frequency of the lease payments',
    options: [],
    label: 'Frequency of Lease Payments',
  },
  when_the_first_payment_is_due: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description or a date (mm/dd/yyyy format)',
    options: [],
    label: 'When the First Payment is Due',
  },
  when_the_final_payment_is_due: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description or a date (mm/dd/yyyy format)',
    options: [],
    label: 'When the Final Payment is Due',
  },
  total_number_of_payments_over_the_term_of_the_lease_contract: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Can be left blank or greater than/equal to 0',
    options: [],
    label: 'Total Number of Payments',
  },
  lease_term_months_portion: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number from 0-11',
    options: [],
    label: 'Lease Term Portion (Months)',
  },
  lease_term_years_portion: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'Lease Term Portion (Years)',
  },
  annual_escalation_rate: {
    input_type: 'number',
    value: 0,
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit % symbol, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Annual Escalation Rate',
  },
  the_expected_value_of_the_rec_incentive_payments_that_will_be_received_by_the_approved_vendor_for_this_project_is: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Expected Value Received by Approved Vendor for REC Incentive Payments',
  },
  is_the_lease_contingent_upon_selection_for_the_adjustable_block_program_incentive: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Lease Contingent Upon Selection for the Adjustable Block Program Incentive?',
  },
  will_the_pricing_terms_change_if_the_project_is_not_selected_for_the_adjustable_block_program_incentive: {
    is_required: false,
    conditional_dependencies: { is_the_lease_contingent_upon_selection_for_the_adjustable_block_program_incentive: ['No'] },
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Pricing Terms Change if the Project is Not Selected for the Adjustable Block Program Incentive?',
  },
  what_is_the_estimated_date_for_the_lessor_to_furnish_a_mechanics_lien_waiver: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Free text: enter a description or a date (mm/dd/yyyy format)',
    options: [],
    label: 'Estimated Date for Mechanics Lien Waiver',
  },
  party_responsible_for_submitting_a_project_interconnection_application_to_the_utility: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'Lessor',
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer', 'Customer'],
    label: 'Party Responsible for Submitting a Project Interconnection Application to the Utility',
  },
  does_the_lessor_intend_to_file_a_uniform_commercial_code_1_filing_statement: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Does the Lessor Intend to File a Uniform Commercial Code 1 Filing Statement?',
  },
  project_size_dc_kw: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit kW, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Project Size DC kW',
  },
  project_size_ac_kw: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit kW, must be greater than 0',
    options: [],
    label: 'Project Size AC kW',
  },
  expected_life_of_the_project_years: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'Expected Life of the Project (Years)',
  },
  mounting_location: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'Roof-mounted',
    instructions: '',
    options: ['Ground-mounted', 'Roof-mounted'],
    label: 'Mounting Location',
  },
  estimated_gross_annual_electricity_production_in_kilowatt_hours_from_the_project_in_the_first_year: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit kWh, must be greater than 0',
    options: [],
    label: 'Estimated Gross Annual Electricity Production',
  },
  will_an_application_be_submitted_for_the_smart_inverter_dg_rebate: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: { customers_electric_utility: ['AmerenIllinois', 'ComEd'] },
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will an Application be Submitted for the Smart Inverter DG Rebate?',
  },
  rebate_amount: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { will_an_application_be_submitted_for_the_smart_inverter_dg_rebate: ['Yes'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Rebate Amount',
  },
  who_keeps_the_rebate_payment: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Customer',
    conditional_dependencies: { will_an_application_be_submitted_for_the_smart_inverter_dg_rebate: ['Yes'] },
    instructions: '',
    options: ['Customer', 'Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who Keeps the Rebate Payment?',
  },
  is_project_maintenance_operation_upkeep_included_as_part_of_the_lease: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Maintenance Operation Upkeep Included as Part of the Lease?',
  },
  who_is_responsible_for_project_maintenance: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Lessor',
    conditional_dependencies: { is_project_maintenance_operation_upkeep_included_as_part_of_the_lease: ['Yes'] },
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Maintenance?',
  },
  for_how_many_years_is_maintenance_included: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { is_project_maintenance_operation_upkeep_included_as_part_of_the_lease: ['Yes'] },
    instructions: '',
    options: [],
    label: 'For How Many Years is Maintenance Included?',
  },
  are_project_repairs_actions_required_to_fix_a_malfunctioning_system_included_as_part_of_the_lease: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Are Project Repairs Required to Fix a Malfunctioning System Included as Part of the Lease?',
  },
  who_is_responsible_for_repairs: {
    is_required: false,
    input_type: 'dropdown',
    value: '',
    conditional_dependencies: { are_project_repairs_actions_required_to_fix_a_malfunctioning_system_included_as_part_of_the_lease: ['Yes'] },
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Repairs?',
  },
  for_how_many_years_are_project_repairs_included: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { are_project_repairs_actions_required_to_fix_a_malfunctioning_system_included_as_part_of_the_lease: ['Yes'] },
    instructions: 'If included, enter a number greater than 0',
    options: [],
    label: 'For How Many Years Are Project Repairs Included?',
  },
  is_the_project_warrantied_against_issues_related_to_improper_installation: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Project Warrantied Against Issues Related to Improper Installation?',
  },
  who_is_responsible_for_warranties_related_to_improper_installation: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Lessor',
    conditional_dependencies: { is_the_project_warrantied_against_issues_related_to_improper_installation: ['Yes'] },
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Warranties Related to Improper Installation?',
  },
  for_how_many_years_is_the_install_warranty_included: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { is_the_project_warrantied_against_issues_related_to_improper_installation: ['Yes'] },
    instructions: '',
    options: [],
    label: 'For How Many Years is the Install Warranty Included?',
  },
  is_the_roof_warrantied_against_leaks_from_the_project_installation: {
    is_required: false,
    input_type: 'dropdown',
    value: 'No',
    conditional_dependencies: { mounting_location: ['Roof-mounted'] },
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Roof Warrantied Against Leaks from the Project Installation?',
  },
  who_is_responsible_for_the_warranty_against_roof_leaks_due_to_improper_installation: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Lessor',
    conditional_dependencies: { is_the_roof_warrantied_against_leaks_from_the_project_installation: ['Yes'] },
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for the Warranty Against Roof Leaks Due to Improper Installation?',
  },
  for_how_many_years_is_the_warranty_against_roof_leaks_included: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { is_the_roof_warrantied_against_leaks_from_the_project_installation: ['Yes'] },
    instructions: '',
    options: [],
    label: 'For How Many Years Is the Warranty Against Roof Leaks Included',
  },
  do_the_solar_panels_come_with_a_manufacturers_warranty: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Do the Solar Panels Come with a Manufacturers Warranty',
  },
  for_how_many_years_is_the_panel_manufacturers_warranty_included: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { do_the_solar_panels_come_with_a_manufacturers_warranty: ['Yes'] },
    instructions: '',
    options: [],
    label: "For How Many Years Is the Panel Manufacturer's Warranty Included?",
  },
  does_the_inverter_come_with_a_manufacturers_warranty: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: "Does the Inverter Come with a Manufacturer's Warranty?",
  },
  for_how_many_years_is_the_inverter_manufacturers_warranty_included: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { does_the_inverter_come_with_a_manufacturers_warranty: ['Yes'] },
    instructions: '',
    options: [],
    label: "For How Many Years Is the Inverter Manufacturer's Warranty Included?",
  },
  will_the_lessor_insure_for_loss_or_damage_to_the_project: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Insure for Loss or Damage to the Project?',
  },
  provide_details_of_insurance_coverage_including_limitations_of_coverage: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { will_the_lessor_insure_for_loss_or_damage_to_the_project: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Provide Details of Insurance Coverage, Including Limitations of Coverage',
  },
  will_the_lessor_impose_a_fee_or_penalty_for_early_termination_of_the_lease_by_the_lessee_prior_to_the_conclusion_of_the_contract_term: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Impose a Fee or Penalty for Early Termination of the Lease by the Lessee Prior to the Conclusion of the Contract Term?',
  },
  amount_of_fee_penalty: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: {
      will_the_lessor_impose_a_fee_or_penalty_for_early_termination_of_the_lease_by_the_lessee_prior_to_the_conclusion_of_the_contract_term: ['Yes'],
    },
    instructions: 'Leave blank if description will be provided',
    options: [],
    label: 'Amount of Fee Penalty',
  },
  or_will_be_calculated_as_follows: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {
      will_the_lessor_impose_a_fee_or_penalty_for_early_termination_of_the_lease_by_the_lessee_prior_to_the_conclusion_of_the_contract_term: ['Yes'],
      amount_of_fee_penalty: [''],
    },
    instructions: 'Free text: enter a description',
    options: [],
    label: 'Or Will be Calculated as Follows',
  },
  will_the_lessor_remove_the_project_at_the_conclusion_of_the_term_of_the_system_lease: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Remove the Project at the Conclusion of the Term of the System Lease?',
  },
  will_the_lessor_return_the_site_to_its_original_condition_upon_the_removal_of_the_system: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Return the Site to Its Original Condition Upon the Removal of the System?',
  },
  will_the_lessor_offer_the_customer_the_option_to_own_and_retain_the_system_at_the_conclusion_of_the_term_of_the_system_lease: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Offer the Customer the Option to Own and Retain the System at the Conclusion of the Term of the System Lease?',
  },
  will_the_lessor_offer_the_customer_the_option_to_renew_after_the_initial_term_of_the_system_lease: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Offer the Customer the Option to Renew After the Initial Term of the System Lease?',
  },
  if_the_home_or_business_where_the_solar_project_is_located_is_sold_can_the_customer_transfer_the_system_lease_obligations_to_the_purchasers_of_the_home_or_business:
    {
      is_required: true,
      conditional_dependencies: {},
      input_type: 'dropdown',
      value: 'No',
      instructions: '',
      options: ['Yes', 'No'],
      label:
        'If the Home or Business Where the Solar Project is Located is Sold, Can the Customer Transfer the System Lease Obligations to the Purchasers of the Home or Business?',
    },
  requirements_for_transferring_lease_obligations: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {
      if_the_home_or_business_where_the_solar_project_is_located_is_sold_can_the_customer_transfer_the_system_lease_obligations_to_the_purchasers_of_the_home_or_business:
        ['Yes'],
    },
    instructions: 'Free text: enter a description',
    options: [],
    label: 'Requirements for Transferring Lease Obligations',
  },
  is_the_customer_required_to_purchase_the_solar_project_before_selling_the_property_where_it_is_located: {
    is_required: false,
    input_type: 'dropdown',
    value: 'No',
    conditional_dependencies: {
      if_the_home_or_business_where_the_solar_project_is_located_is_sold_can_the_customer_transfer_the_system_lease_obligations_to_the_purchasers_of_the_home_or_business:
        ['No'],
    },
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Customer Required to Purchase the Solar Project Before Selling the Property Where it is Located?',
  },
  explain_the_calculation_for_the_purchase_price: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {
      is_the_customer_required_to_purchase_the_solar_project_before_selling_the_property_where_it_is_located: ['Yes'],
      if_the_home_or_business_where_the_solar_project_is_located_is_sold_can_the_customer_transfer_the_system_lease_obligations_to_the_purchasers_of_the_home_or_business:
        ['No'],
    },
    instructions: 'Free text: enter a description',
    options: [],
    label: 'Explain the Calculation for the Purchase Price',
  },
  fee_name_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 fees that will apply to the project. If there are no fees, leave blank',
    options: [],
    label: 'Fee Name 1',
  },
  amount_1: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 1',
  },
  when_its_due_1: {
    input_type: 'text',
    value: '',
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 1',
  },
  number_of_payments_1: {
    input_type: 'number',
    value: 0,
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 1',
  },
  fee_name_2: {
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 2',
  },
  amount_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 2',
  },
  when_its_due_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 2',
  },
  number_of_payments_2: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 2',
  },
  fee_name_3: {
    is_required: false,
    conditional_dependencies: { fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 3',
  },
  amount_3: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 3',
  },
  when_its_due_3: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 3',
  },
  number_of_payments_3: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 3',
  },
  fee_name_4: {
    is_required: false,
    conditional_dependencies: { fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 4',
  },
  amount_4: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 4',
  },
  when_its_due_4: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 4',
  },
  number_of_payments_4: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 4',
  },
  fee_name_5: {
    is_required: false,
    conditional_dependencies: { fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 5',
  },
  amount_5: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 5',
  },
  when_its_due_5: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 5',
  },
  number_of_payments_5: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 5',
  },
  other_fee_name_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 1',
  },
  other_fee_amount_1: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 1',
  },
  other_fee_when_applicable_1: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 1',
  },
  other_fee_name_2: {
    is_required: false,
    conditional_dependencies: { other_fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Other Fee Name 2',
  },
  other_fee_amount_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 2',
  },
  other_fee_when_applicable_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 2',
  },
  other_fee_name_3: {
    is_required: false,
    conditional_dependencies: { other_fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Other Fee Name 3',
  },
  other_fee_amount_3: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 3',
  },
  other_fee_when_applicable_3: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 3',
  },
  other_fee_name_4: {
    is_required: false,
    conditional_dependencies: { other_fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Other Fee Name 4',
  },
  other_fee_amount_4: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 4',
  },
  other_fee_when_applicable_4: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 4',
  },
  other_fee_name_5: {
    is_required: false,
    conditional_dependencies: { other_fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Other Fee Name 5',
  },
  other_fee_amount_5: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 5',
  },
  other_fee_when_applicable_5: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 5',
  },
  the_estimated_start_date_of_project_installation_in_days_from_the_date_the_contract_is_signed: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Leave blank if unknown',
    options: [],
    label: 'The Estimated Start Date of Project Installation in Days from the Date the Contract is Signed',
  },
  alternatively_the_estimated_start_date_will_be: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { the_estimated_start_date_of_project_installation_in_days_from_the_date_the_contract_is_signed: [''] },
    instructions: 'yyyy-mm-dd format',
    options: [],
    label: 'Alternatively, the Estimated Start Date Will Be',
  },
  the_estimated_completion_date_of_project_installation_in_days_from_the_date_the_contract_is_signed: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Leave blank if unknown',
    options: [],
    label: 'The Estimated Completion Date of Project Installation in Days from the Date the Contract is Signed',
  },
  alternatively_the_estimated_completion_date_will_be: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { the_estimated_completion_date_of_project_installation_in_days_from_the_date_the_contract_is_signed: [''] },
    instructions: 'yyyy-mm-dd format',
    options: [],
    label: 'Alternatively, the Estimated Completion Date Will Be',
  },
  project_performance_explanation: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Project Performance Explanation',
  },
  is_the_lessor_providing_a_project_guarantee_if_so_please_provide_details_below: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description',
    options: [],
    label: 'Is the Lessor Providing a Project Guarantee? If so, please provide details below',
  },
  additional_details_or_explanatory_information: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: 'Free text: can refer to any item in the disclosure form',
    options: [],
    label: 'Additional Details or Explanatory Information',
  },
  generate_pdf_in_spanish: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Generate Form PDF in Spanish?',
  },
  auto_esign_email_option: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: 'Select yes if you would like to receive an email with the form attached',
    options: ['Yes', 'No'],
    label: 'Auto E-Sign Email Option',
  },
  substitute_auto_esign_emailaddr: {
    is_required: false,
    conditional_dependencies: { auto_esign_email_option: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Substitute Auto E-Sign Email Address',
  },
}

export const cashDisclosureForm: TsInterface_CashDisclosureForm = {
  vendor_id: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Must match an AV ID that is associated with your user account',
    options: [],
    label: 'Vendor ID',
  },
  form_type: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: 'purchase_form',
    instructions: '',
    options: [],
    label: 'Form Type',
  },
  reference_number: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'AV Reference Number',
  },
  form_name: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Name must be unique, it cannot already exist in your list of DFs',
    options: [],
    label: 'Disclosure Form Name',
  },
  seller_legal_name: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'This will also be used as the preset name if you choose to save this form as a preset',
    options: [],
    label: 'Seller Legal Name',
  },
  seller_address_1: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Seller Street',
  },
  seller_address_2: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Suite',
  },
  seller_address_city: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller City',
  },
  seller_address_state: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller State',
  },
  seller_address_phone: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Phone',
  },
  seller_address_email: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Email',
  },
  seller_address_zip: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Zip Code',
  },
  customer_type: {
    is_required: true,
    input_type: 'dropdown',
    options: ['Residential/Small Commercial', 'Large Commercial/Industrial', 'Public School'],
    value: 'Residential/Small Commercial',
    conditional_dependencies: {},
    instructions: '',
    label: 'Project Type',
  },
  customer_name: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Name',
  },
  customer_address_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Street',
  },
  customer_address_2: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Apartment Or Suite',
  },
  customer_address_city: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer City',
  },
  customer_address_state: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: 'IL',
    instructions: '',
    options: [],
    label: 'Customer State',
  },
  customer_address_zip: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Zip Code',
  },
  customer_address_phone: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Phone',
  },
  customer_address_email: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Email',
  },
  electric_utility: {
    is_required: true,
    input_type: 'dropdown',
    value: 'ComEd',
    conditional_dependencies: {},
    instructions: '',
    options: ['AmerenIllinois', 'ComEd', 'MidAmerican', 'Mt. Carmel', 'Municipal Utility', 'Rural Electric Cooperative'],
    label: 'Electric Utility',
  },
  muni_coop_name: {
    input_type: 'text',
    value: '',
    is_required: false,
    conditional_dependencies: { electric_utility: ['Municipal Utility', 'Rural Electric Cooperative'] },
    instructions: '',
    options: [],
    label: 'Electric Utility Name',
  },
  seller_marketing_name: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Name Used for Marketing',
  },
  installer_known: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Project Installer Known at This Time?',
  },
  installer_legal_name: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Legal Name',
  },
  installer_marketing_name: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: 'Enter if different from legal name',
    options: [],
    label: 'Installer Marketing Name',
  },
  installer_address_1: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Street',
  },
  installer_address_2: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Suite',
  },
  installer_address_city: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer City',
  },
  installer_address_state: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer State',
  },
  installer_address_zip: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Zip Code',
  },
  installer_address_phone: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Phone',
  },
  installer_address_email: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Email',
  },
  installer_name_1: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Name 1',
  },
  installer_legal_name_1: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Legal Name 1',
  },
  installer_name_2: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Name 2',
  },
  installer_legal_name_2: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Legal Name 2',
  },
  installer_name_3: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Name 3',
  },
  installer_legal_name_3: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Legal Name 3',
  },
  deposit_owed: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Deposit Owed',
  },
  initial_deposit_owed: {
    // already a deposit owed field...
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Deposit, Down Payment, or Initial Upfront Amount',
  },
  installation_owed: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Installation Owed',
  },
  final_payment_due: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a date or a description',
    options: [],
    label: 'When is the Final Payment Due?',
  },
  final_amount_owed: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Final Amount Owed',
  },
  intermediary_due: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'When is the Intermediary Due?',
  },
  intermediary_payments: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Intermediary Payments Amount',
  },
  fee_name_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 fees that will apply to the project. If there are no fees, leave blank',
    options: [],
    label: 'Fee Name 1',
  },
  fee_amount_1: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_1: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 1',
  },
  fee_due_1: {
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 1',
  },
  fee_payment_1: {
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 1 Payments',
  },
  fee_name_2: {
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 2',
  },
  fee_amount_2: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_2: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 2',
  },
  fee_due_2: {
    is_required: false,
    conditional_dependencies: { fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 2',
  },
  fee_payment_2: {
    is_required: false,
    conditional_dependencies: { fee_name_2: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 2 Payments',
  },
  fee_name_3: {
    is_required: false,
    conditional_dependencies: { fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 3',
  },
  fee_amount_3: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_3: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 3',
  },
  fee_due_3: {
    is_required: false,
    conditional_dependencies: { fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 3',
  },
  fee_payment_3: {
    is_required: false,
    conditional_dependencies: { fee_name_3: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 3 Payments',
  },
  fee_name_4: {
    is_required: false,
    conditional_dependencies: { fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 4',
  },
  fee_amount_4: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_4: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 4',
  },
  fee_due_4: {
    is_required: false,
    conditional_dependencies: { fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 4',
  },
  fee_payment_4: {
    is_required: false,
    conditional_dependencies: { fee_name_4: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 4 Payments',
  },
  fee_name_5: {
    is_required: false,
    conditional_dependencies: { fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 5',
  },
  fee_amount_5: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_5: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 5',
  },
  fee_due_5: {
    is_required: false,
    conditional_dependencies: { fee_name_5: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 5',
  },
  fee_payment_5: {
    is_required: false,
    conditional_dependencies: { fee_name_5: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 5 Payments',
  },
  other_fee_name_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 1',
  },
  other_fee_amount_1: {
    is_required: false,
    conditional_dependencies: { other_fee_name_1: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 1',
  },
  other_fee_applicable_1: {
    is_required: false,
    conditional_dependencies: { other_fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 1 Payments',
  },
  other_fee_name_2: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 2',
  },
  other_fee_amount_2: {
    is_required: false,
    conditional_dependencies: { other_fee_name_2: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 2',
  },
  other_fee_applicable_2: {
    is_required: false,
    conditional_dependencies: { other_fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 2 Payments',
  },
  other_fee_name_3: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 3',
  },
  other_fee_amount_3: {
    is_required: false,
    conditional_dependencies: { other_fee_name_3: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 3',
  },
  other_fee_applicable_3: {
    is_required: false,
    conditional_dependencies: { other_fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 3 Payments',
  },
  other_fee_name_4: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 4',
  },
  other_fee_amount_4: {
    is_required: false,
    conditional_dependencies: { other_fee_name_4: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 4',
  },
  other_fee_applicable_4: {
    is_required: false,
    conditional_dependencies: { other_fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 4 Payments',
  },
  other_fee_name_5: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 5',
  },
  other_fee_amount_5: {
    is_required: false,
    conditional_dependencies: { other_fee_name_5: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 5',
  },
  other_fee_applicable_5: {
    is_required: false,
    conditional_dependencies: { other_fee_name_5: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 5 Payments',
  },
  collateral_fee_required: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is a Collateral Fee Required?',
  },
  collateral_fee_type: {
    is_required: false,
    conditional_dependencies: { collateral_fee_required: ['Yes'] },
    input_type: 'dropdown',
    value: '',
    instructions: '',
    options: ['Charge a fee', 'Withhold part of the REC payment'],
    label: 'Collateral Fee Type',
  },
  collateral_fee_amount: {
    is_required: false,
    conditional_dependencies: { collateral_fee_required: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Collateral Fee Amount',
  },
  collateral_fee_return_date: {
    is_required: false,
    conditional_dependencies: { collateral_fee_required: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description or a date',
    options: [],
    label: 'Collateral Fee Return Date',
  },
  collateral_fee_conditions: {
    is_required: false,
    conditional_dependencies: { collateral_fee_required: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Collateral Fee Conditions',
  },
  expected_rec_value: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Expected Value Of REC Incentive Payments Received By Approved Vendor If Accepted Into Illinois Shines Program',
  },
  abp_contingent: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Installation Contract Contingent Upon Selection for the Adjustable Block Program Incentive?',
  },
  will_terms_change: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Terms of the Installation Contract Change if the Project is Not Selected for the Adjustable Block Program Incentive?',
  },
  rec_customer_payment: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: '',
    options: [],
    label: 'Amount of REC Payment Customer Will Receive From Approved Vendor',
  },
  project_submit_date: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description or a date',
    options: [],
    label: 'When Will the Approved Vendor Submit an Application For the Project to Illinois Shines?',
  },
  install_start_date_days: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Leave blank if unknown',
    options: [],
    label: 'The Estimated Start Date of Project Installation in Days from the Date the Contract is Signed',
  },
  install_start_date: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { install_start_date_days: [''] },
    instructions: 'yyyy-mm-dd format',
    options: [],
    label: 'Alternatively, the Estimated Start Date Will Be',
  },
  install_completion_date_days: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Leave blank if unknown',
    options: [],
    label: 'The Estimated Completion Date of Project Installation in Days from the Date the Contract is Signed',
  },
  install_completion_date: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { install_completion_date_days: [''] },
    instructions: 'yyyy-mm-dd format',
    options: [],
    label: 'Alternatively, the Estimated Completion Date Will Be',
  },
  mechanic_waiver_date: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Free text: enter a description or a date (mm/dd/yyyy format)',
    options: [],
    label: 'Estimated Date for Mechanics Lien Waiver',
  },
  interconnection_party: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer', 'Customer'],
    label: 'Party Responsible for Submitting a Project Interconnection Application to the Utility',
  },
  project_size_kw_ac: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit kW, must be greater than 0',
    options: [],
    label: 'Project Size AC kW',
  },
  project_size_kw_dc: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit kW, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Project Size DC kW',
  },
  expected_project_life: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'Expected Life of the Project (Years)',
  },
  mounting_location: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'Roof-mounted',
    instructions: '',
    options: ['Ground-mounted', 'Roof-mounted'],
    label: 'Mounting Location',
  },
  gross_electric_production: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit kWh, must be greater than 0',
    options: [],
    label: 'Estimated Gross Annual Electricity Production',
  },
  project_performance_explanation: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Project Performance Explanation',
  },
  smart_inverter_rebate_submission: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: { customers_electric_utility: ['AmerenIllinois', 'ComEd'] },
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will an Application be Submitted for the Smart Inverter DG Rebate?',
  },
  rebate_amount: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { smart_inverter_rebate_submission: ['Yes'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Rebate Amount',
  },
  who_keeps_rebate: {
    is_required: false,
    conditional_dependencies: { smart_inverter_rebate_submission: ['Yes'] },
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer', 'Customer'],
    label: 'Who Keeps the Rebate?',
  },
  maintenance_included: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Maintenance Operation Upkeep Included as Part of the Contract?',
  },
  maintenance_party: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Seller',
    conditional_dependencies: { maintenance_included: ['Yes'] },
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Maintenance?',
  },
  maintenance_years: {
    is_required: false,
    conditional_dependencies: { maintenance_included: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: '',
    options: [],
    label: 'For How Many Years is Maintenance Included?',
  },
  repairs_included: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Are Project Repairs Required to Fix a Malfunctioning System Included as Part of the Lease?',
  },
  repairs_party: {
    is_required: false,
    conditional_dependencies: { repairs_included: ['Yes'] },
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Repairs?',
  },
  repairs_years: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { repairs_included: ['Yes'] },
    instructions: 'If included, enter a number greater than 0',
    options: [],
    label: 'For How Many Years Are Project Repairs Included?',
  },
  install_warranty_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Installation Warranty Included as Part of the Contract?',
  },
  install_warranty_party: {
    is_required: false,
    conditional_dependencies: { install_warranty_included: ['Yes'] },
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Installation Warranty?',
  },
  install_warranty_years: {
    is_required: false,
    conditional_dependencies: { install_warranty_included: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'For How Many Years is Project Installation Warranty Included?',
  },
  roof_warranty_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Roof Warranty Included as Part of the Contract?',
  },
  roof_warranty_party: {
    is_required: false,
    conditional_dependencies: { roof_warranty_included: ['Yes'] },
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Roof Warranty?',
  },
  roof_warranty_years: {
    is_required: false,
    conditional_dependencies: { roof_warranty_included: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'For How Many Years is Project Roof Warranty Included?',
  },
  loss_damage_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Seller Insure for Loss or Damage to the Project?',
  },
  loss_damage_exceptions: {
    is_required: false,
    conditional_dependencies: { loss_damage_included: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Provide Details of Insurance Coverage, Including Limitations of Coverage',
  },
  panel_warranty_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Panel Warranty Included as Part of the Contract?',
  },
  panel_warranty_years: {
    is_required: false,
    conditional_dependencies: { panel_warranty_included: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'For How Many Years is Project Panel Warranty Included?',
  },
  inverter_warranty: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Inverter Warranty Included as Part of the Contract?',
  },
  inverter_warranty_years: {
    is_required: false,
    conditional_dependencies: { inverter_warranty: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'For How Many Years is Project Inverter Warranty Included?',
  },
  project_guarantee: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Seller Project Guarantee',
  },
  explanatory_information: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Additional Explanatory Information',
  },
  is_spanish: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Generate Form PDF in Spanish?',
  },
  designee_email: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: '',
  },
  auto_esign_email_option: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: 'Select yes if you would like to receive an email with the form attached',
    options: ['Yes', 'No'],
    label: 'Auto E-Sign Email Option',
  },
  substitute_auto_esign_emailaddr: {
    is_required: false,
    conditional_dependencies: { auto_esign_email_option: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Substitute Auto E-Sign Email Address',
  },
}
