///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_VehiclesForSpecificUser_Query } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import {
  DatabaseRef_ActiveIncidentTypes_Query,
  DatabaseRef_AllActiveIncidentTypes_Query,
  DatabaseRef_IncidentReports_Collection,
  DatabaseRef_IncidentReportTypes_Collection,
  DatabaseRef_IncidentReportTypes_Document,
  DatabaseRef_IncidentReport_Document,
} from 'rfbp_aux/services/database_endpoints/operations/incident_reports'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { formatCurrency, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseIncidentReportsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_INCIDENT_REPORT: JSX.Element = <Trans>Are you sure that you want to delete this incident report?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_INCIDENT_REPORT: JSX.Element = <Trans>Are you sure that you want to undelete this incident report</Trans>
const s_CREATE_INCIDENT_REPORT: JSX.Element = <Trans>Create Incident Report</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_INCIDENT_REPORT: JSX.Element = <Trans>Delete Incident Report</Trans>

const s_INCIDENT_DATE: JSX.Element = <Trans>Incident Date</Trans>
const s_INCIDENT_LOCATION: JSX.Element = <Trans>Incident Location</Trans>
const s_INCIDENT_REPORTS: JSX.Element = <Trans>Incident Reports</Trans>
const s_INCIDENT_TYPE: JSX.Element = <Trans>Incident Type</Trans>
const s_NEW: JSX.Element = <Trans>New</Trans>
const s_NEW_INCIDENT_REPORT: JSX.Element = <Trans>New Incident Report</Trans>
const s_NO_VEHICLES_FOR_SELECTED_DRIVER: JSX.Element = <Trans>No vehicles for selected driver</Trans>
const s_REMOVE: JSX.Element = <Trans>Remove</Trans>
const s_STATUS: JSX.Element = <Trans>Status</Trans>
const s_UNDELETE: JSX.Element = <Trans>Undelete</Trans>
const s_UNDELETE_INCIDENT_REPORT: JSX.Element = <Trans>Undelete Incident Report</Trans>
const s_VEHICLE: JSX.Element = <Trans>Vehicle</Trans>
const s_VEHICLE_VIN: JSX.Element = <Trans>Vehicle VIN</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const s_WHAT_HAPPENED: JSX.Element = <Trans>What happened?</Trans>
const se_INCIDENT_REPORTS: string = 'Incident Reports'
// { sort-end } - displayed text

export const incidentReportTypeOptions: TsInterface_UnspecifiedObject = {
  damage_to_vehicle_or_trailer: {
    key: 'damage_to_vehicle_or_trailer',
    value: rLIB('Damage to vehicle or trailer'),
    chipJSX: (
      <Box>
        <Chip
          color="warning"
          label={
            <Box>
              <Icon
                icon="car-burst"
                className="tw-mr-2"
              />
              {rLIB('Damage to vehicle or trailer')}
            </Box>
          }
        />
      </Box>
    ),
  },
  accident_with_another_party: {
    key: 'accident_with_another_party',
    value: rLIB('Accident with another party'),
    chipJSX: (
      <Box>
        <Chip
          color="error"
          label={
            <Box>
              <Icon
                icon="cars"
                className="tw-mr-2"
              />
              {rLIB('Accident with another party')}
            </Box>
          }
        />
      </Box>
    ),
  },
  safety_incident: {
    key: 'safety_incident',
    value: rLIB('Safety Incident'),
    chipJSX: (
      <Box>
        <Chip
          color="warning"
          label={
            <Box>
              <Icon
                icon="helmet-safety"
                className="tw-mr-2"
              />
              {rLIB('Safety Incident')}
            </Box>
          }
        />
      </Box>
    ),
  },
  traffic_violation: {
    key: 'traffic_violation',
    value: rLIB('Traffic Violation'),
    chipJSX: (
      <Box>
        <Chip
          color="info"
          label={
            <Box>
              <Icon
                icon="user-police-tie"
                className="tw-mr-2"
              />
              {rLIB('Traffic Violation')}
            </Box>
          }
        />
      </Box>
    ),
  },
}

// Form
const incidentReportStatusOptions: TsInterface_UnspecifiedObject = {
  new: {
    key: 'new',
    value: s_NEW,
    chipJSX: (
      <Box>
        <Chip
          color="error"
          label={
            <Box>
              <Icon
                icon="circle-exclamation"
                className="tw-mr-2"
              />
              {s_NEW}
            </Box>
          }
        />
      </Box>
    ),
  },
  deleted: {
    key: 'deleted',
    value: rLIB('Deleted'),
    chipJSX: (
      <Box>
        <Chip
          color="default"
          label={
            <Box>
              <Icon
                icon="trash"
                className="tw-mr-2"
              />
              {rLIB('Deleted')}
            </Box>
          }
        />
      </Box>
    ),
  },
}

const formInputs_IncidentReport: TsInterface_FormInputs = {
  incident_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'incident_type',
    label: s_INCIDENT_TYPE,
    required: true,
    options: objectToArray(incidentReportTypeOptions),
  },
  associated_user_key: {
    data_type: 'string',
    key: 'associated_user_key',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // User Search
      const rJSX_UserSearchResult = (
        option: TsInterface_UnspecifiedObject,
        searchInputValue: string | null,
        inputHooks: TsInterface_InputHooksObject,
        additionalSearchData: TsInterface_UnspecifiedObject,
      ): JSX.Element => {
        let searchResultJSX = (
          <Box
            sx={{ marginLeft: '8px', marginRight: '8px' }}
            onClick={() => {
              formInputChange('associated_user_key', option.id, true)
              formInputChange('associated_user_name', option.name, true)
              // Get Vehicles linked to user
              if (option.id != null) {
                DatabaseGetCollection(DatabaseRef_VehiclesForSpecificUser_Query(formHooks.uc_RootData_ClientKey, option.id))
                  .then((res_DGC) => {
                    formAdditionalData['vehicles'] = res_DGC.data
                    formHooks.ur_forceRerender()
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                    formAdditionalData['vehicles'] = {}
                    formHooks.ur_forceRerender()
                  })
              } else {
                formAdditionalData['vehicles'] = {}
                formHooks.ur_forceRerender()
              }
            }}
          >
            <Typography className="tw-cursor-pointer">{rJSX_HighlightedSearchString(searchInputValue, option.name)} </Typography>
          </Box>
        )
        return searchResultJSX
      }
      // Full User and Vehicle Selection
      const rJSX_UserSelection = (): JSX.Element => {
        let userSelectionJSX = <></>
        if (formHooks.uc_RootData_ClientKey != null) {
          if (formData['associated_user_key'] == null || formData['associated_user_name'] == null) {
            userSelectionJSX = (
              <Box>
                <Typography
                  variant="body1"
                  className="tw-opacity-70"
                >
                  {rLIB('Employee Name')}*
                </Typography>
                <SearchInput
                  clientKey={formHooks.uc_RootData_ClientKey}
                  searchIndexKey={'users'}
                  searchFilters={[]}
                  searchResultRenderer={rJSX_UserSearchResult}
                  additionalSearchData={{}}
                  defaultSearchValue={''}
                  sx={{ minWidth: '100%' }}
                />
              </Box>
            )
          } else {
            userSelectionJSX = (
              <Box>
                <Typography
                  variant="body1"
                  className=""
                >
                  <Box
                    component="span"
                    className="tw-opacity-70 tw-mr-2"
                  >
                    {rLIB('Employee Name')}:
                  </Box>
                  <Box
                    component="span"
                    className="tw-font-bold"
                  >
                    {getProp(formData, 'associated_user_name', <></>)}
                  </Box>
                  <Icon
                    icon="square-xmark"
                    tooltip={s_REMOVE}
                    tooltipPlacement="right"
                    className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                    onClick={() => {
                      formInputChange('associated_user_key', null, true)
                      formInputChange('associated_user_name', null, true)
                      formInputChange('associated_vehicle_key', null, true)
                      formInputChange('associated_vehicle_vin', null, true)
                      formAdditionalData['vehicles'] = {}
                    }}
                  />
                </Typography>
              </Box>
            )
          }
        }
        return userSelectionJSX
      }
      let inputJSX = <Box className="tw-mt-4 tw-mb-4">{rJSX_UserSelection()}</Box>
      return inputJSX
    },
  },
  associated_vehicle_key: {
    data_type: 'string',
    key: 'associated_vehicle_key',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      let inputJSX = <></>
      if (formAdditionalData != null && formAdditionalData.vehicles != null && objectToArray(getProp(formAdditionalData, 'vehicles', {})).length > 0) {
        inputJSX = (
          <Box className="tw-mt-4 tw-mb-4">
            <Typography
              variant="body1"
              className="tw-opacity-70"
            >
              {s_VEHICLE}*
            </Typography>
            <FormControl fullWidth>
              <RadioGroup
                onChange={(event, value) => {
                  if (value != null) {
                    let vehicleVin = null
                    if (formAdditionalData != null && formAdditionalData.vehicles != null) {
                      vehicleVin = getProp(getProp(formAdditionalData.vehicles, value, {}), 'vin', null)
                    }
                    formInputChange('associated_vehicle_key', value, true)
                    formInputChange('associated_vehicle_vin', vehicleVin, true)
                  }
                }}
                value={formData.associated_vehicle_key || ''}
                sx={{ display: 'block' }}
              >
                {objectToArray(getProp(formAdditionalData, 'vehicles', {})).map((vehicle: TsInterface_UnspecifiedObject, vehicleIndex: number) => (
                  <FormControlLabel
                    key={vehicleIndex}
                    sx={{ display: 'flex' }}
                    control={<Radio />}
                    label={vehicle.vin}
                    value={vehicle.key}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        )
      } else {
        inputJSX = (
          <Box className="tw-mt-4 tw-mb-4">
            <Typography
              variant="body1"
              className="tw-opacity-70"
            >
              {s_VEHICLE}*
            </Typography>
            <Typography
              variant="body1"
              className="tew-mt-2"
            >
              <Icon
                icon="circle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              />
              {s_NO_VEHICLES_FOR_SELECTED_DRIVER}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  associated_user_name: {
    data_type: 'string',
    key: 'associated_user_name',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      return <></>
    },
  },
  associated_vehicle_vin: {
    data_type: 'string',
    key: 'associated_vehicle_vin',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      return <></>
    },
  },
  timestamp_incident: {
    key: 'timestamp_incident',
    label: s_INCIDENT_DATE,
    input_type: 'timestamp_datetime',
    required: true,
    data_type: 'string',
  },
  incident_location: {
    key: 'incident_location',
    label: s_INCIDENT_LOCATION,
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  notes: {
    key: 'notes',
    label: s_WHAT_HAPPENED,
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },

  cost_of_damage: {
    key: 'cost_of_damage',
    label: 'Cost of Damage',
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9.]/g, '') // Remove non-numeric characters
        const formattedValue = `$${parseFloat(value || '0').toFixed(2)}` // Format as dollar amount
        formInputChange('cost_of_damage', formattedValue, true)
      }

      return (
        <TextField
          label="Cost of Damage"
          value={formData.cost_of_damage || ''}
          onChange={handleChange}
          fullWidth
        />
      )
    },
  },
}

// Tables
const tableColumns_IncidentReports: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{s_VIEW}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseIncidentReportViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{s_DELETE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: s_DELETE_INCIDENT_REPORT,
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: s_DELETE,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_INCIDENT_REPORT,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    status: 'deleted',
                    status_before_deletion: getProp(rowData, 'status', null),
                  }
                  DatabaseSetMergeDocument(DatabaseRef_IncidentReport_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-undo"
        />
      ),
      label: <>{s_UNDELETE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: s_UNDELETE_INCIDENT_REPORT,
              icon: (
                <Icon
                  icon="trash-undo"
                  type="solid"
                />
              ),
              submit_text: s_UNDELETE,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_INCIDENT_REPORT,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: getProp(rowData, 'status_before_deletion', 'new'),
                      }
                      DatabaseSetMergeDocument(DatabaseRef_IncidentReport_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  timestamp_incident: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_INCIDENT_DATE
      },
      header_sort_by: 'timestamp_incident',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseIncidentReportViewPage.url(rowData.key as string))
                }
              }}
            >
              {returnFormattedDate(rowData.timestamp_incident, 'D MMM YYYY h:mm a')}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  status: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_STATUS
      },
      header_sort_by: 'status',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>

        // Define status options with associated color, label, and icon dynamically
        const statusMap: { [key: string]: { color: 'default' | 'error' | 'warning' | 'success'; icon: string; label: JSX.Element } } = {
          'New': {
            color: 'error', // Red chip color for "New"
            icon: 'circle-exclamation', // Icon for new status
            label: <Typography>{s_NEW}</Typography>, // Wrapped string in Typography to be a JSX.Element
          },
          'Under Investigation': {
            color: 'warning', // Yellow chip color for "Under Investigation"
            icon: 'search', // Icon for investigation status
            label: <Typography>{rLIB('Under Investigation')}</Typography>, // Wrapped string in Typography
          },
          'Reviewed/Closed': {
            color: 'success', // Green chip color for "Reviewed/Closed"
            icon: 'check-circle', // Icon for closed status
            label: <Typography>{rLIB('Reviewed/Closed')}</Typography>, // Wrapped string in Typography
          },
          'Deleted': {
            color: 'default', // Default color for deleted
            icon: 'trash', // Trash icon for deleted status
            label: <Typography>{rLIB('Deleted')}</Typography>, // Wrapped string in Typography
          },
        }

        // Normalize the rowData status to match the keys in statusMap (e.g., trim and case-insensitive)
        const normalizedStatus = rowData?.status?.toString().trim()

        // Check if rowData has a valid status and that it's one of the expected status keys
        if (normalizedStatus && statusMap[normalizedStatus]) {
          const { color, icon, label } = statusMap[normalizedStatus]

          // Render the status as a Chip component with color, icon, and label
          cellJSX = (
            <Box>
              <Chip
                color={color} // Must be one of the predefined MUI color types
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <Icon
                      icon={icon}
                      className="tw-mr-2"
                    />
                    {label} {/* Display label as JSX.Element */}
                  </Box>
                }
              />
            </Box>
          )
        } else {
          // Fallback if no valid status is found
          cellJSX = <>{rowData.status}</>
        }

        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return '' // Add any additional CSS if needed
      },
    },
  },
  associated_users: {
    header: {
      header_jsx: () => rLIB('Employee Name'),
      header_sort_by: 'associated_users',
      header_css: () => 'width: 250px;', // Provide width as a string inside a function
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow) => {
        // Check if associated_users is an object (map)
        const associatedUsers = rowData.associated_users && typeof rowData.associated_users === 'object' ? rowData.associated_users : {}

        // Join the user names with commas
        const userNames = Object.values(associatedUsers).join(', ')

        return (
          <Box
            sx={{
              whiteSpace: 'nowrap', // Prevent line breaks
              overflow: 'hidden', // Hide overflow content
              textOverflow: 'ellipsis', // Show ellipsis for overflowing text
              maxWidth: '800px', // Set the max width for the cell content
            }}
          >
            {Object.keys(associatedUsers).length > 0 ? (
              <Typography variant="body1">{userNames}</Typography>
            ) : (
              <Typography
                variant="body1"
                className="tw-opacity-50"
              >
                {rLIB('No Employees')}
              </Typography>
            )}
          </Box>
        )
      },
    },
  },

  associated_vehicle_vin: TableCellBasic('associated_vehicle_vin', s_VEHICLE_VIN, 'associated_vehicle_vin'),
  incident_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_INCIDENT_TYPE
      },
      header_sort_by: 'incident_type',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>

        // Ensure that we have both rowData.incident_type and the incidentTypes from tableAdditionalData
        if (rowData != null && rowData.incident_type != null && tableAdditionalData.incidentTypes != null) {
          const incidentTypeKey = rowData.incident_type as string
          const incidentTypeData = tableAdditionalData.incidentTypes[incidentTypeKey]

          // Check if the incidentType exists in the state and render the data accordingly
          if (incidentTypeData) {
            const { incident_name, intensity, icon } = incidentTypeData

            // Determine the background color based on the intensity
            const backgroundColor = intensity === 'severe' ? 'error.main' : 'warning.main'

            cellJSX = (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: backgroundColor, // Background color based on intensity
                  borderRadius: '50px', // Make it round like a bubble
                  padding: '4px 12px', // Some padding inside the bubble
                  color: 'white', // Text color
                  fontWeight: 'bold',
                  maxWidth: 'fit-content', // Ensure the size adjusts to content
                }}
              >
                <Icon
                  icon={icon}
                  className="tw-mr-2"
                  sx={{ color: 'white' }} // Use sx to set the icon color
                />
                <Typography variant="body1">
                  {incident_name} {/* Render the incident name */}
                </Typography>
              </Box>
            )
          } else {
            // Fallback if no matching incidentType is found
            cellJSX = <>{rowData.incident_type}</>
          }
        } else {
          cellJSX = <>{rowData.incident_type}</> // Fallback in case of missing data
        }

        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return '' // Define any additional CSS rules here if needed
      },
    },
  },

  incident_location: TableCellBasic('incident_location', s_INCIDENT_LOCATION, 'incident_location'),
  cost_of_damage: {
    header: {
      header_jsx: () => rLIB('Cost Of Damage'),
      header_sort_by: 'cost_of_damage',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow) => {
        // Convert to a number if possible, otherwise return a fallback
        const costValue =
          typeof rowData.cost_of_damage === 'number'
            ? formatCurrency(rowData.cost_of_damage)
            : typeof rowData.cost_of_damage === 'string' && !isNaN(Number(rowData.cost_of_damage))
              ? formatCurrency(Number(rowData.cost_of_damage))
              : rLIB('N/A')

        return <>{costValue}</> // Return the formatted currency value or N/A
      },
    },
  },

  notes: TableCellBasic('notes', s_WHAT_HAPPENED, 'notes'),
}

const tableSettings_IncidentReports: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_incident',
  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

const tableSettings_IncidentTypes: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'incident_name',
  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

// Columns For incident types
const tableColumns_IncidentTypes: TsInterface_TableColumns = {
  manage: TableCellManage({
    edit: {
      icon: (
        <Icon
          type="solid"
          icon="pen"
        />
      ),
      label: <>{rLIB('Edit')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          // Open the edit dialog with prefilled data
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {
                  incident_name: rowData.incident_name || '', // Prefill name from incident_name field
                  incident_intensity: rowData.intensity || 'moderate', // Default intensity to 'moderate'
                  incident_icon: rowData.icon || 'car-burst', // Default icon to 'car-burst'
                },
                formInputs: {
                  incident_name: {
                    key: 'incident_name',
                    label: rLIB('Incident Type Name'),
                    input_type: 'text_basic',
                    required: true,
                    data_type: 'string',
                  },
                  incident_intensity: {
                    key: 'incident_intensity',
                    label: 'Incident Severity',
                    input_type: 'multiple_choice_radio',
                    required: false,
                    options: [
                      { key: 'moderate', value: 'Moderate' },
                      { key: 'severe', value: 'Severe' },
                    ],
                    data_type: 'string',
                  },
                  incident_icon: {
                    key: 'incident_icon',
                    label: 'Select Icon',
                    input_type: 'multiple_choice_radio',
                    required: false,
                    options: [
                      {
                        key: 'car-burst',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="car-burst"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                      {
                        key: 'cars',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="cars"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                      {
                        key: 'helmet-safety',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="helmet-safety"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                      {
                        key: 'user-police-tie',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="user-police-tie"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                      {
                        key: 'triangle-exclamation',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="triangle-exclamation"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                      {
                        key: 'circle-xmark',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="circle-xmark"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                      {
                        key: 'bandage',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="bandage"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                      {
                        key: 'fire',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="fire"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                      {
                        key: 'bolt',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="bolt"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                      {
                        key: 'truck-medical',
                        value: (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              icon="truck-medical"
                              className="tw-mr-2"
                            />
                          </Box>
                        ),
                      },
                    ],
                    data_type: 'string',
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    DatabaseSetMergeDocument(DatabaseRef_IncidentReportTypes_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), {
                      incident_name: formSubmittedData.incident_name, // Save updated name
                      intensity: formSubmittedData.incident_intensity, // Save updated intensity
                      icon: formSubmittedData.incident_icon, // Save updated icon
                    })
                      .then(() => resolve({ success: true }))
                      .catch(reject)
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'info',
                formDialogHeaderText: <>{rLIB('Edit Incident Type')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen"
                  />
                ),
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Incident Type'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this incident type?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  DatabaseSetMergeDocument(DatabaseRef_IncidentReportTypes_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), {
                    status: 'deleted',
                  })
                    .then(() => resolve({ success: true }))
                    .catch(reject)
                })
              },
            },
          })
        }
      },
    },
  }),

  incident_name: {
    header: {
      header_jsx: () => rLIB('Incident Type Name'),
      header_sort_by: 'incident_name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow) => {
        return <>{rowData.incident_name}</> // Display the incident type name from Firestore
      },
    },
  },
  intensity: {
    header: {
      header_jsx: () => rLIB('Severity'),
      header_sort_by: 'intensity',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow) => {
        let cellJSX = <></>

        if (rowData != null && rowData.intensity != null) {
          const intensity = rowData.intensity
          const backgroundColor = intensity === 'severe' ? 'error.main' : 'warning.main'

          cellJSX = (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: backgroundColor,
                borderRadius: '50px',
                padding: '4px 12px',
                color: 'white',
                fontWeight: 'bold',
                maxWidth: 'fit-content',
              }}
            >
              <Typography variant="body1">{intensity === 'severe' ? 'Severe' : 'Moderate'}</Typography>
            </Box>
          )
        } else {
          cellJSX = <>{rowData.intensity}</> // Fallback in case of missing data
        }

        return cellJSX
      },
    },
  },

  icon: {
    header: {
      header_jsx: () => rLIB('Icon'),
      header_sort_by: 'icon',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow) => {
        return (
          <Icon
            icon={typeof rowData.icon === 'string' && rowData.icon ? rowData.icon : 'default-icon'} // Fallback to default icon
            className="tw-mr-2"
          />
        )
      },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  const [us_incidentTypes, us_setIncidentTypes] = useState<TsInterface_UnspecifiedObject>({})

  // Hooks - useEffect

  useEffect(() => {
    document.title = se_INCIDENT_REPORTS
  }, [])

  //Get Incident Types
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    let status = 'active'
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setIncidentTypes(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllActiveIncidentTypes_Query(res_GCK.clientKey, status), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const tableDatabaseEndpoint_IncidentReports = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "active" } ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_incident', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_IncidentReports_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_IncidentTypes = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' }, // Filter for active incident types
    ]

    let orderByArray: TsInterface_OrderByArray = [{ prop: 'incident_name', desc: false }] // Sort by incident name, ascending
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}

    let limit = getProp(queryGenerationData, 'limit', 100) // Default limit is 100 unless specified

    return generateDatabaseQuery(
      DatabaseRef_ActiveIncidentTypes_Query(uc_RootData_ClientKey as string), // Pass the collection reference
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const createIncidentReport = (): void => {
    // Map the incident types based on the current state
    const mappedIncidentTypes = us_incidentTypes
      ? Object.values(us_incidentTypes).map((type) => ({
          key: type.key,
          value: type.incident_name,
          chipJSX: (
            <Box>
              <Chip
                color={type.intensity === 'severe' ? 'error' : 'warning'}
                label={
                  <Box>
                    <Icon
                      icon={type.icon}
                      className="tw-mr-2"
                    />
                    {type.incident_name}
                  </Box>
                }
              />
            </Box>
          ),
        }))
      : []

    // Define formInputs_IncidentReport dynamically with access to mappedIncidentTypes
    const formInputs_IncidentReport: TsInterface_FormInputs = {
      incident_type: {
        data_type: 'string',
        input_type: 'multiple_choice_radio',
        key: 'incident_type',
        label: s_INCIDENT_TYPE,
        required: true,
        options: us_incidentTypes
          ? Object.values(us_incidentTypes).map((type) => ({
              key: type.key,
              value: (
                <Chip
                  color={type.intensity === 'severe' ? 'error' : 'warning'}
                  label={
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Icon
                        icon={type.icon}
                        className="tw-mr-2"
                      />
                      {type.incident_name}
                    </Box>
                  }
                />
              ),
            }))
          : [],
      },

      associated_users: {
        data_type: 'string',
        key: 'associated_users',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: true,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
          // Ensure associated_users is treated as a map (object)
          const selectedUsers = formData['associated_users'] && typeof formData['associated_users'] === 'object' ? formData['associated_users'] : {}
          console.log('Selected Users:', selectedUsers) // Log selected users (map format)

          const rJSX_UserSearchResult = (
            option: TsInterface_UnspecifiedObject,
            searchInputValue: string | null,
            inputHooks: TsInterface_InputHooksObject,
            additionalSearchData: TsInterface_UnspecifiedObject,
          ): JSX.Element => {
            return (
              <Box
                sx={{ marginLeft: '8px', marginRight: '8px' }}
                onClick={() => {
                  // Add or update the user in the map
                  const updatedUsers: any = {
                    ...selectedUsers,
                    [option.id]: option.name, // Store user ID as key, name as value
                  }

                  // Update the form state with the updated map
                  formInputChange('associated_users', updatedUsers, true)
                  console.log('Updated Users (map):', updatedUsers) // Log updated users in map format

                  // Fetch vehicles for the selected user
                  if (formHooks.uc_RootData_ClientKey) {
                    console.log('Fetching vehicles for user:', option.id) // Log user ID for vehicle fetch
                    DatabaseGetCollection(DatabaseRef_VehiclesForSpecificUser_Query(formHooks.uc_RootData_ClientKey, option.id))
                      .then((res_DGC) => {
                        console.log('Vehicles fetched:', res_DGC.data) // Log fetched vehicles
                        if (formAdditionalData && typeof formAdditionalData === 'object') {
                          formAdditionalData.vehicles = {
                            ...(typeof formAdditionalData.vehicles === 'object' ? formAdditionalData.vehicles : {}),
                            [option.id]: res_DGC.data, // Store vehicles for this user
                          }
                          console.log('Updated formAdditionalData.vehicles:', formAdditionalData.vehicles) // Log formAdditionalData after vehicle update
                          formHooks.ur_forceRerender()
                        }
                      })
                      .catch((rej_DGC) => {
                        console.error('Error fetching vehicles:', rej_DGC) // Log error if fetching fails
                        formHooks.ur_forceRerender()
                      })
                  }
                }}
              >
                <Typography className="tw-cursor-pointer">{rJSX_HighlightedSearchString(searchInputValue, option.name)}</Typography>
              </Box>
            )
          }

          const rJSX_UserSelection = (): JSX.Element => {
            return (
              <Box>
                <Typography
                  variant="body1"
                  className="tw-opacity-70"
                >
                  {rLIB('Employees')}*
                </Typography>
                {/* Display the selected users from the map */}
                {Object.entries(selectedUsers).map(([key, name]: [string, string | number | boolean | TsInterface_FormData | null]) =>
                  typeof name === 'string' ? (
                    <Typography
                      variant="body1"
                      key={key}
                    >
                      <Box
                        component="span"
                        className="tw-font-bold"
                      >
                        {name}
                      </Box>
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      key={key}
                      className="tw-opacity-50"
                    >
                      {rLIB('Invalid Name')}
                    </Typography>
                  ),
                )}

                <SearchInput
                  clientKey={formHooks.uc_RootData_ClientKey}
                  searchIndexKey={'users'}
                  searchFilters={[]}
                  searchResultRenderer={rJSX_UserSearchResult}
                  additionalSearchData={{}}
                  defaultSearchValue={''}
                  sx={{ minWidth: '100%' }}
                />
              </Box>
            )
          }

          return <Box className="tw-mt-4 tw-mb-4">{rJSX_UserSelection()}</Box>
        },
      },
      associated_vehicle_key: {
        data_type: 'string',
        key: 'associated_vehicle_key',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: false,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
          let inputJSX = <></>
          console.log('FormAdditionalData:', formAdditionalData) // Log formAdditionalData to check vehicles

          const validUsersWithVehicles = Object.keys(formData.associated_users || {}).filter((userKey) => {
            const vehicles = formAdditionalData?.vehicles as Record<string, TsInterface_UnspecifiedObject> | undefined
            console.log('Checking vehicles for user:', userKey, 'Vehicles:', vehicles) // Log vehicle check for each user
            return vehicles && typeof vehicles[userKey] === 'object' && Object.keys(vehicles[userKey]).length > 0
          })

          console.log('Valid Users with Vehicles:', validUsersWithVehicles) // Log valid users with vehicles

          // Collect all vehicles from the selected users
          const allVehicles = validUsersWithVehicles.flatMap((userKey) => {
            const vehicles = formAdditionalData?.vehicles as Record<string, TsInterface_UnspecifiedObject> | undefined
            console.log('Checking vehicles for user:', userKey, 'Vehicles:', vehicles) // Added log for vehicle check

            // Ensure we access the actual vehicle data object and convert it into an array if needed
            const userVehicles = vehicles && vehicles[userKey] ? Object.values(vehicles[userKey]) : []
            console.log('User Vehicles:', userVehicles) // Log the vehicles for the current user
            return userVehicles
          })

          console.log('All Vehicles:', allVehicles) // Log all vehicles for rendering

          if (allVehicles.length > 0) {
            inputJSX = (
              <Box className="tw-mt-4 tw-mb-4">
                <Typography
                  variant="body1"
                  className="tw-opacity-70"
                >
                  {s_VEHICLE}*
                </Typography>
                <FormControl fullWidth>
                  <RadioGroup
                    onChange={(event, value) => {
                      if (value != null) {
                        let vehicleVin = null
                        const selectedVehicle = allVehicles.find((vehicle: any) => vehicle.key === value)
                        if (selectedVehicle) {
                          vehicleVin = selectedVehicle.vin
                        }
                        console.log('Selected Vehicle VIN:', vehicleVin) // Log selected VIN
                        formInputChange('associated_vehicle_key', value, true)
                        formInputChange('associated_vehicle_vin', vehicleVin, true)
                      }
                    }}
                    value={formData.associated_vehicle_key || ''}
                    sx={{ display: 'block' }}
                  >
                    {allVehicles.map((vehicle: any, vehicleIndex: number) => (
                      <FormControlLabel
                        key={vehicleIndex}
                        sx={{ display: 'flex' }}
                        control={<Radio />}
                        label={vehicle.vin}
                        value={vehicle.key}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            )
          } else {
            inputJSX = (
              <Box className="tw-mt-4 tw-mb-4">
                <Typography
                  variant="body1"
                  className="tw-opacity-70"
                >
                  {s_VEHICLE}*
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-mt-2"
                >
                  <Icon
                    icon="circle-exclamation"
                    className="tw-mr-2"
                    sx={{ color: themeVariables.warning_main }}
                  />
                  {s_NO_VEHICLES_FOR_SELECTED_DRIVER}
                </Typography>
              </Box>
            )
          }

          return inputJSX
        },
      },
      associated_vehicle_vin: {
        data_type: 'string',
        key: 'associated_vehicle_vin',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: false,
        renderCustomFormInput: () => <></>,
      },
      timestamp_incident: {
        key: 'timestamp_incident',
        label: s_INCIDENT_DATE,
        input_type: 'timestamp_datetime',
        required: true,
        data_type: 'string',
      },
      incident_location: {
        key: 'incident_location',
        label: s_INCIDENT_LOCATION,
        input_type: 'text_basic',
        required: true,
        data_type: 'string',
      },
      notes: {
        key: 'notes',
        label: s_WHAT_HAPPENED,
        input_type: 'text_multiline',
        required: true,
        data_type: 'string',
      },
      cost_of_damage: {
        key: 'cost_of_damage',
        label: 'Cost of Damage',
        input_type: 'text_basic',
        required: true,
        data_type: 'number',
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange) => {
          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value.replace(/[^0-9.]/g, '') // Remove non-numeric characters
            const formattedValue = `$${parseFloat(value || '0').toFixed(2)}` // Format as dollar amount
            formInputChange('cost_of_damage', formattedValue, true)
          }

          return (
            <TextField
              label="Cost of Damage"
              value={formData.cost_of_damage || ''}
              onChange={handleChange}
              fullWidth
            />
          )
        },
      },
    }

    // Set up the form dialog with the dynamically created formInputs_IncidentReport
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_IncidentReport,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'New'
                  formSubmittedData.timestamp_incident = new Date(formSubmittedData.timestamp_incident)
                  DatabaseAddDocument(DatabaseRef_IncidentReports_Collection(res_GCK.clientKey), formSubmittedData, true)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseIncidentReportViewPage.url(res_DSMD.key))
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({
                        display: true,
                        error: rej_DSMD.error,
                      })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: rej_GCK.error,
                  })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{s_CREATE_INCIDENT_REPORT}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_NewIncidentReportButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createIncidentReport()
        }}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {s_NEW_INCIDENT_REPORT}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_IncidentReportTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="tw-mt-2">
            <TableDatabase
              tableAdditionalData={{ incidentTypes: us_incidentTypes }}
              tableColumns={tableColumns_IncidentReports}
              tableDatabaseEndpoint={tableDatabaseEndpoint_IncidentReports}
              tableSettings={tableSettings_IncidentReports}
            />
          </Card>

          {/* <Json data={us_incidentTypes}></Json> */}
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_IncidentTypesTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="tw-mt-2">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_IncidentTypes}
              tableDatabaseEndpoint={tableDatabaseEndpoint_IncidentTypes}
              tableSettings={tableSettings_IncidentTypes}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const addIncidentType = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {
            incident_report_type_name: '',
            incident_intensity: 'moderate', // Default value
            incident_icon: 'car-burst', // Default icon
          },
          formInputs: {
            incident_report_type_name: {
              key: 'incident_report_type_name',
              label: rLIB('Incident Type Name'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
            incident_intensity: {
              key: 'incident_intensity',
              label: 'Incident Severity',
              input_type: 'multiple_choice_radio',

              options: [
                { key: 'moderate', value: 'Moderate (Display Yellow)' },
                { key: 'severe', value: 'Severe (Display Red)' },
              ],
              data_type: 'string',
            },
            incident_icon: {
              key: 'incident_icon',
              label: 'Select Icon',
              input_type: 'multiple_choice_radio',

              // Define options as an array of individual key-value pairs
              options: [
                {
                  key: 'car-burst',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="car-burst"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
                {
                  key: 'cars',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="cars"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
                {
                  key: 'helmet-safety',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="helmet-safety"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
                {
                  key: 'user-police-tie',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="user-police-tie"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
                {
                  key: 'triangle-exclamation',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="triangle-exclamation"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
                {
                  key: 'circle-xmark',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="circle-xmark"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
                {
                  key: 'bandage',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="bandage"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
                {
                  key: 'fire',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="fire"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
                {
                  key: 'bolt',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="bolt"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
                {
                  key: 'truck-medical',
                  value: (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Icon
                        icon="truck-medical"
                        className="tw-mr-2"
                      />
                    </Box>
                  ),
                },
              ],

              data_type: 'string',
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  const newType = {
                    key: formSubmittedData.incident_report_type_name.toLowerCase().replace(/\s/g, '_'),
                    incident_name: formSubmittedData.incident_report_type_name,
                    intensity: formSubmittedData.incident_intensity,
                    icon: formSubmittedData.incident_icon,
                    status: 'active',
                  }
                  DatabaseAddDocument(DatabaseRef_IncidentReportTypes_Collection(res_GCK.clientKey), newType, true)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Add Incident Type')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_AddIncidentReportTypesButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={addIncidentType}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('Add Incident Type')}
      </Button>
    )
  }

  const rJSX_IncidentTypesTab = (): JSX.Element => {
    let tabJSX = rJSX_IncidentTypesTable()

    return tabJSX
  }

  const rJSX_IncidentReportTabs = (): JSX.Element => {
    return (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'Incident_Reports',
            tabHeader: rLIB('Incident Reports'),
            tabContent: rJSX_IncidentReportTable(),
            tabButtons: [{ fullJSX: rJSX_NewIncidentReportButton(), minJSX: rJSX_NewIncidentReportButton(), sizeCutoff: 0 }],
          },
          {
            tabUrlKey: 'Incident_Types',
            tabHeader: rLIB('Incident Types'),
            tabContent: rJSX_IncidentTypesTab(),
            tabButtons: [{ fullJSX: rJSX_AddIncidentReportTypesButton(), minJSX: rJSX_AddIncidentReportTypesButton(), sizeCutoff: 0 }],
          },
        ]}
        tabsSettings={{
          baseUrl: ApplicationPages.AdminDatabaseIncidentReportsListPage.url(),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: 'Incident Reports',
        }}
      />
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_INCIDENT_REPORTS}
        pageKey={pageKey}
        content={rJSX_IncidentReportTabs()}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
