///////////////////////////////
// Imports
///////////////////////////////

import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons/icon'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { rLIB } from 'rfbp_core/localization/library'
import { dynamicSort, formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickNewWindowAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { downloadAdditionalWorkQuotePDF } from '../services/invoice_pdf_templates'
import {
  openAdditionalWorkInvoiceDialog,
  openApproveAdditionalWorkInvoiceDialog,
  openSendAdditionalWorkInvoiceEmailDialog,
} from '../services/invoice_progress_functions'
import { invoiceBillToOptions } from './task_invoice_mapping'

///////////////////////////////
// Variables
///////////////////////////////

const statusCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      const rJSX_TaskStatusIcon = (taskStatus: string): JSX.Element => {
        let iconJSX = <></>
        switch (taskStatus) {
          case 'unapproved':
            iconJSX = (
              <Icon
                icon="circle-pause"
                className="tw-mr-2"
                tooltip={rLIB('Pending Approval')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.warning_main }}
              />
            )
            break
          case 'approved':
            iconJSX = (
              <Icon
                icon="circle-play"
                className="tw-mr-2"
                tooltip={rLIB('Approved')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.success_main }}
              />
            )
            break
          case 'completed':
            iconJSX = (
              <Icon
                icon="circle-check"
                className="tw-mr-2"
                tooltip={rLIB('Tasks Completed but not yet billed')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.primary_main }}
              />
            )
            break
          case 'billed':
            iconJSX = (
              <Icon
                icon="circle-envelope"
                className="tw-mr-2"
                tooltip={rLIB('Billed')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.info_main }}
              />
            )
            break
          case 'paid':
            iconJSX = (
              <Icon
                icon="circle-dollar"
                className="tw-mr-2"
                tooltip={rLIB('Paid')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.success_main }}
              />
            )
            break
          case 'cancelled':
            iconJSX = (
              <Icon
                icon="circle-trash"
                className="tw-mr-2"
                tooltip={rLIB('Cancelled')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.error_main }}
              />
            )
            break
          default:
            iconJSX = (
              <Icon
                icon="circle-question"
                className="tw-mr-2"
                tooltip={rLIB('Unknown Status')}
                tooltipPlacement="right"
                sx={{ fontSize: '20px', color: themeVariables.gray_500 }}
              />
            )
            break
        }
        return iconJSX
      }
      let cellJSX = <Box>{rJSX_TaskStatusIcon(rowData.status as string)}</Box>
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <></>
    },
    header_sort_by: null,
  },
}

const billToCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (
        rowData.bill_to != null &&
        invoiceBillToOptions != null &&
        invoiceBillToOptions[rowData.bill_to as string] != null &&
        invoiceBillToOptions[rowData.bill_to as string].name != null
      ) {
        cellJSX = <>{invoiceBillToOptions[rowData.bill_to as string].name}</>
      } else {
        cellJSX = <>{rowData.bill_to}</>
      }
      return <>{cellJSX}</>
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Bill To') as JSX.Element
    },
    header_sort_by: null,
  },
}

const tasksCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      const rJSX_TaskStatusIcon = (taskStatus: string): JSX.Element => {
        let iconJSX = <></>
        switch (taskStatus) {
          case 'not_created':
            iconJSX = (
              <Icon
                icon="circle-pause"
                className="tw-mr-2"
                tooltip={rLIB('Task Not Created')}
                tooltipPlacement="left"
                sx={{ color: themeVariables.warning_main }}
              />
            )
            break
          case 'active':
            iconJSX = (
              <Icon
                icon="circle-play"
                className="tw-mr-2"
                tooltip={rLIB('Task Active')}
                tooltipPlacement="left"
                sx={{ color: themeVariables.info_main }}
              />
            )
            break
          case 'completed':
            iconJSX = (
              <Icon
                icon="circle-check"
                className="tw-mr-2"
                tooltip={rLIB('Task Completed')}
                tooltipPlacement="left"
                sx={{ color: themeVariables.success_main }}
              />
            )
            break
          case 'deleted':
            iconJSX = (
              <Icon
                icon="circle-trash"
                className="tw-mr-2"
                tooltip={rLIB('Task Deleted')}
                tooltipPlacement="left"
                sx={{ color: themeVariables.error_main }}
              />
            )
            break
          default:
            iconJSX = (
              <Icon
                icon="circle-question"
                className="tw-mr-2"
                tooltip={rLIB('Unknown Status')}
                tooltipPlacement="left"
                sx={{ color: themeVariables.gray_500 }}
              />
            )
            break
        }
        return iconJSX
      }
      let cellJSX = (
        <TableContainer>
          <Table size="small">
            <TableBody>
              {objectToArray(getProp(rowData, 'associated_tasks', {})).map((loopTask: TsInterface_UnspecifiedObject, index: number) => (
                <TableRow key={index}>
                  <TableCell sx={{ paddingLeft: '0px', borderBottom: '0px solid rgba(0,0,0,0)', paddingTop: '0px' }}>
                    {rJSX_TaskStatusIcon(loopTask.status)}
                    {loopTask.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Tasks') as JSX.Element
    },
    header_sort_by: null,
  },
}

const lineItemsCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = (
        <Box>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {objectToArray(getProp(rowData, 'line_items', {}))
                  .sort(dynamicSort('order', 'asc'))
                  .map((loopLineItem: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ paddingLeft: '0px', borderBottom: '0px solid rgba(0,0,0,0)', paddingTop: '0px' }}>
                        <Stack
                          direction="row"
                          spacing={2}
                        >
                          <Box>{loopLineItem.description}</Box>
                          <Box className="tw-ml-2 tw-opacity-30">{formatCurrency(loopLineItem.amount)}</Box>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Line Items') as JSX.Element
    },
    header_sort_by: null,
  },
}

const invoiceJobCodeCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (rowData.associated_project_job_code != null && rowData.associated_project_key != null) {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  onClickNewWindowAppNavigation(ApplicationPages.AdminActiveProjectViewPage.url(rowData.associated_project_key as string))
                }
              }}
            >
              {rowData.associated_project_job_code}
            </Box>
          </>
        )
      } else if (rowData.associated_project_key != null) {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.warning_main, '&:hover': { background: themeVariables.warning_dark } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  onClickNewWindowAppNavigation(ApplicationPages.AdminActiveProjectViewPage.url(rowData.associated_project_key as string))
                }
              }}
            >
              {rLIB('Missing')}
            </Box>
          </>
        )
      } else {
        cellJSX = (
          <>
            <Box className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block">{rLIB('Missing')}</Box>
          </>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Job Code')
    },
    header_sort_by: null,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_AdditionalWorkInvoices: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              openAdditionalWorkInvoiceDialog(res_GCK.clientKey, rowData.key as string, tableHooks.uc_setUserInterface_CustomDialogDisplay)
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
      },
    },
    approve: {
      icon: (
        <Icon
          type="solid"
          icon="circle-check"
        />
      ),
      label: <>{rLIB('Approve Quote')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              openApproveAdditionalWorkInvoiceDialog(
                res_GCK.clientKey,
                tableAdditionalData.pr_projectKey,
                rowData.key as string,
                tableHooks.uc_setUserInterface_CustomDialogDisplay,
              )
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
      },
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'unapproved',
            conditions: [],
          },
        ],
      },
    },
    bill: {
      icon: (
        <Icon
          type="solid"
          icon="paper-plane"
        />
      ),
      label: <>{rLIB('Email Invoice')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            openSendAdditionalWorkInvoiceEmailDialog(
              res_GCK.clientKey,
              tableAdditionalData.pr_projectKey,
              rowData.key as string,
              tableHooks.uc_setUserInterface_CustomDialogDisplay,
              tableHooks.uc_setUserInterface_ErrorDialogDisplay,
            )
          })
          .catch((rej_GCK) => {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      },
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'completed',
            conditions: [],
          },
        ],
      },
    },
    download: {
      icon: (
        <Icon
          type="solid"
          icon="file-pdf"
        />
      ),
      label: <>{rLIB('Download Quote')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            downloadAdditionalWorkQuotePDF(
              res_GCK.clientKey,
              tableAdditionalData.pr_projectKey,
              rowData.bill_to as string,
              rowData.invoice_id_number as string,
              getProp(rowData, 'line_items', []),
              'download',
            )
          })
          .catch((rej_GCK) => {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      },
    },
  }),
  status: statusCell,
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Billable Reference Number'), 'invoice_id_number'),
  bill_to: billToCell,
  total_price: TableCellCurrency('total_price', rLIB('Amount'), 'total_price'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Quote Generated'), 'timestamp_created', 'D MMM YYYY - h:mm a', false),
  tasks: tasksCell,
  line_items: lineItemsCell,
}

export const tableColumns_AdditionalWorkIndexInvoices: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              openAdditionalWorkInvoiceDialog(res_GCK.clientKey, rowData.key as string, tableHooks.uc_setUserInterface_CustomDialogDisplay)
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
      },
    },
    approve: {
      icon: (
        <Icon
          type="solid"
          icon="circle-check"
        />
      ),
      label: <>{rLIB('Approve Quote')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              openApproveAdditionalWorkInvoiceDialog(
                res_GCK.clientKey,
                tableAdditionalData.pr_projectKey,
                rowData.key as string,
                tableHooks.uc_setUserInterface_CustomDialogDisplay,
              )
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
      },
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'unapproved',
            conditions: [],
          },
        ],
      },
    },
    bill: {
      icon: (
        <Icon
          type="solid"
          icon="paper-plane"
        />
      ),
      label: <>{rLIB('Email Invoice')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            openSendAdditionalWorkInvoiceEmailDialog(
              res_GCK.clientKey,
              tableAdditionalData.pr_projectKey,
              rowData.key as string,
              tableHooks.uc_setUserInterface_CustomDialogDisplay,
              tableHooks.uc_setUserInterface_ErrorDialogDisplay,
            )
          })
          .catch((rej_GCK) => {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      },
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'completed',
            conditions: [],
          },
        ],
      },
    },
    download: {
      icon: (
        <Icon
          type="solid"
          icon="file-pdf"
        />
      ),
      label: <>{rLIB('Download Quote')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            downloadAdditionalWorkQuotePDF(
              res_GCK.clientKey,
              tableAdditionalData.pr_projectKey,
              rowData.bill_to as string,
              rowData.invoice_id_number as string,
              getProp(rowData, 'line_items', []),
              'download',
            )
          })
          .catch((rej_GCK) => {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      },
    },
  }),
  status: statusCell,
  associated_project_job_code: invoiceJobCodeCell,
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Billable Reference Number'), 'invoice_id_number'),
  bill_to: billToCell,
  total_price: TableCellCurrency('total_price', rLIB('Amount'), 'total_price'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Quote Generated'), 'timestamp_created', 'D MMM YYYY - h:mm a', false),
  tasks: tasksCell,
  line_items: lineItemsCell,
}

export const tableSettings_AdditionalWorkInvoices: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: false,
  sort_direction: 'asc',
  sort_property_default: 'invoice_id_number',
}

export const tableColumns_AdditionalWorkInvoicesBasic: TsInterface_TableColumns = {
  status: statusCell,
  invoice_id_number: TableCellBasic('invoice_id_number', rLIB('Billable Reference Number'), 'invoice_id_number'),
  bill_to: billToCell,
  total_price: TableCellCurrency('total_price', rLIB('Amount'), 'total_price'),
}

export const tableSettings_AdditionalWorkInvoicesBasic: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: false,
  sort_direction: 'asc',
  sort_property_default: 'invoice_id_number',
}

export const tableSettings_AdditionalWorkIndexInvoices: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'timestamp_created',
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  no_data_message: rLIB('No invoices') as JSX.Element,
  searchable: false,
  hide_pagination: false,
}
