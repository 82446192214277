///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material/'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { createScheduledReminder } from 'app/models/reminders/reminder_forms'
import { sendAppPushNotification } from 'app/services/external_requests/external_requests'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_UserUnreadMessageThreadsCollection_Query } from 'rfbp_aux/services/database_endpoints/operations/messages'
import {
  DatabaseRef_NotificationsUserRead_Query,
  DatabaseRef_NotificationsUserUnread_Query,
  DatabaseRef_Notification_Document,
} from 'rfbp_aux/services/database_endpoints/operations/notifications'
import {
  DatabaseRef_OpenReminders_Query,
  DatabaseRef_RemindersDateRange_Query,
  DatabaseRef_Reminder_Document,
} from 'rfbp_aux/services/database_endpoints/operations/reminders'
import { DatabaseRef_UsersByOrganizationType_Query } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl, TsInterface_TabsSettingsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import {
  dynamicSort,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnTimestampFromUnknownDateFormat,
} from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import {
  TsInterface_UnspecifiedObject,
  TsType_MuiComponentColors,
  TsType_MuiComponentColorsWithInherit,
  TsType_UnknownPromise,
  TsType_VoidFunction,
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AllAlertsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ALERTS: JSX.Element = <Trans>Alerts</Trans>
const s_CREATE_NEW_REMINDER: JSX.Element = <Trans>Create New Reminder</Trans>
const s_DATE: JSX.Element = <Trans>Date</Trans>
const s_DISMISS: JSX.Element = <Trans>Dismiss</Trans>
const s_END_DATE: JSX.Element = <Trans>End Date</Trans>
const s_GO_TO_PROJECT: JSX.Element = <Trans>Go to project</Trans>
const s_MARK_AS_UNREAD: JSX.Element = <Trans>Mark as Unread</Trans>
const s_NO_ACTIVE_REMINDERS: JSX.Element = <Trans>No Active Reminders</Trans>
const s_NO_READ_ALERTS_FOR: JSX.Element = <Trans>No read alerts for</Trans>
const s_NO_SCHEDULED_REMINDERS: JSX.Element = <Trans>No Scheduled Reminders</Trans>
const s_NO_UNREAD_ALERTS: JSX.Element = <Trans>No unread alerts</Trans>
const s_NO_UNREAD_MESSAGES: JSX.Element = <Trans>No unread messages</Trans>
const s_PAST_ALERTS: JSX.Element = <Trans>Past Alerts</Trans>
const s_SCHEDULED_REMINDERS: JSX.Element = <Trans>Scheduled Reminders</Trans>
const s_START_DATE: JSX.Element = <Trans>Start Date</Trans>
const s_UNREAD_ALERTS: JSX.Element = <Trans>Unread Alerts</Trans>
const s_UNREAD_MESSAGES: JSX.Element = <Trans>Unread Messages</Trans>
// { sort-end } - displayed text

// Table
const tabsSettings: TsInterface_TabsSettingsUrl = {
  baseUrl: ApplicationPages.AllAlertsListPage.url(),
  tabQueryParam: 'tab',
  overridePageTitle: true,
  basePageTitle: rLIB('Alerts', false) as string,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeUserReminders, us_setActiveUserReminders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_alertDate, us_setAlertDate] = useState<Date>(new Date())
  const [us_loadedOpenReminders, us_setLoadedOpenReminders] = useState<boolean>(false)
  const [us_loadedProjectMessages, us_setLoadedProjectMessages] = useState<boolean>(false)
  const [us_loadedQueriedReminders, us_setLoadedQueriedReminders] = useState<boolean>(false)
  const [us_loadedReadAlerts, us_setLoadedReadAlerts] = useState<boolean>(false)
  const [us_loadedUnreadAlerts, us_setLoadedUnreadAlerts] = useState<boolean>(false)
  const [us_notificationReloadTimestamp, us_setNotificationReloadTimestamp] = useState<number>(new Date().getTime())
  const [us_pastAlerts, us_setPastAlerts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_reminderEndDate, us_setReminderEndDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1))
  const [us_reminderStartDate, us_setReminderStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [us_scheduledUserReminders, us_setScheduledUserReminders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unreadAlerts, us_setUnreadAlerts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unreadProjectMessages, us_setUnreadProjectMessages] = useState<TsInterface_UnspecifiedObject>({})
  const [us_internalUsers, us_setInternalUsers] = useState<TsInterface_UnspecifiedObject>({})

  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)

  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  const [selectedRole, setSelectedRole] = useState<string>('') // State for the selected role
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]) // Track fully selected roles

  const [errorRecipients, setErrorRecipients] = useState<string>('')
  const [errorNotifications, setErrorNotifications] = useState<string>('')
  const [errorSubject, setErrorSubject] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  // State for the subject of the alert
  const [us_subject, setSubject] = useState<string>('')

  // State for the message body of the alert
  const [us_message, setMessage] = useState<string>('')

  // State for the recipients (selected employees, multiple)
  const [us_recipients, setRecipients] = useState<string[]>([])

  // State for managing which notifications are selected (App, SMS, Email)
  const [us_notifications, setNotifications] = useState({
    app: false,
    sms: false,
    email: false,
  })

  // { sort-end } - hooks

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnreadAlerts(newData)
      if (us_notificationReloadTimestamp > 0) {
        us_setLoadedUnreadAlerts(true)
      }
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_NotificationsUserUnread_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, 'timestamp_created', false, null, {}),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key, us_notificationReloadTimestamp])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setPastAlerts(newData)
      if (us_notificationReloadTimestamp > 0) {
        us_setLoadedReadAlerts(true)
      }
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      let cleanedAlertDate = returnDateFromUnknownDateFormat(us_alertDate)
      let startTimestamp = new Date(cleanedAlertDate.getFullYear(), cleanedAlertDate.getMonth(), cleanedAlertDate.getDate()).getTime()
      let endTimestamp = new Date(cleanedAlertDate.getFullYear(), cleanedAlertDate.getMonth(), cleanedAlertDate.getDate() + 1).getTime()
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_NotificationsUserRead_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, 'timestamp_created', false, null, {
              startAt: startTimestamp,
              endAt: endTimestamp,
            }),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key, us_notificationReloadTimestamp, us_alertDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnreadProjectMessages(newData)
      us_setLoadedProjectMessages(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_UserUnreadMessageThreadsCollection_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLoadedQueriedReminders(true)
      us_setScheduledUserReminders(newData)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_RemindersDateRange_Query(
              res_GCK.clientKey,
              uc_RootData_GlobalUser.key as string,
              new Date(us_reminderStartDate),
              new Date(us_reminderEndDate),
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key, us_reminderStartDate, us_reminderEndDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLoadedOpenReminders(true)
      us_setActiveUserReminders(newData)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      let cutoffTimestamp = new Date()
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_OpenReminders_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, cutoffTimestamp),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key])

  // Get internal users
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // Convert the object into an array and filter users by 'active' status
      const filteredData = Object.values(newData).filter((user: any) => user.status === 'active')

      // Sort the filtered data
      const sortedData = filteredData.sort((a: any, b: any) => {
        const nameA = a.name?.toLowerCase() || '' // Fallback to empty string if name is null/undefined
        const nameB = b.name?.toLowerCase() || ''
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0 // Ascending order
      })

      // Set sorted data in state
      us_setInternalUsers(sortedData)
      ur_forceRerender()
    }

    if (uc_RootData_GlobalUser.key != null) {
      const orgType = 'internal'
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UsersByOrganizationType_Query(res_GCK.clientKey, orgType), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key])

  // Functions
  const calendarDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setLoadedReadAlerts(false)
      us_setAlertDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  const changeNotificationStatus = (notifcationKey: string, status: string): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let updateObject = {
          status: status,
        }
        DatabaseSetMergeDocument(DatabaseRef_Notification_Document(res_GCK.clientKey, notifcationKey), updateObject)
          .then((res_DSMD) => {
            us_setNotificationReloadTimestamp(new Date().getTime())
            ur_forceRerender()
          })
          .catch((rej_DSMD) => {
            console.error(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const changeReminderStatus = (reminderKey: string, status: string): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let updateObject = {
          status: status,
        }
        DatabaseSetMergeDocument(DatabaseRef_Reminder_Document(res_GCK.clientKey, reminderKey), updateObject)
          .then((res_DSMD) => {
            ur_forceRerender()
          })
          .catch((rej_DSMD) => {
            console.error(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const calendarReminderStartDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setReminderStartDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  const calendarReminderEndDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setReminderEndDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  // JSX Generation
  const rJSX_Alert = (alert: TsInterface_UnspecifiedObject): JSX.Element => {
    let alertJSX = <></>
    let iconColor = themeVariables.primary_main
    let buttonColor: TsType_MuiComponentColorsWithInherit = 'primary'
    let alertIcon = <></>
    switch (alert.type) {
      case 'success':
        iconColor = themeVariables.success_main
        buttonColor = 'success'
        alertIcon = (
          <Icon
            icon="circle-check"
            type="solid"
            size="lg"
          />
        )
        break
      case 'info':
        iconColor = themeVariables.info_main
        buttonColor = 'info'
        alertIcon = (
          <Icon
            icon="square-info"
            type="solid"
            size="lg"
          />
        )
        break
      case 'warning':
        iconColor = themeVariables.warning_main
        buttonColor = 'warning'
        alertIcon = (
          <Icon
            icon="triangle-exclamation"
            type="solid"
            size="lg"
          />
        )
        break
      case 'error':
        iconColor = themeVariables.error_main
        buttonColor = 'error'
        alertIcon = (
          <Icon
            icon="siren-on"
            type="solid"
            size="lg"
          />
        )
        break
    }
    let actionIconJSX = <></>
    if (alert.status === 'unread') {
      actionIconJSX = (
        <Tooltip title={s_DISMISS}>
          <Box
            onClick={() => {
              changeNotificationStatus(alert.key, 'read')
            }}
          >
            <Icon
              icon="circle-x"
              type="solid"
              className="tw-cursor-pointer"
            ></Icon>
          </Box>
        </Tooltip>
      )
    } else {
      actionIconJSX = (
        <Tooltip title={s_MARK_AS_UNREAD}>
          <Box
            onClick={() => {
              changeNotificationStatus(alert.key, 'unread')
            }}
          >
            <Icon
              icon="bell-on"
              type="solid"
              className="tw-cursor-pointer"
            ></Icon>
          </Box>
        </Tooltip>
      )
    }
    let actionJSX = <></>
    if (alert != null && alert.associated_project_key) {
      actionJSX = (
        <Button
          variant="outlined"
          color={buttonColor}
          onClick={(event) => {
            // TODO - handle other user types probably
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(alert.associated_project_key))
          }}
        >
          {s_GO_TO_PROJECT}
        </Button>
      )
    }
    alertJSX = (
      <Box
        sx={{ background: themeVariables.background_paper, width: '90%', minWidth: '300px', maxWidth: '600px' }}
        className="tw-p-2 tw-rounded-lg tw-my-2 tw-mx-auto"
      >
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between"
        >
          <Box className="tw-text-left">
            <Box>
              <Box
                className="tw-inline-block tw-mr-3"
                sx={{ color: iconColor }}
              >
                {alertIcon}
              </Box>
              <Typography
                variant="body1"
                className="tw-inline-block tw-font-black"
              >
                {alert.title}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                className="tw-inline-block tw-ml-8"
              >
                {alert.text}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}>{actionIconJSX}</Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between tw-mt-2"
        >
          <Box className="tw-pl-8">{actionJSX}</Box>
          <Box
            className="tw-opacity-30"
            sx={{ alignSelf: 'flex-end' }}
          >
            <Typography variant="body1">
              {returnFormattedDate(alert.timestamp_created, 'h:mm a')} · {returnFormattedDate(alert.timestamp_created, 'MMM D, YYYY')}
            </Typography>
          </Box>
        </Stack>
      </Box>
    )
    return alertJSX
  }

  const rJSX_UnreadAlertsTab = (): JSX.Element => {
    let unreadAlertsTabJSX = <></>
    if (us_loadedUnreadAlerts === false) {
      unreadAlertsTabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      if (objectToArray(us_unreadAlerts).length === 0) {
        unreadAlertsTabJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_UNREAD_ALERTS}
            </Typography>
          </Box>
        )
      } else {
        unreadAlertsTabJSX = (
          <Box className="tw-text-center">
            {objectToArray(us_unreadAlerts)
              .sort(dynamicSort('timestamp_created', 'desc'))
              .map((alert, alertIndex) => (
                <Box
                  className="tw-text-center"
                  key={alertIndex}
                >
                  {rJSX_Alert(alert)}
                </Box>
              ))}
          </Box>
        )
      }
    }
    return unreadAlertsTabJSX
  }

  const rJSX_ReadAlertsTab = (): JSX.Element => {
    let unreadAlertsTabJSX = <></>
    if (us_loadedReadAlerts === false) {
      unreadAlertsTabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      if (objectToArray(us_pastAlerts).length === 0) {
        unreadAlertsTabJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_READ_ALERTS_FOR} {returnFormattedDate(us_alertDate, 'MMM D, YYYY')}
            </Typography>
          </Box>
        )
      } else {
        unreadAlertsTabJSX = (
          <Box className="tw-text-center">
            {objectToArray(us_pastAlerts)
              .sort(dynamicSort('timestamp_created', null))
              .map((alert, alertIndex) => (
                <Box
                  className="tw-text-center"
                  key={alertIndex}
                >
                  {rJSX_Alert(alert)}
                </Box>
              ))}
          </Box>
        )
      }
    }
    return unreadAlertsTabJSX
  }

  const sendCustomEmail = (
    recipientName: string, // The name of the email recipient
    recipientEmail: string, // The email address of the recipient
    subject: string, // Email subject
    messageBody: string, // Main body content of the email
    senderName: string, // The name of the user who sent the notification
  ) => {
    // Create the template object using the new structure
    const templateObject = {
      BODY_DIV: {
        element_type: 'div',
        style: {
          'box-sizing': 'border-box',
          'width': '100%',
          'background': '#eeeeee',
          'padding': '16px',
        },
        contents: {
          CARD_DIV: {
            element_type: 'div',
            style: {
              'max-width': '600px',
              'background': '#ffffff',
              'border-radius': '8px',
              'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
              'margin': '0 auto',
              'overflow': 'hidden',
            },
            contents: {
              HEADER_DIV: {
                element_type: 'div',
                style: {
                  'box-sizing': 'border-box',
                  'background': 'rgb(25, 30, 33)', // ETW's header background
                  'font-size': '20px',
                  'width': '100%',
                  'min-height': '70px',
                  'padding': '8px',
                  'text-align': 'center',
                },
                contents: {
                  LOGO_IMG: {
                    element_type: 'img',
                    src_type: 'static',
                    src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                    alt: 'logo.png',
                    width: '80px',
                    height: 'auto',
                  },
                },
              },
              CONTENT_DIV: {
                element_type: 'div',
                style: {
                  padding: '24px',
                },
                contents: {
                  GREETING: {
                    element_type: 'p',
                    text_type: 'static',
                    text: `Hi ${recipientName.split(' ')[0]},`, // First name greeting
                    style: {
                      'font-size': '16px',
                      'font-weight': 'bold',
                      'margin-bottom': '8px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  SENDER_INFO: {
                    element_type: 'p',
                    text_type: 'static',
                    text: `This is a notification sent to you from ${senderName}:`, // Sender's name
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '12px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  MESSAGE_BODY: {
                    element_type: 'p',
                    text_type: 'static',
                    text: messageBody, // The body of the message
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '12px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                },
              },
            },
          },
        },
      },
    } as TsInterface_EmailTemplateObject

    const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, {})

    // Send the email via a cloud function, using SendGrid or another service
    return cloudFunctionManageRequest('manageEmails', {
      function: 'sendSendgridHtmlEmail',
      to: [recipientEmail],
      subject: subject, // Dynamic subject passed into the function
      html: htmlContent, // Generated HTML content from the template
      cc: [],
      bcc: [],
    })
  }

  const formatPhoneNumberForTwilio = (phoneNumber: string): string => {
    // Remove all non-numeric characters from the input
    const numericOnly = phoneNumber.replace(/\D/g, '')

    // Ensure the number is 10 digits long (US format without country code)
    if (numericOnly.length === 10) {
      return `+1${numericOnly}` // Format for Twilio
    } else if (numericOnly.length === 11 && numericOnly.startsWith('1')) {
      return `+${numericOnly}` // If already has '1', keep it and prepend '+'
    } else {
      return 'Invalid phone number' // Handle invalid numbers
    }
  }

  const resetForm = () => {
    // Reset the form fields after successful submission
    setSubject('') // Reset subject
    setMessage('') // Reset message
    setRecipients([]) // Clear recipients
    setNotifications({
      app: false,
      sms: false,
      email: false,
    }) // Reset notifications
  }

  const rJSX_NewAlertTab = (): JSX.Element => {
    // Function to handle form submission
    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault()

      // Reset error messages
      setErrorRecipients('')
      setErrorNotifications('')
      setErrorSubject('')
      setErrorMessage('')

      // Validation checks
      if (us_recipients.length === 0) {
        setErrorRecipients('Please select at least one recipient.')
        return
      }

      if (!us_notifications.app && !us_notifications.sms && !us_notifications.email) {
        setErrorNotifications('Please select at least one notification method (App, SMS, or Email).')
        return
      }

      if (!us_subject.trim()) {
        setErrorSubject('Subject cannot be empty.')
        return
      }

      if (!us_message.trim()) {
        setErrorMessage('Message body cannot be empty.')
        return
      }

      const recipientKeys = internalUsersArray.filter((user) => us_recipients.includes(user.name)).map((user) => user.key)
      const recipientEmails = internalUsersArray.filter((user) => us_recipients.includes(user.name)).map((user) => user.email)
      const recipientPhones = internalUsersArray.filter((user) => us_recipients.includes(user.name)).map((user) => formatPhoneNumberForTwilio(user.phone))

      if (us_notifications.app) {
        sendAppPushNotification(recipientKeys, 'alert', us_subject, us_message, getProp(uc_RootData_ClientUser, 'key', null), null, null, {}, false)
          .then((res_CFMMR) => {
            resetForm()
          })
          .catch((err_CFMMR) => {
            console.error('Failed to send App Push Notification:', err_CFMMR)
          })
      }

      if (us_notifications.sms) {
        recipientPhones.forEach((phone) => {
          cloudFunctionManageRequest('manageEmails', {
            function: 'sendTwilioSMS',
            to: phone,
            body: `${us_subject} - ${us_message}`,
          })
            .then((res_SMS) => {
              resetForm()
            })
            .catch((err_SMS) => {
              console.error('Failed to send SMS:', err_SMS)
            })
        })
      }

      if (us_notifications.email) {
        const senderName = getProp(uc_RootData_ClientUser, 'name', 'System') // Get the sender's name from user data

        internalUsersArray
          .filter((user) => us_recipients.includes(user.name)) // Filter users based on selected recipients
          .forEach((user) => {
            sendCustomEmail(
              user.name, // The recipient's actual name
              user.email, // The recipient's email
              us_subject, // The subject of the email
              us_message, // The message body of the email
              senderName, // The sender's name retrieved dynamically from the logged-in user's data
            )
              .then((res_CFMMR) => {
                resetForm()
              })
              .catch((err_CFMMR) => {
                console.error('Failed to send email:', err_CFMMR)
              })
          })
      }
    }

    const extractTrueTaskRoles = (taskRoles: { [key: string]: boolean }): string[] => {
      return Object.keys(taskRoles).filter((role) => taskRoles[role] === true)
    }

    const handleNotificationChange = (name: keyof typeof us_notifications) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setNotifications({
        ...us_notifications,
        [name]: event.target.checked,
      })
    }

    const handleSelectRecipient = (recipient: string) => {
      if (us_recipients.includes(recipient)) {
        setRecipients(us_recipients.filter((r) => r !== recipient))
      } else {
        setRecipients([...us_recipients, recipient])
      }
    }

    const handleRemoveRecipient = (recipient: string) => {
      setRecipients(us_recipients.filter((r) => r !== recipient))
    }

    const handleResetRecipients = () => {
      setRecipients([]) // Clear the recipients
      setSelectedRole('') // Clear the selected role
    }

    const handleSelectAllRecipients = () => {
      const allUsers = internalUsersArray.map((user) => user.name)
      setRecipients(allUsers)
    }

    const handleToggleRoleSelection = (role: string) => {
      const usersByRole = internalUsersArray.filter((user) => extractTrueTaskRoles(user.task_roles).includes(role)).map((user) => user.name)

      if (selectedRole === role) {
        setRecipients(us_recipients.filter((recipient) => !usersByRole.includes(recipient)))
        setSelectedRole('') // Clear selected role
      } else {
        setRecipients(usersByRole)
        setSelectedRole(role) // Set the newly selected role
      }
    }

    const internalUsersArray = Object.values(us_internalUsers)

    const formatUserRole = (role: string) => {
      return role
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }

    const uniqueRoles = Array.from(new Set(internalUsersArray.flatMap((user) => extractTrueTaskRoles(user.task_roles)))).sort()

    // JSX content for the tab
    let tabJSX = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          display="flex"
          justifyContent="center"
        >
          <Card
            className="tw-p-4 tw-mt-4"
            sx={{ width: '100%', maxWidth: '1300px' }}
          >
            <Box>
              <form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <Typography
                    variant="h6"
                    gutterBottom
                  >
                    Alert Details
                  </Typography>
                  <Card className="tw-p-3 tw-mb-4">
                    <Box mb={2}>
                      <TextField
                        label="Subject"
                        value={us_subject}
                        onChange={(e) => setSubject(e.target.value)}
                        fullWidth
                        required
                        error={!!errorSubject} // Display error styling
                        helperText={errorSubject} // Display error message
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        label="Message"
                        value={us_message}
                        onChange={(e) => setMessage(e.target.value)}
                        multiline
                        rows={4}
                        fullWidth
                        required
                        error={!!errorMessage} // Display error styling
                        helperText={errorMessage} // Display error message
                      />
                    </Box>

                    {us_recipients.length > 0 && (
                      <Box mb={2}>
                        <Typography
                          variant="h6"
                          gutterBottom
                        >
                          Selected Recipients
                        </Typography>
                        <Card className="tw-p-3 tw-mb-4">
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            gap={1}
                          >
                            {us_recipients.map((recipient) => (
                              <Chip
                                key={recipient}
                                label={recipient}
                                onDelete={() => handleRemoveRecipient(recipient)}
                                sx={{
                                  'backgroundColor': '#004ba0',
                                  'color': 'white',
                                  '& .MuiChip-deleteIcon': { color: 'white' },
                                }}
                              />
                            ))}
                          </Box>
                        </Card>
                      </Box>
                    )}

                    {errorRecipients && (
                      <Box mb={2}>
                        <Typography
                          variant="body2"
                          color="error"
                        >
                          {errorRecipients}
                        </Typography>
                      </Box>
                    )}

                    <Box mb={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={us_notifications.app}
                            onChange={handleNotificationChange('app')}
                            color="primary"
                          />
                        }
                        label="App/Desktop Notification"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={us_notifications.sms}
                            onChange={handleNotificationChange('sms')}
                            color="primary"
                          />
                        }
                        label="SMS Notification"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={us_notifications.email}
                            onChange={handleNotificationChange('email')}
                            color="primary"
                          />
                        }
                        label="Email Notification"
                      />

                      {errorNotifications && (
                        <Box mb={2}>
                          <Typography
                            variant="body2"
                            color="error"
                          >
                            {errorNotifications}
                          </Typography>
                        </Box>
                      )}

                      <Box
                        display="flex"
                        justifyContent="flex-start"
                      >
                        <Button
                          variant="contained"
                          color="success"
                          type="submit"
                        >
                          Send
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Box>

                <Box
                  mb={2}
                  display="flex"
                  justifyContent="flex-start"
                  gap={2}
                  mt={1}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSelectAllRecipients}
                  >
                    Select All
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleResetRecipients}
                  >
                    Reset Recipients
                  </Button>

                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: 120 }}
                  >
                    <InputLabel>Select by Role</InputLabel>
                    <Select
                      value={selectedRole}
                      onChange={(e) => {
                        const selected = e.target.value as string
                        setSelectedRole(selected)
                        handleToggleRoleSelection(selected)
                      }}
                      label="Select by Role"
                    >
                      {uniqueRoles.map((role) => (
                        <MenuItem
                          key={role}
                          value={role}
                          sx={{
                            fontWeight: selectedRole === role ? 'bold' : 'normal',
                            color: selectedRole === role ? '#1976d2' : 'inherit',
                          }}
                        >
                          {formatUserRole(role)}
                          {selectedRole === role && ' ✓'}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  mb={2}
                  display="flex"
                  justifyContent="center"
                >
                  <Card
                    className="tw-p-3 tw-mb-4"
                    sx={{ width: '100%' }}
                  >
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      gap={2}
                    >
                      {internalUsersArray.map((user) => (
                        <Box
                          key={user.key}
                          display="flex"
                          alignItems="center"
                          sx={{
                            'width': '250px',
                            'padding': '8px',
                            'border': '1px solid #e0e0e0',
                            'borderRadius': '8px',
                            'cursor': 'pointer',
                            'backgroundColor': us_recipients.includes(user.name) ? '#1976d2' : 'transparent',
                            'color': us_recipients.includes(user.name) ? 'white' : 'black',
                            '&:hover': {
                              backgroundColor: '#004ba0',
                            },
                          }}
                          onClick={() => handleSelectRecipient(user.name)}
                        >
                          <Avatar
                            alt={user.name || 'Unknown'}
                            src={user.profile_photo_url}
                            sx={{ width: 48, height: 48, marginRight: '16px' }}
                          />

                          <Box
                            display="flex"
                            flexDirection="column"
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 'bold', color: 'white' }}
                            >
                              {user.name || 'Unknown'}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{ color: themeVariables.primary_light }}
                            >
                              {formatUserRole(user.user_role)}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Card>
                </Box>
              </form>
            </Box>
          </Card>
        </Box>
      </LocalizationProvider>
    )

    return tabJSX
  }

  const rJSX_UnreadMessage = (message: TsInterface_UnspecifiedObject): JSX.Element => {
    let messageJSX = <></>
    let messageIconJSX = <></>
    let threadType: string | null = null
    let projectKey: string | null = null
    if (message != null) {
      if (message.key != null) {
        let messageThreadKey = message.key
        let lastUnderscore = messageThreadKey.lastIndexOf('_')
        projectKey = messageThreadKey.substring(0, lastUnderscore)
        threadType = messageThreadKey.substring(lastUnderscore + 1, messageThreadKey.length)
      }
      let buttonColor: TsType_MuiComponentColors = undefined
      if (threadType === 'internal1') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.primary_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'primary'
      }
      if (threadType === 'internal2') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.primary_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'primary'
      }
      if (threadType === 'internal3') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.primary_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'primary'
      } else if (threadType === 'sales') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.success_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'success'
      } else if (threadType === 'customer') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.warning_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'warning'
      }
      let actionJSX = <></>
      if (projectKey != null) {
        actionJSX = (
          <Button
            variant="outlined"
            color={buttonColor}
            onClick={(event) => {
              if (projectKey != null) {
                // TODO - handle other user types probably
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(projectKey))
              }
            }}
          >
            {s_GO_TO_PROJECT}
          </Button>
        )
      }
      // Full Message
      messageJSX = (
        <Box
          sx={{ background: themeVariables.background_paper, width: '90%', minWidth: '300px', maxWidth: '600px' }}
          className="tw-p-2 tw-rounded-lg tw-my-2 tw-mx-auto"
        >
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Box className="tw-text-left">
              <Box>
                <Box className="tw-inline-block tw-mr-3">{messageIconJSX}</Box>
                <Typography
                  variant="body1"
                  className="tw-inline-block tw-font-black"
                >
                  {getProp(message, 'last_message_sender_name', '')}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-inline-block tw-italic tw-opacity-30 tw-ml-2"
                >
                  ({getProp(message, 'thread_name_override_desktop', '')})
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  className="tw-inline-block tw-ml-8"
                >
                  {getProp(message, 'last_message_text', '')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}>{/* { actionIconJSX } */}</Box>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between tw-mt-2"
          >
            <Box className="tw-pl-8">{actionJSX}</Box>
            <Box
              className="tw-opacity-30"
              sx={{ alignSelf: 'flex-end' }}
            >
              <Typography variant="body1">
                {returnFormattedDate(message.last_message_timestamp, 'h:mm a')} · {returnFormattedDate(message.last_message_timestamp, 'MMM D, YYYY')}
              </Typography>
            </Box>
          </Stack>
        </Box>
      )
    }
    return messageJSX
  }

  const rJSX_UnreadMessagesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedProjectMessages === false) {
      tabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      if (objectToArray(us_unreadProjectMessages).length === 0) {
        tabJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_UNREAD_MESSAGES}
            </Typography>
          </Box>
        )
      } else {
        tabJSX = (
          <Box>
            {objectToArray(us_unreadProjectMessages)
              .sort(dynamicSort('last_message_timestamp', 'desc'))
              .map((message, messageIndex) => (
                <Box
                  className="tw-text-center"
                  key={messageIndex}
                >
                  {rJSX_UnreadMessage(message)}
                </Box>
              ))}
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_Reminder = (reminder: TsInterface_UnspecifiedObject): JSX.Element => {
    let reminderJSX = <></>
    let iconColor = themeVariables.primary_main
    let buttonColor: TsType_MuiComponentColorsWithInherit = 'primary'
    let reminderIcon = <></>
    let cutoffDate = new Date().getTime()
    if (reminder.status === 'read') {
      iconColor = themeVariables.info_main
      buttonColor = 'info'
      reminderIcon = (
        <Icon
          icon="circle-check"
          type="solid"
          size="lg"
        />
      )
    } else if (cutoffDate < returnTimestampFromUnknownDateFormat(reminder.timestamp_reminder)) {
      iconColor = themeVariables.warning_main
      buttonColor = 'warning'
      reminderIcon = (
        <Icon
          icon="clock"
          type="solid"
          size="lg"
        />
      )
    } else {
      iconColor = themeVariables.error_main
      buttonColor = 'error'
      reminderIcon = (
        <Icon
          icon="alarm-clock"
          type="solid"
          size="lg"
        />
      )
    }
    let actionIconJSX = <></>
    if (reminder.status === 'unread') {
      actionIconJSX = (
        <Tooltip title={s_DISMISS}>
          <Box
            onClick={() => {
              changeReminderStatus(reminder.key, 'read')
            }}
          >
            <Icon
              icon="circle-x"
              type="solid"
              className="tw-cursor-pointer"
            ></Icon>
          </Box>
        </Tooltip>
      )
    } else {
      actionIconJSX = (
        <Tooltip title={s_MARK_AS_UNREAD}>
          <Box
            onClick={() => {
              changeReminderStatus(reminder.key, 'unread')
            }}
          >
            <Icon
              icon="bell-on"
              type="solid"
              className="tw-cursor-pointer"
            ></Icon>
          </Box>
        </Tooltip>
      )
    }
    let actionJSX = <></>
    if (reminder != null && reminder.associated_project_key) {
      actionJSX = (
        <Button
          variant="outlined"
          color={buttonColor}
          onClick={(event) => {
            // TODO - handle other user types probably
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(reminder.associated_project_key))
          }}
        >
          {s_GO_TO_PROJECT}
        </Button>
      )
    }
    reminderJSX = (
      <Box
        sx={{ background: themeVariables.background_paper, width: '90%', minWidth: '300px', maxWidth: '600px' }}
        className="tw-p-2 tw-rounded-lg tw-my-2 tw-mx-auto"
      >
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between"
        >
          <Box className="tw-text-left">
            <Box>
              <Box
                className="tw-inline-block tw-mr-3"
                sx={{ color: iconColor }}
              >
                {reminderIcon}
              </Box>
              <Typography
                variant="body1"
                className="tw-inline-block tw-font-black"
              >
                {reminder.title}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                className="tw-inline-block tw-ml-8"
              >
                {reminder.text}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}>{actionIconJSX}</Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between tw-mt-2"
        >
          <Box className="tw-pl-8">{actionJSX}</Box>
          <Box
            className="tw-opacity-30"
            sx={{ alignSelf: 'flex-end' }}
          >
            <Typography variant="body1">
              {returnFormattedDate(reminder.timestamp_reminder, 'h:mm a')} · {returnFormattedDate(reminder.timestamp_reminder, 'MMM D, YYYY')}
            </Typography>
          </Box>
        </Stack>
      </Box>
    )
    return reminderJSX
  }

  const rJSX_ActiveRemindersTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedOpenReminders === false) {
      tabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      if (objectToArray(us_activeUserReminders).length === 0) {
        tabJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_ACTIVE_REMINDERS}
            </Typography>
          </Box>
        )
      } else {
        tabJSX = (
          <Box>
            <Box className="tw-text-center">
              {objectToArray(us_activeUserReminders)
                .sort(dynamicSort('timestamp_reminder', 'asc'))
                .map((reminder, reminderIndex) => (
                  <Box
                    className="tw-text-center"
                    key={reminderIndex}
                  >
                    {rJSX_Reminder(reminder)}
                  </Box>
                ))}
            </Box>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_ScheduledRemindersTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedQueriedReminders === false) {
      tabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      let tabContentJSX = <></>
      if (objectToArray(us_scheduledUserReminders).length === 0) {
        tabContentJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_SCHEDULED_REMINDERS}
            </Typography>
          </Box>
        )
      } else {
        tabContentJSX = (
          <Box>
            <Box className="tw-text-center">
              {objectToArray(us_scheduledUserReminders)
                .sort(dynamicSort('timestamp_reminder', 'asc'))
                .map((reminder, reminderIndex) => (
                  <Box
                    className="tw-text-center"
                    key={reminderIndex}
                  >
                    {rJSX_Reminder(reminder)}
                  </Box>
                ))}
            </Box>
          </Box>
        )
      }
      tabJSX = (
        <Box>
          <Box className="tw-mb-2">
            <Stack
              direction="row"
              spacing={1}
            >
              <DatePicker
                key={'start_date'}
                datePickerText={s_START_DATE}
                datePickerDate={us_reminderStartDate}
                datePickerDisabled={false}
                datePickerDateOnChange={calendarReminderStartDateOnChange}
              />
              <DatePicker
                key={'end_date'}
                datePickerText={s_END_DATE}
                datePickerDate={us_reminderEndDate}
                datePickerDisabled={false}
                datePickerDateOnChange={calendarReminderEndDateOnChange}
              />
              <Button
                className=""
                color="success"
                variant="contained"
                startIcon={<Icon icon="circle-plus"></Icon>}
                onClick={() => {
                  createScheduledReminder(uc_setUserInterface_FormDialogDisplay, uc_RootData_GlobalUser, null, uc_RootData_ClientKey, uc_setRootData_ClientKey)
                }}
              >
                {s_CREATE_NEW_REMINDER}
              </Button>
            </Stack>
          </Box>
          <Box className="tw-text-center tw-p-2">{tabContentJSX}</Box>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_ALERTS}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Unread_Messages',
                  tabHeader: (
                    <>
                      <Badge
                        badgeContent={objectToArray(us_unreadProjectMessages).length}
                        color="error"
                      >
                        {s_UNREAD_MESSAGES}
                      </Badge>
                    </>
                  ),
                  tabContent: <Box className="tw-text-center tw-p-2">{rJSX_UnreadMessagesTab()}</Box>,
                },
                {
                  tabUrlKey: 'Unread_Alerts',
                  tabHeader: (
                    <>
                      <Badge
                        badgeContent={objectToArray(us_unreadAlerts).length}
                        color="error"
                      >
                        {s_UNREAD_ALERTS}
                      </Badge>
                    </>
                  ),
                  tabContent: <Box className="tw-text-center tw-p-2">{rJSX_UnreadAlertsTab()}</Box>,
                },
                {
                  tabUrlKey: 'Past_Alerts',
                  tabHeader: s_PAST_ALERTS,
                  tabContent: (
                    <Box className="tw-text-center tw-p-2">
                      <Box className="tw-text-left">
                        <DatePicker
                          key={'alert_date'}
                          datePickerText={s_DATE}
                          datePickerDate={us_alertDate}
                          datePickerDisabled={false}
                          datePickerDateOnChange={calendarDateOnChange}
                        />
                      </Box>
                      {rJSX_ReadAlertsTab()}
                    </Box>
                  ),
                },

                // Conditionally add the New Alert tab if the user is an admin
                ...(getProp(uc_RootData_ClientUser, 'user_role', '') === 'admin'
                  ? [
                      {
                        tabUrlKey: 'New_Alerts',
                        tabHeader: rLIB('New Alert'),
                        tabContent: (
                          <Box className="tw-text-center tw-p-2">
                            <Box className="tw-text-left"></Box>
                            {rJSX_NewAlertTab()}
                          </Box>
                        ),
                      },
                    ]
                  : []),
                {
                  tabUrlKey: 'Active_Reminders',
                  tabHeader: (
                    <>
                      <Badge
                        badgeContent={objectToArray(us_activeUserReminders).length}
                        color="error"
                      >
                        {rLIB('Active Reminders')}
                      </Badge>
                    </>
                  ),
                  tabContent: <Box className="tw-text-center tw-p-2">{rJSX_ActiveRemindersTab()}</Box>,
                },
                {
                  tabUrlKey: 'Scheduled_Reminders',
                  tabHeader: s_SCHEDULED_REMINDERS,
                  tabContent: <Box className="tw-text-center tw-p-2">{rJSX_ScheduledRemindersTab()}</Box>,
                },
              ]}
              tabsSettings={tabsSettings}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
