///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Dialog, DialogContent, IconButton, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material/'
import { useContext, useEffect, useState } from 'react'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_CustomDialog, UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data

const utilityMap = new Map([
  ['Ameren', 'Group A'],
  ['ComEd', 'Group B'],
  ['Tri-County Electric Coop, Inc (TCEC)- IL', 'Group A'],
  ['Village of Chatham', 'Group A'],
  ['City Water Light and Power (CWLP)', 'Group A'],
  ['Rural Electric Conven Coop (RECC)', 'Group A'],
  ['City of Highland (COH)', 'Group A'],
  ['Menard Electric Coop (MEC)', 'Group A'],
  ['Wayne-White Counties Elec Coop (WWCEC)', 'Group A'],
  ['Corn Belt', 'Group A'],
  ['Southwestern Electric Coop Inc (SEC)', 'Group A'],
  ['Southern Illinois Elec Coop (SIEC)', 'Group A'],
  ['Rock Energy Cooperative (REC)', 'Group B'],
  ['Shelby Electric Coop, Inc (SEC)', 'Group A'],
  ['City of Batavia (COB)', 'Group B'],
  ['Cairo Public Utility Company', 'Group A'],
  ['City of Allendale', 'Group A'],
  ['City of Altamont', 'Group A'],
  ['City of Breese', 'Group A'],
  ['City of Bushnell', 'Group A'],
  ['City of Carlyle', 'Group A'],
  ['City of Carmi', 'Group A'],
  ['City of Casey', 'Group A'],
  ['City of Fairfield', 'Group A'],
  ['City of Farmer City', 'Group A'],
  ['City of Flora', 'Group A'],
  ['City of Geneseo', 'Group A'],
  ['City of Geneva', 'Group B'],
  ['City of Highland', 'Group A'],
  ['City of Marshall', 'Group A'],
  ['City of Mascoutah', 'Group A'],
  ['City of Metropolis', 'Group A'],
  ['City of Naperville', 'Group B'],
  ['City of Oglesby', 'Group A'],
  ['City of Peru', 'Group A'],
  ['City of Princeton', 'Group A'],
  ['City of Red Bud', 'Group A'],
  ['City of Rock Falls', 'Group B'],
  ['City of Roodhouse', 'Group A'],
  ['City of Springfield', 'Group A'],
  ['City of St Charles', 'Group B'],
  ['City of Sullivan', 'Group A'],
  ['City of Waterloo', 'Group A'],
  ['Clay Electric Coop Inc', 'Group A'],
  ['Village of Bethany', 'Group A'],
  ['Village of Freeburg', 'Group A'],
  ['Village of Ladd', 'Group A'],
  ['Village of Rantoul', 'Group A'],
  ['Village of Riverton', 'Group A'],
  ['Village of Winnetka', 'Group B'],
])

///////////////////////////////
// Functions
///////////////////////////////
// Format the results as dollar amounts with commas
const formatDollar = (amount: number) => {
  return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const [us_size, us_setSize] = useState<string>('')
  const [us_production, us_setProduction] = useState<string>('')
  const [us_selectedUtility, us_setSelectedUtility] = useState<string>('')
  const [us_results, us_setResults] = useState<{ abpFee: number; srecCollateral: number; upfrontFee: string; srecPayment: string; calculated: boolean }>({
    abpFee: 0,
    srecCollateral: 0,
    upfrontFee: '',
    srecPayment: '',
    calculated: false,
  })
  const [us_sizeError, us_setSizeError] = useState<boolean>(false)
  const [us_productionError, us_setProductionError] = useState<boolean>(false)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('SREC Calculator', false) as string
  }, [])

  useEffect(() => {
    if (us_size === '' || us_production === '' || us_selectedUtility === '' || us_sizeError || us_productionError) {
      us_setResults({ abpFee: 0, srecCollateral: 0, upfrontFee: '', srecPayment: '', calculated: false })
      return
    }
    const sizeNumber = parseFloat(us_size)
    const productionNumber = parseFloat(us_production)

    if (sizeNumber >= 3.0 && sizeNumber <= 25.0) {
      us_setSizeError(false)
    }

    if (productionNumber >= 3000 && productionNumber <= 30000) {
      us_setProductionError(false)
    }

    // Use sizeNumber and productionNumber instead of size and production in your calculations
    let bracket = ''
    let srecUtility = 0
    let abpFee = 0
    let srecPayout = 0
    let srecCollateral = 0
    let numSrecs = 0
    let kWac = 0
    let totalScrecs = 0
    let degredation = 0.0085

    kWac = Math.floor(sizeNumber / 1.4 / 3.8 + 1) * 3.8

    numSrecs = Math.floor((productionNumber * (1 - (1 - degredation) ** 15)) / degredation / 1000)

    if (kWac >= 0 && kWac < 10.001) {
      bracket = 'Under 10 kW'
    } else if (kWac >= 10.001 && kWac < 25.001) {
      bracket = '10 - 25 kW'
    } else {
      bracket = '25 - 100 kW'
    }

    const group = utilityMap.get(us_selectedUtility)

    if (group === 'Group A' && bracket === 'Under 10 kW') {
      srecUtility = 73.71
    }
    if (group === 'Group A' && bracket === '10 - 25 kW') {
      srecUtility = 63.53
    }
    if (group === 'Group A' && bracket === '25 - 100 kW') {
      srecUtility = 55.89
    }
    if (group === 'Group B' && bracket === 'Under 10 kW') {
      srecUtility = 83.87
    }
    if (group === 'Group B' && bracket === '10 - 25 kW') {
      srecUtility = 77.53
    }
    if (group === 'Group B' && bracket === '25 - 100 kW') {
      srecUtility = 70.23
    }

    totalScrecs = numSrecs * srecUtility
    abpFee = kWac * 20
    srecCollateral = totalScrecs * 0.05
    srecPayout = totalScrecs * 0.8

    const upfrontFee = formatDollar(abpFee + srecCollateral)
    const srecPayment = formatDollar(srecPayout)
    const calculated = true

    us_setResults({ abpFee, srecCollateral, upfrontFee, srecPayment, calculated })
  }, [us_size, us_production, us_selectedUtility, us_sizeError, us_productionError])

  const handleSizeBlur = () => {
    const sizeNumber = parseFloat(us_size)
    if (sizeNumber < 3.0 || sizeNumber > 25.0) {
      us_setSizeError(true)
    } else {
      us_setSizeError(false)
    }
  }

  const handleProductionBlur = () => {
    const productionNumber = parseFloat(us_production)
    if (productionNumber < 3000 || productionNumber > 30000) {
      us_setProductionError(true)
    } else {
      us_setProductionError(false)
    }
  }

  // Map of utilities to their group

  // Function to handle calculations
  const openUpFrontFeeDialog = () => {
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Dialog
            className="bp_dialog_lg_width"
            keepMounted
            onClose={() => {
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
            }}
            open={true}
          >
            <AppBar
              position="static"
              color="inherit"
            >
              <Toolbar>
                <IconButton
                  aria-label="menu"
                  color="inherit"
                  disabled
                  edge="start"
                  size="large"
                  sx={{ mr: 2, color: '#fff !important' }}
                >
                  <Icon icon="circle-info" />
                </IconButton>
                <Typography
                  component={'span'}
                  variant={'h6'}
                  sx={{ flexGrow: 1 }}
                >
                  <Box className="tw-inline-block">{rLIB('Upfront Fee')}</Box>
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <Box className="tw-p-4">
                <Typography
                  variant="body1"
                  className="tw-mb-4"
                >
                  {rLIB(
                    'This fee is collected upfront when the design is finalized. It is the sum of the application fee to the IL Shines program and a collateral that is collected by your utility. This fee is submitted directly to the Shines program and is not held by your installer.',
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-mb-4"
                >
                  {rLIB(
                    'Please note that the application to the IL Shines program (which pays out the SREC) will not be submitted until the fee is paid in full.',
                  )}
                </Typography>
                <Typography variant="body1">{rLIB('Detail on the fees:')}</Typography>
                <Typography variant="body1">
                  <Icon
                    icon="circle"
                    className="tw-mx-2"
                    sx={{ fontSize: '10px' }}
                  />
                  {rLIB('The application fee is')} ${formatDollar(us_results.abpFee)}, {rLIB(' calculated as $20/kW of the AC watts of the system')}
                </Typography>
                <Typography variant="body1">
                  <Icon
                    icon="circle"
                    className="tw-mx-2"
                    sx={{ fontSize: '10px' }}
                  />
                  {rLIB('The collateral is')} ${formatDollar(us_results.srecCollateral)},{' '}
                  {rLIB(
                    'calculated as 5% of the total value of the SREC. This is held as collateral for the 15-year period of the SREC contract and returned to the homeowner at the end of the 15-year period (less any draw-downs). For detail on the collateral and draw-downs, refer to the Shines program',
                  )}
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        ),
        settings: {
          max_width: 'lg',
        },
      },
    })
  }

  const openSrecPaymentDialog = () => {
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Dialog
            className="bp_dialog_lg_width"
            keepMounted
            onClose={() => {
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
            }}
            open={true}
          >
            <AppBar
              position="static"
              color="inherit"
            >
              <Toolbar>
                <IconButton
                  aria-label="menu"
                  color="inherit"
                  disabled
                  edge="start"
                  size="large"
                  sx={{ mr: 2, color: '#fff !important' }}
                >
                  <Icon icon="circle-info" />
                </IconButton>
                <Typography
                  component={'span'}
                  variant={'h6'}
                  sx={{ flexGrow: 1 }}
                >
                  <Box className="tw-inline-block">{rLIB('SREC Payment')}</Box>
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent className="tw-p-10">
              <Box className="tw-p-0">
                <Typography
                  variant="body1"
                  className="tw-mb-4"
                >
                  {rLIB('This is the payment that the homeowner can expect to receive when the SREC is approved by the Shines program.')}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-mb-4"
                >
                  {rLIB(
                    'It is 80% of the full SREC value; The reduction of 20% is partly a collateral (that is returned after 15 years) and a fee of 12.5% to the approved vendor for administering and reporting the energy for the life of the SREC contract.',
                  )}
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        ),
        settings: {
          max_width: 'lg',
        },
      },
    })
  }
  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          p: 3,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
        >
          SREC Calculator
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
            maxWidth: 300,
          }}
        >
          <TextField
            label="Size of System (kWdc)"
            type="number"
            inputProps={{
              step: '0.001',
              min: '0',
            }}
            value={us_size}
            onChange={(e) => {
              us_setSize(e.target.value)
            }}
            onBlur={handleSizeBlur}
            error={us_sizeError}
            helperText={us_sizeError ? 'Size must be between 3.0 and 25.0 kWdc' : ''}
            fullWidth
          />
          <TextField
            label="Y1 Production (kWh)"
            type="number"
            inputProps={{
              step: '0.001',
              min: '0',
            }}
            value={us_production}
            onChange={(e) => {
              us_setProduction(e.target.value)
            }}
            onBlur={handleProductionBlur}
            error={us_productionError}
            helperText={us_productionError ? 'Production must be between 3,000 and 30,000 kWh' : ''}
            fullWidth
          />
          <Select
            value={us_selectedUtility}
            onChange={(e) => {
              us_setSelectedUtility(e.target.value)
            }}
            displayEmpty
            fullWidth
          >
            <MenuItem
              value=""
              disabled
            >
              Select a utility
            </MenuItem>
            {Array.from(utilityMap.keys()).map((utility) => (
              <MenuItem
                key={utility}
                value={utility}
              >
                {utility}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          {us_results.calculated === true ? (
            <Box className="tw-bg-warning_dark tw-rounded-lg tw-p-4">
              <Typography variant="h6">
                {rLIB('Upfront Fee')}: ${us_results.upfrontFee}
                <Icon
                  className="tw-cursor-pointer tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
                  icon="circle-info"
                  onClick={() => {
                    openUpFrontFeeDialog()
                  }}
                />
              </Typography>
              <Typography variant="h6">
                {rLIB('SREC Payment')}: ${us_results.srecPayment}
                <Icon
                  className="tw-cursor-pointer tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
                  icon="circle-info"
                  onClick={() => {
                    openSrecPaymentDialog()
                  }}
                />
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
