///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, TextField, Typography } from '@mui/material/'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { authWithPassword } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
// @ts-expect-error - TODO: reason for error
import logoSrc from '../../images/logos/logo_temp.png'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////
const PHONE_NUMBER = '(312) 728-3403'

const white = '#ffffff'
const teal = '#1E83A1'
const aqua = '#3fdeff'

// Authenticated Nav Data
const pageKey: string = ApplicationPages['UnauthenticatedSalesLoginPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string
  const clientKey: string = 'etw_energy_sales'

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  // { sort-end } - hooks
  const [us_loginEmail, us_setLoginEmail] = useState<string>('')
  const [us_loginPassword, us_setLoginPassword] = useState<string>('')

  const un_routerNavigation = useNavigate()

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('SalesLogin', false) as string
  }, [])

  // Functions

  // JSX Generation
  const rJSX_LoginAddressBanner = (logoSrc: string, palette: TsInterface_UnspecifiedObject): JSX.Element => {
    let bannerJSX = (
      <Box
        className="tw-p-2"
        sx={{ background: palette.white }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '5px',
            paddingX: '5px',
            alignItems: 'center',
          }}
        >
          <Box
            className="tw-mb-2"
            component="img"
            sx={{ width: 189, height: 54, background: 'white', borderRadius: '5px', marginRight: '20px' }}
            alt="logo"
            src={logoSrc}
          />
          <Box sx={{ marginTop: '6px', marginRight: '20px' }}>
            <Card
              className="tw-bg-gray_300 tw-p-3"
              elevation={0}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  icon="phone"
                  size="xl"
                  sx={{ color: '#56d3ff' }}
                />
                <Box className="tw-flex-col tw-ml-2">
                  <Typography sx={{ fontSize: '10px' }}>{rLIB('Have questions?')}</Typography>
                  <Typography sx={{ fontSize: '10px', color: palette.black, fontWeight: 'bold' }}>
                    <u>{PHONE_NUMBER}</u> or <u>schedule a call back</u>
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    )

    return bannerJSX
  }

  const rJSX_LoginPage = (stateGettersSetters: any, palette: any, clientKey: string): JSX.Element => {
    return (
      <Box
        sx={{
          backgroundColor: palette.aqua, // Set the background color to blue (aqua)
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh', // Ensure the Box takes the full viewport height
          padding: '40px 20px', // Adds some padding to the top and sides
          boxSizing: 'border-box', // Ensures padding is included in the total height
        }}
      >
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            color: palette.white,
            marginBottom: '8px', // Reduced margin
            fontWeight: 'bold',
            marginTop: '0', // Remove any default top margin
          }}
        >
          {rLIB('Welcome!')}
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="h6"
          sx={{
            color: palette.white,
            marginBottom: '24px', // Adjusted margin
          }}
        >
          {rLIB('Login Below')}
        </Typography>

        {/* Email Input */}
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          sx={{
            'backgroundColor': palette.white,
            'borderRadius': '4px',
            'marginBottom': '16px',
            'width': '300px',
            '& .MuiInputLabel-root': {
              color: '#919191', // Add color for the label
            },
            '& .MuiInputBase-input': {
              color: '#919191', // **Set input text color here**
            },
            '& input:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px ${palette.white} inset`,
              WebkitTextFillColor: '#919191',
            },
          }}
          value={stateGettersSetters.us_loginEmail}
          onChange={(e) => {
            stateGettersSetters.us_setLoginEmail(e.target.value.trim())
          }}
        />

        {/* Password Input */}
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          sx={{
            'backgroundColor': palette.white,
            'borderRadius': '4px',
            'marginBottom': '24px',
            'width': '300px',
            '& .MuiInputLabel-root': {
              color: '#919191', // Add color for the label
            },
            '& .MuiInputBase-input': {
              color: '#919191', // **Set input text color here**
            },
          }}
          value={stateGettersSetters.us_loginPassword}
          onChange={(e) => {
            stateGettersSetters.us_setLoginPassword(e.target.value.trim())
          }}
        />

        {/* Login Button */}
        <Button
          variant="contained"
          sx={{
            'marginTop': '20px',
            'backgroundColor': palette.white,
            'color': palette.aqua,
            'border': `1px solid ${palette.aqua}`,
            'width': '200px',
            'padding': '10px',
            'borderRadius': '25px',
            'textTransform': 'none',
            'fontWeight': 'bold',
            'fontSize': '16px',
            '&:hover': {
              backgroundColor: palette.aqua,
              color: palette.white,
              border: `2px solid ${palette.aqua}`,
            },
          }}
          onClick={() => {
            authWithPassword(stateGettersSetters.us_loginEmail, stateGettersSetters.us_loginPassword)
              .then((res_A: any) => {
                //route to new welcome page
                const userID = res_A.user.uid
                directAppNavigation(
                  stateGettersSetters.un_routerNavigation,
                  `${ApplicationPages.AuthenticatedSalesOpportunityWelcomePage.url()}?userID=${userID}`,
                )
              })
              .catch((rej_A) => {
                console.error('Login failed:', rej_A)
              })
          }}
        >
          {rLIB('Login')}
        </Button>

        {/* Forgot Password Link */}
        <Typography
          variant="body2"
          sx={{
            color: palette.white,
            textDecoration: 'underline',
            fontSize: '18px',
            marginTop: '20px',
          }}
          onClick={() => {
            stateGettersSetters.us_setCurrentView('createAccountOrResetPassword')
          }}
        >
          {rLIB('Forgot Password?')}
        </Typography>
      </Box>
    )
  }

  const rJSX_PageContent = (): JSX.Element => {
    let jsx = <></>

    jsx = (
      <>
        {rJSX_LoginAddressBanner(logoSrc, { teal, white, aqua })}
        {rJSX_LoginPage(
          {
            us_loginEmail,
            us_setLoginEmail,
            us_loginPassword,
            us_setLoginPassword,
            un_routerNavigation,
          },
          { teal, white, aqua },
          clientKey,
        )}
      </>
    )
    return jsx
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = rJSX_PageContent()
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
