///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, CircularProgress, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider } from '@mui/material'
import { getAuth } from 'firebase/auth'
import 'leaflet/dist/leaflet.css'
import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_User_Document } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_SalesEagleviewPropose_Document } from 'rfbp_aux/services/database_endpoints/eagleview_propose'
import { DatabaseRef_UnprotectedOpportunityUpdateTime_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import {
  DatabaseRef_SaleOpportunityFile_Document,
  DatabaseRef_SaleOpportunityPhotos_Collection,
  DatabaseRef_SaleOpportunityPhoto_Document,
  DatabaseRef_SalesOpportunity_v2_Document,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import { DatabaseRef_ProposalsForOpportunity_Query } from 'rfbp_aux/services/database_endpoints/sales/proposals'
import { StorageRef_SalesOpportunityFile, StorageRef_SalesOpportunityPhoto } from 'rfbp_aux/services/storage_endpoints/sales_opportunities'
import { rLIB } from 'rfbp_core/localization/library'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveDocument,
  DatabaseUpdateDocument,
  StorageUploadFile,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import {
  rJSX_EditPanelLayout,
  rJSX_ResetPasswordPage,
  rJSX_SurveyPage,
  rJSX_WelcomeAddressBanner,
  rJSX_WelcomePage,
} from './authenticated_sales_opportunity_v3_jsx'
import { convertEvEnergyTableDataToSolarPanels } from './functions/sales_opportunity_v3_functions'
// @ts-expect-error -  reason for error
import logoSrc from '../../images/logos/logo_temp.png'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

interface TsInterface_FilesToUpload {
  [$fileKey: string]: {
    file_name: string
    file: any
    data_url: any
    storage_url?: string | null
  }
}

export interface TsInterface_EditDialogState {
  open: boolean
  field: string
  value: string | number
  proposalId: number
}

export interface TsInterface_Proposal {
  id: number
  name: string
  systemSize: number
  numPanels: number
  annualProduction: number
  mountChoice: string
  reroof: string
  batteries: number
  type: string
  systemCost: number
  totalIncentives: number
  fedIncentives: number
  stateIncentives: number
  netCost: number
}

const white = '#ffffff'
const teal = '#1E83A1'
const aqua = '#3fdeff'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: teal,
      light: aqua,
      contrastText: white,
    },
  },
})

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AuthenticatedSalesOpportunityWelcomePage']['key']
const clientKey: string = 'etw_energy_sales'

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  // { sort-end } - hooks
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [searchParams] = useSearchParams()
  const userID = searchParams.get('userID') || ''
  const auth = getAuth()
  const user = auth.currentUser

  const proposalKeysRef = useRef({
    primary: '',
    secondary: '',
    tertiary: '',
  })

  const un_routerNavigation = useNavigate()
  const photoInputRef = useRef<HTMLInputElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  //system details
  const [us_editingField, us_setEditingField] = useState<string | null>(null)
  const [us_editValue, us_setEditValue] = useState<string>('')
  const [us_editDialog, us_setEditDialog] = useState<TsInterface_EditDialogState>({
    open: false,
    field: '',
    value: '',
    proposalId: 0,
  })
  const [us_selectedMountOption, us_setSelectedMountOption] = useState<string>('')
  const [us_selectedReroofOption, us_setSelectedReroofOption] = useState<string>('')
  const [us_selectedNumBatteriesOption, us_setSelectedNumBatteriesOption] = useState<number | null>(null)
  const [us_selectedFinancingOption, us_setSelectedFinancingOption] = useState<string>('')

  const [us_opportunityData, us_setOpportunityData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_proposalData, us_setProposalData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_secondaryProposalData, us_setSecondaryProposalData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tertiaryProposalData, us_setTertiaryProposalData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_eagleviewProspectData, us_setEagleviewProspectData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_photosData, us_setPhotosData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_dataLoaded, us_setIsDataLoaded] = useState<boolean>(false)
  const [us_homeDetailsExpanded, us_setHomeDetailsExpanded] = useState<boolean>(true)
  const [us_opportunityKey, us_setOpportunityKey] = useState<string>('')

  const [us_selectedFinancingType, us_setSelectedFinancingType] = useState<string>('cash')
  const [us_secondarySelectedFinancingType, us_setSecondarySelectedFinancingType] = useState<string>('cash')
  const [us_tertiarySelectedFinancingType, us_setTertiarySelectedFinancingType] = useState<string>('cash')

  const [us_selectedRoofType, us_setSelectedRoofType] = useState<string>('')

  const [us_selectedMountType, us_setSelectedMountType] = useState<string>('roof')
  const [us_secondarySelectedMountType, us_setSecondarySelectedMountType] = useState<string>('roof')
  const [us_tertiarySelectedMountType, us_setTertiarySelectedMountType] = useState<string>('roof')

  const [us_numBatteries, us_setNumBatteries] = useState<number>(0)
  const [us_secondaryNumBatteries, us_setSecondaryNumBatteries] = useState<number>(0)
  const [us_tertiaryNumBatteries, us_setTertiaryNumBatteries] = useState<number>(0)

  const [us_roofReplacement, us_setRoofReplacement] = useState<boolean>(false)
  const [us_secondaryRoofReplacement, us_setSecondaryRoofReplacement] = useState<boolean>(false)
  const [us_tertiaryRoofReplacement, us_setTertiaryRoofReplacement] = useState<boolean>(false)

  const [us_systemSizeInWatts, us_setSystemSizeInWatts] = useState<number>(0)
  const [us_secondarySystemSizeInWatts, us_setSecondarySystemSizeInWatts] = useState<number>(0)
  const [us_tertiarySystemSizeInWatts, us_setTertiarySystemSizeInWatts] = useState<number>(0)

  const [us_offset, us_setOffset] = useState<number>(100)
  const [us_secondaryOffset, us_setSecondaryOffset] = useState<number>(100)
  const [us_tertiaryOffset, us_setTertiaryOffset] = useState<number>(100)

  const [us_cashSavings, us_setCashSavings] = useState<number>(0)
  const [us_secondaryCashSavings, us_setSecondaryCashSavings] = useState<number>(0)
  const [us_tertiaryCashSavings, us_setTertiaryCashSavings] = useState<number>(0)

  const [us_leaseMonthlySavings, us_setLeaseMonthlySavings] = useState<number>(0)
  const [us_secondaryLeaseMonthlySavings, us_setSecondaryLeaseMonthlySavings] = useState<number>(0)
  const [us_tertiaryLeaseMonthlySavings, us_setTertiaryLeaseMonthlySavings] = useState<number>(0)

  const [us_leaseCumulativeSavings, us_setLeaseCumulativeSavings] = useState<number>(0)
  const [us_secondaryLeaseCumulativeSavings, us_setSecondaryLeaseCumulativeSavings] = useState<number>(0)
  const [us_tertiaryLeaseCumulativeSavings, us_setTertiaryLeaseCumulativeSavings] = useState<number>(0)

  const [us_numPanels, us_setNumPanels] = useState(0)
  const [us_secondaryNumPanels, us_setSecondaryNumPanels] = useState(0)
  const [us_tertiaryNumPanels, us_setTertiaryNumPanels] = useState(0)

  const [us_baseCashCost, us_setBaseCashCost] = useState<number>(0)
  const [us_secondaryBaseCashCost, us_setSecondaryBaseCashCost] = useState<number>(0)
  const [us_tertiaryBaseCashCost, us_setTertiaryBaseCashCost] = useState<number>(0)

  const [us_currentCashCost, us_setCurrentCashCost] = useState<number>(0)
  const [us_secondaryCurrentCashCost, us_setSecondaryCurrentCashCost] = useState<number>(0)
  const [us_tertiaryCurrentCashCost, us_setTertiaryCurrentCashCost] = useState<number>(0)

  const [us_leaseCost, us_setLeaseCost] = useState<number>(0)
  const [us_secondaryLeaseCost, us_setSecondaryLeaseCost] = useState<number>(0)
  const [us_tertiaryLeaseCost, us_setTertiaryLeaseCost] = useState<number>(0)

  const [us_systemAnnualProductionInKWh, us_setSystemAnnualProductionInKwh] = useState<number>(0)
  const [us_secondarySystemAnnualProductionInKwh, us_setSecondarySystemAnnualProductionInKwh] = useState<number>(0)
  const [us_tertiarySystemAnnualProductionInKwh, us_setTertiarySystemAnnualProductionInKwh] = useState<number>(0)

  const [us_federalIncentive, us_setFederalIncentive] = useState<number>(0)
  const [us_secondaryFederalIncentive, us_setSecondaryFederalIncentive] = useState<number>(0)
  const [us_tertiaryFederalIncentive, us_setTertiaryFederalIncentive] = useState<number>(0)

  const [us_srecIncentive, us_setSrecIncentive] = useState<number>(0)
  const [us_secondarySrecIncentive, us_setSecondarySrecIncentive] = useState<number>(0)
  const [us_tertiarySrecIncentive, us_setTertiarySrecIncentive] = useState<number>(0)

  const [us_currentView, us_setCurrentView] = useState<string>('welcome')
  const [us_firstName, us_setFirstName] = useState<string>('')
  const [us_lastName, us_setLastName] = useState<string>('')
  const [us_email, us_setEmail] = useState<string>('')
  const [us_phone, us_setPhone] = useState<string>('')
  const [us_address, us_setAddress] = useState<string>('')
  const [us_city, us_setCity] = useState<string>('')
  const [us_state, us_setState] = useState<string>('')
  const [us_zip, us_setZip] = useState<string>('')
  const [us_latitude, us_setLatitude] = useState<number>(0)
  const [us_longitude, us_setLongitude] = useState<number>(0)
  const [us_utilityCompanyName, us_setUtilityCompanyName] = useState<string>('')
  const [us_electricityBill, us_setElectricityBill] = useState<number>(0)
  const [us_showFinanceDetailsInWelcome, us_setShowFinanceDetailsInWelcome] = useState<boolean>(false)
  const [us_showOptionsDetailsInWelcome, us_setShowOptionsDetailsInWelcome] = useState<boolean>(false)

  const [us_newPassword, us_setNewPassword] = useState('')
  const [us_confirmPassword, us_setConfirmPassword] = useState('')

  const [us_compareMode, us_setCompareMode] = useState<boolean>(false)
  const [us_selectedLeft, us_setSelectedLeft] = useState<number>(0)
  const [us_selectedRight, us_setSelectedRight] = useState<number>(1)

  //eagleview prospect data
  const [us_evImageURL, us_setEvImageURL] = useState<string>('')
  const [us_evEnergyTableData, us_setEvEnergyTableData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_evFacetData, us_setEvFacetData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_evRoofData, us_setEvRoofData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_panels, us_setPanels] = useState<TsInterface_UnspecifiedObject>({})

  const [us_showFirstLoginDialog, us_setShowFirstLoginDialog] = useState<boolean>(false)
  const [us_photoKey, us_setPhotoKey] = useState<string>('')
  const [us_homeExteriorImage, us_setHomeExteriorImage] = useState<File | null>(null)
  const [us_roofImage, us_setRoofImage] = useState<File | null>(null)
  const [us_atticImage, us_setAtticImage] = useState<File | null>(null)

  //survey data
  const [us_fileKey, us_setFileKey] = useState<string>('')
  const [us_selectedPurchaseTimeline, us_setSelectedPurchaseTimeline] = useState<string>('')
  const [us_partOfHOA, us_setPartOfHOA] = useState<string>('')
  const [us_hoaName, us_setHoaName] = useState<string>('')
  const [us_hoaPhone, us_setHoaPhone] = useState<string>('')
  const [us_selectedHomeType, us_setSelectedHomeType] = useState<string>('')
  const [us_selectedHomeAge, us_setSelectedHomeAge] = useState<string>('')
  const [us_accessToAllExteriorWalls, us_setAccessToAllExteriorWalls] = useState<boolean>(false)
  const [us_noAccessReason, us_setNoAccessReason] = useState<string>('')
  const [us_selectedRoofAge, us_setSelectedRoofAge] = useState<string>('')
  const [us_hasAttic, us_setHasAttic] = useState<boolean>(false)
  const [us_atticAccess, us_setAtticAccess] = useState<string>('')
  const [us_mainBreakerMainPanelRating, us_setMainBreakerMainPanelRating] = useState<number>(0)
  const [us_breakerMainPanelCount, us_setBreakerMainPanelCount] = useState<number>(0)
  const [us_breakerMainPanelSum, us_setBreakerMainPanelSum] = useState<number>(0)
  const [us_subPanelCount, us_setSubPanelCount] = useState<number>(0)
  const [us_subPanelLocation, us_setSubPanelLocation] = useState<string>('')
  const [us_hasSolarSystem, us_setHasSolarSystem] = useState<boolean>(false)
  const [us_hasBatteryStorage, us_setHasBatteryStorage] = useState<boolean>(false)
  const [us_hasGenerator, us_setHasGenerator] = useState<boolean>(false)
  const [us_hasSpecializedElectricalEquipment, us_setHasSpecializedElectricalEquipment] = useState<boolean>(false)
  const [us_hasOtherHighLoadItem, us_setHasOtherHighLoadItem] = useState<boolean>(false)
  const [us_otherHighLoadItem, us_setOtherHighLoadItem] = useState<string>('')
  const [us_hasPool, us_setHasPool] = useState<boolean>(false)
  const [us_hasHotTub, us_setHasHotTub] = useState<boolean>(false)
  const [us_hasElectricHeating, us_setHasElectricHeating] = useState<boolean>(false)
  const [us_hasElectricWaterHeater, us_setHasElectricWaterHeater] = useState<boolean>(false)
  const [us_hasElectricDryer, us_setHasElectricDryer] = useState<boolean>(false)
  const [us_hasEVCharger, us_setHasEVCharger] = useState<boolean>(false)
  const [us_preferredContactMethod, us_setPreferredContactMethod] = useState<string>('')
  const [us_preferredContactTimes, us_setPreferredContactTimes] = useState<string>('')
  const [us_hasPets, us_setHasPets] = useState<boolean>(false)
  const [us_hasGateCode, us_setHasGateCode] = useState<boolean>(false)
  const [us_gateCode, us_setGateCode] = useState<string>('')
  const [us_panelPlacementPreference, us_setPanelPlacementPreference] = useState<string>('')
  const [us_inverterPlacementPreference, us_setInverterPlacementPreference] = useState<string>('')
  const [us_electricityBillFile, us_setElectricityBillFile] = useState<File | null>(null)

  const getUser = useCallback(async (userID: string) => {
    await DatabaseGetDocument(DatabaseRef_User_Document(clientKey, userID)).then((res_DGD: any) => {
      us_setOpportunityKey(res_DGD.data?.associated_opportunity_key ?? '')
      if (res_DGD.data?.first_login !== false) {
        us_setShowFirstLoginDialog(true)
        DatabaseUpdateDocument(DatabaseRef_User_Document(clientKey, userID), {
          first_login: false,
        })
      }
      return res_DGD.data
    })
  }, [])

  const loadPageData = useCallback((clientKey: string, opportunityKey: string) => {
    const handleOpportunityData = (
      opportunityData: any,
      proposalData: any,
      secondaryProposalData: any,
      tertiaryProposalData: any,
      photosData: any,
      eagleviewProspectData: any,
    ) => {
      if (!opportunityData || !proposalData || !secondaryProposalData || !tertiaryProposalData || !photosData || !eagleviewProspectData) return

      us_setEmail(opportunityData.associated_customer_email)
      us_setPhone(opportunityData.associated_customer_phone)
      us_setAddress(opportunityData.location_address)
      us_setCity(opportunityData.location_city)
      us_setState(opportunityData.location_state)
      us_setZip(opportunityData.location_zip)
      us_setLatitude(opportunityData.location_latitude)
      us_setLongitude(opportunityData.location_longitude)
      us_setFirstName(opportunityData.associated_customer_first_name)
      us_setLastName(opportunityData.associated_customer_last_name)
      us_setUtilityCompanyName(opportunityData.associated_utility_company_name)
      us_setElectricityBill(opportunityData.system_monthly_electricity_bill)
      us_setSelectedRoofType(opportunityData.home_roof_type)

      //survey data
      us_setHoaName(opportunityData.home_hoa_name)
      us_setHoaPhone(opportunityData.home_hoa_phone)
      us_setPartOfHOA(opportunityData.contact_part_of_hoa)
      us_setPreferredContactMethod(opportunityData.contact_preferred_method)
      us_setPreferredContactTimes(opportunityData.contact_preferred_contact_time)
      us_setHasPets(opportunityData.home_has_pets)
      us_setHasGateCode(opportunityData.home_has_gate_code)
      us_setGateCode(opportunityData.home_gate_code)
      us_setPanelPlacementPreference(opportunityData.system_placement_preferences)
      us_setInverterPlacementPreference(opportunityData.system_inverter_preferences)
      us_setHasPool(opportunityData.home_has_pool)
      us_setHasHotTub(opportunityData.home_has_hot_tub)
      us_setHasElectricHeating(opportunityData._electric_heating)
      us_setHasElectricWaterHeater(opportunityData._electric_water_heater)
      us_setHasElectricDryer(opportunityData._electric_dryer)
      us_setHasEVCharger(opportunityData.home_ev_charger)
      us_setHasSpecializedElectricalEquipment(opportunityData.electric_specialized_equipment)
      us_setHasOtherHighLoadItem(opportunityData.has_home_electric_other)
      us_setOtherHighLoadItem(opportunityData.home_electric_other)
      us_setAccessToAllExteriorWalls(opportunityData.home_outside_wall_access)
      us_setNoAccessReason(opportunityData.home_outside_wall_access_description)
      us_setBreakerMainPanelCount(opportunityData.electric_main_panel_num_breakers)
      us_setBreakerMainPanelSum(opportunityData.electric_main_panel_sum_breakers)
      us_setSubPanelCount(opportunityData.electric_sub_panel_number)
      us_setSubPanelLocation(opportunityData.electric_sub_panel_locations)
      us_setMainBreakerMainPanelRating(opportunityData.electric_main_breaker_rating)
      us_setHasSolarSystem(opportunityData.home_existing_solar_system)
      us_setHasBatteryStorage(opportunityData.home_battery_storage)
      us_setHasGenerator(opportunityData.home_has_generator)
      us_setSelectedHomeType(opportunityData.home_type)
      us_setSelectedRoofAge(opportunityData.home_roof_age)
      us_setHasAttic(opportunityData.home_has_attic)
      us_setAtticAccess(opportunityData.home_attic_access)
      us_setSelectedPurchaseTimeline(opportunityData.person_purchase_timeframe)
      us_setSelectedHomeAge(opportunityData.home_house_age)
      us_setSelectedHomeType(opportunityData.home_house_type)

      //proposal data
      us_setSelectedFinancingType(proposalData.associated_financing_type)
      us_setBaseCashCost(proposalData.financials_cash_base_price)
      us_setCurrentCashCost(proposalData.financials_cash_current_price)
      us_setLeaseCost(proposalData.financials_lease_price)
      us_setCurrentCashCost(proposalData.financials_cash_price_after_incentives)
      us_setLeaseMonthlySavings(proposalData.financials_lease_monthly_savings)
      us_setLeaseCumulativeSavings(proposalData.financials_lease_year_one_savings)
      us_setNumBatteries(proposalData.system_storage_quantity)
      us_setSelectedMountType(proposalData.system_mount_type)
      us_setCashSavings(proposalData.financials_cash_base_price - proposalData.financials_cash_price_after_incentives)
      us_setFederalIncentive(proposalData.financials_federal_incentive)
      us_setSrecIncentive(proposalData.financials_srec_incentive)
      us_setSystemAnnualProductionInKwh(proposalData.system_estimated_annual_production)
      us_setSystemSizeInWatts(proposalData.selected_system_size)
      us_setNumPanels(proposalData.panel_quantity)
      us_setOffset(proposalData.system_usage_offset)
      us_setRoofReplacement(proposalData.home_roof_quality === 'Needs Repair')

      // Set secondary proposal data
      us_setSecondarySelectedFinancingType(secondaryProposalData.associated_financing_type)
      us_setSecondaryBaseCashCost(secondaryProposalData.financials_cash_base_price)
      us_setSecondaryCurrentCashCost(secondaryProposalData.financials_cash_current_price)
      us_setSecondaryLeaseCost(secondaryProposalData.financials_lease_price)
      us_setSecondaryCurrentCashCost(secondaryProposalData.financials_cash_price_after_incentives)
      us_setSecondaryLeaseMonthlySavings(secondaryProposalData.financials_lease_monthly_savings)
      us_setSecondaryLeaseCumulativeSavings(secondaryProposalData.financials_lease_year_one_savings)
      us_setSecondaryNumBatteries(secondaryProposalData.system_storage_quantity)
      us_setSecondarySelectedMountType(secondaryProposalData.system_mount_type)
      us_setSecondaryCashSavings(secondaryProposalData.financials_cash_base_price - secondaryProposalData.financials_cash_price_after_incentives)
      us_setSecondaryFederalIncentive(secondaryProposalData.financials_federal_incentive)
      us_setSecondarySrecIncentive(secondaryProposalData.financials_srec_incentive)
      us_setSecondarySystemAnnualProductionInKwh(secondaryProposalData.system_estimated_annual_production)
      us_setSecondarySystemSizeInWatts(secondaryProposalData.selected_system_size)
      us_setSecondaryNumPanels(secondaryProposalData.panel_quantity)
      us_setSecondaryOffset(secondaryProposalData.system_usage_offset)
      us_setSecondaryRoofReplacement(secondaryProposalData.home_roof_quality === 'Needs Repair')

      // Set tertiary proposal data
      us_setTertiarySelectedFinancingType(tertiaryProposalData.associated_financing_type)
      us_setTertiaryBaseCashCost(tertiaryProposalData.financials_cash_base_price)
      us_setTertiaryCurrentCashCost(tertiaryProposalData.financials_cash_current_price)
      us_setTertiaryLeaseCost(tertiaryProposalData.financials_lease_price)
      us_setTertiaryCurrentCashCost(tertiaryProposalData.financials_cash_price_after_incentives)
      us_setTertiaryLeaseMonthlySavings(tertiaryProposalData.financials_lease_monthly_savings)
      us_setTertiaryLeaseCumulativeSavings(tertiaryProposalData.financials_lease_year_one_savings)
      us_setTertiaryNumBatteries(tertiaryProposalData.system_storage_quantity)
      us_setTertiarySelectedMountType(tertiaryProposalData.system_mount_type)
      us_setTertiaryCashSavings(tertiaryProposalData.financials_cash_base_price - tertiaryProposalData.financials_cash_price_after_incentives)
      us_setTertiaryFederalIncentive(tertiaryProposalData.financials_federal_incentive)
      us_setTertiarySrecIncentive(tertiaryProposalData.financials_srec_incentive)
      us_setTertiarySystemAnnualProductionInKwh(tertiaryProposalData.system_estimated_annual_production)
      us_setTertiarySystemSizeInWatts(tertiaryProposalData.selected_system_size)
      us_setTertiaryNumPanels(tertiaryProposalData.panel_quantity)
      us_setTertiaryOffset(tertiaryProposalData.system_usage_offset)
      us_setTertiaryRoofReplacement(tertiaryProposalData.home_roof_quality === 'Needs Repair')

      // Set eagleview data only if it exists
      if (eagleviewProspectData) {
        us_setEvImageURL(eagleviewProspectData.ev_image_data)
        us_setEvEnergyTableData(eagleviewProspectData.dataOutput.energyTable)
        us_setEvFacetData(eagleviewProspectData.dataOutput.facet)
        us_setEvRoofData(eagleviewProspectData.dataOutput.roof)
        us_setPanels(convertEvEnergyTableDataToSolarPanels(eagleviewProspectData.dataOutput.energyTable))
      }

      if (photosData) {
        // Fetch home exterior image
        if (photosData.home_exterior?.url) {
          fetch(photosData.home_exterior.url)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], photosData.home_exterior.file_name, {
                type: blob.type,
              })
              us_setHomeExteriorImage(file)
            })
        }

        // Fetch roof image
        if (photosData.roof?.url) {
          fetch(photosData.roof.url)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], photosData.roof.file_name, {
                type: blob.type,
              })
              us_setRoofImage(file)
            })
        }

        // Fetch attic image
        if (photosData.attic?.url) {
          fetch(photosData.attic.url)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], photosData.attic.file_name, {
                type: blob.type,
              })
              us_setAtticImage(file)
            })
        }
      }
      us_setIsDataLoaded(true)
    }

    Promise.all([
      DatabaseGetDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey)),
      DatabaseGetCollection(DatabaseRef_ProposalsForOpportunity_Query(clientKey, opportunityKey)),
      DatabaseGetCollection(DatabaseRef_SaleOpportunityPhotos_Collection(clientKey, opportunityKey)),
      DatabaseGetDocument(DatabaseRef_SalesEagleviewPropose_Document(clientKey, opportunityKey)).catch((error) => {
        console.warn('Unable to load Eagleview data:', error)
        return { data: null }
      }),
    ]).then(([opportunityRes, proposalRes, photosRes, eagleviewRes]) => {
      const opportunityData = opportunityRes.data
      const photosData = photosRes.data
      const allProposalData = proposalRes.data
      const proposalsArray = Array.isArray(allProposalData) ? allProposalData : Object.values(allProposalData || {})
      const proposalData = proposalsArray.find((proposal) => proposal.name === 'Original Proposal') || proposalsArray[0]
      const secondaryProposalData = proposalsArray.find((proposal) => proposal.name === 'Second Proposal') || proposalsArray[1]
      const tertiaryProposalData = proposalsArray.find((proposal) => proposal.name === 'Third Proposal') || proposalsArray[2]
      const eagleviewData = eagleviewRes.data

      proposalKeysRef.current = {
        primary: proposalData.key,
        secondary: secondaryProposalData.key,
        tertiary: tertiaryProposalData.key,
      }

      us_setOpportunityData(opportunityData || {})
      us_setProposalData(proposalData || {})
      us_setSecondaryProposalData(secondaryProposalData || {})
      us_setTertiaryProposalData(tertiaryProposalData || {})
      us_setEagleviewProspectData(eagleviewData || {})
      us_setPhotosData(photosData || {})
      handleOpportunityData(opportunityData, proposalData, secondaryProposalData, tertiaryProposalData, photosData, eagleviewData)
    })
  }, [])

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Welcome', false) as string
  }, [])

  useEffect(() => {
    getUser(userID)
  }, [userID, getUser])

  useEffect(() => {
    if (us_opportunityKey) {
      let unsubscribeLiveData: TsType_VoidFunction
      const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
        loadPageData(clientKey, us_opportunityKey)
        ur_forceRerender()
      }

      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_UnprotectedOpportunityUpdateTime_Document(us_opportunityKey), updateLiveData)
      return () => {
        if (typeof unsubscribeLiveData === 'function') {
          unsubscribeLiveData()
        }
      }
    }
  }, [loadPageData, ur_forceRerender, us_opportunityKey])

  // Functions
  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(file)
    })
  }

  const uploadPhotosProper = (files: TsInterface_FilesToUpload): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let promiseArray: TsType_UnknownPromise[] = []
      for (let fileIndex in files) {
        let file = files[fileIndex]
        let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
        let nameWithoutType = file.file_name.substring(0, file.file_name.lastIndexOf('.'))
        let fileType = file.file_name.substring(file.file_name.lastIndexOf('.') + 1, file.file_name.length)
        let fileNameWithTimestamp = nameWithoutType + ' (' + dateString + ').' + fileType
        promiseArray.push(
          StorageUploadFile(
            StorageRef_SalesOpportunityPhoto(clientKey, us_opportunityKey, fileNameWithTimestamp),
            // file.data_url,
            file.file,
            {}, // No metadata for now
          )
            .then((res_SUF: any) => {
              files[fileIndex]['storage_url'] = getProp(res_SUF, 'url', null)
            })
            .catch((rej_SUF) => {
              console.error(rej_SUF)
            }),
        )
      }
      Promise.all(promiseArray).finally(() => {
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (us_photoKey === 'home_exterior') {
            us_setHomeExteriorImage(file.file)
          }
          if (us_photoKey === 'roof') {
            us_setRoofImage(file.file)
          }
          if (us_photoKey === 'attic') {
            us_setAtticImage(file.file)
          }
          let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
          let nameWithoutType = file.file_name.substring(0, file.file_name.lastIndexOf('.'))
          let fileType = file.file_name.substring(file.file_name.lastIndexOf('.') + 1, file.file_name.length)
          let fileNameWithTimestamp = nameWithoutType + ' (' + dateString + ').' + fileType
          let fileKey = us_photoKey
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_SaleOpportunityPhoto_Document(clientKey, us_opportunityKey, fileKey),
            data: {
              associated_parent_folder_key: us_opportunityKey,
              associated_uploader_name: us_firstName + ' ' + us_lastName,
              file_name: file.file_name,
              file_name_with_timestamp: fileNameWithTimestamp,
              file_system_type: 'file',
              file_type: fileType,
              key: fileKey,
              status: 'active',
              timestamp_uploaded: new Date(),
              url: getProp(file, 'storage_url', null),
            },
          })
        }
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      })
    })
  }

  const uploadPhotos = (event: React.ChangeEvent<HTMLInputElement>): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: TsType_UnknownPromise[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (file != null && typeof file === 'object') {
            promiseArray.push(
              readAsDataURL(file)
                .then((res_RADURL) => {
                  readFiles[fileIndex] = {
                    file_name: us_photoKey + '_' + file.name,
                    file: file,
                    data_url: res_RADURL,
                  }
                })
                .catch((rej_RADURL) => {
                  // Nothing
                }),
            )
          }
        }
        Promise.all(promiseArray).finally(() => {
          promiseArray.push(
            uploadPhotosProper(readFiles)
              .then((res_ITF) => {
                resolve(res_ITF)
              })
              .catch((rej_ITF) => {
                reject(rej_ITF)
              }),
          )
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to Upload File'),
            details: rLIB('Invalid File Selection'),
            code: 'ER-D-TFS-FOS-02',
          },
        })
      }
    })
  }

  const uploadFilesProper = (files: TsInterface_FilesToUpload): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let promiseArray: TsType_UnknownPromise[] = []
      for (let fileIndex in files) {
        let file = files[fileIndex]
        let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
        let nameWithoutType = file.file_name.substring(0, file.file_name.lastIndexOf('.'))
        let fileType = file.file_name.substring(file.file_name.lastIndexOf('.') + 1, file.file_name.length)
        let fileNameWithTimestamp = nameWithoutType + ' (' + dateString + ').' + fileType
        promiseArray.push(
          StorageUploadFile(
            StorageRef_SalesOpportunityFile(clientKey, us_opportunityKey, fileNameWithTimestamp),
            // file.data_url,
            file.file,
            {}, // No metadata for now
          )
            .then((res_SUF: any) => {
              files[fileIndex]['storage_url'] = getProp(res_SUF, 'url', null)
            })
            .catch((rej_SUF) => {
              console.error(rej_SUF)
            }),
        )
      }
      Promise.all(promiseArray).finally(() => {
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (us_fileKey === 'electric_bill') {
            us_setElectricityBillFile(file.file)
          }
          let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
          let nameWithoutType = file.file_name.substring(0, file.file_name.lastIndexOf('.'))
          let fileType = file.file_name.substring(file.file_name.lastIndexOf('.') + 1, file.file_name.length)
          let fileNameWithTimestamp = nameWithoutType + ' (' + dateString + ').' + fileType
          let fileKey = us_fileKey
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_SaleOpportunityFile_Document(clientKey, us_opportunityKey, fileKey),
            data: {
              associated_parent_folder_key: us_opportunityKey,
              associated_uploader_name: us_firstName + ' ' + us_lastName,
              file_name: file.file_name,
              file_name_with_timestamp: fileNameWithTimestamp,
              file_system_type: 'file',
              file_type: fileType,
              key: fileKey,
              status: 'active',
              timestamp_uploaded: new Date(),
              url: getProp(file, 'storage_url', null),
            },
          })
        }
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      })
    })
  }

  const uploadFiles = (event: React.ChangeEvent<HTMLInputElement>): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: TsType_UnknownPromise[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (file != null && typeof file === 'object') {
            promiseArray.push(
              readAsDataURL(file)
                .then((res_RADURL) => {
                  readFiles[fileIndex] = {
                    file_name: us_fileKey + '_' + file.name,
                    file: file,
                    data_url: res_RADURL,
                  }
                })
                .catch((rej_RADURL) => {
                  // Nothing
                }),
            )
          }
        }
        Promise.all(promiseArray).finally(() => {
          promiseArray.push(
            uploadFilesProper(readFiles)
              .then((res_ITF) => {
                resolve(res_ITF)
              })
              .catch((rej_ITF) => {
                reject(rej_ITF)
              }),
          )
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to Upload File'),
            details: rLIB('Invalid File Selection'),
            code: 'ER-D-TFS-FOS-02',
          },
        })
      }
    })
  }

  // JSX Generation

  const rJSX_PageContent = (): JSX.Element => {
    let jsx = <></>

    jsx = (
      <>
        {us_dataLoaded && rJSX_WelcomeAddressBanner({ un_routerNavigation }, logoSrc, { teal, white, aqua })}
        {us_dataLoaded &&
          us_currentView === 'welcome' &&
          rJSX_WelcomePage(
            {
              us_utilityCompanyName,
              us_setUtilityCompanyName,
              us_address,
              us_setAddress,
              us_zip,
              us_setZip,
              us_state,
              us_setState,
              us_city,
              us_setCity,
              us_setCurrentView,
              us_editingField,
              us_setEditingField,
              us_editValue,
              us_setEditValue,
              us_selectedMountType,
              us_setSelectedMountType,
              us_secondarySelectedMountType,
              us_setSecondarySelectedMountType,
              us_tertiarySelectedMountType,
              us_setTertiarySelectedMountType,
              us_selectedRoofType,
              us_setSelectedRoofType,
              us_numBatteries,
              us_setNumBatteries,
              us_secondaryNumBatteries,
              us_setSecondaryNumBatteries,
              us_tertiaryNumBatteries,
              us_setTertiaryNumBatteries,
              us_homeDetailsExpanded,
              us_setHomeDetailsExpanded,
              us_selectedFinancingType,
              us_setSelectedFinancingType,
              us_secondarySelectedFinancingType,
              us_setSecondarySelectedFinancingType,
              us_tertiarySelectedFinancingType,
              us_setTertiarySelectedFinancingType,
              us_systemSizeInWatts,
              us_setSystemSizeInWatts,
              us_secondarySystemSizeInWatts,
              us_setSecondarySystemSizeInWatts,
              us_tertiarySystemSizeInWatts,
              us_setTertiarySystemSizeInWatts,
              us_cashSavings,
              us_setCashSavings,
              us_secondaryCashSavings,
              us_setSecondaryCashSavings,
              us_tertiaryCashSavings,
              us_setTertiaryCashSavings,
              us_numPanels,
              us_setNumPanels,
              us_secondaryNumPanels,
              us_setSecondaryNumPanels,
              us_tertiaryNumPanels,
              us_setTertiaryNumPanels,
              us_baseCashCost,
              us_setBaseCashCost,
              us_secondaryBaseCashCost,
              us_setSecondaryBaseCashCost,
              us_tertiaryBaseCashCost,
              us_setTertiaryBaseCashCost,
              us_currentCashCost,
              us_setCurrentCashCost,
              us_secondaryCurrentCashCost,
              us_setSecondaryCurrentCashCost,
              us_tertiaryCurrentCashCost,
              us_setTertiaryCurrentCashCost,
              us_leaseCost,
              us_setLeaseCost,
              us_secondaryLeaseCost,
              us_setSecondaryLeaseCost,
              us_tertiaryLeaseCost,
              us_setTertiaryLeaseCost,
              us_systemAnnualProductionInKWh,
              us_setSystemAnnualProductionInKwh,
              us_secondarySystemAnnualProductionInKwh,
              us_setSecondarySystemAnnualProductionInKwh,
              us_tertiarySystemAnnualProductionInKwh,
              us_setTertiarySystemAnnualProductionInKwh,
              us_leaseMonthlySavings,
              us_setLeaseMonthlySavings,
              us_secondaryLeaseMonthlySavings,
              us_setSecondaryLeaseMonthlySavings,
              us_tertiaryLeaseMonthlySavings,
              us_setTertiaryLeaseMonthlySavings,
              us_leaseCumulativeSavings,
              us_setLeaseCumulativeSavings,
              us_secondaryLeaseCumulativeSavings,
              us_setSecondaryLeaseCumulativeSavings,
              us_tertiaryLeaseCumulativeSavings,
              us_setTertiaryLeaseCumulativeSavings,
              us_electricityBill,
              us_setElectricityBill,
              us_federalIncentive,
              us_setFederalIncentive,
              us_secondaryFederalIncentive,
              us_setSecondaryFederalIncentive,
              us_tertiaryFederalIncentive,
              us_setTertiaryFederalIncentive,
              us_srecIncentive,
              us_setSrecIncentive,
              us_secondarySrecIncentive,
              us_setSecondarySrecIncentive,
              us_tertiarySrecIncentive,
              us_setTertiarySrecIncentive,
              us_evImageURL,
              us_setEvImageURL,
              us_evEnergyTableData,
              us_setEvEnergyTableData,
              us_evFacetData,
              us_setEvFacetData,
              us_evRoofData,
              us_setEvRoofData,
              us_latitude,
              us_setLatitude,
              us_longitude,
              us_setLongitude,
              us_photoKey,
              us_setPhotoKey,
              us_homeExteriorImage,
              us_setHomeExteriorImage,
              us_roofImage,
              us_setRoofImage,
              us_atticImage,
              us_setAtticImage,
              us_showFinanceDetailsInWelcome,
              us_setShowFinanceDetailsInWelcome,
              us_showOptionsDetailsInWelcome,
              us_setShowOptionsDetailsInWelcome,
              us_roofReplacement,
              us_setRoofReplacement,
              us_offset,
              us_setOffset,
              us_secondaryOffset,
              us_setSecondaryOffset,
              us_tertiaryOffset,
              us_setTertiaryOffset,
              us_compareMode,
              us_setCompareMode,
              us_selectedLeft,
              us_setSelectedLeft,
              us_selectedRight,
              us_setSelectedRight,
              us_editDialog,
              us_setEditDialog,
              us_selectedMountOption,
              us_setSelectedMountOption,
              us_selectedReroofOption,
              us_setSelectedReroofOption,
              us_selectedNumBatteriesOption,
              us_setSelectedNumBatteriesOption,
              us_selectedFinancingOption,
              us_setSelectedFinancingOption,
            },
            { teal, white, aqua },
            photoInputRef,
            clientKey,
            us_opportunityKey,
            proposalKeysRef,
          )}
        {us_currentView === 'survey' &&
          rJSX_SurveyPage(
            {
              us_setCurrentView,
              us_currentView,
              us_selectedPurchaseTimeline,
              us_setSelectedPurchaseTimeline,
              us_partOfHOA,
              us_setPartOfHOA,
              us_hoaName,
              us_setHoaName,
              us_hoaPhone,
              us_setHoaPhone,
              us_selectedHomeType,
              us_setSelectedHomeType,
              us_selectedHomeAge,
              us_setSelectedHomeAge,
              us_accessToAllExteriorWalls,
              us_setAccessToAllExteriorWalls,
              us_noAccessReason,
              us_setNoAccessReason,
              us_selectedRoofAge,
              us_setSelectedRoofAge,
              us_hasAttic,
              us_setHasAttic,
              us_atticAccess,
              us_setAtticAccess,
              us_mainBreakerMainPanelRating,
              us_setMainBreakerMainPanelRating,
              us_breakerMainPanelCount,
              us_setBreakerMainPanelCount,
              us_breakerMainPanelSum,
              us_setBreakerMainPanelSum,
              us_subPanelCount,
              us_setSubPanelCount,
              us_subPanelLocation,
              us_setSubPanelLocation,
              us_hasSolarSystem,
              us_setHasSolarSystem,
              us_hasBatteryStorage,
              us_setHasBatteryStorage,
              us_hasGenerator,
              us_setHasGenerator,
              us_hasSpecializedElectricalEquipment,
              us_setHasSpecializedElectricalEquipment,
              us_hasOtherHighLoadItem,
              us_setHasOtherHighLoadItem,
              us_otherHighLoadItem,
              us_setOtherHighLoadItem,
              us_hasPool,
              us_setHasPool,
              us_hasHotTub,
              us_setHasHotTub,
              us_hasElectricHeating,
              us_setHasElectricHeating,
              us_hasElectricWaterHeater,
              us_setHasElectricWaterHeater,
              us_hasElectricDryer,
              us_setHasElectricDryer,
              us_hasEVCharger,
              us_setHasEVCharger,
              us_preferredContactMethod,
              us_setPreferredContactMethod,
              us_preferredContactTimes,
              us_setPreferredContactTimes,
              us_hasPets,
              us_setHasPets,
              us_hasGateCode,
              us_setHasGateCode,
              us_gateCode,
              us_setGateCode,
              us_panelPlacementPreference,
              us_setPanelPlacementPreference,
              us_inverterPlacementPreference,
              us_setInverterPlacementPreference,
              us_fileKey,
              us_setFileKey,
              us_electricityBillFile,
              us_setElectricityBillFile,
            },
            { teal, white, aqua },
            fileInputRef,
            clientKey,
            us_opportunityKey,
          )}
        {us_currentView === 'edit-panel-layout' &&
          rJSX_EditPanelLayout(
            {
              us_setCurrentView,
              us_selectedMountType,
              us_setSelectedMountType,
              us_numBatteries,
              us_setNumBatteries,
              us_homeDetailsExpanded,
              us_setHomeDetailsExpanded,
              us_selectedFinancingType,
              us_setSelectedFinancingType,
              us_systemSizeInWatts,
              us_setSystemSizeInWatts,
              us_cashSavings,
              us_setCashSavings,
              us_numPanels,
              us_setNumPanels,
              us_baseCashCost,
              us_setBaseCashCost,
              us_currentCashCost,
              us_setCurrentCashCost,
              us_leaseCost,
              us_setLeaseCost,
              us_systemAnnualProductionInKWh,
              us_setSystemAnnualProductionInKwh,
              us_leaseMonthlySavings,
              us_setLeaseMonthlySavings,
              us_leaseCumulativeSavings,
              us_setLeaseCumulativeSavings,
              us_electricityBill,
              us_setElectricityBill,
              us_federalIncentive,
              us_setFederalIncentive,
              us_srecIncentive,
              us_setSrecIncentive,
              us_evImageURL,
              us_setEvImageURL,
              us_evEnergyTableData,
              us_setEvEnergyTableData,
              us_evFacetData,
              us_setEvFacetData,
              us_evRoofData,
              us_setEvRoofData,
              us_panels,
              us_setPanels,
              us_latitude,
              us_setLatitude,
              us_longitude,
              us_setLongitude,
              us_offset,
              us_setOffset,
            },
            { teal, white, aqua },
          )}
        {us_currentView === 'change-password' &&
          rJSX_ResetPasswordPage(
            { us_newPassword, us_setNewPassword, us_confirmPassword, us_setConfirmPassword, us_setCurrentView, us_currentView },
            { teal, white, aqua },
            user,
          )}
      </>
    )

    return jsx
  }

  const LoadingScreen = (): JSX.Element => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: white,
          gap: 4,
        }}
      >
        <img
          src={logoSrc}
          alt="ETW Energy Logo"
          style={{
            maxWidth: '200px',
            marginBottom: '20px',
          }}
        />
        <CircularProgress
          size={50}
          sx={{
            color: teal,
          }}
        />
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box>
        <ThemeProvider theme={lightTheme}>
          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
          {!us_dataLoaded && LoadingScreen()}
          {us_dataLoaded && rJSX_PageContent()}
          {/* <style>{pageCSS}</style> */}
        </ThemeProvider>
      </Box>
    )
    return pageJSX
  }

  const handleChangePassword = () => {
    us_setCurrentView('change-password')
    us_setShowFirstLoginDialog(false)
  }

  const handleSkipPasswordChange = () => {
    us_setShowFirstLoginDialog(false)
  }

  return (
    <>
      <input
        type="file"
        ref={photoInputRef}
        accept="image/*"
        style={{ display: 'none' }}
        onChange={uploadPhotos}
      />
      <input
        type="file"
        ref={fileInputRef}
        accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.csv"
        style={{ display: 'none' }}
        onChange={uploadFiles}
      />
      <Dialog
        open={us_showFirstLoginDialog}
        onClose={handleSkipPasswordChange}
      >
        <DialogTitle>Welcome to Your Account!</DialogTitle>
        <DialogContent>Would you like to change your password? This is recommended for security purposes.</DialogContent>
        <DialogActions>
          <Button onClick={handleSkipPasswordChange}>Skip for Now</Button>
          <Button
            onClick={handleChangePassword}
            variant="contained"
            color="primary"
          >
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
      {rJSX_Page()}
    </>
  )
}
