///////////////////////////////
// Imports
///////////////////////////////

import { Box, Checkbox, FormControl, FormControlLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_TaskWorkflowProd_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_SalesPartner_InvoiceRates_Task_Document } from 'rfbp_aux/services/database_endpoints/finances/invoice_rates'
import { Json } from 'rfbp_core/components/code_display'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { TsInterface_ProjectData } from '../services/invoice_progress_functions'
import { invoiceBillToOptions } from './task_invoice_mapping'

///////////////////////////////
// Variables
///////////////////////////////

const projectInvoiceLineItemTypeOptions: TsInterface_UnspecifiedObject = {
  base_amount: {
    key: 'base_amount',
    value: rLIB('Base Amount', false),
  },
  distance_adder: {
    key: 'distance_adder',
    value: rLIB('Distance Adder', false),
  },
  pitch_adder: {
    key: 'pitch_adder',
    value: rLIB('Pitch Adder', false),
  },
  battery_adder: {
    key: 'battery_adder',
    value: rLIB('Battery Adder', false),
  },
  roof_type_adder: {
    key: 'roof_type_adder',
    value: rLIB('Roof Type Adder', false),
  },
}

export const invoiceApprovalTypeOptions: TsInterface_UnspecifiedObject = {
  auto_approved: {
    key: 'auto_approved',
    value: rLIB('Auto Approved', false),
  },
  internal_optional_evidence: {
    key: 'internal_optional_evidence',
    value: rLIB('Internal (Optional Evidence)', false),
  },
  internal_required_evidence: {
    key: 'internal_required_evidence',
    value: rLIB('Internal (Required Evidence)', false),
  },
  customer_approved: {
    key: 'customer_approved',
    value: rLIB('Customer', false),
    disabled: true,
  },
  sales_partner_approved: {
    key: 'sales_partner_approved',
    value: rLIB('Sales Partner', false),
    disabled: true,
  },
}

export const projectInvoicePriceTypeOptions: TsInterface_UnspecifiedObject = {
  flat: {
    key: 'flat',
    value: rLIB('Flat Rate', false),
    calculate: (lineItem: TsInterface_UnspecifiedObject, projectData: TsInterface_ProjectData) => {
      return lineItem.price
    },
  },
  per_dc_watt: {
    key: 'per_dc_watt',
    value: rLIB('Per DC Watt', false),
    calculate: (lineItem: TsInterface_UnspecifiedObject, projectData: TsInterface_ProjectData) => {
      if (projectData.system_size_dc != null) {
        // Remove non numeric characters from system_size_dc
        let parsedSystemSizeDC = projectData.system_size_dc.toString().replace(/[^0-9.]/g, '')
        return lineItem.price * parseFloat(parsedSystemSizeDC) * 1000
      } else {
        return 0
      }
    },
  },
  per_panel: {
    key: 'per_panel',
    value: rLIB('Per Panel', false),
    calculate: (lineItem: TsInterface_UnspecifiedObject, projectData: TsInterface_ProjectData) => {
      if (projectData.system_panel_quantity != null) {
        return lineItem.price * projectData.system_panel_quantity
      } else {
        return 0
      }
    },
  },
}

export const invoiceBillingTypeOptions: TsInterface_UnspecifiedObject = {
  percent: {
    key: 'percent',
    value: rLIB('Staggered Percentages', false),
  },
  deposit: {
    key: 'deposit',
    value: rLIB('Deposit before Full', false),
  },
}

const depositInvoiceAmountTypeOptions: TsInterface_UnspecifiedObject = {
  deposit: {
    key: 'deposit',
    value: rLIB('Deposit', false),
  },
  remainder: {
    key: 'remainder',
    value: rLIB('Remainder', false),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const rJSX_invoiceLineItemCell = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  regionKey: string,
): JSX.Element => {
  let cellJSX = <></>
  const rJSX_AddLineIcon = (regionKey: string) => {
    let addLineIconJSX = (
      <Icon
        icon="circle-plus"
        className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
        tooltip={rLIB('Add line item')}
        tooltipPlacement="right"
        onClick={() => {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  line_item_type: {
                    data_type: 'string',
                    input_type: 'multiple_choice_radio',
                    key: 'line_item_type',
                    label: rLIB('Line item type'),
                    required: true,
                    options: Object.values(projectInvoiceLineItemTypeOptions),
                  },
                  price_type: {
                    data_type: 'string',
                    input_type: 'multiple_choice_radio',
                    key: 'price_type',
                    label: rLIB('Pricing type'),
                    required: true,
                    options: Object.values(projectInvoicePriceTypeOptions),
                  },
                  price: {
                    data_type: 'number',
                    input_type: 'text_number',
                    key: 'price',
                    label: rLIB('Price / Price Multiplier'),
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let lineItemKey = ''
                    lineItemKey = formSubmittedData['line_item_type']
                    if (
                      projectInvoiceLineItemTypeOptions != null &&
                      formSubmittedData != null &&
                      formSubmittedData['line_item_type'] != null &&
                      projectInvoiceLineItemTypeOptions[formSubmittedData['line_item_type'] as string] != null &&
                      projectInvoiceLineItemTypeOptions[formSubmittedData['line_item_type'] as string].value != null
                    ) {
                      formSubmittedData['name'] = projectInvoiceLineItemTypeOptions[formSubmittedData['line_item_type'] as string].value
                    }
                    let updateObject: TsInterface_UnspecifiedObject = {
                      ['line_items_' + regionKey]: {
                        [lineItemKey]: {
                          line_item_type: formSubmittedData['line_item_type'],
                          name: formSubmittedData['name'],
                          price: formSubmittedData['price'],
                          price_type: formSubmittedData['price_type'],
                          key: lineItemKey,
                          order: new Date().getTime(),
                        },
                      },
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(
                          DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                            res_GCK.clientKey,
                            tableAdditionalData.us_selectedSalesPartnerKey,
                            ('BASE_RATES_' + rowData.key) as string,
                          ),
                          updateObject,
                        )
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Add line item'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    return addLineIconJSX
  }
  const rJSX_EditLineItemIcon = (lineItem: TsInterface_UnspecifiedObject, regionKey: string) => {
    let editLineItemIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
        tooltip={rLIB('Edit line item')}
        tooltipPlacement="right"
        onClick={() => {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: lineItem,
                formInputs: {
                  price_type: {
                    data_type: 'string',
                    input_type: 'multiple_choice_radio',
                    key: 'price_type',
                    label: rLIB('Pricing type'),
                    required: true,
                    options: Object.values(projectInvoicePriceTypeOptions),
                  },
                  price: {
                    data_type: 'number',
                    input_type: 'text_number',
                    key: 'price',
                    label: rLIB('Price / Price Multiplier'),
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      ['line_items_' + regionKey]: {
                        [lineItem.key]: {
                          price: formSubmittedData['price'],
                          price_type: formSubmittedData['price_type'],
                        },
                      },
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(
                          DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                            res_GCK.clientKey,
                            tableAdditionalData.us_selectedSalesPartnerKey,
                            ('BASE_RATES_' + rowData.key) as string,
                          ),
                          updateObject,
                        )
                          .then((res_DSMD) => {
                            // Nothing
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit line item'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    return editLineItemIconJSX
  }
  const rJSX_DeleteLineItemIcon = (lineItemKey: string, regionKey: string) => {
    let deleteLineItemIconJSX = (
      <Icon
        icon="trash"
        className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_main"
        tooltip={rLIB('Delete line item')}
        tooltipPlacement="right"
        onClick={() => {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete line item'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure you want to delete this line item?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    ['line_items_' + regionKey]: {
                      [lineItemKey]: null,
                    },
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                          res_GCK.clientKey,
                          tableAdditionalData.us_selectedSalesPartnerKey,
                          ('BASE_RATES_' + rowData.key) as string,
                        ),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          // Nothing
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          reject(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }}
      />
    )
    return deleteLineItemIconJSX
  }
  const rJSX_LineItemPrice = (lineItem: TsInterface_UnspecifiedObject) => {
    let lineItemPriceJSX = <></>
    if (lineItem.price_type === 'variable') {
      lineItemPriceJSX = (
        <Box
          className="tw-px-2"
          sx={{
            background: themeVariables.warning_main,
            borderRadius: '5px',
          }}
        >
          {rLIB('Variable')}
        </Box>
      )
      return lineItemPriceJSX
    } else {
      lineItemPriceJSX = (
        <Box
          className="tw-px-2"
          sx={{
            background: getProp(lineItem, 'price', 0) > 0 ? themeVariables.success_main : themeVariables.gray_600,
            borderRadius: '5px',
          }}
        >
          {formatCurrency(getProp(lineItem, 'price', 0))}
        </Box>
      )
      return lineItemPriceJSX
    }
  }
  const rJSX_LineItemPriceType = (lineItem: TsInterface_UnspecifiedObject) => {
    let lineItemTypeJSX = <></>
    if (
      lineItem.price_type != null &&
      projectInvoicePriceTypeOptions != null &&
      projectInvoicePriceTypeOptions[lineItem.price_type] != null &&
      projectInvoicePriceTypeOptions[lineItem.price_type].value != null
    ) {
      lineItemTypeJSX = <Box className="tw-pr-0 tw-opacity-60">({projectInvoicePriceTypeOptions[lineItem.price_type].value})</Box>
    }
    return lineItemTypeJSX
  }
  const rJSX_LineItems = (regionKey: string) => {
    let lineItemsJSX = <></>
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[('BASE_RATES_' + rowData.key) as string] != null &&
      tableAdditionalData.us_invoiceRates[('BASE_RATES_' + rowData.key) as string]['line_items_' + regionKey] != null
    ) {
      lineItemsJSX = (
        <Box>
          {objectToArray(tableAdditionalData.us_invoiceRates[('BASE_RATES_' + rowData.key) as string]['line_items_' + regionKey])
            .sort(dynamicSort('order', null))
            .map((lineItem: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Stack
                  direction="row"
                  spacing={1}
                  className="tw-mb-1"
                >
                  <Box>{lineItem.name}</Box>
                  <Box>{rJSX_LineItemPrice(lineItem)}</Box>
                  <Box>{rJSX_LineItemPriceType(lineItem)}</Box>
                  <Box>{rJSX_EditLineItemIcon(lineItem, regionKey)}</Box>
                  <Box>{rJSX_DeleteLineItemIcon(lineItem.key, regionKey)}</Box>
                </Stack>
              </Box>
            ))}
        </Box>
      )
    }
    return lineItemsJSX
  }

  // Full JSX
  cellJSX = (
    <Box>
      {rJSX_LineItems(regionKey)}
      {rJSX_AddLineIcon(regionKey)}
    </Box>
  )
  return cellJSX
}

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_InvoiceBasePricing: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 180px)',
  alternate_row_colors: true,
  alternate_row_color_hex: themeVariables.background_hover,
}

export const tableColumns_InvoiceBasePricing: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Workflow Name'), null),
  bill_to: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = rLIB('Bill To')
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        const rJSX_BillToDropdown = () => {
          let dropdownJSX = <></>
          if (rowData != null && rowData.key != null && tableAdditionalData != null && tableAdditionalData.us_selectedSalesPartnerKey != null) {
            let dropdownValue = ''
            if (
              rowData != null &&
              rowData.key != null &&
              tableAdditionalData != null &&
              tableAdditionalData.us_invoiceRates != null &&
              tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
              tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['bill_to'] != null
            ) {
              dropdownValue = tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['bill_to']
            }
            dropdownJSX = (
              <Box>
                <FormControl className="bp_thin_select_input">
                  <Select
                    color="primary"
                    value={dropdownValue}
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          bill_to: event.target.value,
                        }
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(
                              DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                res_GCK.clientKey,
                                tableAdditionalData.us_selectedSalesPartnerKey,
                                'BASE_RATES_' + rowData.key,
                              ),
                              updateObject,
                            )
                              .then((res_DSMD) => {
                                // Nothing
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                          })
                      }
                    }}
                    variant="outlined"
                  >
                    {objectToArray(invoiceBillToOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                        disabled={getProp(option, 'disabled', false)}
                      >
                        {option['name']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )
          }
          return dropdownJSX
        }
        // Full JSX
        cellJSX = <Box>{rJSX_BillToDropdown()}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  billing_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = rLIB('Billing Type')
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        const rJSX_BillingTypeDropdown = () => {
          let dropdownJSX = <></>
          if (rowData != null && rowData.key != null && tableAdditionalData != null && tableAdditionalData.us_selectedSalesPartnerKey != null) {
            let dropdownValue = ''
            if (
              rowData != null &&
              rowData.key != null &&
              tableAdditionalData != null &&
              tableAdditionalData.us_invoiceRates != null &&
              tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
              tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type'] != null
            ) {
              dropdownValue = tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type']
            }
            dropdownJSX = (
              <Box>
                <FormControl className="bp_thin_select_input">
                  <Select
                    color="primary"
                    value={dropdownValue}
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          billing_type: event.target.value,
                          billing_times: {},
                        }
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(
                              DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                res_GCK.clientKey,
                                tableAdditionalData.us_selectedSalesPartnerKey,
                                'BASE_RATES_' + rowData.key,
                              ),
                              updateObject,
                            )
                              .then((res_DSMD) => {
                                // Nothing
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                          })
                      }
                    }}
                    variant="outlined"
                  >
                    {objectToArray(invoiceBillingTypeOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                      >
                        {option['value']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )
          }
          return dropdownJSX
        }
        // Full JSX
        cellJSX = <Box>{rJSX_BillingTypeDropdown()}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  approval_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = rLIB('Approval Type')
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        const rJSX_ApprovalTypeDropdown = () => {
          let dropdownJSX = <></>
          if (rowData != null && rowData.key != null && tableAdditionalData != null && tableAdditionalData.us_selectedSalesPartnerKey != null) {
            let dropdownValue = ''
            if (
              rowData != null &&
              rowData.key != null &&
              tableAdditionalData != null &&
              tableAdditionalData.us_invoiceRates != null &&
              tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
              tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['approval_type'] != null
            ) {
              dropdownValue = tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['approval_type']
            }
            dropdownJSX = (
              <Box>
                <FormControl className="bp_thin_select_input">
                  <Select
                    color="primary"
                    value={dropdownValue}
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          approval_type: event.target.value,
                        }
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(
                              DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                res_GCK.clientKey,
                                tableAdditionalData.us_selectedSalesPartnerKey,
                                'BASE_RATES_' + rowData.key,
                              ),
                              updateObject,
                            )
                              .then((res_DSMD) => {
                                // Nothing
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                          })
                      }
                    }}
                    variant="outlined"
                  >
                    {objectToArray(invoiceApprovalTypeOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                        disabled={getProp(option, 'disabled', false)}
                      >
                        {option['value']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )
          }
          return dropdownJSX
        }
        // Full JSX
        cellJSX = <Box>{rJSX_ApprovalTypeDropdown()}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  CUSTOM_auto_bill: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = rLIB('Automatic Billing')
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnCheckboxBoolean = () => {
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData.us_invoiceRates != null &&
            tableAdditionalData.us_invoiceRates[rowData.key as string] != null &&
            tableAdditionalData.us_invoiceRates[rowData.key as string]['automatic_billing'] != null
          ) {
            return tableAdditionalData.us_invoiceRates[rowData.key as string]['automatic_billing']
          }
          return false
        }
        let cellJSX = (
          <Box>
            <FormControlLabel
              className="tw-ml-1"
              control={
                <Checkbox
                  checked={returnCheckboxBoolean()}
                  onChange={(event) => {
                    // formInputChange('appliance_backup-washer', event.target.checked, true)
                    if (event != null && event.target != null && event.target.checked != null) {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        automatic_billing: event.target.checked,
                      }
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(
                            DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                              res_GCK.clientKey,
                              tableAdditionalData.us_selectedSalesPartnerKey,
                              rowData.key as string,
                            ),
                            updateObject,
                          )
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }
                  }}
                />
              }
              label={<Box className="tw-inline-block"></Box>}
            />
          </Box>
        )
        return cellJSX
      },
    },
  },
  CUSTOM_when_to_bill: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = rLIB('When to bill')
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        const rJSX_DeleteBillingTimeIcon = (billingTimeKey: string) => {
          let iconJSX = (
            <Icon
              icon="trash"
              className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_main tw-ml-1"
              tooltip={rLIB('Delete')}
              tooltipPlacement="right"
              onClick={() => {
                tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                  display: true,
                  confirm: {
                    color: 'error',
                    header: rLIB('Delete invoice time'),
                    icon: (
                      <Icon
                        icon="trash"
                        type="solid"
                      />
                    ),
                    submit_text: rLIB('Delete'),
                    text: rLIB('Are you sure you want to delete this invoice time?'),
                    submit_callback: () => {
                      return new Promise((resolve, reject) => {
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          tableAdditionalData != null &&
                          tableAdditionalData.us_invoiceRates != null &&
                          tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null
                        ) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            billing_times: {
                              [billingTimeKey]: null,
                            },
                          }
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              DatabaseSetMergeDocument(
                                DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                  res_GCK.clientKey,
                                  tableAdditionalData.us_selectedSalesPartnerKey,
                                  'BASE_RATES_' + rowData.key,
                                ),
                                updateObject,
                              )
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              console.error(rej_GCK)
                              reject(rej_GCK)
                            })
                        }
                      })
                    },
                  },
                })
              }}
            />
          )
          return iconJSX
        }
        const rJSX_BillingTimesLineItem = (billingTime: TsInterface_UnspecifiedObject) => {
          let lineItemJSX = <></>
          if (
            // If billing_type is percent and the percent doesn't add up to 100
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData.us_invoiceRates != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type'] == 'percent'
          ) {
            lineItemJSX = (
              <Box>
                <Box
                  className="tw-inline-block tw-mr-2"
                  sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
                >
                  {billingTime.invoice_suffix}
                </Box>
                <Typography className="tw-inline-block tw-mr-2">{billingTime.associated_task_name}:</Typography>
                <Typography className="tw-inline-block">{billingTime.invoice_amount_percent}%</Typography>
                {rJSX_DeleteBillingTimeIcon(billingTime.associated_task_key)}
              </Box>
            )
          } else if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData.us_invoiceRates != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type'] == 'deposit'
          ) {
            if (billingTime != null && billingTime.invoice_amount_type === 'deposit') {
              lineItemJSX = (
                <Box>
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
                  >
                    {billingTime.invoice_suffix}
                  </Box>
                  <Typography className="tw-inline-block tw-mr-2">{billingTime.associated_task_name}:</Typography>
                  <Typography className="tw-inline-block">{formatCurrency(billingTime.invoice_deposit_amount)}</Typography>
                  {rJSX_DeleteBillingTimeIcon(billingTime.associated_task_key)}
                </Box>
              )
            } else if (billingTime != null && billingTime.invoice_amount_type === 'remainder') {
              lineItemJSX = (
                <Box>
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
                  >
                    {billingTime.invoice_suffix}
                  </Box>
                  <Typography className="tw-inline-block tw-mr-2">{billingTime.associated_task_name}:</Typography>
                  <Typography className="tw-inline-block">{rLIB('Remainder')}</Typography>
                  {rJSX_DeleteBillingTimeIcon(billingTime.associated_task_key)}
                </Box>
              )
            } else {
              lineItemJSX = <Json data={billingTime} />
            }
          }
          return lineItemJSX
        }
        const rJSX_BillingTimeErrors = () => {
          let errorsJSX = <></>
          // No Billing Times
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData.us_invoiceRates != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
            (tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times'] == null ||
              objectToArray(tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times']).length == 0)
          ) {
            errorsJSX = <Box className="tw-rounded tw-bg-error_main tw-p-1 tw-inline-block">{rLIB('No times set')}</Box>
          } else if (
            // If billing_type is percent and the percent doesn't add up to 100
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData.us_invoiceRates != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type'] == 'percent'
          ) {
            let percentSum = 0
            for (let billingTimeKey in tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times']) {
              if (tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times'][billingTimeKey] != null) {
                percentSum += tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times'][billingTimeKey]['invoice_amount_percent']
              }
            }
            if (percentSum != 100) {
              errorsJSX = <Box className="tw-rounded tw-bg-error_main tw-p-1 tw-inline-block">{rLIB("Percents don't add up to 100%")}</Box>
            }
          } else if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData.us_invoiceRates != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type'] == 'deposit'
          ) {
            let remainderCount = 0
            for (let billingTimeKey in tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times']) {
              if (tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times'][billingTimeKey] != null) {
                if (tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times'][billingTimeKey]['invoice_amount_type'] == 'remainder') {
                  remainderCount++
                }
              }
            }
            // If billing_type is deposit and there is not a remainder
            if (remainderCount == 0) {
              errorsJSX = <Box className="tw-rounded tw-bg-error_main tw-p-1 tw-inline-block">{rLIB('There must be one remainder')}</Box>
              // If billing_type is deposit and there are multiple remainders
            } else if (remainderCount > 1) {
              errorsJSX = <Box className="tw-rounded tw-bg-error_main tw-p-1 tw-inline-block">{rLIB('There must be only one remainder')}</Box>
            }
          }
          //
          return <Box className="tw-my-1">{errorsJSX}</Box>
        }
        const rJSX_addBillingTimeIconJSX = () => {
          let iconJSX = <></>
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData.us_invoiceRates != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type'] != null &&
            (tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type'] == 'percent' ||
              tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type'] == 'deposit')
          ) {
            iconJSX = (
              <Icon
                icon="circle-plus"
                className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
                tooltip={rLIB('Add billing time')}
                tooltipPlacement="right"
                onClick={() => {
                  // Get Tasks
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, rowData.key as string))
                        .then((res_GD) => {
                          let listOfTasks: TsInterface_UnspecifiedObject = {}
                          if (res_GD != null && res_GD.data != null && res_GD.data.tasks != null) {
                            for (let taskKey in res_GD.data.tasks) {
                              if (res_GD.data.tasks[taskKey] != null) {
                                listOfTasks[taskKey] = {
                                  key: taskKey,
                                  value: res_GD.data.tasks[taskKey].name,
                                }
                              }
                            }
                          }
                          // Percent Form Inputs
                          let percentFormInputs: TsInterface_FormInputs = {
                            associated_task_key: {
                              data_type: 'string',
                              input_type: 'multiple_choice_select',
                              key: 'associated_task_key',
                              label: rLIB('Task to bill after'),
                              required: true,
                              options: Object.values(listOfTasks).sort(dynamicSort('value', 'asc')),
                            },
                            invoice_suffix: {
                              data_type: 'string',
                              input_type: 'text_basic',
                              key: 'invoice_suffix',
                              label: rLIB('Invoice Suffix'),
                              required: true,
                            },
                            invoice_amount_percent: {
                              data_type: 'number',
                              input_type: 'text_number',
                              key: 'invoice_amount_percent',
                              label: rLIB('Invoice Amount Percent'),
                              required: true,
                            },

                            // TODO: Percents don't add up to 100%
                          }
                          // Deposit Form Inputs
                          let depositFormInputs: TsInterface_FormInputs = {
                            associated_task_key: {
                              data_type: 'string',
                              input_type: 'multiple_choice_select',
                              key: 'associated_task_key',
                              label: rLIB('Task to bill after'),
                              required: true,
                              options: Object.values(listOfTasks).sort(dynamicSort('value', 'asc')),
                            },
                            invoice_suffix: {
                              data_type: 'string',
                              input_type: 'text_basic',
                              key: 'invoice_suffix',
                              label: rLIB('Invoice Suffix'),
                              required: true,
                            },
                            invoice_amount_type: {
                              data_type: 'string',
                              input_type: 'multiple_choice_select',
                              key: 'invoice_amount_type',
                              label: rLIB('Invoice Amount Type'),
                              required: true,
                              options: Object.values(depositInvoiceAmountTypeOptions),
                            },
                            invoice_deposit_amount: {
                              data_type: 'number',
                              input_type: 'text_number',
                              key: 'invoice_deposit_amount',
                              label: rLIB('Deposit Amount'),
                              required: true,
                              conditional_display: {
                                active: true,
                                logic: {
                                  active: true,
                                  logic_type: 'comparison',
                                  source: 'formData',
                                  prop: 'invoice_amount_type',
                                  comparator: '==',
                                  value: 'deposit',
                                  conditions: [],
                                },
                              },
                              conditional_require: {
                                active: true,
                                logic: {
                                  active: true,
                                  logic_type: 'comparison',
                                  source: 'formData',
                                  prop: 'invoice_amount_type',
                                  comparator: '==',
                                  value: 'deposit',
                                  conditions: [],
                                },
                              },
                            },

                            // TODO: Error if no remainder / multiple remainders
                          }
                          // Billing Type
                          let billingType = ''
                          if (
                            rowData != null &&
                            rowData.key != null &&
                            tableAdditionalData != null &&
                            tableAdditionalData.us_invoiceRates != null &&
                            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
                            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type'] != null
                          ) {
                            billingType = tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_type']
                          }
                          // Select Form Inputs
                          let selectedFormInputs: TsInterface_FormInputs = {}
                          if (billingType == 'percent') {
                            selectedFormInputs = percentFormInputs
                          } else if (billingType == 'deposit') {
                            selectedFormInputs = depositFormInputs
                          } else {
                            selectedFormInputs = {}
                          }
                          // Open form dialog
                          tableHooks.uc_setUserInterface_FormDialogDisplay({
                            display: true,
                            form: {
                              form: {
                                formAdditionalData: {},
                                formData: {},
                                formInputs: selectedFormInputs,
                                formOnChange: (
                                  formAdditionalData: TsInterface_FormAdditionalData,
                                  formData: TsInterface_FormData,
                                  formInputs: TsInterface_FormInputs,
                                  formSettings: TsInterface_FormSettings,
                                ) => {},
                                formSettings: {},
                                formSubmission: (
                                  formSubmittedData: TsInterface_FormSubmittedData,
                                  formAdditionalData: TsInterface_FormAdditionalData,
                                  formHooks: TsInterface_FormHooksObject,
                                ) => {
                                  return new Promise((resolve, reject) => {
                                    let updateObject: TsInterface_UnspecifiedObject = {}
                                    if (formSubmittedData != null && formSubmittedData['associated_task_key'] != null) {
                                      updateObject['billing_times'] = {
                                        [formSubmittedData['associated_task_key']]: {
                                          associated_task_key: formSubmittedData['associated_task_key'],
                                          associated_task_name: listOfTasks[formSubmittedData['associated_task_key']].value,
                                          invoice_amount_percent: getProp(formSubmittedData, 'invoice_amount_percent', null),
                                          invoice_amount_type: getProp(formSubmittedData, 'invoice_amount_type', null),
                                          invoice_deposit_amount: getProp(formSubmittedData, 'invoice_deposit_amount', null),
                                          invoice_suffix: getProp(formSubmittedData, 'invoice_suffix', null),
                                        },
                                      }
                                    }
                                    // Save to database
                                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                      .then((res_GCK) => {
                                        DatabaseSetMergeDocument(
                                          DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                            res_GCK.clientKey,
                                            tableAdditionalData.us_selectedSalesPartnerKey,
                                            ('BASE_RATES_' + rowData.key) as string,
                                          ),
                                          updateObject,
                                        )
                                          .then((res_DSMD) => {
                                            resolve(res_DSMD)
                                          })
                                          .catch((rej_DSMD) => {
                                            reject(rej_DSMD)
                                          })
                                      })
                                      .catch((rej_GCK) => {
                                        console.error(rej_GCK)
                                        reject(rej_GCK)
                                      })
                                  })
                                },
                              },
                              dialog: {
                                formDialogHeaderColor: 'success',
                                formDialogHeaderText: rLIB('Add billing time'),
                                formDialogIcon: (
                                  <Icon
                                    type="solid"
                                    icon="circle-plus"
                                  />
                                ),
                              },
                            },
                          })
                        })
                        .catch((rej_GC) => {
                          console.error(rej_GC)
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GC.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }}
              />
            )
          }
          return iconJSX
        }
        const rJSX_BillingTimeLineItems = () => {
          let lineItemsJSX = <></>
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData.us_invoiceRates != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key] != null &&
            tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times'] != null
          ) {
            lineItemsJSX = (
              <Box>
                {objectToArray(tableAdditionalData.us_invoiceRates['BASE_RATES_' + rowData.key]['billing_times'])
                  .sort(dynamicSort('invoice_suffix', 'asc'))
                  .map((billingTime: TsInterface_UnspecifiedObject, index: number) => (
                    <Box key={index}>{rJSX_BillingTimesLineItem(billingTime)}</Box>
                  ))}
              </Box>
            )
          }
          return lineItemsJSX
        }
        // Full JSX
        cellJSX = (
          <Box>
            {rJSX_BillingTimeLineItems()}
            {rJSX_addBillingTimeIconJSX()}
            {rJSX_BillingTimeErrors()}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  CUSTOM_default_line_items: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = <></>
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_selectedSalesPartnerKey != null &&
          tableAdditionalData.us_salesPartners != null &&
          tableAdditionalData.us_salesPartners[tableAdditionalData.us_selectedSalesPartnerKey] != null &&
          tableAdditionalData.us_salesPartners[tableAdditionalData.us_selectedSalesPartnerKey]['name'] != null
        ) {
          headerJSX = (
            <Box>
              {rLIB('Default rates for ')} {tableAdditionalData.us_salesPartners[tableAdditionalData.us_selectedSalesPartnerKey]['name']}
            </Box>
          )
        } else {
          headerJSX = (
            <Box>
              {rLIB('Default rates for ')} {rLIB('sales partner')}
            </Box>
          )
        }
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_invoiceLineItemCell(rowData, tableAdditionalData, tableHooks, 'default')
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  CUSTOM_region_line_items: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = <></>
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_regionKey != null &&
          tableAdditionalData.us_setRegionKey != null &&
          tableAdditionalData.us_regionsList != null
        ) {
          headerJSX = (
            <Box>
              <FormControl className="bp_thin_select_input">
                <Select
                  color="primary"
                  value={tableAdditionalData.us_regionKey || ''}
                  onChange={(event: any) => {
                    if (event != null && event.target != null && event.target.value != null) {
                      tableAdditionalData.us_setRegionKey(event.target.value)
                    }
                  }}
                  variant="outlined"
                >
                  {objectToArray(tableAdditionalData.us_regionsList)
                    .sort(dynamicSort('name', null))
                    .map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                      >
                        {option['name']}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )
        } else {
          headerJSX = <Box>{rLIB('Region rates ')}</Box>
        }
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (tableAdditionalData.us_regionKey != null && tableAdditionalData.us_regionKey != '') {
          cellJSX = rJSX_invoiceLineItemCell(rowData, tableAdditionalData, tableHooks, tableAdditionalData.us_regionKey)
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}
