///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_ProjectAdditionalWorkInvoices_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'project_additional_work_invoices', 'main')
}

export const DatabaseRef_ProjectAdditionalWorkInvoice_Document = (clientKey: string, invoiceKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'project_additional_work_invoices', 'main', invoiceKey)
}

export const DatabaseRef_AllProjectAdditionalWorkInvoicesForStatus_Query = (clientKey: string, status: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: status }]
  let orderByArray: TsInterface_OrderByArray = []
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'project_additional_work_invoices', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_AdditionalWorkInvoicesForTask_Query = (clientKey: string, taskKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_task_key', comparator: '==', value: taskKey }]
  let orderByArray: TsInterface_OrderByArray = []
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'project_additional_work_invoices', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_AdditionalWorkInvoicesForProject_Query = (clientKey: string, projectKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: projectKey }]
  let orderByArray: TsInterface_OrderByArray = []
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'project_additional_work_invoices', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

// Logs
export const DatabaseRef_ProjectAdditionalWorkInvoices_Logs_Collection = (clientKey: string, invoiceKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'project_additional_work_invoices', 'main', invoiceKey, 'logs')
}

export const DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document = (
  clientKey: string,
  invoiceKey: string,
  logKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'project_additional_work_invoices', 'main', invoiceKey, 'logs', logKey)
}

export const DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Query = (clientKey: string, invoiceKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'project_additional_work_invoices', 'main', invoiceKey, 'logs'),
    queryOperatorsArray,
    [{ prop: 'timestamp_created', desc: true }],
    {},
    100,
  )
}
