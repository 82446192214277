///////////////////////////////
// Description
///////////////////////////////

import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ProjectFinance_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { StorageRef_ProjectFinanceApprovalDocumentation } from 'rfbp_aux/services/storage_endpoints/projects'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument, DatabaseSetMergeDocument, StorageUploadFile } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { approveProjectBaseInvoice } from '../services/invoice_progress_functions'

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////
///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ApproveBaseInvoiceDialog {
  clientKey: string
  projectKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const ApproveBaseInvoiceDialog: React.FC<TsInterface_ApproveBaseInvoiceDialog> = ({ clientKey, projectKey }): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // const [us_project, us_setProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectFinances, us_setProjectFinances] = useState<TsInterface_UnspecifiedObject>({})
  const [us_approvingInvoice, us_setApprovingInvoice] = useState<boolean>(false)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // Hooks - useEffect
  // useEffect(() => {
  //   let unsubscribeLiveData: TsType_VoidFunction
  //   const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //     us_setProject(newData)
  //     ur_forceRerender()
  //   }
  //   unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(clientKey, projectKey), updateLiveData)
  //   return () => {
  //     if (typeof unsubscribeLiveData === 'function') {
  //       unsubscribeLiveData()
  //     }
  //   }
  // }, [clientKey, projectKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectFinances(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectFinance_Document(clientKey, projectKey, 'base'), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [clientKey, projectKey, ur_forceRerender])

  // Functions
  const determineSubmitButtonDisabled = (): boolean => {
    let disabled = false
    if (
      getProp(us_projectFinances, 'approval_type', null) === 'internal_required_evidence' &&
      getProp(us_projectFinances, 'approval_evidence_uploaded', false) !== true
    ) {
      disabled = true
    }
    return disabled
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (event.target.files) {
        uploadFiles(event, additionalFileUploadParams)
          .then((urls) => {
            resolve(urls)
          })
          .catch((error) => {
            console.error('Error uploading files:', error)
            reject(error)
          })
      } else {
        reject('No files selected')
      }
    })
  }

  const uploadFiles = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (event?.target?.files?.length) {
        // File Selection
        const readAsDataURL = (file: any) => {
          return new Promise((resolve, reject) => {
            const fr = new FileReader()
            fr.onerror = reject
            fr.onload = () => {
              resolve(fr.result)
            }
            fr.readAsDataURL(file)
          })
        }
        const promiseArray: Promise<any>[] = []
        const promiseArray2: Promise<any>[] = []
        const files = event.target.files
        const readFiles: TsInterface_UnspecifiedObject = {}
        // Read files as Data URLs
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (file) {
            promiseArray.push(
              readAsDataURL(file)
                .then((dataURL) => {
                  readFiles[fileIndex] = { file_name: file.name, file, data_url: dataURL }
                })
                .catch(console.error),
            )
          }
        }
        Promise.all(promiseArray)
          .then(() => {
            let updateObject: TsInterface_UnspecifiedObject = {
              approval_evidence_uploaded: true,
              approval_evidence_files: {},
            }
            const uploadedUrls: string[] = []
            for (let loopFileKey in readFiles) {
              let loopFile = readFiles[loopFileKey]
              const imageKey = uuidv4() // Generate a unique key for each image
              const timestamp_uploaded = new Date()
              promiseArray2.push(
                StorageUploadFile(StorageRef_ProjectFinanceApprovalDocumentation(clientKey, projectKey, loopFile.file_name), loopFile.file, {
                  clientKey: additionalFileUploadParams.clientKey,
                  projectKey: additionalFileUploadParams.projectKey,
                })
                  .then((res) => {
                    const url = (res as { url: string }).url
                    if (url) {
                      uploadedUrls.push(url)
                      const imageData = {
                        key: imageKey,
                        name: loopFile.file_name,
                        timestamp_uploaded,
                        url,
                        tags: additionalFileUploadParams.tags || [],
                        associated_uploader_name: additionalFileUploadParams.associated_uploader_name || null,
                      }
                      updateObject.approval_evidence_files[imageKey] = imageData
                    }
                  })
                  .catch(console.error),
              )
            }
            Promise.all(promiseArray2)
              .then(() => {
                DatabaseSetMergeDocument(DatabaseRef_ProjectFinance_Document(clientKey, projectKey, 'base'), updateObject)
                  .then((res_DSMD) => {
                    resolve(uploadedUrls)
                  })
                  .catch(reject)
              })
              .catch(reject)
          })
          .catch(reject)
      } else {
        reject('No valid files to upload')
      }
    })
  }

  // JSX Generation
  const rJSX_EvidenceUploadButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (
      getProp(us_projectFinances, 'approval_type', null) === 'internal_required_evidence' ||
      getProp(us_projectFinances, 'approval_type', null) === 'internal_optional_evidence'
    ) {
      buttonJSX = (
        <FileUploadButton
          multiple={true}
          button={{
            text: rLIB('Upload Approval Documentation') as JSX.Element,
            icon: (
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
                sx={{ fontSize: '20px' }}
              />
            ),
            color: 'info',
            className: '',
            variant: 'contained',
            disabled: false,
          }}
          accept="*"
          onChange={handleFileUpload}
          additionalFileUploadParams={{}}
        />
      )
    }
    return buttonJSX
  }

  const rJSX_ApproveButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={
          us_approvingInvoice === true ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="badge-check" />
          )
        }
        disabled={us_approvingInvoice === true || determineSubmitButtonDisabled()}
        onClick={() => {
          us_setApprovingInvoice(true)
          approveProjectBaseInvoice(clientKey, projectKey, true)
            .then((res_APBI) => {
              us_setApprovingInvoice(false)
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
            })
            .catch((rej_APBI) => {
              us_setApprovingInvoice(false)
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_APBI.error })
            })
        }}
      >
        {rLIB('Approve Base Project Quote')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_EvidenceUploadedList = (): JSX.Element => {
    let evidenceUploadedListJSX = <></>
    if (
      us_projectFinances != null &&
      (getProp(us_projectFinances, 'approval_type', null) === 'internal_required_evidence' ||
        getProp(us_projectFinances, 'approval_type', null) === 'internal_optional_evidence') &&
      objectToArray(getProp(us_projectFinances, 'approval_evidence_files', {})).length > 0
    ) {
      evidenceUploadedListJSX = (
        <Box className="tw-mb-1">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.primary_main }}
            >
              <Icon
                icon="image"
                className="tw-mr-2"
              />
              {rLIB('Approval Documentation')}:
            </Typography>
            <Box></Box>
          </Stack>
          <Box className="tw-ml-6">
            {objectToArray(getProp(us_projectFinances, 'approval_evidence_files', {}))
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body1">{option.name}</Typography>
                  <Icon
                    icon="cloud-arrow-down"
                    className="tw-cursor-pointer tw-ml-2 hover:tw-opacity-100 tw-opacity-50"
                    tooltip={rLIB('Download')}
                    tooltipPlacement="right"
                    onClick={() => {
                      // Download URL
                      window.open(option.url, '_blank')
                    }}
                  />
                </Stack>
              ))}
          </Box>
        </Box>
      )
    }
    return evidenceUploadedListJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Dialog
        // TransitionComponent={ Transition }
        className="bp_dialog_md_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 1, color: '#fff !important' }}
            >
              <Icon icon="file-invoice-dollar" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              <Box className="tw-inline-block">{rLIB('Project Base Invoice')}</Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box className="tw-p-4">
            {/* {rJSX_ProjectBasePriceCardContent(
              us_project,
              uc_RootData_ClientKey,
              projectKey,
              uc_setRootData_ClientKey,
              uc_setUserInterface_ErrorDialogDisplay,
              {
                base: us_projectFinances,
              },
              uc_setUserInterface_CustomDialogDisplay,
              {},
            )} */}
            {rJSX_EvidenceUploadedList()}
          </Box>
          <Box className="tw-mt-0 tw-mb-2 tw-text-center">{rJSX_EvidenceUploadButton()}</Box>
          <Box className="tw-mb-4 tw-text-center">{rJSX_ApproveButton()}</Box>
        </DialogContent>
      </Dialog>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

// TODO: Upload Evidence
