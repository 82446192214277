///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

        	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { eachWeekOfInterval, endOfMonth, format, isWithinInterval, startOfMonth, startOfWeek } from 'date-fns'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_CombinedVehiclesByDate_Query,
  DatabaseRef_CycleTimeByDate_Query,
  DatabaseRef_CycleTimeThresholds_Collection,
  DatabaseRef_CycleTimeThresholds_Document,
  DatabaseRef_NewCrewHoursByDate_Query,
  DatabaseRef_PanelsInstalledByDate_Query,
  DatabaseRef_SafetyReviewByDate_Query,
} from 'rfbp_aux/services/database_endpoints/operations/gm_dashboards'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_CustomDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { downloadCSV, formatDateToYYYYMMDD, objectToArray, returnFormattedDateKey } from '../../../rfbp_core/services/helper_functions/index'
import { GMDashboardHeatmap } from './components/gm_dashboard_heatmap'

import { getDocs } from 'firebase/firestore'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_FinalizedPayroll_EmployeeFullPayrollDataByDateAndName_Query } from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import { DatabaseRef_ProjectsForSpecificRegion_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_ScheduledTimeOff_Query } from 'rfbp_aux/services/database_endpoints/operations/schedules'
import { DatabaseRef_TasksByDateRange_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatabaseRef_TrailerAssignmentHistory_Collection } from 'rfbp_aux/services/database_endpoints/operations/trailer_assignment_history'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { processCrewHoursData } from './services/data_crew_hours'
import { processCycleTimeCSToInstallData } from './services/data_cycle_time_cs_to_install'
import { processCycleTimeCSToPermitRequested } from './services/data_cycle_time_cs_to_permit_requested'
import { processCycleTimeCSToPto } from './services/data_cycle_time_cs_to_pto'
import { processCycleTimePermitApprovalToInstallData } from './services/data_cycle_time_permit_approval_to_install'
import { processCycleTimeRtgToInstallData } from './services/data_cycle_time_rtg_to_install'
import { processPanelsInstalledData } from './services/data_panels_installed'
import { processPayInefficiencyData } from './services/data_pay_inefficiency'
import { processSafetyReviewData } from './services/data_safety_review'
import { processVehicleMilesData } from './services/data_vehicle_usage'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['GMDashboardIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const currentDate = new Date()
  const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
  const lastDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  const [us_selectedStartDate, us_setSelectedStartDate] = useState<Date>(firstDayOfPreviousMonth)
  const [us_selectedEndDate, us_setSelectedEndDate] = useState<Date>(lastDayOfCurrentMonth)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_refreshingColumn, us_setRefreshingColumn] = useState<string | null>(null)
  const [us_mondaysInDateRange, us_setMondaysInDateRange] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawCrewHours, us_setRawCrewHours] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedCrewHours, us_setFormattedCrewHours] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalCrewHours, us_setFinalCrewHours] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedSafetyReviews, us_setFormattedSafetyReviews] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalSafetyReviews, us_setFinalSafetyReviews] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesPartners, us_setSalesPartners] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawPanelsInstalled, us_setRawPanelsInstalled] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedPanelsInstalled, us_setFormattedPanelsInstalled] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalPanelsInstalled, us_setFinalPanelsInstalled] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawRTGToInstall, us_setRawRTGToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedRTGToInstall, us_setFormattedRTGToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalRTGToInstall, us_setFinalRTGToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const [us_downloadingCycleTimes, us_setDownloadingCycleTimes] = useState<boolean>(false)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const [us_organizedTimeOff, us_setOrganizedTimeOff] = useState<any>({})
  const [us_formattedCSToInstall, us_setFormattedCSToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalCSToInstall, us_setFinalCSToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedPermitApprovalToInstall, us_setFormattedPermitApprovalToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalPermitApprovalToInstall, us_setFinalPermitApprovalToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedCsToPto, us_setFormattedCsToPto] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalCsToPto, us_setFinalCsToPto] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedCsToPermitRequested, us_setFormattedCsToPermitRequested] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalCsToPermitRequested, us_setFinalCsToPermitRequested] = useState<TsInterface_UnspecifiedObject>({})
  const [us_regionsLists, us_setRegionsLists] = useState<TsInterface_UnspecifiedObject>({})
  const [us_trailerAssignments, us_setTrailerAssignments] = useState<TsInterface_UnspecifiedObject>({})
  const [us_combinedVehicles, us_setCombinedVehicles] = useState<any>({})
  const [us_payInefficiencyData, us_setPayInefficiencyData] = useState<any>({})
  const [us_finalPayInefficiencyData, us_setFinalPayInefficiencyData] = useState<any>({})
  const [us_formattedCombinedVehicles, us_setFormattedCombinedVehicles] = useState<any>({})
  const [us_finalCombinedVehicles, us_setFinalCombinedVehicles] = useState<any>({})
  const [us_cycleTimeLimits, us_setCycleTimeLimits] = useState<any>({})
  const [us_rtgToInstallLimit, us_setRTGToInstallLimit] = useState<any>({ value: 0, id: null })
  const [us_CSToInstallLimit, us_setCSToInstallLimit] = useState<any>({ value: 0, id: null })
  const [us_permitApprovalToInstallLimit, us_setPermitApprovalToInstallLimit] = useState<any>({ value: 0, id: null })
  const [us_CSToPtoLimit, us_setCSToPtoLimit] = useState<any>({ value: 0, id: null })
  const [us_CSToPermitRequestedLimit, us_setCSToPermitRequestedLimit] = useState<any>({ value: 0, id: null })
  const [us_isDialogOpen, us_setIsDialogOpen] = useState<boolean>(false)
  const [us_newLimit, us_setNewLimit] = useState<number>(0)
  const [us_currentDocID, us_setCurrentDocID] = useState<string>()
  const [us_dialogTitle, us_setDialogTitle] = useState<string>()

  const [openExportDialog, setOpenExportDialog] = useState(false)

  const [sortConfig, setSortConfig] = useState<{ column: number; direction: string } | null>(null)

  const [sortConfigCrewHours, setSortConfigCrewHours] = useState<{ column: number; direction: string } | null>(null)
  const [sortConfigPanelsInstalled, setSortConfigPanelsInstalled] = useState<{ column: number; direction: string } | null>(null)
  const [sortConfigPayInefficiency, setSortConfigPayInefficiency] = useState<{ column: number; direction: string } | null>(null)
  const [sortConfigSafetyReviews, setSortConfigSafetyReviews] = useState<{ column: number; direction: string } | null>(null)

  const [us_selectedRegion, us_setSelectedRegion] = useState<string>('')

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  //DO NOT DELETE: For testing caching functions
  // useEffect(() => {
  //   cloudFunctionManageRequest('manageData', {
  //     function: 'cacheGMDashboardPanelsInstalledData',
  //     client_key: 'etw_energy',
  //     monday_date_key: new Date('2024-07-08 00:00:00').toISOString(),
  //   })
  //     .then((res_CFMR) => {
  //
  //       us_setRefreshingColumn(null)
  //     })
  //     .catch((rej_CFMR) => {
  //       console.error(rej_CFMR)
  //       us_setRefreshingColumn(null)
  //     })
  // }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('GM Dashboard', false) as string
  }, [])

  //Get Mondays in Date Range
  useEffect(() => {
    const interval = { start: us_selectedStartDate, end: us_selectedEndDate }

    // Generate all the Mondays within the interval
    const mondays = eachWeekOfInterval(interval, { weekStartsOn: 1 })
      .map((weekStart) => startOfWeek(weekStart, { weekStartsOn: 1 }))
      .filter((monday) => isWithinInterval(monday, interval)) // Check if the Monday is within the interval
      .map((monday) => monday.toISOString().split('T')[0]) // Convert to string format

    us_setMondaysInDateRange(mondays)
  }, [us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  // Get Regions
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionsLists(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  //Get and process Safety Reviews
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // Process the raw data using the service function
      const processedData = processSafetyReviewData(newData, us_mondaysInDateRange as any)

      // Set the processed data into the state
      us_setFormattedSafetyReviews(processedData)

      ur_forceRerender()
    }

    if (!uc_RootData_ClientKey) {
      return
    }

    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SafetyReviewByDate_Query(uc_RootData_ClientKey, startDate, endDate), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender, us_mondaysInDateRange, us_refreshingColumn])

  //handle refreshing column logic for safety reviews
  useEffect(() => {
    if (!us_formattedSafetyReviews || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedSafetyReviews,
      'safety_reviews_', // dashboardName prefix
      'cacheGMDashboardSafetyReviewData', // corresponding cloud function
    )

    // Save the final data with dialogs into the state
    us_setFinalSafetyReviews(refreshedData)
  }, [us_formattedSafetyReviews, us_mondaysInDateRange])

  //get time off data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      const organizedTimeOffData = organizeTimeOffByWeekAndTeam(newData, us_mondaysInDateRange as any)
      us_setOrganizedTimeOff(organizedTimeOffData) // Assuming you have a state to store this data
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ScheduledTimeOff_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {})

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, us_mondaysInDateRange])

  //test for trailer assignments
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      const organizedTimeOffData = newData
      us_setTrailerAssignments(organizedTimeOffData) // Assuming you have a state to store this data
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TrailerAssignmentHistory_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {})

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey])

  //get cycle time limits data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let cycleTimeData = objectToArray(newData)
      us_setCycleTimeLimits(cycleTimeData) // Assuming you have a state to store this data
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_CycleTimeThresholds_Collection(uc_RootData_ClientKey as any), updateLiveData)
      })
      .catch((rej_GCK) => {})

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey])

  // Process cycle time limits
  useEffect(() => {
    if (us_cycleTimeLimits.length > 0) {
      us_cycleTimeLimits.forEach((field: any) => {
        if (field.name === 'rtg_to_install') {
          us_setRTGToInstallLimit({ value: field.limit, id: field.name }) // Set RTG to Install limit
        } else if (field.name === 'cs_to_install') {
          us_setCSToInstallLimit({ value: field.limit, id: field.name }) // Set CS to Install limit
        } else if (field.name === 'permit_approval_to_install') {
          us_setPermitApprovalToInstallLimit({ value: field.limit, id: field.name }) // Set Permit Approval to Install limit
        } else if (field.name === 'cs_to_pto') {
          us_setCSToPtoLimit({ value: field.limit, id: field.name }) // Set CS to PTO limit
        } else if (field.name === 'cs_to_permit_requested') {
          us_setCSToPermitRequestedLimit({ value: field.limit, id: field.name }) // Set CS to Permit Requested limit
        }
      })
    }
  }, [us_cycleTimeLimits]) // Runs whenever us_cycleTimeLimits is updated

  // Get and process panels installed
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRawPanelsInstalled(newData)

      // Process the raw data using the service function only if time off data is available
      if (us_organizedTimeOff && Object.keys(us_organizedTimeOff).length > 0) {
        const processedData = processPanelsInstalledData(newData, us_mondaysInDateRange as any, us_organizedTimeOff)

        // Set the processed data into the state
        us_setFormattedPanelsInstalled(processedData as any)

        ur_forceRerender()
      }
    }

    // Ensure necessary data is available before processing
    if (
      !uc_RootData_ClientKey ||
      !us_organizedTimeOff ||
      Object.keys(us_organizedTimeOff).length === 0 ||
      !us_mondaysInDateRange ||
      us_mondaysInDateRange.length === 0
    ) {
      return
    }

    // Define the start and end dates for the data query
    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    // Fetch live data for panels installed and update the state
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_PanelsInstalledByDate_Query(uc_RootData_ClientKey, startDate, endDate), updateLiveData)

    // Cleanup the subscription when the component is unmounted
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, us_mondaysInDateRange, us_organizedTimeOff, us_refreshingColumn])

  // Handle sorting and refreshing column logic and dialog for panels installed
  useEffect(() => {
    if (!us_formattedPanelsInstalled || !us_mondaysInDateRange || us_mondaysInDateRange.length === 0) return

    // Set default sorting to the current week when data is first loaded
    if (!sortConfigPanelsInstalled) {
      const currentMondayIndex = objectToArray(us_mondaysInDateRange).findIndex((monday: string) => {
        const currentMondayDate = getCurrentMonday()
        return monday === currentMondayDate
      })

      if (currentMondayIndex >= 0) {
        // Set default sort by the current week, ascending order (+1 to skip the name column)
        setSortConfigPanelsInstalled({ column: currentMondayIndex + 1, direction: 'desc' })
      }
    }

    // Ensure sortConfigPanelsInstalled is available before applying sorting
    if (sortConfigPanelsInstalled) {
      const { column, direction } = sortConfigPanelsInstalled

      us_formattedPanelsInstalled.data_rows?.sort((a: any, b: any) => {
        const aValue = a[column]?.cellValue ?? ''
        const bValue = b[column]?.cellValue ?? ''

        // Parse to numeric if applicable, else sort as strings
        const numA = parseFloat(aValue)
        const numB = parseFloat(bValue)

        if (!isNaN(numA) && !isNaN(numB)) {
          return direction === 'asc' ? numA - numB : numB - numA
        } else {
          return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
        }
      })
    }

    // Refresh the columns and handle dialog setup
    const refreshedData = handleRefreshColumns(us_formattedPanelsInstalled, 'panels_installed_', 'cacheGMDashboardPanelsInstalledData')
    const finalData = handleDialogSetup(refreshedData)

    // Save the final processed data to state
    us_setFinalPanelsInstalled(finalData)
  }, [us_formattedPanelsInstalled, us_mondaysInDateRange, sortConfigPanelsInstalled])

  // Helper function to get the current Monday
  const getCurrentMonday = (): string => {
    const date = new Date()
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1) // Get this week's Monday
    date.setDate(diff)
    return date.toISOString().split('T')[0] // Format to 'yyyy-MM-dd'
  }

  const handleDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any) =>
      row.map((cell: any) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('/')) {
          const [installedPanels, expectedPanels] = cell.cellValue.split('/').map(Number)
          const dateKey = cell.dateKey
          const teamName = row[0].cellValue

          return {
            ...cell,
            dialogFunction: () => {
              const teamDetails = us_rawPanelsInstalled[dateKey]?.data?.[teamName] || {}

              if (!teamDetails.tasks) return

              const totalInstalled = teamDetails.tasks.reduce((acc: any, task: any) => acc + task.panels + task.batteries * 10, 0)
              const totalPanels = teamDetails.tasks.reduce((acc: any, task: any) => acc + task.panels, 0)
              const totalBatteries = teamDetails.tasks.reduce((acc: any, task: any) => acc + task.batteries, 0)

              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">
                          {teamName} - {dateKey}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: installedPanels >= expectedPanels ? '#28a56c' : '#c82424',
                            fontWeight: 'bold',
                          }}
                        >
                          {installedPanels} / {expectedPanels} panels
                        </Typography>

                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Task Name')}</TableCell>
                                <TableCell>{rLIB('Completion Date')}</TableCell>
                                <TableCell>{rLIB('Panels')}</TableCell>
                                <TableCell>{rLIB('Batteries')}</TableCell>
                                <TableCell>{rLIB('Total')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {teamDetails.tasks
                                .sort((a: any, b: any) => new Date(a.latest_task_completion_date).getTime() - new Date(b.latest_task_completion_date).getTime())
                                .map((task: any, index: any) => (
                                  <TableRow key={index}>
                                    <TableCell>{task.associated_project_id_number}</TableCell>
                                    <TableCell>{task.project_name}</TableCell>
                                    <TableCell>
                                      {new Date(
                                        new Date(task.latest_task_completion_date).setDate(new Date(task.latest_task_completion_date).getDate() + 1),
                                      ).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>{task.panels}</TableCell>
                                    <TableCell>{task.batteries}</TableCell>
                                    <TableCell>{task.panels + task.batteries * 10}</TableCell>
                                  </TableRow>
                                ))}
                              <TableRow>
                                <TableCell className="tw-font-bold">Total</TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell className="tw-font-bold">{totalPanels}</TableCell>
                                <TableCell className="tw-font-bold">{totalBatteries}</TableCell>
                                <TableCell className="tw-font-bold">{totalInstalled}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: installedPanels > 0 ? 'pointer' : 'default',
            },
          }
        }
        return cell
      }),
    )

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time rtg - install
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSalesPartners(newData)
    }

    if (!uc_RootData_ClientKey) {
      return
    }

    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SalesPartner_Collection(uc_RootData_ClientKey), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey])

  useEffect(() => {
    if (!us_salesPartners) return // Wait for sales partners data

    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: any) => {
      const enrichedData = Object.keys(newData).reduce((acc: any, dateKey: any) => {
        const dailyData = newData[dateKey].data
        const enrichedDailyData = Object.keys(dailyData).reduce((accInner: any, projectKey: any) => {
          const projectData = dailyData[projectKey]
          const salesPartnerName = us_salesPartners[projectData.associated_sales_partner_key]?.name || 'Unknown Partner'
          accInner[projectKey] = { ...projectData, salesPartnerName }
          return accInner
        }, {})
        acc[dateKey] = { ...newData[dateKey], data: enrichedDailyData }
        return acc
      }, {})

      us_setRawRTGToInstall(enrichedData)
    }

    if (!uc_RootData_ClientKey) {
      return
    }

    const newStartDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const newEndDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_CycleTimeByDate_Query(uc_RootData_ClientKey, newStartDate, newEndDate), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, us_salesPartners, us_cycleTimeLimits])

  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimeRtgToInstallData(us_rawRTGToInstall, us_mondaysInDateRange as any, us_salesPartners, us_rtgToInstallLimit as any)
    us_setFormattedRTGToInstall(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedRTGToInstall || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedRTGToInstall,
      'cycle_times_rtg_to_install', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    // Avoid unnecessary state updates by checking if the data has actually changed

    const finalData = handleCycleTimeDialogSetup(refreshedData)
    us_setFinalRTGToInstall(finalData)
  }, [us_formattedRTGToInstall, us_mondaysInDateRange])

  const handleCycleTimeDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_rtg_to_install !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_rtg_to_install, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('PICC/RTG')}</TableCell>
                                <TableCell>{rLIB('Install')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.install_picc_rtg).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{project.install_date}</TableCell>
                                  <TableCell>{project.time_delay_from_rtg_to_install}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time CS - Install
  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimeCSToInstallData(us_rawRTGToInstall, us_mondaysInDateRange as any, us_salesPartners, us_CSToInstallLimit.value)
    us_setFormattedCSToInstall(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedCSToInstall || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedCSToInstall,
      'cycle_times_cs-to-install', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    const finalData = handleCycleTimeCSToInstallDialogSetup(refreshedData)
    us_setFinalCSToInstall(finalData)
  }, [us_formattedCSToInstall, us_mondaysInDateRange])

  const handleCycleTimeCSToInstallDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_cs_to_install !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_cs_to_install, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('Customer Signature')}</TableCell>
                                <TableCell>{rLIB('Install')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.sale_customer_signature).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{project.install_date}</TableCell>
                                  <TableCell>{project.time_delay_from_cs_to_install}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time Permit Approval - Install
  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimePermitApprovalToInstallData(
      us_rawRTGToInstall,
      us_mondaysInDateRange as any,
      us_salesPartners,
      us_permitApprovalToInstallLimit.value as any,
    )
    us_setFormattedPermitApprovalToInstall(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedPermitApprovalToInstall || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedPermitApprovalToInstall,
      'cycle_times_permit_approval_to_install', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    // Avoid unnecessary state updates by checking if the data has actually changed

    const finalData = handleCycleTimePermitApprovalToInstallDialogSetup(refreshedData)
    us_setFinalPermitApprovalToInstall(finalData)
  }, [us_formattedPermitApprovalToInstall, us_mondaysInDateRange])

  const handleCycleTimePermitApprovalToInstallDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_permit_approval_to_install !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_permit_approval_to_install, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('Permit Approval')}</TableCell>
                                <TableCell>{rLIB('Install')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.permit_approval).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{project.install_date}</TableCell>
                                  <TableCell>{project.time_delay_from_permit_approval_to_install}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time CS - PTO
  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimeCSToPto(us_rawRTGToInstall, us_mondaysInDateRange as any, us_salesPartners, us_CSToPtoLimit.value)
    us_setFormattedCsToPto(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedCsToPto || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedCsToPto,
      'cycle_times_cs_to_pto', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    const finalData = handleCycleTimeCsToPtoDialogSetup(refreshedData)
    us_setFinalCsToPto(finalData)
  }, [us_formattedCsToPto, us_mondaysInDateRange])

  const handleCycleTimeCsToPtoDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_customer_signature_to_pto !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_customer_signature_to_pto, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('Customer Signature')}</TableCell>
                                <TableCell>{rLIB('PTO')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.sale_customer_signature).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{new Date(project.activation_final_interconnection_approval).toISOString().split('T')[0]}</TableCell>

                                  <TableCell>{project.time_delay_from_customer_signature_to_pto}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time CS - Permit Requested
  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimeCSToPermitRequested(us_rawRTGToInstall, us_mondaysInDateRange as any, us_salesPartners)
    us_setFormattedCsToPermitRequested(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedCsToPermitRequested || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedCsToPermitRequested,
      'cycle_times_cs_to_permit_requested', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    const finalData = handleCycleTimeCsToPermitRequestedDialogSetup(refreshedData)
    us_setFinalCsToPermitRequested(finalData)
  }, [us_formattedCsToPermitRequested, us_mondaysInDateRange])

  const handleCycleTimeCsToPermitRequestedDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_customer_signature_to_permit_requested !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_customer_signature_to_permit_requested, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('Customer Signature')}</TableCell>
                                <TableCell>{rLIB('Permit Requested')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.sale_customer_signature).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{new Date(project.permitting_and_approvals_building_permit_submission).toISOString().split('T')[0]}</TableCell>

                                  <TableCell>{project.time_delay_from_customer_signature_to_permit_requested}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  // Fetch crew hours data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRawCrewHours(newData)
      ur_forceRerender() // Trigger re-render
    }

    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_NewCrewHoursByDate_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error('Error fetching client key:', rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  // Process raw crew hours data and apply sorting
  useEffect(() => {
    if (!us_rawCrewHours || !us_mondaysInDateRange) return

    // Process the raw data
    const processedData = processCrewHoursData(us_rawCrewHours, us_mondaysInDateRange as any)

    // If no sortConfig exists, set default sorting by previous Monday
    if (!sortConfigCrewHours && us_mondaysInDateRange.length > 0) {
      const previousMondayIndex = objectToArray(us_mondaysInDateRange).findIndex((monday: any) => monday === getPreviousMonday())

      if (previousMondayIndex >= 0) {
        setSortConfigCrewHours({ column: previousMondayIndex + 1, direction: 'desc' }) // +1 to skip the name column
      }
    }

    // Apply sorting based on sortConfigCrewHours
    if (sortConfigCrewHours) {
      const { column, direction } = sortConfigCrewHours

      processedData.data_rows.sort((a: any, b: any) => {
        const aValue = a[column]?.cellValue ?? ''
        const bValue = b[column]?.cellValue ?? ''

        // Parse to numeric if applicable, else sort as strings
        const numA = parseFloat(aValue)
        const numB = parseFloat(bValue)

        if (!isNaN(numA) && !isNaN(numB)) {
          return direction === 'asc' ? numA - numB : numB - numA
        } else {
          return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
        }
      })
    }

    // Save processed and sorted data to state
    us_setFormattedCrewHours(processedData)
  }, [us_rawCrewHours, us_mondaysInDateRange, sortConfigCrewHours])

  // Helper function to get previous Monday
  const getPreviousMonday = (): string => {
    const date = new Date()
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1) - 7 // Get last week's Monday
    date.setDate(diff)
    return date.toISOString().split('T')[0] // Format to 'yyyy-MM-dd'
  }

  // Finalize crew hours data and handle refresh and dialogs
  // Finalize crew hours data and handle refresh and dialogs
  useEffect(() => {
    if (!us_formattedCrewHours || !us_mondaysInDateRange) return

    // Refresh columns
    const refreshedData = handleRefreshColumns(
      us_formattedCrewHours,
      'crew_hours_', // Dashboard name prefix
      'cacheGMDashboardCrewHoursData', // Corresponding cloud function
    )

    // Apply dialog setup
    const finalData = handleCrewHoursDialogSetup(refreshedData)

    // Update the final crew hours to trigger re-render
    us_setFinalCrewHours(finalData)
  }, [us_formattedCrewHours, us_mondaysInDateRange, sortConfigCrewHours])

  const handleCrewHoursDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) =>
      row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && !isNaN(Number(cell.cellValue))) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const personName = row[0].cellValue // First cell contains the person's name
          let personKey: string | undefined

          // Find the correct key for the person based on the name
          Object.entries(us_rawCrewHours[formattedDateKey]?.data || {}).forEach(([key, data]: [string, any]) => {
            if (data.name === personName) {
              personKey = key
            }
          })

          if (personKey) {
            return {
              ...cell,
              dialogFunction: () => {
                const formattedDateForQuery = formatDateToYYYYMMDD(new Date(new Date(formattedDateKey).setDate(new Date(formattedDateKey).getDate() + 1)))

                const query = DatabaseRef_FinalizedPayroll_EmployeeFullPayrollDataByDateAndName_Query(
                  uc_RootData_ClientKey as any,
                  formattedDateForQuery,
                  personKey!,
                )

                getDocs(query)
                  .then((snapshot) => {
                    const fetchedData: any = snapshot.docs.map((doc) => doc.data())

                    // Extract the pay inefficiency
                    const payInefficiency = fetchedData.length > 0 ? fetchedData[0].pay_inefficiency || 0 : 0

                    const projectDetails: any = []
                    const groupedCodesHours: any = fetchedData[0]?.grouped_codes?.FIELD || {}
                    const unitPayTaskBreakdown = fetchedData[0]?.unit_pay_task_breakdown || {}

                    // Track processed tasks to avoid duplicates
                    const processedTasks = new Set()

                    // First: Include all tasks in `grouped_codes.FIELD` and combine with task data
                    Object.entries(groupedCodesHours).forEach(([jobCode, hours]: [string, any]) => {
                      if (!processedTasks.has(jobCode)) {
                        const [job, jobType] = jobCode.split(' - ')
                        const taskBreakdown: any = Object.values(unitPayTaskBreakdown).find((task: any) => task.key === jobCode)

                        // Default task data to 0 if missing
                        let units = taskBreakdown?.breakdown?.task_units || 0
                        let batteryAdders = taskBreakdown?.breakdown?.battery_units || 0
                        let pitchAdders = taskBreakdown?.breakdown?.pitch_units || 0
                        let distanceAdders = taskBreakdown?.breakdown?.distance_units || 0

                        // Divide by 2 if the task evaluation is "panels"
                        if (taskBreakdown?.task_unit_evaluation === 'panels') {
                          units /= 2
                          batteryAdders /= 2
                          pitchAdders /= 2
                          distanceAdders /= 2
                        }

                        let totalUnits = units + batteryAdders + pitchAdders + distanceAdders
                        let total = hours - totalUnits

                        projectDetails.push({
                          jobCode: job,
                          jobType,
                          units,
                          batteryAdders,
                          pitchAdders,
                          distanceAdders,
                          hours: hours.toFixed(2), // Use hours from grouped_codes
                          totalUnits: totalUnits.toFixed(2),
                          total: total.toFixed(2),
                        })

                        // Mark this task as processed to avoid duplication
                        processedTasks.add(jobCode)
                      }
                    })

                    // Second: Process tasks from `unit_pay_task_breakdown` that don't have hours
                    Object.entries(unitPayTaskBreakdown).forEach(([key, task]: [string, any]) => {
                      if (!processedTasks.has(task.key)) {
                        let units = task.breakdown?.task_units || 0
                        let batteryAdders = task.breakdown?.battery_units || 0
                        let pitchAdders = task.breakdown?.pitch_units || 0
                        let distanceAdders = task.breakdown?.distance_units || 0

                        if (task.task_unit_evaluation === 'panels') {
                          units /= 2
                          batteryAdders /= 2
                          pitchAdders /= 2
                          distanceAdders /= 2
                        }

                        let totalUnits = units + batteryAdders + pitchAdders + distanceAdders

                        projectDetails.push({
                          jobCode: task.key.split(' - ')[0],
                          jobType: task.key.split(' - ')[1],
                          units,
                          batteryAdders,
                          pitchAdders,
                          distanceAdders,
                          hours: '0.00', // No hours for this task
                          totalUnits: totalUnits.toFixed(2),
                          total: (-totalUnits).toFixed(2), // Negative total since there are no hours
                        })

                        // Mark this task as processed to avoid duplication
                        processedTasks.add(task.key)
                      }
                    })

                    // Sort the projectDetails by the total (difference) from highest to lowest
                    projectDetails.sort((a: any, b: any) => parseFloat(b.total) - parseFloat(a.total))

                    // Totals calculation logic here
                    const totalsForDialog = {
                      units: 0,
                      batteryAdders: 0,
                      pitchUnits: 0,
                      distanceAdders: 0,
                      totalUnits: 0,
                      hours: 0,
                      total: 0,
                    }

                    projectDetails.forEach((project: any) => {
                      totalsForDialog.units += parseFloat(project.units)
                      totalsForDialog.batteryAdders += parseFloat(project.batteryAdders)
                      totalsForDialog.pitchUnits += parseFloat(project.pitchAdders)
                      totalsForDialog.distanceAdders += parseFloat(project.distanceAdders)
                      totalsForDialog.hours += parseFloat(project.hours)
                      totalsForDialog.totalUnits += parseFloat(project.totalUnits)
                      totalsForDialog.total += parseFloat(project.total)
                    })

                    // Update the dialog display with the fetched data, including pay inefficiency and totals
                    uc_setUserInterface_CustomDialogDisplay({
                      display: true,
                      dialog: {
                        dialog_jsx: (
                          <Card>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                  variant="h6"
                                  sx={{ marginRight: '16px' }}
                                >
                                  {personName}
                                </Typography>
                                <Typography variant="subtitle1">{formattedDateKey}</Typography>
                              </Box>
                            </Box>
                            <Box sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                              <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>{rLIB('Job Code')}</TableCell>
                                      <TableCell>{rLIB('Job Type')}</TableCell>
                                      <TableCell>{rLIB('Units')}</TableCell>
                                      <TableCell>{rLIB('Battery Units')}</TableCell>
                                      <TableCell>{rLIB('Pitch Units')}</TableCell>
                                      <TableCell>{rLIB('Distance Units')}</TableCell>
                                      <TableCell>{rLIB('Hours')}</TableCell>
                                      <TableCell>{rLIB('Total Units')}</TableCell>
                                      <TableCell>{rLIB('Difference')}</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {projectDetails.map((project: any, index: number) => (
                                      <TableRow key={index}>
                                        <TableCell>{project.jobCode}</TableCell>
                                        <TableCell>{project.jobType}</TableCell>
                                        <TableCell>{project.units}</TableCell>
                                        <TableCell>{project.batteryAdders}</TableCell>
                                        <TableCell>{project.pitchAdders}</TableCell>
                                        <TableCell>{project.distanceAdders}</TableCell>
                                        <TableCell>{project.hours}</TableCell>
                                        <TableCell>{project.totalUnits}</TableCell>
                                        <TableCell>{project.total}</TableCell>
                                      </TableRow>
                                    ))}
                                    {/* Totals row */}
                                    <TableRow>
                                      <TableCell style={{ fontWeight: 'bold' }}>{rLIB('Total')}</TableCell>
                                      <TableCell />
                                      <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.units.toFixed(2)}</TableCell>
                                      <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.batteryAdders.toFixed(2)}</TableCell>
                                      <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.pitchUnits.toFixed(2)}</TableCell>
                                      <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.distanceAdders.toFixed(2)}</TableCell>
                                      <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.hours.toFixed(2)}</TableCell>
                                      <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.totalUnits.toFixed(2)}</TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: 'bold',
                                          color: totalsForDialog.total > 0 ? themeVariables.error_main : themeVariables.success_main,
                                        }}
                                      >
                                        {totalsForDialog.total.toFixed(2)}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          </Card>
                        ),
                        settings: { max_width: 'lg' },
                      },
                    })
                  })
                  .catch((error) => {
                    console.error('Error fetching data: ', error)
                  })
              },
              conditionalFormatting: {
                ...cell.conditionalFormatting,
                cursor: 'pointer',
              },
            }
          }
        }
        return cell
      }),
    )

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  // Pay Inefficiency
  useEffect(() => {
    if (!us_rawCrewHours || !us_mondaysInDateRange) return

    // Process the raw data to format it
    const processedData = processPayInefficiencyData(us_rawCrewHours, us_mondaysInDateRange as any)

    // Set default sorting to the previous week when data is first loaded
    if (!sortConfigPayInefficiency && us_mondaysInDateRange.length > 0) {
      const previousMondayIndex = objectToArray(us_mondaysInDateRange).findIndex((monday: any) => {
        const previousMondayDate = getPreviousMonday()
        return monday === previousMondayDate
      })

      if (previousMondayIndex >= 0) {
        setSortConfigPayInefficiency({ column: previousMondayIndex + 1, direction: 'desc' }) // +1 to skip the name column
      }
    }

    // Apply sorting based on sortConfigPayInefficiency
    if (sortConfigPayInefficiency) {
      const { column, direction } = sortConfigPayInefficiency

      processedData.data_rows.sort((a: any, b: any) => {
        const aValue = a[column]?.cellValue ?? ''
        const bValue = b[column]?.cellValue ?? ''

        const numA = parseFloat(aValue)
        const numB = parseFloat(bValue)

        if (!isNaN(numA) && !isNaN(numB)) {
          return direction === 'asc' ? numA - numB : numB - numA
        } else {
          return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
        }
      })

      // Add dollar sign after sorting
      processedData.data_rows = processedData.data_rows.map((row: any) => {
        return row.map((cell: any, index: number) => {
          if (index > 0 && cell.cellValue !== '-') {
            return { ...cell, cellValue: `$${cell.cellValue}` } // Add dollar sign to numeric values
          }
          return cell
        })
      })
    }

    // Save processed data into state for display
    us_setPayInefficiencyData(processedData as any)
  }, [us_rawCrewHours, us_mondaysInDateRange, sortConfigPayInefficiency])

  // Second useEffect to handle refreshing of the processed pay inefficiency data
  useEffect(() => {
    if (!us_payInefficiencyData || !us_mondaysInDateRange) return

    // Handle the refresh columns
    const refreshedData = handleRefreshColumns(
      us_payInefficiencyData,
      'pay_inefficiency', // dashboardName prefix for pay inefficiency
      'cacheGMDashboardCrewHoursData', // corresponding cloud function for pay inefficiency
    )

    // Save the final pay inefficiency data into a new state
    us_setFinalPayInefficiencyData(refreshedData)
  }, [us_payInefficiencyData, us_mondaysInDateRange])

  //get vehicles
  //get vehicles
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCombinedVehicles(newData)
      ur_forceRerender()
    }
    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_CombinedVehiclesByDate_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {})
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  useEffect(() => {
    // Ensure all necessary data is available before calling the service function
    if (!us_combinedVehicles || !us_mondaysInDateRange) return

    // Call the service function to process the data
    const processedData = processVehicleMilesData(us_combinedVehicles, us_mondaysInDateRange as any)

    // Save the processed data to the state
    us_setFormattedCombinedVehicles(processedData as any)
  }, [us_combinedVehicles, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedCombinedVehicles || !us_mondaysInDateRange) return

    // Handle the refresh functionality first
    const refreshedData = handleRefreshColumns(us_formattedCombinedVehicles, 'vehicle_usage_', 'cacheGMDashboardVehicleData')

    // Handle the dialog functionality after refresh
    const finalData = handleDialogFunctionality(refreshedData)

    // Update the state with the final processed data

    us_setFinalCombinedVehicles(finalData)
  }, [us_formattedCombinedVehicles, us_mondaysInDateRange, us_refreshingColumn, uc_RootData_ClientKey, ur_forceRerender])

  const handleDialogFunctionality = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      console.error('Invalid data structure in handleDialogSetup:', data)
      return data // Return original data if structure is invalid
    }

    // Filter out "Unknown Driver" rows
    const cleanedDataRows = data.data_rows.filter((row: any) => row[0]?.cellValue !== 'Unknown Driver')

    const newDataRows = cleanedDataRows.map((row: any) =>
      row.map((cell: any, cellIndex: number) => {
        if (cellIndex > 0 && typeof cell.cellValue !== 'string') {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1]

          // Now we reference userKey directly
          const userKey = row[0]?.userKey
          if (!userKey) {
            console.warn('No userKey found for row:', row)
            return cell // Skip processing if no userKey
          }

          const weekData = us_combinedVehicles?.[formattedDateKey]?.data?.[userKey]
          if (!weekData) {
            console.warn(`No weekData found for userKey: ${userKey}, date: ${formattedDateKey}`)
            return cell
          }

          const additionalMiles = weekData.additional_miles || 0
          const cellValue = cell.cellValue
          const difference = isNaN(cellValue) ? '-' : roundNumber(cellValue)

          const hasWarningIcon = Object.keys(weekData).some((date) => {
            const dayData = weekData[date]
            return dayData && ((dayData.miles_driven > 0 && dayData.expected_miles === 0) || (dayData.miles_driven === 0 && dayData.expected_miles > 0))
          })

          return {
            ...cell,
            cellValue: (
              <span
                style={{ cursor: 'pointer', display: 'inline-block', width: '100%' }} // Full width clickable area
                onClick={() => openDialog(userKey, formattedDateKey)}
              >
                {difference === 0 ? '-' : difference}
                {hasWarningIcon && (
                  <Icon
                    icon="exclamation-triangle"
                    sx={{ color: themeVariables.warning_main, marginLeft: '4px' }}
                    tooltip={
                      Object.keys(weekData).some((date) => {
                        const dayData = weekData[date]
                        return dayData && dayData.miles_driven > 0 && dayData.expected_miles === 0
                      })
                        ? 'There are actual driven miles but no expected miles for at least one day this week'
                        : 'There are expected miles but no actual driven miles for at least one day this week'
                    }
                  />
                )}
              </span>
            ),
            conditionalFormatting: {
              backgroundColor: difference === '-' ? 'inherit' : difference < 0 ? themeVariables.success_main : themeVariables.error_main,
              color: 'white',
              cursor: 'pointer',
              width: '120px',
            },
          }
        }
        return cell
      }),
    )

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  // Helper function to open the dialog
  const openDialog = (userKey: string, formattedDateKey: string) => {
    const weekData = us_combinedVehicles?.[formattedDateKey]?.data?.[userKey]
    if (!weekData) {
      console.warn(`No valid data to show in dialog for userKey: ${userKey}, date: ${formattedDateKey}`)
      return
    }

    // Use associated_user_name instead of userKey
    const driverName = weekData.associated_user_name

    const dialogEntries = Object.keys(weekData)
      .filter((date) => weekData[date] && weekData[date].trips) // Ensure dayData and trips are defined
      .map((date) => {
        const dayData = weekData[date]
        const adjustedDate = new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toLocaleDateString()

        const expectedMilesWithAdditional = (dayData?.expected_miles || 0) + (dayData?.expected_miles === 0 ? 0 : weekData.additional_miles)

        return {
          date: adjustedDate,
          total_miles: dayData ? roundNumber(dayData.miles_driven) : 0,
          expected_distance: dayData && dayData.expected_miles > 0 ? roundNumber(dayData.expected_miles) : 0,
          total_duration: dayData ? dayData.total_duration : 0,
          numTasks: dayData ? dayData.num_tasks : 0,
          projectIDs: dayData ? dayData.projects : [],
          additional_miles: dayData?.expected_miles === 0 ? 0 : weekData.additional_miles,
          total_expected_miles: roundNumber(expectedMilesWithAdditional),
          vehicles: dayData ? Array.from(new Set(dayData.trips.map((trip: any) => trip.vehicle_name))).join(', ') : '',
          warning: dayData && ((dayData.miles_driven > 0 && dayData.expected_miles === 0) || (dayData.miles_driven === 0 && dayData.expected_miles > 0)),
        }
      })
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Card>
            <Box sx={{ padding: '16px' }}>
              <TabsComponent
                dialogEntries={dialogEntries}
                driverName={driverName} // Pass name here
                weekStartDate={formattedDateKey}
              />
            </Box>
          </Card>
        ),
        settings: { max_width: 'lg' },
      },
    })
  }

  const roundNumber = (num: number): number => {
    if (num > 0 && num < 1) return 1 // If the number is greater than 0 but less than 1, round it up to 1.
    return Math.round(num) // Otherwise, round the number to the nearest integer.
  }

  const TabsComponent = ({ dialogEntries, driverName, weekStartDate }: any) => {
    const [tabValue, setTabValue] = useState<any>(0)

    const handleTabChange = (event: any, newValue: any) => {
      setTabValue(newValue)
    }

    const totalMilesDriven = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.total_miles || 0), 0)
    const totalDuration = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.total_duration || 0), 0)
    const totalExpectedMiles = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.expected_distance || 0), 0)
    const totalAdditionalMiles = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.additional_miles || 0), 0)
    const totalTotalExpectedMiles = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.total_expected_miles || 0), 0)
    const totalNumTasks = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.numTasks || 0), 0)

    const summaryDifference = totalMilesDriven - totalTotalExpectedMiles

    const hasWarning = dialogEntries.some(
      (entry: any) => (entry.total_miles > 0 && entry.total_expected_miles === 0) || (entry.total_miles === 0 && entry.total_expected_miles > 0),
    )

    return (
      <>
        <Typography
          variant="h6"
          sx={{ padding: '16px' }}
        >
          {driverName} -{' '}
          {new Date(new Date(weekStartDate).setDate(new Date(weekStartDate).getDate() + 1)).toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
          })}
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Mileage Data Tabs"
        >
          <Tab label="Summary" />
          <Tab label="Miles Driven" />
          <Tab label="Expected Miles" />
        </Tabs>

        {tabValue === 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Total Miles Driven</TableCell>
                  <TableCell>Total Expected Miles</TableCell>
                  <TableCell>Difference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{totalMilesDriven}</TableCell>
                  <TableCell>{totalTotalExpectedMiles}</TableCell>
                  <TableCell
                    sx={{
                      color: summaryDifference <= 0 ? themeVariables.success_main : themeVariables.error_main,
                      fontWeight: 'bold',
                    }}
                  >
                    {summaryDifference} {summaryDifference > 0 ? 'miles over limit' : 'miles under limit'}
                    {hasWarning && (
                      <Icon
                        icon="exclamation-triangle"
                        className="tw-ml-2"
                        sx={{ color: themeVariables.warning_main }}
                        tooltip="Warning: check the miles driven and expected miles tabs for more details"
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {tabValue === 1 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Miles Driven</TableCell>
                  <TableCell>Total Duration (min)</TableCell>
                  <TableCell>Vehicle Name</TableCell>
                  <TableCell>Warning</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogEntries.map((entry: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell>{entry.date}</TableCell>
                    <TableCell>
                      {entry.total_miles}
                      {entry.is_cancelled && (
                        <Icon
                          icon="ban"
                          sx={{ color: themeVariables.error_main, marginLeft: '4px' }}
                          tooltip="This task was cancelled"
                        />
                      )}
                    </TableCell>
                    <TableCell>{entry.total_duration}</TableCell>
                    <TableCell>{entry.vehicles}</TableCell>
                    <TableCell>
                      {entry.warning && (
                        <>
                          <Icon
                            icon="exclamation-triangle"
                            sx={{ color: themeVariables.warning_main }}
                            tooltip={
                              entry.total_miles > 0 && entry.total_expected_miles === 0
                                ? 'Driven miles but no expected miles for the day'
                                : 'Expected miles but no miles driven for the day'
                            }
                          />
                          <Typography
                            variant="caption"
                            sx={{ color: themeVariables.warning_main, marginLeft: '4px' }}
                          >
                            {entry.total_miles > 0 && entry.total_expected_miles === 0
                              ? 'Driven miles but no expected miles for the day'
                              : 'Expected miles but no miles driven for the day'}
                          </Typography>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalMilesDriven}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalDuration}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {tabValue === 2 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Expected Miles</TableCell>
                  <TableCell>Additional Miles</TableCell>
                  <TableCell>Total Expected Miles</TableCell>
                  <TableCell>Number of Tasks</TableCell>
                  <TableCell>Project IDs</TableCell>
                  <TableCell>Warning</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogEntries.map((entry: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell>{entry.date}</TableCell>
                    <TableCell>
                      {entry.expected_distance}
                      {entry.is_cancelled && (
                        <Icon
                          icon="ban"
                          sx={{ color: themeVariables.error_main, marginLeft: '4px' }}
                          tooltip="This task was cancelled"
                        />
                      )}
                    </TableCell>
                    <TableCell>{entry.additional_miles}</TableCell>
                    <TableCell>{entry.total_expected_miles}</TableCell>
                    <TableCell>{entry.numTasks}</TableCell>
                    <TableCell>{entry.projectIDs.join(', ')}</TableCell>
                    <TableCell>
                      {entry.warning && (
                        <>
                          <Icon
                            icon="exclamation-triangle"
                            sx={{ color: themeVariables.warning_main }}
                            tooltip={
                              entry.total_miles > 0 && entry.total_expected_miles === 0
                                ? 'Driven miles but no expected miles for the day'
                                : 'Expected miles but no miles driven for the day'
                            }
                          />
                          <Typography
                            variant="caption"
                            sx={{ color: themeVariables.warning_main, marginLeft: '4px' }}
                          >
                            {entry.total_miles > 0 && entry.total_expected_miles === 0
                              ? 'Driven miles but no expected miles for the day'
                              : 'Expected miles but no miles driven for the day'}
                          </Typography>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalExpectedMiles}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalAdditionalMiles}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalTotalExpectedMiles}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalNumTasks}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{''}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    )
  }
  const handleRefreshColumns = (data: any, dashboardName: any, dashboardFunction: any) => {
    if (!data || !Array.isArray(data.summary_rows)) {
      return data // Return original data if structure is invalid
    }

    const newSummaryRows = data.summary_rows.map((row: any, rowIndex: any) => {
      if (rowIndex === 0) {
        return row.map((cell: any, cellIndex: any) => {
          if (cellIndex > 0 && typeof cell.cellValue === 'string') {
            const formattedDateKey = us_mondaysInDateRange[cellIndex - 1]

            return {
              ...cell,
              cellValue: (
                <>
                  {cell.cellValue}
                  {us_refreshingColumn === dashboardName + formattedDateKey ? (
                    <Icon
                      icon="arrows-rotate"
                      sx={{ color: themeVariables.success_main }} // Change to green
                      className="tw-ml-1 bp_spin" // Add spinning class
                      tooltip="Refreshing Data"
                    />
                  ) : (
                    <Icon
                      icon="arrows-rotate"
                      className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1"
                      tooltip="Refresh Data"
                      onClick={() => {
                        // Immediately update the state to start spinning
                        us_setRefreshingColumn(dashboardName + formattedDateKey)

                        // Ensure the UI re-renders with the spinning icon
                        setTimeout(() => {
                          cloudFunctionManageRequest('manageData', {
                            function: dashboardFunction,
                            client_key: uc_RootData_ClientKey,
                            monday_date_key: formattedDateKey,
                          })
                            .then(() => {
                              // Stop the refreshing icon once the data is refreshed
                              us_setRefreshingColumn(null)
                            })
                            .catch((error) => {
                              us_setRefreshingColumn(null) // Reset the state on error too
                            })
                        }, 0) // Ensure the UI updates before the async operation
                      }}
                    />
                  )}
                </>
              ),
            }
          }
          return cell
        })
      }
      return row
    })

    return {
      ...data,
      summary_rows: newSummaryRows,
    }
  }

  // Function to organize time-off data by week and team
  const organizeTimeOffByWeekAndTeam = (us_timeOff: any, us_mondaysInDateRange: string[]) => {
    const timeOffByWeekAndTeam: any = {}

    objectToArray(us_mondaysInDateRange).forEach((monday: string) => {
      timeOffByWeekAndTeam[monday] = {} // Initialize week object
    })

    Object.values(us_timeOff).forEach((timeOffEntry: any) => {
      const timeOffDate = new Date(timeOffEntry.timestamp_time_off.seconds * 1000)
      const timeOffDateKey = returnFormattedDateKey(timeOffDate)

      // Find the week for this time-off date
      const weekStart = objectToArray(us_mondaysInDateRange).find((monday: string) => {
        const weekStartDate = new Date(monday)
        const weekEndDate = new Date(weekStartDate)
        weekEndDate.setDate(weekEndDate.getDate() + 6) // End of the week (Sunday)

        return timeOffDate >= weekStartDate && timeOffDate <= weekEndDate
      })

      if (weekStart) {
        const teamKey = timeOffEntry.associated_team_key
        if (!timeOffByWeekAndTeam[weekStart][teamKey]) {
          timeOffByWeekAndTeam[weekStart][teamKey] = {
            associated_team_key: teamKey,
            associated_team_name: timeOffEntry.associated_team_name,
            time_off_type: timeOffEntry.time_off_type,
            days_off: [],
          }
        }

        // Add the specific day of time off
        timeOffByWeekAndTeam[weekStart][teamKey].days_off.push(timeOffDateKey)
      }
    })

    return timeOffByWeekAndTeam
  }

  // JSX Generation
  const changeCalendarStartMonth = (newDate: Date | null): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      if (newDate) {
        us_setSelectedStartDate(newDate)
        ur_forceRerender()
      }
      resolve({ success: true })
    })
  }

  const changeCalendarEndMonth = (newDate: Date | null): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      if (newDate) {
        us_setSelectedEndDate(newDate)
        ur_forceRerender()
      }
      resolve({ success: true })
    })
  }

  const rJSX_StartMonthPicker = (disabled: boolean, us_selectedStartDate: Date | null, changeCalendarStartMonth: (date: Date | null) => void): JSX.Element => {
    const currentYear = new Date().getFullYear()
    const minDate = new Date(currentYear, 0, 1)
    const maxDate = new Date(currentYear, 11, 31)

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="tw-mr-1"
          views={['month']}
          label={rLIB('Start Month')}
          minDate={minDate}
          maxDate={maxDate}
          value={us_selectedStartDate || new Date()}
          onChange={(newValue) => {
            if (newValue) {
              changeCalendarStartMonth(startOfMonth(newValue))
            } else {
              changeCalendarStartMonth(null)
            }
          }}
          disabled={disabled}
          renderInput={(params: any) => {
            const monthValue = params.inputProps.value ? format(new Date(params.inputProps.value), 'MMM yyyy') : ''
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                value={monthValue}
                sx={{
                  'width': 150,
                  'height': 60,
                  '.MuiInputBase-input': { height: '3px' },
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
    )
  }

  const rJSX_EndMonthPicker = (disabled: boolean, us_selectedEndDate: Date | null, changeCalendarEndMonth: (date: Date | null) => void): JSX.Element => {
    const currentYear = new Date().getFullYear()
    const minDate = new Date(currentYear, 0, 1)
    const maxDate = new Date(currentYear, 11, 31)

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="tw-mr-1"
          views={['month']}
          label={rLIB('End Month')}
          minDate={minDate}
          maxDate={maxDate}
          value={us_selectedEndDate || new Date()}
          onChange={(newValue) => {
            if (newValue) {
              changeCalendarEndMonth(endOfMonth(newValue))
            } else {
              changeCalendarEndMonth(null)
            }
          }}
          disabled={disabled}
          renderInput={(params: any) => {
            const monthValue = params.inputProps.value ? format(new Date(params.inputProps.value), 'MMM yyyy') : ''
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true, // Prevent manual editing
                }}
                value={monthValue}
                sx={{
                  'width': 150,
                  'height': 60,
                  '.MuiInputBase-input': { height: '3px' },
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
    )
  }

  // const rJSX_RegionDropdown = (shrinkButton: boolean): JSX.Element => {
  //   let dropdownJSX = (
  //     <Box className="tw-inline-block tw-align-top">
  //       <FormControl className="bp_thin_select_input">
  //         <InputLabel id={'region_filter'}>{rLIB('Region')}</InputLabel>
  //         <Select
  //           id={'region_filter'}
  //           labelId={'region_filter'}
  //           color="primary"
  //           value={us_selectedRegion}
  //           label={rLIB('Region')}
  //           onChange={(event: any) => {
  //             if (event != null && event.target != null && event.target.value != null) {
  //               us_setSelectedRegion(event.target.value)
  //             }
  //           }}
  //           variant="outlined"
  //         >
  //           <MenuItem value={'ALL_REGIONS'}>{rLIB('All Regions')}</MenuItem>
  //           {objectToArray(us_regionLists)
  //             .sort(dynamicSort('name', null))
  //             .map((option: TsInterface_UnspecifiedObject, index: number) => (
  //               <MenuItem
  //                 key={index}
  //                 value={option['key']}
  //               >
  //                 {option['name']}
  //               </MenuItem>
  //             ))}
  //         </Select>
  //       </FormControl>
  //     </Box>
  //   )
  //   return dropdownJSX
  // }

  // Download crews
  const rJSX_CrewExportButton = (): JSX.Element => {
    return (
      <Tooltip
        title="Export crew data for all completed tasks in the date range."
        arrow
      >
        <Button
          variant="contained"
          className="tw-ml-2 tw-flex tw-items-center"
          color="info"
          startIcon={<Icon icon="file-arrow-down" />}
          disabled={us_downloadingCycleTimes}
          onClick={() => {
            us_setDownloadingCycleTimes(true)
            downloadCrewCSV()
              .then((res_DNC: any) => {
                us_setDownloadingCycleTimes(false)
              })
              .catch((res_DNC: any) => {
                us_setDownloadingCycleTimes(false)
                uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: res_DNC.error,
                })
              })
          }}
        >
          {rLIB('Task Crew Makeup')}
          <Box
            component="span"
            className="tw-ml-2"
          ></Box>
        </Button>
      </Tooltip>
    )
  }

  const downloadCrewCSV = () => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // Step 1: Fetch task data by date range
          DatabaseGetCollection(DatabaseRef_TasksByDateRange_Query(res_GCK.clientKey, us_selectedStartDate, us_selectedEndDate))
            .then((res_TaskData) => {
              const taskData = res_TaskData.data

              // Filter out tasks with status "deleted"
              const filteredTaskData = Object.values(taskData).filter((task: any) => task.status !== 'deleted')

              // Step 2: Sort tasks by associated_project_id_number
              const sortedTasks = filteredTaskData.sort((a: any, b: any) => {
                const idA = a.associated_project_id_number || ''
                const idB = b.associated_project_id_number || ''
                return idA.localeCompare(idB)
              })

              // Step 3: Prepare CSV Data
              let csvData = [['Job Code', 'Task Name', 'Date', 'Person Name', 'Role', 'Task Completion Status']]

              sortedTasks.forEach((task: any) => {
                const jobCode = task.associated_project_id_number || 'Unknown Job Code'
                const taskName = task.name || 'Unknown Task'
                const taskStatus = task.status || 'Unknown Status'

                // Check for team members and their roles for each date
                const teamNamesObj = task.task_completion_scheduled_team_names || {}

                // Iterate over each date key
                Object.keys(teamNamesObj).forEach((dateKey) => {
                  const teamNames = teamNamesObj[dateKey]
                  const teamRoles = task.task_completion_scheduled_team_roles?.[dateKey] || {}

                  // Ensure that teamNames exists and is an object
                  if (teamNames && typeof teamNames === 'object') {
                    // Loop through team members (names) for this date
                    Object.entries(teamNames).forEach(([userKey, userName]) => {
                      // Ensure userName is a string
                      if (typeof userName === 'string') {
                        const role = teamRoles[userKey] || 'Unknown Role'

                        // Add each team member's details to CSV data with the date included
                        csvData.push([jobCode, taskName, dateKey, userName, role, taskStatus])
                      }
                    })
                  }
                })
              })

              // Step 4: Download CSV
              downloadCSV('TaskCrewMakeupExport', csvData)
                .then((res_DC) => {
                  resolve(res_DC)
                })
                .catch((rej_DC) => {
                  console.error('Error downloading CSV:', rej_DC)
                  reject(rej_DC)
                })
            })
            .catch((rej_TaskData) => {
              console.error('Error fetching task data for date range:', rej_TaskData)
              reject(rej_TaskData)
            })
        })
        .catch((rej_GCK) => {
          console.error('Error fetching client key:', rej_GCK)
          reject(rej_GCK)
        })
    })
  }

  // //download cycle times project roles
  // const rJSX_ProjectRolesExportButton = (): JSX.Element => {
  //   let buttonJSX = (
  //     <Button
  //       variant="contained"
  //       className="tw-ml-2"
  //       color="info"
  //       startIcon={<Icon icon="file-arrow-down" />}
  //       disabled={us_downloadingCycleTimes}
  //       onClick={() => {
  //         us_setDownloadingCycleTimes(true)
  //         downloadProjectRolesCSV()
  //           .then((res_DNC: any) => {
  //             us_setDownloadingCycleTimes(false)
  //           })
  //           .catch((res_DNC: any) => {
  //             us_setDownloadingCycleTimes(false)
  //             uc_setUserInterface_ErrorDialogDisplay({
  //               display: true,
  //               error: res_DNC.error,
  //             })
  //           })
  //       }}
  //     >
  //       {rLIB('Project Roles')}
  //     </Button>
  //   )
  //   return buttonJSX
  // }

  // const downloadProjectRolesCSV = () => {
  //   return new Promise((resolve, reject) => {
  //     getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //       .then((res_GCK) => {
  //         // Fetch task data by date range
  //         DatabaseGetCollection(DatabaseRef_TasksByDateRange_Query(res_GCK.clientKey, us_selectedStartDate, us_selectedEndDate))
  //           .then((res_TaskData) => {
  //             const taskData = res_TaskData.data

  //             // Filter out tasks with status "deleted"
  //             const filteredTaskData = Object.values(taskData).filter((task: any) => task.status !== 'deleted')

  //             const uniqueProjectKeys = new Set<string>() // Use Set to store unique project keys

  //             // Loop through the filtered task data and extract associated_project_key
  //             filteredTaskData.forEach((task: any) => {
  //               if (task.associated_project_key) {
  //                 uniqueProjectKeys.add(task.associated_project_key)
  //               }
  //             })

  //             // Convert the Set to an array of unique project keys
  //             const uniqueProjectKeysArray = Array.from(uniqueProjectKeys)

  //             // Use the collection reference to fetch the projects
  //             DatabaseGetCollection(DatabaseRef_Projects_Collection(res_GCK.clientKey))
  //               .then((res_ProjectsData) => {
  //                 let csvData = [['Job Code', 'CSS Rep', 'GM', 'Workflow']] // CSV headers

  //                 uniqueProjectKeysArray.forEach((projectKey) => {
  //                   const project = res_ProjectsData.data[projectKey]

  //                   if (project) {
  //                     const jobCode = project.id_number // Job code is the project key
  //                     const cssRep = project.associated_css_rep_name || ''
  //                     const generalManager = project.associated_general_manager_name || ''
  //                     const workflowName = project.associated_task_workflow_name || ''

  //                     // Add a single row for this project to the CSV data
  //                     csvData.push([jobCode, cssRep, generalManager, workflowName])
  //                   }
  //                 })

  //                 // Download CSV
  //                 downloadCSV('ProjectDataExport', csvData)
  //                   .then((res_DC) => {
  //                     resolve(res_DC)
  //                   })
  //                   .catch((rej_DC) => {
  //                     reject(rej_DC)
  //                   })
  //               })
  //               .catch((rej_ProjectsData) => {
  //                 reject(rej_ProjectsData) // Handle error in fetching projects data
  //               })
  //           })
  //           .catch((rej_TaskData) => {
  //             reject(rej_TaskData) // Handle error in fetching task data
  //           })
  //       })
  //       .catch((rej_GCK) => {
  //         reject(rej_GCK) // Handle error in fetching client key
  //       })
  //   })
  // }

  //download cycle times projects

  // const rJSX_CycleTimeProjectsExportButton = (): JSX.Element => {
  //   let buttonJSX = (
  //     <Button
  //       variant="contained"
  //       className="tw-ml-2"
  //       color="info"
  //       startIcon={<Icon icon="file-arrow-down" />}
  //       disabled={us_downloadingCycleTimes}
  //       onClick={() => {
  //         us_setDownloadingCycleTimes(true)
  //         downloadCycleTimeProjectsCSV()
  //           .then((res_DNC: any) => {
  //             us_setDownloadingCycleTimes(false)
  //           })
  //           .catch((res_DNC: any) => {
  //             us_setDownloadingCycleTimes(false)
  //             uc_setUserInterface_ErrorDialogDisplay({
  //               display: true,
  //               error: res_DNC.error,
  //             })
  //           })
  //       }}
  //     >
  //       {rLIB('Project Data Export')}
  //     </Button>
  //   )
  //   return buttonJSX
  // }

  // const downloadCycleTimeProjectsCSV = () => {
  //   return new Promise((resolve, reject) => {
  //     getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //       .then((res_GCK) => {
  //         // Step 1: Fetch task data by date range
  //         DatabaseGetCollection(DatabaseRef_TasksByDateRange_Query(res_GCK.clientKey, us_selectedStartDate, us_selectedEndDate))
  //           .then((res_TaskData) => {
  //             const taskData = res_TaskData.data

  //             // Filter out tasks with status "deleted"
  //             const filteredTaskData = Object.values(taskData).filter((task: any) => task.status !== 'deleted')

  //             // Extract unique project keys from filtered tasks
  //             const uniqueProjectKeys = new Set<string>()
  //             filteredTaskData.forEach((task: any) => {
  //               if (task.associated_project_key) {
  //                 uniqueProjectKeys.add(task.associated_project_key)
  //               }
  //             })

  //             // Convert the Set to an array of unique project keys
  //             const uniqueProjectKeysArray = Array.from(uniqueProjectKeys)

  //             // Step 2: Fetch project data for each unique project key
  //             Promise.all(
  //               uniqueProjectKeysArray.map((projectKey) =>
  //                 DatabaseGetDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey))
  //                   .then((res_ProjectData) => ({
  //                     projectKey,
  //                     project: res_ProjectData.data || null,
  //                   }))
  //                   .catch((err) => {
  //                     console.error(`Error fetching project ${projectKey}:`, err)
  //                     return null // Handle individual project fetch error gracefully
  //                   }),
  //               ),
  //             )
  //               .then((projectsArray) => {
  //                 let csvData = [
  //                   [
  //                     'Job Code',
  //                     'Workflow Name',
  //                     'Customer Name',
  //                     'Region',
  //                     'Jurisdiction',
  //                     'Utility',
  //                     'Finance Partner',
  //                     'Sales Partner',
  //                     'Package',
  //                     'Panels',
  //                     'Inverters',
  //                     'Storage',
  //                     'Size',
  //                     'Usage Offset',
  //                     'Number of Arrays',
  //                     'Number of Strings',
  //                     'Max Roof Pitch',
  //                   ],
  //                 ] // CSV headers

  //                 projectsArray.forEach((projectData) => {
  //                   if (projectData && projectData.project) {
  //                     const project = projectData.project
  //                     const jobCode = project.id_number || '' // Job code is the project key
  //                     const workflow = project.associated_task_workflow_name || ''
  //                     const associatedCustomerName = project.associated_customer_name || ''
  //                     const associatedRegionName = project.associated_region_name || ''
  //                     const associatedUtilityName = project.associated_utility_company_name || ''
  //                     const associatedFinancePartnerName = project.associated_finance_partner_name || ''
  //                     const associatedSalesPartnerName = project.associated_sales_partner_name || ''
  //                     const associatedJurisdiction = project.location_jurisdiction || ''
  //                     const productName = project.associated_product_name || ''
  //                     const systemPanels = project.system_panel_quantity || ''
  //                     const systemInverters = project.system_inverter_quantity || ''
  //                     const systemStorage = project.system_storage_quantity || ''
  //                     const systemSize = project.system_size_dc || ''
  //                     const systemUsageOffset = project.system_usage_offset || ''
  //                     const systemNumberOfArrays = project.system_number_of_arrays || ''
  //                     const systemNumberOfStrings = project.system_number_of_strings || ''
  //                     const systemMaxRoofPitch = project.system_max_roof_pitch || ''

  //                     // Add a single row for this project to the CSV data
  //                     csvData.push([
  //                       jobCode,
  //                       workflow,
  //                       associatedCustomerName,
  //                       associatedRegionName,
  //                       associatedJurisdiction,
  //                       associatedUtilityName,
  //                       associatedFinancePartnerName,
  //                       associatedSalesPartnerName,
  //                       productName,
  //                       systemPanels,
  //                       systemInverters,
  //                       systemStorage,
  //                       systemSize,
  //                       systemUsageOffset,
  //                       systemNumberOfArrays,
  //                       systemNumberOfStrings,
  //                       systemMaxRoofPitch,
  //                     ])
  //                   }
  //                 })

  //                 // Step 3: Download CSV
  //                 downloadCSV('ProjectDataExport', csvData)
  //                   .then((res_DC) => {
  //
  //                     resolve(res_DC)
  //                   })
  //                   .catch((rej_DC) => {
  //                     console.error('Error downloading CSV:', rej_DC)
  //                     reject(rej_DC)
  //                   })
  //               })
  //               .catch((err) => {
  //                 console.error('Error fetching projects:', err)
  //                 reject(err)
  //               })
  //           })
  //           .catch((rej_TaskData) => {
  //             console.error('Error fetching task data for date range:', rej_TaskData)
  //             reject(rej_TaskData) // Handle error in fetching task data
  //           })
  //       })
  //       .catch((rej_GCK) => {
  //         console.error('Error fetching client key:', rej_GCK)
  //         reject(rej_GCK) // Handle error in fetching client key
  //       })
  //   })
  // }

  // Download cycle times projects
  const rJSX_NewCycleTimeProjectsExportButton = (): JSX.Element => {
    const handleRegionClick = (regionKey: string) => {
      us_setSelectedRegion(regionKey)
    }

    const handleOpenDialog = () => {
      setIsDialogOpen(true)
    }

    const handleCloseDialog = () => {
      setIsDialogOpen(false)
    }

    const handleExport = () => {
      us_setDownloadingCycleTimes(true)
      newDownloadCycleTimeProjectsCSV(us_selectedRegion)
        .then((res_DNC: any) => {
          us_setDownloadingCycleTimes(false)
          handleCloseDialog()
        })
        .catch((res_DNC: any) => {
          us_setDownloadingCycleTimes(false)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: res_DNC.error,
          })
        })
    }

    return (
      <>
        <Tooltip
          title="Export all projects for a selected region. Does not use a date range."
          arrow
        >
          <Button
            variant="contained"
            className="tw-ml-2 tw-flex tw-items-center"
            color="info"
            startIcon={<Icon icon="file-arrow-down" />}
            onClick={handleOpenDialog}
            disabled={us_downloadingCycleTimes}
          >
            {rLIB('Project Data Export')}
            <Box
              component="span"
              className="tw-ml-2"
            ></Box>
          </Button>
        </Tooltip>

        {/* Dialog for Region Selection */}
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="region-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="region-dialog-title">{rLIB('Select Region for Export')}</DialogTitle>
          <DialogContent>
            <Card className="tw-p-4">
              <Box className="tw-flex tw-flex-wrap tw-gap-2">
                {Object.entries(us_regionsLists)
                  .sort(([, a], [, b]) => (a.name > b.name ? 1 : -1)) // Sort by name
                  .map(([regionKey, regionData]: [string, any]) => (
                    <Chip
                      key={regionKey}
                      label={regionData.name}
                      color={us_selectedRegion === regionKey ? 'primary' : 'default'}
                      variant={us_selectedRegion === regionKey ? 'filled' : 'outlined'}
                      onClick={() => handleRegionClick(regionKey)}
                      clickable
                      className="tw-cursor-pointer"
                    />
                  ))}
              </Box>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>{rLIB('Cancel')}</Button>
            <Button
              onClick={handleExport}
              color="primary"
              disabled={!us_selectedRegion || us_downloadingCycleTimes}
            >
              {rLIB('Export')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const newDownloadCycleTimeProjectsCSV = (selectedRegion: string) => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // Step 1: Fetch project data for the selected region
          DatabaseGetCollection(DatabaseRef_ProjectsForSpecificRegion_Query(res_GCK.clientKey, selectedRegion))
            .then((res_ProjectData) => {
              if (!res_ProjectData || !res_ProjectData.data) {
                console.error('No project data found or data field is undefined:', res_ProjectData)
                return reject(new Error('No project data found or data field is undefined'))
              }

              const projects = res_ProjectData.data

              // Prepare CSV Data
              let csvData = [
                [
                  'Job Code',
                  'Workflow Name',
                  'CSS Rep',
                  'Customer Name',
                  'Region',
                  'Jurisdiction',
                  'Utility',
                  'Finance Partner',
                  'Sales Partner',
                  'Package',
                  'Panels',
                  'Inverters',
                  'Storage',
                  'Size',
                  'Usage Offset',
                  'Number of Arrays',
                  'Number of Strings',
                  'Max Roof Pitch',
                ],
              ]

              // Loop through the projects and populate CSV rows
              Object.values(projects).forEach((project: any) => {
                const jobCode = project.id_number || '' // Job code is the project key
                const workflow = project.associated_task_workflow_name || ''
                const associatedCustomerName = project.associated_customer_name || ''
                const associatedRegionName = project.associated_region_name || ''
                const associatedUtilityName = project.associated_utility_company_name || ''
                const associatedFinancePartnerName = project.associated_finance_partner_name || ''
                const associatedSalesPartnerName = project.associated_sales_partner_name || ''
                const associatedJurisdiction = project.location_jurisdiction || ''
                const productName = project.associated_product_name || ''
                const systemPanels = project.system_panel_quantity || ''
                const systemInverters = project.system_inverter_quantity || ''
                const systemStorage = project.system_storage_quantity || ''
                const systemSize = project.system_size_dc || ''
                const systemUsageOffset = project.system_usage_offset || ''
                const systemNumberOfArrays = project.system_number_of_arrays || ''
                const systemNumberOfStrings = project.system_number_of_strings || ''
                const systemMaxRoofPitch = project.system_max_roof_pitch || ''
                const cssRep = project.associated_css_rep_name || ''

                // Add a single row for this project to the CSV data
                csvData.push([
                  jobCode,
                  workflow,
                  cssRep,
                  associatedCustomerName,
                  associatedRegionName,
                  associatedJurisdiction,
                  associatedUtilityName,
                  associatedFinancePartnerName,
                  associatedSalesPartnerName,
                  productName,
                  systemPanels,
                  systemInverters,
                  systemStorage,
                  systemSize,
                  systemUsageOffset,
                  systemNumberOfArrays,
                  systemNumberOfStrings,
                  systemMaxRoofPitch,
                ])
              })

              // Step 3: Download CSV
              downloadCSV('ProjectDataExport', csvData)
                .then((res_DC) => {
                  resolve(res_DC)
                })
                .catch((rej_DC) => {
                  console.error('Error downloading CSV:', rej_DC)
                  reject(rej_DC)
                })
            })
            .catch((rej_ProjectData) => {
              console.error('Error fetching projects for the selected region:', rej_ProjectData)
              reject(rej_ProjectData)
            })
        })
        .catch((rej_GCK) => {
          console.error('Error fetching client key:', rej_GCK)
          reject(rej_GCK) // Handle error in fetching client key
        })
    })
  }

  //download cycle times tasks
  // const rJSX_CycleTimeExportButton = (): JSX.Element => {
  //   let buttonJSX = (
  //     <Button
  //       variant="contained"
  //       className="tw-ml-2"
  //       color="info"
  //       startIcon={<Icon icon="file-arrow-down" />}
  //       disabled={us_downloadingCycleTimes}
  //       onClick={() => {
  //         us_setDownloadingCycleTimes(true)
  //         downloadCycleTimeCSV()
  //           .then((res_DNC: any) => {
  //             us_setDownloadingCycleTimes(false)
  //           })
  //           .catch((res_DNC: any) => {
  //             us_setDownloadingCycleTimes(false)
  //             uc_setUserInterface_ErrorDialogDisplay({
  //               display: true,
  //               error: res_DNC.error,
  //             })
  //           })
  //       }}
  //     >
  //       {rLIB('Task Completion Dates')}
  //     </Button>
  //   )
  //   return buttonJSX
  // }
  // const downloadCycleTimeCSV = () => {
  //   return new Promise((resolve, reject) => {
  //     getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //       .then((res_GCK) => {
  //

  //         // Step 1: Fetch task data by date range to get relevant projects
  //         DatabaseGetCollection(DatabaseRef_TasksByDateRange_Query(res_GCK.clientKey, us_selectedStartDate, us_selectedEndDate))
  //           .then((res_TaskData) => {
  //             const taskData = res_TaskData.data
  //

  //             // Filter out tasks with status "deleted"
  //             const filteredTaskData = Object.values(taskData).filter((task: any) => task.status !== 'deleted')
  //

  //             // Step 2: Group tasks by `associated_project_key` (just to get relevant project keys)
  //             const projectKeysSet = new Set<string>()
  //             filteredTaskData.forEach((task: any) => {
  //               projectKeysSet.add(task.associated_project_key)
  //             })
  //             const projectKeysArray = Array.from(projectKeysSet)
  //

  //             // Step 3: Fetch all tasks for each project, ignoring date range
  //             Promise.all(
  //               projectKeysArray.map((projectKey) => {
  //                 return DatabaseGetCollection(DatabaseRef_TaskForSpecificProject_Query(res_GCK.clientKey, projectKey))
  //                   .then((res_AllProjectTasks) => {
  //                     const allTasksForProject = res_AllProjectTasks.data
  //

  //                     // Initialize project in the map
  //                     const project: any = {
  //                       associated_project_id_number: allTasksForProject[Object.keys(allTasksForProject)[0]].associated_project_id_number,
  //                       associated_project_key: projectKey,
  //                       tasks: [],
  //                     }

  //                     // Loop through all tasks for the project and use their name, delay reasons, and timestamp_completed
  //                     Object.values(allTasksForProject).forEach((task: any) => {
  //                       const taskDetails = {
  //                         associated_task_blueprint_key: task.associated_task_blueprint_key,
  //                         task_name: task.name || 'Unknown Task',
  //                         delay_reasons: task.delay_reasons || {},
  //                         task_date: task.timestamp_completed, // Store the raw timestamp here
  //                       }
  //                       project.tasks.push(taskDetails)
  //                     })

  //                     return { projectKey, project }
  //                   })
  //                   .catch((err) => {
  //                     console.error(`Error fetching all tasks for project ${projectKey}:`, err)
  //                     return null
  //                   })
  //               }),
  //             )
  //               .then((projectDataArray) => {
  //                 const projectsMap: Record<string, any> = {}

  //                 // Add each project to the projectsMap
  //                 projectDataArray.forEach((projectData) => {
  //                   if (projectData) {
  //                     projectsMap[projectData.projectKey] = projectData.project
  //                   }
  //                 })

  //

  //                 // Prepare CSV Data
  //                 let csvData = [['Job Code', 'Task Name', 'Task Date', 'Delay Reasons']]

  //                 // Sort projects by `associated_project_id_number`
  //                 const sortedProjects = Object.values(projectsMap).sort((a: any, b: any) =>
  //                   a.associated_project_id_number > b.associated_project_id_number ? 1 : -1,
  //                 )

  //                 sortedProjects.forEach((project) => {
  //                   const jobCode = project.associated_project_id_number

  //

  //                   project.tasks.forEach((task: any, index: number) => {
  //                     // Initialize taskDate as null initially
  //                     let taskDate: string | null = null

  //                     if (task.task_date && task.task_date.seconds !== undefined) {
  //                       // Convert Firestore timestamp to JavaScript Date
  //                       const dateObj = new Date(task.task_date.seconds * 1000)
  //                       taskDate = dateObj.toISOString().split('T')[0] // Format to 'YYYY-MM-DD'
  //                     }

  //                     // Log task details to check for issues
  //
  //                       task_name: task.task_name,
  //                       delay_reasons: task.delay_reasons,
  //                       task_date: task.task_date,
  //                       formattedDate: taskDate,
  //                     })

  //                     // Only include tasks with a valid date
  //                     if (taskDate) {
  //                       const taskName = task.task_name || 'Unknown Task'
  //                       const delayReasonsStr = typeof task.delay_reasons === 'string' ? task.delay_reasons : Object.values(task.delay_reasons).join(', ')

  //                       csvData.push([jobCode, taskName, taskDate, delayReasonsStr])
  //                     }
  //                   })
  //                 })

  //

  //                 // Step 4: Download CSV
  //                 downloadCSV('TaskCompletionDatesExport', csvData)
  //                   .then((res_DC) => {
  //
  //                     resolve(res_DC)
  //                   })
  //                   .catch((rej_DC) => {
  //                     console.error('Error downloading CSV:', rej_DC)
  //                     reject(rej_DC)
  //                   })
  //               })
  //               .catch((err) => {
  //                 console.error('Error fetching all project tasks:', err)
  //                 reject(err)
  //               })
  //           })
  //           .catch((rej_TaskData) => {
  //             console.error('Error fetching task data for date range:', rej_TaskData)
  //             reject(rej_TaskData)
  //           })
  //       })
  //       .catch((rej_GCK) => {
  //         console.error('Error fetching client key:', rej_GCK)
  //         reject(rej_GCK)
  //       })
  //   })
  // }

  // Download cycle times tasks
  const rJSX_NewCycleTimeExportButton = (): JSX.Element => {
    return (
      <Tooltip
        title="Export all completed tasks within the selected date range."
        arrow
      >
        <Button
          variant="contained"
          className="tw-ml-2 tw-flex tw-items-center"
          color="info"
          startIcon={<Icon icon="file-arrow-down" />}
          disabled={us_downloadingCycleTimes}
          onClick={() => {
            us_setDownloadingCycleTimes(true)
            newDownloadCycleTimeCSV()
              .then((res_DNC: any) => {
                us_setDownloadingCycleTimes(false)
              })
              .catch((res_DNC: any) => {
                us_setDownloadingCycleTimes(false)
                uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: res_DNC.error,
                })
              })
          }}
        >
          {rLIB('Task Completion Dates')}
          <Box
            component="span"
            className="tw-ml-2"
          ></Box>
        </Button>
      </Tooltip>
    )
  }

  const newDownloadCycleTimeCSV = () => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // Step 1: Fetch task data by date range
          DatabaseGetCollection(DatabaseRef_TasksByDateRange_Query(res_GCK.clientKey, us_selectedStartDate, us_selectedEndDate))
            .then((res_TaskData) => {
              // Guard check for task data validity
              if (!res_TaskData || !res_TaskData.data) {
                console.error('No task data found or data field is undefined:', res_TaskData)
                return reject(new Error('No task data found or data field is undefined'))
              }

              const taskData = res_TaskData.data

              // Filter out tasks with status "deleted"
              let filteredTaskData = Object.values(taskData).filter((task: any) => task.status !== 'deleted')

              // Sort tasks by job code (project ID)
              filteredTaskData = filteredTaskData.sort((a: any, b: any) => {
                const jobCodeA = a.associated_project_id_number || ''
                const jobCodeB = b.associated_project_id_number || ''
                return jobCodeA.localeCompare(jobCodeB)
              })

              // Step 2: Prepare CSV Data with additional fields
              let csvData = [
                [
                  'Job Code',
                  'Task Name',
                  'Date Completed',
                  'Date Assigned',
                  'Date Created',
                  'Delay Reasons',
                  'Associated Owner Name',
                  'Associated Task Group Name',
                  'Associated Team Name',
                  'Location Latitude',
                  'Location Longitude',
                  'Status',
                ],
              ]

              // Loop through filtered tasks and organize data for the CSV
              filteredTaskData.forEach((task: any, index: number) => {
                const jobCode = task.associated_project_id_number || 'Unknown Job Code'
                const taskName = task.name || 'Unknown Task'

                // Extract and format date fields with validation
                const dateCompleted =
                  task.timestamp_completed && task.timestamp_completed.seconds !== undefined
                    ? new Date(task.timestamp_completed.seconds * 1000).toISOString().split('T')[0]
                    : ''

                const dateAssigned =
                  task.timestamp_assigned && task.timestamp_assigned.seconds !== undefined
                    ? new Date(task.timestamp_assigned.seconds * 1000).toISOString().split('T')[0]
                    : ''

                const dateCreated =
                  task.timestamp_created && task.timestamp_created.seconds !== undefined
                    ? new Date(task.timestamp_created.seconds * 1000).toISOString().split('T')[0]
                    : ''

                // Prepare delay reasons string
                let delayReasonsStr = ''
                if (typeof task.delay_reasons === 'string') {
                  delayReasonsStr = task.delay_reasons
                } else if (task.delay_reasons && typeof task.delay_reasons === 'object') {
                  delayReasonsStr = Object.values(task.delay_reasons).join(', ')
                }

                // Extract additional fields
                const associatedOwnerName = task.associated_owner_name || ''
                const associatedTaskGroupName = task.associated_task_group_name || ''
                const associatedTeamName = task.associated_team_name || ''
                const locationLatitude = task.location_latitude || ''
                const locationLongitude = task.location_longitude || ''
                const status = task.status || ''

                // Only include tasks with a valid date completed
                if (dateCompleted) {
                  csvData.push([
                    jobCode,
                    taskName,
                    dateCompleted,
                    dateAssigned,
                    dateCreated,
                    delayReasonsStr,
                    associatedOwnerName,
                    associatedTaskGroupName,
                    associatedTeamName,
                    locationLatitude,
                    locationLongitude,
                    status,
                  ])
                }
              })

              // Step 3: Download CSV
              downloadCSV('TaskCompletionDatesExport', csvData)
                .then((res_DC) => {
                  resolve(res_DC)
                })
                .catch((rej_DC) => {
                  console.error('Error downloading CSV:', rej_DC)
                  reject(rej_DC)
                })
            })
            .catch((rej_TaskData) => {
              console.error('Error fetching task data for date range:', rej_TaskData)
              reject(rej_TaskData)
            })
        })
        .catch((rej_GCK) => {
          console.error('Error fetching client key:', rej_GCK)
          reject(rej_GCK)
        })
    })
  }

  // Function to find the limit for a given docID (e.g., 'rtg_to_install') from the us_cycleTimeLimits
  const getLimitByDocID = (docID: string) => {
    const limitData: any = Object.values(us_cycleTimeLimits).find((limitEntry: any) => limitEntry.key === docID)
    return limitData ? limitData.limit : 0
  }

  const editCycleTimeLimit = (docID: string, dashboardTitle: string) => {
    const limitData: any = Object.values(us_cycleTimeLimits).find((limitEntry: any) => limitEntry.key === docID)

    if (limitData) {
      us_setNewLimit(limitData.limit)
    } else {
      us_setNewLimit(0)
    }

    us_setCurrentDocID(docID)
    us_setDialogTitle(dashboardTitle)
    us_setIsDialogOpen(true)
  }

  const handleSaveLimit = () => {
    const clientKey = uc_RootData_ClientKey

    if (!clientKey || !us_currentDocID) {
      console.error('Client key or docID is missing')
      return
    }

    DatabaseSetMergeDocument(DatabaseRef_CycleTimeThresholds_Document(clientKey as any, us_currentDocID), {
      limit: us_newLimit,
      name: us_currentDocID,
      key: us_currentDocID,
    })
      .then(() => {
        us_cycleTimeLimits((prevLimits: any) => {
          const updatedLimits = Object.entries(prevLimits).reduce((acc: any, [key, value]: [string, any]) => {
            if (value.key === us_currentDocID) {
              acc[key] = { ...value, limit: us_newLimit }
            } else {
              acc[key] = value
            }
            return acc
          }, {})
          return updatedLimits
        })

        us_setIsDialogOpen(false)
      })
      .catch((error) => {
        console.error('Error saving the new limit:', error)
      })
  }

  const renderDialog = (
    <Dialog
      open={us_isDialogOpen}
      onClose={() => us_setIsDialogOpen(false)}
    >
      <DialogTitle>{`Edit ${us_dialogTitle} Threshold`}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Number of Days"
          type="text"
          fullWidth
          value={us_newLimit}
          onChange={(e) => us_setNewLimit(Number(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => us_setIsDialogOpen(false)}>Cancel</Button>
        <Button
          onClick={() => handleSaveLimit()}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )

  const renderHeatmapForCycleTime = (dashboardTitle: string, docID: string, summaryRows: any, dataRows: any) => {
    const limit = getLimitByDocID(docID)

    return (
      <Card className="tw-mt-4">
        <GMDashboardHeatmap
          heatmapLegend={
            <Box sx={{ paddingBottom: '10px' }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ marginBottom: 1 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ textAlign: 'center' }}
                >
                  {dashboardTitle}
                </Typography>
                <IconButton
                  onClick={() => editCycleTimeLimit(docID, dashboardTitle)}
                  sx={{ marginLeft: 1, padding: '6px' }}
                >
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                    sx={{ fontSize: '18px' }}
                  />
                </IconButton>
              </Stack>

              {renderDialog}
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{ marginBottom: 1 }}
              >
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: themeVariables.success_main,
                    borderRadius: '5px',
                  }}
                ></Box>

                <Typography variant="body2">{`${limit} days or less`}</Typography>
              </Stack>
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
              >
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: themeVariables.error_main,
                    borderRadius: '5px',
                  }}
                ></Box>
                <Typography variant="body2">{`More than ${limit} days`}</Typography>
              </Stack>
            </Box>
          }
          tableData={{
            summary_rows: summaryRows,
            data_rows: dataRows,
          }}
        />
      </Card>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    const handleOpenExportDialog = () => {
      setOpenExportDialog(true)
    }

    const handleCloseExportDialog = () => {
      setOpenExportDialog(false)
    }

    return (
      <AuthenticatedContainer
        pageHeader={rLIB('GM Dashboard')}
        pageKey={pageKey}
        content={
          <Box>
            <Box className="mb-2 flex items-center gap-2">
              {rJSX_StartMonthPicker(false, us_selectedStartDate, changeCalendarStartMonth)}
              {rJSX_EndMonthPicker(false, us_selectedEndDate, changeCalendarEndMonth)}
              {/* Single Export Button */}
              <Tooltip title="Exports use the selected start and end dates">
                <Button
                  variant="contained"
                  onClick={handleOpenExportDialog}
                  color="info"
                >
                  {rLIB('Export')}
                </Button>
              </Tooltip>
            </Box>

            {/* Export Dialog */}
            <Dialog
              open={openExportDialog}
              onClose={handleCloseExportDialog}
              maxWidth="sm"
              fullWidth
            >
              <Card className="tw-m-4 tw-p-2">
                <DialogTitle>{rLIB('Select Export Option')}</DialogTitle>
                <DialogContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                  >
                    {rJSX_NewCycleTimeExportButton()}
                    {rJSX_NewCycleTimeProjectsExportButton()}
                    {/* {rJSX_ProjectRolesExportButton()} */}
                    {rJSX_CrewExportButton()}

                    {/* {rJSX_CycleTimeExportButton()}
                    {rJSX_CycleTimeProjectsExportButton()} */}
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseExportDialog}
                    variant="outlined"
                  >
                    {rLIB('Close')}
                  </Button>
                </DialogActions>
              </Card>
            </Dialog>

            {/* Heatmap for Panels Installed */}
            <Card className="tw-mb-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Met or exceeded expected panels (20 panels/working day)')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Installed fewer panels than expected (20 panels/working day)')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalPanelsInstalled.summary_rows || [],
                  data_rows: us_finalPanelsInstalled.data_rows || [],
                }}
                sortConfig={sortConfigPanelsInstalled} // Use specific sortConfig
                setSortConfig={setSortConfigPanelsInstalled} // Use specific setSortConfig
              />
            </Card>

            {/* Heatmap for Crew Hours */}
            <Card className="tw-mb-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Box className="tw-mb-2">
                      <Typography variant="body2">
                        {rLIB('Hours against max allocation. Includes pitch and roof and distance type adders for install.')}
                      </Typography>
                    </Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Employee took less hours than max allocation')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Employee took more hours than max allocation')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalCrewHours.summary_rows,
                  data_rows: us_finalCrewHours.data_rows,
                }}
                sortConfig={sortConfigCrewHours} // Use Crew Hours sortConfig
                setSortConfig={setSortConfigCrewHours} // Use Crew Hours setSortConfig
              />
            </Card>

            {/* Heatmap for Pay Inefficiency */}
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Box className="tw-mb-2">
                      <Typography variant="body2">{rLIB('Pay inefficiency pulled directly from payroll.')}</Typography>
                    </Box>
                    {/* <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Employee took less hours than max allocation')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Employee took more hours than max allocation')}</Typography>
                    </Stack> */}
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalPayInefficiencyData.summary_rows,
                  data_rows: us_finalPayInefficiencyData.data_rows,
                }}
                sortConfig={sortConfigPayInefficiency} // Use Pay Inefficiency sortConfig
                setSortConfig={setSortConfigPayInefficiency} // Use Pay Inefficiency setSortConfig
              />
            </Card>

            {/* Heatmap for Safety Reviews */}
            <Card className="tw-mt-4">
              <Box className="tw-flex tw-justify-between tw-items-center tw-mb-2"></Box>
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Safety review completed')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Safety review failed or not completed')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalSafetyReviews.summary_rows || [],
                  data_rows: us_finalSafetyReviews.data_rows || [],
                }}
              />
            </Card>

            {/* Render Heatmap for Cycle Time */}
            {renderHeatmapForCycleTime('RTG to Install', 'rtg_to_install', us_finalRTGToInstall.summary_rows, us_finalRTGToInstall.data_rows)}
            {renderHeatmapForCycleTime('Customer Signature to Install', 'cs_to_install', us_finalCSToInstall.summary_rows, us_finalCSToInstall.data_rows)}
            {renderHeatmapForCycleTime(
              'Permit Approval to Install',
              'permit_approval_to_install',
              us_finalPermitApprovalToInstall.summary_rows,
              us_finalPermitApprovalToInstall.data_rows,
            )}
            {renderHeatmapForCycleTime('Customer Signature to PTO', 'cs_to_pto', us_finalCsToPto.summary_rows, us_finalCsToPto.data_rows)}

            {/* Heatmap for Vehicle Usage */}
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Actual miles were less than or equal to expected miles')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Actual miles were greater than or equal to expected miles')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalCombinedVehicles.summary_rows,
                  data_rows: us_finalCombinedVehicles.data_rows,
                }}
              />
            </Card>

            {/* <Json data={us_regionsLists}></Json> */}
          </Box>
        }
      />
    )
  }

  // Render
  return <>{rJSX_Page()}</>
}
